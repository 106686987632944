import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmFModalNFName, setAdmFModalNFDescription, setAdmFModalNFActive } from "~/redux/action"

class AdmFModalNFDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmFModalNFName("")
		this.props.setAdmFModalNFDescription("")
		this.props.setAdmFModalNFActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.form.modalNewForm.name !== this.props.form.modalNewForm.name ||
			prevProps.form.modalNewForm.description !== this.props.form.modalNewForm.description
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.form.modalNF.name)
		let nameInput = this.props.form.modalNewForm.name.trim().toLowerCase()
		let descriptionHtml = document.getElementById(ResourceAssistance.ID.ADM.form.modalNF.description)
		let descriptionInput = this.props.form.modalNewForm.description.trim().toLowerCase()
		if (
			!nameInput ||
			this.props.form.formTable.original
				.filter((each) => !this.props.form.selectedForm.form || this.props.form.selectedForm.form.id !== each.id)
				.some((each) => each.displayName.trim().toLowerCase() === nameInput)
		) {
			nameHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
			descriptionHtml.setCustomValidity("")
		} else if (!descriptionInput) {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
		} else {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity("")
		}
	}

	onName(event) {
		this.props.setAdmFModalNFName(event.target.value)
	}

	onDescription(event) {
		this.props.setAdmFModalNFDescription(event.target.value)
	}

	onActive(event) {
		this.props.setAdmFModalNFActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.form.modalNF.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.form.modalNewForm.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.description)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.form.modalNF.description}
							as={ResourceAssistance.FormControl.as.textArea}
							value={this.props.form.modalNewForm.description}
							rows="5"
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.form.modalNewForm.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	form: state.admin.itemConfig.form,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmFModalNFName, setAdmFModalNFActive, setAdmFModalNFDescription }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmFModalNFDetails))
