import { ResourceAssistance } from "~/i18n"
import {
	SET_ADMIN_SERVICE_DISPLAY_ALL_IND,
	SET_ADMIN_SERVICE_ROLES,
	SET_ADMIN_SERVICE_SEARCH_CODE,
	SET_ADMIN_SERVICE_SEARCH_NAME,
	SET_ADMIN_SERVICE_SELECTED_SERVICE,
	SET_ADMIN_SERVICE_SELECTED_TYPE,
	SET_ADMIN_SERVICE_SERVICES,
	SET_ADMIN_SERVICE_SERVICES_LOADING,
	SET_ADMIN_SERVICE_TYPES,
	SET_ADM_SERVICE_NEW_SERVICE_ACTIVE,
	SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM,
	SET_ADM_SERVICE_NEW_SERVICE_CODE,
	SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION,
	SET_ADM_SERVICE_NEW_SERVICE_FS_CODE,
	SET_ADM_SERVICE_NEW_SERVICE_NAME,
	SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT,
	SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE,
	SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE,
} from "~/redux/type"

const init = {
	service: {
		searchCode: "",
		searchName: "",
		displayInactive: true,
		types: [],
		roles: [],
		selectedService: {
			index: -1,
			service: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.categoryNum,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.pricePerUnit,
				ResourceAssistance.Message.payee,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewService: {
			code: "",
			fsCode: "",
			categoryNum: "",
			name: "",
			description: "",
			pricePerUnit: "",
			isActive: true,
			selectedType: {
				index: -1,
				type: null,
			},
			selectedRole: {
				index: -1,
				role: null,
			},
		},
	},
}

const serviceConfigReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADMIN_SERVICE_TYPES:
			return Object.assign({}, state, {
				service: {
					...state.service,
					types: action.payload.objs,
				},
			})

		case SET_ADMIN_SERVICE_SELECTED_TYPE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})

		case SET_ADMIN_SERVICE_SEARCH_CODE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					searchCode: action.payload.value,
				},
			})

		case SET_ADMIN_SERVICE_SEARCH_NAME:
			return Object.assign({}, state, {
				service: {
					...state.service,
					searchName: action.payload.value,
				},
			})

		case SET_ADMIN_SERVICE_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				service: {
					...state.service,
					displayInactive: action.payload.value,
				},
			})

		case SET_ADMIN_SERVICE_SERVICES:
			return Object.assign({}, state, {
				service: {
					...state.service,
					serviceTable: {
						...state.service.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_ADMIN_SERVICE_SERVICES_LOADING:
			return Object.assign({}, state, {
				service: {
					...state.service,
					serviceTable: {
						...state.service.serviceTable,
						isLoading: action.payload.isLoading,
					},
				},
			})

		case SET_ADMIN_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						selectedType: {
							index: action.payload.index,
							type: action.payload.selected,
						},
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_CODE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						code: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_FS_CODE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						fsCode: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_NAME:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						name: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						description: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						pricePerUnit: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_ACTIVE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						isActive: action.payload.value,
					},
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						categoryNum: action.payload.value,
					},
				},
			})

		case SET_ADMIN_SERVICE_ROLES:
			return Object.assign({}, state, {
				service: {
					...state.service,
					roles: action.payload.objs,
				},
			})

		case SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE:
			return Object.assign({}, state, {
				service: {
					...state.service,
					modalNewService: {
						...state.service.modalNewService,
						selectedRole: {
							index: action.payload.index,
							role: action.payload.selected,
						},
					},
				},
			})

		default:
			return state
	}
}

export default serviceConfigReducer
