import { StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

class Signature extends React.Component {
	renderSignature(num) {
		let signatures = []
		for (let i = 0; i < num; i++) {
			signatures.push(
				<Fragment>
					{(i > 0 || num === 1) && <View style={ResourceAssistance.Report.styles.col} />}
					<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
						<Text>{_.isEmpty(this.props.signatureTitles) ? "\n" : this.props.signatureTitles[i]}</Text>
						<Text>{"\n\n"}</Text>
						<View style={styles.line}></View>
						{!_.isEmpty(this.props.signatureNames) && <Text>{this.props.signatureNames[i]}</Text>}
						{this.props.displaySignatureDate && <Text>{this.props.signatureDate}</Text>}
					</View>
				</Fragment>
			)
		}

		return signatures
	}

	render() {
		return (
			<View
				style={[ResourceAssistance.Report.styles.row, this.props.fixed ? styles.signature : ""]}
				wrap={false}
				fixed={this.props.fixed}
			>
				{this.renderSignature(this.props.numOfSignature)}
			</View>
		)
	}
}

const styles = StyleSheet.create({
	signature: {
		position: "absolute",
		left: 25,
		bottom: 25,
		right: 25,
		textAlign: "center",
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
})

Signature.propTypes = {
	numOfSignature: PropTypes.number,
	signatureDate: PropTypes.string,
	fixed: PropTypes.bool,
	displaySignatureDate: PropTypes.bool,
}

Signature.defaultProps = {
	fixed: true,
	displaySignatureDate: true,
	signatureDate: Utils.formatDateTime(new Date().getTime()),
	numOfSignature: 1,
}

export default Signature
