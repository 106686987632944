import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER,
	SET_ADMIN_SUPPLIER_SUPPLIERS,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE,
	SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND,
} from "../../type/type/ADMIN"

export const setAdmSSuppliers = (suppliers) => {
	return (dispatch, getState) => {
		let filtered = suppliers
			.filter((each) => {
				if (getState().admin.itemConfig.supplier.displayInactive) {
					return (
						each.code.toLowerCase().includes(getState().admin.itemConfig.supplier.searchCode.toLowerCase()) &&
						each.displayName.toLowerCase().includes(getState().admin.itemConfig.supplier.searchName.toLowerCase())
					)
				} else {
					return (
						each.active &&
						each.code.toLowerCase().includes(getState().admin.itemConfig.supplier.searchCode.toLowerCase()) &&
						each.displayName.toLowerCase().includes(getState().admin.itemConfig.supplier.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.supplier.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.code,
				each.displayName,
				each.phone,
				each.address,
				each.createdBy.displayName,
				Utils.formatDate(each.creationDateTime),
			]
		})

		dispatch({
			type: SET_ADMIN_SUPPLIER_SUPPLIERS,
			payload: {
				original: suppliers,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmSDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmSSelectedSupplier = (index, supplier) => {
	return {
		type: SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER,
		payload: {
			index: index,
			supplier: supplier,
		},
	}
}

export const setAdmSModalNSCode = (code) => {
	return {
		type: SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE,
		payload: {
			code: code,
		},
	}
}

export const setAdmSModalNSName = (name) => {
	return {
		type: SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmSModalNSPhone = (phone) => {
	return {
		type: SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE,
		payload: {
			phone: phone,
		},
	}
}

export const setAdmSModalNSAddress = (address) => {
	return {
		type: SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS,
		payload: {
			address: address,
		},
	}
}

export const setAdmSModalNSActive = (isActive) => {
	return {
		type: SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
