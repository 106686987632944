import { SET_DEPOSIT_MODAL_SEARCH_PATIENTS, SET_DEPOSIT_TRANSACTIONS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setDepositModalSearchPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) =>
			Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber())
		)

		let body = filtered.map((patient) => {
			return [
				patient.id,
				patient.identificationNumber,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
			]
		})

		dispatch({
			type: SET_DEPOSIT_MODAL_SEARCH_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setDepositTransactions = (transactions) => {
	return (dispatch, getState) => {
		let filtered = transactions.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((transaction) => {
			return [
				transaction.id,
				Utils.formatDate(transaction.creationDateTime),
				Utils.formatTime(transaction.creationDateTime),
				transaction.type.charAt(0),
				transaction.payment,
				transaction.remark.replace("\n", " - "),
				Utils.formatNumWithComma(Utils.BigNumber(transaction.amount).toFixed(2)),
				transaction.createdBy.displayName,
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEPOSIT_TRANSACTIONS,
			payload: {
				original: transactions,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
