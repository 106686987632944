import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GiCancel, GiMedicines, GiStopwatch } from "react-icons/gi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelPatients,
	setLoadingAction,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_HOSPITEL_ORDER_END_DATE,
	SET_HOSPITEL_STOP_DISPLAY,
	SET_IPD_PRN_RECORD_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelRightOrderActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onEndDate = this.onEndDate.bind(this)
		this.onChangeEndDate = this.onChangeEndDate.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onPRNRecord = this.onPRNRecord.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.hospitel.selectedPatient, this.props.hospitel.selectedPatient) ||
			!_.isEqual(prevProps.hospitel.selectedHospitel, this.props.hospitel.selectedHospitel)
		) {
			if (
				this.props.hospitel.selectedHospitel.hospitel &&
				this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
			) {
				let dischargeDate = Utils.generateDateFromLong(
					this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime
				)
				let endDate = Utils.generateDateFromLong(
					new Date(dischargeDate.getFullYear(), dischargeDate.getMonth(), dischargeDate.getDate()).getTime(),
					0,
					0,
					0,
					23,
					59,
					59
				)
				this.props.setValue(SET_HOSPITEL_ORDER_END_DATE, endDate.getTime())
			} else {
				this.props.setValue(SET_HOSPITEL_ORDER_END_DATE, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
			}
		}

		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.cancelOrder)
			) {
				this.cancelOrder()
			}
		}
	}

	cancelOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.cancelOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				orders: [
					{
						id: this.props.hospitel.selectedOrder.order.id,
						endDateTime:
							this.props.hospitel.selectedOrder.order.startDateTime > new Date().getTime()
								? Utils.generateDateFromLong(
										this.props.hospitel.selectedOrder.order.startDateTime,
										0,
										0,
										0,
										23,
										59,
										59
								  ).getTime()
								: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
						cancelDateTime: new Date().getTime(),
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_ORDER_END_DATE, date.getTime())
	}

	onChangeEndDate() {
		this.props.onModalDisplayAction(SET_HOSPITEL_STOP_DISPLAY, true)
	}

	onCancel() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.cancelOrder)
		this.props.setWarningMsgAction(
			this.props.hospitel.selectedOrder.order.description + "\n" + this.props.hospitel.selectedOrder.order.notes
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onPRNRecord() {
		this.props.onModalDisplayAction(SET_IPD_PRN_RECORD_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				<Col md={"auto"}>
					<DatePicker
						isShowTime={false}
						minDate={Utils.generateDateFromLong(
							this.props.hospitel.selectedHospitel.hospitel
								? this.props.hospitel.selectedHospitel.hospitel.creationDateTime
								: Utils.generateDate().getTime()
						)}
						maxDate={
							this.props.hospitel.selectedHospitel.hospitel &&
							this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
								? Utils.generateDateFromLong(
										this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime
								  )
								: null
						}
						showDisabledMonthNavigation
						disabled={this.props.hospitel.selectedHospitel.index === -1}
						startDate={Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime)}
						onChange={this.onEndDate}
					/>
				</Col>
				<Col>
					<div className="vertical"></div>
				</Col>

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, this.props.login.user.roles) && (
					<Fragment>
						<Col md={"auto"}>
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.warning}
								disabled={
									this.props.hospitel.selectedHospitel.index === -1 ||
									this.props.hospitel.selectedHospitel.hospitel.dischargeSummary ||
									this.props.hospitel.selectedOrder.index === -1 ||
									this.props.hospitel.selectedOrder.order.endDateTime < Utils.generateDate().getTime() ||
									this.props.hospitel.selectedOrder.order.cancelledDateTime
								}
								onClick={this.onChangeEndDate}
							>
								<GiStopwatch size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.changeEndDate)}
							</Button>
						</Col>
						<Col md={"auto"}>
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.red}
								disabled={
									this.props.hospitel.selectedHospitel.index === -1 ||
									this.props.hospitel.selectedHospitel.hospitel.dischargeSummary ||
									this.props.hospitel.selectedOrder.index === -1 ||
									this.props.hospitel.selectedOrder.order.cancelledDateTime ||
									this.props.hospitel.selectedOrder.order.endDateTime < new Date().getTime()
								}
								onClick={this.onCancel}
							>
								<GiCancel size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.cancel)}
							</Button>
						</Col>
						<Col>
							<div className="vertical"></div>
						</Col>
					</Fragment>
				)}

				<Col md={"auto"}>
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.hospitel.selectedHospitel.index === -1 ||
							this.props.hospitel.selectedOrder.index === -1 ||
							!this.props.hospitel.selectedOrder.order.prn ||
							this.props.hospitel.orderEndDateTime > Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
						}
						onClick={this.onPRNRecord}
					>
						<GiMedicines size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.prnRecord)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
			setWarningMsgAction,
			setWarningId,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightOrderActionRow))
