import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction } from "~/redux/action"
import ConfigFocus from "./focus/ConfigFocus"
import ConfigIntervention from "./intervention/ConfigIntervention"

class CustomDefaultHome extends React.Component {
	ECustomDefault = {
		FOCUS: "CONFIG_FOCUS",
		INTERVENTION: "CONFIG_INTERVENTION",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "CustomDefaultHome",
			tab: this.ECustomDefault.FOCUS,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
	}

	loadTypes(
		func = () => {},
		reqInterceptor = () => {},
		resInterceptor = () => {},
		error = () => {},
		isExcludeInActive = true
	) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.customDefault.getCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.types)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Tab
								eventKey={this.ECustomDefault.FOCUS}
								title={translate(ResourceAssistance.Message.focus)}
								name={this.ECustomDefault.FOCUS}
							>
								{this.state.tab === this.ECustomDefault.FOCUS && <ConfigFocus loadTypes={this.loadTypes} />}
							</Tab>
							<Tab
								eventKey={this.ECustomDefault.INTERVENTION}
								title={translate(ResourceAssistance.Hospitel.intervention)}
								name={this.ECustomDefault.INTERVENTION}
							>
								{this.state.tab === this.ECustomDefault.INTERVENTION && (
									<ConfigIntervention loadTypes={this.loadTypes} />
								)}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDefaultHome)
