const Privilege = {
	Privilege: {
		Management: {
			View: "Management",
			Inventory: {
				View: "Inventory",
			},
			Transfer: {
				View: "Transfer",
				Request: "Transfer_Request",
				Transfer: "Transfer_Transfer",
				Complete: "Transfer_Complete_Request",
			},
			Pr: {
				View: "Purchase_Request",
				Request: "Purchase_Request_Request",
				Inspector: "Purchase_Request_Inspector",
				Approval: "Purchase_Request_Approval",
			},
			Po: {
				View: "Purchase_Order",
				Reject: "Purchase_Order_Reject",
				Inspector: "Purchase_Order_Inspector",
				Approval: "Purchase_Order_Approval",
			},
			Rc: {
				View: "Receive",
				Receive: "Receive_Receive",
				Complete: "Receive_Complete_Order",
			},
		},
		Report: {
			View: "Report",
		},
		Admin: {
			View: "Admin",
		},
		Hospital: {
			View: "Hospital",
			Lab: {
				View: "Laboratory",
				CreateLab: "Laboratory_Create_Lab",
				Nurse: "Laboratory_Nurse",
				Technician: "Laboratory_Technician",
			},
			Deposit: {
				View: "Deposit",
			},
			Cashier: {
				View: "Cashier",
				HealthCarePlanCreateEdit: "Cashier_Health_Care_Plan_Create_Edit",
				BillingPlanCreate: "Cashier_Billing_Plan_Create",
				Discharge: "Cashier_Discharge",
			},
			Hospitel: {
				View: "Hospitel",
				Doctor: "Hospitel_CRUD_Doctor_Order",
				Nurse: "Hospitel_CRUD_Nurse_Order",
				Register: "Hospitel_Assign_Patient",
				Discharge: "Hospitel_Discharge_Patient",
			},
			Registration: {
				View: "Registration",
				CRUD: "Registration_CRUD",
				EXPENSE_EST: "Registration_Expense_Estimation",
			},
			Report: {
				View: "Hospital_Report",
				Cashier: "Cashier_Report",
				Hospitel: "Hospitel_Report",
				Lab: "Laboratory_Report",
			},
			OPD: {
				View: "OPD",
				Doctor: "OPD_CRUD_Doctor_Order",
				Nurse: "OPD_CRUD_Nurse_Order",
			},
			Pharmacy: {
				View: "Pharmacy",
			},
			Front_Inventory: {
				View: "Front_Inventory",
			},
		},
		addAdmin: "add_admin",
		addUser: "add_user",
		createPr: "create_pr",
		approvePrInspector: "approve_pr_inspector",
		approvePrApprover: "approve_pr_approver",
	},
}

export default Privilege
