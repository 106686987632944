import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdCheck, MdOutlineCancel, MdOutlineClear } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	approveAction,
	loadPRsAction,
	onModalDisplayAction,
	selectPRAction,
	selectPrItemAction,
	setPrApproveEvent,
	setPrApproveInd,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_WARNING_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"

class PRRightThirdRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRRightThirdRow",
		}

		this.onApproveClick = this.onApproveClick.bind(this)
		this.onDisapproveClick = this.onDisapproveClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.warning.isContinue !== this.props.warning.isContinue) {
			if (this.props.warning.isContinue) {
				this.props.approve.isApprove ? this.approve(this.props.approve.event) : this.disapprove()
			}
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, false)
		}

		if (prevProps.warning.isClose !== this.props.warning.isClose) {
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, false)
		}

		if (prevProps.isWarningDisplay !== this.props.isWarningDisplay && !this.props.isWarningDisplay) {
			this.props.setPrApproveEvent([])
		}
	}

	onApproveClick = (event) => {
		event.preventDefault()
		if (!this.props.pr.comment && !this.props.pr.approver.requesterApproved) {
			event.persist()
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
			this.props.setWarningMsgAction(
				this.props.intl.formatMessage({
					id: ResourceAssistance.Message.emptyNoteWarning,
				})
			)
			this.props.setPrApproveInd(true)
			this.props.setPrApproveEvent(event.target.value)
		} else {
			return this.approve(event.target.value)
		}
	}

	approve(value) {
		let [approverK, isApproved, approvedDate] = value.split(ResourceAssistance.Symbol.comma)
		this.updatePr(approverK, isApproved, approvedDate)
	}

	onDisapproveClick = (event) => {
		if (this.props.pr.reason.trim() === "") {
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
			this.props.setWarningMsgAction(
				this.props.intl.formatMessage({
					id: ResourceAssistance.Message.emptyDisapproveReasonWarning,
				})
			)
			this.props.setPrApproveInd(false)
		} else {
			this.disapprove()
		}
	}

	disapprove() {
		this.props.disapprove(
			ResourceAssistance.PurchaseRequest.RightPannel.Value.requester,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.isRequesterApproved,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.requesterApprovedDateTime
		)

		this.props.disapprove(
			ResourceAssistance.PurchaseRequest.RightPannel.Value.inspector,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.isInspectorApproved,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.inspectorApprovedDateTime
		)

		this.props.disapprove(
			ResourceAssistance.PurchaseRequest.RightPannel.Value.approver,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.isApproverApproved,
			ResourceAssistance.PurchaseRequest.RightPannel.Value.approverApprovedDateTime
		)

		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.updatePR}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.selectedPR.pr.id,
				userId: this.props.login.user.id,
				label: this.props.selectedPR.pr.label,
				comment: this.props.pr.comment,
				reason: this.props.pr.reason,
			},
		}
		let callback = (res) => {
			this.props.updateOriginalOrders(res)
		}

		let errorHandler = (error) => {}

		axios(params, callback, errorHandler)
	}

	updatePr(approverK, isApproved, approvedDate) {
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.updatePR}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign({}, this.props.selectedPR.pr, {
				[approverK]: this.props.login.user,
				[isApproved]: true,
				[approvedDate]: new Date().getTime(),
				userId: this.props.login.user.id,
				comment: this.props.pr.comment,
				reason: "",
			}),
		}
		let callback = (res) => {
			this.props
				.dispatch(approveAction(approverK, this.props.login.user, isApproved, true, approvedDate, new Date()))
				.then(() => {
					let filteredOrders = this.props.pr.prs.filter((order) => order.id.includes(this.props.pr.searchNum))
					this.props.loadPRsAction(this.props.pr.prs, filteredOrders)
				})
			this.props.updateOriginalOrders(res)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row id={this.state.componentName} className="flex-self-center">
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							onClick={this.onApproveClick}
							value={[
								ResourceAssistance.PurchaseRequest.RightPannel.Value.requester,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.isRequesterApproved,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.requesterApprovedDateTime,
							]}
							disabled={this.props.approver.requesterApproved || this.props.pr.selectedPR.index === -1}
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
						>
							{this.props.approver.requesterApproved ? (
								<MdCheck size={ResourceAssistance.ReactIcon.size} />
							) : (
								<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
							)}
							{translate(ResourceAssistance.Message.requester)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							onClick={this.onApproveClick}
							value={[
								ResourceAssistance.PurchaseRequest.RightPannel.Value.inspector,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.isInspectorApproved,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.inspectorApprovedDateTime,
							]}
							disabled={
								this.props.approver.inspectorApproved ||
								this.props.pr.selectedPR.index === -1 ||
								this.props.pr.prItemsTableData.pendingItems.length > 0 ||
								!this.props.approver.requesterApproved
							}
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
						>
							{this.props.approver.inspectorApproved ? (
								<MdCheck size={ResourceAssistance.ReactIcon.size} />
							) : (
								<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
							)}
							{translate(ResourceAssistance.Message.inspector)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Approval, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							onClick={(event) => {
								this.onApproveClick(event)
							}}
							value={[
								ResourceAssistance.PurchaseRequest.RightPannel.Value.approver,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.isApproverApproved,
								ResourceAssistance.PurchaseRequest.RightPannel.Value.approverApprovedDateTime,
							]}
							disabled={
								!this.props.pr.approver.inspectorApproved ||
								this.props.pr.approver.approverApproved ||
								this.props.pr.selectedPR.index === -1 ||
								this.props.pr.prItemsTableData.pendingItems.length > 0
							}
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
						>
							{this.props.approver.approverApproved ? (
								<MdCheck size={ResourceAssistance.ReactIcon.size} />
							) : (
								<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
							)}
							{translate(ResourceAssistance.Message.approver)}
						</Button>
					</Col>
				)}

				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, this.props.login.user.roles) ||
					Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Approval, this.props.login.user.roles)) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.red}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								!(this.props.pr.selectedPR.index > -1) ||
								!(this.props.pr.approver.requesterApproved || this.props.pr.approver.inspectorApproved) ||
								this.props.pr.prItemsTableData.pendingItems.length === 0 ||
								this.props.isAllApproved()
							}
							onClick={this.onDisapproveClick}
						>
							<MdOutlineCancel size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.unapproved)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	isWarningDisplay: state.modal.isWarningDisplay,
	approver: state.purchaseRequest.approver,
	pr: state.purchaseRequest,
	selectedPR: state.purchaseRequest.selectedPR,
	login: state.login,
	warning: state.modal.warning,
	approve: state.purchaseRequest.approve,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			approveAction,
			loadPRsAction,
			selectPRAction,
			selectPrItemAction,
			onModalDisplayAction,
			setWarningMsgAction,
			setPrApproveEvent,
			setPrApproveInd,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PRRightThirdRow))
