import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OPDModalMedicalRecordMenstruation extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			menstruation: "",
			contraceptive: "",
			menopause: "",
			pregnant: "",
			EDC: "",
			LMP: "",
		}

		this.onMenstruation = this.onMenstruation.bind(this)
		this.onMenstruationNormal = this.onMenstruationNormal.bind(this)
		this.onContraceptive = this.onContraceptive.bind(this)
		this.onMenopause = this.onMenopause.bind(this)
		this.onPregnant = this.onPregnant.bind(this)
		this.onEDC = this.onEDC.bind(this)
		this.onLMP = this.onLMP.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION,
				this.props.opd.selectedMedicalRecord.medicalRecord.menstruation
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.modifyMenstruation()
		}
		if (
			prevProps.opd.modalMedicalRecord.isMenstruationDisplay !== this.props.opd.modalMedicalRecord.isMenstruationDisplay
		) {
			if (this.props.opd.modalMedicalRecord.isMenstruationDisplay) {
				this.setState({
					menstruation: "",
					contraceptive: "",
					menopause: "",
					pregnant: "",
					EDC: "",
					LMP: "",
				})
			} else {
				this.props.setValue(
					SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION,
					this.props.opd.selectedMedicalRecord.medicalRecord.menstruation
				)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION, "")
	}

	modifyMenstruation() {
		let description = ""
		if (!_.isEmpty(this.state.menstruation)) {
			description = description.concat(this.state.menstruation, "\n")
		}
		if (!_.isEmpty(this.state.contraceptive)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.usingContraceptive }),
				": ",
				this.state.contraceptive,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.menopause)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.menopause }),
				": ",
				this.state.menopause,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.pregnant)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.pregnant }),
				": ",
				this.state.pregnant,
				"\t"
			)
		}
		if (!_.isEmpty(this.state.EDC)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.estimatedDateOfConfinement }),
				": ",
				this.state.EDC,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.LMP)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastMenstrualPeriod }),
				": ",
				this.state.LMP,
				"\n"
			)
		}
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION, description)
	}

	onMenstruation(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION, e.target.value)
	}

	onMenstruationNormal(e) {
		this.setState({
			menstruation: e.target.value,
		})
	}

	onContraceptive(e) {
		this.setState({
			contraceptive: e.target.value,
		})
	}

	onMenopause(e) {
		this.setState({
			menopause: e.target.value,
		})
	}

	onPregnant(e) {
		this.setState({
			pregnant: e.target.value,
		})
	}

	onEDC(e) {
		this.setState({
			EDC: e.target.value,
		})
	}

	onLMP(e) {
		this.setState({
			LMP: e.target.value,
		})
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.womenOnly)}</legend>
				{this.props.opd.modalMedicalRecord.isMenstruationDisplay ? (
					<Fragment>
						<Row>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({
										id: ResourceAssistance.Message.menstruation,
									})}
									value={this.state.menstruation}
									onChange={this.onMenstruationNormal}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({
										id: ResourceAssistance.Message.usingContraceptive,
									})}
									value={this.state.contraceptive}
									onChange={this.onContraceptive}
								/>
							</Col>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({
										id: ResourceAssistance.Message.menopause,
									})}
									value={this.state.menopause}
									onChange={this.onMenopause}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.pregnant })}
									value={this.state.pregnant}
									onChange={this.onPregnant}
								/>
							</Col>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({
										id: ResourceAssistance.Message.estimatedDateOfConfinement,
									})}
									value={this.state.EDC}
									onChange={this.onEDC}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastMenstrualPeriod })}
									value={this.state.LMP}
									onChange={this.onLMP}
								/>
							</Col>
						</Row>
					</Fragment>
				) : (
					<Row>
						<Col>
							<GInput
								autoSize
								placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.menstruation })}
								value={this.props.opd.modalMedicalRecord.menstruation}
								onChange={this.onMenstruation}
							/>
						</Col>
					</Row>
				)}
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordMenstruation))
