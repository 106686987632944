import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, onModalElementDisplayAction } from "~/redux/action"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import AbstractTypeView from "./AbstractTypeView"

class ItemType extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	onEditBtnClick(event) {
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)

		this.props.dispatch(onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)).then(() => {
			// let newType = document.getElementById("displayParent")
			// console.log((newType.hidden = false))
		})
	}

	render() {
		return (
			<AbstractTypeView
				scrollableTableData={this.props.type.types}
				onAddBtnClick={this.onAddBtnClick}
				onEditBtnClick={this.onEditBtnClick}
				onRefreshClick={this.props.onRefreshClick}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mpaDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, onModalElementDisplayAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mpaDispatchToProps)(ItemType)
