import { ResourceAssistance } from "~/i18n"
import logoPMG from "~/img/medication_label.jpg"
import { PrintableDataFactory } from "./PrintableDataFactory"

class PharmacyPrintableDataFactory extends PrintableDataFactory {
	static generateOrderLabelData = (patient, order, pharmacy) => {
		return {
			expiredIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.expired }),

			logo: logoPMG,

			pid: patient.id,
			patientName: patient.firstName + " " + patient.lastName,

			description: order.description,
			dose: order.notes,

			lots: pharmacy.items,
			total: pharmacy.amount,
			unit: pharmacy.unit,
		}
	}
}

export default PharmacyPrintableDataFactory
