import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, setOPDDoctorOrders, setSelected } from "~/redux/action"
import { SET_OPD_DOCTOR_INFO_DISPLAY, SET_OPD_SELECTED_DOCTOR_ORDER } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightDoctorOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightDoctorOrder",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onDoubleDoctorOrder = this.onDoubleDoctorOrder.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDDoctorOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders)
		} else {
			this.props.setOPDDoctorOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)
		) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDDoctorOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders)
			} else {
				this.props.setOPDDoctorOrders([])
			}
		}

		if (!_.isEqual(prevProps.opd.doctorOrderTable.filtered, this.props.opd.doctorOrderTable.filtered)) {
			let index = this.props.opd.selectedDoctorOrder.order
				? this.props.opd.doctorOrderTable.filtered.findIndex(
						(doctorOrder) => doctorOrder.id === this.props.opd.selectedDoctorOrder.order.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, index, this.props.opd.doctorOrderTable.filtered[index])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, -1, null)
			}
		}
	}

	onSelectOrder(order, index) {
		this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, index, this.props.opd.doctorOrderTable.filtered[index])
	}

	onDoubleDoctorOrder(row, idx) {
		this.props.onModalDisplayAction(SET_OPD_DOCTOR_INFO_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						data={this.props.opd.doctorOrderTable}
						onClick={this.onSelectOrder}
						onDoubleClick={this.onDoubleDoctorOrder}
						isClearHighlight={this.props.opd.selectedDoctorOrder.index === -1}
						highlightedRow={this.props.opd.selectedDoctorOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOPDDoctorOrders,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDoctorOrder))
