import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import HospitelModalLocationHospitel from "./HospitelModalLocationHospitel"

class HospitelModalLocationDetails extends React.Component {
	render() {
		return (
			<Container fluid>
				<HospitelModalLocationHospitel />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalLocationDetails)
