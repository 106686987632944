import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdminHealthCareTypes,
	setAdminPaymentTypes,
	setAdminTypes,
	setAdminTypeTitile,
	setLoadingAction,
	setSelectedType,
	setTypeUrl,
} from "~/redux/action"
import { SET_GENERAL_TYPE_LOADING } from "~/redux/type/type/ADMIN"
import "./type.css"
import AntigenType from "./view/AntigenType"
import DefaultCategory from "./view/DefaultCategory"
import FallRiskType from "./view/FallRiskType"
import HealthCarePlan from "./view/HealthCarePlan"
import ICDType from "./view/ICDType"
import IntakeType from "./view/IntakeType"
import IsolationPrecaution from "./view/IsolationPrecaution"
import ItemType from "./view/ItemType"
import OutputType from "./view/OutputType"
import PaymentType from "./view/PaymentType"
import PCRType from "./view/PCRType"
import RoleType from "./view/RoleType"
import ServiceType from "./view/ServiceType"

export const EType = {
	ITEM_TYPE: "ITEM_TYPE",
	PAYMENT_TYPE: "PAYMENT_TYPE",
	ROLE_TYPE: "ROLE_TYPE",
	SERVICE_TYPE: "SERVICE_TYPE",
	DEFAULT_TYPE: "DEFAULT_TYPE",
	HEALTH_CARE_TYPE: "HEALTH_CARE_TYPE",
	ICD_TYPE: "ICD_TYPE",
	ISOLATION_PRECAUTION: "ISOLATION_PRECAUTION",
	FALL_RISK: "FALL_RISK",
	INTAKE: "INTAKE",
	OUTPUT: "OUTPUT",
	PCR: "PCR",
	ANTIGEN: "ANTIGEN",
}

class Type extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)

		this.state = {
			componentName: "Type",
			tab: EType.ITEM_TYPE,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadItemTypes = this.loadItemTypes.bind(this)
		this.loadPaymentTypes = this.loadPaymentTypes.bind(this)
		this.loadServiceTypes = this.loadServiceTypes.bind(this)
		this.loadRoleTypes = this.loadRoleTypes.bind(this)
		this.loadDefaultCategories = this.loadDefaultCategories.bind(this)
		this.loadHealthCareTypes = this.loadHealthCareTypes.bind(this)
		this.loadICDTypes = this.loadICDTypes.bind(this)
		this.loadIsolationPrecautions = this.loadIsolationPrecautions.bind(this)
		this.loadFallRisks = this.loadFallRisks.bind(this)
		this.loadIntakes = this.loadIntakes.bind(this)
		this.loadOutputs = this.loadOutputs.bind(this)
		this.loadPCRs = this.loadPCRs.bind(this)
		this.loadAntigens = this.loadAntigens.bind(this)
	}

	componentDidMount() {
		this.props.setAdminTypeTitile(ResourceAssistance.Message.itemType)
		this.props.setTypeUrl(ResourceAssistance.Url.admin.type.item.saveType)
		this.loadItemTypes()
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.type.searchText !== this.props.type.searchText ||
			prevProps.type.displayInactive !== this.props.type.displayInactive
		) {
			this.props.setAdminTypes(this.props.type.types.types)
		}
	}

	onTabClick(event) {
		this.setState({ tab: event })

		switch (event) {
			case EType.ITEM_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.item.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.itemType)
				this.loadItemTypes()
				break

			case EType.PAYMENT_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.payment.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.payment)
				this.loadPaymentTypes()
				break

			case EType.ROLE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.role.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.role)
				this.loadRoleTypes()
				break

			case EType.SERVICE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.service.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.service)
				this.loadServiceTypes()
				break

			case EType.DEFAULT_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.default.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.default)
				this.loadDefaultCategories()
				break

			case EType.HEALTH_CARE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.healthCarePlan.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.healthCarePlan)
				this.loadHealthCareTypes()
				break

			case EType.ICD_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.icd.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.internationalClassificationDiseases)
				this.loadICDTypes()
				break

			case EType.ISOLATION_PRECAUTION:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.isolationPrecaution.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.isolationPrecaution)
				this.loadIsolationPrecautions()
				break

			case EType.FALL_RISK:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.fallRisk.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.fallRisk)
				this.loadFallRisks()
				break

			case EType.INTAKE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.intake.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.intake)
				this.loadIntakes()
				break

			case EType.OUTPUT:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.output.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.output)
				this.loadOutputs()
				break

			case EType.PCR:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.pcr.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.pcr)
				this.loadPCRs()
				break

			case EType.ANTIGEN:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.antigen.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.antigen)
				this.loadAntigens()
				break

			default:
				this.props.setTypeUrl("")
				break
		}
	}

	loadItemTypes() {
		this.load(ResourceAssistance.Url.admin.type.item.loadTypes, this.props.setAdminTypes)
	}

	loadPaymentTypes() {
		this.load(ResourceAssistance.Url.admin.type.payment.loadTypes, this.props.setAdminPaymentTypes)
	}

	loadServiceTypes() {
		this.load(ResourceAssistance.Url.admin.type.service.loadTypes, this.props.setAdminTypes)
	}

	loadRoleTypes() {
		this.load(ResourceAssistance.Url.admin.type.role.loadTypes, this.props.setAdminTypes)
	}

	loadDefaultCategories() {
		this.load(ResourceAssistance.Url.admin.type.default.loadTypes, this.props.setAdminTypes)
	}

	loadHealthCareTypes() {
		this.load(ResourceAssistance.Url.admin.type.healthCarePlan.loadTypes, this.props.setAdminHealthCareTypes)
	}

	loadICDTypes() {
		this.load(ResourceAssistance.Url.admin.type.icd.loadTypes, this.props.setAdminTypes)
	}

	loadIsolationPrecautions() {
		this.load(ResourceAssistance.Url.admin.type.isolationPrecaution.loadTypes, this.props.setAdminTypes)
	}

	loadFallRisks() {
		this.load(ResourceAssistance.Url.admin.type.fallRisk.loadTypes, this.props.setAdminTypes)
	}

	loadIntakes() {
		this.load(ResourceAssistance.Url.admin.type.intake.loadTypes, this.props.setAdminTypes)
	}

	loadOutputs() {
		this.load(ResourceAssistance.Url.admin.type.output.loadTypes, this.props.setAdminTypes)
	}

	loadPCRs() {
		this.load(ResourceAssistance.Url.admin.type.pcr.loadTypes, this.props.setAdminTypes)
	}

	loadAntigens() {
		this.load(ResourceAssistance.Url.admin.type.antigen.loadTypes, this.props.setAdminTypes)
	}

	load(url, func) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setSelectedType(-1, null)
			func(res.data.uts)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			// <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<div className={` ${ResourceAssistance.CSS.fullFlex}`}>
							<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
								<Tab
									eventKey={EType.ANTIGEN}
									title={translate(ResourceAssistance.Hospitel.antigen)}
									name={EType.ANTIGEN}
								>
									{this.state.tab === EType.ANTIGEN && (
										<AntigenType {...this.props} onRefreshClick={this.loadAntigens} />
									)}
								</Tab>
								<Tab
									eventKey={EType.DEFAULT_TYPE}
									title={translate(ResourceAssistance.Message.default)}
									name={EType.DEFAULT_TYPE}
								>
									{this.state.tab === EType.DEFAULT_TYPE && (
										<DefaultCategory {...this.props} onRefreshClick={this.loadDefaultCategories} />
									)}
								</Tab>
								<Tab
									eventKey={EType.FALL_RISK}
									title={translate(ResourceAssistance.Message.fallRisk)}
									name={EType.FALL_RISK}
								>
									{this.state.tab === EType.FALL_RISK && (
										<FallRiskType {...this.props} onRefreshClick={this.loadFallRisks} />
									)}
								</Tab>
								<Tab
									eventKey={EType.HEALTH_CARE_TYPE}
									title={translate(ResourceAssistance.Hospitel.healthCarePlan)}
									name={EType.HEALTH_CARE_TYPE}
								>
									{this.state.tab === EType.HEALTH_CARE_TYPE && (
										<HealthCarePlan {...this.props} onRefreshClick={this.loadHealthCareTypes} />
									)}
								</Tab>
								<Tab
									eventKey={EType.ITEM_TYPE}
									title={translate(ResourceAssistance.Message.inventory)}
									name={EType.ITEM_TYPE}
								>
									{this.state.tab === EType.ITEM_TYPE && (
										<ItemType {...this.props} onRefreshClick={this.loadItemTypes} />
									)}
								</Tab>
								<Tab
									eventKey={EType.ICD_TYPE}
									title={translate(ResourceAssistance.Message.internationalClassificationDiseases)}
									name={EType.ICD_TYPE}
								>
									{this.state.tab === EType.ICD_TYPE && <ICDType {...this.props} onRefreshClick={this.loadICDTypes} />}
								</Tab>
								<Tab
									eventKey={EType.ISOLATION_PRECAUTION}
									title={translate(ResourceAssistance.Message.isolationPrecaution)}
									name={EType.ISOLATION_PRECAUTION}
								>
									{this.state.tab === EType.ISOLATION_PRECAUTION && (
										<IsolationPrecaution {...this.props} onRefreshClick={this.loadIsolationPrecautions} />
									)}
								</Tab>
								<Tab eventKey={EType.INTAKE} title={translate(ResourceAssistance.Message.intake)} name={EType.INTAKE}>
									{this.state.tab === EType.INTAKE && <IntakeType {...this.props} onRefreshClick={this.loadIntakes} />}
								</Tab>
								<Tab eventKey={EType.OUTPUT} title={translate(ResourceAssistance.Message.output)} name={EType.OUTPUT}>
									{this.state.tab === EType.OUTPUT && <OutputType {...this.props} onRefreshClick={this.loadOutputs} />}
								</Tab>
								<Tab
									eventKey={EType.PAYMENT_TYPE}
									title={translate(ResourceAssistance.Message.payment)}
									name={EType.PAYMENT_TYPE}
								>
									{this.state.tab === EType.PAYMENT_TYPE && (
										<PaymentType {...this.props} onRefreshClick={this.loadPaymentTypes} />
									)}
								</Tab>
								<Tab eventKey={EType.PCR} title={translate(ResourceAssistance.Hospitel.pcr)} name={EType.PCR}>
									{this.state.tab === EType.PCR && <PCRType {...this.props} onRefreshClick={this.loadPCRs} />}
								</Tab>
								<Tab
									eventKey={EType.ROLE_TYPE}
									title={translate(ResourceAssistance.Message.role)}
									name={EType.ROLE_TYPE}
								>
									{this.state.tab === EType.ROLE_TYPE && (
										<RoleType {...this.props} onRefreshClick={this.loadRoleTypes} />
									)}
								</Tab>
								<Tab
									eventKey={EType.SERVICE_TYPE}
									title={translate(ResourceAssistance.Hospitel.service)}
									name={EType.SERVICE_TYPE}
								>
									{this.state.tab === EType.SERVICE_TYPE && (
										<ServiceType {...this.props} onRefreshClick={this.loadServiceTypes} />
									)}
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelectedType,
			setTypeUrl,
			setAdminTypes,
			setAdminTypeTitile,
			setAdminHealthCareTypes,
			setAdminPaymentTypes,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Type)
