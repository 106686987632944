import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setCashierModalNursePendingOrders, setValue } from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { IoMdDoneAll } from "react-icons/io"

class CashierModalNurseAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.modalNurse.id)
			) {
				let pendingOrders = this.props.cashier.modalNurse.pendingOrderTable.filtered
				let pendingOrder = pendingOrders.find(
					(each) => each.id === this.props.cashier.modalNurse.selectedOrder.order.id
				)
				let selectedOrder = Object.assign({}, this.props.cashier.modalNurse.selectedOrder.order, {
					charge: Utils.BigNumber(this.props.cashier.modalAdjustment.balance)
						.plus(pendingOrder ? pendingOrder.charge : 0)
						.toFixed(2),
					adjustment: Utils.BigNumber(this.props.cashier.modalAdjustment.amount)
						.plus(pendingOrder ? pendingOrder.adjustment : 0)
						.toFixed(2),
					adjustmentDescription: pendingOrder
						? Utils.trim(this.props.cashier.modalAdjustment.description).concat(
								" ",
								Utils.trim(pendingOrder.adjustmentDescription)
						  )
						: Utils.trim(this.props.cashier.modalAdjustment.description),
				})
				pendingOrders = pendingOrders.filter((each) => each.id !== selectedOrder.id)
				pendingOrders.push(selectedOrder)

				this.props.setCashierModalNursePendingOrders(pendingOrders)
			}
		}
	}

	onDownBtn() {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.modalNurse.id)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY, false)
		this.props.setValue(
			SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
			Utils.BigNumber(this.props.calculateNurseOrderBalance(this.props.cashier.modalNurse.selectedOrder.order))
				.minus(
					Utils.calculatePharmacyReturnOrderBalance(
						this.props.cashier.modalNurse.selectedOrder.order.pharmacyReturnOrders,
						this.props.cashier.modalNurse.selectedOrder.order.pricePerUnit
					)
				)
				.toFixed(2)
		)
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onUpBtn() {
		let pendingOrders = this.props.cashier.modalNurse.pendingOrderTable.filtered.filter(
			(each) => each.id !== this.props.cashier.modalNurse.selectedPendingOrder.order.id
		)
		this.props.setCashierModalNursePendingOrders(pendingOrders)
	}

	onSelectAll() {
		let pendingOrders = this.props.cashier.modalNurse.pendingOrderTable.filtered
		this.props.cashier.modalNurse.orderTable.filtered
			.filter(
				(each) =>
					each.endDateTime <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime() ||
					this.props.cashier.patient.hospitels.every((hospitel) => hospitel.dischargeSummary)
			)
			.forEach((order) => {
				let pendingOrder = pendingOrders.find((each) => each.id === order.id)

				let selectedOrder = Object.assign({}, order, {
					charge: Utils.BigNumber(this.props.calculateNurseOrderBalance(order))
						.plus(pendingOrder ? pendingOrder.charge : 0)
						.minus(Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit))
						.toFixed(2),
					adjustment: pendingOrder ? pendingOrder.adjustment : 0,
					adjustmentDescription: pendingOrder ? Utils.trim(pendingOrder.adjustmentDescription) : "",
				})

				pendingOrders = pendingOrders.filter((each) => each.id !== selectedOrder.id)
				pendingOrders.push(selectedOrder)
			})
		this.props.setCashierModalNursePendingOrders(pendingOrders)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={
								this.props.cashier.modalNurse.selectedOrder.index === -1 ||
								!this.props.cashier.modalNurse.selectedOrder.order.endDateTime ||
								(this.props.cashier.modalNurse.selectedOrder.order.endDateTime >
									Utils.generateDate(0, 0, 0, 23, 59, 59).getTime() &&
									this.props.cashier.patient.hospitels.some((hospitel) => !hospitel.dischargeSummary)) ||
								this.props.cashier.patient.admissions.some(
									(admission) =>
										admission.clinicalRecord &&
										admission.clinicalRecord.medicalRecords.some((mr) => mr.checkOutDateTime === 0)
								)
							}
							onClick={this.onDownBtn}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							disabled={this.props.cashier.modalNurse.orderTable.filtered.length === 0}
							onClick={this.onSelectAll}
						>
							<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.selectAll)}
						</Button>
					</Col>
					<Col />

					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.cashier.modalNurse.selectedPendingOrder.index === -1}
							onClick={this.onUpBtn}
						>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setCashierModalNursePendingOrders,
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalNurseAction)
