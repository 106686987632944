import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import IPDRightIntakeOutputTable from "./IPDRightIntakeOutputTable"
import { setIPDIntakeOutputs } from "~/redux/action"
import _ from "lodash"
import IPDRightIntakeOutputChart from "./IPDRightIntakeOutputChart"

class IPDRightIntakeOutput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightIntakeOutput",
			graphic: false,
		}

		this.onGraphic = this.onGraphic.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDIntakeOutputs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDIntakeOutputs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs)
			} else {
				this.props.setIPDIntakeOutputs([])
			}
		}
	}

	onGraphic(event) {
		this.setState({
			graphic: !this.state.graphic,
		})
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={this.props.ipd.selectedHospitel.index === -1}
							onClick={this.onGraphic}
						>
							{this.state.graphic ? (
								<AiOutlineTable size={ResourceAssistance.ReactIcon.size} />
							) : (
								<AiOutlineLineChart size={ResourceAssistance.ReactIcon.size} />
							)}
							{this.state.graphic
								? translate(ResourceAssistance.Message.table)
								: translate(ResourceAssistance.Message.graph)}
						</Button>
					</Col>
					<Col />
				</Row>
				{this.state.graphic ? <IPDRightIntakeOutputChart /> : <IPDRightIntakeOutputTable />}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDIntakeOutputs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightIntakeOutput))
