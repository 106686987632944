import {
	SET_ADMIN_TYPE_TITLE,
	SET_ADM_NEW_TYPE_DISPLAY_CODE,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN,
	SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT,
	SET_GENERAL_TYPES,
	SET_GENERAL_TYPE_LOADING,
	SET_PR_TYPES,
	SET_SEARCH_TEXT,
	SET_SELECTED_TYPE,
	SET_TYPE_DISPLAY_INACTIVE,
	SET_TYPE_URL,
} from "../../type"
const init = {
	searchText: "",
	displayInactive: true,
	url: "",
	types: {
		header: [],
		types: [],
		filtered: [],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	selectedType: {
		type: null,
		index: -1,
	},
	modal: {
		title: "",
		displayCode: false,
		displaySavingAccount: false,
		displayPrimaryPlan: false,
	},
}

const typeReducer = (state = init, action) => {
	switch (action.type) {
		case SET_GENERAL_TYPE_LOADING:
			return Object.assign({}, state, {
				types: {
					...state.types,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_PR_TYPES:
		case SET_GENERAL_TYPES:
			return Object.assign({}, state, {
				types: {
					...state.types,
					types: action.payload.types,
					filtered: action.payload.filtered,
					header: action.payload.header,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_SEARCH_TEXT:
			return Object.assign({}, state, {
				searchText: action.payload.text,
			})

		case SET_TYPE_DISPLAY_INACTIVE:
			return Object.assign({}, state, {
				displayInactive: action.payload.value,
			})

		case SET_SELECTED_TYPE:
			return Object.assign({}, state, {
				selectedType: {
					...state.selectedType,
					type: action.payload.type,
					index: action.payload.index,
				},
			})

		case SET_TYPE_URL:
			return Object.assign({}, state, {
				url: action.payload.url,
			})

		case SET_ADMIN_TYPE_TITLE:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					title: action.payload.title,
				},
			})

		case SET_ADM_NEW_TYPE_DISPLAY_CODE:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayCode: action.payload.value,
				},
			})

		case SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displaySavingAccount: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayPrimaryPlan: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default typeReducer
