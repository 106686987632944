import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import PORightBillingAddressRow from "./PORightBillingAddressRow"
import PORightFirstRow from "./PORightFirstRow"
import PORightFourthRow from "./PORightFourthRow"
import PORightSecondRow from "./PORightSecondRow"
import PORightThirdRow from "./PORightThirdRow"

class PORight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORight",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.contactInfo)}</legend>
					<PORightFirstRow {...this.props} />
					<PORightBillingAddressRow {...this.props} />
				</fieldset>
				<PORightSecondRow {...this.props} />
				<PORightThirdRow {...this.props} />
				<PORightFourthRow {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PORight)
