export const SET_PO_ADDRESS = "SET_PO_ADDRESS"
export const SET_PO_END_DATE = "SET_PO_END_DATE"
export const SET_PO_LOADING = "SET_PO_LOADING"
export const SET_PO_ORDERS = "SET_PO_ORDERS"
export const SET_PO_SEARCH_NUM = "SET_PO_SEARCH_NUM"
export const SET_PO_SELECTED_PO = "SET_PO_SELECTED_PO"
export const SET_PO_START_DATE = "SET_PO_START_DATE"
export const SET_PO_REJECTED_REASON = "SET_PO_REJECTED_REASON"
export const SET_PO_SUPPLIERS = "SET_PO_SUPPLIERS"
export const SET_PO_SELECTED_SUPPLIER = "SET_PO_SELECTED_SUPPLIER"
export const SET_PO_ITEMS = "SET_PO_ITEMS"
export const SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX = "SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX"
export const SET_PO_EDIT_DISCOUNT = "SET_PO_EDIT_DISCOUNT"
export const SET_PO_EDIT_DONE_IND = "SET_PO_EDIT_DONE_IND"
export const SET_PO_DISPLAY_ALL_IND = "SET_PO_DISPLAY_ALL_IND"
export const SET_PO_MODAL_EDIT_PAYMENTS = "SET_PO_MODAL_EDIT_PAYMENTS"
export const SET_PO_MODAL_EDIT_SELECTED_PAYMENT = "SET_PO_MODAL_EDIT_SELECTED_PAYMENT"
export const SET_PO_MODAL_EDIT_PAYMENT_TERMS = "SET_PO_MODAL_EDIT_PAYMENT_TERMS"
export const SET_PO_MODAL_EDIT_REQUIRED_DATE = "SET_PO_MODAL_EDIT_REQUIRED_DATE"
export const SET_PO_MODAL_EDIT_ROUTING_ID = "SET_PO_MODAL_EDIT_ROUTING_ID"
export const SET_PO_SHIPPING_CONTACT_TEL = "SET_PO_SHIPPING_CONTACT_TEL"
export const SET_PO_BILLING_CONTACT_TEL = "SET_PO_BILLING_CONTACT_TEL"
export const SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL = "SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL"
export const SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL = "SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL"
