import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdCheck, MdLibraryAdd, MdOutlineCancel } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setTfModalRequestPendingItems,
	setTfModalRequestSelectedToBranch,
	setTfModalRequestSelectedToLocation,
	setTfModalRequestSelectedToOrg,
	setTfModalRequestToBranches,
	setTfModalRequestToLocations,
	setTfRequests,
} from "~/redux/action"
import { SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, SET_TF_NEW_REQUEST_DISPLAY, SET_TF_REQUEST_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class TFRightFirstRow extends React.Component {
	constructor(props) {
		super(props)

		this.onApprove = this.onApprove.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onModify = this.onModify.bind(this)
	}

	isSelectedRequestApproved() {
		return this.props.transfer.selectedRequest.request && this.props.transfer.selectedRequest.request.requesterApproved
	}

	onApprove() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.approve,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				requesterApproved: true,
				userId: this.props.login.user.id,
			},
		}

		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onCancel() {
		this.props.onModalDisplayAction(SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, true)
	}

	onModify() {
		let selectedRequest = this.props.transfer.selectedRequest.request

		let orgIdx = this.props.transfer.newRequestModal.toOrgs.findIndex((each) => each.id === selectedRequest.org.id)
		let branchIdx = this.props.transfer.newRequestModal.toOrgs[orgIdx].locations.findIndex(
			(each) => each.id === selectedRequest.branch.id
		)
		let locationIdx = this.props.transfer.newRequestModal.toOrgs[orgIdx].locations[branchIdx].locations.findIndex(
			(each) => each.id === selectedRequest.location.id
		)

		this.props.setTfModalRequestSelectedToOrg(orgIdx, this.props.transfer.newRequestModal.toOrgs[orgIdx])
		this.props.setTfModalRequestToBranches(this.props.transfer.newRequestModal.toOrgs[orgIdx].locations)
		this.props.setTfModalRequestSelectedToBranch(
			branchIdx,
			this.props.transfer.newRequestModal.toOrgs[orgIdx].locations[branchIdx]
		)
		this.props.setTfModalRequestToLocations(
			this.props.transfer.newRequestModal.toOrgs[orgIdx].locations[branchIdx].locations
		)
		this.props.setTfModalRequestSelectedToLocation(
			locationIdx,
			this.props.transfer.newRequestModal.toOrgs[orgIdx].locations[branchIdx].locations[locationIdx]
		)
		this.props.setTfModalRequestPendingItems(this.props.transfer.selectedRequest.request.items)

		this.props.onModalDisplayAction(SET_TF_NEW_REQUEST_DISPLAY, true)
	}

	render() {
		return (
			<Row className="flex-self-center">
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Request, this.props.login.user.roles) && (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.transfer.selectedRequest.index === -1 || this.isSelectedRequestApproved()}
								onClick={this.onModify}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								<span>{translate(ResourceAssistance.Message.edit)}</span>
							</Button>
						</Col>
						<Col sm="auto">
							<div className="vertical"></div>
						</Col>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.transfer.selectedRequest.index === -1 || this.isSelectedRequestApproved()}
								onClick={this.onApprove}
							>
								{this.isSelectedRequestApproved() ? <MdCheck size={ResourceAssistance.ReactIcon.size} /> : ""}
								{translate(ResourceAssistance.Message.request)}
							</Button>
						</Col>
						<Col sm="auto">
							<div className="vertical"></div>
						</Col>
					</>
				)}

				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Request, this.props.login.user.roles) ||
					Utils.hasPrivilege(
						ResourceAssistance.Privilege.Management.Transfer.Transfer,
						this.props.login.user.roles
					)) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.red}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.transfer.selectedRequest.index === -1 ||
								this.props.transfer.selectedRequestItem.index === -1 ||
								this.props.transfer.selectedRequestItem.item.cancelled ||
								!this.isSelectedRequestApproved()
							}
							onClick={this.onCancel}
						>
							<MdOutlineCancel size={ResourceAssistance.ReactIcon.size} />
							<span>{translate(ResourceAssistance.Message.cancel)}</span>
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setTfModalRequestPendingItems,
			setTfModalRequestSelectedToBranch,
			setTfModalRequestSelectedToLocation,
			setTfModalRequestSelectedToOrg,
			setTfModalRequestToBranches,
			setTfModalRequestToLocations,
			setTfRequests,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRightFirstRow)
