import React from "react"
import {
	Badge,
	Button,
	Col,
	Container,
	FormControl,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setRcModalCancelPendingReceives,
	setRcModalCancelReason,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_RC_CANCEL_REASON_DISPLAY, SET_WARNING_DISPLAY } from "~/redux/type/type/MODAL"

class RCModalCancelDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCModalCancelDetail",
		}

		this.onClose = this.onClose.bind(this)
		this.onContinue = this.onContinue.bind(this)
		this.onTextAreaChange = this.onTextAreaChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.warning.isContinue !== this.props.warning.isContinue ||
			prevProps.warning.isClose !== this.props.warning.isClose
		) {
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, false)
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_RC_CANCEL_REASON_DISPLAY, false)
	}

	onClose(event) {
		this.reset()
	}

	onContinue(event) {
		if (!this.props.modalCancel.reason.trim()) {
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
			this.props.setWarningMsgAction(
				this.props.intl.formatMessage({
					id: ResourceAssistance.Message.emptyCancelReason,
				})
			)
			return
		}
		let pendings = this.props.modalCancelPendingReceive.filtered
		let receives = Object.assign([], pendings, [
			...pendings.filter((item) => item.id !== this.props.selectedWaiting.inventory.id),
			{
				...this.props.selectedWaiting.inventory,
				reason: this.props.modalCancel.reason,
			},
		])

		this.props.setRcModalCancelPendingReceives(receives)

		this.reset()
	}

	onTextAreaChange(event) {
		this.props.setRcModalCancelReason(event.target.value)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.lg}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
					<ModalTitle>
						<Badge>{translate(ResourceAssistance.Message.reason)}</Badge>
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<Container fluid>
						<Row>
							<Col>
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows="5"
									onChange={this.onTextAreaChange}
								></FormControl>
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onContinue}>
						{translate(ResourceAssistance.Message.continue)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRCCancelReasonDisplay,
	warning: state.modal.warning,
	modalCancelPendingReceive: state.receive.modalCancelPendingReceive,
	modalCancel: state.receive.modalCancel,
	selectedWaiting: state.receive.selectedWaiting,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{ onModalDisplayAction, setRcModalCancelReason, setRcModalCancelPendingReceives, setWarningMsgAction },
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCModalCancelDetail))
