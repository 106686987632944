import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSelected } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalMedicalCertificateDoctorRow extends React.Component {
	constructor(props) {
		super(props)

		this.onRole = this.onRole.bind(this)
		this.onUser = this.onUser.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			let roleIdx = this.props.opd.modalCertificate.roles.findIndex((role) => {
				return (
					role.users.findIndex(
						(user) =>
							_.isEqual(user.displayName, this.props.opd.selectedCertificate.certificate.doctorName) &&
							_.isEqual(user.licenseNum, this.props.opd.selectedCertificate.certificate.doctorLicense)
					) > -1
				)
			})
			if (roleIdx > -1) {
				let roleDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.role)
				roleDOM.selectedIndex = roleIdx + 1
				this.props.setSelected(
					SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE,
					roleIdx,
					this.props.opd.modalCertificate.roles[roleIdx]
				)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalCertificate.users, this.props.opd.modalCertificate.users)) {
			if (this.props.opd.selectedCertificate.certificate) {
				let userIdx = this.props.opd.modalCertificate.users.findIndex(
					(user) =>
						_.isEqual(user.displayName, this.props.opd.selectedCertificate.certificate.doctorName) &&
						_.isEqual(user.licenseNum, this.props.opd.selectedCertificate.certificate.doctorLicense)
				)
				if (userIdx > -1) {
					let userDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.user)
					userDOM.selectedIndex = userIdx + 1
					this.props.setSelected(
						SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER,
						userIdx,
						this.props.opd.modalCertificate.users[userIdx]
					)
				}
			}
		}
		if (!_.isEqual(prevProps.opd.modalCertificate.selectedRole, this.props.opd.modalCertificate.selectedRole)) {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, -1, null)
			if (this.props.opd.modalCertificate.selectedRole.role) {
				this.props.setObjArray(
					SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS,
					this.props.opd.modalCertificate.selectedRole.role.users
				)
			} else {
				this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS, [])
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE, -1, null)
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, -1, null)
	}

	onRole(event) {
		this.props.setSelected(
			SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE,
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.opd.modalCertificate.roles[event.target.value] : null
		)
	}

	onUser(value) {
		this.props.setSelected(
			SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalCertificate.users[value] : null
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.doctor)}</legend>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.role)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.role}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onRole}
							>
								{Utils.renderOptions(this.props.opd.modalCertificate.roles)}
							</FormControl>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.name)}</InputGroup.Text>
							</InputGroup.Prepend>
							<Select
								id={ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.user}
								showSearch
								status={this.props.opd.modalCertificate.selectedUser.user ? undefined : "error"}
								value={
									this.props.opd.modalCertificate.selectedUser.user
										? this.props.opd.modalCertificate.selectedUser.index
										: undefined
								}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								onChange={this.onUser}
							>
								{Utils.renderSelects(this.props.opd.modalCertificate.users, false)}
							</Select>
						</InputGroup>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setObjArray,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateDoctorRow))
