import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmISModalNISActive, setAdmISModalNISName, setAdmISModalNISSelectedType, setValue } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import { SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD } from "~/redux/type"

class AdmISModalNISDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onName = this.onName.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onKeyword = this.onKeyword.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes(() => {
			if (this.props.itemSource.selectedItem.item) {
				let typeHtml = document.getElementById(ResourceAssistance.ID.ADM.itemSource.modalNIS.type)
				let typeIdx = this.props.itemSource.modalNewItem.types.findIndex(
					(each) => each.id === this.props.itemSource.selectedItem.item.type.id
				)
				if (typeIdx > -1) {
					typeHtml.selectedIndex = typeIdx + 1
					this.props.setAdmISModalNISSelectedType(typeIdx, this.props.itemSource.modalNewItem.types[typeIdx])
				}

				this.props.setAdmISModalNISName(this.props.itemSource.selectedItem.item.displayName)
				this.props.setAdmISModalNISActive(this.props.itemSource.selectedItem.item.active)
				this.props.setValue(
					SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD,
					this.props.itemSource.selectedItem.item.keyword
				)
			}
		})
	}

	componentWillUnmount() {
		this.props.setAdmISModalNISSelectedType(-1, null)
		this.props.setAdmISModalNISName("")
		this.props.setValue(SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD, "")
		this.props.setAdmISModalNISActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.itemSource.modalNewItem.name !== this.props.itemSource.modalNewItem.name ||
			!_.isEqual(prevProps.itemSource.modalNewItem.selectedType, this.props.itemSource.modalNewItem.selectedType)
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.itemSource.modalNIS.name)
		let typeInput = this.props.itemSource.modalNewItem.selectedType.type
		let nameInput = this.props.itemSource.modalNewItem.name.trim().toLowerCase()

		if (
			!nameInput ||
			!typeInput ||
			this.props.itemSource.itemTable.original
				.filter(
					(each) => !this.props.itemSource.selectedItem.item || this.props.itemSource.selectedItem.item.id !== each.id
				)
				.some((each) => {
					return each.displayName.trim().toLowerCase() === nameInput && each.type.id === typeInput.id
				})
		) {
			nameHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
		} else {
			nameHtml.setCustomValidity("")
		}
	}

	onType(event) {
		this.props.setAdmISModalNISSelectedType(
			event.target.value,
			this.props.itemSource.modalNewItem.types[event.target.value]
		)
	}

	onName(event) {
		this.props.setAdmISModalNISName(event.target.value)
	}

	onKeyword(event) {
		this.props.setValue(SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD, event.target.value)
	}

	onActive(event) {
		this.props.setAdmISModalNISActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.type)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.itemSource.modalNIS.type}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.itemSource.modalNewItem.types, true)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.itemSource.modalNIS.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.itemSource.modalNewItem.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.keyword)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							id={ResourceAssistance.ID.ADM.itemSource.modalNIS.keyword}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.itemSource.modalNewItem.keyword}
							onChange={this.onKeyword}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.itemSource.modalNewItem.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	itemSource: state.admin.itemConfig.itemSource,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmISModalNISName,
			setAdmISModalNISSelectedType,
			setAdmISModalNISActive,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmISModalNISDetails))
