import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSearchText, setSelected } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalMedicalCertificateService extends React.Component {
	constructor(props) {
		super(props)

		this.onCategory = this.onCategory.bind(this)
		this.onService = this.onService.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			let categoryDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.category)
			let categoryIdx = this.props.opd.modalCertificate.categories.findIndex(
				(category) => category.id === this.props.opd.selectedCertificate.certificate.service.category.id
			)
			if (categoryIdx > -1) {
				categoryDOM.selectedIndex = categoryIdx + 1
				this.props.setSelected(
					SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
					categoryIdx,
					this.props.opd.modalCertificate.categories[categoryIdx]
				)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalCertificate.services, this.props.opd.modalCertificate.services)) {
			if (this.props.opd.selectedCertificate.certificate) {
				let serviceIdx = this.props.opd.modalCertificate.services.findIndex(
					(service) => service.id === this.props.opd.selectedCertificate.certificate.service.id
				)
				if (serviceIdx > -1) {
					this.props.setSelected(
						SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
						serviceIdx,
						this.props.opd.modalCertificate.services[serviceIdx]
					)
				}
			}
		}
		if (
			prevProps.opd.modalCertificate.selectedCategory.index !== this.props.opd.modalCertificate.selectedCategory.index
		) {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE, -1, null)
			if (this.props.opd.modalCertificate.selectedCategory.category) {
				this.props.setObjArray(
					SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES,
					this.props.opd.modalCertificate.selectedCategory.category.services
				)
			} else {
				this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES, [])
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, -1, null)
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE, -1, null)
	}

	onCategory(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
				event.target.value,
				this.props.opd.modalCertificate.categories[event.target.value]
			)
		} else {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, -1, null)
		}
	}

	onService(value, option) {
		this.props.setSelected(
			SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalCertificate.services[value] : null
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.service)}</legend>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.category)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.category}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onCategory}
							>
								{Utils.renderOptions(this.props.opd.modalCertificate.categories)}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.service)}</InputGroup.Text>
							</InputGroup.Prepend>
							<Select
								status={this.props.opd.modalCertificate.selectedService.service ? undefined : "error"}
								id={ResourceAssistance.ID.HOSPITAL.opd.modalCertificate.service}
								showSearch
								value={
									this.props.opd.modalCertificate.selectedService.service
										? this.props.opd.modalCertificate.selectedService.index
										: undefined
								}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								onChange={this.onService}
							>
								{Utils.renderSelects(this.props.opd.modalCertificate.services, false, "", "name")}
							</Select>
						</InputGroup>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			setObjArray,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateService)
