import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setHospitelModalDoctorOrderPendingItems, setSelected, setValue } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class HospitelModalDoctorOrderFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onDescription = this.onDescription.bind(this)
		this.onType = this.onType.bind(this)
		this.onSaveAll = this.onSaveAll.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.hospitel.modalDoctorOrder.types)) {
			this.props.setSelected(
				SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
				0,
				this.props.hospitel.modalDoctorOrder.types[0]
			)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION, "")
	}

	onDescription(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION, Utils.trim(event.target.value))
	}

	onType(event) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
			event.target.value,
			this.props.hospitel.modalDoctorOrder.types[event.target.value]
		)
	}

	onSaveAll(event) {
		window.saveAllCaller = this
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY, true)
	}

	saveAllCallback() {
		let selectedItems = this.props.hospitel.modalDoctorOrder.itemTable.filtered.map((item) => {
			return Object.assign({}, item, {
				startDateTime: this.props.saveAll.startDateTime,
				endDateTime: this.props.saveAll.isDisableEndDate
					? Utils.generateDateFromLong(this.props.saveAll.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
					: this.props.saveAll.endDateTime,
				notes: Utils.trim(item.medicationUsage),
				categoryName: this.props.hospitel.modalDoctorOrder.selectedType.type.description,
			})
		})

		let items = this.props.hospitel.modalDoctorOrder.pendingItemTable.original
		items = items.concat(selectedItems)
		this.props.setHospitelModalDoctorOrderPendingItems(items)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<FormControl
							id={ResourceAssistance.ID.ADM.service.type}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.hospitel.modalDoctorOrder.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.genericName]}
							callbacks={[this.onDescription]}
						/>
					</Col>
					{/* <Col md="auto">
						<div className="vertical"></div>
					</Col>
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							disabled={this.props.hospitel.modalDoctorOrder.itemTable.filtered.length <= 1}
							onClick={this.onSaveAll}
						>
							<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.saveAll)}
						</Button>
					</Col> */}
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	saveAll: state.modal.hospital.saveAll,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
			onModalDisplayAction,
			setHospitelModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderFilter))
