import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER,
	SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class RegisterModalClinicalRecordHealthCare extends React.Component {
	HEALTH_CARE = "HEALTH_CARE"
	EHealthCare = {
		HEALTH_CARE: "HEALTH_CARE",
		OTHER: "OTHER",
	}

	constructor(props) {
		super(props)

		this.state = {
			healthcareRadio: this.EHealthCare.HEALTH_CARE,
		}

		this.onHealthCare = this.onHealthCare.bind(this)
		this.onHealthCareRadio = this.onHealthCareRadio.bind(this)
		this.onHealthCareOther = this.onHealthCareOther.bind(this)
	}

	componentDidMount() {
		this.props.loadHealthCares()
		if (
			this.props.ipd.selectedAdmission.admission &&
			!_.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther)
		) {
			this.setState({
				healthcareRadio: this.EHealthCare.OTHER,
			})
			this.props.setValue(
				SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER,
				this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.healthcareRadio, this.state.healthcareRadio)) {
			if (_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)) {
				this.props.setSelected(SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE, -1, null)
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, "")
			}
		}

		if (!_.isEqual(prevProps.ipd.modalClinicalRecord.healthcares, this.props.ipd.modalClinicalRecord.healthcares)) {
			if (
				!_.isEmpty(this.props.ipd.modalClinicalRecord.healthcares) &&
				this.props.ipd.selectedAdmission.admission &&
				_.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther)
			) {
				let idx = this.props.ipd.modalClinicalRecord.healthcares.findIndex(
					(healthcare) =>
						healthcare.code === this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareCode &&
						healthcare.displayName === this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareName &&
						healthcare.description === this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareDescription
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE,
						idx,
						this.props.ipd.modalClinicalRecord.healthcares[idx]
					)
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES, [])
		this.props.setSelected(SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE, -1, null)
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, "")
	}

	onHealthCareRadio(event) {
		this.setState({
			healthcareRadio: event.target.value,
		})
	}

	onHealthCare(value) {
		this.props.setSelected(
			SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.ipd.modalClinicalRecord.healthcares[value] : null
		)
	}

	onHealthCareOther(event) {
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
				<Row noGutters>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.HEALTH_CARE}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<Select
							id={ResourceAssistance.ID.HOSPITAL.registration.modalClinicalRecord.healthcare}
							showSearch
							placeholder={translate(ResourceAssistance.Hospitel.healthCare)}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							status={!this.props.ipd.modalClinicalRecord.selectedHealthCare.healthcare ? "error" : undefined}
							value={
								this.props.ipd.modalClinicalRecord.selectedHealthCare.healthcare
									? this.props.ipd.modalClinicalRecord.selectedHealthCare.index
									: undefined
							}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={this.onHealthCare}
						>
							{Utils.renderSelects(this.props.ipd.modalClinicalRecord.healthcares, false)}
						</Select>
					</Col>
				</Row>
				<Row noGutters>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.OTHER}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.other })}...`}
							rows={6}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							value={this.props.ipd.modalClinicalRecord.healthcareOther}
							onChange={this.onHealthCareOther}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setObjArray,
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegisterModalClinicalRecordHealthCare))
