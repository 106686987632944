import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setTfModalRequestItems, setTfModalRequestToOrgs, setTfTypes } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import TFLeft from "./tf_left/TFLeft"
import TFRight from "./tf_right/TFRight"
import "./transfer.css"

class Transfer extends React.Component {
	constructor(props) {
		super(props)

		this.loadOrgs = this.loadOrgs.bind(this)
		this.print = this.print.bind(this)
	}

	componentDidMount() {
		this.loadTypes()
		this.loadItems()
		this.loadOrgs(
			ResourceAssistance.Url.transfer.getGeneralLocations,
			(res) => {
				this.props.setTfModalRequestToOrgs(res.data.locations)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
		)
	}

	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.transfer.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setTfTypes(res.data.uts)
		}
		let reqInterceptor = () => {
			// this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, true)
		}
		let resInterceptor = () => {
			// this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	loadItems() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.transfer.getItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setTfModalRequestItems(res.data.items)
		}
		let reqInterceptor = () => {
			// this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, true)
		}
		let resInterceptor = () => {
			// this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	loadOrgs(
		url,
		callback = (res) => {},
		errorFunc = (error) => {},
		reqInterceptor = () => {},
		resInterceptor = () => {},
		data = {}
	) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: data,
		}
		axios(params, callback, errorFunc, reqInterceptor, resInterceptor)
	}

	print(transfer) {
		const target = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.transfer,
				defaultMessage: "",
			}),
			requestFromIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.requestFrom,
				defaultMessage: "",
			}),
			transferFromIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.transferFrom,
				defaultMessage: "",
			}),
			requestIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.requestId,
				defaultMessage: "",
			}),
			transferIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.transferId,
				defaultMessage: "",
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
				defaultMessage: "",
			}),
			lotIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.lot,
				defaultMessage: "",
			}),
			descriptionIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.description,
				defaultMessage: "",
			}),
			brandIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.brandName,
				defaultMessage: "",
			}),
			qtyIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.qty,
				defaultMessage: "",
			}),
			unitIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unit,
				defaultMessage: "",
			}),
			cancelledIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.cancelled,
				defaultMessage: "",
			}),
			remarkIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.remark,
				defaultMessage: "",
			}),
			issuedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.issuedBy,
				defaultMessage: "",
			}),
			certifiedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certifiedBy,
				defaultMessage: "",
			}),

			name: transfer.location.parent.displayName,
			address: transfer.location.address.displayName,
			tel: transfer.location.address.tel,
			fax: transfer.location.address.fax,
			taxId: transfer.location.parent.taxNo,

			requestOrg: this.props.transfer.selectedRequest.request.org.displayName,
			requestBranch: this.props.transfer.selectedRequest.request.branch.displayName,
			requestLocation: this.props.transfer.selectedRequest.request.location.displayName,
			requestAddress: this.props.transfer.selectedRequest.request.branch.address.displayName,
			requestTel: this.props.transfer.selectedRequest.request.branch.address.tel,

			transferOrg: transfer.location.parent.displayName,
			transferBranch: transfer.location.displayName,
			transferAddress: transfer.location.address.displayName,
			transferTel: transfer.location.address.tel,

			requestId: this.props.transfer.selectedRequest.request.id,

			transferId: transfer.id,

			items: transfer.items,

			issuedByLastName: transfer.approval.lastName,
			issuedByFirstName: transfer.approval.firstName,
			issuedDate: Utils.formatDate(transfer.lastModifiedDateTime),

			certifiedByLastName: this.props.transfer.selectedRequest.request.requester.lastName,
			certifiedByFirstName: this.props.transfer.selectedRequest.request.requester.firstName,
			certifiedDate: Utils.formatDate(this.props.transfer.selectedRequest.request.requesterApprovedDateTime),
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Tf.tf, "_blank")
		myWindow.data = target
	}

	render() {
		return (
			<Container fluid className="full-flex">
				<Row noGutters className="full-size">
					<Col md="auto">
						<TFLeft print={this.print} />
					</Col>
					<Col>
						<TFRight loadOrgs={this.loadOrgs} print={this.print} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfTypes, setTfModalRequestItems, setTfModalRequestToOrgs, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Transfer))
