import _ from "lodash"
import React from "react"
import {
	Badge,
	Button,
	Col,
	Form,
	FormCheck,
	FormGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setBoolean, setLoadingAction, setOPDPatients } from "~/redux/action"
import {
	SET_OPD_MEDICAL_RECORD_DISPLAY,
	SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN,
	SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OPDModalMedicalRecordDetails from "./OPDModalMedicalRecordDetails"

class OPDModalMedicalRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalMedicalRecord",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onAdmissionPlan = this.onAdmissionPlan.bind(this)
		this.onMenstruation = this.onMenstruation.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)
		) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setBoolean(
					SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN,
					!_.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.admissionPlan)
				)
			}
		}
	}

	reset() {
		this.props.setBoolean(SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN, false)
		this.props.setBoolean(SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION, false)
		this.props.onModalDisplayAction(SET_OPD_MEDICAL_RECORD_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.opd.selectedMedicalRecord.medicalRecord) {
			return false
		}

		let medicalRecord = this.props.opd.modalMedicalRecord
		let target = Object.assign({}, this.props.opd.selectedMedicalRecord.medicalRecord, {
			riskOfAbuse: Utils.trim(medicalRecord.riskOfAbuse),
			ga: Utils.trim(medicalRecord.GA),
			heent: Utils.trim(medicalRecord.HEENT.appearance),
			heentNote: Utils.trim(medicalRecord.HEENT.note),
			heart: Utils.trim(medicalRecord.heart.appearance),
			heartNote: Utils.trim(medicalRecord.heart.note),
			lung: Utils.trim(medicalRecord.lung.appearance),
			lungNote: Utils.trim(medicalRecord.lung.note),
			abdomen: Utils.trim(medicalRecord.abdomen.appearance),
			abdomenNote: Utils.trim(medicalRecord.abdomen.note),
			back: Utils.trim(medicalRecord.back.appearance),
			backNote: Utils.trim(medicalRecord.back.note),
			extremities: Utils.trim(medicalRecord.extremities.appearance),
			extremitiesNote: Utils.trim(medicalRecord.extremities.note),
			neuro: Utils.trim(medicalRecord.neuro.appearance),
			neuroNote: Utils.trim(medicalRecord.neuro.note),
			physicalExaminationOthers: Utils.trim(medicalRecord.physicalExaminationOthers),
			menstruation: Utils.trim(this.props.opd.modalMedicalRecord.menstruation),

			cc: Utils.trim(medicalRecord.CC),
			pi: Utils.trim(medicalRecord.PI),
			diagnosis: Utils.trim(medicalRecord.diagnosis),
			treatment: Utils.trim(medicalRecord.treatment),

			// Clinical Record
			clinicalRecord: {
				...this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord,
				admissionPlan: Utils.trim(medicalRecord.admissionPlan),
			},
		})

		let targetPatient = Object.assign({}, this.props.opd.selectedPatient.patient, {
			height: Number(this.props.opd.modalMedicalRecord.height),
			weight: Number(this.props.opd.modalMedicalRecord.weight),
			underlyingDisease: Utils.trim(this.props.opd.modalMedicalRecord.underlyingDisease),
			allergies: Utils.trim(this.props.opd.modalMedicalRecord.allergies),
			pastIllnesses: Utils.trim(this.props.opd.modalMedicalRecord.pastIllnesses),
			pastSurgicalHistory: Utils.trim(this.props.opd.modalMedicalRecord.pastSurgicalHistory),
			familyHealthHistory: Utils.trim(this.props.opd.modalMedicalRecord.familyHealthHistory),
			accidentHistory: Utils.trim(this.props.opd.modalMedicalRecord.accidentHistory),
			medicineHistory: Utils.trim(this.props.opd.modalMedicalRecord.medicineHistory),
			supplementOrHerbsHistory: Utils.trim(this.props.opd.modalMedicalRecord.supplementOrHerbsHistory),
			alcoholHistory: Utils.trim(this.props.opd.modalMedicalRecord.alcoholHistory),
			tobaccoHistory: Utils.trim(this.props.opd.modalMedicalRecord.tobaccoHistory),
			passiveSmokeExposure: Utils.trim(this.props.opd.modalMedicalRecord.passiveSmokeExposure),
			doctorNote: Utils.trim(this.props.opd.modalMedicalRecord.doctorNote),
		})

		return (
			_.isEqual(this.props.opd.selectedMedicalRecord.medicalRecord, target) &&
			_.isEqual(this.props.opd.selectedPatient.patient, targetPatient)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let medicalRecord = this.props.opd.modalMedicalRecord

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.updateMedicalRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				medicalRecordId: this.props.opd.selectedMedicalRecord.medicalRecord.id,
				userId: this.props.login.user.id,
				// PE
				riskOfAbuse: Utils.trim(medicalRecord.riskOfAbuse),
				ga: Utils.trim(medicalRecord.GA),
				heent: Utils.trim(medicalRecord.HEENT.appearance),
				heentNote: Utils.trim(medicalRecord.HEENT.note),
				heart: Utils.trim(medicalRecord.heart.appearance),
				heartNote: Utils.trim(medicalRecord.heart.note),
				lung: Utils.trim(medicalRecord.lung.appearance),
				lungNote: Utils.trim(medicalRecord.lung.note),
				abdomen: Utils.trim(medicalRecord.abdomen.appearance),
				abdomenNote: Utils.trim(medicalRecord.abdomen.note),
				back: Utils.trim(medicalRecord.back.appearance),
				backNote: Utils.trim(medicalRecord.back.note),
				extremities: Utils.trim(medicalRecord.extremities.appearance),
				extremitiesNote: Utils.trim(medicalRecord.extremities.note),
				neuro: Utils.trim(medicalRecord.neuro.appearance),
				neuroNote: Utils.trim(medicalRecord.neuro.note),
				physicalExaminationOthers: Utils.trim(medicalRecord.physicalExaminationOthers),
				menstruation: Utils.trim(this.props.opd.modalMedicalRecord.menstruation),

				// Doctor Consultation
				cc: Utils.trim(medicalRecord.CC),
				pi: Utils.trim(medicalRecord.PI),
				diagnosis: Utils.trim(medicalRecord.diagnosis),
				treatment: Utils.trim(medicalRecord.treatment),
				// Admission
				admissionPlan: Utils.trim(medicalRecord.admissionPlan),
				deleteAdmissionPlan: _.isEmpty(medicalRecord.admissionPlan),

				patients: [
					{
						id: this.props.opd.selectedPatient.patient.id,
						height: this.props.opd.modalMedicalRecord.height,
						weight: this.props.opd.modalMedicalRecord.weight,
						underlyingDisease: Utils.trim(this.props.opd.modalMedicalRecord.underlyingDisease),
						allergies: Utils.trim(this.props.opd.modalMedicalRecord.allergies),
						pastIllnesses: Utils.trim(this.props.opd.modalMedicalRecord.pastIllnesses),
						pastSurgicalHistory: Utils.trim(this.props.opd.modalMedicalRecord.pastSurgicalHistory),
						familyHealthHistory: Utils.trim(this.props.opd.modalMedicalRecord.familyHealthHistory),
						accidentHistory: Utils.trim(this.props.opd.modalMedicalRecord.accidentHistory),
						medicineHistory: Utils.trim(this.props.opd.modalMedicalRecord.medicineHistory),
						supplementOrHerbsHistory: Utils.trim(this.props.opd.modalMedicalRecord.supplementOrHerbsHistory),
						alcoholHistory: Utils.trim(this.props.opd.modalMedicalRecord.alcoholHistory),
						tobaccoHistory: Utils.trim(this.props.opd.modalMedicalRecord.tobaccoHistory),
						passiveSmokeExposure: Utils.trim(this.props.opd.modalMedicalRecord.passiveSmokeExposure),
						menstruation: Utils.trim(this.props.opd.modalMedicalRecord.menstruation),
						doctorNote: Utils.trim(this.props.opd.modalMedicalRecord.doctorNote),
					},
				],
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onAdmissionPlan(event) {
		this.props.setBoolean(SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN, event.target.checked)
	}

	onMenstruation(e) {
		this.props.setBoolean(SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION, e.target.checked)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.xl}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.medicalRecord)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalMedicalRecordDetails />
					</ModalBody>

					<ModalFooter>
						<FormGroup controlId={"admission-plan"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.admissionPlan)}
								checked={this.props.opd.modalMedicalRecord.isAdmissionPlanDisplay}
								onChange={this.onAdmissionPlan}
							/>
						</FormGroup>
						<FormGroup controlId={"menstruation"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.menstruation)}
								checked={this.props.opd.modalMedicalRecord.isMenstruationDisplay}
								onChange={this.onMenstruation}
							/>
						</FormGroup>
						<Col />
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientMedicalRecordDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
			setBoolean,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecord)
