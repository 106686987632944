export const setLoadingAction = (type, isLoading) => {
	return {
		type: type,
		payload: {
			isLoading: isLoading,
		},
	}
}

export const setModalDoneIndAction = (type, isDone, callback) => {
	return {
		type: type,
		payload: {
			isDone: isDone,
			callback: callback,
		},
	}
}

export const setDateAction = (type, date) => {
	return {
		type: type,
		payload: {
			date: date,
		},
	}
}

export const setSearchText = (type, text) => {
	return {
		type: type,
		payload: {
			text: text !== undefined ? text : "",
		},
	}
}

export const setValue = (type, value) => {
	return (dispatch, getState) => {
		dispatch({
			type: type,
			payload: {
				value: value !== undefined ? value : "",
			},
		})
		return Promise.resolve()
	}
}

export const setSelected = (type, index, selected) => {
	return (dispatch, getState) => {
		dispatch({
			type: type,
			payload: {
				index: index,
				selected: selected,
			},
		})
		return Promise.resolve()
	}
}

export const setObjArray = (type, objs) => {
	return {
		type: type,
		payload: {
			objs: objs,
		},
	}
}

export const setBoolean = (type, value) => {
	return {
		type: type,
		payload: {
			value: value,
		},
	}
}
