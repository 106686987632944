import _ from "lodash"
import React from "react"
import { Col, FormControl, FormLabel, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setTfModalRequestItems,
	setTfModalRequestPendingItems,
	setTfModalRequestPendingSelectedItem,
	setTfModalRequestSelectedDueDate,
	setTfModalRequestSelectedItem,
	setTfModalRequestSelectedItemAmount,
} from "~/redux/action"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFModalNRItemRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAmountChange = this.onAmountChange.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onDueDateChange = this.onDueDateChange.bind(this)
	}

	componentDidMount() {
		this.props.setTfModalRequestSelectedDueDate(Utils.generateDate().getTime())
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.transfer.newRequestModal.selectedType) !==
				JSON.stringify(this.props.transfer.newRequestModal.selectedType) ||
			prevProps.transfer.newRequestModal.searchText !== this.props.transfer.newRequestModal.searchText
		) {
			this.props.setTfModalRequestItems(this.props.transfer.newRequestModal.itemTable.original)
			this.props.setTfModalRequestSelectedItem(-1, null)
			this.props.setTfModalRequestSelectedItemAmount(0)
		}

		if (
			!_.isEqual(
				prevProps.transfer.newRequestModal.pendingItemTable.filtered,
				this.props.transfer.newRequestModal.pendingItemTable.filtered
			)
		) {
			this.props.setTfModalRequestPendingSelectedItem(-1, null)
		}
	}

	//reset
	componentWillUnmount() {
		this.props.setTfModalRequestSelectedItem(-1, null)
		this.props.setTfModalRequestSelectedItemAmount(0)
		this.props.setTfModalRequestPendingItems([])
		this.props.setTfModalRequestSelectedDueDate(null)
	}

	onSelectRow(row, index) {
		this.props.setTfModalRequestSelectedItem(index, this.props.transfer.newRequestModal.itemTable.filtered[index])
	}

	onAmountChange(event) {
		this.props.setTfModalRequestSelectedItemAmount(
			event.target.value ? Utils.BigNumber(event.target.value).toNumber() : 0
		)
	}

	onDownBtn() {
		if (
			this.props.transfer.newRequestModal.selectedItem.index === -1 ||
			this.props.transfer.newRequestModal.amount === 0
		) {
			return
		}

		let selectedSupplier = Object.assign({}, this.props.transfer.newRequestModal.selectedItem.item, {
			type: {
				id: this.props.transfer.newRequestModal.selectedType.type.id,
			},
			amount: this.props.transfer.newRequestModal.amount,
			dueDateTime: this.props.transfer.newRequestModal.selectedDueDate
				? this.props.transfer.newRequestModal.selectedDueDate
				: Utils.generateDate().getTime(),
		})

		let pendingItems = Utils.converArrayToObjectByCustomKey(
			this.props.transfer.newRequestModal.pendingItemTable.filtered,
			["code", "displayName", "name", "brand", "unit"]
		)

		let key =
			selectedSupplier.code.toLowerCase().trim() +
			selectedSupplier.displayName.toLowerCase().trim() +
			selectedSupplier.brand.toLowerCase().trim() +
			selectedSupplier.unit.toLowerCase().trim()

		let id = pendingItems[key] ? pendingItems[key].id : 0

		let items = Object.assign({}, pendingItems, {
			[key]: {
				id: id,
				type: selectedSupplier.type,
				name: selectedSupplier.displayName,
				brand: selectedSupplier.brand,
				amount: selectedSupplier.amount,
				unit: selectedSupplier.unit,
				code: selectedSupplier.code,
				dueDateTime: selectedSupplier.dueDateTime,
			},
		})
		this.props.setTfModalRequestPendingItems(Object.values(items))
	}

	onUpBtn() {
		if (this.props.transfer.newRequestModal.selectedPendingItem.index === -1) {
			return
		}

		let pendingItems = this.props.transfer.newRequestModal.pendingItemTable.filtered.filter(
			(item) => item.id !== this.props.transfer.newRequestModal.selectedPendingItem.item.id
		)

		this.props.setTfModalRequestPendingItems(pendingItems)
	}

	onDueDateChange(date) {
		this.props.setTfModalRequestSelectedDueDate(date.getTime())
	}

	render() {
		return (
			<Row noGutters className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.labelInfoRow}`}>
				<Col id={ResourceAssistance.ID.TF.modalRequest.tableItem}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableHighlight
						data={this.props.transfer.newRequestModal.itemTable}
						isClearHighlight={this.props.transfer.newRequestModal.selectedItem.index === -1}
						onClick={this.onSelectRow}
					/>
					{this.props.transfer.newRequestModal.itemTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
					<Row>
						<Col
							className={
								this.props.transfer.newRequestModal.selectedItem.index === -1 ||
								this.props.transfer.newRequestModal.amount === 0
									? ResourceAssistance.CSS.disabled
									: ""
							}
						>
							<FcDownload size={"3rem"} onClick={this.onDownBtn} style={{ alignSelf: "center" }} />
						</Col>
						<Col
							className={
								this.props.transfer.newRequestModal.selectedPendingItem.index === -1
									? ResourceAssistance.CSS.disabled
									: ""
							}
						>
							<FcUpload size={"3rem"} onClick={this.onUpBtn} style={{ alignSelf: "center" }} />
						</Col>
					</Row>
				</Col>
				<Col md="auto">
					<Row>
						<Col>
							<FormLabel className={ResourceAssistance.CSS.flexSelfCenter}>
								{translate(ResourceAssistance.Message.amount)}
							</FormLabel>
							<FormControl
								required
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.number}
								min={1}
								disabled={this.props.transfer.newRequestModal.selectedItem.index === -1}
								onChange={this.onAmountChange}
							></FormControl>
						</Col>
					</Row>
					<Row>
						<Col></Col>
					</Row>
					<Row>
						<Col>
							<FormLabel className={ResourceAssistance.CSS.flexSelfCenter}>
								{translate(ResourceAssistance.Message.dueDate)}
							</FormLabel>
							<DatePicker
								className={ResourceAssistance.CSS.flexSelfCenter}
								disabled={this.props.transfer.newRequestModal.selectedItem.index === -1}
								startDate={Utils.generateDateFromLong(this.props.transfer.newRequestModal.selectedDueDate)}
								isShowTime={false}
								onChange={this.onDueDateChange}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfModalRequestItems,
			setTfModalRequestPendingItems,
			setTfModalRequestPendingSelectedItem,
			setTfModalRequestSelectedDueDate,
			setTfModalRequestSelectedItem,
			setTfModalRequestSelectedItemAmount,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNRItemRow)
