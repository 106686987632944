import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { IoHourglassOutline } from "react-icons/io5"
import { GrRotateLeft } from "react-icons/gr"
import { MdFileDownload } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import { Utils } from "~/utils/Utils"
import ReportLabCovid19LabFilterDetails from "./ReportLabCovid19LabFilterDetails"

class ReportLabCovid19LabFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportLabCovid19LabFilter",
			downloadDisabled: true,
		}

		this.ref = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
		this.onDownload = this.onDownload.bind(this)
	}

	componentWillUnmount() {
		this.setState({
			downloadDisabled: true,
		})
	}

	runReport(data) {
		let headers = [
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.nameSurname }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.gender }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.healthCare }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Message.address }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Message.telNo }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.labNumber }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.result }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.analyte }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.nGene }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.sGene }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.oGene }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.eGene }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.antigenCOI }),
			this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.googleDrive }),
		]
		let excel = {
			filename:
				Utils.formatDateTime(this.props.filter.startDateTime) +
				"_" +
				Utils.formatDateTime(this.props.filter.endDateTime),
			sheets: [
				{
					name: "Covid-19",
					headers: headers,
					data: data.map((lab, index) => {
						let body = [
							lab.patient.id,
							lab.patient.identificationNumber,
							lab.patient.firstName + " " + lab.patient.lastName,
							Utils.formatDate(lab.patient.dobtime),
							Utils.calculateAge(lab.patient.dobtime),
							lab.patient.gender,
							lab.patient.address,
							lab.patient.tel,
							lab.id,
							lab.detail.result,
							lab.detail.analyte,
							lab.detail.ngene,
							lab.detail.sgene,
							lab.detail.ogene,
							lab.detail.egene,
							lab.detail.antigenCOI,
							lab.url,
						]
						return _.zipObject(Object.keys(Object.assign({}, headers)), body)
					}),
				},
			],
		}

		this.ref.current.uodateExcel(excel)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.lab.getLabs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				includePCR: this.props.filter.includePCR,
				includeAntigen: this.props.filter.includeAntigen,
				includePositive: this.props.filter.includePositive,
				includeNegative: this.props.filter.includeNegative,
			},
		}
		let callback = (res) => {
			this.setState({
				downloadDisabled: _.isEmpty(res.data.labs),
			})
			this.runReport(res.data.labs)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	onDownload() {
		// console.log("start")
		// this.props.setLoadingAction(SET_PAGE_LOADING, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Hospitel.covid19Lab)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportLabCovid19LabFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
						<ExcelDocument
							ref={this.ref}
							element={
								<Button
									variant={ResourceAssistance.Button.variant.info}
									disabled={this.state.downloadDisabled}
									// onClick={this.onDownload} // this will be triggered after download complete
									// onPointerOut={(event) => {
									// console.log("end")
									// this.props.setLoadingAction(SET_PAGE_LOADING, false)
									// }}
								>
									<MdFileDownload size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Hospitel.download)}
								</Button>
							}
						/>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.hospital.lab.isCovid19Display,
	filter: state.report.lab.covid19,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

// export default React.forwardRef((props, ref) => <ReportLabCovid19LabFilter innerRef={ref} {...props} />)
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportLabCovid19LabFilter))
