import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setNotificationPatients } from "~/redux/action"
import { SET_NOTIFICATION_SELECTED_PATIENT } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class NotificationSearchResult extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentWillUnmount() {
		this.props.setNotificationPatients([])
		this.props.setSelected(SET_NOTIFICATION_SELECTED_PATIENT, -1, null)
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_NOTIFICATION_SELECTED_PATIENT,
			index,
			this.props.notification.modalSearch.patientTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col style={{ minHeight: "185px", maxHeight: "185px" }}>
					<ScrollableTable
						enableHighlight
						striped
						bordered
						hover
						isClearHighlight={this.props.notification.modalSearch.selectedPatient.index === -1}
						highlightedRow={this.props.notification.modalSearch.selectedPatient.index}
						data={this.props.notification.modalSearch.patientTable}
						onClick={this.onSelect}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setNotificationPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSearchResult)
