import { Checkbox, Descriptions, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT,
	SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT,
	SET_CASHIER_MODAL_PAYMENT_TOTAL,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierModalPaymentDescription extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isPrimaryHealthCarePlan: false,
		}
		this.balance = this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
			.filter((bs) => !bs.billing)
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
			.toFixed(2)
		this.onType = this.onType.bind(this)
		this.onTotal = this.onTotal.bind(this)
		this.onSkip = this.onSkip.bind(this)
	}

	componentDidMount() {
		this.props.loadPayments()
		this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, this.balance)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalPayment.isSkipPayment, this.props.cashier.modalPayment.isSkipPayment)) {
			if (this.props.cashier.modalPayment.isSkipPayment) {
				this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, 0)
				this.props.setSelected(SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT, -1, null)
			} else {
				this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, this.balance)
			}
		}

		if (!_.isEqual(prevProps.cashier.modalPayment.selectedPayment, this.props.cashier.modalPayment.selectedPayment)) {
			if (this.props.cashier.modalPayment.selectedPayment.payment) {
				if (this.props.cashier.modalPayment.selectedPayment.payment.savingAccount) {
					this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, 0)
				} else {
					this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, this.balance)
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT, false)
	}

	isSkipPaymentDisabled() {
		return (
			!this.props.cashier.selectedHealthCarePlan.healthCarePlan.primaryHealthCarePlan ||
			Utils.calculateBillingStatementBalance(
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
			).eq(0)
		)
	}

	onType(value) {
		this.props.setSelected(
			SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.cashier.modalPayment.types[value] : null
		)
	}

	onTotal(event) {
		this.props.setValue(SET_CASHIER_MODAL_PAYMENT_TOTAL, event.target.value)
	}

	onSkip(event) {
		this.props.setValue(SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions column={1}>
							<Descriptions.Item span={1}>
								{this.props.cashier.selectedHealthCarePlan.healthCarePlan.healthCareName}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
							<Descriptions.Item
								label={translate(ResourceAssistance.Hospitel.balance)}
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px" }}
							>
								{Utils.formatNumWithComma(this.balance)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row noGutters>
					<Col />
					<Col md="auto">
						<Checkbox
							style={{ flexDirection: "row" }}
							disabled={this.isSkipPaymentDisabled()}
							checked={this.props.cashier.modalPayment.isSkipPayment}
							onChange={this.onSkip}
						>
							{translate(ResourceAssistance.Message.skipPayment)}
						</Checkbox>
					</Col>
					<Col>
						<Descriptions>
							<Descriptions.Item>
								<Select
									id={ResourceAssistance.ID.HOSPITEL.cashier.modalPayment.type}
									showSearch
									placeholder={translate(ResourceAssistance.Message.payment)}
									disabled={this.props.cashier.modalPayment.isSkipPayment}
									value={
										this.props.cashier.modalPayment.selectedPayment.payment
											? this.props.cashier.modalPayment.selectedPayment.index
											: undefined
									}
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									onChange={this.onType}
								>
									{Utils.renderSelects(this.props.cashier.modalPayment.types, false)}
								</Select>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				{this.props.cashier.modalPayment.selectedPayment.payment &&
					this.props.cashier.modalPayment.selectedPayment.payment.savingAccount && (
						<Row>
							<Col />
							<Col md="auto">
								<Descriptions>
									<Descriptions.Item
										label={translate(ResourceAssistance.Message.patientSaving)}
										labelStyle={{ fontSize: "24px" }}
										contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.blue }}
									>
										{Utils.formatNumWithComma(
											this.props.cashier.patient.transactions
												.reduce((total, cur) => {
													return (total = total.plus(cur.amount))
												}, Utils.BigNumber(0))
												.toFixed(2)
										)}
									</Descriptions.Item>
								</Descriptions>
							</Col>
							<Col />
						</Row>
					)}
				<Row>
					<Col />
					<Col>
						<Descriptions column={1}>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.totalPrice)}
								labelStyle={{ alignSelf: "center" }}
							>
								<FormControl
									type={ResourceAssistance.FormControl.type.number}
									size={ResourceAssistance.FormControl.size.sm}
									step={0.01}
									disabled={
										this.props.cashier.modalPayment.isSkipPayment ||
										(this.props.cashier.modalPayment.selectedPayment.payment &&
											this.props.cashier.modalPayment.selectedPayment.payment.savingAccount)
									}
									value={this.props.cashier.modalPayment.total}
									onChange={this.onTotal}
								/>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.change)}
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.red }}
							>
								{Utils.formatNumWithComma(
									Utils.BigNumber(this.props.cashier.modalPayment.total).minus(this.balance).isGreaterThan(0)
										? Utils.BigNumber(this.props.cashier.modalPayment.total).minus(this.balance).toFixed(2)
										: Utils.BigNumber(0).toFixed(2)
								)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalPaymentDescription)
