import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setReportPOByIdSearchId } from "~/redux/action"

class ReportPOByIdFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onId = this.onId.bind(this)
	}

	componentWillUnmount() {
		this.props.setReportPOByIdSearchId("")
	}

	onId(event) {
		this.props.setReportPOByIdSearchId(event.target.value.trim())
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.filter)}</legend>
					<Row>
						<Col className={ResourceAssistance.CSS.fullSize} />
						<Col md={2}>
							<FormLabel>{translate(ResourceAssistance.Message.id)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.number}
								value={this.props.filter.searchId}
								onChange={this.onId}
							/>
						</Col>
						<Col className={ResourceAssistance.CSS.fullSize} />
					</Row>
				</fieldset>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.po.reportById,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportPOByIdSearchId,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportPOByIdFilterDetails))
