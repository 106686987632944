import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_LAB_END_DATETIME,
	SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME,
	SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER,
	SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER,
	SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME,
	SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE,
	SET_HOSPITEL_LAB_MODAL_REGISTER_TEL,
	SET_HOSPITEL_LAB_PATIENTS,
	SET_HOSPITEL_LAB_SEARCH_ID,
	SET_HOSPITEL_LAB_SEARCH_PATIENT_ID,
	SET_HOSPITEL_LAB_START_DATETIME,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG,
	SET_HOSPITEL_LAB_MODAL_LAB_ORGS,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_NGENE,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_OGENE,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_SGENE,
	SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS,
	SET_HOSPITEL_LAB_MODAL_REGISTER_DOB,
	SET_HOSPITEL_LAB_MODAL_SEARCH_ID,
	SET_HOSPITEL_LAB_PATIENTS_LOADING,
	SET_HOSPITEL_LAB_SELECTED_PAITENT,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH,
	SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES,
	SET_HOSPITEL_LAB_PATIENT_LABS,
	SET_HOSPITEL_LAB_SELECTED_LAB,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_COI,
	SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_EGENE,
	SET_HOSPITAL_LAB_MODAL_SEARCH_PID,
	SET_HOSPITAL_LAB_MODAL_SEARCH_FIRST_NAME,
	SET_HOSPITAL_LAB_MODAL_SEARCH_LAST_NAME,
	SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES,
	SET_HOSPITEL_LAB_MODAL_LAB_SERVICES,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_N2GENE,
	SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES,
	SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_TYPE,
	SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_COMMENT,
	SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPES,
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE,
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_N2GENE,
	SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING,
	SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING,
	SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES,
	SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPE_LOADING,
	SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPE_LOADING,
	SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	startDateTime: Utils.generateDate().getTime(),
	endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
	searchPatientId: "",
	searchId: "",
	selectedPatient: {
		index: -1,
		patient: null,
	},
	selectedLab: {
		index: -1,
		lab: null,
	},
	patientTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.seq,
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Hospitel.identification,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	labTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.labNumber,
			ResourceAssistance.Hospitel.service,
			ResourceAssistance.Hospitel.analyte,
			ResourceAssistance.Hospitel.result,
			ResourceAssistance.Message.status,
			ResourceAssistance.Hospitel.googleDrive,
		],
		body: [],
		rowColor: [],
	},
	modalSearch: {
		identificationNumber: "",
		firstName: "",
		lastName: "",
	},
	modalRegister: {
		title: "",
		firstName: "",
		lastName: "",
		identificationNumber: "",
		gender: "",
		dob: Utils.generateDate().getTime(),
		tel: "",
		address: "",
	},
	modalLab: {
		orgs: [],
		branches: [],
		categories: [],
		services: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
	},
	modalPositive: {
		analyte: ResourceAssistance.CONSTANT.COVID_19_ANTIGEN,
		method: ResourceAssistance.CONSTANT.IMMUNO_CHROMATOGRAPHY_ASSAY,
		nGene: "",
		n2Gene: "",
		sGene: "",
		oGene: "",
		eGene: "",
		coi: "",
		isPCRTypeLoading: false,
		isAntigenTypeLoading: false,
		types: [],
		antigenTypes: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedComment: {
			index: -1,
			comment: null,
		},
	},
	modalNegative: {
		analyte: ResourceAssistance.CONSTANT.COVID_19_ANTIGEN,
		method: ResourceAssistance.CONSTANT.IMMUNO_CHROMATOGRAPHY_ASSAY,
		nGene: "",
		n2Gene: "",
		sGene: "",
		oGene: "",
		eGene: "",
		isPCRTypeLoading: false,
		isAntigenTypeLoading: false,
		types: [],
		antigenTypes: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedComment: {
			index: -1,
			comment: null,
		},
	},
	modalApprove: {
		resultTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.antigenCOI,
				ResourceAssistance.Hospitel.eGene,
				ResourceAssistance.Hospitel.nGene,
				ResourceAssistance.Message.n2geneCT,
				ResourceAssistance.Hospitel.oGene,
				ResourceAssistance.Hospitel.sGene,
			],
			body: [],
		},
	},
}

const labReducer = (state = init, action) => {
	switch (action.type) {
		case SET_HOSPITEL_LAB_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.date,
			})

		case SET_HOSPITEL_LAB_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.date,
			})

		case SET_HOSPITEL_LAB_SEARCH_PATIENT_ID:
			return Object.assign({}, state, {
				searchPatientId: action.payload.text,
			})

		case SET_HOSPITEL_LAB_SEARCH_ID:
			return Object.assign({}, state, {
				searchId: action.payload.text,
			})

		case SET_HOSPITEL_LAB_PATIENTS:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_HOSPITEL_LAB_PATIENTS_LOADING:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_HOSPITEL_LAB_SELECTED_PAITENT:
			return Object.assign({}, state, {
				selectedPatient: {
					index: action.payload.index,
					patient: action.payload.selected,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					title: action.payload.title,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					firstName: action.payload.firstName,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					lastName: action.payload.lastName,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					identificationNumber: action.payload.id,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					gender: action.payload.gender,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_TEL:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					tel: action.payload.tel,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					address: action.payload.address,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_REGISTER_DOB:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					dob: action.payload.datetime,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_SEARCH_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					identificationNumber: action.payload.text,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_NGENE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					nGene: action.payload.value,
				},
			})
		case SET_HOSPITEL_LAB_MODAL_POSITIVE_N2GENE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					n2Gene: action.payload.value,
				},
			})
		case SET_HOSPITEL_LAB_MODAL_POSITIVE_SGENE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					sGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_OGENE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					oGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_EGENE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					eGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					nGene: action.payload.value,
				},
			})
		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_N2GENE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					n2Gene: action.payload.value,
				},
			})
		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					sGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					oGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					eGene: action.payload.value,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_ORGS:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					orgs: action.payload.objs,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					branches: action.payload.objs,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					categories: action.payload.objs,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_SERVICES:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					services: action.payload.objs,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})

		case SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_HOSPITEL_LAB_PATIENT_LABS:
			return Object.assign({}, state, {
				labTable: {
					...state.labTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_HOSPITEL_LAB_SELECTED_LAB:
			return Object.assign({}, state, {
				selectedLab: {
					index: action.payload.index,
					lab: action.payload.selected,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					method: action.payload.method,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					analyte: action.payload.analyte,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					method: action.payload.method,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					analyte: action.payload.analyte,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_POSITIVE_COI:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					coi: action.payload.text,
				},
			})

		case SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS:
			return Object.assign({}, state, {
				modalApprove: {
					...state.modalApprove,
					resultTable: {
						...state.modalApprove.resultTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_HOSPITAL_LAB_MODAL_SEARCH_PID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					pid: action.payload.text,
				},
			})

		case SET_HOSPITAL_LAB_MODAL_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					firstName: action.payload.text,
				},
			})

		case SET_HOSPITAL_LAB_MODAL_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					lastName: action.payload.text,
				},
			})
		case SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					types: action.payload.objs,
				},
			})
		case SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					isPCRTypeLoading: action.payload.isLoading,
				},
			})
		case SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_TYPE:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_COMMENT:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					selectedComment: {
						index: action.payload.index,
						comment: action.payload.selected,
					},
				},
			})
		case SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					isAntigenTypeLoading: action.payload.isLoading,
				},
			})
		case SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES:
			return Object.assign({}, state, {
				modalPositive: {
					...state.modalPositive,
					antigenTypes: action.payload.objs,
				},
			})
		case SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPE_LOADING:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					isPCRTypeLoading: action.payload.isLoading,
				},
			})
		case SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPES:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					types: action.payload.objs,
				},
			})
		case SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPE_LOADING:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					isAntigenTypeLoading: action.payload.isLoading,
				},
			})
		case SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPES:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					antigenTypes: action.payload.objs,
				},
			})
		case SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT:
			return Object.assign({}, state, {
				modalNegative: {
					...state.modalNegative,
					selectedComment: {
						index: action.payload.index,
						comment: action.payload.selected,
					},
				},
			})
		default:
			return state
	}
}

export default labReducer
