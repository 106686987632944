import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import AntDatePicker from "../date_picker/AntDatePicker"
import moment from "moment"

class IntakeOutputDate extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			datetime: "",
		}

		this.onDateChange = this.onDateChange.bind(this)
	}

	componentDidMount() {
		if (this.props.datetime) {
			this.setState({
				datetime: this.props.datetime,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	onDateChange(dateObj) {
		this.setState({
			datetime: dateObj ? dateObj.valueOf() : "",
		})
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<AntDatePicker
						size={"large"}
						format={"DD-MM-YYYY  HH:mm"}
						showTime={{ format: "HH:mm" }}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
						values={this.state.datetime ? [moment(this.state.datetime)] : undefined}
						onChange={this.onDateChange}
					/>
				</Col>
				<Col />
			</Row>
		)
	}
}

IntakeOutputDate.propTypes = {
	onDateChange: propTypes.func,
}

IntakeOutputDate.defaultProps = {}

export default injectIntl(IntakeOutputDate, { forwardRef: true })
