import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class POLeftSixthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "POLeftSixthRow",
		}
	}

	render() {
		return (
			<Row noGutters id={this.state.componentName} className={ResourceAssistance.CSS.labelInfoRow}>
				<Col md={3}>
					<FormLabel>{translate(ResourceAssistance.Message.approver)}</FormLabel>
				</Col>
				<Col>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						typ4={ResourceAssistance.FormControl.type.text}
						disabled
						value={
							this.props.selectedPo.po && this.props.selectedPo.po.approvalApproved
								? this.props.selectedPo.po.approval.displayName
								: ""
						}
					/>
				</Col>
				<Col>
					<FormControl
						disabled
						size={ResourceAssistance.FormControl.size.sm}
						type={ResourceAssistance.FormControl.type.text}
						value={
							this.props.selectedPo.po && this.props.selectedPo.po.approvalApproved
								? Utils.formatDateTime(this.props.selectedPo.po.approvalApprovedDateTime)
								: ""
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	selectedPo: state.purchaseOrder.selectedPo,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POLeftSixthRow)
