const Constant = {
	CONSTANT: {
		PMGCARE: "PMGCare",
		ASYMPTOMATIC: "Asymptomatic",
		MILD_SYMPTOMS: "Mild Symptoms",
		COVID19_INFECTION: "Covid-19 Infection.",
		ADMIT_COVID19: "Admit Covid-19.",
		ADMIT_HOSPITEL: "Admit Hospitel.",
		SUPPORTIVE_TREATMENT_FOR_COVID: "Supportive treatment for Covid-19",
		NORMAL: "N",
		ABNORMAL: "A",
		BRANCH: "BRANCH",
		CANCELLED: "CANCELLED",
		TESTED: "TESTED",
		COMPLETED: "COMPLETED",
		CM: "cm",
		COVID19_NEGATIVE: "ผลการตรวจไม่พบเชื้อ Covid-19",
		COVID19_POSITIVE: "ผลการตรวจพบเชื้อ Covid-19",
		COVID_19_ANTIGEN: "COVID-19 Antigen",
		DETECTED: "DETECTED",
		EGENE: "E gene:CT",
		IMMUNO_CHROMATOGRAPHY_ASSAY: "Immuno Chromatography Assay",
		IMMUNO_FLUORESCENCE_ASSAY: "Immuno Fluorescence Assay",
		KG: "kg",
		NEGATIVE: "NEGATIVE",
		NONE: "-",
		NOT_DETECTED: "NOT_DETECTED",
		ORG: "ORG",
		OVERLAY_HIDE: 0,
		OVERLAY_SHOW: 0,
		PCR_XPERT_XPRESS_SARS_COV_2: "Xpert-Xpress-SARS-CoV-2",
		PCR_SARS_COV_2_RNA_PCR: "SARS-CoV-2-RNA-PCR",
		PCR: "Real-Time PCR",
		POSITIVE: "POSITIVE",
		PX: "PX",
		REQUEST_COVID19_MEDICAL_CERTIFICATE: "ขอใบรับรองแพทย์ตรวจ Covid-19",
		SARS_COV_2_RNA_PCR: "SARS-CoV-2-RNA-PCR",
		XPERT_XPRESS_SARS_COV_2: "Xpert-Xpress-SARS-CoV-2",
		TAX: 7,
		OTHER: "Other",
		OPD_CLINICAL_RECORD: "OPD Clinical Record",
		ADMISSION_NOTE: "Admission Note",
		DEFAULT_NURSE_EVALUATION: "รู้สึกตัวดี ไม่กังวล ไม่เหนื่อย",
		SAT_CODE: "SAT Code",
		CLAIM: "Claim",
		DOCTOR_OPITION_DEFAULT_AFTER: "ได้รับการรักษา ครบถ้วน 14 วันแล้ว สามารถไปทำงาน และ ใช้ชีวิตได้ตามปกติ",
		PRN: "PRN",
		ML: "mL",
		MM: "mm",
		PUPIL_SIZE: "Pupil Size",
		PAIN_SCALE: "Pain Scale",
		INTAKE_OUTPUT: "Intake/Output",
		MALE: "Male",
		FEMALE: "Female",
	},
}

export default Constant
