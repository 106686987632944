import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import moment from "moment"

class LabelMedicationDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				lots: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.ML}
						style={[ResourceAssistance.Report.styles.pageLabel, { justifyContent: "unset" }]}
					>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Image style={styles.orgImg} src={this.state.data.logo} />
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>PID: {this.state.data.pid}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.patientName}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.description}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.dose}</Text>
							</View>
						</View>

						{this.state.data.lots.map((lot) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>Lot: {lot.lotNum}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>
											{this.state.data.expiredIntl}/Expired: {Utils.formatDate(lot.expiredDateTime)}
										</Text>
									</View>
								</View>
							)
						})}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}></View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>
									{this.state.data.total} {this.state.data.unit}
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}></View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{Utils.formatDateTime(moment().valueOf())}</Text>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	orgImg: {
		position: "fixed",
		top: 0,
		right: 0,
		height: "auto",
		objectFit: "contain",
	},
})

export default LabelMedicationDoc
