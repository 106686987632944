import { Descriptions, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT,
	SET_DEPOSIT_MODAL_WITHDRAW_REMARK,
	SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class DepositModalWithdrawDescription extends React.Component {
	constructor(props) {
		super(props)

		this.onAmount = this.onAmount.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		this.props.loadPayments()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.deposit.modalWithdraw.amount, this.props.deposit.modalWithdraw.amount)) {
			this.validateWithdraw()
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT, 0)
	}

	validateWithdraw() {
		let amountDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.deposit.modalWithdraw.amount)

		if (
			Utils.BigNumber(this.props.deposit.modalWithdraw.amount).isGreaterThan(
				this.props.deposit.patient.transactions.reduce((total, cur) => {
					return (total = total.plus(cur.amount))
				}, Utils.BigNumber(0))
			)
		) {
			amountDOM.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.wrongWithdrawAmount }))
		} else {
			amountDOM.setCustomValidity("")
		}
	}

	onAmount(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT, event.target.value)
	}

	onPayment(value) {
		this.props.setSelected(
			SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.deposit.modalWithdraw.payments[value] : null
		)
	}

	onRemark(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_WITHDRAW_REMARK, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions>
							<Descriptions.Item
								label={translate(ResourceAssistance.Hospitel.balance)}
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.blue }}
							>
								{Utils.formatNumWithComma(Utils.calculatePatientDeposit(this.props.deposit.patient.transactions))}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={6}>
						<Descriptions>
							<Descriptions.Item>
								<Select
									id={ResourceAssistance.ID.HOSPITAL.deposit.modalWithdraw.payment}
									showSearch
									placeholder={translate(ResourceAssistance.Message.payment)}
									value={
										this.props.deposit.modalWithdraw.selectedPayment.payment
											? this.props.deposit.modalWithdraw.selectedPayment.index
											: undefined
									}
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									onChange={this.onPayment}
								>
									{Utils.renderSelects(this.props.deposit.modalWithdraw.payments, false)}
								</Select>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={6}>
						<Descriptions column={1}>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.amount)}
								labelStyle={{ alignSelf: "center" }}
							>
								<FormControl
									required
									id={ResourceAssistance.ID.HOSPITAL.deposit.modalWithdraw.amount}
									type={ResourceAssistance.FormControl.type.number}
									size={ResourceAssistance.FormControl.size.sm}
									step={0.01}
									value={this.props.deposit.modalWithdraw.amount}
									onChange={this.onAmount}
								/>
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Descriptions>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.withdraw)}
								labelStyle={{ fontSize: "24px" }}
								contentStyle={{ fontSize: "24px", color: ResourceAssistance.CSS.Color.red }}
							>
								{this.props.deposit.modalWithdraw.amount
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.deposit.modalWithdraw.amount).toFixed(2))
									: Utils.BigNumber(0).toFixed(2)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.remark)}
							onChange={this.onRemark}
							value={this.props.deposit.modalWithdraw.remark}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DepositModalWithdrawDescription))
