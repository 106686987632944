import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { BiCheckSquare, BiSelectMultiple } from "react-icons/bi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setPharmacyDoctorOrders, setSelected, setValue } from "~/redux/action"
import {
	SET_PHARMACY_MODAL_VERIFY_DAILY_IND,
	SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND,
	SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME,
	SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME,
	SET_PHARMACY_SELECTED_DOCTOR_ORDER,
	SET_PHARMACY_VERIFY_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightDoctorOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightDoctorOrder",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onVerify = this.onVerify.bind(this)
		this.onVerifyAll = this.onVerifyAll.bind(this)
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedAdmission.admission) {
			this.props.setPharmacyDoctorOrders(this.props.pharmacy.selectedAdmission.admission.clinicalRecord.doctorOrders)
		} else {
			this.props.setPharmacyDoctorOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedAdmission, this.props.pharmacy.selectedAdmission)) {
			if (this.props.pharmacy.selectedAdmission.admission) {
				this.props.setPharmacyDoctorOrders(this.props.pharmacy.selectedAdmission.admission.clinicalRecord.doctorOrders)
			} else {
				this.props.setPharmacyDoctorOrders([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.doctorOrderTable.filtered, this.props.pharmacy.doctorOrderTable.filtered)) {
			let index = this.props.pharmacy.selectedDoctorOrder.doctorOrder
				? this.props.pharmacy.doctorOrderTable.filtered.findIndex(
						(doctorOrder) => doctorOrder.id === this.props.pharmacy.selectedDoctorOrder.doctorOrder.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(
					SET_PHARMACY_SELECTED_DOCTOR_ORDER,
					index,
					this.props.pharmacy.doctorOrderTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_DOCTOR_ORDER, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PHARMACY_SELECTED_DOCTOR_ORDER, -1, null)
	}

	onSelectOrder(order, index) {
		this.props.setSelected(
			SET_PHARMACY_SELECTED_DOCTOR_ORDER,
			index,
			this.props.pharmacy.doctorOrderTable.filtered[index]
		)
	}

	onVerify(event, row, rIdx, cIdx) {
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME, this.props.pharmacy.startDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, this.props.pharmacy.endDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DAILY_IND, true)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND, true)
		this.props.onModalDisplayAction(SET_PHARMACY_VERIFY_DISPLAY, true)
	}

	onVerifyAll(event, row, rIdx, cIdx) {
		let selectedDoctorOrder = this.props.pharmacy.doctorOrderTable.filtered[rIdx]
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME, selectedDoctorOrder.startDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, selectedDoctorOrder.endDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DAILY_IND, false)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND, true)
		this.props.onModalDisplayAction(SET_PHARMACY_VERIFY_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlinePrimary,
						]}
						btnIcons={[
							this.props.pharmacy.selectedPatient.patient &&
							this.props.pharmacy.selectedAdmission.admission &&
							!this.props.pharmacy.selectedPatient.patient.isSearchPatient &&
							!this.props.pharmacy.selectedAdmission.admission.dischargedDateTime ? (
								<BiCheckSquare size={ResourceAssistance.ReactIcon.size} />
							) : undefined,
							this.props.pharmacy.selectedAdmission.admission &&
							this.props.pharmacy.selectedAdmission.admission.dischargedDateTime > 0 ? (
								<BiSelectMultiple size={ResourceAssistance.ReactIcon.size} />
							) : undefined,
						]}
						onCheckedArray={[this.onVerify, this.onVerifyAll]}
						data={this.props.pharmacy.doctorOrderTable}
						onClick={this.onSelectOrder}
						isClearHighlight={this.props.pharmacy.selectedDoctorOrder.index === -1}
						highlightedRow={this.props.pharmacy.selectedDoctorOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPharmacyDoctorOrders,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightDoctorOrder))
