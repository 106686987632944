import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setPharmacyModalReturnLots } from "~/redux/action"
import { SET_PHARMACY_RETURN_EDIT_DISPLAY } from "~/redux/type"
import PharmacyModalReturnEditDetails from "./PharmacyModalReturnEditDetails"

class PharmacyModalReturnEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyModalReturnEdit",
		}
		this.editRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PHARMACY_RETURN_EDIT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.editRef.current) {
			return true
		}
		let target = Object.assign({}, this.props.pharmacy.modalReturn.selectedLot.lot, {
			returnAmount: Number(this.editRef.current.state.amount),
		})
		return _.isEqual(target, this.props.pharmacy.modalReturn.selectedLot.lot)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		Object.assign(this.props.pharmacy.modalReturn.selectedLot.lot, {
			returnAmount: Number(this.editRef.current.state.amount),
			isReturn: true,
		})
		this.props.setPharmacyModalReturnLots(this.props.pharmacy.modalReturn.itemTable.original)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.edit)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PharmacyModalReturnEditDetails
							editRef={this.editRef}
							amount={
								this.props.pharmacy.modalReturn.selectedLot.lot
									? this.props.pharmacy.modalReturn.selectedLot.lot.returnAmount
									: 0
							}
							onRenderParentCallback={this.onRenderParentCallback}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPharmacyReturnEditDisplay,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPharmacyModalReturnLots,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalReturnEdit)
