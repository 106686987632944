import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class CLRightPatientInfoRow extends React.Component {
	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
				<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
					<Col />
					<Col md="auto">
						<FormLabel>
							{translate(ResourceAssistance.Message.name)}
							{ResourceAssistance.Symbol.colon}
						</FormLabel>
					</Col>
					<Col md="auto">
						<FormLabel>
							{this.props.lab.selectedPatient.patient
								? this.props.lab.selectedPatient.patient.title +
								  " " +
								  this.props.lab.selectedPatient.patient.firstName +
								  " " +
								  this.props.lab.selectedPatient.patient.lastName
								: " "}
						</FormLabel>
					</Col>
					<Col />
					<Col md="auto">
						<FormLabel>
							{translate(ResourceAssistance.Hospitel.dateOfBirth)}
							{ResourceAssistance.Symbol.colon}
						</FormLabel>
					</Col>
					<Col md="auto">
						<FormLabel>
							{this.props.lab.selectedPatient.patient
								? Utils.formatDate(this.props.lab.selectedPatient.patient.dobtime)
								: " "}
						</FormLabel>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLRightPatientInfoRow)
