import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "../component/Signature"

class ReportReceiveDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.receive,
				items: [],
			},
		}

		// window.onstorage = (e) => {
		// 	if (e.key != "reportPo") return
		// 	this.setState({
		// 		data: Object.assign({}, this.state.data, {
		// 			...this.state.data,
		// 			...JSON.parse(e.newValue),
		// 		}),
		// 	})
		// }
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Invoice Info */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>PO ID/{this.state.data.poIdIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.poId} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Invoice ID/{this.state.data.invoiceIdIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.invoiceId} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Date/{this.state.data.dateIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.invoiceDate}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Vendor ID/{this.state.data.vendorIdIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.vendorId}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Vendor/{this.state.data.vendorIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.vendorName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Payment/{this.state.data.paymentIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.payment}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoHeader]}>
									<Text>Terms/{this.state.data.termsIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.invoiceInfoBody]}>
									<Text>{this.state.data.terms} Days</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Item Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.descriptionIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>
										{this.state.data.qtyIntl}/{this.state.data.uomIntl}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.bonusIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitPriceIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.discountIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>ID</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>Description</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Qty/UOM</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Bonus</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Unit Price</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Discount</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{/* Items */}
						{this.state.data.items.map((item) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
											<Text>{item.id}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
											<Text style={ResourceAssistance.Report.styles.textItalic}>{item.description}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
											<Text>
												{item.qty}x{item.minQtyPerOrder} {item.unit}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
											<Text>{item.bonus}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
											<Text>{item.pricePerUnit}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
											<Text>{item.discount}%</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
											<Text>{item.totalPrice}</Text>
										</View>
									</View>
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Totals */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Remark/{this.state.data.remarkIntl}:
											</Text>
										</View>
									</View>
								</View>

								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Sub Total/{this.state.data.subTotalIntl}
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.subTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Discount/{this.state.data.discountIntl} {this.state.data.discount}%
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.discountTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 15 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>Total/{this.state.data.totalIntl}</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.total}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												VAT/{this.state.data.vatIntl} {this.state.data.vat}%
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.vatTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 16 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Grand Total/{this.state.data.grandTotalIntl}
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.grandTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Signature */}
						<Signature
							numOfSignature={1}
							signatureDate={this.state.data.signatureDate}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 100,
	},
	invoiceInfoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	invoiceInfoBody: {
		fontStyle: "italic",
	},
	info: {
		justifyContent: "flex-start",
	},
	itemRow: {
		fontSize: 16,
	},
	id: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	description: {
		minWidth: "29%",
		maxWidth: "29%",
	},
	qty: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	bonus: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	unitPrice: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	discount: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	amount: {
		minWidth: "10%",
		maxWidth: "10%",
	},
})

export default ReportReceiveDocument
