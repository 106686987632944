import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setPoAddress, setPoShippingContact } from "~/redux/action"

class PORightFirstRow extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedPo.index !== this.props.selectedPo.index) {
			if (this.props.selectedPo.po) {
				this.props.setPoAddress(this.props.selectedPo.po.pr.location.address.displayName)
			} else {
				this.props.setPoAddress("")
			}
		}

		if (prevProps.selectedSupplier.index !== this.props.selectedSupplier.index) {
			if (this.props.selectedSupplier.supplier) {
				this.props.setPoShippingContact(
					this.props.selectedSupplier.supplier.orderDetail.shippingContact
						? this.props.selectedSupplier.supplier.orderDetail.shippingContact
						: ""
				)
			} else {
				this.props.setPoShippingContact("")
			}
		}
	}

	render() {
		return (
			<Row>
				<Col sm="auto">
					<FormLabel>
						{translate(ResourceAssistance.Message.shipAddress)}
						{ResourceAssistance.Symbol.colon}
					</FormLabel>
				</Col>
				<Col>
					<FormControl
						type={ResourceAssistance.FormControl.type.text}
						size={ResourceAssistance.FormControl.size.sm}
						disabled
						value={this.props.address}
					/>
				</Col>
				<Col md="auto">
					<FormLabel>
						{translate(ResourceAssistance.Message.contact)}
						{ResourceAssistance.Symbol.colon}
					</FormLabel>
				</Col>
				<Col md={3}>
					<FormControl
						type={ResourceAssistance.FormControl.type.text}
						size={ResourceAssistance.FormControl.size.sm}
						disabled
						value={this.props.contact}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	address: state.purchaseOrder.address,
	contact: state.purchaseOrder.shippingContact,
	selectedPo: state.purchaseOrder.selectedPo,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoAddress, setPoShippingContact }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightFirstRow)
