import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, FormControl, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmIDisplayAll,
	setAdmIItems,
	setAdmIModalNIActive,
	setAdmIModalNIBonus,
	setAdmIModalNIBrand,
	setAdmIModalNICode,
	setAdmIModalNIDiscount,
	setAdmIModalNIDose,
	setAdmIModalNIForms,
	setAdmIModalNIItems,
	setAdmIModalNIPricePerUnit,
	setAdmIModalNIQtyPerOrder,
	setAdmIModalNISuppliers,
	setAdmIModalNITaxes,
	setAdmIModalNITypes,
	setAdmIModalNIUnits,
	setAdmISelectedItem,
	setAdmISelectedType,
	setAdmITypes,
	setObjArray,
	setSearchText,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
	SET_ADMIN_ITEM_SEARCH_CODE,
	SET_ADMIN_ITEM_SEARCH_NAME,
	SET_ADM_ITEM_NEW_ITEM_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class AdmItem extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes((types) => {
			this.props.setAdmITypes(types)
			this.props.setAdmIModalNITypes(types)
		})
		this.props.loadItemSuppliers((itemSuppliers) => {
			this.props.setAdmIItems(itemSuppliers)
		})
		this.props.loadItems((items) => {
			this.props.setAdmIModalNIItems(items)
		}, false)
		this.props.loadForms((forms) => {
			this.props.setAdmIModalNIForms(forms)
		})
		this.props.loadUnits((units) => {
			this.props.setAdmIModalNIUnits(units)
		})
		this.props.loadSuppliers((suppliers) => {
			this.props.setAdmIModalNISuppliers(suppliers)
		})
		this.props.loadTaxes((taxes) => {
			this.props.setAdmIModalNITaxes(taxes)
		})
		this.props.loadHealthCarePlan((healthcarePlans) => {
			this.props.setObjArray(SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS, healthcarePlans)
		})
	}

	componentWillUnmount() {
		this.props.setAdmISelectedType(-1, null)
		this.props.setAdmISelectedItem(-1, null)
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_CODE, "")
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_NAME, "")
		this.props.setAdmIDisplayAll(true)
		this.props.setAdmIItems([])
		this.props.setAdmITypes([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.item.types, this.props.item.types)) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.item.type)
			type.selectedIndex = 0
			this.props.setAdmISelectedType(type.selectedIndex, this.props.item.types[type.selectedIndex])
		}

		if (
			prevProps.item.selectedType.index !== this.props.item.selectedType.index ||
			prevProps.item.searchCode !== this.props.item.searchCode ||
			prevProps.item.searchName !== this.props.item.searchName ||
			prevProps.item.displayInactive !== this.props.item.displayInactive
		) {
			this.props.setAdmISelectedItem(-1, null)
			this.props.setAdmIItems(this.props.item.itemTable.original)
		}
	}

	onType(event) {
		this.props.setAdmISelectedType(event.target.value, this.props.item.types[event.target.value])
	}

	onSearchCode(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmIDisplayAll(event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setAdmISelectedItem(index, this.props.item.itemTable.filtered[index])
	}

	onAdd() {
		this.props.setAdmISelectedItem(-1, null)
		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, true)
	}

	onEdit() {
		this.props.setAdmIModalNICode(this.props.item.selectedItem.item.code)
		this.props.setAdmIModalNIDose(this.props.item.selectedItem.item.dose)
		this.props.setAdmIModalNIBrand(this.props.item.selectedItem.item.brand)
		this.props.setValue(
			SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
			this.props.item.selectedItem.item.medicationUsage
		)
		this.props.setAdmIModalNIPricePerUnit(this.props.item.selectedItem.item.pricePerUnit)
		this.props.setAdmIModalNIDiscount(this.props.item.selectedItem.item.discount)
		this.props.setAdmIModalNIQtyPerOrder(this.props.item.selectedItem.item.minQtyPerOrder)
		this.props.setAdmIModalNIBonus(this.props.item.selectedItem.item.bonus)
		this.props.setAdmIModalNIActive(this.props.item.selectedItem.item.active)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, this.props.item.selectedItem.item.fsCode)
		this.props.setValue(
			SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
			this.props.item.selectedItem.item.sellPricePerUnit
		)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, this.props.item.selectedItem.item.categoryNum)
		this.props.setValue(
			SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
			this.props.item.selectedItem.item.healthcarePlan
		)

		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md="auto">
						<FormControl
							id={ResourceAssistance.ID.ADM.item.type}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.item.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.item.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.item.selectedItem.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableOverlay
							enableHighlight
							isClearHighlight={this.props.item.selectedItem.index === -1}
							data={this.props.item.itemTable}
							onClick={this.onSelectRow}
						/>
						{this.props.item.itemTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	item: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmIDisplayAll,
			setAdmIItems,
			setAdmIModalNIActive,
			setAdmIModalNIBonus,
			setAdmIModalNIBrand,
			setAdmIModalNICode,
			setAdmIModalNIDiscount,
			setAdmIModalNIDose,
			setAdmIModalNIForms,
			setAdmIModalNIItems,
			setAdmIModalNIPricePerUnit,
			setAdmIModalNIQtyPerOrder,
			setAdmIModalNISuppliers,
			setAdmIModalNITaxes,
			setAdmIModalNITypes,
			setAdmIModalNIUnits,
			setAdmISelectedItem,
			setAdmISelectedType,
			setAdmITypes,
			setObjArray,
			setSearchText,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmItem)
