import React from "react"
import { Badge, Button, ButtonGroup, Col, Dropdown, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { ImPrinter } from "react-icons/im"
import { FaUserEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
} from "~/redux/type"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import HospitalModalPatientClinicalRecordDetails from "./HospitalModalPatientClinicalRecordDetails"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import _ from "lodash"

class HospitalModalPatientClinicalRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitalModalPatientClinicalRecord",
		}

		this.onPrintClinicalRecord = this.onPrintClinicalRecord.bind(this)
		this.onPrintAdmission = this.onPrintAdmission.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (!this.props.isDisplay) {
				this.props.setValue(SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT, {})
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY, false)
	}

	onPrintClinicalRecord() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.clinicalRecord, "_blank")
		myWindow.data = PrintableDataFactory.generateClinicalRecordReportData(this.props.clinicalRecord.patient)
	}

	onPrintAdmission() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.admissionNote, "_blank")
		myWindow.data = PrintableDataFactory.generateAdmissionNoteReportData(this.props.clinicalRecord.patient)
	}

	onEdit() {
		this.props.clinicalRecord.callback()
	}

	onClose() {
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundInfo}>
					<ModalTitle>
						<Badge>{translate(ResourceAssistance.Hospitel.clinicalRecord)}</Badge>
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<HospitalModalPatientClinicalRecordDetails {...this.props} />
				</ModalBody>
				<ModalFooter>
					<Dropdown drop="up" as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								disabled={
									!this.props.clinicalRecord.patient.clinicalRecord ||
									_.isEmpty(this.props.clinicalRecord.patient.clinicalRecord.treatment)
								}
								onClick={this.onPrintClinicalRecord}
							>
								{translate(ResourceAssistance.Hospitel.clinicalRecord)}
							</DropdownItem>
							<DropdownItem
								disabled={
									!this.props.clinicalRecord.patient.clinicalRecord ||
									_.isEmpty(this.props.clinicalRecord.patient.clinicalRecord.treatment) ||
									_.isEmpty(this.props.clinicalRecord.patient.clinicalRecord.admissionPlan)
								}
								onClick={this.onPrintAdmission}
							>
								{translate(ResourceAssistance.Hospitel.admission)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					<Col />
					{this.props.clinicalRecord.isEditDisplay && (
						<Button variant={ResourceAssistance.Button.variant.primary} onClick={this.onEdit}>
							<FaUserEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					)}
					<Col />
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						{translate(ResourceAssistance.Message.close)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospital.clinicalRecord.isClinicalRecordDisplay,
	clinicalRecord: state.modal.hospital.clinicalRecord,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitalModalPatientClinicalRecord))
