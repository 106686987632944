import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import TypeModalNew from "./TypeModalNew"

class AbstractTypeModal extends React.Component {
	constructor(props) {
		super(props)

		this.setParam = this.setParam.bind(this)
		this.callback = this.callback.bind(this)
		this.errorHandler = this.errorHandler.bind(this)
	}

	setParam(param, url) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: param,
		}
		return params
	}

	callback(res) {}

	errorHandler(error) {}

	render() {
		return <TypeModalNew setParam={this.setParam} callback={this.callback} errorHandler={this.errorHandler} />
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractTypeModal)
