import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdOutlineCancel } from "react-icons/md"
import { TiDownload } from "react-icons/ti"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setRcWaitingReceiveDisplayAll } from "~/redux/action"
import { SET_RC_CANCEL_DISPLAY, SET_RC_RECEIVE_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"

class RCBottomFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCBottomFirstRow",
		}

		this.onReceive = this.onReceive.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onWaitingDisplayAll = this.onWaitingDisplayAll.bind(this)
	}

	onReceive() {
		this.props.onModalDisplayAction(SET_RC_RECEIVE_DISPLAY, true)
	}

	onCancel() {
		this.props.onModalDisplayAction(SET_RC_CANCEL_DISPLAY, true)
	}

	onWaitingDisplayAll(event) {
		this.props.setRcWaitingReceiveDisplayAll(event.target.checked)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Rc.Receive, this.props.login.user.roles) && (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.selectedRc.index === -1 || this.props.selectedRc.rc.suppliers.length === 0}
								onClick={this.onReceive}
							>
								<TiDownload size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.receive)}
							</Button>
						</Col>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.red}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.selectedRc.index === -1 || this.props.selectedRc.rc.suppliers.length === 0}
								onClick={this.onCancel}
							>
								<MdOutlineCancel size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.cancel)}
							</Button>
						</Col>
						{/* <Col sm="auto">
							<div className={ResourceAssistance.CSS.vertical}></div>
						</Col> */}
					</>
				)}
				{/* <Col md="auto">
					<FormCheck
						type={ResourceAssistance.FormControl.type.checkBox}
						label={translate(ResourceAssistance.Message.displayAll)}
						onChange={this.onWaitingDisplayAll}
					/>
				</Col> */}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	selectedRc: state.receive.selectedRc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setRcWaitingReceiveDisplayAll }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCBottomFirstRow)
