import {
	SET_LOCATIONS,
	SET_LOCATION_LOADING,
	SET_LOCATION_DISPLAY_INACTIVE,
	SET_SELECTED_LOC,
	SET_LOC_SEARCH_TEXT,
	SET_LOC_ORG,
	SET_LOC_BRANCH,
	SET_LOC_INVENTORY,
	SET_LOC_PARENT_LOCS,
	SET_LOC_PARENT_ORGS,
	SET_ADMIN_LOC_NEW_LOC_SELECTED_ORG,
	SET_LOC_GENERAL,
	SET_ADMIN_LOC_NEW_LOC_SELECTED_BRANCH,
	SET_ADMIN_LOC_DISPLAY_ORG_IND,
	SET_ADMIN_LOC_DISPLAY_BRANCH_IND,
	SET_ADMIN_LOC_DISPLAY_INVENTORY_IND,
	SET_ADMIN_LOC_DISPLAY_LOCATION_IND,
	SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE,
	SET_LOC_OPD,
	SET_LOC_IPD,
} from "../../type/type/ADMIN"

const init = {
	searchText: "",
	displayInactive: true,
	displayOrg: true,
	displayBranch: true,
	displayInventory: true,
	displayLocation: true,
	isOrg: false,
	isBranch: false,
	isInventory: false,
	isGeneralLocation: false,
	isIPD: false,
	isOPD: false,
	locations: {
		locations: [],
		filtered: [],
		header: [],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	parentOrgs: {
		locs: [],
		isLoading: false,
	},
	parentLocs: {
		locs: [],
		isLoading: false,
	},
	selectedLoc: {
		loc: null,
		index: -1,
	},
	newLoc: {
		title: "",
		selectedOrg: {
			org: null,
			index: -1,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
}

const locationReducer = (state = init, action) => {
	switch (action.type) {
		case SET_LOCATIONS:
			return Object.assign({}, state, {
				locations: {
					...state.locations,
					locations: action.payload.locations,
					filtered: action.payload.filtered,
					header: action.payload.header,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_LOCATION_LOADING:
			return Object.assign({}, state, {
				locations: {
					...state.locations,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_LOCATION_DISPLAY_INACTIVE:
			return Object.assign({}, state, {
				displayInactive: action.payload.isDisplay,
			})

		case SET_ADMIN_LOC_DISPLAY_ORG_IND:
			return Object.assign({}, state, {
				displayOrg: action.payload.isDisplay,
			})

		case SET_ADMIN_LOC_DISPLAY_BRANCH_IND:
			return Object.assign({}, state, {
				displayBranch: action.payload.isDisplay,
			})

		case SET_ADMIN_LOC_DISPLAY_INVENTORY_IND:
			return Object.assign({}, state, {
				displayInventory: action.payload.isDisplay,
			})

		case SET_ADMIN_LOC_DISPLAY_LOCATION_IND:
			return Object.assign({}, state, {
				displayLocation: action.payload.isDisplay,
			})

		case SET_SELECTED_LOC:
			return Object.assign({}, state, {
				selectedLoc: {
					...state.selectedLoc,
					index: action.payload.index,
					loc: action.payload.loc,
				},
			})

		case SET_LOC_SEARCH_TEXT:
			return Object.assign({}, state, {
				searchText: action.payload.searchText,
			})

		case SET_LOC_ORG:
			return Object.assign({}, state, {
				isOrg: action.payload.value,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
			})

		case SET_LOC_BRANCH:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: action.payload.value,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
			})

		case SET_LOC_INVENTORY:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: action.payload.value,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
			})

		case SET_LOC_GENERAL:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: action.payload.value,
				isIPD: false,
				isOPD: false,
			})

		case SET_LOC_IPD:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: action.payload.value,
				isOPD: false,
			})

		case SET_LOC_OPD:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: action.payload.value,
			})

		case SET_LOC_PARENT_ORGS:
			return Object.assign({}, state, {
				parentOrgs: {
					...state.parentOrgs,
					locs: action.payload.locs,
				},
			})

		case SET_LOC_PARENT_LOCS:
			return Object.assign({}, state, {
				parentLocs: {
					...state.parentLocs,
					locs: action.payload.locs,
				},
			})

		case SET_ADMIN_LOC_NEW_LOC_SELECTED_ORG:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					selectedOrg: {
						...state.newLoc.selectedOrg,
						org: action.payload.org,
						index: action.payload.index,
					},
				},
			})

		case SET_ADMIN_LOC_NEW_LOC_SELECTED_BRANCH:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.branch,
					},
				},
			})

		case SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					title: action.payload.title,
				},
			})

		default:
			return state
	}
}

export default locationReducer
