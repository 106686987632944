import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ReportCashierBillingToTodayFilterDetails from "./ReportCashierBillingToTodayFilterDetails"

class ReportCashierBillingToTodayFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportCashierBillingToTodayFilter",
		}

		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {}

	runReport(admission) {
		let grandTotal = Utils.BigNumber(0)
		let doctorOrders = admission.doctorOrders
			.filter(
				(order) =>
					(order.duration > 0 || order.serviceCode) &&
					order.startDateTime <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
			)
			.sort((a, b) => Utils.sort(a.categoryName, b.categoryName))
			.reduce((resultAry, order) => {
				if (order.prn) {
					if (_.isEmpty(order.prnDispensingRecords)) {
						return resultAry
					}
					let qty = order.prnDispensingRecords.reduce((total, record) => {
						return total.plus(record.dispensingQty)
					}, Utils.BigNumber(0))
					resultAry.push({
						date: Utils.formatDate(order.startDateTime) + "\n" + Utils.formatTime(order.startDateTime),
						name: order.description,
						description: order.notes,
						categoryName: order.categoryName,
						qty: Utils.formatNumWithComma(qty.toNumber()),
						pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
						total: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).times(qty).toFixed(2)),
					})
					grandTotal = grandTotal.plus(Utils.BigNumber(order.pricePerUnit).times(qty))
					return resultAry
				} else if (order.code) {
					let qty = 0
					for (
						let i = order.startDateTime;
						i <= order.endDateTime &&
						i <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime() &&
						(!order.cancelledDateTime || i <= order.cancelledDateTime);
						i = Utils.BigNumber(i)
							.plus(order.duration * 60000)
							.toNumber()
					) {
						qty = Utils.BigNumber(qty).plus(order.durationQty).toNumber()
					}
					resultAry.push({
						date: Utils.formatDate(order.startDateTime) + "\n" + Utils.formatTime(order.startDateTime),
						name: order.description,
						description: order.notes,
						categoryName: order.categoryName,
						qty: qty,
						pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
						total: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).times(qty).toFixed(2)),
					})
					grandTotal = grandTotal.plus(Utils.BigNumber(order.pricePerUnit).times(qty))
					return resultAry
				} else if (order.serviceCode) {
					let qty = 0
					for (
						let i = order.startDateTime;
						i <= order.endDateTime &&
						i <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime() &&
						(!order.cancelledDateTime || i <= order.cancelledDateTime);
						i = Utils.BigNumber(i)
							.plus(24 * 60 * 60000)
							.toNumber()
					) {
						qty = Utils.BigNumber(qty).plus(1).toNumber()
					}
					grandTotal = grandTotal.plus(Utils.BigNumber(order.pricePerUnit).times(qty))
					resultAry.push({
						date: Utils.formatDate(order.startDateTime),
						name: order.description,
						description: order.categoryName,
						categoryName: order.categoryName,
						qty: qty,
						pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
						total: Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).times(qty).toFixed(2)),
					})
					return resultAry
				} else {
					return resultAry
				}
			}, [])

		let nurseOrders = admission.nurseOrders
			.filter((nurseOrder) => nurseOrder.startDateTime <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
			.sort((a, b) => Utils.sort(a.categoryName, b.categoryName))
			.reduce((resultAry, nurseOrder) => {
				let days = Utils.calculateDaysBetween(
					nurseOrder.startDateTime,
					Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
				)
				grandTotal = grandTotal.plus(Utils.BigNumber(nurseOrder.pricePerUnit).times(nurseOrder.qtyPerDay).times(days))
				resultAry.push({
					date: Utils.formatDate(nurseOrder.startDateTime),
					name: nurseOrder.description,
					description: nurseOrder.notes,
					categoryName: nurseOrder.categoryName,
					qty: Utils.formatNumWithComma(Utils.BigNumber(nurseOrder.qtyPerDay).times(days)),
					pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(nurseOrder.pricePerUnit).toFixed(2)),
					total: Utils.formatNumWithComma(
						Utils.BigNumber(nurseOrder.pricePerUnit).times(nurseOrder.qtyPerDay).times(days).toFixed(2)
					),
				})
				return resultAry
			}, [])

		let data = {
			titleIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.billingToTodayByAdmission }),
			admissionIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.admission }),
			dateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.date }),
			patientIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			patientIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patient }),
			dobIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			ageIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			scheduledIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.scheduled }),
			serviceIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.service }),
			descriptionIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.description }),
			pricePerUnitIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.pricePerUnit }),
			qtyIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.qty }),
			balanceIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			grandTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			admission: admission.id,
			admissionDate: Utils.formatDateTime(admission.creationDateTime),
			pid: admission.patient.id,
			patient: admission.patient.title + " " + admission.patient.firstName + " " + admission.patient.lastName,
			dob: Utils.formatDate(admission.patient.dobtime),
			age: Utils.calculateAge(admission.patient.dobtime),
			services: Utils.groupBy(
				doctorOrders.concat(nurseOrders),
				// admission.certificates.map((certificate) => {
				// 	grandTotal = grandTotal.plus(certificate.service.pricePerUnit)
				// 	return {
				// 		name: service.name,
				// 		description: service.description,
				// 		categoryName: service.categoryName,
				// 		amount: Utils.formatNumWithComma(Utils.BigNumber(service.charge).toFixed(2)),
				// 		adjustment: Utils.formatNumWithComma(Utils.BigNumber(service.adjustment).negated().toFixed(2)),
				// 		balance: Utils.formatNumWithComma(Utils.BigNumber(service.charge).minus(service.adjustment).toFixed(2)),
				// 	}
				// }),
				"categoryName"
			),
			grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.cashier.billingToToday, "_blank")
		myWindow.data = data
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.cashier.getBillingToTodayByAdmission,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				admissionId: this.props.filter.admissionId,
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.admissions)) {
				this.runReport(res.data.admissions[0])
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.billingToTodayByAdmission)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportCashierBillingToTodayFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={_.isEmpty(this.props.filter.admissionId)}
						>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.hospital.cashier.isBillingToTodayDisplay,
	filter: state.report.cashier.billingToToday,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierBillingToTodayFilter))
