import { ResourceAssistance } from "~/i18n"
import {
	SET_ADMIN_FORM_DISPLAY_ALL_IND,
	SET_ADMIN_FORM_FORMS,
	SET_ADMIN_FORM_FORMS_LOADING,
	SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE,
	SET_ADMIN_FORM_MODAL_NEW_FORM_DESC,
	SET_ADMIN_FORM_MODAL_NEW_FORM_NAME,
	SET_ADMIN_FORM_SEARCH_NAME,
	SET_ADMIN_FORM_SELECTED_FORM,
	SET_ADMIN_ITEM_DISPLAY_ALL_IND,
	SET_ADMIN_ITEM_ITEMS,
	SET_ADMIN_ITEM_ITEMS_LOADING,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS,
	SET_ADMIN_ITEM_SEARCH_CODE,
	SET_ADMIN_ITEM_SEARCH_NAME,
	SET_ADMIN_ITEM_SELECTED_ITEM,
	SET_ADMIN_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND,
	SET_ADMIN_ITEM_SOURCE_ITEMS,
	SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES,
	SET_ADMIN_ITEM_SOURCE_SEARCH_NAME,
	SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM,
	SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE,
	SET_ADMIN_ITEM_SOURCE_TYPES,
	SET_ADMIN_ITEM_TYPES,
	SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME,
	SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE,
	SET_ADMIN_SUPPLIER_SEARCH_CODE,
	SET_ADMIN_SUPPLIER_SEARCH_NAME,
	SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER,
	SET_ADMIN_SUPPLIER_SUPPLIERS,
	SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING,
	SET_ADMIN_TAX_DISPLAY_ALL_IND,
	SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE,
	SET_ADMIN_TAX_MODAL_NEW_TAX_DESC,
	SET_ADMIN_TAX_MODAL_NEW_TAX_NAME,
	SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE,
	SET_ADMIN_TAX_SEARCH_NAME,
	SET_ADMIN_TAX_SELECTED_TAX,
	SET_ADMIN_TAX_TAXS,
	SET_ADMIN_TAX_TAXS_LOADING,
	SET_ADMIN_UNIT_DISPLAY_ALL_IND,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME,
	SET_ADMIN_UNIT_SEARCH_NAME,
	SET_ADMIN_UNIT_SELECTED_UNIT,
	SET_ADMIN_UNIT_UNITS,
	SET_ADMIN_UNIT_UNITS_LOADING,
} from "~/redux/type"

const init = {
	item: {
		searchCode: "",
		searchName: "",
		displayInactive: true,
		types: [],
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.genericName,
				ResourceAssistance.Message.subunit,
				ResourceAssistance.Message.medicationUsage,
				ResourceAssistance.Message.tradeNameOrBrand,
				ResourceAssistance.Message.supplier,
				ResourceAssistance.Hospitel.healthCarePlan,
				ResourceAssistance.Message.minQtyPerOrder,
				ResourceAssistance.Message.bonus,
				ResourceAssistance.Message.pricePerOrder,
				ResourceAssistance.Message.discountWithSymbol,
				ResourceAssistance.Message.taxRateWithSymbol,
				ResourceAssistance.Message.pricePerUnit,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.categoryNum,
				ResourceAssistance.Message.by,
			],
			body: [],
			colStyle: [],
			isLoading: false,
		},
		modalNewItem: {
			searchName: "",
			code: "",
			dose: "",
			brand: "",
			medicationUsage: "",
			healthcarePlan: "",
			pricePerUnit: "",
			discount: "",
			qtyPerOrder: "",
			bonus: "",
			fsCode: "",
			categoryNum: "",
			sellPricePerUnit: "",
			isActive: true,
			types: [],
			forms: [],
			units: [],
			suppliers: [],
			taxes: [],
			healthcarePlans: [],
			itemTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.genericName],
				body: [],
			},
			selectedItem: {
				index: -1,
				item: null,
			},
			selectedType: {
				index: -1,
				type: null,
			},
			selectedForm: {
				index: -1,
				form: null,
			},
			selectedUnit: {
				index: -1,
				unit: null,
			},
			selectedSupplier: {
				index: -1,
				supplier: null,
			},
			selectedTax: {
				index: -1,
				tax: null,
			},
		},
	},
	itemSource: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.keyword,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
		modalNewItem: {
			name: "",
			keyword: "",
			isActive: true,
			types: [],
			selectedType: {
				index: -1,
				type: null,
			},
		},
	},
	supplier: {
		searchCode: "",
		searchName: "",
		displayInactive: true,
		selectedSupplier: {
			index: -1,
			supplier: null,
		},
		supplierTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.phone,
				ResourceAssistance.Message.address,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
		newSupplierModal: {
			code: "",
			name: "",
			phone: "",
			address: "",
			isActive: true,
		},
	},
	unit: {
		searchName: "",
		displayInactive: true,
		selectedUnit: {
			index: -1,
			unit: null,
		},
		unitTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
		modalNewUnit: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	form: {
		searchName: "",
		displayInactive: true,
		selectedForm: {
			index: -1,
			form: null,
		},
		formTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
		modalNewForm: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	tax: {
		searchName: "",
		displayInactive: true,
		selectedTax: {
			index: -1,
			tax: null,
		},
		taxTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.taxRateWithSymbol,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
		modalNewTax: {
			name: "",
			description: "",
			taxRate: "",
			isActive: true,
		},
	},
}

const itemConfigReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADMIN_ITEM_SOURCE_ITEMS:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					itemTable: {
						...state.itemSource.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					itemTable: {
						...state.itemSource.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADMIN_ITEM_SOURCE_TYPES:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					types: action.payload.types,
				},
			})

		case SET_ADMIN_ITEM_SOURCE_SEARCH_NAME:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					selectedType: {
						index: action.payload.index,
						type: action.payload.type,
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					modalNewItem: {
						...state.itemSource.modalNewItem,
						types: action.payload.types,
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					modalNewItem: {
						...state.itemSource.modalNewItem,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					modalNewItem: {
						...state.itemSource.modalNewItem,
						keyword: action.payload.value,
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					modalNewItem: {
						...state.itemSource.modalNewItem,
						selectedType: {
							index: action.payload.index,
							type: action.payload.type,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE:
			return Object.assign({}, state, {
				itemSource: {
					...state.itemSource,
					modalNewItem: {
						...state.itemSource.modalNewItem,
						isActive: action.payload.isActive,
					},
				},
			})

		case SET_ADMIN_ITEM_ITEMS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					itemTable: {
						...state.item.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_ADMIN_ITEM_ITEMS_LOADING:
			return Object.assign({}, state, {
				item: {
					...state.item,
					itemTable: {
						...state.item.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})

		case SET_ADMIN_ITEM_TYPES:
			return Object.assign({}, state, {
				item: {
					...state.item,
					types: action.payload.types,
				},
			})

		case SET_ADMIN_ITEM_SELECTED_TYPE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					selectedType: {
						index: action.payload.index,
						type: action.payload.type,
					},
				},
			})

		case SET_ADMIN_ITEM_SEARCH_CODE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					searchCode: action.payload.text,
				},
			})

		case SET_ADMIN_ITEM_SEARCH_NAME:
			return Object.assign({}, state, {
				item: {
					...state.item,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_ITEM_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				item: {
					...state.item,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_ITEM_SELECTED_ITEM:
			return Object.assign({}, state, {
				item: {
					...state.item,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						itemTable: {
							...state.item.modalNewItem.itemTable,
							original: action.payload.original,
							filtered: action.payload.filtered,
							body: action.payload.body,
							rowColor: action.payload.rowColor,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						types: action.payload.types,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						forms: action.payload.forms,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						units: action.payload.units,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						suppliers: action.payload.suppliers,
					},
				},
			})
		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						taxes: action.payload.taxes,
					},
				},
			})
		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						healthcarePlans: action.payload.objs,
					},
				},
			})
		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						healthcarePlan: action.payload.value,
					},
				},
			})
		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						searchName: action.payload.text,
					},
				},
			})
		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						code: action.payload.code,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						dose: action.payload.dose,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						brand: action.payload.brand,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						medicationUsage: action.payload.value,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						fsCode: action.payload.value,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						sellPricePerUnit: action.payload.value,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						pricePerUnit: action.payload.price,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						discount: action.payload.discount,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						qtyPerOrder: action.payload.qty,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						bonus: action.payload.bonus,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						isActive: action.payload.isActive,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedType: {
							index: action.payload.index,
							type: action.payload.type,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedItem: {
							index: action.payload.index,
							item: action.payload.item,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedForm: {
							index: action.payload.index,
							form: action.payload.form,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedUnit: {
							index: action.payload.index,
							unit: action.payload.unit,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedSupplier: {
							index: action.payload.index,
							supplier: action.payload.supplier,
						},
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						selectedTax: {
							index: action.payload.index,
							tax: action.payload.tax,
						},
					},
				},
			})

		case SET_ADMIN_SUPPLIER_SEARCH_CODE:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					searchCode: action.payload.text,
				},
			})

		case SET_ADMIN_SUPPLIER_SEARCH_NAME:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					searchName: action.payload.text,
				},
			})
		case SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					displayInactive: action.payload.isDisplay,
				},
			})
		case SET_ADMIN_SUPPLIER_SUPPLIERS:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					supplierTable: {
						...state.supplier.supplierTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					supplierTable: {
						...state.supplier.supplierTable,
						isLoading: action.payload.isLoading,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					selectedSupplier: {
						...state.supplier.selectedSupplier,
						index: action.payload.index,
						supplier: action.payload.supplier,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					newSupplierModal: {
						...state.supplier.newSupplierModal,
						code: action.payload.code,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					newSupplierModal: {
						...state.supplier.newSupplierModal,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					newSupplierModal: {
						...state.supplier.newSupplierModal,
						phone: action.payload.phone,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					newSupplierModal: {
						...state.supplier.newSupplierModal,
						address: action.payload.address,
					},
				},
			})

		case SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					newSupplierModal: {
						...state.supplier.newSupplierModal,
						isActive: action.payload.isActive,
					},
				},
			})
		case SET_ADMIN_UNIT_UNITS:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					unitTable: {
						...state.unit.unitTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADMIN_UNIT_UNITS_LOADING:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					unitTable: {
						...state.unit.unitTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADMIN_UNIT_SEARCH_NAME:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_UNIT_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_UNIT_SELECTED_UNIT:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					selectedUnit: {
						index: action.payload.index,
						unit: action.payload.unit,
					},
				},
			})

		case SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					modalNewUnit: {
						...state.unit.modalNewUnit,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					modalNewUnit: {
						...state.unit.modalNewUnit,
						description: action.payload.desc,
					},
				},
			})
		case SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE:
			return Object.assign({}, state, {
				unit: {
					...state.unit,
					modalNewUnit: {
						...state.unit.modalNewUnit,
						isActive: action.payload.isActive,
					},
				},
			})
		case SET_ADMIN_FORM_FORMS:
			return Object.assign({}, state, {
				form: {
					...state.form,
					formTable: {
						...state.form.formTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADMIN_FORM_FORMS_LOADING:
			return Object.assign({}, state, {
				form: {
					...state.form,
					formTable: {
						...state.form.formTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADMIN_FORM_SEARCH_NAME:
			return Object.assign({}, state, {
				form: {
					...state.form,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_FORM_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				form: {
					...state.form,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_FORM_SELECTED_FORM:
			return Object.assign({}, state, {
				form: {
					...state.form,
					selectedForm: {
						index: action.payload.index,
						form: action.payload.form,
					},
				},
			})

		case SET_ADMIN_FORM_MODAL_NEW_FORM_NAME:
			return Object.assign({}, state, {
				form: {
					...state.form,
					modalNewForm: {
						...state.form.modalNewForm,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_FORM_MODAL_NEW_FORM_DESC:
			return Object.assign({}, state, {
				form: {
					...state.form,
					modalNewForm: {
						...state.form.modalNewForm,
						description: action.payload.desc,
					},
				},
			})
		case SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE:
			return Object.assign({}, state, {
				form: {
					...state.form,
					modalNewForm: {
						...state.form.modalNewForm,
						isActive: action.payload.isActive,
					},
				},
			})
		case SET_ADMIN_TAX_TAXS:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					taxTable: {
						...state.tax.taxTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADMIN_TAX_TAXS_LOADING:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					taxTable: {
						...state.tax.taxTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADMIN_TAX_SEARCH_NAME:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_TAX_SELECTED_TAX:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					selectedTax: {
						index: action.payload.index,
						tax: action.payload.tax,
					},
				},
			})

		case SET_ADMIN_TAX_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_TAX_MODAL_NEW_TAX_NAME:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					modalNewTax: {
						...state.tax.modalNewTax,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_TAX_MODAL_NEW_TAX_DESC:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					modalNewTax: {
						...state.tax.modalNewTax,
						description: action.payload.desc,
					},
				},
			})

		case SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					modalNewTax: {
						...state.tax.modalNewTax,
						taxRate: action.payload.taxRate,
					},
				},
			})

		case SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE:
			return Object.assign({}, state, {
				tax: {
					...state.tax,
					modalNewTax: {
						...state.tax.modalNewTax,
						isActive: action.payload.isActive,
					},
				},
			})

		case SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM:
			return Object.assign({}, state, {
				item: {
					...state.item,
					modalNewItem: {
						...state.item.modalNewItem,
						categoryNum: action.payload.value,
					},
				},
			})

		default:
			return state
	}
}

export default itemConfigReducer
