import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "~/view/component/input/GInput"
import { setValue } from "~/redux/action"
import { SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER } from "~/redux/type"

class RegModalClinicalRecordCarAccident extends React.Component {
	ERadio = {
		DRIVER: "DRIVER",
		PASSENGER: "PASSENGER",
		PEDESTRIAN: "PEDESTRIAN",
	}
	constructor(props) {
		super(props)

		this.state = {
			victim: this.ERadio.DRIVER,
			typeOfIncident: "",
			timeOfIncident: "",
			placeOfIncident: "",
			policeStation: "",
			policeName: "",
		}

		this.onVictim = this.onVictim.bind(this)
		this.onTypeOfIncident = this.onTypeOfIncident.bind(this)
		this.onTimeOfIncident = this.onTimeOfIncident.bind(this)
		this.onPlaceOfIncident = this.onPlaceOfIncident.bind(this)
		this.onPoliceStation = this.onPoliceStation.bind(this)
		this.onPoliceName = this.onPoliceName.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.modifyHealthcareOther()
		}
	}

	modifyHealthcareOther() {
		let description = ""

		if (!_.isEmpty(this.state.typeOfIncident)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.typeOfIncident }),
				": ",
				this.state.typeOfIncident,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.timeOfIncident)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.timeOfIncident }),
				": ",
				this.state.timeOfIncident,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.placeOfIncident)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.placeOfIncident }),
				": ",
				this.state.placeOfIncident,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.policeStation)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.policeStation }),
				": ",
				this.state.policeStation,
				"\n"
			)
		}
		if (!_.isEmpty(this.state.policeName)) {
			description = description.concat(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.policeName }),
				": ",
				this.state.policeName,
				"\n"
			)
		}

		switch (this.state.victim) {
			case this.ERadio.DRIVER:
				description = description.concat(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.victim }),
					": ",
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.driver })
				)
				break
			case this.ERadio.PASSENGER:
				description = description.concat(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.victim }),
					": ",
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.passenger })
				)
				break
			case this.ERadio.PEDESTRIAN:
				description = description.concat(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.victim }),
					": ",
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.pedestrian })
				)
				break
			default:
				break
		}
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, description)
	}

	onTypeOfIncident(e) {
		this.setState({
			typeOfIncident: e.target.value,
		})
	}

	onTimeOfIncident(e) {
		this.setState({
			timeOfIncident: e.target.value,
		})
	}

	onPlaceOfIncident(e) {
		this.setState({
			placeOfIncident: e.target.value,
		})
	}

	onPoliceStation(e) {
		this.setState({
			policeStation: e.target.value,
		})
	}

	onPoliceName(e) {
		this.setState({
			policeName: e.target.value,
		})
	}

	onVictim(e) {
		this.setState({
			victim: e.target.value,
		})
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.carAccident)}</legend>
				<Row>
					<Col>
						<GInput
							required
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.typeOfIncident })}
							onChange={this.onTypeOfIncident}
							autoSize
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.timeOfIncident })}
							onChange={this.onTimeOfIncident}
							autoSize
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.placeOfIncident })}
							onChange={this.onPlaceOfIncident}
							autoSize
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.policeStation })}
							onChange={this.onPoliceStation}
							autoSize
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.policeName })}
							onChange={this.onPoliceName}
							autoSize
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<FormLabel>{translate(ResourceAssistance.Message.victim)}</FormLabel>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<Radio.Group
							options={[
								{ label: translate(ResourceAssistance.Message.driver), value: this.ERadio.DRIVER },
								{ label: translate(ResourceAssistance.Message.passenger), value: this.ERadio.PASSENGER },
								{ label: translate(ResourceAssistance.Message.pedestrian), value: this.ERadio.PEDESTRIAN },
							]}
							value={this.state.victim}
							onChange={this.onVictim}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalClinicalRecordCarAccident))
