import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	setInventoryLocs,
	setInventoryOrgs,
	setInventorySelectedType,
	setInventorySuppliers,
	setInventoryTypes,
	setLoadingAction,
} from "~/redux/action"
import { SET_INVENTORY_LOC_LOADING, SET_INVENTORY_SUPPLIERS_LOADING, SET_INVENTORY_TYPE_LOADING } from "~/redux/type"
import "./inventory.css"
import InventoryFirstRow from "./InventoryFirstRow"
import InventoryFourthRow from "./InventoryFourthRow"
import InventorySecondRow from "./InventorySecondRow"
import InventoryThirdRow from "./InventoryThirdRow"

class Inventory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Inventory",
		}
	}

	componentDidMount() {
		this.loadTypes()
		this.loadLocations()
		this.loadAllItems()
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.inventory.getLocations,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			if (res.data.locations.length > 0) {
				this.props.setInventoryLocs(res.data.locations)
				this.props.setInventoryOrgs(res.data.locations)
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_LOC_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_LOC_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.inventory.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			if (res.data.uts.length > 0) {
				this.props.setInventoryTypes(res.data.uts)

				let type = document.getElementById(ResourceAssistance.ID.INV.type)
				this.props.setInventorySelectedType(type.selectedIndex, this.props.inventory.type.types[type.selectedIndex])
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_TYPE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	loadAllItems() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.inventory.getActiveItems}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}

		let callback = ({ data }) => {
			this.props.setInventorySuppliers(data.items)
		}

		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_INVENTORY_SUPPLIERS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_INVENTORY_SUPPLIERS_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className="full-flex">
				<Row noGutters className="full-flex">
					<Col>
						<Container fluid id={this.state.componentName} className="full-flex">
							<InventoryFirstRow {...this.props} />
							<InventorySecondRow />
							<InventoryThirdRow />
							<InventoryFourthRow />
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}
const mapStateToProps = (state) => ({
	inventory: state.inventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setInventoryLocs,
			setInventoryOrgs,
			setLoadingAction,
			setInventoryTypes,
			setInventorySelectedType,
			setInventorySuppliers,
		},
		dispatch
	),
	dispatch,
})
export default connect(mapStateToProps, mapDispatchToProps)(Inventory)
