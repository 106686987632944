import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setRcOrders } from "~/redux/action"
import { SET_RC_ORDERS_LOADING } from "~/redux/type/type/RC"
import RCBottom from "./rc_bottom/RCBottom"
import RCLeft from "./rc_left/RCLeft"
import RCRight from "./rc_right/RCRight"
import "./receive.css"

class Receive extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Receive",
		}

		this.loadReceives = this.loadReceives.bind(this)
		this.updateReceive = this.updateReceive.bind(this)
	}

	componentDidMount() {
		this.loadReceives()
	}

	loadReceives() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.rc.getReceives,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: this.props.rc.searchDate,
		}

		let callback = (res) => {
			this.props.setRcOrders(res.data.receives)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	updateReceive(receives) {
		let original = this.props.rc.order.original
		receives.forEach((receive) => {
			let index = original.findIndex((original) => original.id === receive.id)
			original[index] = receive
		})
		this.props.setRcOrders(original)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<Row noGutters>
					<Col md="auto">
						<RCLeft loadReceives={this.loadReceives} updateReceive={this.updateReceive} />
					</Col>
					<Col>
						<RCRight />
					</Col>
				</Row>
				<Row noGutters id="RcBottomRow">
					<Col>
						<RCBottom />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	rc: state.receive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcOrders, setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Receive)
