import propTypes from "prop-types"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class HospitelModalPatientClinicalRecordPatient extends React.Component {
	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
				<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
					<Col md="auto">
						<FormLabel>
							{translate(ResourceAssistance.Message.name)}
							{ResourceAssistance.Symbol.colon}
						</FormLabel>
					</Col>
					<Col md="auto">
						<FormLabel className={"italic-text"}>
							{this.props.patient.title + " " + this.props.patient.firstName + " " + this.props.patient.lastName}
						</FormLabel>
					</Col>
					<Col />
					<Col md="auto">
						<FormLabel>
							{translate(ResourceAssistance.Hospitel.dateOfBirth)}
							{ResourceAssistance.Symbol.colon}
						</FormLabel>
					</Col>
					<Col md="auto">
						<FormLabel className={"italic-text"}>{Utils.formatDate(this.props.patient.dobtime)}</FormLabel>
					</Col>
					<Col />
					<Col md="auto">
						<FormLabel>
							{translate(ResourceAssistance.Hospitel.age)}
							{ResourceAssistance.Symbol.colon}
						</FormLabel>
					</Col>
					<Col md="auto">
						<FormLabel className={"italic-text"}>{Utils.calculateAge(this.props.patient.dobtime)}</FormLabel>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

HospitelModalPatientClinicalRecordPatient.propTypes = {
	patient: propTypes.object,
}

HospitelModalPatientClinicalRecordPatient.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitelModalPatientClinicalRecordPatient
