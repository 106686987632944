import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_ORDER_END_DATE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelRightNurseActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onEndDate = this.onEndDate.bind(this)
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_ORDER_END_DATE, date.getTime())
	}

	render() {
		return (
			<Row>
				<Col md={"auto"}>
					<DatePicker
						isShowTime={false}
						minDate={Utils.generateDateFromLong(
							this.props.hospitel.selectedHospitel.hospitel
								? this.props.hospitel.selectedHospitel.hospitel.creationDateTime
								: Utils.generateDate().getTime()
						)}
						maxDate={
							this.props.hospitel.selectedHospitel.hospitel &&
							this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
								? Utils.generateDateFromLong(
										this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime
								  )
								: null
						}
						showDisabledMonthNavigation
						disabled={this.props.hospitel.selectedHospitel.index === -1}
						startDate={Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime)}
						onChange={this.onEndDate}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightNurseActionRow))
