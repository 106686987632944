import React from "react"
import { injectIntl } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import Administration from "./administration/Administration"
import "./home.css"
import Hospital from "./hospital/Hospital"
import Management from "./stock-manager/Management"
import ManagementReport from "./stock-manager/ManagementReport"

export const EType = {
	INV: "INV",
	TF: "TF",
	PR: "PR",
	PO: "PO",
	RC: "RC",
	TYPE: "TYPE",
	SERVICE: "SERVICE",
	CUSTOM_DEFAULT: "CUSTOM_DEFAULT",
	LOCATION: "LOCATION",
	ITEM: "ITEM",
	ICD: "ICD",
	USER: "USER",
	LABORATORY: "LABORATORY",
	REPORT: {
		TF: "REPORT_TF",
		PR: "REPORT_PR",
		PO: "REPORT_PO",
		RC: "REPORT_RC",
		INV: "REPORT_INV",
	},
	HOSPITAL: {
		DEPOSIT: "HS_DEPOSIT",
		CASHIER: "HS_CASHIER",
		DEBT: "HS_DEBT",
		IPD: "HS_IPD",
		COVID19: "HS_COVID19",
		REGISTRATION: "HS_REGISTRATION",
		RADIOLOGY: "HIS_RADIOLOGY",
		OPD: "HS_OPD",
		PHARMACY: "HS_PHARMACY",
		FRONT_INVENTORY: "HS_FRONT_INVENTORY",
		REPORT: {
			CASHIER: "HS_REPORT_CASHIER",
			IPD: "HS_REPORT_IPD",
			LAB: "HS_REPORT_LAB",
		},
	},
}

class Home extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)
		this.state = {
			componentName: "Home",
		}

		this.onBtnClick = this.onBtnClick.bind(this)
	}

	onBtnClick(event) {
		switch (event.target.value) {
			case EType.INV:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.inventory)
				break

			case EType.TF:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.transfer })
				this.props.history.push(ResourceAssistance.Path.transfer)
				break

			case EType.PR:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseRequest })
				this.props.history.push(ResourceAssistance.Path.pr)
				break

			case EType.PO:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseOrder })
				this.props.history.push(ResourceAssistance.Path.po)
				break

			case EType.RC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.receive })
				this.props.history.push(ResourceAssistance.Path.rc)
				break

			case EType.USER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.user })
				this.props.history.push(ResourceAssistance.Path.configUser)
				break

			case EType.TYPE:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.type })
				this.props.history.push(ResourceAssistance.Path.configType)
				break

			case EType.LABORATORY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.lab })
				this.props.history.push(ResourceAssistance.Path.configLaboratory)
				break

			case EType.LOCATION:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.location })
				this.props.history.push(ResourceAssistance.Path.configLoc)
				break

			case EType.ITEM:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.item })
				this.props.history.push(ResourceAssistance.Path.configItem)
				break

			case EType.SERVICE:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.service })
				this.props.history.push(ResourceAssistance.Path.configService)
				break

			case EType.CUSTOM_DEFAULT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.default })
				this.props.history.push(ResourceAssistance.Path.configCustomDefault)
				break

			case EType.ICD:
				document.title = this.props.intl.formatMessage({
					id: ResourceAssistance.Message.internationalClassificationDiseases,
				})
				this.props.history.push(ResourceAssistance.Path.configICD)
				break

			case EType.REPORT.INV:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.reportInv)
				break

			case EType.REPORT.TF:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.transfer })
				this.props.history.push(ResourceAssistance.Path.reportTf)
				break

			case EType.REPORT.PR:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseRequest })
				this.props.history.push(ResourceAssistance.Path.reportPr)
				break

			case EType.REPORT.PO:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseOrder })
				this.props.history.push(ResourceAssistance.Path.reportPo)
				break

			case EType.REPORT.RC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.receive })
				this.props.history.push(ResourceAssistance.Path.reportRc)
				break

			//Hospital
			case EType.HOSPITAL.DEPOSIT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.deposit })
				this.props.history.push(ResourceAssistance.Path.HIS.deposit)
				break
			case EType.HOSPITAL.CASHIER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier })
				this.props.history.push(ResourceAssistance.Path.Hospitel.cashier)
				break
			case EType.HOSPITAL.DEBT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.debt })
				this.props.history.push(ResourceAssistance.Path.HIS.debt)
				break
			case EType.HOSPITAL.REGISTRATION:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.registration })
				this.props.history.push(ResourceAssistance.Path.Hospitel.registration)
				break
			case EType.HOSPITAL.IPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.ipd })
				this.props.history.push(ResourceAssistance.Path.Hospitel.hospitel)
				break
			case EType.HOSPITAL.COVID19:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.covid19Lab })
				this.props.history.push(ResourceAssistance.Path.Hospitel.covid19Lab)
				break
			case EType.HOSPITAL.RADIOLOGY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.radiology })
				this.props.history.push(ResourceAssistance.Path.HIS.radiology)
				break
			case EType.HOSPITAL.OPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.opd })
				this.props.history.push(ResourceAssistance.Path.HIS.opd)
				break
			case EType.HOSPITAL.PHARMACY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.pharmacy })
				this.props.history.push(ResourceAssistance.Path.HIS.pharmacy)
				break
			case EType.HOSPITAL.FRONT_INVENTORY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.HIS.frontInventory)
				break

			//HIS Report
			case EType.HOSPITAL.REPORT.CASHIER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportCashier)
				break

			case EType.HOSPITAL.REPORT.IPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.ipd })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportHospitel)
				break

			case EType.HOSPITAL.REPORT.LAB:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.covid19Lab })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportLab)
				break

			default:
				break
		}
	}

	render() {
		return (
			<div id={this.state.componentName}>
				{/* Hospital */}
				<Hospital onBtnClick={this.onBtnClick} />

				{/* Management */}
				<Management onBtnClick={this.onBtnClick} />

				{/* Report */}
				<ManagementReport onBtnClick={this.onBtnClick} />

				{/* Admin */}
				<Administration onBtnClick={this.onBtnClick} />
			</div>
		)
	}
}

export default injectIntl(Home)
