import _ from "lodash"
import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setTfRequests } from "~/redux/action"
import { SET_TF_REQUEST_LOADING } from "~/redux/type"
import { SET_TF_NEW_REQUEST_DISPLAY } from "~/redux/type/type/MODAL"
import TFModalNItemRow from "./TFModalNRItemRow"
import TFModalNLocationRow from "./TFModalNRLocationRow"
import TFModalNPendingItemRow from "./TFModalNRPendingItemRow"
import TFModalNSearchRow from "./TFModalNRSearchRow"

class TFModalNR extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "TFModalNR",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_TF_NEW_REQUEST_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			_.isEqual(
				this.props.transfer.newRequestModal.pendingItemTable.filtered,
				this.props.transfer.requestItemTable.filtered
			) &&
			this.props.transfer.selectedRequest.request &&
			this.props.transfer.newRequestModal.selectedToLocation.location &&
			this.props.transfer.selectedRequest.request.location.id ===
				this.props.transfer.newRequestModal.selectedToLocation.location.id
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.createTR,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request ? this.props.transfer.selectedRequest.request.id : 0,
				userId: this.props.login.user.id,
				orgId: this.props.transfer.newRequestModal.selectedToOrg.location.id,
				branchId: this.props.transfer.newRequestModal.selectedToBranch.location.id,
				locationId: this.props.transfer.newRequestModal.selectedToLocation.location.id,
				items: this.props.transfer.newRequestModal.pendingItemTable.original.map((item) => {
					return {
						transferRequestItemId: item.id,
						typeId: item.type.id,
						userId: this.props.login.user.id,
						code: item.code,
						name: item.displayName ? item.displayName : item.name,
						unit: item.unit,
						brand: item.brand,
						amount: item.amount,
						dueDateTime: item.dueDateTime,
					}
				}),
			},
		}

		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original
			if (res.data.requests.length > 0) {
				requests = requests.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
			} else {
				requests = requests.filter((each) => each.id !== this.props.transfer.selectedRequest.request.id)
			}
			this.props.setTfRequests(requests)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.onClose()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newRequest)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<TFModalNLocationRow />
							<TFModalNSearchRow />
							<TFModalNItemRow />
							<TFModalNPendingItemRow />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isTFNewRequestDisplay,
	transfer: state.transfer,
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setTfRequests }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNR)
