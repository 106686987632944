import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GiStopwatch } from "react-icons/gi"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDPatients,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OPD_MODIFY_DATE_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OutpatientRightDoctorOrderAction extends React.Component {
	constructor(props) {
		super(props)

		this.onChangeEndDate = this.onChangeEndDate.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteDoctorOrder)
			) {
				this.deleteDoctorOrder()
			}
		}
	}

	deleteDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteDoctorOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				doctorOrders: [
					{
						id: this.props.opd.selectedDoctorOrder.order.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onChangeEndDate() {
		this.props.onModalDisplayAction(SET_OPD_MODIFY_DATE_DISPLAY, true)
	}

	onDelete() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteDoctorOrder)
		this.props.setValue(SET_MODAL_WARNING_SIZE, undefined)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
            ${this.props.opd.selectedDoctorOrder.order.description}
            ${this.props.opd.selectedDoctorOrder.order.notes}
            ${Utils.formatDateTime(this.props.opd.selectedDoctorOrder.order.startDateTime)} - ${Utils.formatDateTime(
				this.props.opd.selectedDoctorOrder.order.endDateTime
			)}`
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md={"auto"}>
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.warning}
						disabled={
							this.props.opd.selectedMedicalRecord.index === -1 ||
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0 ||
							this.props.opd.selectedDoctorOrder.index === -1 ||
							this.props.opd.selectedDoctorOrder.order.cancelledDateTime
						}
						onClick={this.onChangeEndDate}
					>
						<GiStopwatch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.modifyDateRange)}
					</Button>
				</Col>
				<Col md={"auto"}>
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.red}
						disabled={
							this.props.opd.selectedMedicalRecord.index === -1 ||
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0 ||
							this.props.opd.selectedDoctorOrder.index === -1 ||
							this.props.opd.selectedDoctorOrder.order.cancelledDateTime
						}
						onClick={this.onDelete}
					>
						<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.delete)}
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			onModalDisplayAction,
			setLoadingAction,
			setWarningMsgAction,
			setWarningId,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDoctorOrderAction))
