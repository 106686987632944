import React, { Component } from "react"
import {
	Button,
	Col,
	Container,
	Form,
	FormControl,
	FormLabel,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
	Spinner,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { MdAddShoppingCart } from "react-icons/md"
import { VscSaveAs } from "react-icons/vsc"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	loadOutOfStockInventoryAction,
	onModalDisplayAction,
	selectInventoryAction,
	selectPRAction,
	setInventoryPropertyAction,
	setPrMinLastPurchasedInfo,
	setSearchText,
	setSelectedInventoriesAction,
} from "~/redux/action"
import {
	PR_MIN_STOCK,
	SET_PROPERTY_AMOUNT,
	SET_PROPERTY_LABEL,
	SET_PR_MIN_STOCK_SEARCH_CODE,
	SET_PR_MIN_STOCK_SEARCH_NAME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class MinStock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "MinStock",
			isAddItemValid: false,
			isAmountSet: false,
		}

		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onSelectInventoryClick = this.onSelectInventoryClick.bind(this)
		this.onSelectedInventoryDoubleClick = this.onSelectedInventoryDoubleClick.bind(this)
		this.onCodeChange = this.onCodeChange.bind(this)
		this.onNameChange = this.onNameChange.bind(this)
		this.onAddInventory = this.onAddInventory.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				this.props.loadOutOfStockInventoryAction(this.props.pr.location.locations)
			} else {
				this.props.setPrMinLastPurchased("", "")
			}
		}

		if (this.props.isDisplay) {
			if (
				JSON.stringify(prevProps.pr.location.selectedOrg.org) !==
					JSON.stringify(this.props.pr.location.selectedOrg.org) ||
				JSON.stringify(prevProps.pr.location.selectedBranch.branch) !==
					JSON.stringify(this.props.pr.location.selectedBranch.branch) ||
				prevProps.minStock.searchName !== this.props.minStock.searchName ||
				prevProps.minStock.searchCode !== this.props.minStock.searchCode
			) {
				this.props.loadOutOfStockInventoryAction(this.props.pr.location.locations)
				this.props.selectInventoryAction(-1, null)
			}

			if (
				JSON.stringify(prevProps.minStock.selectedInventory) !== JSON.stringify(this.props.minStock.selectedInventory)
			) {
				if (this.props.minStock.selectedInventory.index === -1) {
					this.props.setPrMinLastPurchased("", "")
				} else {
					this.props.setPrMinLastPurchased(
						this.props.minStock.selectedInventory.inventory.lastPurchasedFrom,
						this.props.minStock.selectedInventory.inventory.lastPurchasedDate
							? this.props.minStock.selectedInventory.inventory.lastPurchasedDate.toLocaleDateString()
							: ""
					)
				}
			}

			if (prevProps.minStock.selectedAmount !== this.props.minStock.selectedAmount) {
				this.validate()
			}
		}
	}

	reset() {
		this.setState({
			isAmountSet: false,
		})

		this.props.reset()
		this.props.selectInventoryAction(-1, null)
		this.props.setSelectedInventoriesAction([])
		this.props.setInventoryPropertyAction(SET_PROPERTY_LABEL, "")
		this.props.setInventoryPropertyAction(SET_PROPERTY_AMOUNT, "")

		this.props.onModalDisplayAction(PR_MIN_STOCK, false)
	}

	updateProperties() {
		this.setState({
			isAmountSet: false,
		})

		if (this.props.minStock.selectedInventory.index > -1) {
		}

		this.setState({
			isAmountSet: this.props.minStock.selectedInventory.index > -1,
		})
	}

	validate() {
		let amountHtml = document.getElementById(ResourceAssistance.ID.PR.modalMinStock.amount)
		if (
			!Utils.BigNumber(this.props.minStock.selectedAmount)
				.dividedBy(this.props.minStock.selectedInventory.inventory.minQtyPerOrder)
				.isInteger()
		) {
			amountHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongAmount }))
		} else {
			amountHtml.setCustomValidity("")
		}
	}

	onAddInventory(event) {
		const form = event.currentTarget
		if (form.checkValidity() === false) {
			this.setState({
				isAddItemValid: true,
			})
		}
		event.preventDefault()
		event.stopPropagation()

		let selectedInventory = Object.assign({}, this.props.minStock.selectedInventory.inventory, {
			amount: Number(this.props.minStock.selectedAmount),
		})

		let selectedInventories = this.props.minStock.selectedInventories.inventories.reduce((obj, item) => {
			obj[item.itemSupplierRelpId] = item
			return obj
		}, {})

		Object.assign(selectedInventories, {
			[selectedInventory.itemSupplierRelpId]: selectedInventory,
		})

		if (!this.props.validateItem([selectedInventory])) {
			return
		}

		this.props.setSelectedInventoriesAction(Object.keys(selectedInventories).map((key) => selectedInventories[key]))
	}

	onCloseBtn(event) {
		this.reset()
	}

	onDoneBtn(event) {
		let param = JSON.stringify({
			userId: this.props.login.user.id,
			locationId: this.props.pr.location.selectedBranch.branch.id,
			label: this.props.minStock.selectedLabel,
			items: this.props.minStock.selectedInventories.inventories.map((inventory) => {
				return {
					typeId: inventory.typeId,
					itemCode: inventory.code,
					itemName: inventory.displayName,
					brand: inventory.brand,
					unit: inventory.unit,
					supplierCode: inventory.supplier.code,
					supplierName: inventory.supplier.displayName,
					supplierPhone: inventory.supplier.phone,
					supplierAddress: inventory.supplier.address,
					amount: inventory.amount,
					bonus: inventory.bonus,
					minQtyPerOrder: inventory.minQtyPerOrder,
					pricePerUnit: inventory.pricePerUnit,
					discount: inventory.discount,
					taxRate: inventory.taxRate,
				}
			}),
		})

		this.props.fetch(param)
		this.reset()
	}

	onPropertyChange(event, type) {
		switch (type) {
			default:
				this.props.setInventoryPropertyAction(type, event.target.value)
				break
		}
		this.updateProperties()
	}

	onSelectInventoryClick(item, index) {
		let selectedInventory = this.props.minStock.filtered[index]
		this.props.selectInventoryAction(index, selectedInventory)
	}

	onNameChange(event) {
		this.props.setSearchText(SET_PR_MIN_STOCK_SEARCH_NAME, event.target.value)
	}

	onCodeChange(event) {
		this.props.setSearchText(SET_PR_MIN_STOCK_SEARCH_CODE, event.target.value)
	}

	onSelectedInventoryDoubleClick(item, index) {
		let inventories = this.props.minStock.selectedInventories.inventories.filter((inventory, idx) => idx !== index)
		this.props.setSelectedInventoriesAction(inventories)
	}

	renderLocs(locs) {
		let options = locs.map((loc, key) => {
			return (
				<option key={key} value={key}>
					{loc.displayName}
				</option>
			)
		})

		return Object.assign([], options, [
			<option key={-1} value={""}>
				{ResourceAssistance.Symbol.space}
			</option>,
			...options,
		])
	}

	render() {
		return (
			<div id={this.state.componentName}>
				<Modal
					id={this.state.componentName}
					centered
					animation={false}
					dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					show={this.props.isDisplay}
					onHide={this.onCloseBtn}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>{translate(ResourceAssistance.Message.minStock)}</ModalTitle>
					</ModalHeader>
					<ModalBody className="show-grid">
						<Form
							className={ResourceAssistance.CSS.fullFlex}
							validated={this.state.isAddItemValid}
							onSubmit={this.onAddInventory}
						>
							<Container fluid className={ResourceAssistance.CSS.fullFlex}>
								<Row>
									<Col sm="auto">
										<FormLabel>{translate(ResourceAssistance.Message.org)}</FormLabel>
									</Col>
									<Col>
										<FormattedMessage id={ResourceAssistance.Message.loading}>
											{(placeholder) => (
												<FormControl
													required
													id={ResourceAssistance.CONSTANT.ORG}
													size={ResourceAssistance.FormControl.size.sm}
													as={ResourceAssistance.FormControl.as.select}
													disabled={
														this.props.pr.location.isLoading ||
														this.props.minStock.selectedInventories.inventories.length > 0
													}
													onChange={this.props.onOrgChange}
												>
													{this.props.pr.location.isLoading ? (
														<option>{placeholder}</option>
													) : (
														this.renderLocs(this.props.pr.location.orgs)
													)}
												</FormControl>
											)}
										</FormattedMessage>
									</Col>
									<Col sm="auto">
										<FormLabel>{translate(ResourceAssistance.Message.location)}</FormLabel>
									</Col>
									<Col>
										<FormattedMessage id={ResourceAssistance.Message.loading}>
											{(placeholder) => (
												<FormControl
													required
													id={ResourceAssistance.CONSTANT.BRANCH}
													size={ResourceAssistance.FormControl.size.sm}
													as={ResourceAssistance.FormControl.as.select}
													disabled={
														this.props.pr.location.isLoading ||
														this.props.minStock.selectedInventories.inventories.length > 0
													}
													onChange={this.props.onBranchChange}
												>
													{this.props.pr.location.isLoading ? (
														<option>{placeholder}</option>
													) : (
														this.renderLocs(this.props.pr.location.branches)
													)}
												</FormControl>
											)}
										</FormattedMessage>
									</Col>
								</Row>
								<Row>
									<Col>
										<SearchBox
											size={ResourceAssistance.Button.size.sm}
											num={2}
											controlType={ResourceAssistance.FormControl.type.text}
											callbacks={[this.onCodeChange, this.onNameChange]}
											placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
										/>
									</Col>
								</Row>
								<Row className={ResourceAssistance.CSS.fullSize}>
									<Col>
										<Row className={ResourceAssistance.CSS.fullSize}>
											<Col id={ResourceAssistance.ID.PR.modalMinStock.tableItem}>
												<ScrollableTable
													className={ResourceAssistance.CSS.fullSize}
													enableHighlight
													striped
													responsive
													hover
													disabled={this.props.pr.location.selectedBranch.index === -1}
													onClick={this.onSelectInventoryClick}
													data={this.props.minStock.inventories}
													isClearHighlight={this.props.minStock.selectedInventory.index === -1}
												/>
												{this.props.minStock.inventories.isLoading && (
													<Spinner
														animation={ResourceAssistance.Spinner.border}
														variant={ResourceAssistance.Button.variant.primary}
													/>
												)}
											</Col>
										</Row>
										<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
											<Col md="auto">
												<FormLabel>{translate(ResourceAssistance.Message.lastPurchasedFrom)}</FormLabel>
											</Col>
											<Col>
												<FormControl
													disabled
													size={ResourceAssistance.FormControl.size.sm}
													type={ResourceAssistance.FormControl.type.text}
													value={this.props.minStock.lastPurchasedFrom}
												/>
											</Col>
											<Col md="auto">
												<FormControl
													disabled
													size={ResourceAssistance.FormControl.size.sm}
													type={ResourceAssistance.FormControl.type.text}
													value={this.props.minStock.lastPurchasedDate}
												/>
											</Col>
											<Col md="auto">
												<Button
													size={ResourceAssistance.Button.size.sm}
													type={ResourceAssistance.Button.type.submit}
													variant={ResourceAssistance.Button.variant.primary}
													disabled={!this.state.isAmountSet}
												>
													<MdAddShoppingCart size={ResourceAssistance.ReactIcon.size} />
													{translate(ResourceAssistance.Message.add)}
												</Button>
											</Col>
										</Row>
									</Col>
									<Col sm={2}>
										<Row>
											<Col md="auto">
												<FormLabel>{translate(ResourceAssistance.Message.label)}</FormLabel>
											</Col>
											<Col>
												<FormControl
													type={ResourceAssistance.FormControl.type.text}
													disabled={this.props.minStock.selectedInventory.index === -1}
													onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_LABEL)}
													value={this.props.minStock.selectedLabel}
												></FormControl>
											</Col>
										</Row>
										<Row>
											<Col md="auto">
												<FormLabel>{translate(ResourceAssistance.Message.amount)}</FormLabel>
											</Col>
											<Col>
												<InputGroup>
													<FormattedMessage id={ResourceAssistance.Message.setAmount}>
														{(placeholder) => (
															<FormControl
																required
																id={ResourceAssistance.ID.PR.modalMinStock.amount}
																type={ResourceAssistance.FormControl.type.number}
																disabled={this.props.minStock.selectedInventory.index === -1}
																min="1"
																placeholder={this.props.minStock.selectedInventory.index > -1 ? placeholder : ""}
																onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_AMOUNT)}
																value={this.props.minStock.selectedAmount}
															/>
														)}
													</FormattedMessage>
													{this.props.minStock.selectedInventory.inventory && (
														<InputGroup.Append>
															<InputGroup.Text>
																{this.props.minStock.selectedInventory.inventory.minQtyPerOrder}
															</InputGroup.Text>
														</InputGroup.Append>
													)}
												</InputGroup>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className={ResourceAssistance.CSS.fullSize}>
									<Col id={ResourceAssistance.ID.PR.modalMinStock.tableSelectedItem}>
										<ScrollableTable
											className={ResourceAssistance.CSS.fullSize}
											striped
											responsive
											hover
											enableHighlight
											enableOverlay
											overlayMsg={ResourceAssistance.Message.doubleClickToDelete}
											data={this.props.minStock.selectedInventories}
											onDoubleClick={this.onSelectedInventoryDoubleClick}
										/>
									</Col>
								</Row>
							</Container>
						</Form>
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							onClick={this.onDoneBtn}
							disabled={
								this.props.minStock.selectedInventories.body.length === 0 ||
								this.props.pr.location.selectedBranch.index === -1
							}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isMinStockDisplay,
	minStock: state.purchaseRequest.minStock,
	pr: state.purchaseRequest,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			loadOutOfStockInventoryAction,
			onModalDisplayAction,
			selectInventoryAction,
			selectPRAction,
			setInventoryPropertyAction,
			setSearchText,
			setSelectedInventoriesAction,
			setPrMinLastPurchased: setPrMinLastPurchasedInfo,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MinStock))
