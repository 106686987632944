import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmSSuppliers, setAdmTTaxes, setAdmUUnits, setLoadingAction } from "~/redux/action"
import { setAdmFForms } from "~/redux/action/admin/formActions"
import {
	SET_ADMIN_FORM_FORMS_LOADING,
	SET_ADMIN_ITEM_ITEMS_LOADING,
	SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING,
	SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING,
	SET_ADMIN_TAX_TAXS_LOADING,
	SET_ADMIN_UNIT_UNITS_LOADING,
} from "~/redux/type"
import AdmForm from "./form/AdmForm"
import AdmItem from "./item/AdmItem"
import "./itemConfig.css"
import AdmItemSource from "./item_source/AdmItemSource"
import AdmSupplier from "./supplier/AdmSupplier"
import AdmTax from "./tax/AdmTax"
import AdmUnit from "./unit/AdmUnit"

export const EItemConfig = {
	ITEM_SUPPLIER: "ITEM_SUPPLIER",
	ITEM: "ITEM",
	SUPPLIER: "SUPPLIER",
	UNIT: "UNIT",
	FORM: "FORM",
	TAX: "TAX",
}

class ItemConfig extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			componentName: "ItemConfig",
			tab: EItemConfig.ITEM_SUPPLIER,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadItems = this.loadItems.bind(this)
		this.loadItemSuppliers = this.loadItemSuppliers.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
		this.loadForms = this.loadForms.bind(this)
		this.loadUnits = this.loadUnits.bind(this)
		this.loadSuppliers = this.loadSuppliers.bind(this)
		this.loadTaxs = this.loadTaxs.bind(this)
		this.loadHealthCarePlan = this.loadHealthCarePlan.bind(this)
	}

	loadTypes(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.type.item.loadTypes}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.uts)
		}
		let reqInterceptor = () => {
			// this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			// this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	loadItems(func = () => {}, isExcludeInActive = false) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.itemSource.loadItems}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.items)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadItemSuppliers(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.item.loadItemSuppliers}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}

		let callback = (res) => {
			func(res.data.itemSuppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_ITEMS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_ITEMS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ADMIN_ITEM_ITEMS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadSuppliers(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.supplier.loadSuppliers}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.suppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadUnits(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.unit.loadUnits}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.units)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_UNIT_UNITS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_UNIT_UNITS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_UNIT_UNITS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadForms(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.form.loadForms}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.forms)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_FORM_FORMS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_FORM_FORMS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_FORM_FORMS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadTaxs(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.tax.loadTaxes}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.taxes)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADMIN_TAX_TAXS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_TAX_TAXS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADMIN_TAX_TAXS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadHealthCarePlan(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.type.healthCarePlan.loadTypes}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.uts)
		}
		let errorHandler = (error) => {}
		let reqInterceptor = () => {}
		let resInterceptor = () => {}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })

		switch (event) {
			case EItemConfig.SUPPLIER:
				this.loadSuppliers((suppliers) => {
					this.props.setAdmSSuppliers(suppliers)
				})
				break

			case EItemConfig.UNIT:
				this.loadUnits((units) => {
					this.props.setAdmUUnits(units)
				})
				break

			case EItemConfig.FORM:
				this.loadForms((forms) => {
					this.props.setAdmFForms(forms)
				})
				break

			case EItemConfig.TAX:
				this.loadTaxs((taxes) => {
					this.props.setAdmTTaxes(taxes)
				})
				break

			default:
				break
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<div className={` ${ResourceAssistance.CSS.fullFlex}`}>
							<Tabs justify variant="tabs" activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
								<Tab
									eventKey={EItemConfig.ITEM_SUPPLIER}
									title={translate(ResourceAssistance.Message.itemSupplier)}
									name={EItemConfig.ITEM_SUPPLIER}
								>
									{this.state.tab === EItemConfig.ITEM_SUPPLIER && (
										<AdmItem
											loadItemSuppliers={this.loadItemSuppliers}
											loadTypes={this.loadTypes}
											loadItems={this.loadItems}
											loadForms={this.loadForms}
											loadUnits={this.loadUnits}
											loadSuppliers={this.loadSuppliers}
											loadTaxes={this.loadTaxs}
											loadHealthCarePlan={this.loadHealthCarePlan}
										/>
									)}
								</Tab>
								<Tab
									eventKey={EItemConfig.ITEM}
									title={translate(ResourceAssistance.Message.item)}
									name={EItemConfig.ITEM}
								>
									{this.state.tab === EItemConfig.ITEM && (
										<AdmItemSource loadItems={this.loadItems} loadTypes={this.loadTypes} />
									)}
								</Tab>

								<Tab
									eventKey={EItemConfig.SUPPLIER}
									title={translate(ResourceAssistance.Message.supplier)}
									name={EItemConfig.SUPPLIER}
								>
									{this.state.tab === EItemConfig.SUPPLIER && <AdmSupplier />}
								</Tab>
								<Tab
									eventKey={EItemConfig.UNIT}
									title={translate(ResourceAssistance.Message.unit)}
									name={EItemConfig.UNIT}
								>
									{this.state.tab === EItemConfig.UNIT && <AdmUnit />}
								</Tab>
								<Tab
									eventKey={EItemConfig.FORM}
									title={translate(ResourceAssistance.Message.form)}
									name={EItemConfig.FORM}
								>
									{this.state.tab === EItemConfig.FORM && <AdmForm />}
								</Tab>
								<Tab
									eventKey={EItemConfig.TAX}
									title={translate(ResourceAssistance.Message.taxRate)}
									name={EItemConfig.TAX}
								>
									{this.state.tab === EItemConfig.TAX && <AdmTax />}
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setAdmSSuppliers,
			setAdmUUnits,
			setAdmFForms,
			setAdmTTaxes,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemConfig)
