import { ResourceAssistance } from "~/i18n"
import {
	PR_DONE_HANDLER,
	PR_MIN_STOCK,
	PR_NEW,
	SET_ADD_INVENTORY_DISPLAY,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY,
	SET_ADM_FORM_NEW_FORM_DISPLAY,
	SET_ADM_ITEM_NEW_ITEM_DISPLAY,
	SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_ROLE_NEW_ROLE_DISPLAY,
	SET_ADM_SERVICE_NEW_SERVICE_DISPLAY,
	SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY,
	SET_ADM_TAX_NEW_TAX_DISPLAY,
	SET_ADM_UNIT_NEW_UNIT_DISPLAY,
	SET_ADM_USER_CHANGE_PASSWORD_DISPLAY,
	SET_ADM_USER_NEW_USER_DISPLAY,
	SET_ADM_USER_SIGNATURE_DISPLAY,
	SET_CASHIER_CERTIFICATE_DISPLAY,
	SET_CASHIER_DOCTOR_DISPLAY,
	SET_CASHIER_FINANCIAL_PLAN_DISPLAY,
	SET_CASHIER_LAB_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_NURSE_DISPLAY,
	SET_CASHIER_PAYMENT_DISPLAY,
	SET_CASHIER_SEARCH_PATIENT_DISPLAY,
	SET_CONFIG_ICD_NEW_DISPLAY,
	SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY,
	SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY,
	SET_CONFIG_LEVEL_NEW_DISPLAY,
	SET_DEPOSIT_SAVING_DISPLAY,
	SET_DEPOSIT_SEARCH_PATIENT_DISPLAY,
	SET_DEPOSIT_WITHDRAW_DISPLAY,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
	SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND,
	SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY,
	SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME,
	SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME,
	SET_HOSPITEL_HOSPITEL_NEW_DISPLAY,
	SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY,
	SET_HOSPITEL_IPD_REGISTER_DISPLAY,
	SET_HOSPITEL_IPD_SEARCH_DISPLAY,
	SET_HOSPITEL_LAB_APPROVE_DISPLAY,
	SET_HOSPITEL_LAB_LAB_DISPLAY,
	SET_HOSPITEL_LAB_NEGATIVE_DISPLAY,
	SET_HOSPITEL_LAB_POSITIVE_DISPLAY,
	SET_HOSPITEL_LAB_REGISTER_PATIENT,
	SET_HOSPITEL_LAB_SEARCH_PATIENT,
	SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_LOCATION_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY,
	SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITEL_STOP_DISPLAY,
	SET_INVENTORY_EDIT_INVENTORY_DISPLAY,
	SET_INVENTORY_MIN_STOCK_DISPLAY,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_IPD_CREATE_PRN_RECORD_DISPLAY,
	SET_IPD_GCS_DISPLAY,
	SET_IPD_ICD_DISPLAY,
	SET_IPD_INTAKE_OUTPUT_DISPLAY,
	SET_IPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_IPD_PAIN_SCALE_DISPLAY,
	SET_IPD_PRN_RECORD_DISPLAY,
	SET_IPD_PUPIL_SIZE_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
	SET_NEW_TYPE_DISPLAY,
	SET_NOTIFICATION_DISPLAY,
	SET_NOTIFICATION_NEW_DISPLAY,
	SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY,
	SET_OPD_ADJUSTMENT_DISPLAY,
	SET_OPD_APPOINTMENT_DISPLAY,
	SET_OPD_APPOINTMENT_EDIT_DISPLAY,
	SET_OPD_DOCTOR_DISPLAY,
	SET_OPD_DOCTOR_INSTRUCTION_DISPLAY,
	SET_OPD_DOCTOR_SERVICE_DISPLAY,
	SET_OPD_GCS_DISPLAY,
	SET_OPD_ICD_DISPLAY,
	SET_OPD_INTAKE_OUTPUT_DISPLAY,
	SET_OPD_LOACTION_DISPLAY,
	SET_OPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_OPD_MEDICAL_RECORD_DISPLAY,
	SET_OPD_MODIFY_DATE_DISPLAY,
	SET_OPD_NURSE_DISPLAY,
	SET_OPD_NURSE_ORDER_DISPLAY,
	SET_OPD_NURSE_SERVICE_DISPLAY,
	SET_OPD_PAIN_SCALE_DISPLAY,
	SET_OPD_PUPIL_SIZE_DISPLAY,
	SET_OPD_SEARCH_DISPLAY,
	SET_OPD_VITAL_SIGN_DISPLAY,
	SET_FRONT_INVENTORY_EDIT_DISPLAY,
	SET_PHARMACY_LOCATION_DISPLAY,
	SET_PHARMACY_SEARCH_DISPLAY,
	SET_PO_EDIT_DISPLAY,
	SET_PO_REJECT_REASON_DISPLAY,
	SET_RC_CANCEL_DISPLAY,
	SET_RC_CANCEL_REASON_DISPLAY,
	SET_RC_RECEIVE_DETAIL_DISPLAY,
	SET_RC_RECEIVE_DISPLAY,
	SET_REGISTRATION_ADJUSTMENT_DISPLAY,
	SET_REGISTRATION_BUDGET_DISPLAY,
	SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY,
	SET_REPORT_INV_FILTER_BY_LOCATION,
	SET_REPORT_PO_FILTER_BY_ID,
	SET_REPORT_PO_FILTER_BY_LOCATION,
	SET_REPORT_TF_FILTER_BY_LOCATION,
	SET_TF_CANCEL_REQUEST_ITEM_DISPLAY,
	SET_TF_NEW_REQUEST_DISPLAY,
	SET_TF_NEW_TRANSFER_DISPLAY,
	SET_TIMELINE_DISPLAY,
	SET_TIMELINE_SEARCH_PATIENT_DISPLAY,
	SET_WARNING_CLOSE,
	SET_WARNING_CONTINUE,
	SET_WARNING_DISPLAY,
	SET_WARNING_ID,
	SET_WARNING_MESSAGE,
	SET_PHARMACY_VERIFY_DISPLAY,
	SET_PHARMACY_RETURN_DISPLAY,
	SET_PHARMACY_RETURN_EDIT_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_OPD_NURSE_INFO_DISPLAY,
	SET_OPD_DOCTOR_INFO_DISPLAY,
	SET_IPD_DOCTOR_INFO_DISPLAY,
	SET_IPD_NURSE_INFO_DISPLAY,
	SET_IPD_DOCTOR_SOAP_INFO_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	createPR: false,
	isAddInventoryDisplay: false,
	isAdmCustomDefaultNewFocusDisplay: false,
	isAdmCustomDefaultNewInterventionDisplay: false,
	isAdmFNewFormDisplay: false,
	isAdmINewItemDisplay: false,
	isAdmISNewItemDisplay: false,
	isAdmRoleNewRoleDisplay: false,
	isAdmSNewSupplierDisplay: false,
	isAdmServiceNewServiceDisplay: false,
	isAdmTNewTaxDisplay: false,
	isAdmUNewUnitDisplay: false,
	isAdmUserChangePasswordDisplay: false,
	isAdmUserNewUserDisplay: false,
	isAdmUserSignatureDisplay: false,
	isCashierFinancialPlanDisplay: false,
	isCashierPaymentDisplay: false,
	isConfigICDNewDisplay: false,
	isConfigLevelNewDisplay: false,
	isDepositSavingDisplay: false,
	isDepositSearchPatientDisplay: false,
	isDepositWithdrawDisplay: false,
	isEditInventoryDisplay: false,
	isIPDCreatePRNRecordDisplay: false,
	isIPDPRNRecordDisplay: false,
	isInpatientGCSDisplay: false,
	isInpatientICDDisplay: false,
	isInpatientIntakeOuputDisplay: false,
	isInpatientMedicalCertificateDisplay: false,
	isInpatientPainScaleDisplay: false,
	isInpatientPupilSizeDisplay: false,
	isInpatientDoctorInfoDisplay: false,
	isInpatientNurseInfoDisplay: false,
	isInpatientDoctorSOAPInfoDisplay: false,
	isInventoryMinStockDisplay: false,
	isMenuNotificationDisplay: false,
	isMenuNotificationNewDisplay: false,
	isMenuNotificationSearchDisplay: false,
	isMenuTimelineDisplay: false,
	isMenuTimelineSearchDisplay: false,
	isMinStockDisplay: false,
	isNewLocationDisplay: false,
	isNewTypeDisplay: false,
	isOutpatientAdjustmentDisplay: false,
	isOutpatientAppointmentDisplay: false,
	isOutpatientAppointmentEditDisplay: false,
	isOutpatientDoctorDisplay: false,
	isOutpatientDoctorInstructionDisplay: false,
	isOutpatientDoctorServiceDisplay: false,
	isOutpatientGCSDisplay: false,
	isOutpatientICDDisplay: false,
	isOutpatientIntakeOuputDisplay: false,
	isOutpatientLocationDisplay: false,
	isOutpatientMedicalCertificateDisplay: false,
	isOutpatientMedicalRecordDisplay: false,
	isOutpatientModifyDateDisplay: false,
	isOutpatientNurseDisplay: false,
	isOutpatientNurseOrderDisplay: false,
	isOutpatientNurseServiceDisplay: false,
	isOutpatientPainScaleDisplay: false,
	isOutpatientPupilSizeDisplay: false,
	isOutpatientSearchDisplay: false,
	isOutpatientVitalSignDisplay: false,
	isOutpatientNurseInfoDisplay: false,
	isOutpatientDoctorInfoDisplay: false,
	isPOEditDisplay: false,
	isPORejectDisplay: false,
	isRCCancelDisplay: false,
	isRCCancelReasonDisplay: false,
	isRCReceiveDetailDisplay: false,
	isRCReceiveDisplay: false,
	isRegistrationAdjustmentDisplay: false,
	isRegistrationBudgetDisplay: false,
	isRegistrationExpenseEstimationDisplay: false,
	isTFCancelRequestItemDisplay: false,
	isTFNewRequestDisplay: false,
	isTFNewTransferDisplay: false,
	isWarningDisplay: false,
	isPharmacyLocationDisplay: false,
	isPharmacySearchDisplay: false,
	isFrontInventoryEditDisplay: false,
	isConfigLaboratoryPCRCommentNewDisplay: false,
	isConfigLaboratoryAntigenCommentNewDisplay: false,
	isPharmacyVerifyDisplay: false,
	isPharmacyReturnDisplay: false,
	isPharmacyReturnEditDisplay: false,
	newPr: {
		type: "",
	},
	warning: {
		id: "",
		isClose: false,
		isContinue: false,
		msg: "",
		size: ResourceAssistance.Modal.size.sm,
	},
	newLoc: {
		isParentOrgDisplay: false,
		isParentLocDisplay: false,
		isAddressDisplay: false,
		isTaxNoDisplay: false,
		isNameEngDisplay: false,
		isPharmacyIndDisplay: false,
	},
	report: {
		inv: {
			isReportByLocationDisplay: false,
		},
		tf: {
			isReportByLocationDisplay: false,
		},
		po: {
			isReportByIdDisplay: false,
			isReportByLocationDisplay: false,
		},
		hospital: {
			cashier: {
				isAdmissionDisplay: false,
				isUserRoleDisplay: false,
				isBillingToTodayDisplay: false,
			},
			hospitel: {
				isPatientDisplay: false,
			},
			lab: {
				isCovid19Display: false,
			},
		},
	},
	hospitel: {
		lab: {
			isRegisterPatientDisplay: false,
			isSearchPatientDisplay: false,
			isPositiveDisplay: false,
			isNewLabDisplay: false,
			isNegativeDisplay: false,
			isApproveDisplay: false,
		},
		ipd: {
			isSearchPatientDisplay: false,
			isRegisterPatientDisplay: false,
			isClinicalRecordDisplay: false,
		},
		hospitel: {
			isNewDisplay: false,
			isSearchDisplay: false,
			isDoctorOrderDisplay: false,
			isStopDisplay: false,
			isNurseRecordDisplay: false,
			isDoctorNoteDisplay: false,
			isVitalSignDisplay: false,
			isDefaultOrderDisplay: false,
			isLocationDisplay: false,
			isNurseOrderDisplay: false,
			isNurseServiceDisplay: false,
			isAdjustmentDisplay: false,
			isAdjustmentServiceDisplay: false,
			isDoctorServiceDisplay: false,
		},
		cashier: {
			isSearchPatientDisplay: false,
			isLabDisplay: false,
			isCertificateDisplay: false,
			isAdjustmentDisplay: false,
			isDoctorDisplay: false,
			isNurseDisplay: false,
		},
	},
	hospital: {
		clinicalRecord: {
			isClinicalRecordDisplay: false,
			isEditDisplay: false,
			patient: {},
			callback: () => {},
		},
		saveAll: {
			isSaveAllDisplay: false,
			isDisableEndDate: true,
			startDateTime: Utils.generateDate().getTime(),
			endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		},
	},
}

const modalReducer = (state = init, action) => {
	switch (action.type) {
		case PR_NEW:
			return Object.assign({}, state, {
				createPR: action.payload.isDisplay,
			})

		case PR_MIN_STOCK:
			return Object.assign({}, state, {
				isMinStockDisplay: action.payload.isDisplay,
			})

		case PR_DONE_HANDLER:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					type: action.payload.type,
				},
			})

		case SET_WARNING_ID:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					id: action.payload.id,
				},
			})

		case SET_WARNING_DISPLAY:
			return Object.assign({}, state, {
				isWarningDisplay: action.payload.isDisplay,
			})

		case SET_WARNING_MESSAGE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					msg: action.payload.msg,
				},
			})

		case SET_ADD_INVENTORY_DISPLAY:
			return Object.assign({}, state, {
				isAddInventoryDisplay: action.payload.isDisplay,
			})

		case SET_NEW_TYPE_DISPLAY:
			return Object.assign({}, state, {
				isNewTypeDisplay: action.payload.isDisplay,
			})

		case SET_NEW_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isNewLocationDisplay: action.payload.isDisplay,
			})

		case SET_NEW_LOCATION_PARENT_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isParentLocDisplay: action.payload.isDisplay,
				},
			})

		case SET_NEW_LOCATION_PARENT_ORG_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isParentOrgDisplay: action.payload.isDisplay,
				},
			})

		case SET_NEW_LOCATION_TAX_NO_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isTaxNoDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isNameEngDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isPharmacyIndDisplay: action.payload.isDisplay,
				},
			})
		case SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					isAddressDisplay: action.payload.isDisplay,
				},
			})

		case SET_INVENTORY_EDIT_INVENTORY_DISPLAY:
			return Object.assign({}, state, {
				isEditInventoryDisplay: action.payload.isDisplay,
			})

		case SET_INVENTORY_MIN_STOCK_DISPLAY:
			return Object.assign({}, state, {
				isInventoryMinStockDisplay: action.payload.isDisplay,
			})

		case SET_PO_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isPOEditDisplay: action.payload.isDisplay,
			})

		case SET_PO_REJECT_REASON_DISPLAY:
			return Object.assign({}, state, {
				isPORejectDisplay: action.payload.isDisplay,
			})

		case SET_RC_RECEIVE_DISPLAY:
			return Object.assign({}, state, {
				isRCReceiveDisplay: action.payload.isDisplay,
			})

		case SET_RC_CANCEL_DISPLAY:
			return Object.assign({}, state, {
				isRCCancelDisplay: action.payload.isDisplay,
			})

		case SET_RC_RECEIVE_DETAIL_DISPLAY:
			return Object.assign({}, state, {
				isRCReceiveDetailDisplay: action.payload.isDisplay,
			})

		case SET_RC_CANCEL_REASON_DISPLAY:
			return Object.assign({}, state, {
				isRCCancelReasonDisplay: action.payload.isDisplay,
			})

		case SET_TF_NEW_REQUEST_DISPLAY:
			return Object.assign({}, state, {
				isTFNewRequestDisplay: action.payload.isDisplay,
			})

		case SET_TF_NEW_TRANSFER_DISPLAY:
			return Object.assign({}, state, {
				isTFNewTransferDisplay: action.payload.isDisplay,
			})

		case SET_TF_CANCEL_REQUEST_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isTFCancelRequestItemDisplay: action.payload.isDisplay,
			})

		case SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY:
			return Object.assign({}, state, {
				isAdmSNewSupplierDisplay: action.payload.isDisplay,
			})

		case SET_ADM_UNIT_NEW_UNIT_DISPLAY:
			return Object.assign({}, state, {
				isAdmUNewUnitDisplay: action.payload.isDisplay,
			})

		case SET_ADM_FORM_NEW_FORM_DISPLAY:
			return Object.assign({}, state, {
				isAdmFNewFormDisplay: action.payload.isDisplay,
			})

		case SET_ADM_TAX_NEW_TAX_DISPLAY:
			return Object.assign({}, state, {
				isAdmTNewTaxDisplay: action.payload.isDisplay,
			})

		case SET_ADM_ITEM_NEW_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isAdmINewItemDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY:
			return Object.assign({}, state, {
				isAdmISNewItemDisplay: action.payload.isDisplay,
			})
		case SET_ADM_USER_NEW_USER_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserNewUserDisplay: action.payload.isDisplay,
			})
		case SET_ADM_USER_CHANGE_PASSWORD_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserChangePasswordDisplay: action.payload.isDisplay,
			})
		case SET_ADM_ROLE_NEW_ROLE_DISPLAY:
			return Object.assign({}, state, {
				isAdmRoleNewRoleDisplay: action.payload.isDisplay,
			})

		case SET_ADM_SERVICE_NEW_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isAdmServiceNewServiceDisplay: action.payload.isDisplay,
			})

		case SET_REPORT_PO_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					po: {
						...state.report.po,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						lab: {
							...state.report.hospital.lab,
							isCovid19Display: action.payload.isDisplay,
						},
					},
				},
			})

		case SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						hospitel: {
							...state.report.hospital.hospitel,
							isPatientDisplay: action.payload.isDisplay,
						},
					},
				},
			})

		case SET_REPORT_PO_FILTER_BY_ID:
			return Object.assign({}, state, {
				report: {
					...state.report,
					po: {
						...state.report.po,
						isReportByIdDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_TF_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					tf: {
						...state.report.tf,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_REPORT_INV_FILTER_BY_LOCATION:
			return Object.assign({}, state, {
				report: {
					...state.report,
					inv: {
						...state.report.inv,
						isReportByLocationDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_WARNING_CONTINUE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					isContinue: action.payload.isContinue,
				},
			})

		case SET_WARNING_CLOSE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					isClose: action.payload.isClose,
				},
			})

		// Hospitel
		case SET_HOSPITEL_LAB_REGISTER_PATIENT:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isRegisterPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_SEARCH_PATIENT:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_DOCTOR_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isDoctorDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_NURSE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isNurseDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_POSITIVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isPositiveDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_LAB_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isNewLabDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_NEGATIVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isNegativeDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_LAB_APPROVE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					lab: {
						...state.hospitel.lab,
						isApproveDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_IPD_REGISTER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isRegisterPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_IPD_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_HOSPITEL_NEW_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNewDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isSearchDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_OPD_MEDICAL_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientMedicalCertificateDisplay: action.payload.isDisplay,
			})

		case SET_CASHIER_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isSearchPatientDisplay: action.payload.isDisplay,
					},
				},
			})

		case SET_CASHIER_LAB_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isLabDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_CASHIER_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isCertificateDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					ipd: {
						...state.hospitel.ipd,
						isClinicalRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					cashier: {
						...state.hospitel.cashier,
						isAdjustmentDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_STOP_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isStopDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorNoteDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isVitalSignDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDefaultOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isLocationDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						isClinicalRecordDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						isEditDisplay: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						callback: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					clinicalRecord: {
						...state.hospital.clinicalRecord,
						patient: action.payload.value,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseOrderDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isNurseServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isAdjustmentDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isAdjustmentServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				hospitel: {
					...state.hospitel,
					hospitel: {
						...state.hospitel.hospitel,
						isDoctorServiceDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						isSaveAllDisplay: action.payload.isDisplay,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						isDisableEndDate: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						startDateTime: action.payload.value,
					},
				},
			})
		case SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME:
			return Object.assign({}, state, {
				hospital: {
					...state.hospital,
					saveAll: {
						...state.hospital.saveAll,
						endDateTime: action.payload.value,
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isAdmissionDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isUserRoleDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY:
			return Object.assign({}, state, {
				report: {
					...state.report,
					hospital: {
						...state.report.hospital,
						cashier: {
							...state.report.hospital.cashier,
							isBillingToTodayDisplay: action.payload.isDisplay,
						},
					},
				},
			})
		case SET_ADM_USER_SIGNATURE_DISPLAY:
			return Object.assign({}, state, {
				isAdmUserSignatureDisplay: action.payload.isDisplay,
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCustomDefaultNewFocusDisplay: action.payload.isDisplay,
			})
		}
		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DISPLAY: {
			return Object.assign({}, state, {
				isAdmCustomDefaultNewInterventionDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_PRN_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isIPDPRNRecordDisplay: action.payload.isDisplay,
			})
		case SET_IPD_CREATE_PRN_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isIPDCreatePRNRecordDisplay: action.payload.isDisplay,
			})
		case SET_CASHIER_FINANCIAL_PLAN_DISPLAY:
			return Object.assign({}, state, {
				isCashierFinancialPlanDisplay: action.payload.isDisplay,
			})
		case SET_CASHIER_PAYMENT_DISPLAY:
			return Object.assign({}, state, {
				isCashierPaymentDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isDepositSearchPatientDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_SAVING_DISPLAY:
			return Object.assign({}, state, {
				isDepositSavingDisplay: action.payload.isDisplay,
			})
		case SET_DEPOSIT_WITHDRAW_DISPLAY:
			return Object.assign({}, state, {
				isDepositWithdrawDisplay: action.payload.isDisplay,
			})
		case SET_OPD_LOACTION_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientLocationDisplay: action.payload.isDisplay,
			})
		case SET_OPD_APPOINTMENT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAppointmentDisplay: action.payload.isDisplay,
			})
		case SET_OPD_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientSearchDisplay: action.payload.isDisplay,
			})
		case SET_OPD_MEDICAL_RECORD_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientMedicalRecordDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorDisplay: action.payload.isDisplay,
			})
		case SET_OPD_MODIFY_DATE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientModifyDateDisplay: action.payload.isDisplay,
			})
		case SET_MODAL_WARNING_SIZE:
			return Object.assign({}, state, {
				warning: {
					...state.warning,
					size: action.payload.value,
				},
			})
		case SET_OPD_DOCTOR_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorServiceDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_INSTRUCTION_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorInstructionDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseDisplay: action.payload.isDisplay,
			})
		case SET_OPD_VITAL_SIGN_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientVitalSignDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_ORDER_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseOrderDisplay: action.payload.isDisplay,
			})
		case SET_OPD_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAdjustmentDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_SERVICE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseServiceDisplay: action.payload.isDisplay,
			})
		case SET_OPD_APPOINTMENT_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientAppointmentEditDisplay: action.payload.isDisplay,
			})
		case SET_OPD_NURSE_INFO_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientNurseInfoDisplay: action.payload.isDisplay,
			})
		case SET_OPD_DOCTOR_INFO_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientDoctorInfoDisplay: action.payload.isDisplay,
			})
		case SET_IPD_MEDICAL_CERTIFICATE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientMedicalCertificateDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_ICD_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigICDNewDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LEVEL_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLevelNewDisplay: action.payload.isDisplay,
			})
		case SET_OPD_ICD_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientICDDisplay: action.payload.isDisplay,
			})
		case SET_IPD_ICD_DISPLAY:
			return Object.assign({}, state, {
				isInpatientICDDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationExpenseEstimationDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_ADJUSTMENT_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationAdjustmentDisplay: action.payload.isDisplay,
			})
		case SET_REGISTRATION_BUDGET_DISPLAY:
			return Object.assign({}, state, {
				isRegistrationBudgetDisplay: action.payload.isDisplay,
			})
		case SET_OPD_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_OPD_GCS_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientGCSDisplay: action.payload.isDisplay,
			})
		case SET_IPD_INTAKE_OUTPUT_DISPLAY:
			return Object.assign({}, state, {
				isInpatientIntakeOuputDisplay: action.payload.isDisplay,
			})
		case SET_IPD_GCS_DISPLAY:
			return Object.assign({}, state, {
				isInpatientGCSDisplay: action.payload.isDisplay,
			})
		case SET_OPD_PAIN_SCALE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientPainScaleDisplay: action.payload.isDisplay,
			})
		case SET_IPD_PAIN_SCALE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientPainScaleDisplay: action.payload.isDisplay,
			})
		case SET_OPD_PUPIL_SIZE_DISPLAY:
			return Object.assign({}, state, {
				isOutpatientPupilSizeDisplay: action.payload.isDisplay,
			})
		case SET_IPD_PUPIL_SIZE_DISPLAY:
			return Object.assign({}, state, {
				isInpatientPupilSizeDisplay: action.payload.isDisplay,
			})
		case SET_IPD_DOCTOR_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isInpatientDoctorInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_NURSE_INFO_DISPLAY: {
			return Object.assign({}, state, {
				isInpatientNurseInfoDisplay: action.payload.isDisplay,
			})
		}
		case SET_IPD_DOCTOR_SOAP_INFO_DISPLAY:
			return Object.assign({}, state, {
				isInpatientDoctorSOAPInfoDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationSearchDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationDisplay: action.payload.isDisplay,
			})
		case SET_NOTIFICATION_NEW_DISPLAY:
			return Object.assign({}, state, {
				isMenuNotificationNewDisplay: action.payload.isDisplay,
			})
		case SET_TIMELINE_DISPLAY:
			return Object.assign({}, state, {
				isMenuTimelineDisplay: action.payload.isDisplay,
			})
		case SET_TIMELINE_SEARCH_PATIENT_DISPLAY:
			return Object.assign({}, state, {
				isMenuTimelineSearchDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_LOCATION_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyLocationDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_SEARCH_DISPLAY:
			return Object.assign({}, state, {
				isPharmacySearchDisplay: action.payload.isDisplay,
			})
		case SET_FRONT_INVENTORY_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isFrontInventoryEditDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLaboratoryPCRCommentNewDisplay: action.payload.isDisplay,
			})
		case SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY:
			return Object.assign({}, state, {
				isConfigLaboratoryAntigenCommentNewDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_VERIFY_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyVerifyDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_RETURN_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyReturnDisplay: action.payload.isDisplay,
			})
		case SET_PHARMACY_RETURN_EDIT_DISPLAY:
			return Object.assign({}, state, {
				isPharmacyReturnEditDisplay: action.payload.isDisplay,
			})

		default:
			return state
	}
}

export default modalReducer
