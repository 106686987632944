import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import ReportTitle from "~/report/component/ReportTitle"
import SignatureFooter from "~/report/component/SignatureFooter"

class ReportTransferDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.transfer,
				items: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Shipping */}
						<View style={ResourceAssistance.Report.styles.row}>
							<View style={[ResourceAssistance.Report.styles.col, { marginRight: 10, justifyContent: "flex-start" }]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>
									Request From/{this.state.data.requestFromIntl}:
								</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.requestOrg}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.requestBranch}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.requestLocation}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.requestAddress}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>Tel: {this.state.data.requestTel}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>
									Transfer From/{this.state.data.transferFromIntl}:
								</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.transferOrg}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.transferBranch}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.transferAddress}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>Tel: {this.state.data.transferTel}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Transfer Info */}
						<View style={ResourceAssistance.Report.styles.row}>
							<View style={[ResourceAssistance.Report.styles.col, { marginRight: 10, justifyContent: "flex-start" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Request ID/{this.state.data.requestIdIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.requestId}</Text>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Transfer ID/{this.state.data.transferIdIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.transferId}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.lotIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl} </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.descriptionIntl} </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.brandIntl} </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitIntl} </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.cancelled]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.cancelledIntl} </Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Lot</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>ID</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Description</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Brand</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Unit</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.cancelled]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Cancelled</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Items */}
						{this.state.data.items.map((item) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
										<Text>{item.lotNum}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
										<Text>{item.code}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{item.name}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
										<Text>{item.brand}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
										<Text>{Utils.formatNumWithComma(item.amount)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
										<Text>{item.unit}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.cancelled]}>
										{/* <Svg><Rect x="22.5" y="22.5" width="15" height="15" fill="black" /></Svg> */}
										<Text style={{ border: 1, width: 18, alignSelf: "center" }}> </Text>
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Remark */}
						<View wrap={false}>
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Remark/{this.state.data.remarkIntl}:</Text>
									<Text>{"\n\n\n\n"}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Signature */}
						<SignatureFooter data={this.state.data} isApprovalDisplay={false} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 115,
	},
	itemRow: {
		fontSize: 16,
	},
	infoBody: {
		fontStyle: "italic",
	},
	lot: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	id: {
		minWidth: "17%",
		maxWidth: "17%",
	},
	description: {
		minWidth: "31%",
		maxWidth: "31%",
	},
	brand: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	qty: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	unit: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	cancelled: {
		minWidth: "10%",
		maxWidth: "10%",
	},
})

export default ReportTransferDocument
