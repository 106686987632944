import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, FormControl, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigServiceServices,
	setLoadingAction,
	setObjArray,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_SERVICE_DISPLAY_ALL_IND,
	SET_ADMIN_SERVICE_ROLES,
	SET_ADMIN_SERVICE_SEARCH_CODE,
	SET_ADMIN_SERVICE_SEARCH_NAME,
	SET_ADMIN_SERVICE_SELECTED_SERVICE,
	SET_ADMIN_SERVICE_SELECTED_TYPE,
	SET_ADMIN_SERVICE_TYPES,
	SET_ADM_SERVICE_NEW_SERVICE_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"

class ConfigService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigService",
		}

		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes(
			(types) => {
				this.props.setObjArray(SET_ADMIN_SERVICE_TYPES, types)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
		)

		this.props.loadRoles(
			(roles) => {
				this.props.setObjArray(SET_ADMIN_SERVICE_ROLES, roles)
			},
			() => {},
			() => {},
			() => {}
		)
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_CODE, "")
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_NAME, "")
		this.props.setValue(SET_ADMIN_SERVICE_DISPLAY_ALL_IND, true)
		this.props.setConfigServiceServices([])
		this.props.setObjArray(SET_ADMIN_SERVICE_TYPES, [])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.service.types, this.props.service.types)) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.service.type)
			type.selectedIndex = 0
			this.props.setSelected(
				SET_ADMIN_SERVICE_SELECTED_TYPE,
				type.selectedIndex,
				this.props.service.types[type.selectedIndex]
			)
			this.props.setConfigServiceServices(this.props.service.types[type.selectedIndex].services)
		}

		if (
			prevProps.service.selectedType.index !== this.props.service.selectedType.index ||
			prevProps.service.searchCode !== this.props.service.searchCode ||
			prevProps.service.searchName !== this.props.service.searchName ||
			prevProps.service.displayInactive !== this.props.service.displayInactive
		) {
			this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
			if (this.props.service.selectedType.type) {
				this.props.setConfigServiceServices(this.props.service.selectedType.type.services)
			}
		}
	}

	onType(event) {
		this.props.setSelected(
			SET_ADMIN_SERVICE_SELECTED_TYPE,
			event.target.value,
			this.props.service.types[event.target.value]
		)
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADMIN_SERVICE_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, index, this.props.service.serviceTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
		this.props.onModalDisplayAction(SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md="auto">
						<FormControl
							id={ResourceAssistance.ID.ADM.service.type}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.service.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.service.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.service.selectedService.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.service.selectedService.index === -1}
							data={this.props.service.serviceTable}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setValue,
			setSelected,
			setLoadingAction,
			onModalDisplayAction,
			setConfigServiceServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigService)
