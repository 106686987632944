import React, { Fragment } from "react"
import { Col, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_REGISTER_ROOM } from "~/redux/type"

class HospitelModalRegisterIPD extends React.Component {
	constructor(props) {
		super(props)

		this.onRoom = this.onRoom.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_ROOM, "")
	}

	onRoom(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_ROOM, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<fieldset>
					<legend>{translate(ResourceAssistance.Hospitel.ipd)}</legend>
					<Row>
						<Col />
						<Col md="auto">
							<FormLabel>{this.props.hospitel.department.displayName}</FormLabel>
						</Col>
						<Col />
					</Row>
					<Row>
						<Col />
						<Col md="auto">
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.room)}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									required
									autoFocus
									type={ResourceAssistance.FormControl.type.text}
									value={this.props.hospitel.modalRegister.room}
									onChange={this.onRoom}
								/>
							</InputGroup>
						</Col>
						<Col />
					</Row>
				</fieldset>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalRegisterIPD))
