import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"

class RegModalExpenseEstHealthCareInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
						<Descriptions
							size={"small"}
							style={{ flex: "unset", display: "unset" }}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.reg.selectedAdmission.admission
									? this.props.reg.selectedAdmission.admission.clinicalRecord.healthcareCode
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={2}>
								{this.props.reg.selectedAdmission.admission
									? this.props.reg.selectedAdmission.admission.clinicalRecord.healthcareName
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={3}>
								{this.props.reg.selectedAdmission.admission
									? this.props.reg.selectedAdmission.admission.clinicalRecord.healthcareDescription
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.other)} span={3}>
								{this.props.reg.selectedAdmission.admission
									? this.props.reg.selectedAdmission.admission.clinicalRecord.healthcareOther
									: ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstHealthCareInfo)
