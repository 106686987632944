import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { FiRefreshCcw } from "react-icons/fi"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSearchText, setSelectedType, setValue } from "~/redux/action"
import { SET_SEARCH_TEXT, SET_TYPE_DISPLAY_INACTIVE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"

class AbstractTypeView extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchChange = this.onSearchChange.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRowClick = this.onSelectRowClick.bind(this)
	}

	onSearchChange(event) {
		this.props.setSelectedType(-1, null)
		this.props.setSearchText(SET_SEARCH_TEXT, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setSelectedType(-1, null)
		this.props.setValue(SET_TYPE_DISPLAY_INACTIVE, event.target.checked)
	}

	onSelectRowClick(item, index) {
		this.props.setSelectedType(index, this.props.type.types.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchChange]}
							values={[this.props.type.searchText]}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.type.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.props.onAddBtnClick}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.type.selectedType.index === -1}
							onClick={this.props.onEditBtnClick}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.props.onRefreshClick}>
							<FiRefreshCcw size={ResourceAssistance.ReactIcon.size} />
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							isClearHighlight={this.props.type.selectedType.index === -1}
							data={this.props.scrollableTableData}
							onClick={this.onSelectRowClick}
						/>
						{this.props.type.types.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

AbstractTypeView.propTypes = {
	onAddBtnClick: PropTypes.func,
	onEditBtnClick: PropTypes.func,
	onRefreshClick: PropTypes.func,
	scrollableTableData: PropTypes.object,
}

AbstractTypeView.defaultProps = {
	scrollableTableData: { header: [], body: [] },
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelectedType,
			setSearchText,
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractTypeView)
