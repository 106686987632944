import {
	SET_ADMIN_LOC_NEW_LOC_SELECTED_ORG,
	SET_ADMIN_LOC_NEW_LOC_SELECTED_BRANCH,
	SET_ADMIN_LOC_DISPLAY_ORG_IND,
	SET_ADMIN_LOC_DISPLAY_BRANCH_IND,
	SET_ADMIN_LOC_DISPLAY_INVENTORY_IND,
	SET_ADMIN_LOC_DISPLAY_LOCATION_IND,
	SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE,
} from "../../type/type/ADMIN"

export const setAdminLocNewLocSelectedOrg = (index, org) => {
	return {
		type: SET_ADMIN_LOC_NEW_LOC_SELECTED_ORG,
		payload: {
			index: index,
			org: org,
		},
	}
}

export const setAdminLocNewLocSelectedBranch = (index, branch) => {
	return {
		type: SET_ADMIN_LOC_NEW_LOC_SELECTED_BRANCH,
		payload: {
			index: index,
			branch: branch,
		},
	}
}

export const setAdminLocDisplayOrg = (isDisplay) => {
	return {
		type: SET_ADMIN_LOC_DISPLAY_ORG_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdminLocDisplayBranch = (isDisplay) => {
	return {
		type: SET_ADMIN_LOC_DISPLAY_BRANCH_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdminLocDisplayInventory = (isDisplay) => {
	return {
		type: SET_ADMIN_LOC_DISPLAY_INVENTORY_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdminLocDisplayLocation = (isDisplay) => {
	return {
		type: SET_ADMIN_LOC_DISPLAY_LOCATION_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdminlocModalNLTitle = (title) => {
	return {
		type: SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE,
		payload: {
			title: title,
		},
	}
}
