import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormControl, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setObjArray,
	setOPDModalNurseServicePendingServices,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY,
	SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME,
	SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class OPDModalNurseServiceFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onCategory = this.onCategory.bind(this)
		this.onSaveAll = this.onSaveAll.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.opd.modalNurseService.categories)) {
			this.props.setSelected(
				SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY,
				0,
				this.props.opd.modalNurseService.categories[0]
			)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY, -1, null)
		this.props.setValue(SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME, "")
	}

	onName(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME, Utils.trim(event.target.value))
	}

	onCategory(event) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY,
			event.target.value,
			this.props.opd.modalNurseService.categories[event.target.value]
		)
	}

	onSaveAll() {
		window.saveAllCaller = this
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_SAVE_ALL_DISPLAY, true)
	}

	saveAllCallback() {
		let selectedServices = this.props.opd.modalNurseService.serviceTable.filtered.map((service) => {
			return Object.assign({}, service, {
				startDateTime: this.props.saveAll.startDateTime,
				endDateTime: this.props.saveAll.isDisableEndDate ? null : this.props.saveAll.endDateTime,
			})
		})
		let services = this.props.opd.modalNurseService.pendingServiceTable.original
		services = services.concat(selectedServices)
		this.props.setOPDModalNurseServicePendingServices(services)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<FormControl
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onCategory}
						>
							{Utils.renderOptions(this.props.opd.modalNurseService.categories, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onName]}
						/>
					</Col>
					<Col md="auto">
						<div className="vertical"></div>
					</Col>
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							disabled={this.props.opd.modalNurseService.serviceTable.filtered.length <= 1}
							onClick={this.onSaveAll}
						>
							<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.saveAll)}
						</Button>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	saveAll: state.modal.hospital.saveAll,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setObjArray,
			setSelected,
			setValue,
			setOPDModalNurseServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseServiceFilter))
