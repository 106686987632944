import {
	SET_RC_DISPLAY_ALL_IND,
	SET_RC_END_DATE,
	SET_RC_INVOICES,
	SET_RC_INVOICE_NUM,
	SET_RC_MODAL_CANCEL_PENDING_RECEIVE,
	SET_RC_MODAL_CANCEL_REASON,
	SET_RC_MODAL_CANCEL_SEARCH_CODE,
	SET_RC_MODAL_CANCEL_SEARCH_NAME,
	SET_RC_MODAL_CANCEL_WAITING_RECEIVES,
	SET_RC_MODAL_RECEIVE_WAITING_RECEIVES,
	SET_RC_ORDERS,
	SET_RC_ORDERS_LOADING,
	SET_RC_PENDINGS,
	SET_RC_RECEIVED,
	SET_RC_RECEIVED_DATE,
	SET_RC_RECEIVE_DETAIL_AMOUNT,
	SET_RC_RECEIVE_DETAIL_BONUS,
	SET_RC_RECEIVE_DETAIL_CODE,
	SET_RC_RECEIVE_DETAIL_DISCOUNT,
	SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_EXPIRE_DATE,
	SET_RC_RECEIVE_DETAIL_LOT,
	SET_RC_RECEIVE_DETAIL_NAME,
	SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT,
	SET_RC_RECEIVE_DETAIL_TAX,
	SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_UNIT,
	SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT,
	SET_RC_SEARCH_CODE,
	SET_RC_SEARCH_NAME,
	SET_RC_SEARCH_NUM,
	SET_RC_SELECTED_INVOICE,
	SET_RC_SELECTED_PENDING,
	SET_RC_SELECTED_RC,
	SET_RC_SELECTED_SUPPLIER,
	SET_RC_SELECTED_WAITING,
	SET_RC_START_DATE,
	SET_RC_TAXED_TOTAL_PRICE,
	SET_RC_WAITING_RECEIVES,
	SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND,
} from "../type/type/RC"

const { ResourceAssistance } = require("~/i18n")

const DATE = new Date()

const init = {
	searchNum: "",
	isDisplayAll: false,
	isWaitingReceiveDisplayAll: false,
	searchDate: {
		startDate: new Date(DATE.getFullYear(), DATE.getMonth(), 1),
		endDate: new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0, 23, 59, 59),
	},
	order: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.hashSymbol,
			ResourceAssistance.Message.approverCheck,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	invoice: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.invoiceNum,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.supplier,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.totalPriceTaxed,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.by,
		],
		body: [],
		isLoading: false,
	},
	received: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.lotNum,
			ResourceAssistance.Message.expire,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.pricePerOrder,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerOrder,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceTaxed,
		],
		body: [],
		isLoading: false,
	},
	waitingReceive: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.received,
			ResourceAssistance.Message.pricePerOrder,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerOrder,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.supplierDiscount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	modalReceiveWaitingReceive: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.received,
			ResourceAssistance.Message.pricePerUnit,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerUnit,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.supplierDiscount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
		],
		body: [],
	},
	pendingReceive: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.lotNum,
			ResourceAssistance.Message.expire,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.pricePerUnit,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerUnit,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.supplierDiscount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
		],
		body: [],
		isLoading: false,
	},
	modalCancelWaitingReceive: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.received,
			ResourceAssistance.Message.pricePerUnit,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerUnit,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceTaxed,
		],
		body: [],
	},
	modalCancelPendingReceive: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.pricePerUnit,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerUnit,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceTaxed,
		],
		body: [],
	},
	modalReceive: {
		searchCode: "",
		searchName: "",
		invoiceNum: "",
		taxedTotalPrice: "",
		receivedDate: new Date(),
	},
	modalCancel: {
		searchCode: "",
		searchName: "",
		reason: "",
	},
	modalReceiveDetail: {
		name: "",
		lot: "",
		expireDate: new Date(DATE.getFullYear() + 1, DATE.getMonth(), DATE.getDate()),
		amount: "",
		unit: "",
		bonus: "0",
		pricePerUnit: "",
		discount: "0",
		discountPricePerUnit: "",
		supplierDiscount: "",
		supplierDiscountPricePerUnit: "",
		tax: "",
		discountTaxPricePerUnit: "",
		totalPrice: "",
		discountedTotalPrice: "",
		taxedTotalPrice: "",
		isTotalPriceModified: false,
		isDiscountedTotalPriceModified: false,
		isTaxedTotalPriceModified: false,
	},
	selectedRc: {
		rc: null,
		index: -1,
	},
	selectedWaiting: {
		inventory: null,
		index: -1,
	},
	selectedPending: {
		inventory: null,
		index: -1,
	},
	selectedSupplier: {
		supplier: null,
		index: -1,
	},
	selectedInvoice: {
		invoice: null,
		index: -1,
	},
}

const receiveReducer = (state = init, action) => {
	switch (action.type) {
		case SET_RC_START_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					startDate: action.payload.date,
				},
			})

		case SET_RC_END_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					endDate: action.payload.date,
				},
			})

		case SET_RC_SEARCH_NUM:
			return Object.assign({}, state, {
				searchNum: action.payload.num,
			})

		case SET_RC_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				isDisplayAll: action.payload.isDisplayAll,
			})

		case SET_RC_ORDERS_LOADING:
			return Object.assign({}, state, {
				order: {
					...state.order,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_RC_ORDERS:
			return Object.assign({}, state, {
				order: {
					...state.order,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_RC_INVOICES:
			return Object.assign({}, state, {
				invoice: {
					...state.invoice,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_RC_RECEIVED:
			return Object.assign({}, state, {
				received: {
					...state.received,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_RC_SELECTED_RC:
			return Object.assign({}, state, {
				selectedRc: {
					...state.selectedRc,
					rc: action.payload.rc,
					index: action.payload.index,
				},
			})

		case SET_RC_PENDINGS:
			return Object.assign({}, state, {
				pendingReceive: {
					...state.pendingReceive,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_RC_WAITING_RECEIVES:
			return Object.assign({}, state, {
				waitingReceive: {
					...state.waitingReceive,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_RC_MODAL_RECEIVE_WAITING_RECEIVES:
			return Object.assign({}, state, {
				modalReceiveWaitingReceive: {
					...state.modalReceiveWaitingReceive,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_RC_SELECTED_WAITING:
			return Object.assign({}, state, {
				selectedWaiting: {
					...state.selectedWaiting,
					inventory: action.payload.inventory,
					index: action.payload.index,
				},
			})

		case SET_RC_SELECTED_PENDING:
			return Object.assign({}, state, {
				selectedPending: {
					...state.selectedPending,
					inventory: action.payload.inventory,
					index: action.payload.index,
				},
			})

		case SET_RC_RECEIVE_DETAIL_CODE:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					code: action.payload.code,
				},
			})

		case SET_RC_RECEIVE_DETAIL_NAME:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					name: action.payload.name,
				},
			})

		case SET_RC_RECEIVE_DETAIL_LOT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					lot: action.payload.lot,
				},
			})

		case SET_RC_RECEIVE_DETAIL_EXPIRE_DATE:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					expireDate: action.payload.expireDate,
				},
			})

		case SET_RC_RECEIVE_DETAIL_AMOUNT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					amount: action.payload.amount,
				},
			})

		case SET_RC_RECEIVE_DETAIL_UNIT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					unit: action.payload.unit,
				},
			})

		case SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					pricePerUnit: action.payload.pricePerUnit,
				},
			})

		case SET_RC_RECEIVE_DETAIL_BONUS:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					bonus: action.payload.bonus,
				},
			})

		case SET_RC_RECEIVE_DETAIL_DISCOUNT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					discount: action.payload.discount,
				},
			})

		case SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					discountPricePerUnit: action.payload.discountPricePerUnit,
				},
			})

		case SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					supplierDiscount: action.payload.supplierDiscount,
				},
			})

		case SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					supplierDiscountPricePerUnit: action.payload.supplierDiscountPricePerUnit,
				},
			})

		case SET_RC_RECEIVE_DETAIL_TOTAL_PRICE:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					totalPrice: action.payload.totalPrice,
				},
			})

		case SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					discountedTotalPrice: action.payload.discountedTotalPrice,
				},
			})

		case SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					taxedTotalPrice: action.payload.taxedTotalPrice,
				},
			})

		case SET_RC_RECEIVE_DETAIL_TAX:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					tax: action.payload.tax,
				},
			})

		case SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					discountTaxPricePerUnit: action.payload.discountTaxPricePerUnit,
				},
			})

		case SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					isDiscountedTotalPriceModified: action.payload.isModified,
				},
			})

		case SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					isTaxedTotalPriceModified: action.payload.isTaxedTotalPriceModified,
				},
			})

		case SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND:
			return Object.assign({}, state, {
				modalReceiveDetail: {
					...state.modalReceiveDetail,
					isTotalPriceModified: action.payload.isTotalPriceModified,
				},
			})

		case SET_RC_SEARCH_CODE:
			return Object.assign({}, state, {
				modalReceive: {
					...state.modalReceive,
					searchCode: action.payload.text,
				},
			})

		case SET_RC_SEARCH_NAME:
			return Object.assign({}, state, {
				modalReceive: {
					...state.modalReceive,
					searchName: action.payload.text,
				},
			})

		case SET_RC_RECEIVED_DATE:
			return Object.assign({}, state, {
				modalReceive: {
					...state.modalReceive,
					receivedDate: action.payload.date,
				},
			})

		case SET_RC_INVOICE_NUM:
			return Object.assign({}, state, {
				modalReceive: {
					...state.modalReceive,
					invoiceNum: action.payload.num,
				},
			})

		case SET_RC_TAXED_TOTAL_PRICE:
			return Object.assign({}, state, {
				modalReceive: {
					...state.modalReceive,
					taxedTotalPrice: action.payload.taxedTotalPrice,
				},
			})

		case SET_RC_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				selectedSupplier: {
					...state.selectedSupplier,
					supplier: action.payload.supplier,
					index: action.payload.index,
				},
			})

		case SET_RC_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					...state.selectedInvoice,
					invoice: action.payload.invoice,
					index: action.payload.index,
				},
			})

		case SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				isWaitingReceiveDisplayAll: action.payload.isDisplayAll,
			})

		case SET_RC_MODAL_CANCEL_SEARCH_CODE: {
			return Object.assign({}, state, {
				modalCancel: {
					...state.modalCancel,
					searchCode: action.payload.text,
				},
			})
		}

		case SET_RC_MODAL_CANCEL_SEARCH_NAME: {
			return Object.assign({}, state, {
				modalCancel: {
					...state.modalCancel,
					searchName: action.payload.text,
				},
			})
		}

		case SET_RC_MODAL_CANCEL_WAITING_RECEIVES: {
			return Object.assign({}, state, {
				modalCancelWaitingReceive: {
					...state.modalCancelWaitingReceive,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		}

		case SET_RC_MODAL_CANCEL_PENDING_RECEIVE: {
			return Object.assign({}, state, {
				modalCancelPendingReceive: {
					...state.modalCancelPendingReceive,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		}

		case SET_RC_MODAL_CANCEL_REASON: {
			return Object.assign({}, state, {
				modalCancel: {
					...state.modalCancel,
					reason: action.payload.reason,
				},
			})
		}

		default:
			return state
	}
}

export default receiveReducer
