import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPoSelectedSupplier, setPoSuppliers } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PORightThirdRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORightThirdRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.selectedPo.index !== this.props.selectedPo.index ||
			!_.isEqual(prevProps.selectedPo.po, this.props.selectedPo.po)
		) {
			if (this.props.selectedPo.index === -1) {
				this.props.setPoSuppliers([])
			} else {
				this.props.setPoSuppliers(this.props.selectedPo.po.suppliers)
			}
			this.props.setPoSelectedSupplier(-1, null)
		}
	}

	onSelectRow(row, index) {
		this.props.setPoSelectedSupplier(index, this.props.supplier.filtered[index])
	}

	render() {
		return (
			<Row noGutters id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.supplier}
						displayAttachment
						onClick={this.onSelectRow}
						isClearHighlight={this.props.selectedSupplier.index === -1}
					/>
					{this.props.supplier.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	supplier: state.purchaseOrder.supplier,
	selectedPo: state.purchaseOrder.selectedPo,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoSuppliers, setPoSelectedSupplier }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightThirdRow)
