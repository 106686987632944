import BigNumber from "bignumber.js"
import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setTfModalTransferBranches,
	setTfModalTransferCurrent,
	setTfModalTransferExpireDate,
	setTfModalTransferItems,
	setTfModalTransferLots,
	setTfModalTransferPendingItems,
	setTfModalTransferSelectedBranch,
	setTfModalTransferSelectedItem,
	setTfModalTransferSelectedLot,
	setTfModalTransferSelectedOrg,
	setTfModalTransferTransferQty,
} from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFModalNTItemRow extends React.Component {
	constructor(props) {
		super(props)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onOrgChange = this.onOrgChange.bind(this)
		this.onBranchChange = this.onBranchChange.bind(this)
		this.onLotChange = this.onLotChange.bind(this)
		this.onAmountChange = this.onAmountChange.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentWillUnmount() {
		this.props.setTfModalTransferItems([])
		this.props.setTfModalTransferSelectedItem(-1, null)
		this.props.setTfModalTransferBranches([])
		this.props.setTfModalTransferLots(null)
		this.props.setTfModalTransferSelectedOrg(-1, null)
		this.props.setTfModalTransferSelectedBranch(-1, null)
		this.props.setTfModalTransferSelectedLot(-1, null)
		this.props.setTfModalTransferCurrent(null, "")
		this.props.setTfModalTransferExpireDate(0)
		this.props.setTfModalTransferTransferQty(0)
	}

	componentDidMount() {
		this.props.setTfModalTransferItems(this.props.transfer.selectedRequest.request.items)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.transfer.newTransferModal.selectedItem) !==
			JSON.stringify(this.props.transfer.newTransferModal.selectedItem)
		) {
			this.props.setTfModalTransferSelectedOrg(-1, null)
			let org = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.org)
			org.selectedIndex = 0
		}

		if (
			JSON.stringify(prevProps.transfer.newTransferModal.selectedOrg) !==
			JSON.stringify(this.props.transfer.newTransferModal.selectedOrg)
		) {
			this.props.setTfModalTransferSelectedBranch(-1, null)
			let branch = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.branch)
			branch.selectedIndex = 0
			if (this.props.transfer.newTransferModal.selectedOrg.index === -1) {
				this.props.setTfModalTransferBranches([])
			} else {
				this.props.setTfModalTransferBranches(this.props.transfer.newTransferModal.selectedOrg.location.locations)
			}
		}

		if (
			JSON.stringify(prevProps.transfer.newTransferModal.selectedBranch) !==
			JSON.stringify(this.props.transfer.newTransferModal.selectedBranch)
		) {
			this.props.setTfModalTransferSelectedLot(-1, null)
			let lot = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.lot)
			lot.selectedIndex = 0
			if (this.props.transfer.newTransferModal.selectedBranch.index === -1) {
				this.props.setTfModalTransferLots(null)
				this.props.setTfModalTransferCurrent(null, "")
			} else {
				this.props.setTfModalTransferLots(this.props.transfer.newTransferModal.selectedBranch.location)
				this.props.setTfModalTransferCurrent(this.props.transfer.newTransferModal.selectedBranch.location)
			}
		}

		if (
			JSON.stringify(prevProps.transfer.newTransferModal.selectedLot) !==
			JSON.stringify(this.props.transfer.newTransferModal.selectedLot)
		) {
			if (this.props.transfer.newTransferModal.selectedLot.index === -1) {
				this.props.setTfModalTransferCurrent(this.props.transfer.newTransferModal.selectedBranch.location)
				this.props.setTfModalTransferExpireDate("")
			} else {
				this.props.setTfModalTransferCurrent(null, this.props.transfer.newTransferModal.selectedLot.lot.current)
				this.props.setTfModalTransferExpireDate(this.props.transfer.newTransferModal.selectedLot.lot.expiredDateTime)
			}
		}

		if (
			prevProps.transfer.newTransferModal.transfer !== this.props.transfer.newTransferModal.transfer ||
			JSON.stringify(prevProps.transfer.newTransferModal.selectedLot) !==
				JSON.stringify(this.props.transfer.newTransferModal.selectedLot)
		) {
			this.resetValidateAmount()
		}

		if (
			JSON.stringify(prevProps.transfer.newTransferModal.pendingItemTable.original) !==
			JSON.stringify(this.props.transfer.newTransferModal.pendingItemTable.original)
		) {
			this.props.setTfModalTransferItems(this.props.transfer.selectedRequest.request.items)
		}
	}

	isDownBtnDisabled() {
		return (
			this.props.transfer.newTransferModal.selectedItem.index === -1 ||
			this.props.transfer.newTransferModal.selectedOrg.index === -1 ||
			this.props.transfer.newTransferModal.selectedBranch.index === -1 ||
			this.props.transfer.newTransferModal.selectedLot.index === -1 ||
			this.props.transfer.newTransferModal.transfer === 0
		)
	}

	isUpBtnDisabled() {
		return this.props.transfer.newTransferModal.selectedPendingItem.index === -1
	}

	resetValidateAmount() {
		let amountInput = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.transferAmount)
		amountInput.setCustomValidity("")
	}

	validateAmount() {
		let current = BigNumber(this.props.transfer.newTransferModal.selectedLot.lot.current)
		let amountInput = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.transferAmount)
		let selectedItem = this.props.transfer.newTransferModal.selectedItem.item
		let pendingItems = this.props.transfer.newTransferModal.pendingItemTable.filtered
		let totalTransferAmount = pendingItems
			.filter(
				(each) =>
					(each.code + each.name + each.brand + each.unit).trim().toLowerCase() ===
					(selectedItem.code + selectedItem.name + selectedItem.brand + selectedItem.unit).trim().toLowerCase()
			)
			.filter((each) => each.lotNum !== this.props.transfer.newTransferModal.selectedLot.lot.number)
			.reduce((obj, item) => {
				return obj.plus(item.amount)
			}, BigNumber(0))
			.plus(selectedItem.transferredAmount)

		if (BigNumber(this.props.transfer.newTransferModal.transfer).gt(current)) {
			amountInput.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongTransferAmount })
			)
			return false
		} else if (totalTransferAmount.plus(this.props.transfer.newTransferModal.transfer).gt(selectedItem.amount)) {
			amountInput.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongTransferAmountGreaterThanRequested })
			)
			return false
		} else {
			amountInput.setCustomValidity("")
		}

		return true
	}

	onSelectRow(row, index) {
		this.props.setTfModalTransferSelectedItem(index, this.props.transfer.newTransferModal.itemTable.filtered[index])
	}

	onOrgChange(event) {
		this.props.setTfModalTransferSelectedOrg(
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.transfer.newTransferModal.orgs[event.target.value] : null
		)
	}

	onBranchChange(event) {
		this.props.setTfModalTransferSelectedBranch(
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.transfer.newTransferModal.branches[event.target.value] : null
		)
	}

	onLotChange(event) {
		this.props.setTfModalTransferSelectedLot(
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.transfer.newTransferModal.lots[event.target.value] : null
		)
	}

	onAmountChange(event) {
		this.props.setTfModalTransferTransferQty(event.target.value ? Utils.BigNumber(event.target.value).toNumber() : 0)
	}

	onDownBtn() {
		let amountInput = document.getElementById(ResourceAssistance.ID.TF.modalTransfer.transferAmount)
		if (!this.validateAmount() || this.isDownBtnDisabled()) {
			amountInput.reportValidity()
			return
		}

		let item = this.props.transfer.newTransferModal.selectedItem.item

		let pendingItems = Utils.converArrayToObjectByCustomKey(
			this.props.transfer.newTransferModal.pendingItemTable.filtered,
			["code", "displayName", "name", "brand", "unit", "lotNum", "locationId"]
		)

		let key = (
			item.code +
			item.name +
			item.brand +
			item.unit +
			this.props.transfer.newTransferModal.selectedLot.lot.number +
			this.props.transfer.newTransferModal.selectedBranch.location.id
		)
			.trim()
			.toLowerCase()

		let items = Object.assign({}, pendingItems, {
			[key]: {
				id: pendingItems[key] ? pendingItems[key].id : 0,
				transferRequestItemId: item.id,
				type: item.type,
				code: item.code,
				name: item.name,
				brand: item.brand,
				unit: item.unit,
				lotNum: this.props.transfer.newTransferModal.selectedLot.lot.number,
				expiredDateTime: this.props.transfer.newTransferModal.selectedLot.lot.expiredDateTime,
				amount: this.props.transfer.newTransferModal.transfer,
				locationId: this.props.transfer.newTransferModal.selectedBranch.location.id,
				location:
					this.props.transfer.newTransferModal.selectedOrg.location.displayName +
					"-" +
					this.props.transfer.newTransferModal.selectedBranch.location.displayName,
				lot: this.props.transfer.newTransferModal.selectedLot.lot,
			},
		})

		this.props.setTfModalTransferPendingItems(Object.values(items))
	}

	onUpBtn() {
		if (this.isUpBtnDisabled()) {
			return
		}

		let pendingItems = this.props.transfer.newTransferModal.pendingItemTable.filtered.filter(
			(item) => item.id !== this.props.transfer.newTransferModal.selectedPendingItem.item.id
		)

		this.props.setTfModalTransferPendingItems(pendingItems)
	}

	render() {
		return (
			<Row noGutters className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.labelInfoRow}`}>
				<Col id={ResourceAssistance.ID.TF.modalTransfer.tableItem}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableHighlight
						data={this.props.transfer.newTransferModal.itemTable}
						onClick={this.onSelectRow}
					/>
					<Row>
						<Col className={this.isDownBtnDisabled() ? ResourceAssistance.CSS.disabled : ""}>
							<FcDownload size={"3rem"} onClick={this.onDownBtn} style={{ alignSelf: "center" }} />
						</Col>
						<Col className={this.isUpBtnDisabled() ? ResourceAssistance.CSS.disabled : ""}>
							<FcUpload size={"3rem"} onClick={this.onUpBtn} style={{ alignSelf: "center" }} />
						</Col>
					</Row>
				</Col>
				<Col md="auto">
					<Row>
						<Col>
							<FormLabel
								className={ResourceAssistance.CSS.flexSelfCenter}
								size={ResourceAssistance.FormControl.size.sm}
							>
								{translate(ResourceAssistance.Message.org)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.TF.modalTransfer.org}
								size={ResourceAssistance.FormControl.size.sm}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.transfer.newTransferModal.selectedItem.index === -1}
								onChange={this.onOrgChange}
							>
								{Utils.renderOptions(this.props.transfer.newTransferModal.orgs, true)}
							</FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabel
								className={ResourceAssistance.CSS.flexSelfCenter}
								size={ResourceAssistance.FormControl.size.sm}
							>
								{translate(ResourceAssistance.Message.branch)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.TF.modalTransfer.branch}
								size={ResourceAssistance.FormControl.size.sm}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.transfer.newTransferModal.selectedItem.index === -1}
								onChange={this.onBranchChange}
							>
								{Utils.renderOptions(this.props.transfer.newTransferModal.branches, true)}
							</FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabel
								className={ResourceAssistance.CSS.flexSelfCenter}
								size={ResourceAssistance.FormControl.size.sm}
							>
								{translate(ResourceAssistance.Message.lotNum)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.TF.modalTransfer.lot}
								size={ResourceAssistance.FormControl.size.sm}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.transfer.newTransferModal.selectedItem.index === -1}
								onChange={this.onLotChange}
							>
								{Utils.renderOptions(this.props.transfer.newTransferModal.lots, true, "", "number")}
							</FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabel className={ResourceAssistance.CSS.flexSelfCenter}>
								{translate(ResourceAssistance.Message.current)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								type={ResourceAssistance.FormControl.type.text}
								size={ResourceAssistance.FormControl.size.sm}
								value={this.props.transfer.newTransferModal.current}
								disabled
							></FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabel className={ResourceAssistance.CSS.flexSelfCenter}>
								{translate(ResourceAssistance.Message.expired)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								type={ResourceAssistance.FormControl.type.text}
								size={ResourceAssistance.FormControl.size.sm}
								value={Utils.formatDate(this.props.transfer.newTransferModal.expired)}
								disabled
							></FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabel className={ResourceAssistance.CSS.flexSelfCenter}>
								{translate(ResourceAssistance.Message.transfer)}
							</FormLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.TF.modalTransfer.transferAmount}
								type={ResourceAssistance.FormControl.type.number}
								size={ResourceAssistance.FormControl.size.sm}
								disabled={this.props.transfer.newTransferModal.selectedLot.index === -1}
								onChange={this.onAmountChange}
							></FormControl>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfModalTransferItems,
			setTfModalTransferSelectedItem,
			setTfModalTransferBranches,
			setTfModalTransferSelectedOrg,
			setTfModalTransferSelectedBranch,
			setTfModalTransferLots,
			setTfModalTransferSelectedLot,
			setTfModalTransferCurrent,
			setTfModalTransferTransferQty,
			setTfModalTransferExpireDate,
			setTfModalTransferPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TFModalNTItemRow))
