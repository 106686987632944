import _ from "lodash"
import React from "react"
import { connect } from "react-redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { LOGIN } from "~/redux/type"
import { loginAction } from "~/redux/action/loginAction"

class Validate extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.login.user)) {
			let params = {
				method: "GET",
				url: ResourceAssistance.Url.auth.validateCookie,
				withCredentials: true,
			}

			let callback = (res) => {
				this.props.loginAction(LOGIN, true, res.data.user)
				this.props.history.push(ResourceAssistance.Path.home)
			}

			let errorHandler = (error) => {
				if (error.response.status === 401 && this.props.history.location.pathname !== ResourceAssistance.Path.login) {
					this.props.history.push(ResourceAssistance.Path.login)
				}
			}

			axios(params, callback, errorHandler)
		}
	}

	render() {
		return <></>
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default connect(mapStateToProps, { loginAction })(Validate)
