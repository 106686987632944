import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmTSelectedTax, setAdmTTaxes, setLoadingAction } from "~/redux/action"
import { SET_ADM_TAX_NEW_TAX_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmTModalNTDetails from "./AdmTModalNTDetails"

class AdmUModalNU extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmUModalNU",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.tax.selectedTax.tax, {
			...this.props.tax.selectedTax.tax,
			displayName: this.props.tax.modalNewTax.name.trim(),
			description: this.props.tax.modalNewTax.description.trim(),
			taxRate: Utils.BigNumber(this.props.tax.modalNewTax.taxRate).toNumber(),
			active: this.props.tax.modalNewTax.isActive,
		})
		return _.isEqual(this.props.tax.selectedTax.tax, target)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_TAX_NEW_TAX_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.tax.saveTax}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.tax.selectedTax.index === -1 ? 0 : this.props.tax.selectedTax.tax.id,
				name: this.props.tax.modalNewTax.name.trim(),
				description: this.props.tax.modalNewTax.description.trim(),
				taxRate: this.props.tax.modalNewTax.taxRate,
				active: this.props.tax.modalNewTax.isActive,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			this.props.setAdmTSelectedTax(-1, null)
			let taxes = this.props.tax.taxTable.original.filter((each) => each.id !== res.data.taxes[0].id)
			taxes.push(res.data.taxes[0])
			this.props.setAdmTTaxes(taxes)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_TAX_NEW_TAX_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newTax)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						{/*  */}
						{<AdmTModalNTDetails />}
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmTNewTaxDisplay,
	login: state.login,
	tax: state.admin.itemConfig.tax,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmTTaxes, setAdmTSelectedTax }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUModalNU)
