import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import RadiologyLeft from "./left/RadiologyLeft"

class Radiology extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Radiology",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<Row noGutters>
					<Col md="auto">
						<RadiologyLeft />
					</Col>
					<Col>{/* <IPDRight /> */}</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	radiology: state.hospital.radiology,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Radiology))
