import { Descriptions } from "antd"
import propTypes from "prop-types"
import React, { Fragment } from "react"
import { ResourceAssistance, translate } from "~/i18n"

class HospitelModalPatientClinicalRecordHealthcare extends React.Component {
	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
				<Descriptions
					size={"small"}
					style={{ flex: "unset", display: "unset" }}
					labelStyle={{ fontWeight: "bold" }}
					contentStyle={{ fontStyle: "italic" }}
				>
					{this.props.patient.clinicalRecord.healthcareCode ? (
						<Fragment>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.patient.clinicalRecord.healthcareCode}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={2}>
								{this.props.patient.clinicalRecord.healthcareName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={3}>
								{this.props.patient.clinicalRecord.healthcareDescription}
							</Descriptions.Item>
						</Fragment>
					) : (
						<Descriptions.Item label={translate(ResourceAssistance.Message.other)} span={3}>
							{this.props.patient.clinicalRecord.healthcareOther}
						</Descriptions.Item>
					)}
				</Descriptions>
			</fieldset>
		)
	}
}

HospitelModalPatientClinicalRecordHealthcare.propTypes = {
	patient: propTypes.object,
}

HospitelModalPatientClinicalRecordHealthcare.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitelModalPatientClinicalRecordHealthcare
