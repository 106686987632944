import propTypes from "prop-types"
import React from "react"
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile"
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn"
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet"

class ExcelDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			excel: {
				sheets: [
					{
						headers: [],
						data: [],
					},
				],
			},
		}
	}

	componentWillUnmount() {
		this.setState({
			excel: {
				sheets: [
					{
						headers: [],
						data: [],
					},
				],
			},
		})
	}

	uodateExcel = (excel) => {
		this.setState({
			excel: excel,
		})
	}

	render() {
		return (
			<ExcelFile element={this.props.element} filename={this.state.excel.filename}>
				{this.state.excel.sheets.map((sheet, sheetIdx) => {
					return (
						<ExcelSheet key={sheetIdx} data={sheet.data} name={sheet.name}>
							{sheet.headers.map((header, headerIdx) => {
								return (
									<ExcelColumn
										key={headerIdx}
										label={header}
										value={(col) => {
											return col[headerIdx]
										}}
									/>
								)
							})}
						</ExcelSheet>
					)
				})}
			</ExcelFile>
		)
	}
}

ExcelDocument.propTypes = {
	element: propTypes.object,
}

ExcelDocument.defaultProps = {}

export default ExcelDocument
