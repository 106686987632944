import _ from "lodash"
import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import PharmacyRightDispensedOrder from "./PharmacyRightDispensedOrder"
import PharmacyRightReturnOrder from "./PharmacyRightReturnOrder"
import PharmacyRightVerifiedOrder from "./PharmacyRightVerifiedOrder"

class PharmacyRightPharmacyTab extends React.Component {
	EPharmacy = {
		VERIFIED: "PHARMACY_VERIFIED",
		DISPENSED: "PHARMACY_DISPENSED",
		RETURN: "PHARMACY_RETURN",
		ICD: "PHARMACY_ICD",
	}
	constructor(props) {
		super(props)

		this.state = {
			componentName: "PharmacyRightPharmacyTab",
			tab: this.EPharmacy.VERIFIED,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedAdmission, this.props.pharmacy.selectedAdmission)) {
			this.setState({
				tab: this.EPharmacy.VERIFIED,
			})
		}
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tab.Container
				id={this.state.componentName}
				activeKey={this.state.tab}
				onSelect={this.onTabClick}
				mountOnEnter={false}
			>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.EPharmacy.VERIFIED}>{translate(ResourceAssistance.Message.verified)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EPharmacy.DISPENSED}>
									{translate(ResourceAssistance.Message.dispensed)}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EPharmacy.RETURN}>{translate(ResourceAssistance.Message.return)}</Nav.Link>
							</Nav.Item>
							{/* {this.props.pharmacy.selectedMedicalRecord.medicalRecord &&
								Object.entries(
									Utils.groupBy(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.icds, "type")
								).map(([key, values]) => {
									return (
										<Nav.Item key={key}>
											<Nav.Link eventKey={key}>{key}</Nav.Link>
										</Nav.Item>
									)
								})} */}
						</Nav>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EPharmacy.VERIFIED}>
								<PharmacyRightVerifiedOrder {...this.props} />
							</Tab.Pane>
							<Tab.Pane eventKey={this.EPharmacy.DISPENSED}>
								<PharmacyRightDispensedOrder {...this.props} />
							</Tab.Pane>
							<Tab.Pane eventKey={this.EPharmacy.RETURN}>
								<PharmacyRightReturnOrder />
							</Tab.Pane>

							{/* {this.props.opd.selectedMedicalRecord.medicalRecord &&
								Object.entries(
									Utils.groupBy(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.icds, "type")
								).map(([key, values]) => {
									return (
										<Tab.Pane key={key} eventKey={key}>
											<OutpatientRightICD icds={values} />
										</Tab.Pane>
									)
								})} */}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyRightPharmacyTab)
