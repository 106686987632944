import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND,
} from "~/redux/type"

const init = {
	focus: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedFocus: {
			index: -1,
			focus: null,
		},
		focusTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewFocus: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	intervention: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedIntervention: {
			index: -1,
			intervention: null,
		},
		interventionTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewIntervention: {
			name: "",
			description: "",
			isActive: true,
		},
	},
}

const customDefaultConfigReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					searchName: action.payload.value,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					displayInactive: action.payload.value,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					types: action.payload.objs,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					focusTable: {
						...state.focus.focusTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					selectedFocus: {
						index: action.payload.index,
						focus: action.payload.selected,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						name: action.payload.value,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						description: action.payload.value,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						isActive: action.payload.value,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					searchName: action.payload.value,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					displayInactive: action.payload.value,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					types: action.payload.objs,
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					interventionTable: {
						...state.intervention.interventionTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					selectedIntervention: {
						index: action.payload.index,
						intervention: action.payload.selected,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						name: action.payload.value,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						description: action.payload.value,
					},
				},
			})

		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						isActive: action.payload.value,
					},
				},
			})

		default:
			return state
	}
}

export default customDefaultConfigReducer
