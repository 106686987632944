import React from "react"
import { Col, Container, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND,
	SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME,
	SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES,
	SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalAdjustmentDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCheckEndDate = this.onCheckEndDate.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onNote = this.onNote.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedNurseOrder.order) {
			this.props.setValue(
				SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME,
				this.props.hospitel.selectedNurseOrder.order.startDateTime
			)
			this.props.setValue(
				SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY,
				this.props.hospitel.selectedNurseOrder.order.qtyPerDay
			)
			this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES, this.props.hospitel.selectedNurseOrder.order.notes)
			if (this.props.hospitel.selectedNurseOrder.order.endDateTime) {
				this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, false)
				this.props.setValue(
					SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME,
					this.props.hospitel.selectedNurseOrder.order.endDateTime
				)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.modalAdjustment.startDateTime !== this.props.hospitel.modalAdjustment.startDateTime) {
			this.props.setValue(
				SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME,
				Utils.generateDateFromLong(this.props.hospitel.modalAdjustment.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, true)
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY, "")
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES, "")
	}

	onCheckEndDate(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, !event.target.checked)
	}

	onStartDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME, date.getTime())
	}

	onQty(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY, Number(event.target.value))
	}

	onNote(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row noGutters className={ResourceAssistance.CSS.labelInfoRow} style={{ justifyContent: "center" }}>
					<Col md={"auto"}>
						<DatePicker
							isShowTime={false}
							// minDate={Utils.generateDate()}
							// showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustment.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col md={"auto"}>
						<FormLabel>{translate(ResourceAssistance.Message.to)}</FormLabel>
					</Col>
					<Col md={"auto"}>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Checkbox
									checked={!this.props.hospitel.modalAdjustment.isDisableEndDate}
									onChange={this.onCheckEndDate}
								/>
							</InputGroup.Prepend>
							<DatePicker
								isShowTime={false}
								minDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustment.startDateTime)}
								showDisabledMonthNavigation
								disabled={this.props.hospitel.modalAdjustment.isDisableEndDate}
								startDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustment.endDateTime)}
								onChange={this.onEndDate}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup size={"sm"}>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.qty)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								autoFocus
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.number}
								step={1}
								min={1}
								value={this.props.hospitel.modalAdjustment.qtyPerDay}
								onChange={this.onQty}
							/>
							<InputGroup.Append>
								<InputGroup.Text>/{translate(ResourceAssistance.Message.day)}</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.note}>
							{(placeholder) => (
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.props.hospitel.modalAdjustment.notes}
									onChange={this.onNote}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalAdjustmentDetails)
