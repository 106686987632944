import React from "react"
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setObjArray, setOPDPatients } from "~/redux/action"
import { SET_OPD_DOCTOR_DISPLAY, SET_OPD_MODAL_DOCTOR_ORDER_UNITS, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OutpatientModalDoctorDetails from "./OutpatientModalDoctorDetails"

class OutpatientModalDoctor extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientModalDoctor",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.loadUnits()
	}

	loadUnits() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getUnits,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_MODAL_DOCTOR_ORDER_UNITS, res.data.units)
		}
		axios(params, callback)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_DOCTOR_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.opd.modalDoctorOrder.pendingItemTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveDoctorOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				doctorOrders: this.props.opd.modalDoctorOrder.pendingItemTable.original.map((order) => ({
					code: Utils.trim(order.code),
					fsCode: order.fsCode,
					description: order.brand
						? Utils.trim(order.displayName) + ` (${order.brand})`
						: Utils.trim(order.displayName),
					startDateTime: order.startDateTime,
					endDateTime: order.endDateTime,
					pricePerUnit: order.sellPricePerUnit,
					notes: Utils.trim(order.notes),
					unit: order.unit ? order.unit.displayName : "",
					categoryName: order.categoryName,
					categoryNum: order.categoryNum,
					durationQty: order.durationQty,
					duration: order.duration,
					prn: order.isPRN,
					healthcarePlan: order.healthcarePlan,
				})),
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>
						<Badge>{translate(ResourceAssistance.Hospitel.doctor)}</Badge>
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<OutpatientModalDoctorDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button
						variant={ResourceAssistance.Button.variant.green}
						disabled={this.isDoneBtnDisabled()}
						onClick={this.onDone}
					>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientDoctorDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setObjArray,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctor))
