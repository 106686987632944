import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class InpatientModalDoctorOrderInfoCriteria extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions
						style={{ flex: "unset", display: "unset" }}
						size={"small"}
						labelStyle={{ fontWeight: "bold" }}
						contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
						column={3}
					>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.startDate)}>
							{Utils.formatDateTime(this.props.ipd.selectedOrder.order.startDateTime)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.endDate)}>
							{Utils.formatDateTime(this.props.ipd.selectedOrder.order.endDateTime)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.duration)}>
							{Utils.calculateDaysBetween(
								this.props.ipd.selectedOrder.order.startDateTime,
								this.props.ipd.selectedOrder.order.endDateTime,
								1
							)}{" "}
							{translate(ResourceAssistance.Message.days)}
						</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.ipd.selectedOrder.order.code}</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.ipd.selectedOrder.order.description}</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.ipd.selectedOrder.order.notes}</Descriptions.Item>
						<Descriptions.Item span={3} contentStyle={{ justifyContent: "flex-end" }}>
							{this.props.ipd.selectedOrder.order.lastModifiedBy.displayName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InpatientModalDoctorOrderInfoCriteria)
