const Warning = {
	Warning: {
		appointmentDelete: "appointmentDelete",
		createBillinReceiptAndInvoice: "warningCreateBillingReceiptAndInvoice",
		createBillingInvoice: "warningCreateBillingInvoice",
		createBillingReceipt: "warningCreateBillingReceipt",
		dischargePatient: "warningDischargePatient",
		forAnyComplications: "forAnyComplications",
		inCaseRefundPatient: "inCaseRefundPatient",
		opdCheckOut: "opdCheckOut",
		pharmacyCannotMeetDoctorOrder: "pharmacyCannotMeetDoctorOrder",
		theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling: "theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling",
		wrongWithdrawAmount: "wrongWithdrawAmount",
	},
}

export default Warning
