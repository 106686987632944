import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, FormControl, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigLaboratoryPCRCommentComments,
	setLoadingAction,
	setObjArray,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING,
	SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND,
	SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME,
	SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT,
	SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE,
	SET_ADM_LABORATORY_PCR_COMMENT_TYPES,
	SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigPCRComment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigPCRComment",
		}

		this.onType = this.onType.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadPCRs(
			ResourceAssistance.Url.admin.laboratory.getPCRComments,
			(res) => {
				this.props.setObjArray(SET_ADM_LABORATORY_PCR_COMMENT_TYPES, res.data.types)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setConfigLaboratoryPCRCommentComments([])
		this.props.setObjArray(SET_ADM_LABORATORY_PCR_COMMENT_TYPES, [])
		this.props.setSelected(SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT, -1, null)
		this.props.setValue(SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME, "")
		this.props.setValue(SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND, true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pcrComment.types, this.props.pcrComment.types)) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.laboratory.pcrComment.type)
			type.selectedIndex = 0
			this.props.setSelected(
				SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE,
				type.selectedIndex,
				this.props.pcrComment.types[type.selectedIndex]
			)
		}
		if (
			prevProps.pcrComment.selectedType.index !== this.props.pcrComment.selectedType.index ||
			prevProps.pcrComment.searchName !== this.props.pcrComment.searchName ||
			prevProps.pcrComment.displayInactive !== this.props.pcrComment.displayInactive
		) {
			if (this.props.pcrComment.selectedType.type) {
				this.props.setConfigLaboratoryPCRCommentComments(this.props.pcrComment.selectedType.type.labComments)
			}
		}
		if (!_.isEqual(prevProps.pcrComment.commentTable.filtered, this.props.pcrComment.commentTable.filtered)) {
			if (this.props.pcrComment.selectedComment.comment) {
				let idx = this.props.pcrComment.commentTable.filtered.findIndex(
					(each) => each.id === this.props.pcrComment.selectedComment.comment.id
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT,
						idx,
						this.props.pcrComment.commentTable.filtered[idx]
					)
				} else {
					this.props.setSelected(SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT, -1, null)
				}
			} else {
				this.props.setSelected(SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT, -1, null)
			}
		}
	}

	onType(event) {
		this.props.setSelected(
			SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE,
			event.target.value,
			this.props.pcrComment.types[event.target.value]
		)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(
			SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT,
			index,
			this.props.pcrComment.commentTable.filtered[index]
		)
	}

	onAdd() {
		this.props.setSelected(SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT, -1, null)
		this.props.onModalDisplayAction(SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={2}>
						<FormControl
							id={ResourceAssistance.ID.ADM.laboratory.pcrComment.type}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.pcrComment.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.pcrComment.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.pcrComment.selectedComment.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							data={this.props.pcrComment.commentTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.pcrComment.selectedComment.index === -1}
							highlightedRow={this.props.pcrComment.selectedComment.index}
						/>
						{this.props.pcrComment.commentTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	pcrComment: state.admin.configLaboratory.pcrComment,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigLaboratoryPCRCommentComments,
			setLoadingAction,
			setObjArray,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPCRComment)
