import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import { Utils } from "~/utils/Utils"
import rootReducer from "./reducer"

export const store = Utils.isDevMode()
	? createStore(
			rootReducer,
			{},
			compose(
				applyMiddleware(thunk),
				window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
			)
	  )
	: createStore(rootReducer, {}, compose(applyMiddleware(thunk)))
