import { LANGUAGE_CHANGE, LOGIN_ERROR, LOGIN_PASSWORD, LOGIN_USERNAME } from "~/redux/type"

export const changeLocale = (locale) => {
	return {
		type: LANGUAGE_CHANGE,
		payload: {
			locale: { locale },
		},
	}
}

export const updateLoginUsername = (username) => {
	return {
		type: LOGIN_USERNAME,
		payload: {
			username: { username },
		},
	}
}

export const updateLoginPassword = (password) => {
	return {
		type: LOGIN_PASSWORD,
		payload: {
			password: { password },
		},
	}
}

export const displayLoginError = (errorMsg) => {
	return {
		type: LOGIN_ERROR,
		payload: {
			msg: { errorMsg },
		},
	}
}
