import { Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigAntigenCommentModalNCriteria extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let comment = this.props.antigenComment.selectedComment.comment
		if (comment) {
			let idx = this.props.antigenComment.types.findIndex(
				(each) => each.id === this.props.antigenComment.selectedType.type.id
			)
			this.props.setSelected(
				SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE,
				idx,
				this.props.antigenComment.types[idx]
			)
			this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME, comment.name)
			this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION, comment.comments)
			this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE, comment.active)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME, "")
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION, "")
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE, true)
	}

	onType(value) {
		this.props.setSelected(
			SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.antigenComment.types[value] : null
		)
	}

	onName(event) {
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION, event.target.value)
	}

	onActive(event) {
		this.props.setValue(SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<Select
							showSearch
							size={ResourceAssistance.Select.size.large}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.method)}
							status={this.props.antigenComment.modalNewComment.selectedType.type ? undefined : "error"}
							value={
								this.props.antigenComment.modalNewComment.selectedType.type
									? this.props.antigenComment.modalNewComment.selectedType.index
									: undefined
							}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.antigenComment.types)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required={_.isEmpty(this.props.antigenComment.modalNewComment.name)}
							placeholder={translate(ResourceAssistance.Message.name)}
							onChange={this.onName}
							value={this.props.antigenComment.modalNewComment.name}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required={_.isEmpty(this.props.antigenComment.modalNewComment.description)}
							autoSize
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.description)}
							onChange={this.onDescription}
							value={this.props.antigenComment.modalNewComment.description}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col md="auto">
						<ToggleCheckBox onClick={this.onActive} checked={this.props.antigenComment.modalNewComment.isActive} />
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	antigenComment: state.admin.configLaboratory.antigenComment,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigAntigenCommentModalNCriteria))
