import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setIPDPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_IPD_PATIENTS_LOADING } from "~/redux/type"
import "./ipd.css"
import IPDLeft from "./left-pannel/IPDLeft"
import IPDRight from "./right-pannel/IPDRight"

class InPatientDepartment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InPatientDepartment",
		}

		this.loadPatients = this.loadPatients.bind(this)
	}

	loadPatients() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.ipd.startDateTime,
				endDateTime: this.props.ipd.endDateTime,
			},
		}

		let callback = (res) => {
			this.props.setIPDPatients(res.data.patients)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<Row noGutters>
					<Col md="auto">
						<IPDLeft loadPatients={this.loadPatients} />
					</Col>
					<Col>
						<IPDRight />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setIPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InPatientDepartment))
