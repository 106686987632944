import { LOCALES } from "../locale"

const en = {
	[LOCALES.ENGLISH]: {
		// Warning
		appointmentDelete: "Delete OPD Appointment.",
		approvedByAnotherOrderWarning: "Item has been already approved by another order.",
		emptyCancelReason: "Reason can not be empty for the cancelling item.",
		emptyDisapproveReasonWarning:
			"The disapproved reason is empty! The disapproved reason cannot be changed after disapproved.",
		emptyNoteWarning: "The note is empty! The note cannot be changed after approved.",
		forAnyComplications:
			"For any complications that might occurred or any changes in diagnosis or treatment, the actual cost will be reassessed.",
		inCaseRefundPatient:
			"In case that the patient is unable to perform the surgery, the hospital will refund the deposit back by transfer within 7 working days.",
		newItemWarning: "The Item has been approved already!",
		opdCheckOut: "Medical Consultation Complete.",
		smartCardReaderIsNotWorking:
			"ID Smart Card Reader is not working! \n Note* (Make sure the SMC Reader app is running.)",
		taxedTotalPriceDoesNotMatch: "Total price does not match total price of all items.",
		warning: "Warning!",
		wrongAmount: "The amount must be a multiple of quantity per order.",
		wrongTotalPrice: "Taxed total price is not calculated correctly based on Price/Unit.",
		wrongTransferAmount: "The transfer amount must be less than or equal to current amount.",
		wrongTransferAmountGreaterThanRequested: "Cannot transfer more than requested amount.",
		pharmacyCannotMeetDoctorOrder: "The order is out of stock.",
		theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling:
			"The Doctor Order is Prescription Only and Will Not Include in Billing.",
		warningCreateBillingInvoice: "This will create an Invoice.",
		warningCreateBillingReceipt: "This will create a Receipt.",
		warningCreateBillingReceiptAndInvoice: "This will create a Receipt and an Invoice.",
		warningDischargePatient: "Discharge Patient!",

		//Remark
		remarkReactionToLight: "***Brisk +, No Reaction -, Sluggish s, Eye Closed c",
		remarkPrimaryHealthCarePlan:
			"The Health Care Plan receiving payment directly from the patient by Cash, Credit Card, etc.",

		// Message
		"": "",
		ICD: "ICD",
		accidentHistory: "Accident History",
		active: "Active",
		adHocOrder: "Ad Hoc Order",
		add: "Add",
		address: "Address",
		addressEng: "Address (Eng)",
		adjustments: "Adjustments",
		admin: "Administration",
		admission: "Admission",
		admissionDate: "Admissiont Date",
		admissionPlan: "Admission Plan",
		age: "Age",
		aie: "AIE",
		alcohol: "Alcohol",
		allergies: "Allergies",
		amount: "Amount",
		amountOfPackage: "Amount of Package",
		amountPerOrder: "Amount/Order",
		analyte: "Analyte",
		antigen: "Antigen",
		antigenCOI: "Antigen (COI)",
		appointment: "Appointment",
		appointmentDate: "Appointment Date",
		appointmentTime: "Appointment Time",
		approve: "Approve",
		approved: "Approved",
		approvedBy: "Approved By",
		approvedInventories: "Approved Inventories",
		approver: "Approver",
		approverCheck: "A",
		arrival: "Arrival",
		assessment: "Assessment",
		attachment: "Attachment",
		balance: "Balance",
		bill: "Bill",
		billByCategory: "Bill (Category)",
		billByHealthCarePlan: "Bill (Health Care Plan)",
		billDetails: "Bill Details",
		billSummary: "Bill Summary",
		billTo: "Bill To",
		billingAddress: "Billing Address",
		billingContact: "Billing Contact",
		billingToTodayByAdmission: "Billing To Today By Admission",
		bloodTest: "Blood Test",
		bonus: "Bonus",
		bpmmHg: "BP. (mmHg)",
		branch: "Branch",
		brandName: "Brand",
		brandNameAndCode: "Brand/Code",
		budget: "Budget",
		by: "\u00A0By\u00A0",
		byAdmission: "By Admission",
		byUser: "By User",
		cancel: "Cancel",
		cancelNotReceived: "Cancel Not Received",
		cancelled: "Cancelled",
		cannotReadSMC: "Cannot read the ID!",
		carAccident: "Car Accident",
		cashier: "Cashier",
		category: "Category",
		categoryNum: "Category #",
		certified: "Certified",
		certifiedBy: "Certified By",
		certifiesThat: "Certifies that",
		change: "Change",
		changeEndDate: "Modify End Date",
		changePassword: "Change Password",
		checkIn: "Check In",
		checkInDate: "Check-In Date",
		checkInTime: "Check-In Time",
		checkOut: "Check Out",
		checkOutDate: "Check-Out Date",
		checkOutTime: "Check-Out Time",
		checkbox: "C\u00A0",
		checkout: "Check Out",
		chiefComplaint: "Chief Complaint (CC.)",
		chromatography: "Chromatography",
		city: "City",
		cityEng: "City (Eng)",
		claimFile: "Claim File",
		clear: "Clear",
		clinicalRecord: "Clinical Record",
		close: "Close",
		code: "Code",
		companyOrResponsiblePersonContact: "Company/Responsible Person Contact",
		complete: "Complete",
		confirm: "Confirm",
		confirmPassword: "Confirm Password",
		contact: "Contact",
		contactInfo: "Contact Info",
		continue: "Continue",
		coordinator: "Coordinator",
		costPerOrder: "Cost/Order",
		covid19Lab: "Covid-19 Lab",
		covid19Negative: "Covid-19 Negative",
		covid19Positive: "Covid-19 Positive",
		createNewPR: "Add Items for PR",
		currencySign: "\u0024",
		current: "Current",
		currentBalance: "Current Balance",
		currentLevel: "Current Level",
		date: "Date",
		dateOfBirth: "DOB",
		dateOfPo: "Date of PO",
		day: "Day",
		days: "Days",
		daysOfRest: "Days of rest.",
		debt: "Debt",
		default: "Default",
		delete: "Delete",
		department: "Department",
		deposit: "Deposit",
		depressionAssessment: "Depression Assessment",
		description: "Description",
		deselectAll: "Deselect All",
		diagnosis: "Diagnosis",
		discharge: "Discharge",
		dischargeDate: "Discharge Date",
		discharged: "Discharged",
		discount: "Discount",
		discountPricePerOrder: "D-Price/Order",
		discountPricePerUnit: "D-Price/Unit",
		discountTaxPricePerUnit: "DT-Price/Unit",
		discountWithSymbol: "Discount %",
		dispensingTime: "Dispensing Time",
		displayAll: "Display All",
		displayExpire: "Display Expire",
		displayInactive: "Display Inactive",
		displayMinStock: "Display Min Stock",
		doctor: "Doctor",
		doctorConsultation: "Doctor Consultation",
		doctorName: "Doctor Name",
		doctorNote: "Doctor Note",
		doctorOpinion: "Doctor's opinion",
		doctorOptionAfter: "After receiving complete treatment for 14 days, you can go to work and live normally.",
		doctorStandingOrder: "Doctor Order",
		documentNum: "Document Number",
		done: "Done",
		dose: "Dose",
		doubleClickToDelete: "Double click to delete the item.",
		download: "Download",
		drinking: "Drinking",
		driver: "Driver",
		dueDate: "Due Date",
		dupplicateCodeWarning: "Code exist already!",
		dupplicateNameWarning: "Name exist already!",
		dupplicateWarning: "Exist Already!",
		duration: "Duration",
		eGene: "E gene:CT",
		edit: "Edit",
		email: "Email",
		empty: "",
		emptyWarning: "Cannot be empty!",
		endDate: "End Date",
		endDateTime: "End Date Time",
		estimatedDateOfConfinement: "Estimate Date of Confinement (EDC)",
		evaluation: "Evaluation",
		every: "Every",
		examinationDate: "Examination Date",
		expected: "Expected",
		expenseEstimation: "Expense Estimation",
		expire: "Expire",
		expired: "Expired",
		expiredDate: "Expired Date",
		fallRisk: "Fall Risk",
		familyHealthHistory: "Family Health History",
		fax: "Fax",
		faxNo: "Fax No.",
		filter: "Filter",
		financialPlan: "Financial Plan",
		firstAssessment: "First Assessment",
		firstName: "First Name",
		firstNameEng: "First Name (Eng)",
		fluorescence: "Fluorescence",
		focus: "Focus",
		form: "Form",
		from: "From",
		fsCode: "F/S Code",
		gender: "Gender",
		general: "General",
		generalAppearance: "General Appearance (GA)",
		generalInfo: "General Information",
		generic: "Generic",
		genericName: "Generic Name",
		genericNameOrTradeName: "Generic Name/Trade Name",
		googleDrive: "G-Drive",
		grandTotal: "Grand Total",
		graph: "Graph",
		hasExaminedOn: "Has examined on",
		hashSymbol: "\u00A0#",
		health: "Health",
		healthCare: "Health Care",
		healthCarePlan: "Health Care Plan",
		healthHistory: "Health History",
		height: "Height",
		hn: "HN",
		home: "Home",
		hospital: "Hospital",
		hospitalBilling: "Hospital Billing",
		hospitel: "Hospitel",
		hours: "Hours",
		id: "ID",
		idCard: "ID Card",
		identification: "ID",
		inPatient: "Inpatient",
		inProgress: "In Progress",
		inpatientPlan: "Inpatient Plan",
		inspector: "Inspector",
		inspectorCheck: "I",
		instruction: "Instruction",
		internationalClassificationDiseases: "International Classification of Diseases (ICD)",
		intervention: "Intervention",
		inventory: "Inventory",
		inventoryByLocation: "Inventory By Location",
		investigation: "Investigation",
		invoiceId: "Invoice ID",
		invoiceNum: "Invoice #",
		ipd: "IPD",
		isolationPrecaution: "Isolation Precaution",
		issued: "Issued",
		issuedBy: "Issued By",
		item: "Item",
		itemSupplier: "Item Supplier",
		itemType: "Item Type",
		keyword: "Keyword",
		lab: "Laboratory",
		labNumber: "Lab No.",
		label: "Label",
		language: "Language",
		lastMenstrualPeriod: "Last Menstrual Period (LMP)",
		lastName: "Last Name",
		lastNameEng: "Last Name (Eng)",
		lastPurchasedFrom: "Last Purchased From:\u00A0",
		lastReceivedDate: "Last Received Date",
		letterOfConsent: "Letter of Consent",
		level: "Level",
		licenseNo: "License No.",
		licenseNum: "License Number",
		loadPreviousMedicalRecords: "Load Previous Medical Records",
		loading: "Loading...",
		location: "Location",
		login: "Login",
		loginError: "Username or password is wrong!",
		loginName: "Login Name",
		logout: "Logout",
		lot: "Lot",
		lotNum: "Lot #",
		management: "Management",
		max: "Max",
		maxLevel: "Max Level",
		md: "MD.",
		medicalCertificate: "Medical Certificate",
		medicalCertificateEng: "Medical Certificate (Eng)",
		medicalCertificateIPD: "Medical Certificate (IPD)",
		medicalCertificateOPD: "Medical Certificate (OPD)",
		medicalRecord: "Medical Record",
		medicalSupply: "Medical Supply",
		medicationSheet: "Medication Sheet",
		medicationUsage: "Medication Usage",
		medicine: "Medicine",
		medicineHistory: "Medicine History",
		memoId: "Memo ID",
		menopause: "Menopause",
		menstruation: "Menstruation",
		min: "Min",
		minLevel: "Min Level",
		minQtyPerOrder: "Qty/Order",
		minStock: "Min Stock",
		minutes: "Minutes",
		modifyDateRange: "Modify Date Range",
		month: "Month",
		nGene: "N gene:CT",
		name: "Name",
		nameEng: "Name (Eng)",
		nameSurname: "Name-Surname",
		nameTitle: "Ms./Mrs./Mr.",
		negative: "Negative",
		new: "New",
		newForm: "New Form",
		newItem: "New Item",
		newItemSupplier: "New Item Supplier",
		newLab: "New Lab",
		newReceive: "New Receive",
		newRecord: "New Record",
		newRequest: "New Request",
		newRole: "New Role",
		newSupplier: "New Supplier",
		newTax: "New Tax",
		newTransfer: "New Transfer",
		newUnit: "New Unit",
		newUser: "New User",
		nickName: "Nickname",
		no: "No",
		note: "Note",
		novelDiseaseInvestigationSheet: "Novel 3 Disease Investigation Sheet",
		number: "Number",
		nurse: "Nurse",
		nurseOrder: "Nurse Order",
		nurseProgressNote: "Nurse Progress Note",
		o2Sat: "O\u2082 Sat (%)",
		oGene: "ORF1ab gene:CT",
		objective: "Objective",
		oneMonth: "One Month",
		oneWeek: "One Week",
		opd: "OPD",
		opdClinicalRecord: "OPD Clinical Record",
		operationalService: "Operational Service",
		or: "Or",
		order: "Order",
		org: "Organization",
		other: "Other",
		package: "Package",
		paid: "Paid",
		parentLoc: "Parent Location",
		passenger: "Passenger",
		passiveSmokeExposure: "Passive Smoke Exposure",
		password: "Password",
		passwordDoesNotMatch: "Password does not match!",
		pastIllnesses: "Past Illnesses",
		pastSurgicalHistory: "Past Surgical History",
		patient: "Patient",
		patientId: "PID",
		patientInfo: "Patient Info",
		patientName: "Patient Name",
		patientSaving: "Patient Saving",
		pay: "Pay",
		payee: "Payee",
		payment: "Payment",
		paymentTerms: "Payment Terms",
		paymentType: "Payment Type",
		pcr: "PCR",
		pedestrian: "Pedestrian",
		pending: "Pending",
		pendingItems: "Pending Items",
		pendingOrder: "Pending Order",
		pendingTransfers: "Pending Transfers",
		permission: "Permission",
		phone: "Phone",
		physicalExamination: "Physical Examination",
		placeOfIncident: "Place of Incident",
		plan: "Plan",
		poDate: "PO Date",
		poId: "PO ID",
		poNumber: "PO #",
		policeName: "Police Name",
		policeStation: "Police Station",
		positive: "Positive",
		postcode: "Postcode",
		prNumber: "PR #",
		prPerMin: "PR./min",
		pregnant: "Pregnant",
		presentIllness: "Present Illness (PI.)",
		pricePerOrder: "Price/Order",
		pricePerOrderBeforeTax: "Price/Order",
		pricePerUnit: "Price/Unit",
		pricePerUnitBeforeTax: "Price/Unit",
		print: "Print",
		printDate: "Print Date",
		printLabel: "Print Label",
		prnRecord: "PRN Record",
		professionalServiceExpenseByUser: "Professional Service Expense By User",
		province: "Province",
		provinceEng: "Province (Eng)",
		purchase: "Purchase",
		purchaseFrom: "Purchase From",
		purchaseOrder: "Purchase Order",
		purchaseOrderById: "Purchase Order By ID",
		purchaseOrderByLocation: "Purchase Order By Location",
		purchaseRequest: "Purchase Request",
		qty: "Qty",
		qtyPerDay: "Qty/Day",
		questionnaire: "Questionnaire",
		radiology: "Radiology",
		rc: "RC",
		reason: "Reason",
		receipt: "Receipt",
		receiptCategory: "Receipt (Category)",
		receiptId: "Receipt ID",
		receive: "Receive",
		receiveItemDetails: "Receive Item Details",
		received: "Received",
		receivedBy: "Received By",
		references: "References",
		refresh: "Refresh",
		registerOPD: "Register OPD",
		registerPatient: "Register Patient",
		registration: "Registration",
		registrationDate: "Registration Date",
		reject: "Reject",
		remark: "Remark",
		report: "Report",
		reportById: "Report By ID",
		reportByLocation: "Report By Location",
		request: "Request",
		requestCovid19MedicalCertificate: "Request Covid-19 Medical Certificate",
		requestFrom: "Request From",
		requestId: "Request ID",
		requestedInventories: "Requested Inventories",
		requester: "Requester",
		requesterCheck: "R",
		requiredByDate: "Required By Date",
		result: "Result",
		riskOfAbuse: "Risk of Abuse",
		role: "Role",
		room: "Room",
		rrPerMin: "RR./min",
		run: "Run",
		sGene: "S gene:CT",
		satCode: "SAT Code",
		save: "Save",
		saveAll: "Save All",
		scheduled: "Scheduled",
		search: "Search",
		searchPatient: "Search Patient",
		selectAll: "Select All",
		sell: "Sell",
		sellingPricePerUnit: "Selling Price/Unit",
		seq: "SEQ",
		sequence: "Sequence",
		service: "Service",
		setAmount: "Set amount...",
		shipAddress: "Shipping Address",
		shippingContact: "Shipping Contact",
		signature: "Signature",
		skipPayment: "Skip Payment",
		smoking: "Smoking",
		soap: "SOAP",
		startDate: "Start Date",
		startDateTime: "Start Date Time",
		status: "Status",
		stock: "Stock",
		stockAndPurchasing: "Stock & Purchasing",
		stockManager: "Stock Manager",
		stools: "Stools",
		stop: "Stop",
		stretcher: "Stretcher",
		subjective: "Subjective",
		subunit: "Subunit",
		supplementOrHerbsHistory: "Supplement/Herbs History",
		supplier: "Supplier",
		supplierCode: "Vendor ID",
		supplierDiscount: "V-Discount",
		supplierName: "Supplier Name",
		symptoms: "Symptoms",
		table: "Table",
		taxId: "Tax ID",
		taxRate: "Tax",
		taxRateWithSymbol: "Tax %",
		tel: "Tel",
		telNo: "Tel No.",
		temperature: "T. (C)",
		terms: "Terms",
		thai: "TH",
		theAboveNamedPersonRequires: "The above named person requires",
		thisMonth: "This Month",
		time: "Time",
		timeOfIncident: "Time of Incident",
		title: "Title",
		to: "To",
		tobacco: "Tobacco",
		today: "Today",
		tomorrow: "Tomorrow",
		totalBudget: "Total Budget",
		totalDeposit: "Total Deposit",
		totalEstimationCost: "Total Estimation Cost",
		totalPaid: "Total Paid",
		totalPrice: "Total Price",
		totalPriceDiscounted: "D-Total",
		totalPriceDiscountedTaxed: "DT-Total",
		totalPriceTaxed: "T-Total",
		totalUnpaid: "Total Unpaid",
		totalWithdraw: "Total Withdraw",
		tradeName: "Trade Name",
		tradeNameOrBrand: "Trade Name/Brand",
		transaction: "Transaction",
		transfer: "Transfer",
		transferByLocation: "Transfer By Location",
		transferFrom: "Transfer From",
		transferId: "Transfer ID",
		transferingItems: "Transfering Items",
		transferred: "Transferred",
		translator: "Translator",
		treatment: "Treatment",
		triage: "Triage (ESI Index)",
		twoWeeks: "Two Weeks",
		type: "Type",
		typeOfIncident: "Type of Incident",
		typesOfDiagnosis: "Types of Diagnosis",
		unapproveReason: "Unapproved Reason:\u00A0",
		unapproved: "Disapprove",
		underlyingDisease: "Underlying Disease",
		unit: "Unit",
		unitPrice: "Unit Price",
		unpaid: "Unpaid",
		uom: "UOM",
		upload: "Upload",
		uploaded: "Uploaded",
		urine: "Urine",
		user: "User",
		username: "Username",
		usingContraceptive: "Using Contraceptive",
		vaccine: "Vaccine",
		value: "Value",
		vat: "VAT",
		victim: "Victim",
		view: "View",
		vitalSign: "Vital Sign",
		walk: "Walk",
		weight: "Weight",
		wheelchair: "Wheelchair",
		withdraw: "Withdraw",
		womenOnly: "Women Only",
		wrongWithdrawAmount: "Wrong Withdraw Amount!",
		year: "Year",
		yes: "Yes",
		intakeOutput: "Intake/Output",
		intake: "Intake",
		output: "Output",
		method: "Method",
		volume: "Volume",
		amountML: "Amount (mL)",
		totalIntake: "Total Intake",
		totalOutput: "Total Output",
		ioBalance: "I/O Balance",
		pulse: "PR",
		glasgowComaScale: "Glasgow Coma Scale (GCS)",
		eyeOpeningResponse: "Eye Opening Response",
		verbalResponse: "Verbal Response",
		motorResponse: "Motor Response",
		noEyeOpening: "None",
		toPressure: "To Pressure",
		toSound: "To Sound",
		spontaneous: "Spontaneous",
		noVerbal: "None",
		sounds: "Sounds",
		words: "Words",
		confused: "Confused",
		orientated: "Orientated",
		noMotor: "None",
		extension: "Extension",
		abnormalFlexion: "Abnormal Flexion",
		normalFlexion: "Normal Flexion",
		localising: "Localising",
		obeysCommands: "Obeys Commands",
		score: "Score",
		painScale: "Pain Scale",
		pupilSize: "Pupil Size",
		left: "Left (L)",
		right: "Right (R)",
		notification: "Notification",
		message: "Message",
		leftEye: "Left Eye (L)",
		rightEye: "Right Eye (R)",
		size: "Size",
		leftEyePupilSizeMM: "Pupil Size (mm) (L)",
		rightEyePupilSizeMM: "Pupil Size (mm) (R)",
		reactionToLight: "Reaction to Light",
		leftEyeReactionToLight: "Reaction to Light (L)",
		rightEyeReactionToLight: "Reaction to Light (R)",
		timeline: "Timeline",
		pharmacy: "Pharmacy",
		lastLocation: "Last Location",
		heent: "HEENT",
		heart: "Heart",
		lung: "Lung",
		abdomen: "Abdomen",
		back: "Back",
		extremities: "Extremities",
		neuro: "Neuro",
		pharmacistVerified: "Pharmacist Verified",
		verified: "Verified",
		modified: "Modified",
		dispensed: "Dispensed",
		return: "Return",
		pharmacyInventory: "Pharmacy Inventory",
		male: "Male",
		female: "Female",
		pcrComment: "PCR Comment",
		sarsCov2RnaPcr: "SARS-CoV-2-RNA-PCR",
		xpertXpressSarsCov2: "Xpert-Xpress-SARS-CoV-2",
		n2geneCT: "N2 gene:CT",
		antigenComment: "Antigen Comment",
		dispense: "Dispense",
		verify: "Verify",
		pharmacist: "Pharmacist",
		pharmacyDispensed: "Pharmacy Dispensed",
		dispensedBy: "Dispensed By",
		returned: "Returned",
		returning: "Returning",
		doctorPrescriptionOnly: "Doctor's Prescription Only",
		cancelDoctorPrescriptionOnly: "Cancel Doctor's Prescription Only",
		primaryPlan: "Primary Plan",
		refund: "Refund",
		doctorPrefix: "Dr.",
		doctorPrescription: "Doctor Prescription",
		relativeInfo: "Relative Info",
		relationship: "Relationship",
		beforeMeal: "Before Meal",
		beforeBed: "Before Bed",
		afterMeal: "After Meal",
		nurseName: "Nurse Name",
	},
}

export default en
