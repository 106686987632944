import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmUserSelectedUser, setAdmUserUsers, setLoadingAction } from "~/redux/action"
import { SET_ADM_USER_CHANGE_PASSWORD_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import AdmUserModalCPWDetails from "./AdmUserModalCPWDetails"

class AdmUserModalCPW extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmUserModalCPW",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_USER_CHANGE_PASSWORD_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.user.changePassword}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.user.selectedUser.user.id,
				password: this.props.user.modalChangePassword.password.trim(),
				userId: this.props.login.user.id,
			},
		}

		let callback = (res) => {
			this.props.setAdmUserSelectedUser(-1, null)
			let users = this.props.user.userTable.original.filter((each) => each.id !== res.data.users[0].id)
			users.push(res.data.users[0])
			this.props.setAdmUserUsers(users)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_USER_CHANGE_PASSWORD_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.changePassword)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmUserModalCPWDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmUserChangePasswordDisplay,
	login: state.login,
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmUserUsers, setAdmUserSelectedUser }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUserModalCPW)
