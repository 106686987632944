import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_HOSPITELS,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
	SET_HOSPITEL_NURSE_NOTES,
	SET_HOSPITEL_ORDERS,
	SET_HOSPITEL_PATIENTS,
	SET_HOSPITEL_VITAL_SIGNS,
	SET_HOSPITEL_SOAPS,
	SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS,
	SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS,
	SET_HOSPITEL_NURSE_ORDERS,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
	SET_IPD_MODAL_PRN_RECORD_RECORDS,
	SET_IPD_CERTIFICATES,
	SET_IPD_INTAKE_OUTPUTS,
	SET_IPD_GCS,
	SET_IPD_PAIN_SCALE,
	SET_IPD_PUPIL_SIZE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setHospitelPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients
			.filter(
				(patient) =>
					String(patient.id).includes(getState().hospitel.hospitel.searchPatientId.trim().toLowerCase()) &&
					String(patient.identificationNumber).includes(getState().hospitel.hospitel.searchId.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.hospitels[0].creationDateTime, a.hospitels[0].creationDateTime))

		if (getState().hospitel.hospitel.inpatientChecked) {
			filtered = filtered.filter((patient) => {
				return !patient.hospitels[0].dischargeSummary
			})
		}

		if (getState().hospitel.hospitel.soapChecked) {
			filtered = filtered.filter((patient) => {
				return (
					!patient.hospitels[0].dischargeSummary &&
					!patient.hospitels[0].admission.clinicalRecord.orders.some(
						(order) =>
							(!_.isEmpty(order.subjective) ||
								!_.isEmpty(order.objective) ||
								!_.isEmpty(order.assessment) ||
								!_.isEmpty(order.plan)) &&
							order.startDateTime >= Utils.generateDate().getTime()
					)
				)
			})
		}

		if (getState().hospitel.hospitel.nurseChecked) {
			filtered = filtered.filter((patient) => {
				return (
					!patient.hospitels[0].dischargeSummary &&
					!patient.hospitels[0].admission.clinicalRecord.nurseNotes.some(
						(nurseNote) => nurseNote.dateTime >= Utils.generateDate().getTime()
					)
				)
			})
		}

		if (getState().hospitel.hospitel.vitalSignChecked) {
			filtered = filtered.filter((patient) => {
				return (
					!patient.hospitels[0].dischargeSummary &&
					!patient.hospitels[0].admission.clinicalRecord.vitalSigns.some(
						(vitalSign) => vitalSign.dateTime >= Utils.generateDate().getTime()
					)
				)
			})
		}

		let rowColor = []
		rowColor = filtered.map((patient) => {
			let color = ""
			if (
				patient.hospitels.some((hospitel) => !hospitel.dischargeSummary) &&
				(!patient.hospitels[0].admission.clinicalRecord.vitalSigns.some(
					(vitalSign) => vitalSign.dateTime >= Utils.generateDate().getTime()
				) ||
					!patient.hospitels[0].admission.clinicalRecord.nurseNotes.some(
						(nurseNote) => nurseNote.dateTime >= Utils.generateDate().getTime()
					) ||
					!patient.hospitels[0].admission.clinicalRecord.orders.some(
						(order) =>
							(!_.isEmpty(order.subjective) ||
								!_.isEmpty(order.objective) ||
								!_.isEmpty(order.assessment) ||
								!_.isEmpty(order.plan)) &&
							order.startDateTime >= Utils.generateDate().getTime()
					))
			) {
				color = ResourceAssistance.CSS.Color.red
			}

			return [true, color]
		})

		let body = filtered.map((patient, index) => {
			return [
				index + 1,
				patient.id,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
				patient.identificationNumber,
			]
		})

		dispatch({
			type: SET_HOSPITEL_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelHospitels = (hospitels) => {
	return (dispatch, getState) => {
		let filtered = hospitels.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = []
		rowColor = filtered.map((hospitel) => {
			let discharged = ResourceAssistance.CSS.Color.green
			let color = ""
			if (hospitel.dischargeSummary) {
				color = discharged
			}
			return [true, color]
		})

		let body = filtered.map((hospitel) => {
			return [
				Utils.formatDate(hospitel.creationDateTime),
				Utils.formatTime(hospitel.creationDateTime),
				hospitel.department.displayName,
				hospitel.room,
			]
		})

		dispatch({
			type: SET_HOSPITEL_HOSPITELS,
			payload: {
				original: hospitels,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalDoctorOrderItems = (items) => {
	return (dispatch, getState) => {
		let itemRelps = items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])

		let filtered = itemRelps
			.filter(
				(itemRelp) =>
					getState().hospitel.hospitel.modalDoctorOrder.selectedType.type &&
					getState().hospitel.hospitel.modalDoctorOrder.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospitel.hospitel.modalDoctorOrder.searchDescription.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospitel.hospitel.modalDoctorOrder.searchDescription).toLowerCase()) ||
						Utils.trim(itemRelp.brand)
							.toLowerCase()
							.includes(Utils.trim(getState().hospitel.hospitel.modalDoctorOrder.searchDescription).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalDoctorOrderPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items

		let body = filtered.map((item) => {
			return [
				item.displayName,
				item.brand,
				Utils.trim(item.notes),
				Utils.formatDateTime(item.startDateTime),
				Utils.formatDateTime(item.endDateTime),
			]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelSOAPs = (soaps) => {
	return (dispatch, getState) => {
		let filtered = soaps
			.filter(
				(soap) =>
					soap.endDateTime >= getState().hospitel.hospitel.orderEndDateTime &&
					soap.startDateTime <= getState().hospitel.hospitel.orderEndDateTime &&
					(!_.isEmpty(soap.subjective) ||
						!_.isEmpty(soap.objective) ||
						!_.isEmpty(soap.assessment) ||
						!_.isEmpty(soap.plan))
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((soap) => {
			return [
				Utils.formatTime(soap.startDateTime),
				soap.subjective,
				soap.objective,
				soap.assessment,
				soap.plan,
				soap.lastModifiedBy.displayName,
				getState().hospitel.hospitel.selectedHospitel.hospitel &&
					!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					getState().hospitel.hospitel.orderEndDateTime >= Utils.generateDate().getTime() &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_HOSPITEL_SOAPS,
			payload: {
				original: soaps,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter(
				(order) =>
					order.endDateTime >=
						Utils.generateDateFromLong(
							getState().hospitel.hospitel.orderEndDateTime,
							0,
							0,
							0,
							-23,
							-59,
							-59
						).getTime() &&
					order.startDateTime <= getState().hospitel.hospitel.orderEndDateTime &&
					_.isEmpty(order.subjective) &&
					_.isEmpty(order.objective) &&
					_.isEmpty(order.assessment) &&
					_.isEmpty(order.plan)
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = []
		rowColor = filtered.map((order) => {
			let cancelled = ResourceAssistance.CSS.Color.red
			let color = ""
			if (order.cancelledDateTime && getState().hospitel.hospitel.orderEndDateTime >= order.cancelledDateTime) {
				color = cancelled
			}
			return [true, color]
		})

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.description,
				Utils.trim(order.notes),
				_.isEmpty(order.code) ? Utils.formatDate(order.startDateTime) : Utils.formatDateTime(order.startDateTime),
				_.isEmpty(order.code) ? Utils.formatDate(order.endDateTime) : Utils.formatDateTime(order.endDateTime),
				getState().hospitel.hospitel.orderEndDateTime >= order.cancelledDateTime && order.cancelledDateTime
					? Utils.formatDateTime(order.cancelledDateTime)
					: "",
				order.lastModifiedBy.displayName,
			]
		})

		dispatch({
			type: SET_HOSPITEL_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelNurseOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter(
				(order) =>
					(!order.endDateTime || order.endDateTime >= getState().hospitel.hospitel.orderEndDateTime) &&
					order.startDateTime <= getState().hospitel.hospitel.orderEndDateTime
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = []

		let body = filtered.map((order) => {
			return [
				Utils.trim(order.description),
				order.notes,
				Utils.formatNumWithComma(order.qtyPerDay),
				Utils.formatDate(order.startDateTime),
				Utils.formatDate(order.endDateTime),
				order.lastModifiedBy.displayName,
				getState().hospitel.hospitel.selectedHospitel.hospitel &&
					!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_HOSPITEL_NURSE_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelVitalSigns = (vitalSigns) => {
	return (dispatch, getState) => {
		let filtered = vitalSigns
			.filter((vitalSign) => {
				return (
					vitalSign.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					vitalSign.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((vitalSign) => {
			return [
				Utils.formatTime(vitalSign.dateTime),
				vitalSign.temperature,
				vitalSign.pr,
				vitalSign.rr,
				vitalSign.bp,
				vitalSign.oxygenSat,
				vitalSign.lastModifiedBy.displayName,
				getState().hospitel.hospitel.selectedHospitel.hospitel &&
					!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_HOSPITEL_VITAL_SIGNS,
			payload: {
				original: vitalSigns,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDIntakeOutputs = (intakeOutputs) => {
	return (dispatch, getState) => {
		let filtered = intakeOutputs
			.filter((io) => {
				return (
					io.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					io.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((IO) => {
			return [
				Utils.formatTime(IO.dateTime),
				IO.intake,
				IO.intakeAmount,
				IO.intakeNote,
				IO.output,
				IO.outputAmount,
				IO.outputNote,
				IO.lastModifiedBy.displayName,
				!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_IPD_INTAKE_OUTPUTS,
			payload: {
				original: intakeOutputs,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDGCSs = (gcScales) => {
	return (dispatch, getState) => {
		let filtered = gcScales
			.filter((gcs) => {
				return (
					gcs.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					gcs.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((gcs) => {
			return [
				Utils.formatTime(gcs.dateTime),
				gcs.eyeOpening,
				gcs.verbal,
				gcs.motor,
				Utils.BigNumber(gcs.eyeOpening).plus(gcs.verbal).plus(gcs.motor).toNumber(),
				gcs.lastModifiedBy.displayName,
				!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		let rowColor = []
		rowColor = filtered.map((gcs) => {
			let color = ""
			let score = Utils.BigNumber(gcs.eyeOpening).plus(gcs.verbal).plus(gcs.motor)
			if (score.isGreaterThanOrEqualTo(13) && score.isLessThanOrEqualTo(15)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (score.isGreaterThanOrEqualTo(9) && score.isLessThanOrEqualTo(12)) {
				color = ResourceAssistance.CSS.Color.warning
			} else {
				color = ResourceAssistance.CSS.Color.red
			}
			return [true, color]
		})

		dispatch({
			type: SET_IPD_GCS,
			payload: {
				original: gcScales,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDPainScales = (painScales) => {
	return (dispatch, getState) => {
		let filtered = painScales
			.filter((painScale) => {
				return (
					painScale.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					painScale.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((painScale) => {
			return [
				Utils.formatTime(painScale.dateTime),
				painScale.score,
				painScale.lastModifiedBy.displayName,
				!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		let rowColor = []
		rowColor = filtered.map((painScale) => {
			let color = ""
			let score = Utils.BigNumber(painScale.score)
			if (score.isGreaterThan(7)) {
				color = ResourceAssistance.CSS.Color.red
			} else if (score.isGreaterThan(3)) {
				color = ResourceAssistance.CSS.Color.warning
			} else {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		dispatch({
			type: SET_IPD_PAIN_SCALE,
			payload: {
				original: painScales,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDPupilSizes = (pupilSizes) => {
	return (dispatch, getState) => {
		let filtered = pupilSizes
			.filter((pupilSizes) => {
				return (
					pupilSizes.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					pupilSizes.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((pupilSize) => {
			return [
				Utils.formatTime(pupilSize.dateTime),
				pupilSize.left,
				pupilSize.leftReactionToLight,
				pupilSize.right,
				pupilSize.rightReactionToLight,
				pupilSize.lastModifiedBy.displayName,
				!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_IPD_PUPIL_SIZE,
			payload: {
				original: pupilSizes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelNurseNotes = (nurseNotes) => {
	return (dispatch, getState) => {
		let filtered = nurseNotes
			.filter((nurseNote) => {
				return (
					nurseNote.dateTime >=
						Utils.generateDateFromLong(getState().hospitel.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					nurseNote.dateTime <= getState().hospitel.hospitel.orderEndDateTime
				)
			})
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((nurseNote) => {
			return [
				Utils.formatTime(nurseNote.dateTime),
				nurseNote.focus,
				_.isEmpty(nurseNote.assessmentNote) ? nurseNote.assessment : nurseNote.assessmentNote,
				nurseNote.intervention,
				nurseNote.evaluation,
				nurseNote.lastModifiedBy.displayName,
				getState().hospitel.hospitel.selectedHospitel.hospitel &&
					!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_HOSPITEL_NURSE_NOTES,
			payload: {
				original: nurseNotes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalNurseOrdeItems = (items) => {
	return (dispatch, getState) => {
		let itemRelps = items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])

		let filtered = itemRelps
			.filter(
				(itemRelp) =>
					getState().hospitel.hospitel.modalNurseOrder.selectedType.type &&
					getState().hospitel.hospitel.modalNurseOrder.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospitel.hospitel.modalNurseOrder.searchName.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospitel.hospitel.modalNurseOrder.searchName).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalNurseOrderPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items

		let body = filtered.map((each) => {
			return [
				each.displayName,
				each.notes,
				each.unit.displayName,
				Utils.formatNumWithComma(each.qtyPerDay),
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalNurseServiceServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((service) =>
				service.name
					.trim()
					.toLowerCase()
					.includes(getState().hospitel.hospitel.modalNurseService.searchName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []
		rowColor = filtered.map((service) => {
			let color = ""

			if (service.role && getState().login.user.userType && service.role.id !== getState().login.user.userType.id) {
				color = ResourceAssistance.CSS.Color.red
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelModalNurseServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services

		let body = filtered.map((each) => {
			return [
				each.name,
				each.description,
				each.notes,
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelmodalDoctorServiceServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((service) =>
				service.name
					.trim()
					.toLowerCase()
					.includes(getState().hospitel.hospitel.modalDoctorService.searchName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []
		rowColor = filtered.map((service) => {
			let color = ""

			if (service.role && service.role.id !== getState().login.user.userType.id) {
				color = ResourceAssistance.CSS.Color.red
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setHospitelmodalDoctorServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services

		let body = filtered.map((each) => {
			return [
				each.name,
				each.description,
				each.notes,
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDModalPRNRecordRecords = (records) => {
	return (dispatch, getState) => {
		let filtered = records.sort((a, b) => Utils.sort(a.dispensingDateTime, b.dispensingDateTime))

		let body = filtered.map((each) => {
			return [
				Utils.formatDateTime(each.dispensingDateTime),
				Utils.formatNumWithComma(each.dispensingQty),
				each.createdBy.displayName,
			]
		})

		dispatch({
			type: SET_IPD_MODAL_PRN_RECORD_RECORDS,
			payload: {
				original: records,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDMedicalCertificates = (certificates) => {
	return (dispatch, getState) => {
		let filtered = certificates.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = []

		rowColor = filtered.map((certificate) => {
			let color = ""
			if (certificate.admission && !_.isEmpty(certificate.doctorName)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (certificate.admission) {
				color = ResourceAssistance.CSS.Color.warning
			}

			return [true, color]
		})

		let body = filtered.map((certificate) => {
			return [
				Utils.formatDate(certificate.creationDateTime),
				Utils.formatTime(certificate.creationDateTime),
				certificate.symptoms,
				certificate.diagnosis,
				certificate.doctorOpinion,
				certificate.doctorName,
				certificate.doctorLicense,
				certificate.lastModifiedBy.displayName,
				getState().hospitel.hospitel.selectedHospitel.hospitel &&
					!getState().hospitel.hospitel.selectedHospitel.hospitel.dischargeSummary,
			]
		})
		dispatch({
			type: SET_IPD_CERTIFICATES,
			payload: {
				original: certificates,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
