import { Radio, Space } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"

class GCSVerbal extends React.Component {
	ERadio = {
		NONE: {
			description: translate(ResourceAssistance.Message.noVerbal),
			value: 1,
		},
		SOUNDS: {
			description: translate(ResourceAssistance.Message.sounds),
			value: 2,
		},
		WORDS: {
			description: translate(ResourceAssistance.Message.words),
			value: 3,
		},
		CONFUSED: {
			description: translate(ResourceAssistance.Message.confused),
			value: 4,
		},
		ORIENTATED: {
			description: translate(ResourceAssistance.Message.orientated),
			value: 5,
		},
	}

	constructor(props) {
		super(props)

		this.state = {
			verbal: this.ERadio.NONE.value,
		}

		this.onVerbal = this.onVerbal.bind(this)
	}

	componentDidMount() {
		if (this.props.gcs) {
			this.setState({
				verbal: this.props.gcs.verbal,
			})
		}
	}

	onVerbal(e) {
		this.setState({
			verbal: e.target.value,
		})
		this.props.onVerbal(e)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.verbalResponse)}</legend>
						<Row>
							<Col md="auto">
								<Radio.Group value={this.state.verbal} onChange={this.onVerbal}>
									<Space direction="vertical">
										<Radio value={this.ERadio.NONE.value}>{this.ERadio.NONE.description}</Radio>
										<Radio value={this.ERadio.SOUNDS.value}>{this.ERadio.SOUNDS.description}</Radio>
										<Radio value={this.ERadio.WORDS.value}>{this.ERadio.WORDS.description}</Radio>
										<Radio value={this.ERadio.CONFUSED.value}>{this.ERadio.CONFUSED.description}</Radio>
										<Radio value={this.ERadio.ORIENTATED.value}>{this.ERadio.ORIENTATED.description}</Radio>
									</Space>
								</Radio.Group>
							</Col>
							<Col>
								<Space direction="vertical">
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.NONE.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.SOUNDS.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.WORDS.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.CONFUSED.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.ORIENTATED.value}</FormLabel>
								</Space>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

GCSVerbal.propTypes = {
	onVerbal: propTypes.func.isRequired,
}

GCSVerbal.defaultProps = {
	onVerbal: () => {},
}

export default injectIntl(GCSVerbal)
