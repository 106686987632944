import React, { Fragment } from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { Property } from "./TypeModalNew"

class TypeModalNewGeneralFields extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayCode && (
					<Row>
						<Col>
							<GInput
								name={Property.CODE}
								placeholder={translate(ResourceAssistance.Message.code)}
								value={this.props.code}
								onChange={this.props.onPropertyChange}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<GInput
							name={Property.NAME}
							placeholder={translate(ResourceAssistance.Message.name)}
							onChange={this.props.onPropertyChange}
							value={this.props.name}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={5}
							name={Property.DESCRIPTION}
							placeholder={translate(ResourceAssistance.Message.description)}
							onChange={this.props.onPropertyChange}
							value={this.props.description}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox name={Property.ACTIVE} onClick={this.props.onPropertyChange} checked={this.props.active} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewGeneralFields)
