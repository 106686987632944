import _ from "lodash"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setDateAction, setValue } from "~/redux/action"
import {
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class IPDModalMedicalCertificateExamDate extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedCertificate.certificate) {
			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START,
				this.props.ipd.selectedCertificate.certificate.examinationStartDateTime
			)
			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
				this.props.ipd.selectedCertificate.certificate.examinationEndDateTime
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.ipd.modalCertificate.examinationStartDateTime,
				this.props.ipd.modalCertificate.examinationStartDateTime
			)
		) {
			if (
				this.props.ipd.modalCertificate.examinationStartDateTime >
				this.props.ipd.modalCertificate.examinationEndDateTime
			) {
				this.props.setDateAction(
					SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
					Utils.generateDateFromLong(
						this.props.ipd.modalCertificate.examinationStartDateTime,
						0,
						0,
						0,
						23,
						59,
						59
					).getTime()
				)
			}
		}
	}

	componentWillUnmount() {
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START, Utils.generateDate().getTime())
		this.props.setDateAction(
			SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
			Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
		)
	}

	onStartDate(date) {
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START, date.getTime())
	}

	onEndDate(date) {
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END, date.getTime())
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.examinationDate)}</legend>
				<Row noGutters>
					<Col />
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							startDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.examinationStartDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col>
						<FormLabel style={{ alignSelf: ResourceAssistance.CSS.center }}>
							{translate(ResourceAssistance.Message.to)}
						</FormLabel>
					</Col>
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.examinationStartDateTime)}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.examinationEndDateTime)}
							onChange={this.onEndDate}
						/>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setDateAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificateExamDate)
