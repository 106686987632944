import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdmSModalNSCode,
	setAdmSModalNSName,
	setAdmSModalNSPhone,
	setAdmSModalNSAddress,
	setAdmSModalNSActive,
} from "~/redux/action/admin/supplierActions"

class AdmSModalNSDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCode = this.onCode.bind(this)
		this.onName = this.onName.bind(this)
		this.onPhone = this.onPhone.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmSModalNSCode("")
		this.props.setAdmSModalNSName("")
		this.props.setAdmSModalNSPhone("")
		this.props.setAdmSModalNSAddress("")
		this.props.setAdmSModalNSActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.supplier.newSupplierModal.code !== this.props.supplier.newSupplierModal.code ||
			prevProps.supplier.newSupplierModal.name !== this.props.supplier.newSupplierModal.name ||
			prevProps.supplier.newSupplierModal.address !== this.props.supplier.newSupplierModal.address
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let codeHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.code)
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.name)
		let addressHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.address)
		let codeInput = this.props.supplier.newSupplierModal.code.trim().toLowerCase()
		let nameInput = this.props.supplier.newSupplierModal.name.trim().toLowerCase()
		let addressInput = this.props.supplier.newSupplierModal.address.trim()

		if (
			!codeInput ||
			this.props.supplier.supplierTable.original
				.filter(
					(each) =>
						!this.props.supplier.selectedSupplier.supplier ||
						this.props.supplier.selectedSupplier.supplier.id !== each.id
				)
				.some((each) => each.code.trim().toLowerCase() === codeInput)
		) {
			codeHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateCodeWarning })
			)
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity("")
		} else if (
			!nameInput ||
			this.props.supplier.supplierTable.original
				.filter(
					(each) =>
						!this.props.supplier.selectedSupplier.supplier ||
						this.props.supplier.selectedSupplier.supplier.id !== each.id
				)
				.some((each) => each.displayName.trim().toLowerCase() === nameInput)
		) {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
			addressHtml.setCustomValidity("")
		} else if (!addressInput) {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
		} else {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity("")
		}
	}

	onCode(event) {
		this.props.setAdmSModalNSCode(event.target.value.toUpperCase())
	}

	onName(event) {
		this.props.setAdmSModalNSName(event.target.value)
	}

	onPhone(event) {
		this.props.setAdmSModalNSPhone(event.target.value)
	}

	onAddress(event) {
		this.props.setAdmSModalNSAddress(event.target.value)
	}

	onActive(event) {
		this.props.setAdmSModalNSActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.code)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.supplier.modalNS.code}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.supplier.newSupplierModal.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.supplier.modalNS.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.supplier.newSupplierModal.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.phone)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.phone}
							value={this.props.supplier.newSupplierModal.phone}
							onChange={this.onPhone}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.address)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.supplier.modalNS.address}
							as={ResourceAssistance.FormControl.as.textArea}
							value={this.props.supplier.newSupplierModal.address}
							rows="10"
							onChange={this.onAddress}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.supplier.newSupplierModal.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	supplier: state.admin.itemConfig.supplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{ setAdmSModalNSCode, setAdmSModalNSName, setAdmSModalNSPhone, setAdmSModalNSAddress, setAdmSModalNSActive },
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmSModalNSDetails))
