import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDocument } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { EType } from "../Home"

class ManagementReport extends React.Component {
	render() {
		return (
			<>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Report.View, this.props.login.user.roles) && (
					<Container fluid>
						<Row noGutters>
							<Col md="auto">
								<h2 style={{ marginBottom: "0.5rem" }}>{translate(ResourceAssistance.Message.report)}</h2>
							</Col>
						</Row>
						<Row>
							<Col sm="auto">
								<Button
									variant={ResourceAssistance.Button.variant.link}
									value={EType.REPORT.INV}
									onClick={this.props.onBtnClick}
								>
									<FcDocument size={ResourceAssistance.ReactIcon.size_md} />
									{translate(ResourceAssistance.Message.inventory)}
								</Button>
							</Col>
							<Col sm="auto">
								<Button
									variant={ResourceAssistance.Button.variant.link}
									value={EType.REPORT.TF}
									onClick={this.props.onBtnClick}
								>
									<FcDocument size={ResourceAssistance.ReactIcon.size_md} />
									{translate(ResourceAssistance.Message.transfer)}
								</Button>
							</Col>
							<Col sm="auto">
								<Button
									variant={ResourceAssistance.Button.variant.link}
									value={EType.REPORT.PR}
									onClick={this.props.onBtnClick}
								>
									<FcDocument size={ResourceAssistance.ReactIcon.size_md} />
									{translate(ResourceAssistance.Message.purchaseRequest)}
								</Button>
							</Col>
							<Col sm="auto">
								<Button
									variant={ResourceAssistance.Button.variant.link}
									value={EType.REPORT.PO}
									onClick={this.props.onBtnClick}
								>
									<FcDocument size={ResourceAssistance.ReactIcon.size_md} />
									{translate(ResourceAssistance.Message.purchaseOrder)}
								</Button>
							</Col>
							<Col sm="auto">
								<Button
									variant={ResourceAssistance.Button.variant.link}
									value={EType.REPORT.RC}
									onClick={this.props.onBtnClick}
								>
									<FcDocument size={ResourceAssistance.ReactIcon.size_md} />
									{translate(ResourceAssistance.Message.receive)}
								</Button>
							</Col>
						</Row>
					</Container>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default injectIntl(connect(mapStateToProps, {})(ManagementReport))
