import React from "react"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"
import ReportCashierAdmissionFilter from "~/report/hospitel/cashier/modal/admission/ReportCashierAdmissionFilter"
import ReportCashierBillingToTodayFilter from "~/report/hospitel/cashier/modal/billingToToday/ReportCashierBillingToTodayFilter"
import ReportCashierUserFilter from "~/report/hospitel/cashier/modal/user/ReportCashierUserFilter"
import ReportHospitelPatientFilter from "~/report/hospitel/hospitel/modal/patient/ReportHospitelPatientFilter"
import ReportLabCovid19LabFilter from "~/report/hospitel/lab/modal/covid19/ReportLabCovid19LabFilter"
import ReportInvByLocationFilter from "~/report/inv/modal/ReportInvByLocationFilter"
import ReportPOByIdFilter from "~/report/po/modal/report_by_id/ReportPOByIdFilter"
import ReportPOByLocationFilter from "~/report/po/modal/report_by_location/ReportPOByLocationFilter"
import ReportTFByLocationFilter from "~/report/tf/modal/report_by_location/ReportTFByLocationFilter"
import Warning from "~/view/component/modal/Warning"
import ModalSaveAll from "~/view/component/save-all/ModalSaveAll"
import ConfigServiceModalNS from "~/view/container/admin/configService/service/modal/ConfigServiceModalNS"
import AdmFModalNF from "~/view/container/admin/itemConfig/form/modal/AdmFModalNF"
import AdmIModalNI from "~/view/container/admin/itemConfig/item/modal/AdmIModalNI"
import AdmISModalNIS from "~/view/container/admin/itemConfig/item_source/modal/AdmISModalNIS"
import AdmSModalNS from "~/view/container/admin/itemConfig/supplier/modal/AdmSModalNS"
import AdmTModalNT from "~/view/container/admin/itemConfig/tax/modal/AdmTModalNT"
import AdmUModalNU from "~/view/container/admin/itemConfig/unit/modal/AdmUModalNU"
import NewLocation from "~/view/container/admin/location/modal/NewLocation"
import AbstractTypeModal from "~/view/container/admin/type/modal/AbstractTypeModal"
import AdmRoleModalNR from "~/view/container/admin/userConfig/role/modal/AdmRoleModalNR"
import AdmUserModalCPW from "~/view/container/admin/userConfig/user/modal/AdmUserModalCPW"
import AdmUserModalNU from "~/view/container/admin/userConfig/user/modal/AdmUserModalNU"
import AddInventory from "~/view/container/inventory/modal/AddInventory"
import EditInventory from "~/view/container/inventory/modal/EditInventory"
import POEdit from "~/view/container/purchase_order/modal/POEdit"
import PORejectReason from "~/view/container/purchase_order/modal/PORejectReason"
import AbstractPrModal from "~/view/container/purchase_request/modals/AbstractPrModal"
import RCModalCancel from "~/view/container/receive/modal/RCModalCancel"
import RCModalCancelDetail from "~/view/container/receive/modal/RCModalCancelDetail"
import RCModalReceive from "~/view/container/receive/modal/RCModalReceive"
import RCModalReceiveDetail from "~/view/container/receive/modal/RCModalReceiveDetail"
import TFModalCancelTRI from "~/view/container/transfer/modal/cancelRequestItem/TFModalCancelTRI"
import TFModalNR from "~/view/container/transfer/modal/newRequest/TFModalNR"
import TFModalNT from "~/view/container/transfer/modal/newTransfer/TFModalNT"
import DepositModalSearchPatient from "~/view/HIS/deposit/modal/search-patient/DepositModalSearchPatient"
import DepositModalWithdraw from "~/view/HIS/deposit/modal/withdraw/DepositModalWithdraw"
import FrontInventoryModalEdit from "~/view/HIS/front-inventory/_modal/edit/FrontInventoryModalEdit"
import OPDModalAdjustment from "~/view/HIS/opd/modal/adjustment/OPDModalAdjustment"
import OPDModalAppointmentEdit from "~/view/HIS/opd/modal/appointment-edit/OPDModalAppointmentEdit"
import OPDModalAppointment from "~/view/HIS/opd/modal/appointment/OPDModalAppointment"
import OPDModalDoctorInstruction from "~/view/HIS/opd/modal/doctor-instruction/OPDModalDoctorInstruction"
import OutpatientModalNurseOrderInfo from "~/view/HIS/opd/modal/nurse-order-info/OutpatientModalNurseOrderInfo"
import OPDModalDoctorService from "~/view/HIS/opd/modal/doctor-service/OPDModalDoctorService"
import OutpatientModalDoctor from "~/view/HIS/opd/modal/doctor/OutpatientModalDoctor"
import OPDModalGCS from "~/view/HIS/opd/modal/glasgow-coma-scale/OPDModalGCS"
import OPDModalICD from "~/view/HIS/opd/modal/icd/OPDModalICD"
import OPDModalIntakeOutput from "~/view/HIS/opd/modal/intake-output/OPDModalIntakeOutput"
import OPDModalLocation from "~/view/HIS/opd/modal/location/OPDModalLocation"
import OPDModalMedicalCertificate from "~/view/HIS/opd/modal/medical-certificate/OPDModalMedicalCertificate"
import OPDModalMedicalRecord from "~/view/HIS/opd/modal/medical-record/OPDModalMedicalRecord"
import OPDModalMedicalSupply from "~/view/HIS/opd/modal/medical-supply/OPDModalMedicalSupply"
import OPDModalModifyDate from "~/view/HIS/opd/modal/modify-date/OPDModalModifyDate"
import OPDModalNurseService from "~/view/HIS/opd/modal/nurse-service/OPDModalNurseService"
import OPDModalNurse from "~/view/HIS/opd/modal/nurse/OPDModalNurse"
import OPDModalPainScale from "~/view/HIS/opd/modal/pain-scale/OPDModalPainScale"
import OPDModalPupilSize from "~/view/HIS/opd/modal/pupil-size/OPDModalPupilSize"
import OPDModalSearch from "~/view/HIS/opd/modal/search/OPDModalSearch"
import OPDModalVitalSign from "~/view/HIS/opd/modal/vital-sign/OPDModalVitalSign"
import PharmacyModalLocation from "~/view/HIS/pharmacy/_modal/location/PharmacyModalLocation"
import PharmacyModalReturnEdit from "~/view/HIS/pharmacy/_modal/return/edit/PharmacyModalReturnEdit"
import PharmacyModalReturn from "~/view/HIS/pharmacy/_modal/return/PharmacyModalReturn"
import PharmacyModalSearch from "~/view/HIS/pharmacy/_modal/search/PharmacyModalSearch"
import PharmacyModalVerify from "~/view/HIS/pharmacy/_modal/verify/PharmacyModalVerify"
import CashierModalAdjustment from "~/view/hospitel/cashier/modal/adjustment/CashierModalAdjustment"
import CashierModalCertificate from "~/view/hospitel/cashier/modal/certificate/CashierModalCertificate"
import CashierModalDoctor from "~/view/hospitel/cashier/modal/doctor/CashierModalDoctor"
import CashierModalFinancialPlan from "~/view/hospitel/cashier/modal/financial-plan/CashierModalFinancialPlan"
import CashierModalLab from "~/view/hospitel/cashier/modal/lab/CashierModalLab"
import CashierModalNurse from "~/view/hospitel/cashier/modal/nurse/CashierModalNurse"
import CashierModalPayment from "~/view/hospitel/cashier/modal/payment/CashierModalPayment"
import CashierModalSearchPatient from "~/view/hospitel/cashier/modal/search-patient/CashierModalSearchPatient"
import HospitelModalAdjustmentService from "~/view/hospitel/hospitel/modal/adjustment-service/HospitelModalAdjustmentService"
import HospitelModalAdjustment from "~/view/hospitel/hospitel/modal/adjustment/HospitelModalAdjustment"
import HospitelModalDefaultOrder from "~/view/hospitel/hospitel/modal/doctor-instruction/HospitelModalDefaultOrder"
import HospitelModalDoctorNote from "~/view/hospitel/hospitel/modal/doctor-note/HospitelModalDoctorNote"
import HospitelModalDoctorOrder from "~/view/hospitel/hospitel/modal/doctor-order/HospitelModalDoctorOrder"
import HospitelModalDoctorService from "~/view/hospitel/hospitel/modal/doctor-service/HospitelModalDoctorService"
import IPDModalGCS from "~/view/hospitel/hospitel/modal/gcs/IPDModalGCS"
import IPDModalICD from "~/view/hospitel/hospitel/modal/icd/IPDModalICD"
import IPDModalIntakeOutput from "~/view/hospitel/hospitel/modal/io/IPDModalIntakeOutput"
import HospitelModalLocation from "~/view/hospitel/hospitel/modal/location/HospitelModalLocation"
import IPDModalMedicalCertificate from "~/view/hospitel/hospitel/modal/medical-certificate/IPDModalMedicalCertificate"
import HospitelModalMedicalSupply from "~/view/hospitel/hospitel/modal/medical-supply/HospitelModalMedicalSupply"
import HospitelModalNurseRecord from "~/view/hospitel/hospitel/modal/nurse-record/HospitelModalNurseRecord"
import HospitelModalNurseService from "~/view/hospitel/hospitel/modal/nurse-service/HospitelModalNurseService"
import IPDModalPainScale from "~/view/hospitel/hospitel/modal/pain-scale/IPDModalPainScale"
import IPDModalCreatePRNRecord from "~/view/hospitel/hospitel/modal/prn-create/IPDModalCreatePRNRecord"
import IPDModalPRNRecord from "~/view/hospitel/hospitel/modal/prn-record/IPDModalPRNRecord"
import IPDModalPupilSize from "~/view/hospitel/hospitel/modal/pupil-size/IPDModalPupilSize"
import HospitelModalRegister from "~/view/hospitel/hospitel/modal/register/HospitelModalRegister"
import HospitelModalSearch from "~/view/hospitel/hospitel/modal/search/HospitelModalSearch"
import HospitelModalStop from "~/view/hospitel/hospitel/modal/stop/HospitelModalStop"
import HospitelModalVitalSign from "~/view/hospitel/hospitel/modal/vital-sign/HospitelModalVitalSign"
import RegModalAdjustment from "~/view/hospitel/ipd/modal/adjustment/RegModalAdjustment"
import RegModalBudget from "~/view/hospitel/ipd/modal/budget/RegModalBudget"
import RegModalExpenseEst from "~/view/hospitel/ipd/modal/expense-estimation/RegModalExpenseEst"
import RegisterModalClinicalRecord from "~/view/hospitel/ipd/modal/register-clinical-record/RegisterModalClinicalRecord"
import IPDModalRegister from "~/view/hospitel/ipd/modal/register/IPDModalRegister"
import IPDModalSearch from "~/view/hospitel/ipd/modal/search/IPDModalSearch"
import CLModalApprove from "~/view/hospitel/lab/modal/approve/CLModalApprove"
import CLModalNewLab from "~/view/hospitel/lab/modal/lab/CLModalNewLab"
import CLModalNegative from "~/view/hospitel/lab/modal/negative/CLModalNegative"
import CLModalPositive from "~/view/hospitel/lab/modal/positive/CLModalPositive"
import CLModalRegister from "~/view/hospitel/lab/modal/register/CLModalRegister"
import CLModalSearchPatient from "~/view/hospitel/lab/modal/search-patient/CLModalSearchPatient"
import HospitalModalPatientClinicalRecord from "~/view/hospitel/modal/clinical-record/HospitalModalPatientClinicalRecord"
import NotificationModalNew from "~/view/menu/notification/modal/message/NotificationModalNew"
import Notification from "~/view/menu/notification/Notification"
import NotificationSearch from "~/view/menu/notification/NotificationSearch"
import TimelineModalSearch from "~/view/menu/timeline/search/TimelineModalSearch"
import Timeline from "~/view/menu/timeline/Timeline"
import ConfigICDModalN from "../admin/configICD/icd/modal/ConfigICDModalN"
import ConfigLevelModalN from "../admin/configICD/level/modal/ConfigLevelModalN"
import ConfigAntigenCommentModalN from "../admin/configLaboratory/antigen_comment/modal/ConfigAntigenCommentModalN"
import ConfigPCRCommentModalN from "../admin/configLaboratory/pcr_comment/modal/ConfigPCRCommentModalN"
import ConfigFocusModalNF from "../admin/customDefault/focus/modal/ConfigFocusModalNF"
import ConfigInterventionModalND from "../admin/customDefault/intervention/modal/ConfigInterventionModalND"
import AdmUserModalSignature from "../admin/userConfig/user/modal/signature/AdmUserModalSignature"
import DepositModalSave from "./../../HIS/deposit/modal/save/DepositModalSave"
import "./modal.css"
import OutpatientModalDoctorOrderInfo from "~/view/HIS/opd/modal/doctor-order-info/OutpatientModalDoctorOrderInfo"
import InpatientModalDoctorOrderInfo from "~/view/hospitel/hospitel/modal/doctor-order-info/InpatientModalDoctorOrderInfo"
import InpatientModalNurseRecordInfo from "~/view/hospitel/hospitel/modal/nurse-record-info/InpatientModalNurseRecordInfo"
import InpatientModalDoctorSOAPInfo from "~/view/hospitel/hospitel/modal/doctor-note-info/InpatientModalDoctorSOAPInfo"

class Modal extends React.Component {
	render() {
		return (
			<Route>
				<Route exact path={ResourceAssistance.Path.transfer}>
					<Route component={TFModalCancelTRI} />
					<Route component={TFModalNR} />
					<Route component={TFModalNT} />
				</Route>
				<Route exact path={ResourceAssistance.Path.pr} component={AbstractPrModal} />
				<Route exact path={ResourceAssistance.Path.po}>
					<Route component={POEdit} />
					<Route component={PORejectReason} />
				</Route>
				<Route exact path={ResourceAssistance.Path.rc}>
					<Route component={RCModalReceive} />
					<Route component={RCModalCancel} />
					<Route component={RCModalReceiveDetail} />
					<Route component={RCModalCancelDetail} />
				</Route>
				<Route exact path={ResourceAssistance.Path.inventory}>
					<Route component={AddInventory} />
					<Route component={EditInventory} />
				</Route>
				<Route exact path={ResourceAssistance.Path.type} component={AbstractTypeModal} />
				<Route exact path={ResourceAssistance.Path.configLoc} component={NewLocation} />
				<Route exact path={ResourceAssistance.Path.configItem}>
					<Route component={AdmSModalNS} />
					<Route component={AdmUModalNU} />
					<Route component={AdmFModalNF} />
					<Route component={AdmTModalNT} />
					<Route component={AdmIModalNI} />
					<Route component={AdmISModalNIS} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configUser}>
					<Route component={AdmUserModalNU} />
					<Route component={AdmUserModalCPW} />
					<Route component={AdmRoleModalNR} />
					<Route component={AdmUserModalSignature} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configService}>
					<Route component={ConfigServiceModalNS} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configCustomDefault}>
					<Route component={ConfigFocusModalNF} />
					<Route component={ConfigInterventionModalND} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configICD}>
					<Route component={ConfigICDModalN} />
					<Route component={ConfigLevelModalN} />
				</Route>
				<Route exact path={ResourceAssistance.Path.configLaboratory}>
					<Route component={ConfigPCRCommentModalN} />
					<Route component={ConfigAntigenCommentModalN} />
				</Route>

				{/* ***********************************************Report********************************* */}
				{/* Inventory filters*/}
				<Route exact path={ResourceAssistance.Path.reportInv}>
					<Route component={ReportInvByLocationFilter} />
				</Route>

				{/* PO filters*/}
				<Route exact path={ResourceAssistance.Path.reportPo}>
					<Route component={ReportPOByLocationFilter} />
					<Route component={ReportPOByIdFilter} />
				</Route>

				{/* Transfer filters */}
				<Route exact path={ResourceAssistance.Path.reportTf}>
					<Route component={ReportTFByLocationFilter} />
				</Route>

				{/* Cashier filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportCashier}>
					<Route component={ReportCashierAdmissionFilter} />
					<Route component={ReportCashierUserFilter} />
					<Route component={ReportCashierBillingToTodayFilter} />
				</Route>

				{/* Hospitel filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportHospitel}>
					<Route component={ReportHospitelPatientFilter} />
				</Route>

				{/* Laboratory filters */}
				<Route exact path={ResourceAssistance.Path.Hospitel.reportLab}>
					<Route component={ReportLabCovid19LabFilter} />
				</Route>
				{/* ***************************************************************************************** */}

				{/* Warning */}
				<Route
					render={(props) => (
						<Warning
							doneBtnRef={this.props.modal.warning.doneBtnRef}
							closeBtnRef={this.props.modal.warning.closeBtnRef}
							isShow={this.props.modal.isWarningDisplay}
							msg={this.props.modal.warning.msg}
							{...this.props}
						/>
					)}
				/>

				<Route
					render={(props) => (
						<HospitalModalPatientClinicalRecord patient={this.props.modal.hospital.clinicalRecord.patient} {...props} />
					)}
				/>

				{/* Cashier */}
				<Route exact path={ResourceAssistance.Path.Hospitel.cashier}>
					<Route component={CashierModalFinancialPlan} />
					<Route component={CashierModalSearchPatient} />
					<Route component={CashierModalLab} />
					<Route component={CashierModalCertificate} />
					<Route component={CashierModalAdjustment} />
					<Route component={CashierModalDoctor} />
					<Route component={CashierModalNurse} />
					<Route component={CashierModalPayment} />
				</Route>

				{/* IPD */}
				<Route exact path={ResourceAssistance.Path.Hospitel.hospitel}>
					<Route component={HospitelModalLocation} />
					<Route component={HospitelModalRegister} />
					<Route component={HospitelModalSearch} />
					<Route component={HospitelModalDoctorOrder} />
					<Route component={HospitelModalStop} />
					<Route component={HospitelModalNurseRecord} />
					<Route component={HospitelModalDoctorNote} />
					<Route component={HospitelModalVitalSign} />
					<Route component={HospitelModalDefaultOrder} />
					<Route component={HospitelModalMedicalSupply} />
					<Route component={HospitelModalNurseService} />
					<Route component={HospitelModalAdjustment} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={HospitelModalDoctorService} />
					<Route component={ModalSaveAll} />
					<Route component={IPDModalPRNRecord} />
					<Route component={IPDModalCreatePRNRecord} />
					<Route component={IPDModalMedicalCertificate} />
					<Route component={IPDModalICD} />
					<Route component={IPDModalIntakeOutput} />
					<Route component={IPDModalGCS} />
					<Route component={IPDModalPainScale} />
					<Route component={IPDModalPupilSize} />
					<Route component={InpatientModalDoctorOrderInfo} />
					<Route component={InpatientModalNurseRecordInfo} />
					<Route component={InpatientModalDoctorSOAPInfo} />
				</Route>

				{/* Lab */}
				<Route exact path={ResourceAssistance.Path.Hospitel.covid19Lab}>
					<Route component={CLModalRegister} />
					<Route component={CLModalSearchPatient} />
					<Route component={CLModalNegative} />
					<Route component={CLModalPositive} />
					<Route component={CLModalNewLab} />
					<Route component={CLModalApprove} />
				</Route>

				{/* Registration */}
				<Route exact path={ResourceAssistance.Path.Hospitel.registration}>
					<Route component={IPDModalRegister} />
					<Route component={IPDModalSearch} />
					<Route component={RegisterModalClinicalRecord} />
					<Route component={RegModalExpenseEst} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={RegModalAdjustment} />
					<Route component={RegModalBudget} />
				</Route>

				{/* Deposit */}
				<Route exact path={ResourceAssistance.Path.HIS.deposit}>
					<Route component={DepositModalSearchPatient} />
					<Route component={DepositModalSave} />
					<Route component={DepositModalWithdraw} />
				</Route>

				{/* OPD */}
				<Route exact path={ResourceAssistance.Path.HIS.opd}>
					<Route component={OPDModalLocation} />
					<Route component={OPDModalAppointment} />
					<Route component={OPDModalSearch} />
					<Route component={OPDModalMedicalRecord} />
					<Route component={OutpatientModalDoctor} />
					<Route component={OPDModalModifyDate} />
					<Route component={OPDModalMedicalCertificate} />
					<Route component={OPDModalDoctorService} />
					<Route component={ModalSaveAll} />
					<Route component={HospitelModalAdjustmentService} />
					<Route component={OPDModalDoctorInstruction} />
					<Route component={OPDModalNurse} />
					<Route component={OPDModalVitalSign} />
					<Route component={OPDModalMedicalSupply} />
					<Route component={OPDModalAdjustment} />
					<Route component={OPDModalNurseService} />
					<Route component={OPDModalAppointmentEdit} />
					<Route component={OPDModalICD} />
					<Route component={OPDModalIntakeOutput} />
					<Route component={OPDModalGCS} />
					<Route component={OPDModalPainScale} />
					<Route component={OPDModalPupilSize} />
					<Route component={OutpatientModalNurseOrderInfo} />
					<Route component={OutpatientModalDoctorOrderInfo} />
				</Route>

				{/* Pharmacy */}
				<Route exact path={ResourceAssistance.Path.HIS.pharmacy}>
					<Route component={PharmacyModalLocation} />
					<Route component={PharmacyModalSearch} />
					<Route component={PharmacyModalVerify} />
					<Route component={PharmacyModalReturn} />
					<Route component={PharmacyModalReturnEdit} />
				</Route>

				{/* Font Inventory */}
				<Route exact path={ResourceAssistance.Path.HIS.frontInventory}>
					<Route component={FrontInventoryModalEdit} />
				</Route>

				{/* Menu */}
				{/* Notification */}
				<Route component={Notification} />
				<Route component={NotificationSearch} />
				<Route component={NotificationModalNew} />

				{/* Timeline */}
				<Route component={Timeline} />
				<Route component={TimelineModalSearch} />
			</Route>
		)
	}
}

const mapStateToProps = (state) => ({
	modal: state.modal,
	modalAdjustment: state.hospitel.cashier.modalAdjustment,
})

export default connect(mapStateToProps, {})(Modal)
