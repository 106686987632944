import propTypes from "prop-types"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { ResourceAssistance, translate } from "~/i18n"
import LevelType from "./ICDLevelType"

class ICDLevel extends React.Component {
	render() {
		return (
			<Modal
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.sm}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onCloseBtn}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.props.onSave}
				>
					<ModalHeader className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.typesOfDiagnosis)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<LevelType {...this.state} {...this.props} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.props.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button type={ResourceAssistance.Button.type.submit} variant={ResourceAssistance.Button.variant.green}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

ICDLevel.propTypes = {
	isDisplay: propTypes.bool,
}

ICDLevel.defaultProps = {
	isDisplay: false,
}

export default ICDLevel
