import React, { Fragment } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import OutpatientRightDoctorOrder from "./OutpatientRightDoctorOrder"
import OutpatientRightDoctorOrderAction from "./OutpatientRightDoctorOrderAction"
import OutpatientRightMedicalCertificate from "./OutpatientRightMedicalCertificate"
import OutpatientRightNurseOrder from "./OutpatientRightNurseOrder"

class OutpatientRightOrderTab extends React.Component {
	EOrder = {
		DOCTOR: "OPD_DOCTOR_ORDER",
		NURSE: "OPD_NURSE_ORDER",
		CERTIFICATE: "OPD_MEDICAL_CERTIFICATE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EOrder.DOCTOR,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tabs
				variant="tabs"
				// transition={false}
				activeKey={this.state.tab}
				onSelect={this.onTabClick}
				mountOnEnter={false}
			>
				<Tab
					eventKey={this.EOrder.DOCTOR}
					title={translate(ResourceAssistance.Hospitel.doctor)}
					name={this.EOrder.DOCTOR}
				>
					{this.state.tab === this.EOrder.DOCTOR && (
						<Fragment>
							{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Doctor, this.props.login.user.roles) && (
								<OutpatientRightDoctorOrderAction />
							)}
							<OutpatientRightDoctorOrder {...this.state} />
						</Fragment>
					)}
				</Tab>
				<Tab eventKey={this.EOrder.NURSE} title={translate(ResourceAssistance.Hospitel.nurse)} name={this.EOrder.NURSE}>
					{this.state.tab === this.EOrder.NURSE && (
						<Fragment>
							<OutpatientRightNurseOrder {...this.state} />
						</Fragment>
					)}
				</Tab>
				<Tab
					eventKey={this.EOrder.CERTIFICATE}
					title={translate(ResourceAssistance.Hospitel.medicalCertificate)}
					name={this.EOrder.CERTIFICATE}
				>
					{this.state.tab === this.EOrder.CERTIFICATE && (
						<Fragment>
							<OutpatientRightMedicalCertificate {...this.state} />
						</Fragment>
					)}
				</Tab>
			</Tabs>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientRightOrderTab)
