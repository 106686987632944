import { Radio, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class RegModalClinicalRecordStatus extends React.Component {
	ERadio = {
		WALK: "WALK",
		WHEELCHAIR: "WHEELCHAIR",
		STRETCHER: "STRETCHER",
		OTHER: "OTHER",
	}
	TRIAGES = [
		{ displayName: "Level 1 - Immediate: life threatening" },
		{ displayName: "Level 2 - Emergency: could become life threatening" },
		{ displayName: "Level 3 - Urgent: not life threatening" },
		{ displayName: "Level 4 - Semi Urgent: not life threatening" },
		{ displayName: "Level 5 - Non-urgent: needs treatment when time permits" },
	]
	constructor(props) {
		super(props)

		this.state = {
			arrival: this.ERadio.WALK,
			iso: "",
			fallRisk: "",
		}

		this.onArrival = this.onArrival.bind(this)
		this.onArrivalOther = this.onArrivalOther.bind(this)
		this.onTriage = this.onTriage.bind(this)
		this.onSelectIso = this.onSelectIso.bind(this)
		this.onDeselectIso = this.onDeselectIso.bind(this)
		this.onSelectFallRisk = this.onSelectFallRisk.bind(this)
		this.onDeselectFallRisk = this.onDeselectFallRisk.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.reg.modalClinicalRecord.isolationPrecautions)) {
			this.props.loadIsoPrecautions()
		}

		if (_.isEmpty(this.props.reg.modalClinicalRecord.fallRisks)) {
			this.props.loadFallRisks()
		}

		if (this.props.reg.selectedAdmission.admission) {
			// arrival
			switch (this.props.reg.selectedAdmission.admission.clinicalRecord.arrival) {
				case this.ERadio.WALK:
				case this.ERadio.WHEELCHAIR:
				case this.ERadio.STRETCHER:
					this.setState({
						arrival: this.props.reg.selectedAdmission.admission.clinicalRecord.arrival,
					})
					break
				default:
					this.setState({
						arrival: this.ERadio.OTHER,
					})
					break
			}
			this.props.setValue(
				SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL,
				this.props.reg.selectedAdmission.admission.clinicalRecord.arrival
			)

			// triage
			let idx = this.TRIAGES.findIndex(
				(each) => each.displayName === this.props.reg.selectedAdmission.admission.clinicalRecord.triage
			)
			this.props.setSelected(SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE, idx, this.TRIAGES[idx])

			// isolation precaution
			this.setState({
				iso: this.props.reg.selectedAdmission.admission.clinicalRecord.isolationPrecaution,
			})

			//fall risk
			this.setState({
				fallRisk: this.props.reg.selectedAdmission.admission.clinicalRecord.fallRisk,
			})
		} else {
			this.props.setSelected(SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE, 4, this.TRIAGES[4])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.iso !== this.state.iso || prevState.fallRisk !== this.state.fallRisk) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL, "")
		this.props.setSelected(SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE, -1, null)
	}

	getIsoValues() {
		let values = []
		this.props.reg.modalClinicalRecord.isolationPrecautions.forEach((iso, idx) => {
			if (_.includes(this.state.iso, iso.displayName)) {
				values.push(idx)
			}
		})
		return values
	}

	getFallRiskValues() {
		let values = []
		this.props.reg.modalClinicalRecord.fallRisks.forEach((iso, idx) => {
			if (_.includes(this.state.fallRisk, iso.description)) {
				values.push(idx)
			}
		})
		return values
	}

	onArrival(e) {
		this.setState({
			arrival: e.target.value,
		})
		switch (e.target.value) {
			case this.ERadio.WALK:
			case this.ERadio.WHEELCHAIR:
			case this.ERadio.STRETCHER:
				this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL, e.target.value)
				break
			default:
				this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL, "")
				break
		}
	}

	onArrivalOther(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL, e.target.value)
	}

	onTriage(value) {
		this.props.setSelected(
			SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.TRIAGES[value] : null
		)
	}

	onSelectIso(idx) {
		this.setState({
			iso: Utils.replaceDuplicateEmptyLine(
				this.state.iso.concat(this.props.reg.modalClinicalRecord.isolationPrecautions[idx].displayName, "\n")
			),
		})
	}

	onDeselectIso(idx) {
		this.setState({
			iso: Utils.replaceDuplicateEmptyLine(
				this.state.iso.replace(this.props.reg.modalClinicalRecord.isolationPrecautions[idx].displayName, "")
			),
		})
	}

	onSelectFallRisk(idx) {
		this.setState({
			fallRisk: Utils.replaceDuplicateEmptyLine(
				this.state.fallRisk.concat(this.props.reg.modalClinicalRecord.fallRisks[idx].description, "\n")
			),
		})
	}

	onDeselectFallRisk(idx) {
		this.setState({
			fallRisk: Utils.replaceDuplicateEmptyLine(
				this.state.fallRisk.replace(this.props.reg.modalClinicalRecord.fallRisks[idx].description, "")
			),
		})
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.firstAssessment)}</legend>
				<Row noGutters>
					<Col md="auto">
						<FormLabel style={{ paddingLeft: "0.3rem" }}>{translate(ResourceAssistance.Message.arrival)}</FormLabel>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<Radio.Group
							options={[
								{ label: translate(ResourceAssistance.Message.walk), value: this.ERadio.WALK },
								{ label: translate(ResourceAssistance.Message.wheelchair), value: this.ERadio.WHEELCHAIR },
								{ label: translate(ResourceAssistance.Message.stretcher), value: this.ERadio.STRETCHER },
								{ label: translate(ResourceAssistance.Message.other), value: this.ERadio.OTHER },
							]}
							value={this.state.arrival}
							onChange={this.onArrival}
						/>
					</Col>
				</Row>
				{this.state.arrival === this.ERadio.OTHER && (
					<Row>
						<Col>
							<GInput
								required
								placeholder={translate(ResourceAssistance.Message.other)}
								value={this.props.reg.modalClinicalRecord.arrival}
								onChange={this.onArrivalOther}
								autoSize
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<Select
							required
							showSearch
							placeholder={translate(ResourceAssistance.Message.triage)}
							status={!this.props.reg.modalClinicalRecord.selectedTriage.triage ? "error" : undefined}
							value={
								this.props.reg.modalClinicalRecord.selectedTriage.triage
									? this.props.reg.modalClinicalRecord.selectedTriage.index
									: undefined
							}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={this.onTriage}
						>
							{Utils.renderSelects(this.TRIAGES, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							mode={ResourceAssistance.Select.mode.multiple}
							style={{ flexGrow: 1, textAlign: "center" }}
							value={this.getIsoValues()}
							placeholder={translate(ResourceAssistance.Message.isolationPrecaution)}
							maxTagCount="responsive"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onSelect={this.onSelectIso}
							onDeselect={this.onDeselectIso}
						>
							{Utils.renderSelects(this.props.reg.modalClinicalRecord.isolationPrecautions, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							mode={ResourceAssistance.Select.mode.multiple}
							style={{ flexGrow: 1, textAlign: "center" }}
							value={this.getFallRiskValues()}
							placeholder={translate(ResourceAssistance.Message.fallRisk)}
							maxTagCount="responsive"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onSelect={this.onSelectFallRisk}
							onDeselect={this.onDeselectFallRisk}
						>
							{Utils.renderSelects(this.props.reg.modalClinicalRecord.fallRisks, false, -1, "description")}
						</Select>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RegModalClinicalRecordStatus)
