import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_DEPOSIT_PATIENT, SET_PAGE_LOADING } from "~/redux/type"
import DepositAction from "./DepositAction"
import DepositBalance from "./DepositBalance"
import DepositPatient from "./DepositPatient"
import DepositTransaction from "./DepositTransaction"

class DepositDetails extends React.Component {
	constructor(props) {
		super(props)

		this.getPatientById = this.getPatientById.bind(this)
	}

	getPatientById() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.deposit.searchPatient,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				pid: this.props.deposit.patient.id,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEPOSIT_PATIENT, res.data.patients[0])
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<Row>
					<Col>
						<DepositPatient />
					</Col>
					<Col md="3">
						<DepositBalance getPatientById={this.getPatientById} />
					</Col>
				</Row>
				<DepositAction />
				<DepositTransaction />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositDetails)
