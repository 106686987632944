import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setRegModalExpenseEstPendings } from "~/redux/action"
import { SET_REGISTRATION_ADJUSTMENT_DISPLAY } from "~/redux/type"
import Adjustment from "~/view/component/adjustment/Adjustment"
import moment from "moment"

class RegModalAdjustment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RegModalAdjustment",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.adjustmentRef = React.createRef()
	}

	reset() {
		this.props.onModalDisplayAction(SET_REGISTRATION_ADJUSTMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let selected = Object.assign({}, this.props.reg.modalExpenseEst.selectedOrder.order, {
			id: undefined,
			creationDateTime: moment().valueOf(),
			qtyPerDay: this.adjustmentRef.current.state.qtyPerDay,
			description: this.props.reg.modalExpenseEst.selectedOrder.order.displayName,
			startDateTime: this.adjustmentRef.current.state.startDateTime,
			endDateTime: this.adjustmentRef.current.state.endDateTime,
			notes: this.adjustmentRef.current.state.notes,
		})
		let pendings = this.props.reg.modalExpenseEst.pendingTable.original
		pendings.push(selected)
		this.props.setRegModalExpenseEstPendings(pendings)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>
								{this.props.reg.modalExpenseEst.selectedOrder.order
									? this.props.reg.modalExpenseEst.selectedOrder.order.displayName
									: ""}
							</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Adjustment ref={this.adjustmentRef} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.FormControl.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRegistrationAdjustmentDisplay,
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setRegModalExpenseEstPendings,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalAdjustment))
