import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_PHARMACY_SEARCH_END_DATETIME,
	SET_PHARMACY_SEARCH_START_DATETIME,
	SET_PHARMACY_SELECTED_PAITENT,
} from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class PharmacyLeftDate extends React.Component {
	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_PHARMACY_SEARCH_START_DATETIME, moment().startOf("day").valueOf())
		this.props.setValue(SET_PHARMACY_SEARCH_END_DATETIME, moment().endOf("day").milliseconds(0).valueOf())
	}

	onDateChange(value, dateString) {
		this.props.setValue(
			SET_PHARMACY_SEARCH_START_DATETIME,
			value ? moment(value).startOf("day").valueOf() : moment().startOf("day").valueOf()
		)
		this.props.setValue(
			SET_PHARMACY_SEARCH_END_DATETIME,
			value ? moment(value).endOf("day").milliseconds(0).valueOf() : moment().endOf("day").milliseconds(0).valueOf()
		)
	}

	onSearch() {
		this.props.setSelected(SET_PHARMACY_SELECTED_PAITENT, -1, null)
		this.props.loadPatients()
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<AntDatePicker
						size={"small"}
						format={"DD-MM-YYYY"}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
						defaultValues={[moment(this.props.pharmacy.startDateTime)]}
						onChange={this.onDateChange}
					/>
				</Col>
				<Col sm="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearch}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyLeftDate))
