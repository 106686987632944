import { Radio, Space } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"

class GCSEyeOpening extends React.Component {
	ERadio = {
		NONE: {
			description: translate(ResourceAssistance.Message.noEyeOpening),
			value: 1,
		},
		PRESSURE: {
			description: translate(ResourceAssistance.Message.toPressure),
			value: 2,
		},
		SOUND: {
			description: translate(ResourceAssistance.Message.toSound),
			value: 3,
		},
		SPONTANEOUS: {
			description: translate(ResourceAssistance.Message.spontaneous),
			value: 4,
		},
	}

	constructor(props) {
		super(props)

		this.state = {
			eye: this.ERadio.NONE.value,
		}

		this.onEyeOpening = this.onEyeOpening.bind(this)
	}

	componentDidMount() {
		if (this.props.gcs) {
			this.setState({
				eye: this.props.gcs.eyeOpening,
			})
		}
	}

	onEyeOpening(e) {
		this.setState({
			eye: e.target.value,
		})
		this.props.onEyeOpening(e)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.eyeOpeningResponse)}</legend>
						<Row>
							<Col md="auto">
								<Radio.Group value={this.state.eye} onChange={this.onEyeOpening}>
									<Space direction="vertical">
										<Radio value={this.ERadio.NONE.value}>{this.ERadio.NONE.description}</Radio>
										<Radio value={this.ERadio.PRESSURE.value}>{this.ERadio.PRESSURE.description}</Radio>
										<Radio value={this.ERadio.SOUND.value}>{this.ERadio.SOUND.description}</Radio>
										<Radio value={this.ERadio.SPONTANEOUS.value}>{this.ERadio.SPONTANEOUS.description}</Radio>
									</Space>
								</Radio.Group>
							</Col>
							<Col>
								<Space direction="vertical">
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.NONE.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.PRESSURE.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.SOUND.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.SPONTANEOUS.value}</FormLabel>
								</Space>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

GCSEyeOpening.propTypes = {
	onEyeOpening: propTypes.func.isRequired,
}

GCSEyeOpening.defaultProps = {
	onEyeOpening: () => {},
}

export default injectIntl(GCSEyeOpening)
