import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { I18nProvider, ResourceAssistance } from "~/i18n"
import { resetAction } from "~/redux/action"
import Loading from "~/view/component/loading/Loading"
import Footer from "~/view/container/Footer"
import Header from "~/view/container/Header"
import Validate from "~/view/container/login/Validate"
import Main from "~/view/container/Main"
import "./App.css"
import { store } from "./redux/Store"
import { Utils } from "./utils/Utils"

function App({ match, history }) {
	const locale = useSelector((state) => state.language.locale)

	useEffect(() => {
		window.addEventListener("popstate", () => {
			history.push(ResourceAssistance.Path.home)
			store.dispatch(resetAction())
		})

		window.oncontextmenu = function () {
			return Utils.isDevMode()
		}

		document.addEventListener(
			"keydown",
			function (event) {
				var key = event.key || event.keyCode

				if (key === 123) {
					return Utils.isDevMode()
				} else if ((event.ctrlKey && event.shiftKey && key === 73) || (event.ctrlKey && event.shiftKey && key === 74)) {
					return Utils.isDevMode()
				}
			},
			false
		)
	})

	return (
		<I18nProvider locale={locale}>
			<Switch>
				<Route exact path={ResourceAssistance.Path.root} component={Validate} />
				<Route>
					<Route render={(props) => <Header {...props} />} />
					<Route render={(props) => <Main {...props} />} />
					<Route component={Footer} />
					<Route component={Loading} />
				</Route>
			</Switch>
		</I18nProvider>
	)
}

export default App
