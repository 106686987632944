import _ from "lodash"
import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLabPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_LAB_PATIENTS_LOADING, SET_HOSPITEL_LAB_REGISTER_PATIENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import CLModalRegisterPatient from "./CLModalRegisterPatient"

class CLModalRegister extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLModalRegister",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_REGISTER_PATIENT, false)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.lab.selectedPatient.patient, {
			...this.props.lab.selectedPatient.patient,
			title: Utils.trim(this.props.lab.modalRegister.title),
			firstName: Utils.trim(this.props.lab.modalRegister.firstName),
			lastName: Utils.trim(this.props.lab.modalRegister.lastName),
			identificationNumber: Utils.trim(this.props.lab.modalRegister.identificationNumber),
			gender: Utils.trim(this.props.lab.modalRegister.gender),
			dobtime: Utils.trim(this.props.lab.modalRegister.dob),
			tel: Utils.trim(this.props.lab.modalRegister.tel),
			address: Utils.trim(this.props.lab.modalRegister.address),
		})

		return (
			Utils.BigNumber(this.props.lab.modalRegister.dob).isGreaterThanOrEqualTo(Utils.generateDate().getTime()) ||
			_.isEqual(this.props.lab.selectedPatient.patient, target)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.lab.registerPatient,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				patients: [
					{
						...this.props.lab.selectedPatient.patient,
						id: this.props.lab.selectedPatient.patient ? this.props.lab.selectedPatient.patient.id : 0,
						title: this.props.lab.modalRegister.title.trim(),
						firstName: this.props.lab.modalRegister.firstName.trim(),
						lastName: this.props.lab.modalRegister.lastName.trim(),
						identificationNumber: this.props.lab.modalRegister.identificationNumber.trim(),
						gender: this.props.lab.modalRegister.gender.trim(),
						tel: this.props.lab.modalRegister.tel.trim(),
						address: this.props.lab.modalRegister.address.trim(),
						dob: this.props.lab.modalRegister.dob,
					},
				],
			},
		}

		let callback = (res) => {
			if (
				this.props.lab.patientTable.filtered.length > 0 &&
				res.data.patients[0].creationDateTime < this.props.lab.patientTable.filtered[0].creationDateTime
			) {
				this.props.setLabPatients(res.data.patients)
			} else {
				let patients = this.props.lab.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setLabPatients(patients)
			}
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				// dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Hospitel.registerPatient)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<CLModalRegisterPatient />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.lab.isRegisterPatientDisplay,
	login: state.login,
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setLabPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalRegister)
