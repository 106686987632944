import { Input } from "antd"
import propTypes from "prop-types"
import React from "react"
import "./input.css"

class GInput extends React.Component {
	constructor(props) {
		super(props)

		this.inputRef = React.createRef()
		this.textAreaRef = React.createRef()

		this.state = {
			componentName: "GInput",
		}
		this.onFocus = this.onFocus.bind(this)
	}

	componentDidMount() {
		// if (this.inputRef.current) {
		// 	this.inputRef.current.focus()
		// 	this.inputRef.current.select()
		// }
		// if (this.textAreaRef.current) {
		// 	this.textAreaRef.current.focus({
		// 		cursor: "all",
		// 	})
		// }
	}

	onFocus() {
		if (this.props.autoSize) {
			if (this.textAreaRef.current) {
				this.textAreaRef.current.focus({
					cursor: "all",
				})
			}
		} else {
			if (this.inputRef.current) {
				this.inputRef.current.select()
			}
		}
	}

	render() {
		return (
			<label className={this.state.componentName}>
				{this.props.autoSize ? (
					<Input.TextArea
						ref={this.textAreaRef}
						required={this.props.required}
						name={this.props.name}
						step={this.props.step}
						min={this.props.min}
						max={this.props.max}
						placeholder={this.props.placeholder}
						className={"textfield"}
						autoSize={{ minRows: this.props.minRows, maxRows: this.props.maxRows }}
						disabled={this.props.disabled}
						value={this.props.value}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
					/>
				) : (
					<Input
						ref={this.inputRef}
						required={this.props.required}
						name={this.props.name}
						placeholder={this.props.placeholder}
						style={this.props.style}
						type={this.props.type}
						step={this.props.step}
						min={this.props.min}
						max={this.props.max}
						addonBefore={this.props.addonBefore}
						addonAfter={this.props.addonAfter}
						className="textfield"
						disabled={this.props.disabled}
						pattern={this.props.pattern}
						value={this.props.value}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
					/>
				)}
				<span className="placeholder">{this.props.placeholder}</span>
			</label>
		)
	}
}

GInput.propTypes = {
	autoSize: propTypes.bool,
	disabled: propTypes.bool,
	minRows: propTypes.number,
	maxRows: propTypes.number,
	onChange: propTypes.func,
	placeholder: propTypes.any,
	pattern: propTypes.string,
	required: propTypes.bool,
	style: propTypes.object,
	type: propTypes.string,
	value: propTypes.any,
}

GInput.defaultProps = {
	autoSize: false,
	disabled: false,
	onChange: () => {},
	minRows: undefined,
	maxRows: undefined,
	required: false,
	style: {},
	type: "text",
	value: undefined,
}

export default GInput
