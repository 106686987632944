import { ResourceAssistance } from "~/i18n"
import {
	SET_DEPOSIT_MODAL_SAVE_AMOUNT,
	SET_DEPOSIT_MODAL_SAVE_PAYMENT_TYPES,
	SET_DEPOSIT_MODAL_SAVE_REMARK,
	SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT,
	SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME,
	SET_DEPOSIT_MODAL_SEARCH_ID,
	SET_DEPOSIT_MODAL_SEARCH_LAST_NAME,
	SET_DEPOSIT_MODAL_SEARCH_PATIENTS,
	SET_DEPOSIT_MODAL_SEARCH_PID,
	SET_DEPOSIT_MODAL_SEARCH_SELECTED_PATIENT,
	SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT,
	SET_DEPOSIT_MODAL_WITHDRAW_PAYMENT_TYPES,
	SET_DEPOSIT_MODAL_WITHDRAW_REMARK,
	SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT,
	SET_DEPOSIT_PATIENT,
	SET_DEPOSIT_TRANSACTIONS,
} from "~/redux/type"

const init = {
	patient: null,

	transactionTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.id,
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.type,
			ResourceAssistance.Message.paymentType,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.by,
		],
		body: [],
		colStyle: [],
	},
	modalSearch: {
		pid: "",
		identificationNumber: "",
		firstName: "",
		lastName: "",
		selectedPatient: {
			index: -1,
			patient: null,
		},
		patientTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Hospitel.identification,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Hospitel.dateOfBirth,
			],
			body: [],
		},
	},
	modalSave: {
		amount: 0,
		remark: "",
		payments: [],
		selectedPayment: {
			index: -1,
			payment: null,
		},
	},
	modalWithdraw: {
		amount: 0,
		remark: "",
		payments: [],
		selectedPayment: {
			index: -1,
			payment: null,
		},
	},
}

const depositReducer = (state = init, action) => {
	switch (action.type) {
		case SET_DEPOSIT_MODAL_SEARCH_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					identificationNumber: action.payload.value,
				},
			})

		case SET_DEPOSIT_MODAL_SEARCH_PID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					pid: action.payload.value,
				},
			})

		case SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					firstName: action.payload.value,
				},
			})

		case SET_DEPOSIT_MODAL_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					lastName: action.payload.value,
				},
			})

		case SET_DEPOSIT_MODAL_SEARCH_PATIENTS:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					patientTable: {
						...state.modalSearch.patientTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_DEPOSIT_MODAL_SEARCH_SELECTED_PATIENT:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					selectedPatient: {
						index: action.payload.index,
						patient: action.payload.selected,
					},
				},
			})

		case SET_DEPOSIT_PATIENT:
			return Object.assign({}, state, {
				patient: action.payload.value,
			})
		case SET_DEPOSIT_MODAL_SAVE_PAYMENT_TYPES:
			return Object.assign({}, state, {
				modalSave: {
					...state.modalSave,
					payments: action.payload.objs,
				},
			})
		case SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				modalSave: {
					...state.modalSave,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.selected,
					},
				},
			})
		case SET_DEPOSIT_MODAL_SAVE_AMOUNT:
			return Object.assign({}, state, {
				modalSave: {
					...state.modalSave,
					amount: action.payload.value,
				},
			})
		case SET_DEPOSIT_MODAL_SAVE_REMARK:
			return Object.assign({}, state, {
				modalSave: {
					...state.modalSave,
					remark: action.payload.value,
				},
			})
		case SET_DEPOSIT_TRANSACTIONS:
			return Object.assign({}, state, {
				transactionTable: {
					...state.transactionTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})

		case SET_DEPOSIT_MODAL_WITHDRAW_PAYMENT_TYPES:
			return Object.assign({}, state, {
				modalWithdraw: {
					...state.modalWithdraw,
					payments: action.payload.objs,
				},
			})

		case SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				modalWithdraw: {
					...state.modalWithdraw,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.selected,
					},
				},
			})

		case SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT:
			return Object.assign({}, state, {
				modalWithdraw: {
					...state.modalWithdraw,
					amount: action.payload.value,
				},
			})
		case SET_DEPOSIT_MODAL_WITHDRAW_REMARK:
			return Object.assign({}, state, {
				modalWithdraw: {
					...state.modalWithdraw,
					remark: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default depositReducer
