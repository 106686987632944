import { Button, Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiRefreshCcw } from "react-icons/fi"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class CashierLeftPatientRow extends React.Component {
	constructor(props) {
		super(props)

		this.onRefresh = this.onRefresh.bind(this)
	}

	onRefresh() {
		this.props.getPatientById(this.props.cashier.patient.id)
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
							column={1}
							extra={
								<Col md="auto">
									<Button
										type="primary"
										size="middle"
										shape="circle"
										icon={<FiRefreshCcw size={ResourceAssistance.ReactIcon.size} />}
										onClick={this.onRefresh}
									/>
								</Col>
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.cashier.patient.id}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
								{this.props.cashier.patient.title} {this.props.cashier.patient.firstName}{" "}
								{this.props.cashier.patient.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{Utils.formatDate(this.props.cashier.patient.dobtime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
								{this.props.cashier.patient.identificationNumber}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierLeftPatientRow)
