import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdErrorOutline, MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	addNewItemAction,
	loadPRsAction,
	onModalDisplayAction,
	selectPRAction,
	selectPrItemAction,
	setItemPropertyAction,
	setLoadingAction,
	setPrLocationBranches,
	setPrModalTypeAction,
	setPrSelectedBranch,
	setPrSelectedOrg,
} from "~/redux/action"
import { PR_MIN_STOCK, PR_NEW, PR_UPDATE, SET_PAGE_LOADING, SET_PROPERTY_LABEL } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class PRRightFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRRightFirstRow",
		}

		this.onModifyPrBtn = this.onModifyPrBtn.bind(this)
		this.onDeleteItemBtn = this.onDeleteItemBtn.bind(this)
		this.onMinStockBtn = this.onMinStockBtn.bind(this)
	}

	onMinStockBtn(event) {
		this.props.onModalDisplayAction(PR_MIN_STOCK, true)
	}

	onModifyPrBtn(event) {
		event.preventDefault()
		let selectedItems = this.props.pr.selectedPR.pr.items
			.filter((item) => !item.selected)
			.reduce((obj, item) => {
				obj[item.code] = {
					...item,
				}
				return obj
			}, {})

		// set current pr location
		let branch = this.props.pr.selectedPR.pr.location
		let org = this.props.pr.selectedPR.pr.location.parent
		let orgIndex = this.props.pr.location.orgs.findIndex((loc) => loc.id === org.id)
		let branches = this.props.pr.location.orgs[orgIndex].locations.sort((a, b) =>
			Utils.sort(a.displayName, b.displayName)
		)
		let branchIndex = branches.findIndex((loc) => loc.id === branch.id)
		this.props.setPrSelectedOrg(orgIndex, this.props.pr.location.orgs[orgIndex])
		this.props.setPrLocationBranches(branches)
		this.props.setPrSelectedBranch(branchIndex, branches[branchIndex])
		//////////////////////

		this.props.addNewItemAction(selectedItems)
		this.props.setItemPropertyAction(SET_PROPERTY_LABEL, this.props.pr.selectedPR.pr.label)
		this.props.onModalDisplayAction(PR_NEW, true)
		this.props.setPrModalTypeAction(PR_UPDATE)
	}

	onDeleteItemBtn(event) {
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.deletePrItem}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.pr.selectedPR.pr.id,
				items: [
					{
						id: this.props.pr.selectedPrItem.item.id,
					},
				],
			},
		}

		let callback = (res) => {
			let orders = []
			if (res.data.orders.length === 0) {
				orders = this.props.pr.prs.filter((order) => order.id !== this.props.pr.selectedPR.pr.id)
				let filteredOrders = orders.filter((order) => order.id.includes(this.props.pr.searchNum))
				this.props.loadPRsAction(orders, filteredOrders)
				this.props.selectPRAction(-1, null)
			} else {
				orders = this.props.pr.prs.map((order) => {
					let items = order.items.filter((item) => item.id !== this.props.pr.selectedPrItem.item.id)
					return Object.assign({}, order, {
						items: items,
					})
				})
				this.props.loadPRsAction(orders, orders)
				this.props.selectPRAction(
					this.props.pr.selectedPR.index,
					this.props.pr.filtered[this.props.pr.selectedPR.index]
				)
			}
			this.props.selectPrItemAction(-1, null)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row id={this.state.componentName} className="flex-self-center">
				<Col sm="auto">
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
						<Button
							variant={ResourceAssistance.Button.variant.warning}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.pr.type.length === 0}
							onClick={this.onMinStockBtn}
						>
							<MdErrorOutline size={ResourceAssistance.ReactIcon.size} />
							<span>{translate(ResourceAssistance.Message.minStock)}</span>
						</Button>
					)}
				</Col>
				<Col sm="auto">
					<div className="vertical"></div>
				</Col>
				<Col sm="auto">
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.pr.selectedPR.index === -1 || this.props.isAllApproved()}
							onClick={this.onModifyPrBtn}
						>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							<span>{translate(ResourceAssistance.Message.add)}</span>
						</Button>
					)}
				</Col>
				<Col sm="auto">
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.pr.selectedPR.index === -1 || this.props.isAllApproved()}
							onClick={this.onModifyPrBtn}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							<span>{translate(ResourceAssistance.Message.edit)}</span>
						</Button>
					)}
				</Col>
				<Col sm="auto">
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
						<Button
							variant={ResourceAssistance.Button.variant.red}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.pr.selectedPR.index === -1 || this.props.pr.selectedPrItem.index === -1}
							onClick={this.onDeleteItemBtn}
						>
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
							<span>{translate(ResourceAssistance.Message.delete)}</span>
						</Button>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.login,
		pr: state.purchaseRequest,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			addNewItemAction: addNewItemAction,
			loadPRsAction,
			onModalDisplayAction,
			selectPRAction,
			selectPrItemAction,
			setItemPropertyAction,
			setPrModalTypeAction,
			setLoadingAction,
			setPrSelectedOrg,
			setPrSelectedBranch,
			setPrLocationBranches,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PRRightFirstRow)
