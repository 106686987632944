import { combineReducers } from "redux"
import typeReducer from "./typeReducer"
import locationReducer from "./locationReducer"
import itemConfigReducer from "./itemConfigReducer"
import userConfigReducer from "./userConfigReducer"
import serviceConfigReducer from "./serviceConfigReducer"
import customDefaultConfigReducer from "./customDefaultConfigReducer"
import ConfigICDReducer from "./configICDReducer"
import ConfigLaboratoryReducer from "./configLaboratory"

export default combineReducers({
	type: typeReducer,
	location: locationReducer,
	itemConfig: itemConfigReducer,
	userConfig: userConfigReducer,
	serviceConfig: serviceConfigReducer,
	customDefaultConfig: customDefaultConfigReducer,
	configICD: ConfigICDReducer,
	configLaboratory: ConfigLaboratoryReducer,
})
