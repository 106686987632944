import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import DatePicker from "~/view/component/date_picker/DatePicker"

class RCLeftFifthRow extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.selectedRc) !== JSON.stringify(this.props.selectedRc)) {
		}
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col md={2}>
					<FormLabel>{translate(ResourceAssistance.Message.approver)}</FormLabel>
				</Col>
				<Col>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						typ4={ResourceAssistance.FormControl.type.text}
						disabled
						value={
							this.props.selectedRc.rc && this.props.selectedRc.rc.approvalApproved
								? this.props.selectedRc.rc.approval.firstName + " " + this.props.selectedRc.rc.approval.lastName
								: ""
						}
					/>
				</Col>
				<Col>
					{this.props.selectedRc.rc && this.props.selectedRc.rc.approvalApproved ? (
						<DatePicker startDate={new Date(this.props.selectedRc.rc.approvedDate)} disabled />
					) : (
						<FormControl
							size={ResourceAssistance.FormControl.size.sm}
							type={ResourceAssistance.FormControl.type.text}
							disabled
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	selectedRc: state.receive.selectedRc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCLeftFifthRow)
