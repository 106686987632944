import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import io from "socket.io-client"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setLabModalRegisterAddress,
	setLabModalRegisterDOB,
	setLabModalRegisterFirstName,
	setLabModalRegisterGender,
	setLabModalRegisterIdentificationNumber,
	setLabModalRegisterLastName,
	setLabModalRegisterNameTitle,
	setLabModalRegisterTel,
	setLoadingAction,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

const nameTitles = [
	{ displayName: "น.ส." },
	{ displayName: "นาง" },
	{ displayName: "นาย" },
	{ displayName: "ด.ญ." },
	{ displayName: "ด.ช." },
	{ displayName: "Mr." },
	{ displayName: "Mrs." },
	{ displayName: "Ms." },
]
const genders = [{ displayName: "ชาย" }, { displayName: "หญิง" }]

class CLModalRegisterPatient extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			socket: null,
		}
		this.onNameTitle = this.onNameTitle.bind(this)
		this.onFirstName = this.onFirstName.bind(this)
		this.onLastName = this.onLastName.bind(this)
		this.onIdentificationNumber = this.onIdentificationNumber.bind(this)
		this.onGender = this.onGender.bind(this)
		this.onTel = this.onTel.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onDOB = this.onDOB.bind(this)
	}

	componentDidMount() {
		if (this.props.lab.selectedPatient.index !== -1) {
			const patient = this.props.lab.selectedPatient.patient
			const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, patient.title))
			if (titleIdx > -1) {
				let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.title)
				titleHtml.selectedIndex = titleIdx + 1
				this.props.setLabModalRegisterNameTitle(nameTitles[titleIdx].displayName)
			}

			const genderIdx = genders.findIndex((gender) => _.isEqual(gender.displayName, patient.gender))
			if (genderIdx > -1) {
				let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.gender)
				genderHtml.selectedIndex = genderIdx + 1
				this.props.setLabModalRegisterGender(genders[genderIdx].displayName)
			}

			this.props.setLabModalRegisterFirstName(patient.firstName)
			this.props.setLabModalRegisterLastName(patient.lastName)
			this.props.setLabModalRegisterIdentificationNumber(patient.identificationNumber)
			this.props.setLabModalRegisterDOB(patient.dobtime)
			this.props.setLabModalRegisterTel(patient.tel)
			this.props.setLabModalRegisterAddress(patient.address)
		} else {
			this.connectSMCReader()
		}
	}

	componentWillUnmount() {
		if (this.state.socket) {
			this.state.socket.disconnect()
		}
		this.reset()
	}

	reset() {
		this.resetTitle()
		this.resetGender()
		this.props.setLabModalRegisterFirstName("")
		this.props.setLabModalRegisterLastName("")
		this.props.setLabModalRegisterIdentificationNumber("")
		this.props.setLabModalRegisterTel("")
		this.props.setLabModalRegisterAddress("")
		this.props.setLabModalRegisterDOB(Utils.generateDate().getTime())
	}

	resetTitle() {
		this.props.setLabModalRegisterNameTitle("")
		let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.title)
		titleHtml.selectedIndex = 0
	}

	resetGender() {
		this.props.setLabModalRegisterGender("")
		let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.gender)
		genderHtml.selectedIndex = 0
	}

	connectSMCReader() {
		const socket = io.connect("http://127.0.0.1:9898")
		this.setState({ socket: socket })
		socket.on("connect_error", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Hospitel.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect_failed", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Message.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect", function () {
			socket.emit("set-query", {
				query: [
					"cid",
					"name",
					// 'nameEn',
					"dob",
					"gender",
					// 'issuer',
					// 'issueDate',
					// 'expireDate',
					"address",
					// "photo",
					// 'nhso',
				],
			})
		})

		socket.on("smc-inserted", (data) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
			this.reset()
		})
		socket.on("smc-incorrect", (data) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		})
		socket.on("smc-error", (data) => {
			//SMCReader will send this error and restart itself
			//and resent correct data, so we dont have to handle
			//this.
		})

		socket.on("smc-data", (res) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
			if (res.data.name && res.data.name.prefix) {
				const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, res.data.name.prefix))
				if (titleIdx > -1) {
					let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.title)
					titleHtml.selectedIndex = titleIdx + 1
					this.props.setLabModalRegisterNameTitle(nameTitles[titleIdx].displayName)
				}
			}

			if (res.data.gender) {
				let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalRegister.gender)
				genderHtml.selectedIndex = res.data.gender
				this.props.setLabModalRegisterGender(genders[Utils.BigNumber(res.data.gender).minus(1).toNumber()].displayName)
			}

			this.props.setLabModalRegisterFirstName(res.data.name.firstname || "")
			this.props.setLabModalRegisterLastName(res.data.name.lastname || "")
			this.props.setLabModalRegisterIdentificationNumber(res.data.cid || "")
			this.props.setLabModalRegisterAddress(res.data.address.full || "")
			this.props.setLabModalRegisterDOB(
				res.data.dob ? Utils.generateDateByFormat(res.data.dob, "yyyy-mm-dd").getTime() : Utils.generateDate().getTime()
			)
		})

		socket.on("smc-removed", (data) => {})
	}

	onNameTitle(event) {
		this.props.setLabModalRegisterNameTitle(nameTitles[event.target.value].displayName)
	}

	onFirstName(event) {
		if (typeof event.target.value === "string") {
			this.props.setLabModalRegisterFirstName(event.target.value.trim())
		} else {
			this.props.setLabModalRegisterFirstName(event.target.value)
		}
	}

	onLastName(event) {
		if (typeof event.target.value === "string") {
			this.props.setLabModalRegisterLastName(event.target.value.trim())
		} else {
			this.props.setLabModalRegisterLastName(event.target.value)
		}
	}

	onIdentificationNumber(event) {
		if (typeof event.target.value === "string") {
			this.props.setLabModalRegisterIdentificationNumber(event.target.value.trim())
		} else {
			this.props.setLabModalRegisterIdentificationNumber(event.target.value)
		}
	}

	onGender(event) {
		this.props.setLabModalRegisterGender(genders[event.target.value].displayName)
	}

	onDOB(date) {
		this.props.setLabModalRegisterDOB(date.getTime())
	}

	onTel(event) {
		this.props.setLabModalRegisterTel(event.target.value)
	}

	onAddress(event) {
		this.props.setLabModalRegisterAddress(event.target.value)
	}

	render() {
		return (
			<Container fluid>
				<Row noGutters>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.title)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITEL.lab.modalRegister.title}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onNameTitle}
							>
								{Utils.renderOptions(nameTitles)}
							</FormControl>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.firstName)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								value={this.props.lab.modalRegister.firstName}
								onChange={this.onFirstName}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.lastName)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								value={this.props.lab.modalRegister.lastName}
								onChange={this.onLastName}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.gender)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITEL.lab.modalRegister.gender}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onGender}
							>
								{Utils.renderOptions(genders)}
							</FormControl>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.identification)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								value={this.props.lab.modalRegister.identificationNumber}
								onChange={this.onIdentificationNumber}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.dateOfBirth)}</InputGroup.Text>
							</InputGroup.Prepend>

							<DatePicker
								inputClassName={ResourceAssistance.ID.HOSPITEL.lab.modalRegister.dob}
								maximumDate={Utils.generateDate(0, 0, -1)}
								size={ResourceAssistance.FormControl.size.lg}
								isShowTime={false}
								startDate={Utils.generateDateFromLong(this.props.lab.modalRegister.dob)}
								locale={{ yearOffSet: 543 }}
								onChange={this.onDOB}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.age)}</InputGroup.Text>
							</InputGroup.Prepend>
							<InputGroup.Append>
								<InputGroup.Text>{Utils.calculateAge(this.props.lab.modalRegister.dob)}</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.tel)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								type={ResourceAssistance.FormControl.type.tel}
								value={this.props.lab.modalRegister.tel}
								onChange={this.onTel}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.address)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={3}
								value={this.props.lab.modalRegister.address}
								onChange={this.onAddress}
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLabModalRegisterNameTitle,
			setLabModalRegisterFirstName,
			setLabModalRegisterLastName,
			setLabModalRegisterIdentificationNumber,
			setLabModalRegisterGender,
			setLabModalRegisterTel,
			setLabModalRegisterAddress,
			setLabModalRegisterDOB,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CLModalRegisterPatient))
