import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalMedicalSupplyDetails from "./HospitelModalMedicalSupplyDetails"

class HospitelModalMedicalSupply extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalMedicalSupply",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEmpty(this.props.hospitel.modalNurseOrder.pendingItemTable.original)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveNurseOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.hospitel.selectedHospitel.hospitel.admission.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				nurseOrders: this.props.hospitel.modalNurseOrder.pendingItemTable.original.map((order) => ({
					code: order.code,
					fsCode: order.fsCode,
					description: order.displayName,
					startDateTime: order.startDateTime,
					endDateTime: order.endDateTime,
					pricePerUnit: order.sellPricePerUnit,
					qtyPerDay: order.qtyPerDay,
					notes: Utils.trim(order.notes),
					categoryName: order.categoryName,
					categoryNum: order.categoryNum,
					healthcarePlan: order.healthcarePlan,
				})),
			},
		}

		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge style={{ wordWrap: "break-word" }}>{translate(ResourceAssistance.Message.medicalSupply)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalMedicalSupplyDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isNurseOrderDisplay,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalMedicalSupply))
