import { Descriptions } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class HospitalModalPatientClinicalRecordStatus extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.status)}</legend>
						<Descriptions
							size={"small"}
							style={{ flex: "unset", display: "unset" }}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.arrival)} span={1}>
								{this.props.patient.clinicalRecord.arrival}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.triage)} span={3}>
								{this.props.patient.clinicalRecord.triage}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.isolationPrecaution)} span={2}>
								{this.props.patient.clinicalRecord.isolationPrecaution.replaceAll("\n", ", ")}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.fallRisk)} span={2}>
								{this.props.patient.clinicalRecord.fallRisk}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

HospitalModalPatientClinicalRecordStatus.propTypes = {
	patient: propTypes.object,
}

HospitalModalPatientClinicalRecordStatus.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitalModalPatientClinicalRecordStatus
