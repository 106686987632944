import { AppstoreOutlined, NotificationOutlined, FieldTimeOutlined } from "@ant-design/icons"
import { Menu } from "antd"
import React from "react"
import { Button, Col, Form, Navbar } from "react-bootstrap"
import { injectIntl, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { LOCALES } from "~/i18n/locale"
import { onModalDisplayAction, resetAction } from "~/redux/action"
import { changeLocale } from "~/redux/action/actions"
import { store } from "~/redux/Store"
import { SET_NOTIFICATION_DISPLAY, SET_TIMELINE_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import Logout from "./header/Logout"

const PMG_CARE = "PMGCare"

const EItemKey = {
	NOTIFICATION: "NOTIFICATION",
	TIMELINE: "TIMELINE",
}

const items = [
	{
		key: EItemKey.NOTIFICATION,
		icon: <NotificationOutlined />,
		label: translate(ResourceAssistance.Message.notification),
		// disabled: true,
	},
	{
		key: EItemKey.TIMELINE,
		icon: <FieldTimeOutlined />,
		label: translate(ResourceAssistance.Message.timeline),
	},
]

// export default class Header extends Component {
export default injectIntl(function Header(props) {
	const dispatch = useDispatch()
	const intl = useIntl()
	const user = useSelector((state) => state.login.user)
	const common = useSelector((state) => state.common)

	const onMenu = (e) => {
		switch (e.key) {
			case EItemKey.NOTIFICATION:
				dispatch(onModalDisplayAction(SET_NOTIFICATION_DISPLAY, true))
				break
			case EItemKey.TIMELINE:
				dispatch(onModalDisplayAction(SET_TIMELINE_DISPLAY, true))
				break
			default:
				break
		}
	}

	// render() {
	return (
		<>
			<header>
				<Navbar bg="dark" variant="dark">
					{/* <Navbar.Brand href={ResourceAssistance.Path.home}> */}
					{props.history.location.pathname !== ResourceAssistance.Path.login && (
						<Menu
							style={{
								maxWidth: "100px",
								backgroundColor: "inherit",
								lineHeight: "14px",
								marginLeft: "-20px",
								marginRight: "-10px",
							}}
							selectable={false}
							overflowedIndicator={<AppstoreOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size_md }} />}
							mode="horizontal"
							theme="dark"
							items={items}
							onClick={onMenu}
						/>
					)}
					<Navbar.Brand
						style={{ marginRight: "5rem" }}
						title={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : []) ||
							props.history.location.pathname === ResourceAssistance.Path.login
								? common.header
								: ResourceAssistance.Message.stockManagement.text
						}
						onClick={() => {
							const unSubscribe = store.subscribe(() => {})
							// document.title = props.intl.formatMessage({
							// id: ResourceAssistance.Message.purchaseRequest,
							// })
							if (Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : [])) {
								document.title = PMG_CARE
							} else {
								document.title = intl.formatMessage({ id: ResourceAssistance.Message.stockManager })
							}
							props.history.push(ResourceAssistance.Path.home)
							store.dispatch(resetAction())
							unSubscribe()
							Utils.notification()
						}}
					>
						{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, user.roles ? user.roles : []) ||
						props.history.location.pathname === ResourceAssistance.Path.login
							? common.header
							: ResourceAssistance.Message.stockManagement.text}
					</Navbar.Brand>
					<Form
						style={{
							flexGrow: 1,
							display: "flex",
							flexWrap: "nowrap",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
						}}
					>
						<Col md="auto">
							<Navbar.Brand style={{ marginRight: 0, verticalAlign: "bottom" }} title={user.displayName}>
								{user.displayName}
							</Navbar.Brand>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.info}
								onClick={() => dispatch(changeLocale(LOCALES.ENGLISH))}
							>
								{ResourceAssistance.Message.english}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.info}
								onClick={() => dispatch(changeLocale(LOCALES.THAI))}
							>
								{translate(ResourceAssistance.Message.thai)}
							</Button>
						</Col>
						{props.history.location.pathname !== ResourceAssistance.Path.login && (
							<Col sm="auto">
								<Button href={ResourceAssistance.Path.login} onClick={() => Logout(props)}>
									{translate(ResourceAssistance.Message.logout)}
								</Button>
							</Col>
						)}
					</Form>
				</Navbar>
			</header>
		</>
	)
})
