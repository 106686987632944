import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setOPDVitalSigns, setSelected } from "~/redux/action"
import OutpatientRightVitalSignChart from "./OutpatientRightVitalSignChart"
import OutpatientRightVitalSignTable from "./OutpatientRightVitalSignTable"

class OutpatientRightVitalSign extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightVitalSign",
			graphic: false,
		}

		this.onGraphic = this.onGraphic.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDVitalSigns(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.vitalSigns)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)
		) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDVitalSigns(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.vitalSigns)
			} else {
				this.props.setOPDVitalSigns([])
			}
		}
	}

	onGraphic(event) {
		this.setState({
			graphic: !this.state.graphic,
		})
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={this.props.opd.selectedMedicalRecord.index === -1}
							onClick={this.onGraphic}
						>
							{this.state.graphic ? (
								<AiOutlineTable size={ResourceAssistance.ReactIcon.size} />
							) : (
								<AiOutlineLineChart size={ResourceAssistance.ReactIcon.size} />
							)}
							{this.state.graphic
								? translate(ResourceAssistance.Message.table)
								: translate(ResourceAssistance.Message.graph)}
						</Button>
					</Col>
					<Col />
				</Row>
				{this.state.graphic ? <OutpatientRightVitalSignChart /> : <OutpatientRightVitalSignTable />}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setOPDVitalSigns,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightVitalSign))
