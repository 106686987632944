import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setOPDModalDoctorServicePendingServices, setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalDoctorServiceAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	onDownBtn() {
		window.adjustmentServiceCaller = this
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY, true)
	}

	adjustmentServiceCallback() {
		let selectedService = Object.assign({}, this.props.opd.modalDoctorService.selectedService.service, {
			startDateTime: this.props.hospitel.modalAdjustmentService.startDateTime,
			endDateTime: this.props.hospitel.modalAdjustmentService.isDisableEndDate
				? Utils.generateDateFromLong(
						this.props.hospitel.modalAdjustmentService.startDateTime,
						0,
						0,
						0,
						23,
						59,
						59
				  ).getTime()
				: this.props.hospitel.modalAdjustmentService.endDateTime,
			notes: this.props.hospitel.modalAdjustmentService.notes,
		})
		let services = this.props.opd.modalDoctorService.pendingServiceTable.original
		services.push(selectedService)
		this.props.setOPDModalDoctorServicePendingServices(services)
	}

	onUpBtn() {
		let pendingServices = this.props.opd.modalDoctorService.pendingServiceTable.filtered.filter(
			(each, index) => index !== this.props.opd.modalDoctorService.selectedPendingService.index
		)
		this.props.setOPDModalDoctorServicePendingServices(pendingServices)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.opd.modalDoctorService.selectedService.index === -1}
						onClick={this.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.opd.modalDoctorService.selectedPendingService.index === -1}
						onClick={this.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setOPDModalDoctorServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalDoctorServiceAction)
