import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class TimelinePatient extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions
						style={{ flex: "unset", display: "unset", marginBottom: "1rem" }}
						size={"small"}
						labelStyle={{ fontWeight: "bold" }}
						contentStyle={{ fontStyle: "italic" }}
						column={2}
					>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
							{this.props.timeline.patient.id}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
							{Utils.formatDate(this.props.timeline.patient.dobtime)}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={2}>
							{this.props.timeline.patient.title +
								" " +
								this.props.timeline.patient.firstName +
								" " +
								this.props.timeline.patient.lastName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	timeline: state.menu.timeline,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelinePatient)
