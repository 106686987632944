import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmIItems, setAdmISelectedItem, setLoadingAction } from "~/redux/action"
import { SET_ADM_ITEM_NEW_ITEM_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmIModalNIDetails from "./AdmIModalNIDetails"

class AdmIModalNI extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmIModalNI",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		if (
			!this.props.isDisplay ||
			!this.props.item.selectedItem.item ||
			!this.props.item.modalNewItem.selectedItem.item
		) {
			return false
		}

		let selectedItem = this.props.item.selectedItem.item

		let target = Object.assign({}, this.props.item.selectedItem.item, {
			...this.props.item.selectedItem.item,
			item: {
				id: this.props.item.modalNewItem.selectedItem.item.id,
				displayName: this.props.item.modalNewItem.selectedItem.item.displayName,
			},
			code: this.props.item.modalNewItem.code.trim().toUpperCase(),
			pricePerUnit: this.props.item.modalNewItem.pricePerUnit,
			discount: Utils.BigNumber(this.props.item.modalNewItem.discount).toNumber(),
			minQtyPerOrder: Utils.BigNumber(this.props.item.modalNewItem.qtyPerOrder).toNumber(),
			bonus: Utils.BigNumber(this.props.item.modalNewItem.bonus).toNumber(),
			active: this.props.item.modalNewItem.isActive,
		})

		let targetTypeId = this.props.item.modalNewItem.selectedType.type.id
		let targetFormId = this.props.item.modalNewItem.selectedForm.form.id
		let targetUnitId = this.props.item.modalNewItem.selectedUnit.unit.id
		let targetSupplierId = this.props.item.modalNewItem.selectedSupplier.supplier.id
		let targetTaxId = this.props.item.modalNewItem.selectedTax.tax.id
		let targetDose = this.props.item.modalNewItem.dose.trim().toUpperCase()
		let targetBrand = this.props.item.modalNewItem.brand.trim().toUpperCase()
		let targetMedicationUsage = this.props.item.modalNewItem.medicationUsage.trim().toUpperCase()
		let targetHealthCarePlan = Utils.trim(this.props.item.modalNewItem.healthcarePlan).toUpperCase()
		let targetCategoryNum = this.props.item.modalNewItem.categoryNum

		return (
			_.isEqual(selectedItem, target) &&
			selectedItem.type.id === targetTypeId &&
			selectedItem.form.id === targetFormId &&
			selectedItem.unit.id === targetUnitId &&
			selectedItem.supplier.id === targetSupplierId &&
			selectedItem.tax.id === targetTaxId &&
			selectedItem.dose.trim().toUpperCase() === targetDose &&
			selectedItem.brand.trim().toUpperCase() === targetBrand &&
			selectedItem.medicationUsage.trim().toUpperCase() === targetMedicationUsage &&
			Utils.trim(selectedItem.healthcarePlan).toUpperCase() === targetHealthCarePlan &&
			selectedItem.fsCode.trim().toUpperCase() === this.props.item.modalNewItem.fsCode.trim().toUpperCase() &&
			Utils.BigNumber(selectedItem.sellPricePerUnit).eq(this.props.item.modalNewItem.sellPricePerUnit) &&
			selectedItem.categoryNum === targetCategoryNum
		)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.item.saveItemSupplier}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.item.selectedItem.index === -1 ? 0 : this.props.item.selectedItem.item.id,
				itemId: this.props.item.modalNewItem.selectedItem.item.id,
				code: this.props.item.modalNewItem.code.trim().toUpperCase(),
				formId: this.props.item.modalNewItem.selectedForm.form.id,
				dose: this.props.item.modalNewItem.dose.trim(),
				unitId: this.props.item.modalNewItem.selectedUnit.unit.id,
				supplierId: this.props.item.modalNewItem.selectedSupplier.supplier.id,
				brand: this.props.item.modalNewItem.brand.trim(),
				medicationUsage: Utils.trim(this.props.item.modalNewItem.medicationUsage),
				healthcarePlan: Utils.trim(this.props.item.modalNewItem.healthcarePlan),
				pricePerUnit: this.props.item.modalNewItem.pricePerUnit.trim(),
				discount: this.props.item.modalNewItem.discount,
				taxId: this.props.item.modalNewItem.selectedTax.tax.id,
				minQtyPerOrder: this.props.item.modalNewItem.qtyPerOrder,
				bonus: this.props.item.modalNewItem.bonus,
				active: this.props.item.modalNewItem.isActive,
				userId: this.props.login.user.id,
				fsCode: Utils.trim(this.props.item.modalNewItem.fsCode),
				sellPricePerUnit: this.props.item.modalNewItem.sellPricePerUnit,
				categoryNum: this.props.item.modalNewItem.categoryNum,
			},
		}
		let callback = (res) => {
			this.props.setAdmISelectedItem(-1, null)
			this.props.setAdmIItems(res.data.itemSuppliers)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newItemSupplier)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmIModalNIDetails {...this.props} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmINewItemDisplay,
	login: state.login,
	item: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setAdmIItems,
			setAdmISelectedItem,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AdmIModalNI)
