import { pdf } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineSafetyCertificate } from "react-icons/ai"
import { BiBarcodeReader } from "react-icons/bi"
import { FaCheck } from "react-icons/fa"
import { GoCloudUpload } from "react-icons/go"
import { ImLab, ImPrinter } from "react-icons/im"
import { RiVirusFill } from "react-icons/ri"
import { TiCancel } from "react-icons/ti"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { GDrive } from "~/api/google/GDrive"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLabPatients, setLoadingAction } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_APPROVE_DISPLAY,
	SET_HOSPITEL_LAB_LAB_DISPLAY,
	SET_HOSPITEL_LAB_NEGATIVE_DISPLAY,
	SET_HOSPITEL_LAB_POSITIVE_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import ReportCovid19Document from "~/report/hospitel/lab/document/ReportCovid19Document"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class CLRightActionRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLRightActionRow",
		}

		this.onNew = this.onNew.bind(this)
		this.onPrintLabel = this.onPrintLabel.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onNegative = this.onNegative.bind(this)
		this.onPositive = this.onPositive.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onComplete = this.onComplete.bind(this)
		this.onGoogleDrive = this.onGoogleDrive.bind(this)
	}

	componentDidMount() {
		GDrive.connect()
	}

	componentWillUnmount() {
		GDrive.signOut()
	}

	generateLabData() {
		return PrintableDataFactory.generateCovid19ReportData(
			this.props.lab.selectedPatient.patient,
			this.props.lab.selectedLab.lab
		)
	}

	onNew(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_LAB_DISPLAY, true)
	}

	onPrintLabel(event) {
		let patient = this.props.lab.selectedPatient.patient
		let lab = this.props.lab.selectedLab.lab
		let target = {
			displayLabNumber: true,

			name: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientNum: patient.id,
			labNum: lab.id,
			dob: Utils.formatDate(patient.dobtime),
			ageYear: Utils.calculateAge(patient.dobtime),
			ageMonth: Utils.calculateAgeMonth(patient.dobtime),
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.label, "_blank")
		myWindow.data = target
	}

	onCancel(event) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.lab.cancelLab,
			data: {
				id: this.props.lab.selectedLab.lab.id,
				patientId: this.props.lab.selectedPatient.patient.id,
				userId: this.props.login.user.id,
			},
		}
		this.props.updateLab(params)
	}

	onNegative(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_NEGATIVE_DISPLAY, true)
	}

	onPositive(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_POSITIVE_DISPLAY, true)
	}

	onPrint(event) {
		let myWindow = window.open(ResourceAssistance.Path.Report.lab.covid19Lab, "_blank")
		myWindow.data = this.generateLabData()
	}

	onComplete(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_APPROVE_DISPLAY, true)
	}

	async onGoogleDrive(event) {
		let fileName =
			this.props.lab.selectedPatient.patient.id +
			"_" +
			this.props.lab.selectedLab.lab.id +
			"_" +
			this.props.lab.selectedPatient.patient.firstName +
			"_" +
			this.props.lab.selectedPatient.patient.lastName

		let doc = <ReportCovid19Document data={this.generateLabData()} isPdfViewer={false} />
		const asPdf = pdf([])
		asPdf.updateContainer(doc)
		const blob = await asPdf.toBlob()

		try {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
			await GDrive.signIn().then(() => {
				GDrive.uploadPDF(
					fileName,
					blob,
					async (f) => {
						const gDriveURL = await GDrive.generatePublicUrl(f.id)
						let params = {
							method: "POST",
							url: ResourceAssistance.Url.lab.updateLabUrl,
							data: {
								id: this.props.lab.selectedLab.lab.id,
								patientId: this.props.lab.selectedPatient.patient.id,
								url: gDriveURL,
							},
						}
						this.props.updateLab(params)
					},
					(folderName) => {
						alert(`Cannot find folder: "${folderName}"`)
					},
					(fileName) => {
						alert(`File: "${fileName}" exist already!`)
					}
				)
			})
		} catch (error) {
		} finally {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
	}

	render() {
		return (
			<Row id={this.state.componentName} noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col></Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.CreateLab, this.props.login.user.roles) && (
					<Fragment>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.lab.selectedPatient.index === -1}
								onClick={this.onNew}
							>
								<ImLab size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.info}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.lab.selectedLab.index === -1 || this.props.lab.selectedLab.lab.cancelledBy}
								onClick={this.onPrintLabel}
							>
								<BiBarcodeReader size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Hospitel.printLabel)}
							</Button>
						</Col>
						<Col>
							<div className="vertical"></div>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.red}
								size={ResourceAssistance.Button.size.sm}
								disabled={
									this.props.lab.selectedLab.index === -1 ||
									this.props.lab.selectedLab.lab.cancelledBy ||
									this.props.lab.selectedLab.lab.certifiedBy
								}
								onClick={this.onCancel}
							>
								<TiCancel size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.cancel)}
							</Button>
						</Col>
					</Fragment>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.Technician, this.props.login.user.roles) && (
					<Fragment>
						<Col>
							<div className="vertical"></div>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={
									this.props.lab.selectedLab.index === -1 ||
									this.props.lab.selectedLab.lab.approvedBy ||
									this.props.lab.selectedLab.lab.cancelledBy
								}
								onClick={this.onNegative}
							>
								<AiOutlineSafetyCertificate size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Hospitel.negative)}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.warning}
								size={ResourceAssistance.Button.size.sm}
								disabled={
									this.props.lab.selectedLab.index === -1 ||
									this.props.lab.selectedLab.lab.approvedBy ||
									this.props.lab.selectedLab.lab.cancelledBy
								}
								onClick={this.onPositive}
							>
								<RiVirusFill size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Hospitel.positive)}
							</Button>
						</Col>

						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={
									this.props.lab.selectedLab.index === -1 ||
									!this.props.lab.selectedLab.lab.certifiedBy ||
									this.props.lab.selectedLab.lab.approvedBy
								}
								onClick={this.onComplete}
							>
								<FaCheck size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.complete)}
							</Button>
						</Col>
					</Fragment>
				)}

				<Col>
					<div className="vertical"></div>
				</Col>

				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.lab.selectedLab.index === -1 || !this.props.lab.selectedLab.lab.approvedBy}
						onClick={this.onPrint}
					>
						<ImPrinter size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.print)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.lab.selectedLab.index === -1 || !this.props.lab.selectedLab.lab.approvedBy}
						onClick={this.onGoogleDrive}
					>
						<GoCloudUpload size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.googleDrive)}
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setLabPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLRightActionRow)
