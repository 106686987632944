import { FieldTimeOutlined, NotificationOutlined } from "@ant-design/icons"
import _ from "lodash"
import React, { Fragment } from "react"
import { Item, Menu, theme } from "react-contexify"
import "react-contexify/dist/ReactContexify.css"
import { FaUserInjured } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_TIMELINE_DISPLAY, SET_TIMELINE_PATIENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ContextMenuPatientDetails from "./ContextMenuPatientDetails"

class ContextMenuPatient extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isPatientInfoVisible: false,
			patient: {},
		}

		this.onPatientInfo = this.onPatientInfo.bind(this)
		this.onPatientInfoClose = this.onPatientInfoClose.bind(this)
		this.onNotification = this.onNotification.bind(this)
		this.onTimeline = this.onTimeline.bind(this)
	}

	async getPatientByPID(pid) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.timeline.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				pid: pid,
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.patients)) {
				this.props.setValue(SET_TIMELINE_PATIENT, res.data.patients[0])
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		await axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPatientInfo(e) {
		this.setState({
			isPatientInfoVisible: true,
			patient: e.props.patient,
		})
	}

	onPatientInfoClose(e) {
		this.setState({
			isPatientInfoVisible: false,
		})
	}

	onNotification({ event, props, triggerEvent, data }) {
		Utils.notification(
			this.props.intl.formatMessage({ id: ResourceAssistance.Message.notification }),
			props.patient.notifications,
			"warning"
		)
	}

	async onTimeline(e) {
		if (e.props.pid) {
			await this.getPatientByPID(e.props.pid)
		} else {
			this.props.setValue(SET_TIMELINE_PATIENT, e.props.patient)
		}
		this.props.onModalDisplayAction(SET_TIMELINE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Menu id={ResourceAssistance.ContextMenu.patient} theme={theme.dark} animation={false}>
					{this.props.contextMenuPatient.isDisplayPatientInfo && (
						<Item onClick={this.onPatientInfo}>
							<span>
								<FaUserInjured size={ResourceAssistance.ReactIcon.size} />
							</span>
							<span style={{ marginLeft: "10px" }}>{translate(ResourceAssistance.Hospitel.patientInfo)}</span>
						</Item>
					)}
					<Item onClick={this.onNotification}>
						<span>
							<NotificationOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
						</span>
						<span style={{ marginLeft: "10px" }}>{translate(ResourceAssistance.Message.notification)}</span>
					</Item>
					<Item onClick={this.onTimeline}>
						<span>
							<FieldTimeOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
						</span>
						<span style={{ marginLeft: "10px" }}>{translate(ResourceAssistance.Message.timeline)}</span>
					</Item>
				</Menu>
				<ContextMenuPatientDetails
					isVisible={this.state.isPatientInfoVisible}
					patient={this.state.patient}
					onClose={this.onPatientInfoClose}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	contextMenuPatient: state.contextMenu.patient,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContextMenuPatient))
