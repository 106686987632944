import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setRegModalExpenseEstBudgets,
	setRegModalExpenseEstPendings,
	setSelected,
} from "~/redux/action"
import { SET_REGISTRATION_BUDGET_DISPLAY, SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import { FaRegEdit } from "react-icons/fa"

class RegModalExpenseEstBudgetTable extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentWillUnmount() {
		this.props.setRegModalExpenseEstBudgets([])
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET, -1, null)
	}

	onNew(event) {
		this.props.onModalDisplayAction(SET_REGISTRATION_BUDGET_DISPLAY, true)
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.setSelected(
			SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET,
			rIdx,
			this.props.reg.modalExpenseEst.budgetTable.original[rIdx]
		)
		this.props.onModalDisplayAction(SET_REGISTRATION_BUDGET_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let budgets = this.props.reg.modalExpenseEst.budgetTable.original.filter((each, index) => index !== rIdx)
		this.props.setRegModalExpenseEstBudgets(budgets)
	}

	render() {
		return (
			<Row style={{ minHeight: "250px" }}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.reg.modalExpenseEst.budgetTable}
						isClearHighlight={this.props.reg.modalExpenseEst.selectedBudget.index === -1}
						highlightedRow={this.props.reg.modalExpenseEst.selectedBudget.index}
						extra={
							<Button
								style={{ fontSize: "medium" }}
								variant={ResourceAssistance.Button.variant.link}
								size={ResourceAssistance.Button.size.sm}
								onClick={this.onNew}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setRegModalExpenseEstPendings,
			setRegModalExpenseEstBudgets,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstBudgetTable))
