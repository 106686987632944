import { ResourceAssistance } from "~/i18n"
import { SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS, SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setCustomDefaultFocus = (customDefaults) => {
	return (dispatch, getState) => {
		let filtered = customDefaults
			.filter((each) => {
				if (getState().admin.customDefaultConfig.focus.displayInactive) {
					return each.name.toLowerCase().includes(getState().admin.customDefaultConfig.focus.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.name.toLowerCase().includes(getState().admin.customDefaultConfig.focus.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.name, b.name))

		let colors = []
		if (getState().admin.customDefaultConfig.focus.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})
		dispatch({
			type: SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS,
			payload: {
				original: customDefaults,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setCustomDefaultInterventions = (customDefaults) => {
	return (dispatch, getState) => {
		let filtered = customDefaults
			.filter((each) => {
				if (getState().admin.customDefaultConfig.intervention.displayInactive) {
					return each.name
						.toLowerCase()
						.includes(getState().admin.customDefaultConfig.intervention.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.name.toLowerCase().includes(getState().admin.customDefaultConfig.intervention.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.name, b.name))

		let colors = []
		if (getState().admin.customDefaultConfig.intervention.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})
		dispatch({
			type: SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS,
			payload: {
				original: customDefaults,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}
