import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaPrescription, FaRegEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setCashierAdmissions,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierRightServiceAction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightServiceAction",
		}

		this.onEdit = this.onEdit.bind(this)
		this.onPrescriptionOnly = this.onPrescriptionOnly.bind(this)
		this.onCancelPrescriptionOnly = this.onCancelPrescriptionOnly.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.id)
			) {
				this.saveAdjustment()
			}
		}
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.cashier.doctorPrescriptionOnly)) {
					this.saveDoctorPrescriptionOnly(true)
				}
			}
		}
	}

	saveAdjustment() {
		let selectedBillingStatement = this.props.cashier.selectedService.service
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveBillingStatementAdjustment,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				userId: this.props.login.user.id,
				billingStatements: [
					{
						id: selectedBillingStatement.id,
						adjustments: Utils.BigNumber(this.props.cashier.modalAdjustment.discountPercent).isGreaterThan(0)
							? Utils.BigNumber(selectedBillingStatement.charge)
									.times(this.props.cashier.modalAdjustment.discountPercent)
									.dividedBy(100)
									.toFixed(2)
							: this.props.cashier.modalAdjustment.amount,
						adjustmentsDescription: Utils.trim(this.props.cashier.modalAdjustment.description),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter(
					(each) => each.id !== res.data.admissions[0].id
				)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	saveDoctorPrescriptionOnly(isDoctorPrescriptionOnly) {
		let billingStatements = this.props.cashier.selectedAdmission.admission.billingStatements.filter(
			(bs) =>
				bs.doctorOrder && bs.doctorOrder.id === this.props.cashier.selectedService.service.doctorOrder.id && !bs.billing
		)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveDoctorOrderPrescriptionOnly,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				userId: this.props.login.user.id,
				doctorPrescriptionOnly: isDoctorPrescriptionOnly,
				billingStatements: billingStatements,
				doctorOrders: [
					{
						id: this.props.cashier.selectedService.service.doctorOrder.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter(
					(each) => each.id !== res.data.admissions[0].id
				)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isEditDisabled() {
		return this.props.cashier.selectedService.service.billing
	}

	isDoctorPrescriptionOnlyDisabled() {
		return (
			this.props.cashier.selectedAdmission.index === -1 ||
			this.props.cashier.selectedService.index === -1 ||
			this.props.cashier.selectedAdmission.admission.dischargedBy ||
			this.isEditDisabled() ||
			!this.props.cashier.selectedService.service.doctorOrder ||
			!this.props.cashier.selectedService.service.doctorOrder.code ||
			this.props.cashier.selectedService.service.doctorOrder.prescriptionOnly
		)
	}

	isCancelDoctorPrescriptionOnlyDisabled() {
		return (
			this.props.cashier.selectedAdmission.index === -1 ||
			this.props.cashier.selectedService.index === -1 ||
			this.props.cashier.selectedAdmission.admission.dischargedBy ||
			this.isEditDisabled() ||
			!this.props.cashier.selectedService.service.doctorOrder ||
			!this.props.cashier.selectedService.service.doctorOrder.code ||
			!this.props.cashier.selectedService.service.doctorOrder.prescriptionOnly
		)
	}

	onEdit(event) {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.id)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY, false)
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onPrescriptionOnly() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.cashier.doctorPrescriptionOnly)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			translate(ResourceAssistance.Warning.theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling)
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onCancelPrescriptionOnly() {
		this.saveDoctorPrescriptionOnly(false)
	}

	render() {
		return (
			<Row id={this.state.componentName} style={{ justifyContent: "center" }}>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.cashier.selectedAdmission.index === -1 ||
							this.props.cashier.selectedService.index === -1 ||
							this.props.cashier.selectedAdmission.admission.dischargedBy ||
							this.isEditDisabled()
						}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				{!this.isCancelDoctorPrescriptionOnlyDisabled() ? (
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.red}
							disabled={this.isCancelDoctorPrescriptionOnlyDisabled()}
							onClick={this.onCancelPrescriptionOnly}
						>
							<FaPrescription size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.cancelDoctorPrescriptionOnly)}
						</Button>
					</Col>
				) : (
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.warning}
							disabled={this.isDoctorPrescriptionOnlyDisabled()}
							onClick={this.onPrescriptionOnly}
						>
							<FaPrescription size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.doctorPrescriptionOnly)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCashierAdmissions,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierRightServiceAction))
