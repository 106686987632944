import { SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO } from "~/redux/type"

const init = {
	patient: {
		isDisplayPatientInfo: true,
	},
}

const contextMenuReducer = (state = init, action) => {
	switch (action.type) {
		case SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					isDisplayPatientInfo: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default contextMenuReducer
