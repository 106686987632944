import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import {
	setHospitelModalDoctorOrderItems,
	setHospitelModalDoctorOrderPendingItems,
	setLoadingAction,
	setSelected,
} from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalDoctorOrderItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.hospitel.modalDoctorOrder.searchDescription !==
				this.props.hospitel.modalDoctorOrder.searchDescription ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedType, this.props.hospitel.modalDoctorOrder.selectedType)
		) {
			this.props.setHospitelModalDoctorOrderItems(this.props.hospitel.modalDoctorOrder.itemTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM,
			index,
			this.props.hospitel.modalDoctorOrder.itemTable.filtered[index]
		)
	}

	onUpBtn() {
		let pendingItems = this.props.hospitel.modalDoctorOrder.pendingItemTable.filtered.filter(
			(each) => each.displayName !== this.props.hospitel.modalDoctorOrder.selectedPendingItem.item.displayName
		)
		this.props.setHospitelModalDoctorOrderPendingItems(pendingItems)
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col id={ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.tableItem}>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectItem}
							data={this.props.hospitel.modalDoctorOrder.itemTable}
							isClearHighlight={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
						/>
					</Col>
					{this.props.hospitel.modalDoctorOrder.itemTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalDoctorOrder.selectedPendingItem.index === -1}
							onClick={this.onUpBtn}
						>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setHospitelModalDoctorOrderItems,
			setHospitelModalDoctorOrderPendingItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderItem))
