import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import RCLeftFifthRow from "./RCLeftFifthRow"
import RCLeftFirstRow from "./RCLeftFirstRow"
import RCLeftFourthRow from "./RCLeftFourthRow"
import RCLeftSecondRow from "./RCLeftSecondRow"
import RCLeftThirdRow from "./RCLeftThirdRow"

class RCLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<RCLeftFirstRow {...this.props} />
				<RCLeftSecondRow {...this.props} />
				<RCLeftThirdRow {...this.props} />
				<RCLeftFourthRow {...this.props} />
				<RCLeftFifthRow {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCLeft)
