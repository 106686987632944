import { combineReducers } from "redux"
import adminReducer from "./admin"
import inventoryReducer from "./inventoryReducer"
import { languageReducer } from "./languageReducer"
import LoginReducer from "./loginReducer"
import modalReducer from "./modalReducer"
import purchaseRequestReducer from "./purchaseRequester"
import purchaseOrderReducer from "./purchaseOrderReducer"
import commonReducer from "./commonReducer"
import receiveReducer from "./receiveReducer"
import transferReducer from "./transferReducer"
import reportReducer from "./report"
import { RESET } from "../type"
import hospitelReducers from "./hospitel"
import hospitalReducers from "./hospital"
import componentReducers from "./modal"
import menuReducer from "./menu"
import contextMenuReducer from "./context-menu"

const appReducer = combineReducers({
	common: commonReducer,
	login: LoginReducer,
	language: languageReducer,
	modal: modalReducer,
	menu: menuReducer,
	contextMenu: contextMenuReducer,
	component: componentReducers,
	report: reportReducer,
	admin: adminReducer,
	inventory: inventoryReducer,
	purchaseRequest: purchaseRequestReducer,
	purchaseOrder: purchaseOrderReducer,
	receive: receiveReducer,
	transfer: transferReducer,
	hospitel: hospitelReducers,
	hospital: hospitalReducers,
})

const rootReducer = (state, action) => {
	switch (action.type) {
		case RESET:
			return appReducer(
				{
					login: {
						...state.login,
					},
					language: {
						...state.language,
					},
				},
				action
			)

		default:
			return appReducer(state, action)
	}
}

export default rootReducer
