import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelModalNurseOrderPendingItems } from "~/redux/action"
import { SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY } from "~/redux/type"
import HospitelModalAdjustmentDetails from "./HospitelModalAdjustmentDetails"

class HospitelModalAdjustment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalAdjustment",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		if (window.adjustmentItemCaller) {
			window.adjustmentItemCaller.adjustmentItemCallback()
			window.adjustmentItemCaller = null
		} else {
			let selectedItem = Object.assign({}, this.props.hospitel.modalNurseOrder.selectedItem.item, {
				notes: this.props.hospitel.modalAdjustment.notes,
				qtyPerDay: this.props.hospitel.modalAdjustment.qtyPerDay,
				startDateTime: this.props.hospitel.modalAdjustment.startDateTime,
				endDateTime: this.props.hospitel.modalAdjustment.isDisableEndDate
					? null
					: this.props.hospitel.modalAdjustment.endDateTime,
				categoryName: this.props.hospitel.modalNurseOrder.selectedType.type.description,
			})
			let items = this.props.hospitel.modalNurseOrder.pendingItemTable.original
			items.push(selectedItem)

			this.props.setHospitelModalNurseOrderPendingItems(items)
		}
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>
								{this.props.hospitel.modalNurseOrder.selectedItem.item
									? this.props.hospitel.modalNurseOrder.selectedItem.item.displayName
									: ""}
							</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalAdjustmentDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.FormControl.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isAdjustmentDisplay,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelModalNurseOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalAdjustment))
