import _ from "lodash"
import propTypes from "prop-types"
import React, { Component } from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import "./date_picker.css"

// import th from "date-fns/locale/th"
// registerLocale("th", th)

class DatePicker extends Component {
	rangeType = [{ displayName: "Day" }]

	constructor(props) {
		super(props)

		this.onRange = this.onRange.bind(this)
		this.onRangeType = this.onRangeType.bind(this)
		this.state = {
			componentName: "DatePicker",
			startDate: this.getOffSetDate(this.props.startDate),
			range: 0,
			rangeType: 0,
		}
	}

	componentDidMount() {
		this.validDate()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.startDate, this.props.startDate)) {
			this.setState({
				startDate: this.getOffSetDate(this.props.startDate),
				range: this.calculateRange(this.getOffSetDate(this.props.startDate)),
			})
		}

		if (!_.isEqual(prevProps.rangeStartDate, this.props.rangeStartDate)) {
			this.setState({
				range: this.calculateRange(this.state.startDate),
			})
		}

		if (!_.isEqual(prevState.startDate, this.state.startDate)) {
			this.validDate()
		}
	}

	componentWillUnmount() {
		this.setState({
			range: 0,
			rangeType: 0,
		})
	}

	getOffSetDate(date) {
		if (!date) {
			return null
		}
		return new Date(
			date.getFullYear() + this.props.locale.yearOffSet,
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	}

	calculateRange(date) {
		switch (this.state.rangeType) {
			case 0:
				let days = Utils.calculateDaysBetween(this.getOffSetDate(this.props.rangeStartDate), date)
				return days

			default:
				break
		}
	}

	validDate() {
		if (this.props.inputClassName) {
			let inputDOM = document.getElementsByClassName(this.props.inputClassName)
			if (inputDOM.length > 0) {
				if (
					this.props.minimumDate &&
					this.state.startDate.getTime() < this.getOffSetDate(this.props.minimumDate).getTime()
				) {
					inputDOM[0].style.borderColor = "red"
				} else {
					inputDOM[0].style.borderColor = ""
				}

				if (
					this.props.maximumDate &&
					this.state.startDate.getTime() > this.getOffSetDate(this.props.maximumDate).getTime()
				) {
					inputDOM[0].style.borderColor = "red"
				} else {
					inputDOM[0].style.borderColor = ""
				}
			}
		}
	}

	onRange(event) {
		this.setState({
			range: event.target.value ? Utils.BigNumber(event.target.value).toNumber() : 0,
			startDate: event.target.value
				? Utils.generateDateFromLong(
						this.getOffSetDate(this.props.rangeStartDate).getTime(),
						0,
						0,
						Utils.BigNumber(event.target.value).toNumber(),
						0,
						0,
						0
				  )
				: this.state.startDate,
		})
	}

	onRangeType(event) {
		this.setState({
			rangeType: event.target.value,
		})
	}

	render() {
		// const CustomInput = forwardRef(
		// 	(
		// 		// { value, onClick, className, onChange, onFocus }
		// 		{ ...props },
		// 		ref
		// 	) => {
		// 		return (
		// 			<input
		// 				// id={this.props.id}
		// 				// type="text"
		// 				// className={props.className}
		// 				// onClick={props.onClick}
		// 				// onChange={props.onChange}
		// 				// onFocus={props.onFocus}
		// 				// ref={ref}
		// 				// onBlur={props.onBlur}
		// 				// onKeyDown={props.onKeyDown}
		// 				// value={props.value}
		// 				// autoComplete={false}
		// 				{...props}
		// 			/>
		// 		)
		// 	}
		// )
		return (
			<div id={this.state.componentName} className={this.props.className}>
				<ReactDatePicker
					// customInput={<CustomInput />}
					className={this.props.inputClassName}
					disabled={this.props.disabled}
					minDate={this.props.minDate}
					showTimeSelect={_.isEmpty(this.props.includeTimes) ? false : this.props.isShowTime}
					includeTimes={this.props.includeTimes}
					timeIntervals={this.props.timeIntervals}
					maxDate={this.props.maxDate}
					// showDisabledMonthNavigation={this.props.showDisabledMonthNavigation}
					selected={this.state.startDate}
					openToDate={this.state.startDate}
					// startDate={this.state.startDate}
					// endDate={null}
					// selectsRange={this.props.selectsRange}
					// inline={this.props.inline}
					onChange={(localeDate) => {
						if (!localeDate) {
							return
						}
						this.setState({
							startDate: localeDate,
						})

						if (this.props.rangeStartDate) {
							this.setState({
								range: this.calculateRange(localeDate),
							})
						}

						if (this.props.onChange) {
							let date = new Date(
								localeDate.getFullYear() - this.props.locale.yearOffSet,
								localeDate.getMonth(),
								localeDate.getDate(),
								localeDate.getHours(),
								localeDate.getMinutes(),
								localeDate.getSeconds()
							)
							this.props.onChange(date)
						}
					}}
					// showTimeSelect={this.props.isShowTime}
					// timeIntervals={1}
					// timeCaption="Time"
					dateFormat={this.props.isShowTime ? this.props.dateFormat : "dd-MM-yyyy"}
					showTimeInput={_.isEmpty(this.props.includeTimes) ? this.props.isShowTime : false}
					timeFormat="HH:mm"
					readOnly={this.props.readOnly}
					inline={this.props.inline}
					todayButton={translate(ResourceAssistance.Message.today)}
					rangeStartDate={this.props.rangeStartDate}
					shouldCloseOnSelect={this.props.shouldCloseOnSelect}
				>
					{this.props.isShowDateRange && this.props.rangeStartDate && (
						<Container>
							<Row>
								<Col>
									<FormControl
										required
										autoFocus
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										step={1}
										min={0}
										value={this.state.range}
										onChange={this.onRange}
									/>
								</Col>
								<Col>
									<FormControl
										required
										as={ResourceAssistance.FormControl.as.select}
										size={ResourceAssistance.FormControl.size.sm}
										onChange={this.onRangeType}
									>
										{Utils.renderOptions(this.rangeType, false)}
									</FormControl>
								</Col>
							</Row>
						</Container>
					)}
				</ReactDatePicker>
			</div>
		)
	}
}

DatePicker.propTypes = {
	id: propTypes.string,
	className: propTypes.string,
	inputClassName: propTypes.string,
	disabled: propTypes.bool,
	dateFormat: propTypes.string,
	isShowTime: propTypes.bool,
	isShowDateRange: propTypes.bool,
	locale: propTypes.object,
	onChange: propTypes.func,
	readOnly: propTypes.bool,
	startDate: propTypes.object,
	minimumDate: propTypes.object,
	maximumDate: propTypes.object,
	showDisabledMonthNavigation: propTypes.bool,
	rangeStartDate: propTypes.object,
	shouldCloseOnSelect: propTypes.bool,
}

DatePicker.defaultProps = {
	startDate: new Date(),
	isShowTime: true,
	isShowDateRange: false,
	dateFormat: "dd-MM-yyyy h:mm aa",
	locale: {
		yearOffSet: 0,
	},
	showDisabledMonthNavigation: false,
}

export default DatePicker
