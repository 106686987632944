import {
	SET_REPORT_INV_BY_LOCATION_BRANCHES,
	SET_REPORT_INV_BY_LOCATION_CODE,
	SET_REPORT_INV_BY_LOCATION_ORGS,
	SET_REPORT_INV_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_INV_BY_LOCATION_SELECTED_ORG,
	SET_REPORT_INV_BY_LOCATION_DESCRIPTION,
	SET_REPORT_INV_BY_LOCATION_VIEW_QTY,
	SET_REPORT_INV_BY_LOCATION_VIEW_VALUE,
} from "~/redux/type"

export const setReportInvByLocationOrgs = (orgs) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_REPORT_INV_BY_LOCATION_ORGS,
			payload: {
				orgs: orgs,
			},
		})
		return Promise.resolve()
	}
}

export const setReportInvByLocationSelectedOrg = (index, org) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_SELECTED_ORG,
		payload: {
			index: index,
			org: org,
		},
	}
}

export const setReportInvByLocationBranches = (branches) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_BRANCHES,
		payload: {
			branches: branches,
		},
	}
}

export const setReportInvByLocationSelectedBranch = (index, branch) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_SELECTED_BRANCH,
		payload: {
			index: index,
			branch: branch,
		},
	}
}

export const setReportInvByLocationCode = (code) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_CODE,
		payload: {
			code: code.toUpperCase(),
		},
	}
}

export const setReportInvByLocationDescription = (description) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_DESCRIPTION,
		payload: {
			description: description,
		},
	}
}

export const setReportInvByLocationViewQty = (isChecked) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_VIEW_QTY,
		payload: {
			isChecked: isChecked,
		},
	}
}

export const setReportInvByLocationViewValue = (isChecked) => {
	return {
		type: SET_REPORT_INV_BY_LOCATION_VIEW_VALUE,
		payload: {
			isChecked: isChecked,
		},
	}
}
