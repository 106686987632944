import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setTfSelectedTransfer, setTfTransfers } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFRightPendingRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.transfer.selectedRequest) !== JSON.stringify(this.props.transfer.selectedRequest)) {
			if (this.props.transfer.selectedRequest.index === -1) {
				this.props.setTfTransfers([])
			} else {
				this.props.setTfTransfers(this.props.transfer.selectedRequest.request.transferOrders)
			}
		}

		if (
			JSON.stringify(prevProps.transfer.transferTable.original) !==
			JSON.stringify(this.props.transfer.transferTable.original)
		) {
			let index = this.props.transfer.selectedTransfer.transfer
				? this.props.transfer.transferTable.filtered.findIndex(
						(each) => each.id === this.props.transfer.selectedTransfer.transfer.id
				  )
				: -1
			if (index !== -1) {
				this.props.setTfSelectedTransfer(index, this.props.transfer.transferTable.filtered[index])
			} else {
				this.props.setTfSelectedTransfer(-1, null)
			}
		}
	}

	onSelectRow(row, index) {
		this.props.setTfSelectedTransfer(index, this.props.transfer.transferTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pendingTransfers)}</legend>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							enableHighlight
							striped
							hover
							enableOverlay
							data={this.props.transfer.transferTable}
							isClearHighlight={this.props.transfer.selectedTransfer.index === -1}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfTransfers, setTfSelectedTransfer }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRightPendingRow)
