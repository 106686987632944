import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class ConfigServiceModalNSRole extends React.Component {
	constructor(props) {
		super(props)

		this.onRole = this.onRole.bind(this)
	}

	componentDidMount() {
		let service = this.props.service.selectedService.service
		if (service && service.role) {
			let roleDOM = document.getElementById(ResourceAssistance.ID.ADM.service.modalNewService.role)
			let roleIdx = this.props.service.roles.findIndex((role) => role.id === service.role.id)
			roleDOM.selectedIndex = roleIdx + 1
			this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE, roleIdx, this.props.service.roles[roleIdx])
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE, -1, null)
	}

	onRole(event) {
		this.props.setSelected(
			SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE,
			event.target.value,
			this.props.service.roles[event.target.value]
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.payee)}</legend>
				<Row>
					<Col />
					<Col md={8}>
						<FormControl
							id={ResourceAssistance.ID.ADM.service.modalNewService.role}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onRole}
						>
							{Utils.renderOptions(this.props.service.roles)}
						</FormControl>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNSRole))
