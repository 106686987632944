import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setRcOrders, setRcSelectedRc } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RCLeftFourthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCLeftFourthRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplayAll !== this.props.isDisplayAll) {
			this.props.setRcOrders(this.props.order.original)
		}

		if (JSON.stringify(prevProps.order.filtered) !== JSON.stringify(this.props.order.filtered)) {
			let index = this.props.selectedRc.rc
				? this.props.order.filtered.findIndex((order) => order.id === this.props.selectedRc.rc.id)
				: -1
			if (index !== -1) {
				this.props.setRcSelectedRc(index, this.props.order.filtered[index])
			} else {
				this.props.setRcSelectedRc(-1, null)
			}
		}
	}

	onSelectRow(row, index) {
		let selectedRc = this.props.order.filtered[index]
		this.props.setRcSelectedRc(index, selectedRc)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.order}
						isClearHighlight={this.props.selectedRc.index === -1}
						onClick={this.onSelectRow}
					/>
					{this.props.order.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	order: state.receive.order,
	searchDate: state.receive.searchDate,
	selectedType: state.receive.selectedType,
	selectedRc: state.receive.selectedRc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcOrders, setRcSelectedRc }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCLeftFourthRow)
