import React from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { ImPrinter } from "react-icons/im"
import { MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setRcDisplayAllInd, setRcOrders } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_RC_ORDERS_LOADING } from "~/redux/type/type/RC"
import { Utils } from "~/utils/Utils"

class RCLeftThirdRow extends React.Component {
	constructor(props) {
		super(props)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onComplete = this.onComplete.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		// if (prevProps.type.types.length === 0 && this.props.type.types.length > 0) {
		// 	let selectedIndex = document.getElementById(ResourceAssistance.ID.RC.type).value
		// 	this.props.setRcSelectedType(selectedIndex, this.props.type.types[selectedIndex])
		// }

		if (prevProps.isDisplayAll !== this.props.isDisplayAll) {
			this.props.setRcOrders(this.props.order.original)
		}
	}

	onDisplayAll(event) {
		this.props.setRcDisplayAllInd(event.target.checked)
	}

	onPrint(event) {
		let subTotal = Utils.BigNumber(0)
		let target = {
			taxIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.taxId,
			}),
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.receive,
			}),
			poIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.poId,
			}),
			invoiceIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.invoiceNum,
			}),
			dateIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.date,
			}),
			vendorIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.supplierCode,
			}),
			vendorIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.supplierName,
			}),
			paymentIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.payment,
			}),
			termsIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.terms,
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
			}),
			descriptionIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.description,
			}),
			qtyIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.qty,
			}),
			bonusIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.bonus,
			}),
			unitPriceIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unitPrice,
			}),
			discountIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.discount,
			}),
			amountIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.amount,
			}),
			uomIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.uom,
			}),
			remarkIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.remark,
			}),
			subTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.totalPrice,
			}),
			totalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.totalPriceDiscounted,
			}),
			grandTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.grandTotal,
			}),
			vatIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.vat,
			}),

			name: this.props.selectedRc.rc.pr.location.parent.displayName,
			address: this.props.selectedRc.rc.pr.location.address.displayName,
			tel: this.props.selectedRc.rc.pr.location.address.tel,
			fax: this.props.selectedRc.rc.pr.location.address.fax,
			taxId: this.props.selectedRc.rc.pr.location.parent.taxNo,

			poId: this.props.selectedRc.rc.id,
			invoiceId: this.props.selectedInvoice.invoice.invoice,
			invoiceDate: Utils.formatDateTime(this.props.selectedInvoice.invoice.creationDateTime),
			vendorId: this.props.selectedInvoice.invoice.supplierCode,
			vendorName: this.props.selectedInvoice.invoice.supplierName,
			payment: this.props.selectedInvoice.invoice.payment,
			terms: this.props.selectedInvoice.invoice.paymentTerms,

			items: this.props.selectedInvoice.invoice.receivedItems.map((item) => {
				return Object.assign(
					{},
					{
						id: item.code,
						description: item.name,
						qty: Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()),
						minQtyPerOrder: Utils.formatNumWithComma(item.minQtyPerOrder),
						unit: item.unit,
						bonus: Utils.formatNumWithComma(item.bonus),
						pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
						discount: item.discount,
						totalPrice: Utils.formatNumWithComma(Utils.BigNumber(item.totalPrice).toFixed(2)),
					}
				)
			}),

			subTotal: Utils.formatNumWithComma(
				this.props.selectedInvoice.invoice.receivedItems
					.reduce((total, item) => {
						total = total.plus(item.totalPrice)
						subTotal = total
						return total
					}, Utils.BigNumber(0))
					.toFixed(2)
			),
			discount: this.props.selectedInvoice.invoice.discount,
			discountTotal: Utils.formatNumWithComma(
				subTotal.times(this.props.selectedInvoice.invoice.discount / 100).toFixed(2)
			),
			total: Utils.formatNumWithComma(subTotal.times(1 - this.props.selectedInvoice.invoice.discount / 100).toFixed(2)),
			vat: this.props.selectedInvoice.invoice.receivedItems.some(
				(item) => item.taxRate !== this.props.selectedInvoice.invoice.receivedItems[0].taxRate
			)
				? 0
				: this.props.selectedInvoice.invoice.receivedItems[0].taxRate,
			vatTotal: Utils.formatNumWithComma(
				this.props.selectedInvoice.invoice.receivedItems
					.reduce((total, item) => {
						total = total.plus(Utils.BigNumber(item.totalPrice).times(item.taxRate / 100))
						return total
					}, Utils.BigNumber(0))
					.toFixed(2)
			),
			grandTotal: Utils.formatNumWithComma(
				this.props.selectedInvoice.invoice.receivedItems
					.reduce((total, item) => {
						total = total.plus(Utils.BigNumber(item.totalPrice).times(1 + item.taxRate / 100))
						return total
					}, Utils.BigNumber(0))
					.toFixed(2)
			),

			signatureTitles: [
				this.props.intl
					.formatMessage({ id: ResourceAssistance.Message.receivedBy })
					.concat(
						`: ${this.props.selectedInvoice.invoice.createdBy.firstName} ${this.props.selectedInvoice.invoice.createdBy.lastName}`
					),
			],
			signatureDate: Utils.formatDateTime(new Date().getTime()),
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.Rc.rc, "_blank")
		myWindow.data = target
	}

	onComplete(event) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.rc.updateReceive,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.selectedRc.rc.id,
				approvalApproved: true,
				userId: this.props.user.id,
			},
		}

		let callback = (res) => {
			this.props.updateReceive(res.data.receives)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, true)
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<FormCheck
						className={ResourceAssistance.CSS.flexSelfCenter}
						type={ResourceAssistance.FormControl.type.checkBox}
						label={translate(ResourceAssistance.Message.displayAll)}
						onChange={this.onDisplayAll}
					/>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.selectedInvoice.index === -1}
						onClick={this.onPrint}
					>
						<ImPrinter size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.print)}
					</Button>
				</Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Rc.Complete, this.props.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.selectedRc.index === -1 ||
								this.props.selectedRc.rc.suppliers.length > 0 ||
								this.props.selectedRc.rc.approvalApproved
							}
							onClick={this.onComplete}
						>
							<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.complete)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.login.user,
	isDisplayAll: state.receive.isDisplayAll,
	order: state.receive.order,
	selectedRc: state.receive.selectedRc,
	selectedInvoice: state.receive.selectedInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction, setRcDisplayAllInd, setRcOrders }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCLeftThirdRow))
