import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "./PrintableDataFactory"

class RegistrationPrintableDataFactory extends PrintableDataFactory {
	static generateExpenseEstReportData = (patient, services, budgets) => {
		let intl = super.getIntl()

		let subTotal = Utils.BigNumber(0)
		let budgetSubTotal = Utils.BigNumber(0)
		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			telIntl: intl.formatMessage({ id: ResourceAssistance.Message.tel }),
			addressIntl: intl.formatMessage({ id: ResourceAssistance.Message.address }),
			serviceIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.service }),
			noteIntl: intl.formatMessage({ id: ResourceAssistance.Message.note }),
			amountIntl: intl.formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: intl.formatMessage({ id: ResourceAssistance.Message.totalPrice }),
			grandTotalIntl: intl.formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			forAnyComplicationsIntl: intl.formatMessage({ id: ResourceAssistance.Warning.forAnyComplications }),
			inCaseRefundPatientIntl: intl.formatMessage({ id: ResourceAssistance.Warning.inCaseRefundPatient }),
			nameIntl: intl.formatMessage({ id: ResourceAssistance.Message.name }),
			budgetIntl: intl.formatMessage({ id: ResourceAssistance.Message.budget }),
			responsibleContactIntl: intl.formatMessage({ id: ResourceAssistance.Message.companyOrResponsiblePersonContact }),
			serviceSubTotalIntl: intl.formatMessage({ id: ResourceAssistance.Message.totalEstimationCost }),
			budgetSubTotalIntl: intl.formatMessage({ id: ResourceAssistance.Message.totalBudget }),

			titleIntl: intl.formatMessage({ id: ResourceAssistance.Message.expenseEstimation }),
			title: "Expense Estimation",

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			tel: patient.tel,
			address: patient.address,

			services: Utils.groupBy(
				services
					.map((service) => {
						let total =
							service.duration !== undefined
								? Utils.calculateDoctorOrderBalance(service)
								: Utils.calculateNurseOrderBalance(service)
						subTotal = subTotal.plus(total)
						return {
							name: service.description,
							description: service.notes,
							categoryName: service.categoryName,
							amount: Utils.formatNumWithComma(total),
						}
					})
					.sort((a, b) => Utils.sort(a.categoryName, b.categoryName)),
				"categoryName"
			),

			budgets: budgets.map((budget) => {
				budgetSubTotal = budgetSubTotal.plus(budget.budget)
				return Object.assign({}, budget, {
					name: budget.code ? budget.name : budget.description,
					budget: Utils.formatNumWithComma(Utils.BigNumber(budget.budget).toFixed(2)),
				})
			}),

			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			budgetSubTotal: budgetSubTotal.isGreaterThan(0)
				? Utils.formatNumWithComma(budgetSubTotal.negated().toFixed(2))
				: Utils.BigNumber(0).toFixed(2),
			grandTotal: subTotal.minus(budgetSubTotal).isLessThan(0)
				? Utils.BigNumber(0).toFixed(2)
				: Utils.formatNumWithComma(subTotal.minus(budgetSubTotal).toFixed(2)),

			signatureDate: Utils.formatDate(new Date().getTime()),
			signatureTitles: [
				"ผู้รับผิดชอบค่ารักษาพยาบาล",
				intl.formatMessage({ id: ResourceAssistance.Message.coordinator }),
			],
		}
	}
}

export default RegistrationPrintableDataFactory
