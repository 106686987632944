import { gapi } from "gapi-script"
import _ from "lodash"

export const GDrive = { connect, uploadPDF, generatePublicUrl, searchFolder, signOut, signIn }

const API_KEY = "AIzaSyC5Ygvq9dvSI5Dvu1-5Yc5pI2LHcjmbthw"
const CLIENT_ID = "735943276868-rn124ak1b5rs9kpeveno1kj8mcbvc506.apps.googleusercontent.com"
const FOLDER_NAME = "covid19_lab_results"

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/drive"

function connect() {
	gapi.load("client:auth2", initClient)
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
async function initClient() {
	try {
		await gapi.client
			.init({
				apiKey: API_KEY,
				clientId: CLIENT_ID,
				discoveryDocs: DISCOVERY_DOCS,
				scope: SCOPES,
			})
			.then(async function () {
				// Listen for sign-in state changes.
				// gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)

				// Handle the initial sign-in state.
				// updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())

				gapi.auth2.getAuthInstance().isSignedIn.listen(() => {
					if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
						console.log("Signed In")
					} else {
						console.log("Signed Out")
					}
				})
			})
	} catch (e) {
		console.log(e)
		throw new Error()
	}
}

function signIn() {
	if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
		return Promise.resolve()
	} else {
		return Promise.resolve(gapi.auth2.getAuthInstance().signIn())
	}
}

async function uploadPDF(
	fileName,
	blob,
	callback = (f) => {},
	errorFolder = (folderName) => {},
	errorFile = (fileName) => {}
) {
	let folderId = (await searchFolder(FOLDER_NAME)) || (await createFolder(FOLDER_NAME))

	if (_.isEmpty(folderId)) {
		errorFolder(FOLDER_NAME)
		return false
	}

	if (await searchFile(folderId, fileName)) {
		errorFile(fileName)
		return false
	}

	let metadata = {
		name: fileName,
		mimeType: "application/pdf",
		parents: [folderId],
	}

	const boundary = "foo_bar_baz"
	const delimiter = "\r\n--" + boundary + "\r\n"
	const close_delim = "\r\n--" + boundary + "--"

	const base64 = await new Promise((resolve, reject) => {
		const fr = new FileReader()
		fr.onload = () => resolve(fr.result.split(",").pop())
		fr.onerror = () => reject(fr.error)
		fr.readAsDataURL(blob)
	})

	var multipartRequestBody =
		delimiter +
		"Content-Type: application/json; charset=UTF-8\r\n\r\n" +
		JSON.stringify(metadata) +
		delimiter +
		"Content-Type: application/pdf\r\n" +
		"Content-Transfer-Encoding: base64\r\n\r\n" +
		base64 +
		"\r\n" +
		close_delim

	var request = gapi.client.request({
		path: "https://www.googleapis.com/upload/drive/v3/files",
		method: "POST",
		params: { uploadType: "multipart" },
		headers: {
			"Content-Type": "multipart/related; boundary=" + boundary + "",
		},
		body: multipartRequestBody,
	})

	request.execute(callback)

	return true
}

async function generatePublicUrl(fileId) {
	try {
		await gapi.client.drive.permissions.create({
			fileId: fileId,
			resource: {
				role: "reader",
				type: "anyone",
			},
		})
		const result = await gapi.client.drive.files
			.get({
				fileId: fileId,
				fields: "webViewLink, webContentLink",
			})
			.then((response) => {
				return response
			})
		return result.result.webViewLink
	} catch (error) {
		console.log(error)
	}
}

async function searchFolder(name) {
	try {
		const res = await gapi.client.drive.files.list(
			{
				q: `mimeType='application/vnd.google-apps.folder' and name='${name}'`,
				fields: "files(id,name)",
			},
			(response) => {
				return response
			}
		)
		return _.isEmpty(res.result.files) ? null : res.result.files[0].id
	} catch (error) {
		console.log(error)
	}
}

async function searchFile(folderId, fileName) {
	try {
		let page = null
		const res = await gapi.client.drive.files.list({
			q: `mimeType='application/pdf' and name='${fileName}' and parents in '${folderId}'`,
			fields: "nextPageToken, files(id, name)",
			spaces: "drive",
			pageToken: page,
		})
		console.log(res)
		return _.isEmpty(res.result.files) ? null : res.result.files[0].id
	} catch (error) {
		console.log(error)
	}
}

async function createFolder(name) {
	try {
		const res = await gapi.client.drive.files.create({
			resource: {
				name: name,
				mimeType: "application/vnd.google-apps.folder",
			},
			fields: "id, name",
		})
		return res.result.id
	} catch (error) {
		console.log(error)
	}
}

function signOut() {
	gapi.auth2.getAuthInstance().signOut()
}

/**
 *  Called when the signed in status changes, to update the UI
 *  appropriately. After a sign-in, the API is called.
 */
// function updateSigninStatus(isSignedIn) {
// if (isSignedIn) {
// authorizeButton.style.display = "none"
// signoutButton.style.display = "block"
// } else {
// authorizeButton.style.display = "block"
// signoutButton.style.display = "none"
// }
// }

/**
 *  Sign in the user upon button click.
 */
// function handleAuthClick(event) {
// gapi.auth2.getAuthInstance().signIn()
// updateSigninStatus()
// }

/**
 *  Sign out the user upon button click.
 */
// function handleSignoutClick(event) {
// 	gapi.auth2.getAuthInstance().signOut()
// 	updateSigninStatus()
// }

/**
 * Append a pre element to the body containing the given message
 * as its text node. Used to display the results of the API call.
 *
 * @param {string} message Text to be placed in pre element.
 */
// function appendPre(message) {
// 	var pre = document.getElementById("content")
// 	var textContent = document.createTextNode(message + "\n")
// 	pre.appendChild(textContent)
// }

/**
 * Print files.
 */
// function listFiles() {
// 	console.log("list files")
// 	gapi.client.drive.files
// 		.list({
// 			pageSize: 10,
// 			fields: "nextPageToken, files(id, name)",
// 		})
// 		.then(function (response) {
// 			appendPre("Files:")
// 			var files = response.result.files
// 			if (files && files.length > 0) {
// 				for (var i = 0; i < files.length; i++) {
// 					var file = files[i]
// 					appendPre(file.name + " (" + file.id + ")")
// 				}
// 			} else {
// 				appendPre("No files found.")
// 			}
// 		})
// }

// const UIController = (function () {
// 	//the private section of the module
// 	let component = "Replacement Text"
// 	const changeComponent = function () {
// 		//change all the h1 text to what is in the component variable above
// 		const element = document.querySelector("h1")
// 		element.textContent = component
// 	}
// 	//the public section of the module
// 	return {
// 		callChangeComponent: function () {
// 			changeComponent()
// 		},
// 	}
// })()
