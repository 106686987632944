const ID = {
	PO: {
		type: "po_type",
		modalEdit: {
			paymentType: "po_modal_edit_payment_type",
		},
	},

	PR: {
		type: "pr_type",
		modalNewPr: {
			tableItem: "pr_modal_npr_table_item",
			tableSelectedItem: "pr_modal_npr_table_selected_item",
		},
		modalMinStock: {
			amount: "pr_modal_min_stock_amount",
			tableItem: "pr_modal_min_stock_table_item",
			tableSelectedItem: "pr_modal_min_stock_table_selected_item",
		},
	},
	RC: {
		type: "rc_type",
		unit: "rc_unit",
		modalReceiveSupplier: "modal_receive_supplier",
		modalCancelSupplier: "modal_cancel_supplier",
		modalReceive: {
			tableReceive: "rc_modal_receive_table_receive",
			tableReceived: "rc_modal_receive_table_received",
		},
		modalCancel: {
			tableReceive: "rc_modal_cancel_table_receive",
			tableCancelled: "rc_modal_cancel_table_cancelled",
		},
	},
	INV: {
		type: "inv_type",
		newInventoryType: "inv_new_inv_type",
	},
	TF: {
		modalRequest: {
			type: "modal_request_type",
			toOrgs: "modal_request_to_orgs",
			toBranches: "modal_request_to_branches",
			toLocations: "modal_request_to_locations",
			tableItem: "tf_modal_request_table_item",
			tableSelectedItem: "tf_modal_request_table_selected_item",
		},
		modalTransfer: {
			type: "modal_transfer_type",
			transferAmount: "modal_transfer_transfer_amount",
			org: "modal_transfer_org",
			branch: "modal_transfer_branch",
			lot: "modal_transfer_lot",
			tableItem: "tf_modal_transfer_table_item",
			tableTransferredItem: "tf_modal_transfer_table_transferred_item",
		},
	},
	ADM: {
		item: {
			type: "adm_item_type",
			modalNI: {
				code: "adm_item_modal_ni_code",
				categoryNum: "adm_item_modal_ni_category_num",
				fsCode: "adm_item_modal_ni_fs_code",
				dose: "adm_item_modal_ni_dose",
				brand: "adm_item_modal_ni_brand",
				medicationUsage: "adm_item_modal_ni_medication_usage",
				pricePerUnit: "adm_item_modal_ni_pricePerUnit",
				sellPricePerUnit: "adm_item_modal_ni_sell_price_per_unit",
				discount: "adm_item_modal_ni_discount",
				minQtyPerOrder: "adm_item_modal_ni_minQtyPerOrder",
				bonus: "adm_item_modal_ni_bonus",
				type: "adm_item_modal_ni_type",
				form: "adm_item_modal_ni_form",
				unit: "adm_item_modal_ni_unit",
				supplier: "adm_item_modal_ni_supplier",
				tax: "adm_item_modal_ni_tax",
			},
		},
		itemSource: {
			type: "adm_item_source_type",
			modalNIS: {
				type: "adm_item_source_modal_nis_type",
				name: "adm_item_source_modal_nis_name",
				keyword: "adm_item_source_modal_nis_keyword",
			},
		},
		supplier: {
			modalNS: {
				code: "adm_supplier_modal_ns_code",
				name: "adm_supplier_modal_ns_name",
				address: "adm_supplier_modal_ns_address",
			},
		},
		unit: {
			modalNU: {
				name: "adm_unit_modal_nu_name",
				description: "adm_unit_modal_nu_description",
			},
		},
		form: {
			modalNF: {
				name: "adm_form_modal_nf_name",
				description: "adm_form_modal_nf_description",
			},
		},
		tax: {
			modalNT: {
				name: "adm_tax_modal_nt_name",
				description: "adm_tax_modal_nt_description",
				taxRate: "adm_tax_modal_nt_taxRate",
			},
		},
		user: {
			modalNU: {
				lastName: "adm_user_modal_nu_lastName",
				firstName: "adm_user_modal_nu_firstName",
				loginName: "adm_user_modal_nu_loginName",
				nickname: "adm_user_modal_nu_nickname",
				password: "adm_user_modal_nu_password",
				confirmPassword: "adm_user_modal_nu_confirmPassword",
				role: "adm_user_modal_nu_role",
			},
			modalCPW: {
				password: "adm_user_modal_cpw_password",
				confirmPassword: "adm_user_modal_cpw_confirmPassword",
			},
		},
		role: {
			modalNR: {
				name: "adm_role_modal_nr_name",
				description: "adm_role_modal_nr_description",
			},
		},
		service: {
			type: "adm_service_type",
			modalNewService: {
				type: "adm_service_modal_new_service_type",
				role: "adm_service_modal_new_service_role",
			},
		},
		customDefault: {
			focus: {
				type: "adm_custom_default_focus_type",
			},
			intervention: {
				type: "adm_custom_default_intervention_type",
			},
		},
		icd: {
			type: "adm_icd_type",
			modalNewICD: {
				type: "adm_icd_modal_new_ICD_type",
			},
		},
		laboratory: {
			pcrComment: {
				type: "adm_laboratory_pcr_comment_type",
				modalNewComment: {
					type: "adm_laboratory_pcr_comment_modal_new_comment_type",
				},
			},
			antigenComment: {
				type: "adm_laboratory_antigen_comment_type",
				modalNewComment: {
					type: "adm_laboratory_antigen_comment_modal_new_comment_type",
				},
			},
		},
	},
	REPORT: {
		inv: {
			byLocation: {
				branch: "report_inv_by_location_branch",
			},
		},
		tf: {
			byLocation: {
				branch: "report_tf_by_location_branch",
			},
		},
		po: {
			byLocation: {
				branch: "report_po_by_location_branch",
			},
		},
		hospitel: {
			patient: {
				branch: "report_hospitel_patient_branch",
				department: "report_hospitel_patient_department",
			},
		},
	},
	HOSPITEL: {
		cashier: {
			id: "hospital_cashier_id",
			doctorPrescriptionOnly: "hospital_cashier_doctor_prescription_only",
			dischargePatient: "hospital_cashier_discharge_patient",
			modalSearch: {
				patientTable: "hospital_cashier_modal_search_table_patient",
			},
			modalLab: {
				id: "hospital_cashier_modal_lab_id",
				tableUncharged: "hospital_cashier_modal_lab_table_uncharged",
				tablePending: "hospital_cashier_modal_lab_table_pending",
			},
			modalCertificate: {
				id: "hospital_cashier_modal_certificate_id",
				tableUncharged: "hospital_cashier_modal_certificate_table_uncharged",
				tablePending: "hospital_cashier_modal_certificate_table_pending",
			},
			modalDoctor: {
				id: "hospital_cashier_modal_doctor_id",
			},
			modalNurse: {
				id: "hospital_cashier_modal_nurse_id",
			},
			modalFinancialPlan: {
				healthCare: "hospital_cashier_modal_financial_plan_health_care",
			},
			modalPayment: {
				type: "hospital_cashier_modal_payment_type",
				saveBilling: "hospital_cashier_modal_payment_save_billing",
			},
		},
		hospitel: {
			cancelOrder: "hospitel_hospitel_cancel_order",
			discharge: "hospital_hospitel_discharge_hospitel",
			modalLocation: {
				branch: "hospital_hospitel_modal_location_branch",
				department: "hospital_hospitel_modal_location_department",
			},
			modalDoctorOrder: {
				tableItem: "hospital_hospitel_modal_doctor_order_item",
				pendingItemTable: "hospital_hospitel_modal_doctor_order_pending_item",
				durationType: "hospital_hospitel_modal_doctor_order_duration_type",
				unit: "hospital_ipd_modal_doctor_order_unit",
			},
			modalSOAP: {
				delete: "hospital_hospitel_modal_soap_delete",
			},
			modalVitalSign: {
				delete: "hospitel_hospitel_modal_vital_sign_delete",
			},
			modalNurseNote: {
				delete: "hospitel_hospitel_modal_nurse_note_delete",
			},
			modalRegister: {
				done: "hospital_hospitel_modal_register_done",
				category: "hospital_hospitel_modal_register_category",
				service: "hospital_hospitel_modal_register_service",
			},
			modalNurseOrder: {
				itemTable: "hospital_hospitel_modal_nurse_order_item",
				pendingItemTable: "hospital_hospitel_modal_nurse_order_pending_item",
			},
			modalAdjustment: {
				delete: "hospital_hospitel_modal_adjustment_delete",
			},
			modalAdjustmentService: {
				delete: "hospital_hospitel_modal_adjustment_service_delete",
			},
		},
		lab: {
			modalLab: {
				branch: "hospitel_lab_modal_lab_branch",
				service: "hosptiel_lab_modal_lab_service",
			},
			modalRegister: {
				dob: "hospitel_lab_modal_register_dob",
				title: "hospitel_lab_modal_register_title",
				gender: "hospitel_lab_modal_register_gender",
			},
		},
		ipd: {
			modalRegister: {
				dob: "hospitel_ipd_modal_register_dob",
				title: "hospital_ipd_modal_register_title",
				gender: "hospital_ipd_modal_register_gender",
			},
		},
	},
	HOSPITAL: {
		deposit: {
			modalSave: {
				payment: "hospital_deposit_modal_save_payment",
			},
			modalWithdraw: {
				payment: "hospital_deposit_modal_withdraw_payment",
				amount: "hospital_deposit_modal_withdraw_amount",
			},
		},
		registration: {
			modalClinicalRecord: {
				healthcare: "hospital_registration_modal_clinical_record_healthcare",
			},
		},
		opd: {
			checkOut: "hospital_opd_check_out",
			appointmentDelete: "hospital_opd_appointment_delete",
			deleteDoctorOrder: "hospital_opd_delete_doctor_order",
			deleteIO: "hospital_opd_delete_io",
			deleteNurseService: "hospital_opd_delete_nurse_service",
			deleteNurseMedicalSupply: "hospital_opd_delete_nurse_medical_supply",
			deleteCertificate: "hospital_opd_delete_certificate",
			deleteICD: "hospital_opd_delete_icd",
			deleteGCS: "hospital_opd_delete_gcs",
			deletePainScale: "hospital_opd_delete_pain_scale",
			deletePupilSize: "hospital_opd_delete_pupil_size",
			modalLocation: {
				branch: "hospital_opd_modal_location_branch",
				department: "hospital_opd_modal_location_department",
			},
			modalDoctorOrder: {
				durationType: "hospital_opd_modal_doctor_order_duration_type",
				unit: "hospital_opd_modal_doctor_order_unit",
			},
			modalCertificate: {
				service: "hospital_opd_modal_certificate_service",
				role: "hospital_opd_modal_certificate_role",
				user: "hospital_opd_modal_certificate_user",
				branch: "hospital_opd_modal_certificate_branch",
				org: "hospital_opd_modal_certificate_org",
				category: "hospital_opd_modal_certificate_category",
			},
			modalNurse: {
				delete: "hospital_opd_modal_nurse_delete",
			},
			modalVitalSign: {
				delete: "hospital_opd_modal_vital_sign_delete",
			},
			modalAdjustment: {
				delete: "hospital_opd_modal_adjustment_delete",
			},
		},
		ipd: {
			deleteIO: "hospital_ipd_delete_io",
			deleteNurseService: "hospital_ipd_delete_nurse_service",
			deleteNurseMedicalSupply: "hospital_ipd_delete_nurse_medical_supply",
			deleteCertificate: "hospital_ipd_delete_certificate",
			deleteICD: "hospital_ipd_delete_icd",
			deleteGCS: "hospital_ipd_delete_gcs",
			deletePainScale: "hospital_ipd_delete_pain_scale",
			deletePupilSize: "hospital_ipd_delete_pupil_size",
			modalCertificate: {
				service: "hospital_ipd_modal_certificate_service",
				role: "hospital_ipd_modal_certificate_role",
				user: "hospital_ipd_modal_certificate_user",
				category: "hospital_ipd_modal_certificate_category",
			},
		},
		pharmacy: {
			deleteVerifiedOrder: "hospital_pharmacy_delete_verified_order",
			dispenseVerifiedOrder: "hospital_pharmacy_dispense_verified_order",
			modalLocation: {
				branch: "hospital_pharmacy_modal_location_branch",
				department: "hospital_pharmacy_modal_location_department",
			},
		},
	},
	MENU: {
		notification: {},
	},
}

export default ID
