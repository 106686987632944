export const SET_HOSPITEL_IPD_CERTIFICATES = "SET_HOSPITEL_IPD_CERTIFICATES"
export const SET_HOSPITEL_IPD_END_DATETIME = "SET_HOSPITEL_IPD_END_DATETIME"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS = "SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES = "SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_DOB = "SET_HOSPITEL_IPD_MODAL_REGISTER_DOB"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME = "SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER = "SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT = "SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER =
	"SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME = "SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES = "SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_TEL = "SET_HOSPITEL_IPD_MODAL_REGISTER_TEL"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE = "SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE = "SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE"
export const SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT = "SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT"
export const SET_HOSPITEL_IPD_MODAL_SEARCH_ID = "SET_HOSPITEL_IPD_MODAL_SEARCH_ID"
export const SET_HOSPITEL_IPD_PATIENTS = "SET_HOSPITEL_IPD_PATIENTS"
export const SET_HOSPITEL_IPD_PATIENTS_LOADING = "SET_HOSPITEL_IPD_PATIENTS_LOADING"
export const SET_HOSPITEL_IPD_PATIENT_LABS = "SET_HOSPITEL_IPD_PATIENT_LABS"
export const SET_HOSPITEL_IPD_REGISTER_DISPLAY = "SET_HOSPITEL_IPD_REGISTER_DISPLAY"
export const SET_HOSPITEL_IPD_SEARCH_DISPLAY = "SET_HOSPITEL_IPD_SEARCH_DISPLAY"
export const SET_HOSPITEL_IPD_SEARCH_ID = "SET_HOSPITEL_IPD_SEARCH_ID"
export const SET_HOSPITEL_IPD_SEARCH_PATIENT_ID = "SET_HOSPITEL_IPD_SEARCH_PATIENT_ID"
export const SET_HOSPITEL_IPD_SELECTED_LAB = "SET_HOSPITEL_IPD_SELECTED_LAB"
export const SET_HOSPITEL_IPD_SELECTED_PAITENT = "SET_HOSPITEL_IPD_SELECTED_PAITENT"
export const SET_HOSPITEL_IPD_START_DATETIME = "SET_HOSPITEL_IPD_START_DATETIME"
export const SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY = "SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_BP = "SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_BP"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN = "SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_O2 = "SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_O2"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_PR = "SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_PR"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_RR = "SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_RR"
export const SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_TEMPERATURE =
	"SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_TEMPERATURE"
export const SET_REGISTER_ADMISSIONS = "SET_REGISTER_ADMISSIONS"
export const SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES = "SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES"
export const SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER =
	"SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER"
export const SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE =
	"SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE"
export const SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY = "SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY"
export const SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL = "SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL"
export const SET_REGISTER_MODAL_SEARCH_FIRST_NAME = "SET_REGISTER_MODAL_SEARCH_FIRST_NAME"
export const SET_REGISTER_MODAL_SEARCH_LAST_NAME = "SET_REGISTER_MODAL_SEARCH_LAST_NAME"
export const SET_REGISTER_MODAL_SEARCH_PID = "SET_REGISTER_MODAL_SEARCH_PID"
export const SET_REGISTER_SELECTED_ADMISSION = "SET_REGISTER_SELECTED_ADMISSION"
export const SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY = "SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS = "SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING = "SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS = "SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES = "SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE = "SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER = "SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES = "SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES = "SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES = "SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR = "SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE = "SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR =
	"SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE = "SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE = "SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY =
	"SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY"
export const SET_REGISTRATION_ADJUSTMENT_DISPLAY = "SET_REGISTRATION_ADJUSTMENT_DISPLAY"
export const SET_REGISTRATION_BUDGET_DISPLAY = "SET_REGISTRATION_BUDGET_DISPLAY"
export const SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARES = "SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARES"
export const SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE = "SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE"
export const SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER = "SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER"
export const SET_REGISTRATION_MODAL_BUDGET_BUDGET = "SET_REGISTRATION_MODAL_BUDGET_BUDGET"
export const SET_REGISTRATION_MODAL_BUDGET_CONTACT = "SET_REGISTRATION_MODAL_BUDGET_CONTACT"
export const SET_REGISTRATION_MODAL_BUDGET_NOTE = "SET_REGISTRATION_MODAL_BUDGET_NOTE"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS = "SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS"
export const SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET = "SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY =
	"SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL = "SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE = "SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION =
	"SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK = "SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK"
export const SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE = "SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE"
export const SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG = "SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG"
export const SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG = "SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG"
export const SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY = "SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY"
export const SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY = "SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY"
export const SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME = "SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME"
export const SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME = "SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME"
export const SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP = "SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP"
export const SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL = "SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL"
export const SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS = "SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS"
export const SET_REGISTRATION_MODAL_REGISTER_PHOTO = "SET_REGISTRATION_MODAL_REGISTER_PHOTO"
