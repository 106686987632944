import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { BiDownload, BiUpload } from "react-icons/bi"
import { ImPrinter } from "react-icons/im"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_DEPOSIT_SAVING_DISPLAY, SET_DEPOSIT_WITHDRAW_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class DepositAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDeposit = this.onDeposit.bind(this)
		this.onWithdraw = this.onWithdraw.bind(this)
	}

	isWithdrawDisabled() {
		return (
			Utils.BigNumber(Utils.calculatePatientDeposit(this.props.deposit.patient.transactions)).isLessThanOrEqualTo(0) ||
			this.props.deposit.patient.admissions.some((admission) => admission.dischargedDateTime === undefined)
		)
	}

	onDeposit() {
		this.props.onModalDisplayAction(SET_DEPOSIT_SAVING_DISPLAY, true)
	}

	onWithdraw() {
		this.props.onModalDisplayAction(SET_DEPOSIT_WITHDRAW_DISPLAY, true)
	}

	render() {
		return (
			<Row style={{ justifyContent: "center", marginBottom: "0" }}>
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.green} onClick={this.onDeposit}>
						<BiDownload size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.deposit)}
					</Button>
				</Col>
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.red}
						disabled={this.isWithdrawDisabled()}
						onClick={this.onWithdraw}
					>
						<BiUpload size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.withdraw)}
					</Button>
				</Col>
				<Col md="auto">
					<div className="vertical"></div>
				</Col>
				<Col md="auto">
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu>
							<DropdownItem onClick={this.onPrint}>{translate(ResourceAssistance.Message.transaction)}</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositAction)
