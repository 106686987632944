import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPoItems } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PORightFourthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORightFourthRow",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedSupplier.index !== this.props.selectedSupplier.index) {
			if (this.props.selectedSupplier.index === -1) {
				this.props.setPoItems([])
			} else {
				this.props.setPoItems(this.props.selectedSupplier.supplier.items)
			}
		}
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable striped hover enableOverlay data={this.props.inventory} />
					{this.props.inventory.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	inventory: state.purchaseOrder.inventory,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoItems }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightFourthRow)
