import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setRcOrders, setRcSearchNum } from "~/redux/action"
import SearchBox from "~/view/component/search_box/SearchBox"

class RCLeftSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.onPoSearchChange = this.onPoSearchChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.searchNum !== this.props.searchNum) {
			this.props.setRcOrders(this.props.order.original)
		}
	}

	onPoSearchChange(event) {
		this.props.setRcSearchNum(event.target.value)
	}

	render() {
		return (
			<Row>
				<Col>
					<SearchBox
						num={1}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number]}
						placeholders={[ResourceAssistance.Message.number]}
						callbacks={[this.onPoSearchChange]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	searchNum: state.receive.searchNum,
	order: state.receive.order,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcOrders, setRcSearchNum }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCLeftSecondRow)
