import moment from "moment"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME, SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OPDModalModifyDateRange extends React.Component {
	constructor(props) {
		super(props)

		this.onRangeChange = this.onRangeChange.bind(this)
	}

	componentDidMount() {
		this.props.setValue(
			SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME,
			this.props.opd.selectedDoctorOrder.order.startDateTime
		)
		this.props.setValue(SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME, this.props.opd.selectedDoctorOrder.order.endDateTime)
	}

	onRangeChange(value, dateString) {
		this.props.setValue(SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME, value ? value[0].milliseconds(0).valueOf() : 0)
		this.props.setValue(SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME, value ? value[1].milliseconds(0).valueOf() : 0)
	}

	render() {
		return (
			<Fragment>
				<Row style={{ alignSelf: "center" }}>
					<Col md="auto">
						<AntDatePicker
							displayRange
							size={"large"}
							showTime={{
								hideDisabledOptions: true,
								format: "HH:mm",
							}}
							format={"DD-MM-YYYY HH:mm"}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }),
							]}
							defaultValues={[
								moment(this.props.opd.selectedDoctorOrder.order.startDateTime),
								moment(this.props.opd.selectedDoctorOrder.order.endDateTime),
							]}
							disabledDate={(current) => {
								return current && current < moment().startOf("day")
							}}
							disabledTime={(current, type) => {
								if (current.dayOfYear() === moment().dayOfYear()) {
									return {
										disabledHours: () => Array.from(Array(24).keys()).splice(0, moment().hour()),
										disabledMinutes: () => Array.from(Array(60).keys()).splice(0, moment().minute()),
									}
								}
							}}
							ranges={{
								[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.today })]]: [
									moment(),
									moment().endOf("day"),
								],
								[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.tomorrow })]]: [
									moment().startOf("day").add(1, "d"),
									moment().endOf("day").add(1, "d"),
								],
								[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.oneWeek })]]: [
									moment(),
									moment().add(1, "w"),
								],
								[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.twoWeeks })]]: [
									moment(),
									moment().add(2, "w"),
								],
								[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.oneMonth })]]: [
									moment(),
									moment().add(1, "M"),
								],
							}}
							onChange={this.onRangeChange}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalModifyDateRange))
