import React from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalDoctorOrderPendingItems, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalDoctorOrderAction from "./HospitelModalDoctorOrderAction"
import HospitelModalDoctorOrderFilter from "./HospitelModalDoctorOrderFilter"
import HospitelModalDoctorOrderItem from "./HospitelModalDoctorOrderItem"
import HospitelModalDoctorOrderModify from "./HospitelModalDoctorOrderModify"
import HospitelModalDoctorOrderPending from "./HospitelModalDoctorOrderPending"

class HospitelModalDoctorOrderDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
	}

	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()

		let selectedItem = this.props.hospitel.modalDoctorOrder.selectedItem.item
		let newItem = Object.assign({}, selectedItem, {
			...selectedItem,
			startDateTime: this.props.hospitel.modalDoctorOrder.startDateTime,
			endDateTime: this.props.hospitel.modalDoctorOrder.endDateTime,
			notes: this.props.hospitel.modalDoctorOrder.notes,
			categoryName: this.props.hospitel.modalDoctorOrder.selectedType.type.description,
			durationQty: this.props.hospitel.modalDoctorOrder.durationQty,
			duration: Utils.BigNumber(this.props.hospitel.modalDoctorOrder.duration)
				.times(this.props.hospitel.modalDoctorOrder.selectedDuration.duration.value)
				.toNumber(),
			isPRN: this.props.hospitel.modalDoctorOrder.isPRN,
		})
		let pendingItems = this.props.hospitel.modalDoctorOrder.pendingItemTable.filtered
		pendingItems.push(newItem)
		this.props.setHospitelModalDoctorOrderPendingItems(pendingItems)

		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalDoctorOrderAction />
				<HospitelModalDoctorOrderFilter />
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDownBtn}
				>
					<Row className={ResourceAssistance.CSS.fullSize}>
						<Col>
							<HospitelModalDoctorOrderItem />
						</Col>
						{this.props.hospitel.modalDoctorOrder.selectedItem.item && (
							<Col md={3} style={{ minWidth: "435px" }}>
								<HospitelModalDoctorOrderModify />
							</Col>
						)}
					</Row>
				</Form>
				<HospitelModalDoctorOrderPending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setHospitelModalDoctorOrderPendingItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderDetails)
