import { notification, Select } from "antd"
import BigNumber from "bignumber.js"
import _ from "lodash"
import React from "react"
import { ResourceAssistance, translate } from "~/i18n"
import ServerUtils from "./ServerUtils"

class Utils extends ServerUtils {
	static BigNumber(num) {
		let BN = BigNumber.clone({ DECIMAL_PLACES: 2, ROUNDING_MODE: 4 })
		if (num === undefined || !num) {
			return new BN(0)
		}
		return new BN(num.toString().replace(/,/g, ""))
	}

	static calculateAge = (dob) => {
		let diff_ms = 0
		if (typeof dob === "number") {
			diff_ms = Date.now() - dob
		} else {
			diff_ms = Date.now() - dob.getTime()
		}

		if (diff_ms < 0) {
			return 0
		}
		let age_dt = new Date(diff_ms)

		return Math.abs(age_dt.getUTCFullYear() - 1970)
	}

	static calculateAgeMonth = (dob) => {
		let birthDate
		if (typeof dob === "number") {
			birthDate = new Date(dob)
		} else {
			birthDate = dob
		}
		let today = new Date()

		if (today.getMonth() >= birthDate.getMonth()) {
			var monthAge = today.getMonth() - birthDate.getMonth()
		} else {
			monthAge = 12 + today.getMonth() - birthDate.getMonth()
		}

		// if (dateNow >= dateDob) {
		// 	var dateAge = dateNow - dateDob
		// } else {
		// 	monthAge--
		// 	var dateAge = 31 + dateNow - dateDob

		// 	if (monthAge < 0) {
		// 		monthAge = 11
		// 		yearAge--
		// 	}
		// }

		return monthAge
	}

	static calculateDaysBetween = (dateTimeA, dateTimeB, dayOffSet = 0) => {
		if (!dateTimeA || !dateTimeB) {
			return 0
		}
		let a = new Date(dateTimeA)
		let b = new Date(dateTimeB)
		return Utils.BigNumber(new Date(b.getFullYear(), b.getMonth(), b.getDate()).getTime())
			.minus(new Date(a.getFullYear(), a.getMonth(), a.getDate()).getTime())
			.dividedBy(24 * 60 * 60 * 1000)
			.plus(dayOffSet)
			.toFixed(0)
	}

	static calculateTextWidthWithW(text, font) {
		let canvas = document.createElement("canvas")
		let context = canvas.getContext("2d")
		context.font = font
		let strW = new Array(text.length + 1).join("w")
		let width = context.measureText(strW).width
		return Math.ceil(width)
	}

	static calculateStepSize = (values, tickCount) => {
		let min = Math.min(...values)
		let max = Math.max(...values)
		let range = max - min
		let unroundedTickSize = range / (tickCount - 1)
		let x = Math.ceil(Math.log10(unroundedTickSize) - 1)
		let pow10x = Math.pow(10, x)
		return Math.ceil(unroundedTickSize / pow10x) * pow10x
	}

	static calculateDoctorOrderQty = (
		doctorOrder,
		startDateTime = doctorOrder.startDateTime,
		endDateTime = doctorOrder.endDateTime
	) => {
		//Old doctor order made before scheduled time introduced.
		if (doctorOrder.duration === 0 && _.isEmpty(doctorOrder.serviceCode)) {
			return this.BigNumber(0).toFixed(2)
		}

		//Newly doctor order made after schdueld time introduced.
		let qty = Utils.BigNumber(0)
		for (
			let i = doctorOrder.startDateTime;
			doctorOrder.duration > 0 && i <= doctorOrder.endDateTime;
			i = i + Number(doctorOrder.duration) * 60000
		) {
			if (i >= startDateTime && i <= endDateTime) {
				qty = qty.plus(doctorOrder.durationQty)
			}
		}
		return qty.toNumber()
	}

	static calculateDoctorOrderBalance = (doctorOrder) => {
		//Old doctor order made before scheduled time introduced.
		if (doctorOrder.duration === 0 && _.isEmpty(doctorOrder.serviceCode)) {
			return this.BigNumber(0).toFixed(2)
		}

		//Newly doctor order made after schdueld time introduced.
		let qty = 0
		if (doctorOrder.prn && doctorOrder.prnDispensingRecords) {
			qty = doctorOrder.prnDispensingRecords
				.reduce((total, record) => {
					return total.plus(record.dispensingQty)
				}, Utils.BigNumber(0))
				.toNumber()
		} else if (!_.isEmpty(doctorOrder.serviceCode)) {
			qty = this.calculateDaysBetween(doctorOrder.startDateTime, doctorOrder.endDateTime, 1)
			console.log("here")
		} else {
			for (
				let i = doctorOrder.startDateTime;
				doctorOrder.duration > 0 && i <= doctorOrder.endDateTime;
				i = i + doctorOrder.duration * 60000
			) {
				qty = Utils.BigNumber(qty).plus(doctorOrder.durationQty).toNumber()
			}
		}
		return this.BigNumber(doctorOrder.pricePerUnit).times(qty).toFixed(2)
	}

	static calculateNurseOrderQty = (
		nurseOrder,
		startDateTime = nurseOrder.startDateTime,
		endDateTime = nurseOrder.endDateTime
	) => {
		let days = Utils.BigNumber(this.calculateDaysBetween(startDateTime, endDateTime)).plus(1).toNumber()
		return Utils.BigNumber(days).times(nurseOrder.qtyPerDay).toNumber()
	}

	static calculateNurseOrderBalance = (nurseOrder) => {
		let days = Utils.BigNumber(this.calculateDaysBetween(nurseOrder.startDateTime, nurseOrder.endDateTime))
			.plus(1)
			.toNumber()
		return Utils.BigNumber(days).times(nurseOrder.qtyPerDay).times(nurseOrder.pricePerUnit).toFixed(2)
	}

	static calculatePatientDeposit = (transactions) => {
		return transactions
			.reduce((total, cur) => {
				return (total = total.plus(cur.amount))
			}, Utils.BigNumber(0))
			.toFixed(2)
	}

	static calculatePharmacyReturnOrderBalance = (pharmacyReturnOrders, pricePerUnit) => {
		if (pharmacyReturnOrders === null || pharmacyReturnOrders === undefined) {
			return 0
		}
		return pharmacyReturnOrders
			.reduce((total, cur) => {
				cur.items.forEach((item) => {
					total = total.plus(Utils.BigNumber(item.amount).times(pricePerUnit))
				})
				return total
			}, Utils.BigNumber(0))
			.toFixed(2)
	}

	static calculateBillingStatementBalance(billingStatements) {
		return billingStatements
			.filter((bs) => !bs.billing)
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
	}

	static convertArrayToObject = (array, key) => {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			}
		}, initialValue)
	}

	static converArrayToObjectByCustomKey = (array, keyArray) => {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[keyArray.reduce((obj, key) => {
					return obj + (item[key] !== undefined ? item[key].toLowerCase().trim() : "")
				}, "")]: item,
			}
		}, initialValue)
	}

	static convertEnum = (e, isReturnObj = true) => {
		let em = {
			CANCELLED: ResourceAssistance.Message.cancelled,
			DISPENSED: ResourceAssistance.Message.dispensed,
			IN_PROGRESS: ResourceAssistance.Message.inProgress,
			MODIFIED: ResourceAssistance.Message.modified,
			PENDING: ResourceAssistance.Message.pending,
			RECEIVED: ResourceAssistance.Message.received,
			VERIFIED: ResourceAssistance.Message.verified,
			ชาย: ResourceAssistance.Message.male,
			หญิง: ResourceAssistance.Message.female,
		}
		return isReturnObj ? translate(em[e]) : em[e]
	}

	static convertToObject(json) {
		// Check if an object is an array
		var isObject = function (value) {
			return typeof value === "object"
		}

		// Iterate object properties and store all reference keys and references
		var getKeys = function (obj, key) {
			var keys = []
			for (var i in obj) {
				// Skip methods
				if (!obj.hasOwnProperty(i)) {
					continue
				}

				if (isObject(obj[i])) {
					keys = keys.concat(getKeys(obj[i], key))
				} else if (i === key) {
					keys.push({ key: obj[key], obj: obj })
				}
			}

			return keys
		}

		var convertToObjectHelper = function (json, key, keys) {
			// Store all reference keys and references to object map
			if (!keys) {
				keys = getKeys(json, key)

				var convertedKeys = {}

				for (var i = 0; i < keys.length; i++) {
					convertedKeys[keys[i].key] = keys[i].obj
				}

				keys = convertedKeys
			}

			var obj = json

			// Iterate all object properties and object children
			// recursively and replace references with real objects
			for (var j in obj) {
				// Skip methods
				if (!obj.hasOwnProperty(j)) {
					continue
				}

				if (isObject(obj[j])) {
					// Property is an object, so process its children
					// (note well: recursive call)
					convertToObjectHelper(obj[j], key, keys)
				} else if (j === key) {
					// Remove reference id
					delete obj[j]
				} else if (keys[obj[j]]) {
					// Replace reference with real object
					obj[j] = keys[obj[j]]
				}
			}

			return obj
		}

		// As discussed above, the serializer needs to use some unique property name for
		// the IDs it generates. Here we use "@id" since presumably prepending the "@" to
		// the property name is adequate to ensure that it is unique. But any unique
		// property name can be used, as long as the same one is used by the serializer
		// and deserializer.
		//
		// Also note that we leave off the 3rd parameter in our call to
		// convertToObjectHelper since it will be initialized within that function if it
		// is not provided.
		return convertToObjectHelper(json, "@id")
	}

	static formatNumberFromStr(numStr) {
		if (!numStr) {
			return ""
		}
		let num = numStr.replace(/,/g, "")
		return BigNumber(num).isNaN() ? numStr.slice(0, numStr.length - 1) : this.formatNumWithComma(num)
	}

	static formatNumWithComma(num) {
		if (num !== undefined) {
			return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
		}
	}

	static formatDate(dateTime) {
		if (dateTime) {
			let date = new Date(dateTime)
			return new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds()
			).toLocaleDateString()
		}
		return ""
	}

	static formatTime(dateTime) {
		if (dateTime) {
			let date = new Date(dateTime)
			return date.toLocaleTimeString()
		}
		return ""
	}

	static formatDateTime(dateTime) {
		if (dateTime) {
			let date = new Date(dateTime)
			return date.toLocaleString()
		}
		return ""
	}

	static generateDate = (year = 0, month = 0, date = 0, hour = 0, minute = 0, second = 0) => {
		const d = new Date()
		return new Date(d.getFullYear() + year, d.getMonth() + month, d.getDate() + date, hour, minute, second)
	}

	static generateDateFromLong = (datetime = 0, year = 0, month = 0, date = 0, hour = 0, minute = 0, second = 0) => {
		const d = new Date(datetime)
		return new Date(
			d.getFullYear() + year,
			d.getMonth() + month,
			d.getDate() + date,
			d.getHours() + hour,
			d.getMinutes() + minute,
			d.getSeconds() + second
		)
	}

	static generateDateByFormat = (dateStr, format) => {
		format = format || "yyyy-mm-dd" // default format
		let parts = dateStr.match(/(\d+)/g)
		let i = 0,
			fmt = {}
		// extract date-part indexes from the format
		format.replace(/(yyyy|dd|mm)/g, function (part) {
			fmt[part] = i++
		})

		return new Date(parts[fmt["yyyy"]], parts[fmt["mm"]] - 1, parts[fmt["dd"]])
	}

	static getComputedStyle(html) {
		return window.getComputedStyle(html)
	}

	static groupBy(array, ...keys) {
		if (keys.length === 1) {
			let key = keys[0]
			return array.reduce((rv, x) => {
				;(rv[x[key]] = rv[x[key]] || []).push(x)
				return rv
			}, {})
		} else {
			return array.reduce((rv, x) => {
				let key = keys.reduce((combineKey, cur) => {
					if (_.isEmpty(combineKey)) {
						return combineKey.concat(x[cur])
					} else {
						return combineKey.concat("#", x[cur])
					}
				}, "")

				;(rv[key] = rv[key] || []).push(x)
				return rv
			}, {})
		}
	}

	static hasPrivilege(privilege, roles) {
		let permissions = []
		roles.forEach((role) => {
			permissions = permissions.concat(role.permissions)
		})
		return permissions.some((prv) => prv.displayName === privilege)
	}

	static isDevMode(env) {
		return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
	}

	static parseFloat(num) {
		return Math.round(parseFloat((num * Math.pow(10, 8)).toFixed(8))) / Math.pow(10, 8)
	}

	static preventEnterKeyPress(event) {
		let keyCode = event.keyCode ? event.keyCode : event.which
		if (keyCode === ResourceAssistance.KeyCode.enter) {
			event.preventDefault()
		}
	}

	static renderOptions(options, includeDefaultOption = true, defaultOptionValue = "", displayProperty = "displayName") {
		let html = options
			.sort((a, b) => Utils.sort(a[displayProperty], b[displayProperty]))
			.map((loc, key) => {
				return (
					<option
						key={key}
						value={key}
						style={loc.active !== undefined && !loc.active ? { backgroundColor: ResourceAssistance.CSS.Color.red } : {}}
					>
						{loc[displayProperty]}
					</option>
				)
			})

		if (includeDefaultOption) {
			return Object.assign([], html, [
				<option key={-1} value={defaultOptionValue}>
					{ResourceAssistance.Symbol.space}
				</option>,
				...html,
			])
		}

		return html
	}

	static renderSelects = (
		options,
		includeDefaultOption = true,
		defaultOptionValue = -1,
		displayProperty = "displayName"
	) => {
		let html = options
			.sort((a, b) => Utils.sort(a[displayProperty], b[displayProperty]))
			.map((loc, key) => {
				return (
					<Select.Option
						key={key}
						value={key}
						style={loc.active !== undefined && !loc.active ? { backgroundColor: ResourceAssistance.CSS.Color.red } : {}}
					>
						{loc[displayProperty]}
					</Select.Option>
				)
			})

		if (includeDefaultOption) {
			return Object.assign([], html, [
				<Select.Option key={-1} value={defaultOptionValue}>
					{ResourceAssistance.Symbol.space}
				</Select.Option>,
				...html,
			])
		}
		return html
	}

	static replaceDuplicateEmptyLine(str) {
		return str.replace(/^\s*$(?:\r\n?|\n)/gm, "")
	}

	static replaceAllEmptyLines(str) {
		return str.replace(/(?:\r\n|\r|\n)/g, " ")
	}

	static replaceDuplicateSpaces(str) {
		str = str.replace(/  +/g, " ").trim()
		return str.replace(/^,|,$/g, "")
	}

	static sort(x, y, array) {
		if (x < y) {
			return -1
		}
		if (x > y) {
			return 1
		}

		if (array && array.length > 0) {
			for (const each of array) {
				if (each[0] < each[1]) {
					return -1
				}
				if (each[0] > each[1]) {
					return 1
				}
			}
		}
		return 0
	}

	static trim(str) {
		if (str && typeof str === "string") {
			return Utils.replaceDuplicateSpaces(str.trim())
		} else {
			return str
		}
	}

	static getItemRelpsFrom = (items) => {
		return items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])
	}

	static base64ToArrayBuffer = (base64) => {
		var binaryString = window.atob(base64)
		var binaryLen = binaryString.length
		var bytes = new Uint8Array(binaryLen)
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i)
			bytes[i] = ascii
		}
		return bytes
	}

	// This function is used to convert base64 encoding to mime type (blob)
	static base64ToBlob = (base64, mime) => {
		mime = mime || ""
		var sliceSize = 1024
		var byteChars = window.atob(base64)
		var byteArrays = []

		for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
			var slice = byteChars.slice(offset, offset + sliceSize)

			var byteNumbers = new Array(slice.length)
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i)
			}

			var byteArray = new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		return new Blob(byteArrays, { type: mime })
	}

	static convertBlob = (blob, type, callback) => {
		return new Promise((resolve, reject) => {
			let canvas = this.createTempCanvas()
			let ctx = canvas.getContext("2d")
			let image = new Image()
			image.src = URL.createObjectURL(blob)
			image.onload = () => {
				canvas.width = image.width
				canvas.height = image.height
				ctx.drawImage(image, 0, 0)
				let result = this.dataURItoBlob(canvas.toDataURL(type))

				result.lastModified = blob.lastModified
				result.lastModifiedDate = blob.lastModifiedDate
				result.name = blob.name
				result.webkitRelativePath = blob.webkitRelativePath

				if (callback) {
					callback(result)
				} else {
					resolve(result)
				}
			}
		})
	}

	static dataURItoBlob = (dataURI) => {
		var byteString = window.atob(dataURI.split(",")[1])
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
		var ab = new ArrayBuffer(byteString.length)
		var ia = new Uint8Array(ab)
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		var blob = new Blob([ab], { type: mimeString })
		return blob
	}

	static createTempCanvas = () => {
		let canvas = document.createElement("CANVAS")
		canvas.style.display = "none"
		return canvas
	}

	static notification = (title, notifications, type) => {
		notification.destroy()
		if (_.isEmpty(notifications)) {
			return
		}
		notifications.forEach((each) => {
			let args = {
				placement: "topRight",
				top: 72,
				message: title,
				description: each.message,
				duration: 0,
			}
			switch (type) {
				case "success":
					notification.success(args)
					break
				case "error":
					notification.error(args)
					break
				case "info":
					notification.info(args)
					break
				case "warning":
					notification.warning(args)
					break
				default:
					notification.open(args)
					break
			}
		})
	}
}

export { Utils }
