import React, { Fragment } from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_LOCATION_BRANCHES,
	SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS,
	SET_HOSPITEL_MODAL_LOCATION_ORGS,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalLocationHospitel extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onDepartment = this.onDepartment.bind(this)
	}

	componentDidMount() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.ipd.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_ORGS, res.data.locations)
		}
		let reqInterceptor = () => {}
		let resInterceptor = () => {}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.modalLocation.selectedOrg.index !== this.props.hospitel.modalLocation.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.hospitel.modalLocation.branch)
			branchHtml.selectedIndex = 0
			this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
			if (this.props.hospitel.modalLocation.selectedOrg.org) {
				this.props.setObjArray(
					SET_HOSPITEL_MODAL_LOCATION_BRANCHES,
					this.props.hospitel.modalLocation.selectedOrg.org.locations
				)
			} else {
				this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_BRANCHES, [])
			}
		}

		if (
			prevProps.hospitel.modalLocation.selectedBranch.index !== this.props.hospitel.modalLocation.selectedBranch.index
		) {
			let department = document.getElementById(ResourceAssistance.ID.HOSPITEL.hospitel.modalLocation.department)
			department.selectedIndex = 0
			this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT, -1, null)
			if (this.props.hospitel.modalLocation.selectedBranch.branch) {
				this.props.setObjArray(
					SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS,
					this.props.hospitel.modalLocation.selectedBranch.branch.locations
				)
			} else {
				this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_ORGS, [])
		this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_BRANCHES, [])
		this.props.setObjArray(SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS, [])
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG,
				event.target.value,
				this.props.hospitel.modalLocation.orgs[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG, -1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH,
				event.target.value,
				this.props.hospitel.modalLocation.branches[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
		}
	}

	onDepartment(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT,
				event.target.value,
				this.props.hospitel.modalLocation.departments[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT, -1, null)
		}
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.org)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
								{Utils.renderOptions(this.props.hospitel.modalLocation.orgs)}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.branch)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITEL.hospitel.modalLocation.branch}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onBranch}
							>
								{Utils.renderOptions(this.props.hospitel.modalLocation.branches)}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.department)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITEL.hospitel.modalLocation.department}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onDepartment}
							>
								{Utils.renderOptions(this.props.hospitel.modalLocation.departments)}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalLocationHospitel)
