import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SignaturePad from "react-signature-canvas"

class AdmUserModalSignaturePad extends React.Component {
	render() {
		return (
			<Row noGutters>
				<Col>
					<SignaturePad ref={this.props.signatureRef} canvasProps={{ className: "signatureCanvas" }} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmUserModalSignaturePad))
