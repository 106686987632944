import _ from "lodash"
import React, { Fragment } from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { FaRegEdit } from "react-icons/fa"
import { GiPayMoney } from "react-icons/gi"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected } from "~/redux/action"
import {
	SET_CASHIER_FINANCIAL_PLAN_DISPLAY,
	SET_CASHIER_PAYMENT_DISPLAY,
	SET_CASHIER_SELECTED_HEALTH_CARE_PLAN,
} from "~/redux/type"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class CashierRightAction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightAction",
		}

		this.onFinancialPlan = this.onFinancialPlan.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onPay = this.onPay.bind(this)
		this.onPrintReceipt = this.onPrintReceipt.bind(this)
		this.onPrintCategoryReceipt = this.onPrintCategoryReceipt.bind(this)
	}

	isEditDisabled() {
		return !this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.some((bs) => !bs.billing)
	}

	isPayDisabled() {
		return (
			this.props.cashier.selectedAdmission.index === -1 ||
			this.props.cashier.selectedHealthCarePlan.index === -1 ||
			this.props.cashier.selectedAdmission.admission.dischargedBy ||
			!this.props.cashier.selectedHealthCarePlan.healthCarePlan.primaryHealthCarePlan ||
			this.props.cashier.patient.hospitels.some((hospitel) => !hospitel.dischargeSummary) ||
			this.props.cashier.selectedAdmission.admission.clinicalRecord.medicalRecords.some(
				(md) => md.checkOutDateTime === 0
			) ||
			!this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.some((bs) => !bs.billing)
		)
	}

	isPrintReciptDisabled() {
		let selectedHCP = this.props.cashier.selectedHealthCarePlan.healthCarePlan
		let foundHealthCarePlan = this.props.cashier.selectedAdmission.admission.billingPlans.find(
			(bp) =>
				bp.code === selectedHCP.healthCareCode &&
				bp.name === selectedHCP.healthCareName &&
				bp.description === selectedHCP.healthCareDescription
		)
		return !foundHealthCarePlan || _.isEmpty(foundHealthCarePlan.billings)
	}

	onFinancialPlan(event) {
		this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, -1, null)
		this.props.onModalDisplayAction(SET_CASHIER_FINANCIAL_PLAN_DISPLAY, true)
	}

	onEdit(event) {
		this.props.onModalDisplayAction(SET_CASHIER_FINANCIAL_PLAN_DISPLAY, true)
	}

	onPay(event) {
		this.props.onModalDisplayAction(SET_CASHIER_PAYMENT_DISPLAY, true)
	}

	onPrintReceipt() {
		let selectedHCP = this.props.cashier.selectedHealthCarePlan.healthCarePlan
		let foundHealthCarePlan = this.props.cashier.selectedAdmission.admission.billingPlans.find(
			(bp) =>
				bp.code === selectedHCP.healthCareCode &&
				bp.name === selectedHCP.healthCareName &&
				bp.description === selectedHCP.healthCareDescription
		)

		foundHealthCarePlan.billings
			.filter((b) => foundHealthCarePlan.billingStatements.some((bs) => bs.billing && bs.billing.id === b.id))
			.forEach((billing) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.cashier.healthCarePlanReceipt, "_blank")
				myWindow.data = PrintableDataFactory.generateHealthCarePlanReceiptData(
					this.props.cashier.patient,
					this.props.cashier.selectedAdmission.admission,
					foundHealthCarePlan.billingStatements.filter((bs) => bs.billing && bs.billing.id === billing.id),
					billing,
					foundHealthCarePlan.billings,
					this.props.login.user
				)
			})
	}

	onPrintCategoryReceipt() {
		let selectedHCP = this.props.cashier.selectedHealthCarePlan.healthCarePlan
		let foundHealthCarePlan = this.props.cashier.selectedAdmission.admission.billingPlans.find(
			(bp) =>
				bp.code === selectedHCP.healthCareCode &&
				bp.name === selectedHCP.healthCareName &&
				bp.description === selectedHCP.healthCareDescription
		)

		foundHealthCarePlan.billings
			.filter((b) => foundHealthCarePlan.billingStatements.some((bs) => bs.billing && bs.billing.id === b.id))
			.forEach((billing) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.cashier.healthCarePlanCategoryReceipt, "_blank")
				myWindow.data = PrintableDataFactory.generateHealthCarePlanReceiptCategoryData(
					this.props.cashier.patient,
					this.props.cashier.selectedAdmission.admission,
					foundHealthCarePlan.billingStatements.filter((bs) => bs.billing && bs.billing.id === billing.id),
					billing,
					foundHealthCarePlan.billings,
					this.props.login.user
				)
			})
	}

	render() {
		return (
			<Row id={this.state.componentName} style={{ justifyContent: "center" }}>
				{Utils.hasPrivilege(
					ResourceAssistance.Privilege.Hospital.Cashier.HealthCarePlanCreateEdit,
					this.props.login.user.roles
				) && (
					<Fragment>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								disabled={
									this.props.cashier.selectedAdmission.index === -1 ||
									this.props.cashier.selectedAdmission.admission.dischargedBy
								}
								onClick={this.onFinancialPlan}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.financialPlan)}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								disabled={
									this.props.cashier.selectedAdmission.index === -1 ||
									this.props.cashier.selectedHealthCarePlan.index === -1 ||
									this.props.cashier.selectedAdmission.admission.dischargedBy ||
									this.isEditDisabled()
								}
								onClick={this.onEdit}
							>
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col md={"auto"}>
							<div className="vertical"></div>
						</Col>
					</Fragment>
				)}

				{Utils.hasPrivilege(
					ResourceAssistance.Privilege.Hospital.Cashier.BillingPlanCreate,
					this.props.login.user.roles
				) && (
					<Fragment>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								disabled={this.isPayDisabled()}
								onClick={this.onPay}
							>
								<GiPayMoney size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.pay)}
							</Button>
						</Col>
						<Col md="auto">
							<div className="vertical"></div>
						</Col>
					</Fragment>
				)}
				<Col md={"auto"}>
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={
								this.props.cashier.selectedAdmission.index === -1 ||
								this.props.cashier.selectedHealthCarePlan.index === -1 ||
								!this.props.cashier.selectedAdmission.admission.dischargedBy
							}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu>
							<DropdownItem
								disabled={
									this.props.cashier.selectedAdmission.index === -1 ||
									this.props.cashier.selectedHealthCarePlan.index === -1 ||
									this.isPrintReciptDisabled()
								}
								onClick={this.onPrintReceipt}
							>
								{translate(ResourceAssistance.Message.receipt)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.cashier.selectedAdmission.index === -1 ||
									this.props.cashier.selectedHealthCarePlan.index === -1 ||
									this.isPrintReciptDisabled()
								}
								onClick={this.onPrintCategoryReceipt}
							>
								{translate(ResourceAssistance.Message.receiptCategory)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierRightAction))
