import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelModalDoctorOrderItems,
	setHospitelModalNurseOrdeItems,
	setHospitelPatients,
	setLoadingAction,
	setObjArray,
} from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING,
	SET_HOSPITEL_MODAL_LOCATION_DISPLAY,
	SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES,
	SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING,
	SET_HOSPITEL_PATIENTS_LOADING,
	SET_IPD_ICD_TYPES,
	SET_IPD_LEVELS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES,
} from "~/redux/type"
import "./Hospitel.css"
import HospitelLeft from "./left-pannel/HospitelLeft"
import HospitelRight from "./right-pannel/HospitelRight"

class Hospitel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Hospitel",
		}

		this.loadHospitels = this.loadHospitels.bind(this)
	}

	componentDidMount() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_LOCATION_DISPLAY, true)
		this.loadItems()
		this.loadTypes()
		this.loadCategories()
		this.loadICDs()
		this.loadLevels()
	}

	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES, res.data.types)
			this.props.setObjArray(SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES, res.data.types)
		}
		axios(params, callback)
	}

	loadItems() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setHospitelModalDoctorOrderItems(res.data.items)
			this.props.setHospitelModalNurseOrdeItems(res.data.items)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING, false)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING, true)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING, false)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadHospitels() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getHospitels,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.hospitel.department.id,
				startDateTime: this.props.hospitel.startDateTime,
				endDateTime: this.props.hospitel.endDateTime,
			},
		}
		let callback = (res) => {
			let patientMap = {}
			res.data.hospitels.forEach((hospitel) => {
				let patient = hospitel.patient
				if (patientMap[patient.id]) {
					patientMap[patient.id].hospitels.unshift(hospitel)
				} else {
					patientMap[patient.id] = {
						...patient,
						hospitels: [
							{
								...hospitel,
							},
						],
					}
				}
			})
			this.props.setHospitelPatients(Object.values(patientMap))
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getServices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES, res.data.serviceTypes)
			this.props.setObjArray(SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES, res.data.serviceTypes)
			this.props.setObjArray(SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES, res.data.serviceTypes)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING, false)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING, true)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING, false)
			this.props.setLoadingAction(SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadICDs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getICDs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_IPD_ICD_TYPES, res.data.types)
		}
		axios(params, callback)
	}

	loadLevels() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getLevels,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_IPD_LEVELS, res.data.types)
		}
		axios(params, callback)
	}

	render() {
		return (
			<Fragment>
				{this.props.hospitel.department && (
					<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
						<Row noGutters className={ResourceAssistance.CSS.fullSize}>
							<Col style={{ maxWidth: "437px" }}>
								<HospitelLeft loadHospitels={this.loadHospitels} />
							</Col>
							<Col>
								<HospitelRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setLoadingAction,
			setHospitelPatients,
			onModalDisplayAction,
			setHospitelModalDoctorOrderItems,
			setHospitelModalNurseOrdeItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Hospitel))
