import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setObjArray } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION,
} from "~/redux/type"
import RegisterModalClinicalRecordHealthCare from "./RegisterModalClinicalRecordHealthCare"
import RegisterModalClinicalRecordRef from "./RegisterModalClinicalRecordRef"
import RegModalClinicalRecordCarAccident from "./RegModalClinicalRecordCarAccident"
import RegModalClinicalRecordOther from "./RegModalClinicalRecordOther"
import RegModalClinicalRecordStatus from "./RegModalClinicalRecordStatus"

class RegisterModalClinicalRecordDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadHealthCares = this.loadHealthCares.bind(this)
		this.loadIsoPrecautions = this.loadIsoPrecautions.bind(this)
		this.loadFallRisks = this.loadFallRisks.bind(this)
	}

	loadHealthCares() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getHealthCares,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadIsoPrecautions() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getIsoPrecautions,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION, res.data.uts)
		}
		axios(params, callback)
	}

	loadFallRisks() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getFallRisks,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK, res.data.uts)
		}
		axios(params, callback)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<RegModalClinicalRecordStatus
					ref={this.props.statusRef}
					loadIsoPrecautions={this.loadIsoPrecautions}
					loadFallRisks={this.loadFallRisks}
					{...this.props}
				/>
				{this.props.ipd.modalClinicalRecord.isCarAccidentDisplay ? (
					<RegModalClinicalRecordCarAccident />
				) : (
					<RegisterModalClinicalRecordHealthCare loadHealthCares={this.loadHealthCares} />
				)}
				<RegisterModalClinicalRecordRef />
				<RegModalClinicalRecordOther />
				{/* <RegisterModalCRVitalSign />
				<Row>
					<Col>
						<Row>
							<Col>
								<RegisterModalCRPhysicalExamination />
							</Col>
						</Row>
					</Col>

					<Col>
						<Row>
							<Col>
								<RegisterModalCRTreatment />
							</Col>
						</Row>
					</Col>
				</Row>
				<RegisterModalCRReference /> */}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModalClinicalRecordDetails)
