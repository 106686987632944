import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { IoHourglassOutline } from "react-icons/io5"
import { GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_INV_FILTER_BY_LOCATION } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ReportInvByLocationFilterDetails from "./ReportInvByLocationFilterDetails"
import ReportInvByLocationFilterViewDetails from "./ReportInvByLocationFilterViewDetails"

const EStatus = {
	PENDING: "PENDING",
	IN_PROGRESS: "IN_PROGRESS",
	RECEIVED: "RECEIVED",
	CANCELLED: "CANCELLED",
}

class ReportInvByLocationFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportInvByLocationFilter",
		}

		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	generateStatusIcon(status) {
		switch (status) {
			case EStatus.IN_PROGRESS:
				return "\ue558"

			case EStatus.RECEIVED:
				return "\ue5ca"

			case EStatus.CANCELLED:
				return "\ue5cd"

			case EStatus.PENDING:
			default:
				return ""
		}
	}

	runReport(branch) {
		let data = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.inventoryByLocation,
			}),
			branchIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.branch,
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
			}),
			descriptionIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.description,
			}),
			brandIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.brandName,
			}),
			qtyIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.qty,
			}),
			unitIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unit,
			}),
			lotIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.lot,
			}),
			expiredIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.expired,
			}),
			inventoryIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.inventory,
			}),
			currentIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.current,
			}),
			minIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.min,
			}),
			maxIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.max,
			}),
			codeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.code,
			}),
			pricePerUnitIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unitPrice,
			}),
			totalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.totalPrice,
			}),
			grandTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.grandTotal,
			}),
			arrowIcon: "\ue5da",

			branch: this.props.filter.selectedBranch.branch.displayName,
			code: this.props.filter.code,
			description: this.props.filter.description,

			name: this.props.filter.selectedOrg.org.displayName,
			taxId: this.props.filter.selectedOrg.org.taxNo,
			address: this.props.filter.selectedBranch.branch.address.displayName,
			tel: this.props.filter.selectedBranch.branch.address.tel,
			fax: this.props.filter.selectedBranch.branch.address.fax,
		}

		if (this.props.filter.isQty) {
			Object.assign(data, {
				...data,
				inStockItems: branch.inStockItems.map((item) => {
					return Object.assign(
						{},
						{
							id: item.code,
							description: item.displayName,
							brand: item.brand,
							qty: Utils.formatNumWithComma(
								item.lots
									.reduce((total, lot) => {
										total = total.plus(lot.current)
										return total
									}, Utils.BigNumber(0))
									.toNumber()
							),
							unit: item.unit,
							lots: item.lots.map((lot) => {
								return Object.assign(
									{},
									{
										id: lot.number,
										qty: Utils.formatNumWithComma(lot.current),
										expired: Utils.formatDate(lot.expiredDateTime),
									}
								)
							}),
						}
					)
				}),
				inventories: branch.locations.map((inventory) => {
					return Object.assign(
						{},
						{
							name: inventory.displayName,
							description: inventory.description,
							inventoryDetails: inventory.inventoryDetails.map((itemDetail) => {
								return Object.assign(
									{},
									{
										id: itemDetail.code,
										description: itemDetail.displayName,
										brand: itemDetail.brand,
										unit: itemDetail.unit,
										// current: Utils.formatNumWithComma(itemDetail.current),
										current: branch.inStockItems.find(
											(inStockItem) =>
												inStockItem.displayName.trim().toLowerCase() === itemDetail.displayName.trim().toLowerCase() &&
												inStockItem.brand.trim().toLowerCase() === itemDetail.brand.trim().toLowerCase() &&
												inStockItem.supplierName.trim().toLowerCase() ===
													(itemDetail.supplierName
														? itemDetail.supplierName.trim().toLowerCase()
														: itemDetail.supplier.displayName.trim().toLowerCase())
										)
											? Utils.formatNumWithComma(
													branch.inStockItems
														.find(
															(inStockItem) =>
																inStockItem.displayName.trim().toLowerCase() ===
																	itemDetail.displayName.trim().toLowerCase() &&
																inStockItem.brand.trim().toLowerCase() === itemDetail.brand.trim().toLowerCase() &&
																inStockItem.supplierName.trim().toLowerCase() ===
																	(itemDetail.supplierName
																		? itemDetail.supplierName.trim().toLowerCase()
																		: itemDetail.supplier.displayName.trim().toLowerCase())
														)
														.lots.reduce((total, lot) => {
															total = total.plus(lot.current)
															return total
														}, Utils.BigNumber(0))
														.toNumber()
											  )
											: 0,
										min: Utils.formatNumWithComma(itemDetail.min),
										max: Utils.formatNumWithComma(itemDetail.max),
									}
								)
							}),
						}
					)
				}),
			})
			let myWindow = window.open(ResourceAssistance.Path.Report.Inv.byLocation.qty, "_blank")
			myWindow.data = data
		} else {
			Object.assign(data, {
				...data,
				inStockItems: branch.inStockItems.map((item) => {
					return Object.assign(
						{},
						{
							id: item.code,
							description: item.displayName,
							brand: item.brand,
							qty: Utils.formatNumWithComma(
								item.lots
									.reduce((total, lot) => {
										total = total.plus(lot.current)
										return total
									}, Utils.BigNumber(0))
									.toNumber()
							),
							unit: item.unit,
							pricePerUnit: Utils.formatNumWithComma(
								Utils.BigNumber(item.pricePerUnit).dividedBy(item.minQtyPerOrder).toFixed(2)
							),
							total: Utils.formatNumWithComma(
								item.lots
									.reduce((total, lot) => {
										total = total.plus(lot.current)
										return total
									}, Utils.BigNumber(0))
									.times(Utils.BigNumber(item.pricePerUnit).dividedBy(item.minQtyPerOrder))
									.toFixed(2)
							),
						}
					)
				}),
				grandTotal: Utils.formatNumWithComma(
					branch.inStockItems
						.reduce((grandTotal, item) => {
							grandTotal = grandTotal.plus(
								item.lots
									.reduce((total, lot) => {
										total = total.plus(lot.current)
										return total
									}, Utils.BigNumber(0))
									.times(Utils.BigNumber(item.pricePerUnit).dividedBy(item.minQtyPerOrder))
							)
							return grandTotal
						}, Utils.BigNumber(0))
						.toFixed(2)
				),
			})

			let myWindow = window.open(ResourceAssistance.Path.Report.Inv.byLocation.value, "_blank")
			myWindow.data = data
		}
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_INV_FILTER_BY_LOCATION, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.inv.getInvs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				orgId: this.props.filter.selectedOrg.org.id,
				branchId: this.props.filter.selectedBranch.branch.id,
				code: this.props.filter.code,
				description: this.props.filter.description,
			},
		}
		let callback = (res) => {
			if (res.data.locations.length > 0) {
				this.runReport(res.data.locations[0].locations[0])
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.reportByLocation)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<ReportInvByLocationFilterDetails />
							<ReportInvByLocationFilterViewDetails />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.inv.isReportByLocationDisplay,
	filter: state.report.inv.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportInvByLocationFilter))
