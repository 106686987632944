import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setFrontInventoryInventories, setLoadingAction, setObjArray } from "~/redux/action"
import {
	SET_FRONT_INVENTORY_INVENTORIES_LOADING,
	SET_FRONT_INVENTORY_ORGS,
	SET_FRONT_INVENTORY_ORGS_LOADING,
	SET_FRONT_INVENTORY_TYPES,
	SET_FRONT_INVENTORY_TYPES_LOADING,
} from "~/redux/type"
import FrontInventoryFilter from "./FrontInventoryFilter"
import FrontInventoryItem from "./FrontInventoryItem"
import FrontInventoryLot from "./FrontInventoryLot"

class FrontInventory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "FrontInventory",
		}

		this.getPharmacyInventories = this.getPharmacyInventories.bind(this)
	}

	componentDidMount() {
		this.loadLocations()
		this.loadItemTypes()
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.frontInventory.getOrgs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_FRONT_INVENTORY_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_ORGS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadItemTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.frontInventory.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_FRONT_INVENTORY_TYPES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getPharmacyInventories(locationId) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.frontInventory.getPharmacyInventories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				locationId: locationId,
			},
		}
		let callback = (res) => {
			this.props.setFrontInventoryInventories(res.data.pharmacyInventories)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row noGutters className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
							<FrontInventoryFilter getPharmacyInventories={this.getPharmacyInventories} />
							<FrontInventoryItem getPharmacyInventories={this.getPharmacyInventories} />
							<FrontInventoryLot />
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	FrontInventory: state.hospital.FrontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setFrontInventoryInventories,
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FrontInventory))
