import _ from "lodash"
import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setTfRequests } from "~/redux/action"
import { SET_TF_REQUEST_LOADING } from "~/redux/type"
import { SET_TF_NEW_TRANSFER_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import TFModalNTItemRow from "./TFModalNTItemRow"
import TFModalNTPendingItemRow from "./TFModalNTPendingItemRow"

class TFModalNT extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "TFModalNT",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_TF_NEW_TRANSFER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEqual(
			this.props.transfer.newTransferModal.pendingItemTable.filtered,
			this.props.transfer.transferItemTable.filtered
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let itemsByLocation = Utils.groupBy(this.props.transfer.newTransferModal.pendingItemTable.original, "locationId")

		let pendingTransfers = this.props.transfer.transferTable.original.filter((each) => !each.approval)

		let data

		if (_.isEmpty(itemsByLocation)) {
			data = [
				{
					id: this.props.transfer.selectedRequest.request.id,
					transferId: this.props.transfer.selectedTransfer.transfer.id,
					items: [],
				},
			]
		} else {
			data = Object.keys(itemsByLocation).map((key) => {
				let transfer = pendingTransfers.find((each) => each.location.id === key)
				let transferId = transfer ? transfer.id : 0
				let curItems = transfer ? transfer.items : []

				let items = itemsByLocation[key].map((item) => {
					//increase item amount if creating a new transfer order that it's location already exist. In other words, edit current transfer order will not increase the item amount since it is modify the item directly.
					if (
						transfer &&
						(this.props.transfer.selectedTransfer.index === -1 ||
							this.props.transfer.selectedTransfer.transfer.id !== transfer.id)
					) {
						let curItem = transfer.items.find(
							(each) =>
								(item.code + item.name + item.brand + item.unit + item.lotNum).trim().toLowerCase() ===
								(each.code + each.name + each.brand + each.unit + each.lotNum).trim().toLowerCase()
						)
						if (curItem) {
							Object.assign(item, {
								id: curItem.id,
								amount: Utils.BigNumber(curItem.amount).plus(item.amount).toNumber(),
							})

							curItems = curItems.filter((each) => {
								return each.id !== item.id
							})
						}
					} else {
						curItems = []
					}

					return {
						transferRequestItemId: item.transferRequestItemId,
						transferOrderItemId: item.id,
						typeId: item.type.id,
						lotNum: item.lotNum,
						expiredDateTime: item.expiredDateTime,
						code: item.code,
						name: item.displayName ? item.displayName : item.name,
						brand: item.brand,
						unit: item.unit,
						amount: item.amount,
					}
				})

				return {
					id: this.props.transfer.selectedRequest.request.id,
					transferId: transferId,
					branchId: key,
					items: items.concat(curItems),
				}
			})
		}

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.createT,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					noValidate
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newTransfer)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<TFModalNTItemRow />
							<TFModalNTPendingItemRow />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isTFNewTransferDisplay,
	transfer: state.transfer,
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setTfRequests, setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNT)
