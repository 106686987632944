export const SET_OPD_MODAL_LOCATION_ORGS = "SET_OPD_MODAL_LOCATION_ORGS"
export const SET_OPD_MODAL_LOCATION_SELECTED_ORG = "SET_OPD_MODAL_LOCATION_SELECTED_ORG"
export const SET_OPD_MODAL_LOCATION_SELECTED_BRANCH = "SET_OPD_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT = "SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT"
export const SET_OPD_LOCATION = "SET_OPD_LOCATION"
export const SET_OPD_MODAL_APPOINTMENT_PATIENTS = "SET_OPD_MODAL_APPOINTMENT_PATIENTS"
export const SET_OPD_MODAL_APPOINTMENT_DATE_TIME = "SET_OPD_MODAL_APPOINTMENT_DATE_TIME"
export const SET_OPD_MODAL_APPOINTMENT_SELECTED_PATIENT = "SET_OPD_MODAL_APPOINTMENT_SELECTED_PATIENT"
export const SET_OPD_SEARCH_START_DATETIME = "SET_OPD_SEARCH_START_DATETIME"
export const SET_OPD_SEARCH_END_DATETIME = "SET_OPD_SEARCH_END_DATETIME"
export const SET_OPD_PATIENTS_LOADING = "SET_OPD_PATIENTS_LOADING"
export const SET_OPD_PATIENTS = "SET_OPD_PATIENTS"
export const SET_OPD_SELECTED_PAITENT = "SET_OPD_SELECTED_PAITENT"
export const SET_OPD_MEDICAL_RECORDS = "SET_OPD_MEDICAL_RECORDS"
export const SET_OPD_SELECTED_MEDICAL_RECORD = "SET_OPD_SELECTED_MEDICAL_RECORD"
export const SET_OPD_SEARCH_PID = "SET_OPD_SEARCH_PID"
export const SET_OPD_SEARCH_IDENTIFICATION = "SET_OPD_SEARCH_IDENTIFICATION"
export const SET_OPD_MODAL_MEDICAL_RECORD_GA = "SET_OPD_MODAL_MEDICAL_RECORD_GA"
export const SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE = "SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE"
export const SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE"
export const SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS =
	"SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS"
export const SET_OPD_MODAL_MEDICAL_RECORD_CC = "SET_OPD_MODAL_MEDICAL_RECORD_CC"
export const SET_OPD_MODAL_MEDICAL_RECORD_PI = "SET_OPD_MODAL_MEDICAL_RECORD_PI"
export const SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS = "SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS"
export const SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT = "SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT"
export const SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN = "SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN"
export const SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN = "SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN"
export const SET_OPD_VITAL_SIGNS = "SET_OPD_VITAL_SIGNS"
export const SET_OPD_INTAKE_OUTPUTS = "SET_OPD_INTAKE_OUTPUTS"
export const SET_OPD_NURSE_NOTES = "SET_OPD_NURSE_NOTES"
export const SET_OPD_DOCTOR_ORDERS = "SET_OPD_DOCTOR_ORDERS"
export const SET_OPD_SELECTED_NURSE_ORDER = "SET_OPD_SELECTED_NURSE_ORDER"
export const SET_OPD_NURSE_ORDERS = "SET_OPD_NURSE_ORDERS"
export const SET_OPD_CERTIFICATES = "SET_OPD_CERTIFICATES"
export const SET_OPD_MODAL_DOCTOR_ORDER_TYPES = "SET_OPD_MODAL_DOCTOR_ORDER_TYPES"
export const SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE = "SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE"
export const SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION = "SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION"
export const SET_OPD_MODAL_DOCTOR_ORDER_ITEM_LOADING = "SET_OPD_MODAL_DOCTOR_ORDER_ITEM_LOADING"
export const SET_OPD_MODAL_DOCTOR_ORDER_ITEMS = "SET_OPD_MODAL_DOCTOR_ORDER_ITEMS"
export const SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM = "SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM"
export const SET_OPD_MODAL_DOCTOR_ORDER_UNITS = "SET_OPD_MODAL_DOCTOR_ORDER_UNITS"
export const SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS = "SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS"
export const SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM = "SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM"
export const SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME = "SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME"
export const SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME = "SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME"
export const SET_OPD_MODAL_DOCTOR_ORDER_NOTES = "SET_OPD_MODAL_DOCTOR_ORDER_NOTES"
export const SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY = "SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY"
export const SET_OPD_MODAL_DOCTOR_ORDER_DURATION = "SET_OPD_MODAL_DOCTOR_ORDER_DURATION"
export const SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION = "SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION"
export const SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT = "SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT"
export const SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK = "SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK"
export const SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES = "SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES"
export const SET_OPD_SELECTED_DOCTOR_ORDER = "SET_OPD_SELECTED_DOCTOR_ORDER"
export const SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME = "SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME"
export const SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME = "SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_START = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_START"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_END = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_END"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_ROLES = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_ROLES"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_ORGS = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_ORGS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_BRANCHES = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_BRANCHES"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS =
	"SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END"
export const SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START = "SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START"
export const SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES = "SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES"
export const SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY = "SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY"
export const SET_OPD_MODAL_DOCTOR_SERVICE_SEARCH_NAME = "SET_OPD_MODAL_DOCTOR_SERVICE_SEARCH_NAME"
export const SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE = "SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE"
export const SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES = "SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES"
export const SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES = "SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES"
export const SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE =
	"SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME = "SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME"
export const SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME = "SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME"
export const SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION = "SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION"
export const SET_OPD_MEDICAL_RECORD_LOADING = "SET_OPD_MEDICAL_RECORD_LOADING"
export const SET_OPD_MODAL_NURSE_DATETIME = "SET_OPD_MODAL_NURSE_DATETIME"
export const SET_OPD_MODAL_NURSE_FOCUS = "SET_OPD_MODAL_NURSE_FOCUS"
export const SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY = "SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY"
export const SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES = "SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES"
export const SET_OPD_MODAL_NURSE_ASSESSMENT = "SET_OPD_MODAL_NURSE_ASSESSMENT"
export const SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER = "SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER"
export const SET_OPD_MODAL_NURSE_EVALUATION = "SET_OPD_MODAL_NURSE_EVALUATION"
export const SET_OPD_MODAL_NURSE_INTERVENTION = "SET_OPD_MODAL_NURSE_INTERVENTION"
export const SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY = "SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY"
export const SET_OPD_SELECTED_NURSE_NOTE = "SET_OPD_SELECTED_NURSE_NOTE"
export const SET_OPD_SELECTED_VITAL_SIGN = "SET_OPD_SELECTED_VITAL_SIGN"
export const SET_OPD_MODAL_VITAL_SIGN_BP = "SET_OPD_MODAL_VITAL_SIGN_BP"
export const SET_OPD_MODAL_VITAL_SIGN_DATETIME = "SET_OPD_MODAL_VITAL_SIGN_DATETIME"
export const SET_OPD_MODAL_VITAL_SIGN_OXYGEN = "SET_OPD_MODAL_VITAL_SIGN_OXYGEN"
export const SET_OPD_MODAL_VITAL_SIGN_PR = "SET_OPD_MODAL_VITAL_SIGN_PR"
export const SET_OPD_MODAL_VITAL_SIGN_RR = "SET_OPD_MODAL_VITAL_SIGN_RR"
export const SET_OPD_MODAL_VITAL_SIGN_TEMPERATURE = "SET_OPD_MODAL_VITAL_SIGN_TEMPERATURE"
export const SET_OPD_MODAL_NURSE_ORDER_ITEMS = "SET_OPD_MODAL_NURSE_ORDER_ITEMS"
export const SET_OPD_MODAL_NURSE_ORDER_TYPES = "SET_OPD_MODAL_NURSE_ORDER_TYPES"
export const SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE = "SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE"
export const SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME = "SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME"
export const SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM = "SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM"
export const SET_OPD_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM = "SET_OPD_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM"
export const SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS = "SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS"
export const SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND = "SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND"
export const SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME = "SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME"
export const SET_OPD_MODAL_ADJUSTMENT_NOTES = "SET_OPD_MODAL_ADJUSTMENT_NOTES"
export const SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY = "SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY"
export const SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME = "SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME"
export const SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES = "SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES"
export const SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY = "SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY"
export const SET_OPD_MODAL_NURSE_SERVICE_SELECTED_SERVICE = "SET_OPD_MODAL_NURSE_SERVICE_SELECTED_SERVICE"
export const SET_OPD_MODAL_NURSE_SERVICE_SERVICES = "SET_OPD_MODAL_NURSE_SERVICE_SERVICES"
export const SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME = "SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME"
export const SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES = "SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES"
export const SET_OPD_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE =
	"SET_OPD_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME = "SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME"
export const SET_OPD_ICD_TYPES = "SET_OPD_ICD_TYPES"
export const SET_OPD_LEVELS = "SET_OPD_LEVELS"
export const SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES = "SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES"
export const SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT = "SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT"
export const SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES = "SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES"
export const SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE = "SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE"
export const SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT = "SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT"
export const SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL = "SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL"
export const SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY = "SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY =
	"SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY"
export const SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE = "SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE"
export const SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION = "SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION"
export const SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION = "SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION"
export const SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE = "SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE"
export const SET_OPD_INTAKE_TYPES = "SET_OPD_INTAKE_TYPES"
export const SET_OPD_OUTPUT_TYPES = "SET_OPD_OUTPUT_TYPES"
export const SET_OPD_SELECTED_INTAKE_OUTPUT = "SET_OPD_SELECTED_INTAKE_OUTPUT"
export const SET_OPD_ICD_DISPLAY = "SET_OPD_ICD_DISPLAY"
export const SET_OPD_INTAKE_OUTPUT_DISPLAY = "SET_OPD_INTAKE_OUTPUT_DISPLAY"
export const SET_OPD_GCS_DISPLAY = "SET_OPD_GCS_DISPLAY"
export const SET_OPD_SELECTED_GCS = "SET_OPD_SELECTED_GCS"
export const SET_OPD_GCS = "SET_OPD_GCS"
export const SET_OPD_PAIN_SCALE_DISPLAY = "SET_OPD_PAIN_SCALE_DISPLAY"
export const SET_OPD_SELECTED_PAIN_SCALE = "SET_OPD_SELECTED_PAIN_SCALE"
export const SET_OPD_PAIN_SCALE = "SET_OPD_PAIN_SCALE"
export const SET_OPD_SELECTED_PUPIL_SIZE = "SET_OPD_SELECTED_PUPIL_SIZE"
export const SET_OPD_PUPIL_SIZE = "SET_OPD_PUPIL_SIZE"
export const SET_OPD_PUPIL_SIZE_DISPLAY = "SET_OPD_PUPIL_SIZE_DISPLAY"
export const SET_OPD_NURSE_INFO_DISPLAY = "SET_OPD_NURSE_INFO_DISPLAY"
export const SET_OPD_DOCTOR_INFO_DISPLAY = "SET_OPD_DOCTOR_INFO_DISPLAY"
