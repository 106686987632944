import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelModalDoctorOrderPendingItems } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalDefaultOrderDetails from "./HospitelModalDefaultOrderDetails"

class HospitelModalDefaultOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalDefaultOrder",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.description)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.admitHospitel)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.oxygenCannular)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.oxygenMask)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.diet)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.vitalSign)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDefaultOrder.monitor))
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let pendingItems = this.props.hospitel.modalDoctorOrder.pendingItemTable.original

		Object.entries(this.props.hospitel.modalDefaultOrder).forEach(([key, value]) => {
			if ((!_.isEqual(key, "startDateTime") || !_.isEqual(key, "endDateTime")) && !_.isEmpty(value)) {
				pendingItems = pendingItems.filter((item) => !_.isEqual(item.displayName, value))
				let defaultOrder = {}
				Object.assign(defaultOrder, {
					startDateTime: this.props.hospitel.modalDefaultOrder.startDateTime,
					endDateTime: this.props.hospitel.modalDefaultOrder.endDateTime,
					displayName: value,
				})
				pendingItems.push(defaultOrder)
			}
		})

		this.props.setHospitelModalDoctorOrderPendingItems(pendingItems)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.instruction)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalDefaultOrderDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isDefaultOrderDisplay,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDefaultOrder)
