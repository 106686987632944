import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_IPD_CERTIFICATES,
	SET_HOSPITEL_IPD_END_DATETIME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES,
	SET_HOSPITEL_IPD_MODAL_REGISTER_DOB,
	SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT,
	SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TEL,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE,
	SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE,
	SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT,
	SET_HOSPITEL_IPD_MODAL_SEARCH_ID,
	SET_HOSPITEL_IPD_PATIENTS,
	SET_HOSPITEL_IPD_PATIENTS_LOADING,
	SET_HOSPITEL_IPD_PATIENT_LABS,
	SET_HOSPITEL_IPD_SEARCH_ID,
	SET_HOSPITEL_IPD_SEARCH_PATIENT_ID,
	SET_HOSPITEL_IPD_SELECTED_LAB,
	SET_HOSPITEL_IPD_SELECTED_PAITENT,
	SET_HOSPITEL_IPD_START_DATETIME,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_BP,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_O2,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_PR,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_RR,
	SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_TEMPERATURE,
	SET_REGISTER_ADMISSIONS,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER,
	SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE,
	SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY,
	SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL,
	SET_REGISTER_MODAL_SEARCH_FIRST_NAME,
	SET_REGISTER_MODAL_SEARCH_LAST_NAME,
	SET_REGISTER_MODAL_SEARCH_PID,
	SET_REGISTER_SELECTED_ADMISSION,
	SET_REGISTRATION_MODAL_BUDGET_BUDGET,
	SET_REGISTRATION_MODAL_BUDGET_CONTACT,
	SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARES,
	SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER,
	SET_REGISTRATION_MODAL_BUDGET_NOTE,
	SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES,
	SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES,
	SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_PHOTO,
	SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL,
	SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY,
	SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	startDateTime: Utils.generateDate().getTime(),
	endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
	searchPatientId: "",
	searchId: "",
	selectedPatient: {
		index: -1,
		patient: null,
	},
	selectedAdmission: {
		index: -1,
		admission: null,
	},
	patientTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.seq,
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Hospitel.identification,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	admissionTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.time],
		body: [],
		rowColor: [],
	},
	labTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.labNumber,
			ResourceAssistance.Hospitel.analyte,
			ResourceAssistance.Hospitel.result,
			ResourceAssistance.Message.status,
		],
		body: [],
		rowColor: [],
	},
	modalSearch: {
		pid: "",
		identificationNumber: "",
		firstName: "",
		lastName: "",
	},
	modalRegister: {
		photo: "",
		title: "",
		firstName: "",
		lastName: "",
		firstNameEng: "",
		lastNameEng: "",
		identificationNumber: "",
		gender: "",
		dob: Utils.generateDate().getTime(),
		tel: "",
		telSecondary: "",
		telTertiary: "",
		address: "",
		relativeFirstName: "",
		relativeLastName: "",
		relationship: "",
		relativeTel: "",
		relativeAddress: "",
		height: "",
		weight: "",
		underlyingDisease: ResourceAssistance.CONSTANT.NONE,
		allergies: ResourceAssistance.CONSTANT.NONE,
		pastIllnesses: ResourceAssistance.CONSTANT.NONE,
		pastSurgicalHistory: ResourceAssistance.CONSTANT.NONE,
		familyHealthHistory: ResourceAssistance.CONSTANT.NONE,
	},
	modalClinicalRecord: {
		isCarAccidentDisplay: false,
		hn: "",
		healthcareOther: "",
		arrival: "",
		language: "",
		healthcares: [],
		isolationPrecautions: [],
		fallRisks: [],
		selectedHealthCare: {
			index: -1,
			healthcare: null,
		},
		selectedTriage: {
			index: -1,
			triage: null,
		},
	},
	modalExpenseEst: {
		searchDescription: "",
		filterDoctor: false,
		filterNurse: false,
		filterCoordinator: false,
		filterService: false,
		filterMedicine: false,
		filterMedicalSupply: false,
		itemTypes: [],
		serviceTypes: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedOrder: {
			index: -1,
			order: null,
		},
		selectedPending: {
			index: -1,
			pending: null,
		},
		selectedBudget: {
			index: -1,
			budget: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
			],
			body: [],
			isLoading: false,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
		},
		pendingTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.time,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
				ResourceAssistance.Message.pricePerUnit,
				ResourceAssistance.Message.totalPrice,
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
		budgetTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.budget,
				ResourceAssistance.Message.contact,
				ResourceAssistance.Message.note,
				"",
			],
			body: [],
			colStyle: [],
		},
	},
	modalBudget: {
		healthcares: [],
		healthcareOther: "",
		budget: "",
		contact: "",
		note: "",
		selectedHealthcare: {
			index: -1,
			healthcare: null,
		},
	},
}

const ipdReducer = (state = init, action) => {
	switch (action.type) {
		case SET_HOSPITEL_IPD_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.date,
			})

		case SET_HOSPITEL_IPD_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.date,
			})

		case SET_HOSPITEL_IPD_PATIENTS:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_HOSPITEL_IPD_PATIENTS_LOADING:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_HOSPITEL_IPD_SEARCH_PATIENT_ID:
			return Object.assign({}, state, {
				searchPatientId: action.payload.text,
			})

		case SET_HOSPITEL_IPD_SEARCH_ID:
			return Object.assign({}, state, {
				searchId: action.payload.text,
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					title: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					firstName: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					lastName: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					identificationNumber: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					gender: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_DOB:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					dob: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_TEL:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					tel: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					address: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_SELECTED_PAITENT:
			return Object.assign({}, state, {
				selectedPatient: {
					index: action.payload.index,
					patient: action.payload.selected,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_SEARCH_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					identificationNumber: action.payload.text,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					height: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					weight: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					underlyingDisease: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					allergies: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					pastIllnesses: action.payload.value,
				},
			})

		case SET_HOSPITEL_IPD_SELECTED_LAB:
			return Object.assign({}, state, {
				selectedLab: {
					index: action.payload.index,
					lab: action.payload.selected,
				},
			})

		case SET_HOSPITEL_IPD_PATIENT_LABS:
			return Object.assign({}, state, {
				labTable: {
					...state.labTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_HOSPITEL_IPD_CERTIFICATES:
			return Object.assign({}, state, {
				certificateTable: {
					...state.certificateTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_TEMPERATURE:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					temperature: action.payload.value,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_PR:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					PR: action.payload.value,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_RR:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					RR: action.payload.value,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_BP:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					BP: action.payload.value,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_O2:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					O2: action.payload.value,
				},
			})

		case SET_REGISTER_MODAL_SEARCH_PID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					pid: action.payload.text,
				},
			})

		case SET_REGISTER_MODAL_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					firstName: action.payload.text,
				},
			})

		case SET_REGISTER_MODAL_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					lastName: action.payload.text,
				},
			})

		case SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					hn: action.payload.value,
				},
			})

		case SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					healthcares: action.payload.objs,
				},
			})

		case SET_REGISTER_MODAL_CLINICAL_RECORD_SELECTED_HEALTH_CARE:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					selectedHealthCare: {
						index: action.payload.index,
						healthcare: action.payload.selected,
					},
				},
			})

		case SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					healthcareOther: action.payload.value,
				},
			})

		case SET_REGISTER_SELECTED_ADMISSION:
			return Object.assign({}, state, {
				selectedAdmission: {
					index: action.payload.index,
					admission: action.payload.selected,
				},
			})

		case SET_REGISTER_ADMISSIONS:
			return Object.assign({}, state, {
				admissionTable: {
					...state.admissionTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					pastSurgicalHistory: action.payload.value,
				},
			})

		case SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					familyHealthHistory: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					pendingTable: {
						...state.modalExpenseEst.pendingTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					selectedPending: {
						index: action.payload.index,
						pending: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					itemTable: {
						...state.modalExpenseEst.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					itemTypes: action.payload.objs,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					selectedOrder: {
						index: action.payload.index,
						order: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					searchDescription: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					serviceTypes: action.payload.objs,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					serviceTable: {
						...state.modalExpenseEst.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterDoctor: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterNurse: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterCoordinator: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterService: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterMedicine: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					filterMedicalSupply: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARES:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					healthcares: action.payload.objs,
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					selectedHealthcare: {
						index: action.payload.index,
						healthcare: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					healthcareOther: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_BUDGET:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					budget: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_CONTACT:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					contact: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_BUDGET_NOTE:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					note: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					budgetTable: {
						...state.modalExpenseEst.budgetTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_BUDGET:
			return Object.assign({}, state, {
				modalExpenseEst: {
					...state.modalExpenseEst,
					selectedBudget: {
						index: action.payload.index,
						budget: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					isCarAccidentDisplay: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_ARRIVAL:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					arrival: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_TRIAGE:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					selectedTriage: {
						index: action.payload.index,
						triage: action.payload.selected,
					},
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_ISOLATION_PRECAUTION:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					isolationPrecautions: action.payload.objs,
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_FALL_RISK:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					fallRisks: action.payload.objs,
				},
			})
		case SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE:
			return Object.assign({}, state, {
				modalClinicalRecord: {
					...state.modalClinicalRecord,
					language: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					firstNameEng: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					lastNameEng: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					telSecondary: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					telTertiary: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					relativeFirstName: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					relativeLastName: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					relationship: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					relativeTel: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					relativeAddress: action.payload.value,
				},
			})
		case SET_REGISTRATION_MODAL_REGISTER_PHOTO:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					photo: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default ipdReducer
