import { ResourceAssistance } from "~/i18n"
import {
	SET_CASHIER_ADMISSIONS,
	SET_CASHIER_ADMISSION_BALANCE,
	SET_CASHIER_ADMISSION_LOADING,
	SET_CASHIER_HEALTH_CARE_PLANS,
	SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_CLOSE,
	SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE,
	SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
	SET_CASHIER_MODAL_CERTIFICATE_PENDING,
	SET_CASHIER_MODAL_CERTIFICATE_SELECTED_PENDING,
	SET_CASHIER_MODAL_CERTIFICATE_SELECTED_UNCHARGED,
	SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED,
	SET_CASHIER_MODAL_DOCTOR_ORDERS,
	SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS,
	SET_CASHIER_MODAL_DOCTOR_SELECTED_ORDER,
	SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE,
	SET_CASHIER_MODAL_LAB_PENDING_LABS,
	SET_CASHIER_MODAL_LAB_SELECTED_PENDING,
	SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED,
	SET_CASHIER_MODAL_LAB_UNCHARGED_LABS,
	SET_CASHIER_MODAL_NURSE_ORDERS,
	SET_CASHIER_MODAL_NURSE_PENDING_ORDERS,
	SET_CASHIER_MODAL_NURSE_SELECTED_ORDER,
	SET_CASHIER_MODAL_NURSE_SELECTED_PENDING_ORDER,
	SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT,
	SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT,
	SET_CASHIER_MODAL_PAYMENT_TOTAL,
	SET_CASHIER_MODAL_PAYMENT_TYPES,
	SET_CASHIER_MODAL_SEARCH_END_DATETIME,
	SET_CASHIER_MODAL_SEARCH_FIRST_NAME,
	SET_CASHIER_MODAL_SEARCH_ID,
	SET_CASHIER_MODAL_SEARCH_LAST_NAME,
	SET_CASHIER_MODAL_SEARCH_PATIENTS,
	SET_CASHIER_MODAL_SEARCH_PID,
	SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT,
	SET_CASHIER_MODAL_SEARCH_START_DATETIME,
	SET_CASHIER_PATIENT,
	SET_CASHIER_SELECTED_ADMISSION,
	SET_CASHIER_SELECTED_HEALTH_CARE_PLAN,
	SET_CASHIER_SELECTED_SERVICE,
	SET_CASHIER_SERVICES,
} from "~/redux/type"

const init = {
	patient: null,
	searchPatientId: "",
	searchId: "",
	selectedAdmission: {
		index: -1,
		admission: null,
	},
	selectedService: {
		index: -1,
		service: null,
	},
	selectedHealthCarePlan: {
		index: -1,
		healthCarePlan: null,
	},
	admissionTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.id, ResourceAssistance.Message.date, ResourceAssistance.Message.time],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	healthCarePlanTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Hospitel.healthCare,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.paid,
			ResourceAssistance.Message.unpaid,
			ResourceAssistance.Hospitel.balance,
		],
		body: [],
		rowColor: [],
	},
	balanceTable: {
		header: [
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.totalPaid,
			ResourceAssistance.Message.totalUnpaid,
			ResourceAssistance.Hospitel.balance,
		],
		body: [],
	},
	serviceTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Hospitel.adjustments,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Hospitel.balance,
		],
		body: [],
		rowColor: [],
	},
	modalSearch: {
		startDateTime: 0,
		endDateTime: 0,
		pid: "",
		identificationNumber: "",
		firstName: "",
		lastName: "",
		selectedPatient: {
			index: -1,
			patient: null,
		},
		patientTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Hospitel.identification,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Hospitel.dateOfBirth,
			],
			body: [],
		},
	},
	modalLab: {
		selectedUncharged: {
			index: -1,
			lab: null,
		},
		selectedPending: {
			index: -1,
			lab: null,
		},
		unchargedTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.time,
				ResourceAssistance.Hospitel.labNumber,
				ResourceAssistance.Message.status,
				ResourceAssistance.Hospitel.service,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
		pendingTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.time,
				ResourceAssistance.Hospitel.labNumber,
				ResourceAssistance.Message.status,
				ResourceAssistance.Hospitel.service,
				ResourceAssistance.Message.totalPrice,
				ResourceAssistance.Hospitel.adjustments,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
	},
	modalCertificate: {
		selectedUncharged: {
			index: -1,
			certificate: null,
		},
		selectedPending: {
			index: -1,
			certificate: null,
		},
		unchargedTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.date,
				ResourceAssistance.Hospitel.symptoms,
				ResourceAssistance.Hospitel.diagnosis,
				ResourceAssistance.Hospitel.doctorOpinion,
				ResourceAssistance.Hospitel.doctorName,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
		pendingTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
				ResourceAssistance.Hospitel.symptoms,
				ResourceAssistance.Hospitel.diagnosis,
				ResourceAssistance.Hospitel.doctorOpinion,
				ResourceAssistance.Hospitel.doctorName,
				ResourceAssistance.Message.totalPrice,
				ResourceAssistance.Hospitel.adjustments,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
	},
	modalDoctor: {
		selectedOrder: {
			index: -1,
			order: null,
		},
		selectedPendingOrder: {
			index: -1,
			order: null,
		},
		orderTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
				ResourceAssistance.Message.pricePerUnit,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
			rowColor: [],
		},
		pendingOrderTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.totalPrice,
				ResourceAssistance.Hospitel.adjustments,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
	},
	modalNurse: {
		selectedOrder: {
			index: -1,
			order: null,
		},
		selectedPendingOrder: {
			index: -1,
			order: null,
		},
		orderTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
				ResourceAssistance.Message.qtyPerDay,
				ResourceAssistance.Message.pricePerUnit,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
			rowColor: [],
		},
		pendingOrderTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.fsCode,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.totalPrice,
				ResourceAssistance.Hospitel.adjustments,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
	},
	modalAdjustment: {
		id: "",
		isClose: false,
		isContinue: false,
		isQtyPerDayDisplay: true,
		isBalanceDisplay: true,
		isDiscountDisplay: true,
		balance: 0,
		qtyPerDay: "",
		amount: 0,
		discountPercent: 0,
		description: ResourceAssistance.CONSTANT.NONE,
	},
	modalFinancialPlan: {
		healthCares: [],
		selectedHealthCare: {
			index: -1,
			healthCare: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.totalPrice,
				ResourceAssistance.Hospitel.adjustments,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Hospitel.balance,
			],
			body: [],
		},
	},
	modalPayment: {
		isSkipPayment: false,
		total: "",
		types: [],
		selectedPayment: {
			index: -1,
			payment: null,
		},
	},
}

const cashierReducer = (state = init, action) => {
	switch (action.type) {
		case SET_CASHIER_MODAL_SEARCH_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					identificationNumber: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_PID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					pid: action.payload.value,
				},
			})

		case SET_CASHIER_PATIENT:
			return Object.assign({}, state, {
				patient: action.payload.value,
			})

		case SET_CASHIER_ADMISSION_LOADING:
			return Object.assign({}, state, {
				admissionTable: {
					...state.admissionTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_CASHIER_SELECTED_ADMISSION:
			return Object.assign({}, state, {
				selectedAdmission: {
					index: action.payload.index,
					admission: action.payload.selected,
				},
			})

		case SET_CASHIER_ADMISSIONS:
			return Object.assign({}, state, {
				admissionTable: {
					...state.admissionTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_CASHIER_MODAL_LAB_UNCHARGED_LABS:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					unchargedTable: {
						...state.modalLab.unchargedTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_LAB_PENDING_LABS:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					pendingTable: {
						...state.modalLab.pendingTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedUncharged: {
						index: action.payload.index,
						lab: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_LAB_SELECTED_PENDING:
			return Object.assign({}, state, {
				modalLab: {
					...state.modalLab,
					selectedPending: {
						index: action.payload.index,
						lab: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_SERVICES:
			return Object.assign({}, state, {
				serviceTable: {
					...state.serviceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					unchargedTable: {
						...state.modalCertificate.unchargedTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_CERTIFICATE_PENDING:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					pendingTable: {
						...state.modalCertificate.pendingTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_CERTIFICATE_SELECTED_UNCHARGED:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedUncharged: {
						index: action.payload.index,
						certificate: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_CERTIFICATE_SELECTED_PENDING:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedPending: {
						index: action.payload.index,
						certificate: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_SELECTED_SERVICE:
			return Object.assign({}, state, {
				selectedService: {
					index: action.payload.index,
					service: action.payload.selected,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					qtyPerDay: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					amount: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					description: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_ID:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					id: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_CLOSE:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isClose: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isContinue: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isQtyPerDayDisplay: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					firstName: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					lastName: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_PATIENTS:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					patientTable: {
						...state.modalSearch.patientTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					selectedPatient: {
						index: action.payload.index,
						patient: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_DOCTOR_ORDERS:
			return Object.assign({}, state, {
				modalDoctor: {
					...state.modalDoctor,
					orderTable: {
						...state.modalDoctor.orderTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_CASHIER_MODAL_DOCTOR_SELECTED_ORDER:
			return Object.assign({}, state, {
				modalDoctor: {
					...state.modalDoctor,
					selectedOrder: {
						index: action.payload.index,
						order: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS:
			return Object.assign({}, state, {
				modalDoctor: {
					...state.modalDoctor,
					pendingOrderTable: {
						...state.modalDoctor.pendingOrderTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER:
			return Object.assign({}, state, {
				modalDoctor: {
					...state.modalDoctor,
					selectedPendingOrder: {
						index: action.payload.index,
						order: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_NURSE_ORDERS:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					orderTable: {
						...state.modalNurse.orderTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_CASHIER_MODAL_NURSE_SELECTED_ORDER:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					selectedOrder: {
						index: action.payload.index,
						order: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_NURSE_PENDING_ORDERS:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					pendingOrderTable: {
						...state.modalNurse.pendingOrderTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_MODAL_NURSE_SELECTED_PENDING_ORDER:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					selectedPendingOrder: {
						index: action.payload.index,
						order: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES:
			return Object.assign({}, state, {
				modalFinancialPlan: {
					...state.modalFinancialPlan,
					healthCares: action.payload.objs,
				},
			})

		case SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE:
			return Object.assign({}, state, {
				modalFinancialPlan: {
					...state.modalFinancialPlan,
					selectedHealthCare: {
						index: action.payload.index,
						healthCare: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_BALANCE:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					balance: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS:
			return Object.assign({}, state, {
				modalFinancialPlan: {
					...state.modalFinancialPlan,
					serviceTable: {
						...state.modalFinancialPlan.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_CASHIER_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				healthCarePlanTable: {
					...state.healthCarePlanTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_CASHIER_SELECTED_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				selectedHealthCarePlan: {
					index: action.payload.index,
					healthCarePlan: action.payload.selected,
				},
			})

		case SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalFinancialPlan: {
					...state.modalFinancialPlan,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isBalanceDisplay: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_PAYMENT_TYPES:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					types: action.payload.objs,
				},
			})

		case SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.selected,
					},
				},
			})

		case SET_CASHIER_MODAL_PAYMENT_TOTAL:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					total: action.payload.value,
				},
			})

		case SET_CASHIER_ADMISSION_BALANCE:
			return Object.assign({}, state, {
				balanceTable: {
					...state.balanceTable,
					body: action.payload.body,
				},
			})

		case SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					isSkipPayment: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_START_DATETIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					startDateTime: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_SEARCH_END_DATETIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					endDateTime: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isDiscountDisplay: action.payload.value,
				},
			})

		case SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discountPercent: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default cashierReducer
