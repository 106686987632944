import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID } from "~/redux/type"

class ReportCashierBillingToTodayFilterAdmission extends React.Component {
	constructor(props) {
		super(props)

		this.onAdmissionId = this.onAdmissionId.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID, "")
	}

	onAdmissionId(event) {
		this.props.setValue(SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>{translate(ResourceAssistance.Message.id)}</InputGroup.Text>
						</InputGroup.Prepend>
						<FormControl required type={ResourceAssistance.FormControl.type.number} onChange={this.onAdmissionId} />
					</InputGroup>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierBillingToTodayFilterAdmission))
