import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_DATETIME } from "~/redux/type"
import DatePicker from "~/view/component/date_picker/DatePicker"

class OPDModalNurseDate extends React.Component {
	constructor(props) {
		super(props)

		this.onRecordDateTime = this.onRecordDateTime.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_OPD_MODAL_NURSE_DATETIME, this.props.opd.selectedNurseNote.nurseNote.dateTime)
		} else {
			this.props.setValue(SET_OPD_MODAL_NURSE_DATETIME, moment().valueOf())
		}
	}

	onRecordDateTime(date) {
		this.props.setValue(SET_OPD_MODAL_NURSE_DATETIME, date.getTime())
	}

	render() {
		return (
			<Row style={{ alignSelf: "center" }}>
				<Col md="auto">
					<DatePicker
						minDate={moment().toDate()}
						showDisabledMonthNavigation
						startDate={moment(this.props.opd.modalNurse.recordDateTime).toDate()}
						onChange={this.onRecordDateTime}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseDate))
