import { Descriptions } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class HospitalModalPatientClinicalRecordOther extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.other)}</legend>
						<Descriptions
							size={"small"}
							style={{ flex: "unset", display: "unset" }}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={1}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.translator)} span={1}>
								{this.props.patient.clinicalRecord.language
									? this.props.patient.clinicalRecord.language
									: translate(ResourceAssistance.Message.no)}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

HospitalModalPatientClinicalRecordOther.propTypes = {
	patient: propTypes.object,
}

HospitalModalPatientClinicalRecordOther.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitalModalPatientClinicalRecordOther
