import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setOPDPatients, setValue } from "~/redux/action"
import { SET_OPD_PATIENTS_LOADING, SET_OPD_SEARCH_END_DATETIME, SET_OPD_SEARCH_START_DATETIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OutpatientLeftDate extends React.Component {
	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_OPD_SEARCH_START_DATETIME, moment().startOf("day").valueOf())
		this.props.setValue(SET_OPD_SEARCH_END_DATETIME, moment().endOf("day").milliseconds(0).valueOf())
	}

	onDateChange(value, dateString) {
		this.props.setValue(SET_OPD_SEARCH_START_DATETIME, value ? value.valueOf() : moment().startOf("day").valueOf())
		this.props.setValue(
			SET_OPD_SEARCH_END_DATETIME,
			value ? moment(value).endOf("day").milliseconds(0).valueOf() : moment().endOf("day").milliseconds(0).valueOf()
		)
	}

	onSearch() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.opd.location.id,
				startDateTime: this.props.opd.startDateTime,
				endDateTime: this.props.opd.endDateTime,
			},
		}
		let callback = (res) => {
			res.data.patients.forEach((patient) =>
				Object.assign(patient, {
					loadPreviousMedicalRecords: true,
				})
			)
			this.props.setOPDPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<AntDatePicker
						size={"small"}
						format={"DD-MM-YYYY"}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
						defaultValues={[moment(this.props.opd.startDateTime)]}
						onChange={this.onDateChange}
					/>
				</Col>
				<Col sm="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearch}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientLeftDate))
