import axios from "axios"
import axiosRetry from "axios-retry"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

// export default axios.create({
// let instance = axios.create({
// 	baseURL: "http://localhost:8080",
// })

const REACT_APP_URL_DEV = "http://localhost:5000"
const REACT_APP_URL_PRO = process.env.REACT_APP_API_URL

export const API_URL = Utils.isDevMode() ? REACT_APP_URL_DEV : REACT_APP_URL_PRO

const singleRequest = async (
	params = {},
	callback,
	errorHandler = () => {},
	reqInterceptor = () => {},
	resInterceptor = () => {}
) => {
	let instance = axios.create({
		baseURL: API_URL,
		// headers: {
		// "Access-Control-Allow-Origin": "*",
		// },
	})

	if (params.method === "GET") {
	} else {
	}
	axiosRetry(instance, {
		retries: params.method === "GET" ? 3 : 0,
		retryDelay: axiosRetry.exponentialDelay,
		retryCondition: (input, input_2, input_3, input_4) => {
			// console.log("retry axios")
			// console.log("input: " + input)
			// console.log("input_detail: " + input.response.status)
			return input.response.status !== 401
		},
	})

	instance.interceptors.request.use((request) => {
		reqInterceptor(request)
		return request
	})

	instance.interceptors.response.use((response) => {
		resInterceptor(response)
		return response
	})

	await instance(params)
		.then((res) => {
			callback(res)
		})
		.catch((error) => {
			console.log(error)
			if (error.response.status === 401 && window.location.pathname !== ResourceAssistance.Path.login) {
				window.location.pathname = ResourceAssistance.Path.login
			}
			errorHandler(error)
		})
}

const multipleRequests = async (
	params = [],
	callbacks = [],
	errorHandler = (error) => {},
	reqInterceptors = [() => {}],
	resInterceptors = [() => {}]
) => {
	await Promise.all(
		params.map((param, index) => {
			let instance = axios.create({
				baseURL: API_URL,
			})

			axiosRetry(instance, {
				retries: 3,
				retryDelay: axiosRetry.exponentialDelay,
				retryCondition: (input, input_2, input_3, input_4) => {
					// console.log("multiple retry axios")
					// console.log("input: " + input)
					//401 unauthorized
					return input.response.status !== 401
				},
			})

			instance.interceptors.request.use((request) => {
				reqInterceptors[index](request)
				return request
			})

			instance.interceptors.response.use((response) => {
				resInterceptors[index](response)
				return response
			})
			return instance(param)
		})
	)
		.then((responseArr) => {
			responseArr.forEach((res, index) => {
				callbacks[index](res)
			})
		})
		.catch((error) => {
			errorHandler(error)
		})
}

export { singleRequest as axios, multipleRequests as axiosAll }
