import _ from "lodash"
import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdCheck, MdOutlineCancel, MdOutlineClear } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setPoSelectedSupplier, setPoSuppliers } from "~/redux/action"
import { onModalDisplayAction } from "~/redux/action/modal/actions"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_PO_EDIT_DISPLAY } from "~/redux/type/type/MODAL"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class PORightSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORightSecondRow",
		}

		this.onEdit = this.onEdit.bind(this)
		this.onInspectorApprove = this.onInspectorApprove.bind(this)
		this.onApprovalApprove = this.onApprovalApprove.bind(this)
		this.onDisapprove = this.onDisapprove.bind(this)
		this.onPrintAttachment = this.onPrintAttachment.bind(this)
		this.onPrintPo = this.onPrintPo.bind(this)
	}

	onEdit(event) {
		this.props.onModalDisplayAction(SET_PO_EDIT_DISPLAY, true)
	}

	approve(data, func = () => {}) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.po.updatePoApproval,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign(
				{},
				{
					id: this.props.selectedPo.po.id,
					userId: this.props.login.user.id,
					inspectorApproved: this.props.selectedPo.po.inspectorApproved,
					approvalApproved: this.props.selectedPo.po.approvalApproved,
				},
				{
					...data,
				}
			),
		}

		let callback = (res) => {
			this.props.updateOriginalOrders(res.data.orders)
			func(res.data.orders[0])
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onInspectorApprove(event) {
		if (
			this.props.selectedPo.po.suppliers.some((supplier, index) => {
				if (
					!supplier.orderDetail.payment ||
					!supplier.orderDetail.paymentTerms ||
					!supplier.orderDetail.requiredDateTime
				) {
					this.props.setPoSelectedSupplier(index, supplier)
					return true
				}
				return false
			})
		) {
			this.props.onModalDisplayAction(SET_PO_EDIT_DISPLAY, true)
			return
		}

		let data = {
			inspectorApproved: true,
		}
		this.approve(data)
	}

	onApprovalApprove(event) {
		let data = {
			approvalApproved: true,
		}
		this.approve(data, (order) => {
			this.props.setPoSuppliers(order.suppliers)
			this.props.print(order, order.suppliers)
			this.props.setPoSuppliers([])
		})
	}

	onDisapprove(event) {
		let data = {
			userId: 0,
			inspectorApproved: false,
			approvalApproved: false,
		}
		this.approve(data)
	}

	onPrintAttachment() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.po.downloadFile,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				path: this.props.selectedSupplier.supplier.orderDetail.attachmentFilePath,
				name: this.props.selectedSupplier.supplier.orderDetail.attachmentFileName,
			},
		}

		let callback = (res) => {
			let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
			myWindow.data = PrintableDataFactory.generatePdfFileData(res.data)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPrintPo() {
		this.props.print(this.props.selectedPo.po, [this.props.selectedSupplier.supplier])
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col md="auto">
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<DropdownToggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.selectedSupplier.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</DropdownToggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								disabled={this.props.selectedSupplier.index === -1 || !this.props.selectedPo.po.approvalApproved}
								onClick={this.onPrintPo}
							>
								{translate(ResourceAssistance.Message.purchaseOrder)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.selectedSupplier.index === -1 ||
									_.isEmpty(this.props.selectedSupplier.supplier.orderDetail.attachmentFileName)
								}
								onClick={this.onPrintAttachment}
							>
								{translate(ResourceAssistance.Message.attachment)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.selectedSupplier.index === -1 ||
								this.props.selectedPo.index === -1 ||
								this.props.selectedPo.po.inspectorApproved
							}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				)}

				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Inspector, this.props.login.user.roles) ||
					(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Approval, this.props.login.user.roles) &&
						Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles))) && (
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Inspector, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.selectedPo.index === -1 || this.props.selectedPo.po.inspectorApproved}
							onClick={this.onInspectorApprove}
						>
							{this.props.selectedPo.po && this.props.selectedPo.po.inspectorApproved ? (
								<MdCheck size={ResourceAssistance.ReactIcon.size} />
							) : (
								<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
							)}
							{translate(ResourceAssistance.Message.inspector)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Approval, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.selectedPo.index === -1 ||
								this.props.selectedPo.po.approvalApproved ||
								!this.props.selectedPo.po.inspectorApproved
							}
							onClick={this.onApprovalApprove}
						>
							{this.props.selectedPo.po && this.props.selectedPo.po.approvalApproved ? (
								<MdCheck size={ResourceAssistance.ReactIcon.size} />
							) : (
								<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
							)}
							{translate(ResourceAssistance.Message.approver)}
						</Button>
					</Col>
				)}

				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Inspector, this.props.login.user.roles) ||
					Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Approval, this.props.login.user.roles)) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.red}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.selectedPo.index === -1 ||
								!this.props.selectedPo.po.inspectorApproved ||
								this.props.selectedPo.po.approvalApproved
							}
							onClick={this.onDisapprove}
						>
							<MdOutlineCancel size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.unapproved)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
	selectedPo: state.purchaseOrder.selectedPo,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPoSelectedSupplier,
			setPoSuppliers,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightSecondRow)
