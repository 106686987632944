import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdmIModalNIActive,
	setAdmIModalNIBonus,
	setAdmIModalNIBrand,
	setAdmIModalNICode,
	setAdmIModalNIDiscount,
	setAdmIModalNIDose,
	setAdmIModalNIPricePerUnit,
	setAdmIModalNIQtyPerOrder,
	setAdmIModalNISelectedForm,
	setAdmIModalNISelectedSupplier,
	setAdmIModalNISelectedTax,
	setAdmIModalNISelectedUnit,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmIModalNIRight extends React.Component {
	constructor(props) {
		super(props)

		this.onCode = this.onCode.bind(this)
		this.onForm = this.onForm.bind(this)
		this.onDose = this.onDose.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onBrand = this.onBrand.bind(this)
		this.onMedicationUsage = this.onMedicationUsage.bind(this)
		this.onPrice = this.onPrice.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
		this.onTax = this.onTax.bind(this)
		this.onMinQtyPerOrder = this.onMinQtyPerOrder.bind(this)
		this.onBonus = this.onBonus.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onFsCode = this.onFsCode.bind(this)
		this.onSellPricePerUnit = this.onSellPricePerUnit.bind(this)
		this.onCategoryNum = this.onCategoryNum.bind(this)
		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
	}

	componentDidMount() {
		if (this.props.item.selectedItem.item) {
			let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
			let formIdx = this.props.item.modalNewItem.forms.findIndex(
				(each) => each.id === this.props.item.selectedItem.item.form.id
			)
			if (formIdx > -1) {
				formHtml.selectedIndex = formIdx + 1
				this.props.setAdmIModalNISelectedForm(formIdx, this.props.item.modalNewItem.forms[formIdx])
			}

			let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
			let unitIdx = this.props.item.modalNewItem.units.findIndex(
				(each) => each.id === this.props.item.selectedItem.item.unit.id
			)
			if (unitIdx > -1) {
				unitHtml.selectedIndex = unitIdx + 1
				this.props.setAdmIModalNISelectedUnit(unitIdx, this.props.item.modalNewItem.units[unitIdx])
			}

			let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
			let supplierIdx = this.props.item.modalNewItem.suppliers.findIndex(
				(each) => each.id === this.props.item.selectedItem.item.supplier.id
			)
			if (supplierIdx > -1) {
				supplierHtml.selectedIndex = supplierIdx + 1
				this.props.setAdmIModalNISelectedSupplier(supplierIdx, this.props.item.modalNewItem.suppliers[supplierIdx])
			}

			let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
			let taxIdx = this.props.item.modalNewItem.taxes.findIndex(
				(each) => each.id === this.props.item.selectedItem.item.tax.id
			)
			if (taxIdx > -1) {
				taxHtml.selectedIndex = taxIdx + 1
				this.props.setAdmIModalNISelectedTax(taxIdx, this.props.item.modalNewItem.taxes[taxIdx])
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.item.modalNewItem.selectedItem, this.props.item.modalNewItem.selectedItem)) {
			if (!this.props.item.selectedItem.item) {
				this.reset()

				let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
				formHtml.selectedIndex = 0
				let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
				unitHtml.selectedIndex = 0
				let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
				supplierHtml.selectedIndex = 0
				let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
				if (_.isEmpty(this.props.item.modalNewItem.taxes)) {
					taxHtml.selectedIndex = 0
				} else {
					taxHtml.selectedIndex = 1
					this.props.setAdmIModalNISelectedTax(0, this.props.item.modalNewItem.taxes[0])
				}

				this.props.setAdmIModalNIDiscount(0)
				this.props.setAdmIModalNIBonus(0)
			}
		}

		if (
			prevProps.item.modalNewItem.code !== this.props.item.modalNewItem.code ||
			!_.isEqual(prevProps.item.modalNewItem.selectedForm, this.props.item.modalNewItem.selectedForm) ||
			prevProps.item.modalNewItem.dose !== this.props.item.modalNewItem.dose ||
			!_.isEqual(prevProps.item.modalNewItem.selectedUnit, this.props.item.modalNewItem.selectedUnit) ||
			!_.isEqual(prevProps.item.modalNewItem.selectedSupplier, this.props.item.modalNewItem.selectedSupplier) ||
			prevProps.item.modalNewItem.brand !== this.props.item.modalNewItem.brand ||
			prevProps.item.modalNewItem.pricePerUnit !== this.props.item.modalNewItem.pricePerUnit ||
			prevProps.item.modalNewItem.discount !== this.props.item.modalNewItem.discount ||
			!_.isEqual(prevProps.item.modalNewItem.selectedTax, this.props.item.modalNewItem.selectedTax) ||
			prevProps.item.modalNewItem.qtyPerOrder !== this.props.item.modalNewItem.qtyPerOrder ||
			prevProps.item.modalNewItem.bonus !== this.props.item.modalNewItem.bonus ||
			prevProps.item.modalNewItem.fsCode !== this.props.item.modalNewItem.fsCode ||
			prevProps.item.modalNewItem.sellPricePerUnit !== this.props.item.modalNewItem.sellPricePerUnit
		) {
			this.validateInput()
		}
	}

	reset() {
		this.props.setAdmIModalNICode("")
		this.props.setAdmIModalNISelectedForm(-1, null)
		this.props.setAdmIModalNIDose("")
		this.props.setAdmIModalNISelectedUnit(-1, null)
		this.props.setAdmIModalNISelectedSupplier(-1, null)
		this.props.setAdmIModalNIBrand("")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE, "")
		this.props.setAdmIModalNIPricePerUnit("")
		this.props.setAdmIModalNIDiscount("")
		this.props.setAdmIModalNISelectedTax(-1, null)
		this.props.setAdmIModalNIQtyPerOrder("")
		this.props.setAdmIModalNIBonus("")
		this.props.setAdmIModalNIActive(true)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN, "")
	}

	validateInput() {
		let selectedItem = this.props.item.modalNewItem.selectedItem.item
		let items = this.props.item.itemTable.original

		if (!selectedItem) {
			return
		}

		let codeHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.code)
		let codeInput = this.props.item.modalNewItem.code.trim().toUpperCase()

		let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
		let formInput = this.props.item.modalNewItem.selectedForm.form

		let doseHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.dose)
		let doseInput = this.props.item.modalNewItem.dose.trim().toLowerCase()

		let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
		let unitInput = this.props.item.modalNewItem.selectedUnit.unit

		let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
		let supplierInput = this.props.item.modalNewItem.selectedSupplier.supplier

		let brandHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.brand)
		let brandInput = this.props.item.modalNewItem.brand.trim().toLowerCase()

		let pricePerUnitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.pricePerUnit)
		let pricePerUnitInput = this.props.item.modalNewItem.pricePerUnit

		let discountHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.discount)
		let discountInput = this.props.item.modalNewItem.discount

		let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
		let taxInput = this.props.item.modalNewItem.selectedTax.tax

		let minQtyPerOrderHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.minQtyPerOrder)
		let minQtyPerOrderInput = this.props.item.modalNewItem.qtyPerOrder

		let bonusHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.bonus)
		let bonusInput = this.props.item.modalNewItem.bonus

		let fsCodeDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.fsCode)
		let fsCodeInput = this.props.item.modalNewItem.fsCode

		let sellPricePerUnitDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.sellPricePerUnit)
		let sellPricePerUnitInput = this.props.item.modalNewItem.sellPricePerUnit

		let categoryNumDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.categoryNum)
		let categoryNumInput = this.props.item.modalNewItem.categoryNum

		if (
			!codeInput ||
			this.props.item.itemTable.original.some((item) => {
				return item.itemSupplierRelps
					.filter((each) => !this.props.item.selectedItem.item || this.props.item.selectedItem.item.id !== each.id)
					.some((itemSupplier) => itemSupplier.code === codeInput)
			})
		) {
			codeHtml.setCustomValidity(
				// this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateCodeWarning })
				""
			)
			formHtml.setCustomValidity("")
			doseHtml.setCustomValidity("")
			unitHtml.setCustomValidity("")
			supplierHtml.setCustomValidity("")
			brandHtml.setCustomValidity("")
			pricePerUnitHtml.setCustomValidity("")
			discountHtml.setCustomValidity("")
			taxHtml.setCustomValidity("")
			minQtyPerOrderHtml.setCustomValidity("")
			bonusHtml.setCustomValidity("")
			fsCodeDOM.setCustomValidity("")
			sellPricePerUnitDOM.setCustomValidity("")
		} else if (
			items
				.filter(
					(each) =>
						(!selectedItem.item || each.id === selectedItem.id) &&
						(!this.props.item.modalNewItem.selectedType ||
							this.props.item.modalNewItem.selectedType.type.id === each.type.id)
				)
				.some((item) => {
					return item.itemSupplierRelps
						.filter((each) => !this.props.item.selectedItem.item || this.props.item.selectedItem.item.id !== each.id)
						.some((itemSupplier) => {
							return (
								formInput &&
								itemSupplier.form.id === formInput.id &&
								itemSupplier.dose.toLowerCase() === doseInput &&
								unitInput &&
								itemSupplier.unit.id === unitInput.id &&
								supplierInput &&
								itemSupplier.supplier.id === supplierInput.id &&
								itemSupplier.brand.toLowerCase() === brandInput &&
								Utils.BigNumber(itemSupplier.pricePerUnit).eq(pricePerUnitInput) &&
								Utils.BigNumber(itemSupplier.discount).eq(discountInput) &&
								taxInput &&
								itemSupplier.tax.id === taxInput.id &&
								Utils.BigNumber(itemSupplier.minQtyPerOrder).eq(minQtyPerOrderInput) &&
								Utils.BigNumber(itemSupplier.bonus).eq(bonusInput) &&
								itemSupplier.fsCode.toLowerCase() === fsCodeInput &&
								Utils.BigNumber(itemSupplier.sellPricePerUnit).eq(sellPricePerUnitInput) &&
								Utils.BigNumber(itemSupplier.categoryNum).eq(categoryNumInput)
							)
						})
				})
		) {
			codeHtml.setCustomValidity("")
			formHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			doseHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			unitHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			supplierHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
			brandHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			pricePerUnitHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
			discountHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
			taxHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			minQtyPerOrderHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
			bonusHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			fsCodeDOM.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			sellPricePerUnitDOM.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
			categoryNumDOM.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
			)
		} else {
			codeHtml.setCustomValidity("")
			formHtml.setCustomValidity("")
			doseHtml.setCustomValidity("")
			unitHtml.setCustomValidity("")
			supplierHtml.setCustomValidity("")
			brandHtml.setCustomValidity("")
			pricePerUnitHtml.setCustomValidity("")
			discountHtml.setCustomValidity("")
			taxHtml.setCustomValidity("")
			minQtyPerOrderHtml.setCustomValidity("")
			bonusHtml.setCustomValidity("")
			fsCodeDOM.setCustomValidity("")
			sellPricePerUnitDOM.setCustomValidity("")
			categoryNumDOM.setCustomValidity("")
		}
	}

	getHealthcarePlanValues() {
		let values = []
		this.props.item.modalNewItem.healthcarePlans.forEach((healthcarePlan, idx) => {
			if (_.includes(this.props.item.modalNewItem.healthcarePlan, healthcarePlan.displayName)) {
				values.push(idx)
			}
		})
		return values
	}

	onCode(event) {
		this.props.setAdmIModalNICode(event.target.value.toUpperCase())
	}

	onForm(event) {
		this.props.setAdmIModalNISelectedForm(event.target.value, this.props.item.modalNewItem.forms[event.target.value])
	}

	onDose(event) {
		this.props.setAdmIModalNIDose(event.target.value)
	}

	onUnit(event) {
		this.props.setAdmIModalNISelectedUnit(event.target.value, this.props.item.modalNewItem.units[event.target.value])
	}

	onSupplier(value) {
		this.props.setAdmIModalNISelectedSupplier(
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.item.modalNewItem.suppliers[value] : null
		)
	}

	onBrand(event) {
		this.props.setAdmIModalNIBrand(event.target.value)
	}

	onMedicationUsage(event) {
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE, event.target.value)
	}

	onPrice(event) {
		this.props.setAdmIModalNIPricePerUnit(event.target.value)
	}

	onDiscount(event) {
		this.props.setAdmIModalNIDiscount(event.target.value)
	}

	onTax(event) {
		this.props.setAdmIModalNISelectedTax(event.target.value, this.props.item.modalNewItem.taxes[event.target.value])
	}

	onMinQtyPerOrder(event) {
		this.props.setAdmIModalNIQtyPerOrder(event.target.value)
	}

	onBonus(event) {
		this.props.setAdmIModalNIBonus(event.target.value)
	}

	onActive(event) {
		this.props.setAdmIModalNIActive(event.target.checked)
	}

	onFsCode(event) {
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, event.target.value.toUpperCase())
	}

	onSellPricePerUnit(event) {
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT, event.target.value)
	}

	onCategoryNum(event) {
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, event.target.value)
	}

	onHealthCarePlan(value) {
		this.props.setValue(
			SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
			value.map((idx) => this.props.item.modalNewItem.healthcarePlans[idx].displayName).join("/")
		)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.code)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.code}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.code}
								onChange={this.onCode}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.form)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.form}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								onChange={this.onForm}
							>
								{Utils.renderOptions(this.props.item.modalNewItem.forms, true)}
							</FormControl>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.dose)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.dose}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.dose}
								onChange={this.onDose}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.subunit)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.unit}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								onChange={this.onUnit}
							>
								{Utils.renderOptions(this.props.item.modalNewItem.units, true)}
							</FormControl>
						</Col>
					</Row>

					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.supplier)}</FormLabel>
						</Col>
						<Col>
							<Select
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.supplier}
								showSearch
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={
									this.props.item.modalNewItem.selectedSupplier.supplier
										? this.props.item.modalNewItem.selectedSupplier.index
										: undefined
								}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "unset" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								onChange={this.onSupplier}
							>
								{Utils.renderSelects(this.props.item.modalNewItem.suppliers, true)}
							</Select>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={4}>
							<FormLabel>{translate(ResourceAssistance.Message.tradeNameOrBrand)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.brand}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.brand}
								onChange={this.onBrand}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.medicationUsage)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.medicationUsage}
								as={ResourceAssistance.FormControl.as.textArea}
								rows="3"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.medicationUsage}
								onChange={this.onMedicationUsage}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Select
								allowClear
								mode={ResourceAssistance.Select.mode.multiple}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								maxTagCount="responsive"
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) =>
									optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
								}
								placeholder={translate(ResourceAssistance.Hospitel.healthCarePlan)}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.getHealthcarePlanValues()}
								onChange={this.onHealthCarePlan}
							>
								{Utils.renderSelects(this.props.item.modalNewItem.healthcarePlans, false)}
							</Select>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.purchase)}</legend>
					<Row noGutters>
						<Col md={6}>
							<FormLabel>{translate(ResourceAssistance.Message.costPerOrder)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.pricePerUnit}
								type={ResourceAssistance.FormControl.type.number}
								min="0.0001"
								step="0.0001"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.pricePerUnit}
								onChange={this.onPrice}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.discount)}</FormLabel>
						</Col>
						<Col>
							<InputGroup>
								<FormControl
									required
									id={ResourceAssistance.ID.ADM.item.modalNI.discount}
									type={ResourceAssistance.FormControl.type.number}
									min="0"
									step="0.01"
									max="100"
									disabled={this.props.item.modalNewItem.selectedItem.index === -1}
									value={this.props.item.modalNewItem.discount}
									onChange={this.onDiscount}
								/>
								<InputGroup.Append>
									<InputGroup.Text>{`%`}</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.taxRate)}</FormLabel>
						</Col>
						<Col>
							<InputGroup>
								<FormControl
									required
									id={ResourceAssistance.ID.ADM.item.modalNI.tax}
									as={ResourceAssistance.FormControl.as.select}
									disabled={this.props.item.modalNewItem.selectedItem.index === -1}
									onChange={this.onTax}
								>
									{Utils.renderOptions(this.props.item.modalNewItem.taxes)}
								</FormControl>
								{this.props.item.modalNewItem.selectedTax.tax && (
									<InputGroup.Append>
										<InputGroup.Text>{`${this.props.item.modalNewItem.selectedTax.tax.taxRate}%`}</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={5}>
							<FormLabel>{translate(ResourceAssistance.Message.minQtyPerOrder)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.minQtyPerOrder}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.qtyPerOrder}
								min="1"
								onChange={this.onMinQtyPerOrder}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.bonus)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.bonus}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.bonus}
								onChange={this.onBonus}
							/>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.sell)}</legend>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.categoryNum)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.categoryNum}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.categoryNum}
								onChange={this.onCategoryNum}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={3}>
							<FormLabel>{translate(ResourceAssistance.Message.fsCode)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.fsCode}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.fsCode}
								onChange={this.onFsCode}
							/>
						</Col>
					</Row>
					<Row noGutters>
						<Col md={4}>
							<FormLabel>{translate(ResourceAssistance.Message.sellingPricePerUnit)}</FormLabel>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.sellPricePerUnit}
								type={ResourceAssistance.FormControl.type.number}
								min="0.00"
								step="0.01"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.sellPricePerUnit}
								onChange={this.onSellPricePerUnit}
							/>
						</Col>
					</Row>
				</fieldset>
				<Row style={{ marginTop: "0.3rem" }}>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox
							onClick={this.onActive}
							disabled={this.props.item.modalNewItem.selectedItem.index === -1}
							checked={this.props.item.modalNewItem.isActive}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	item: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmIModalNISelectedTax,
			setAdmIModalNISelectedSupplier,
			setAdmIModalNIBrand,
			setAdmIModalNIPricePerUnit,
			setAdmIModalNIDiscount,
			setAdmIModalNIQtyPerOrder,
			setAdmIModalNIBonus,
			setAdmIModalNIActive,
			setAdmIModalNICode,
			setAdmIModalNISelectedForm,
			setAdmIModalNIDose,
			setAdmIModalNISelectedUnit,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmIModalNIRight))
