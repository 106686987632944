import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import TFRightFirstRow from "./TFRightRequestActionRow"
import TFRightSecondRow from "./TFRightRequestItemRow"
import TFRightTransferActionRow from "./TFRightTransferActionRow"
import TFRightFourthRow from "./TFRightTransferItemRow"
import TFRightPendingRow from "./TFRightTransferRow"

class TFRight extends React.Component {
	render() {
		return (
			<Container fluid className={"border full-flex"}>
				<TFRightFirstRow />
				<TFRightSecondRow />
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Transfer, this.props.login.user.roles) && (
					<TFRightTransferActionRow {...this.props} />
				)}
				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Transfer, this.props.login.user.roles) ||
					Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Complete, this.props.login.user.roles) ||
					Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, this.props.login.user.roles)) && (
					<>
						<TFRightPendingRow />
						<TFRightFourthRow />
					</>
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRight)
