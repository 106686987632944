import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportCashierBillByHealthCarePlanDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				healthCarePlans: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page
					size={ResourceAssistance.Report.size.page.A4}
					style={[ResourceAssistance.Report.styles.page, styles.page]}
				>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayTitle={false}
						displayBranch={true}
						displayFax={false}
						styles={{
							org: {
								fontSize: 24,
							},
						}}
					/>

					{/* Patient Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>PID: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.pid}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientName}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Address/{this.state.data.addressIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientAddress}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />

					{/* Receipt Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission ID/{this.state.data.admissionIdIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionId}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission Date/{this.state.data.admissionDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Discharge Date/{this.state.data.dischargeDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.dischargeDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />

					{/* Service Header */}
					<View wrap={false}>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceNameHeader]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.nameIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustment]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.adjustmentIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.balanceIntl}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceNameHeader]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Service</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustment]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Adjustments</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Balance</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
					</View>

					{/* Services */}
					{this.state.data.healthCarePlans.map((hcp) => {
						return [
							<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontStyle: "bold" }]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>{hcp.name}</Text>
								</View>
							</View>,
						]
							.concat(
								hcp.billingStatements.map((bs) => {
									return (
										<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
											<View style={[ResourceAssistance.Report.styles.col, styles.blankSpace]}></View>
											<View style={[ResourceAssistance.Report.styles.col, styles.serviceName]}>
												<Text>{bs.name}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.amount, { textAlign: "right" }]}>
												<Text>{bs.amount}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.adjustment, { textAlign: "right" }]}>
												<Text>{bs.adjustment}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.balance, { textAlign: "right" }]}>
												<Text>{bs.balance}</Text>
											</View>
										</View>
									)
								})
							)
							.concat(
								<View style={[{ flex: 1 }]} wrap={false}>
									<View style={[ResourceAssistance.Report.styles.separatorDash]} />
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
										<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
											<View style={ResourceAssistance.Report.styles.row}>
												<View style={[ResourceAssistance.Report.styles.col, { minWidth: 40 }]}>
													<Text style={ResourceAssistance.Report.styles.textBold}>
														Sub Total/{this.state.data.subTotalIntl}:
													</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.col]}>
													<Text>{hcp.subTotal}</Text>
												</View>
											</View>
											<View style={ResourceAssistance.Report.styles.row}>
												<View style={[ResourceAssistance.Report.styles.col, { minWidth: 40 }]}>
													<Text style={ResourceAssistance.Report.styles.textBold}>
														Adjustments/{this.state.data.adjustmentIntl}:
													</Text>
												</View>
												<View style={ResourceAssistance.Report.styles.col}>
													<Text>{hcp.adjustments}</Text>
												</View>
											</View>
											<View style={ResourceAssistance.Report.styles.row}>
												<View style={[ResourceAssistance.Report.styles.col, { minWidth: 40 }]}>
													<Text style={ResourceAssistance.Report.styles.textBold}>
														Grand Total/{this.state.data.grandTotalIntl}:
													</Text>
												</View>
												<View style={ResourceAssistance.Report.styles.col}>
													<Text>{hcp.grandTotal}</Text>
												</View>
											</View>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalLine} />
								</View>
							)
					})}

					{/* Signature */}
					<Signature
						numOfSignature={1}
						signatureTitles={this.state.data.signatureTitles}
						signatureNames={this.state.data.signatureNames}
					/>
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 115,
	},
	infoHeader: {
		fontStyle: "bold",
		textAlign: "right",
		marginRight: 10,
	},
	itemRow: {
		fontSize: 16,
	},
	serviceNameHeader: {
		minWidth: "63%",
		maxWidth: "63%",
	},
	blankSpace: {
		minWidth: "2%",
		maxWidth: "2%",
	},
	serviceName: {
		minWidth: "61%",
		maxWidth: "61%",
	},
	amount: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	adjustment: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	balance: {
		minWidth: "12%",
		maxWidth: "12%",
	},
})

export default ReportCashierBillByHealthCarePlanDoc
