import { Descriptions } from "antd"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class ContextMenuPatientMedicalRecord extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.patient.clinicalRecord.medicalRecords
					.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
					.map((medicalRecord, idx) => (
						<Row key={idx}>
							<Col>
								<fieldset>
									<legend>{translate(ResourceAssistance.Message.medicalRecord)}</legend>
									<Descriptions
										style={{ flex: "unset", display: "unset" }}
										size={"small"}
										labelStyle={{ fontWeight: "bold" }}
										contentStyle={{ fontStyle: "italic" }}
										column={2}
									>
										{medicalRecord.menstruation && (
											<Descriptions.Item label={translate(ResourceAssistance.Message.menstruation)} span={2}>
												{medicalRecord.menstruation}
											</Descriptions.Item>
										)}
										<Descriptions.Item label={translate(ResourceAssistance.Message.department)} span={2}>
											{medicalRecord.department.branch.displayName}/{medicalRecord.department.displayName}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.checkInDate)} span={2}>
											{Utils.formatDateTime(medicalRecord.checkInDateTime)}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.by)} span={2}>
											{medicalRecord.lastModifiedBy.displayName}/
											{medicalRecord.lastModifiedBy.role ? medicalRecord.lastModifiedBy.role.displayName : ""}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.generalAppearance)} span={2}>
											{medicalRecord.ga}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.heent)} span={1}>
											{medicalRecord.heentNote ? medicalRecord.heentNote : medicalRecord.heent}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.heart)} span={1}>
											{medicalRecord.heartNote ? medicalRecord.heartNote : medicalRecord.heart}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.lung)} span={1}>
											{medicalRecord.lungNote ? medicalRecord.lungNote : medicalRecord.lung}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.abdomen)} span={1}>
											{medicalRecord.abdomenNote ? medicalRecord.abdomenNote : medicalRecord.abdomen}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.back)} span={1}>
											{medicalRecord.backNote ? medicalRecord.backNote : medicalRecord.back}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.extremities)} span={1}>
											{medicalRecord.extremitiesNote ? medicalRecord.extremitiesNote : medicalRecord.extremities}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.neuro)} span={2}>
											{medicalRecord.neuroNote ? medicalRecord.neuroNote : medicalRecord.neuro}
										</Descriptions.Item>
										{medicalRecord.physicalExaminationOthers && (
											<Descriptions.Item label={translate(ResourceAssistance.Message.other)} span={2}>
												{medicalRecord.physicalExaminationOthers}
											</Descriptions.Item>
										)}
										<Descriptions.Item label={translate(ResourceAssistance.Message.chiefComplaint)} span={2}>
											{medicalRecord.cc}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.presentIllness)} span={2}>
											{medicalRecord.pi}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.diagnosis)} span={2}>
											{medicalRecord.diagnosis}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.treatment)} span={2}>
											{medicalRecord.treatment}
										</Descriptions.Item>
									</Descriptions>
								</fieldset>
							</Col>
						</Row>
					))}
			</Fragment>
		)
	}
}

export default ContextMenuPatientMedicalRecord
