import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_RC_DISPLAY_ALL_IND,
	SET_RC_INVOICES,
	SET_RC_INVOICE_NUM,
	SET_RC_MODAL_CANCEL_PENDING_RECEIVE,
	SET_RC_MODAL_CANCEL_REASON,
	SET_RC_MODAL_CANCEL_WAITING_RECEIVES,
	SET_RC_MODAL_RECEIVE_WAITING_RECEIVES,
	SET_RC_ORDERS,
	SET_RC_PENDINGS,
	SET_RC_RECEIVED,
	SET_RC_RECEIVE_DETAIL_AMOUNT,
	SET_RC_RECEIVE_DETAIL_BONUS,
	SET_RC_RECEIVE_DETAIL_CODE,
	SET_RC_RECEIVE_DETAIL_DISCOUNT,
	SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_EXPIRE_DATE,
	SET_RC_RECEIVE_DETAIL_LOT,
	SET_RC_RECEIVE_DETAIL_NAME,
	SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT,
	SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT,
	SET_RC_RECEIVE_DETAIL_TAX,
	SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_TOTAL_PRICE,
	SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND,
	SET_RC_RECEIVE_DETAIL_UNIT,
	SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT,
	SET_RC_SEARCH_NUM,
	SET_RC_SELECTED_INVOICE,
	SET_RC_SELECTED_PENDING,
	SET_RC_SELECTED_RC,
	SET_RC_SELECTED_SUPPLIER,
	SET_RC_SELECTED_WAITING,
	SET_RC_TAXED_TOTAL_PRICE,
	SET_RC_WAITING_RECEIVES,
	SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND,
} from "../../type/type/RC"
export const setRcOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter((order) => String(order.id).includes(getState().receive.searchNum.toLowerCase()))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let color = []
		if (getState().receive.isDisplayAll) {
			color = filtered.map((order) => {
				let success = ResourceAssistance.CSS.Color.green
				let isDisplay = order.approvalApproved
				return [isDisplay, isDisplay ? success : ""]
			})
		} else {
			filtered = filtered.filter((order) => !order.approvalApproved)
		}

		let body = filtered.map((order) => {
			return [
				Utils.formatDate(order.creationDateTime),
				Utils.formatTime(order.creationDateTime),
				order.id,
				order.approvalApproved,
			]
		})

		dispatch({
			type: SET_RC_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				rowColor: color,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices.sort((a, b) => Utils.sort(a.receivedDate, b.receivedDate))

		let body = filtered.map((invoice) => {
			let totalPrice = invoice.receivedItems.reduce((object, item) => {
				return object.plus(Utils.BigNumber(item.totalPrice))
			}, Utils.BigNumber(0))

			let taxedTotalPrice = invoice.receivedItems.reduce((object, item) => {
				return object.plus(Utils.BigNumber(item.totalPrice).times(1 + item.taxRate / 100))
			}, Utils.BigNumber(0))

			return [
				invoice.receivedDate,
				invoice.invoice,
				invoice.supplierCode,
				invoice.supplierName,
				Utils.formatNumWithComma(totalPrice.dp(2).toFixed(2)),
				Utils.formatNumWithComma(taxedTotalPrice.dp(2).toFixed(2)),
				Utils.formatNumWithComma(invoice.discount) + "%",
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
				invoice.payment,
				invoice.createdBy.displayName,
			]
		})
		dispatch({
			type: SET_RC_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcReceived = (received) => {
	return (dispatch, getState) => {
		let filtered = received.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((item) => {
			return [
				item.lot,
				item.expireDate,
				item.code,
				item.name,
				item.unit,
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(item.discount) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(Utils.BigNumber(item.totalPrice).toFixed(2)),
				Utils.formatNumWithComma(item.taxRate) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.totalPrice)
						.times(1 + item.taxRate / 100)
						.dp(2)
						.toFixed(2)
				),
			]
		})
		dispatch({
			type: SET_RC_RECEIVED,
			payload: {
				original: received,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcWaitingReceives = (suppliers) => {
	return (dispatch, getState) => {
		let receives = suppliers.reduce((receives, supplier) => {
			supplier.items.forEach((item) => {
				Object.assign(item, {
					...item,
					supplierDiscount: supplier.discount,
				})
				receives.push(item)
			})
			return receives
		}, [])

		let filtered = receives.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let rowColor = []
		if (!getState().receive.isWaitingReceiveDisplayAll) {
			filtered = filtered.filter(
				(item) =>
					!getState()
						.receive.selectedRc.rc.receivedItems.reduce((total, received) => {
							if (received.code === item.code) {
								return total.minus(received.amount)
							}
							return total
						}, Utils.BigNumber(item.amount))
						.isZero() &&
					getState().receive.selectedRc.rc.cancelItems.findIndex((cancelItem) => cancelItem.itemCode === item.code) ===
						-1
			)
		}

		rowColor = filtered.map((item) => {
			let color = ""
			let isDisplay = false

			if (
				getState().receive.selectedRc.rc.cancelItems.findIndex((cancelItem) => cancelItem.itemCode === item.code) > -1
			) {
				isDisplay = true
				color = ResourceAssistance.CSS.Color.red
			} else if (
				getState().receive.selectedRc.rc.receivedItems.findIndex((receive) => receive.code === item.code) > -1
			) {
				isDisplay = true
				let receivedAmount = getState()
					.receive.selectedRc.rc.receivedItems.filter((receivedItem) => receivedItem.code === item.code)
					.reduce((total, receivedItem) => {
						return total.plus(receivedItem.amount)
					}, Utils.BigNumber(0))
				if (receivedAmount.eq(item.amount)) {
					color = ResourceAssistance.CSS.Color.green
				} else {
					color = ResourceAssistance.CSS.Color.warning
				}
			}

			return [isDisplay, isDisplay ? color : ""]
		})

		let body = filtered.map((item) => {
			let receivedAmount = getState()
				.receive.selectedRc.rc.receivedItems.filter((receivedItem) => receivedItem.code === item.code)
				.reduce((total, receivedItem) => {
					return total.plus(receivedItem.amount)
				}, Utils.BigNumber(0))
				.toNumber()

			return [
				item.code,
				item.displayName,
				item.displayUnit,
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(receivedAmount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(item.discount) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.dp(2)
						.toFixed(2)
				),
				item.supplierDiscount + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 - item.supplierDiscount / 100)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.tax) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 - item.supplierDiscount / 100)
						.times(1 + item.tax / 100)
						.dp(2)
						.toFixed(2)
				),
			]
		})

		dispatch({
			type: SET_RC_WAITING_RECEIVES,
			payload: {
				original: receives,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setRcModalReceiveWaitingReceives = (receives) => {
	return (dispatch, getState) => {
		let filtered = receives
			.filter(
				(receive) =>
					receive.code.toLowerCase().includes(getState().receive.modalReceive.searchCode.toLowerCase()) &&
					receive.displayName.toLowerCase().includes(getState().receive.modalReceive.searchName.toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((item) => {
			let receivedAmount = getState()
				.receive.selectedRc.rc.receivedItems.filter((receivedItem) => receivedItem.code === item.code)
				.reduce((total, receivedItem) => {
					return total.plus(receivedItem.amount)
				}, Utils.BigNumber(0))
				.toNumber()

			return [
				item.code,
				item.displayName,
				item.displayUnit,
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(receivedAmount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(item.discount) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.dp(2)
						.toFixed(2)
				),
				item.supplierDiscount + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 - item.supplierDiscount / 100)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.tax) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 - item.supplierDiscount / 100)
						.times(1 + item.tax / 100)
						.dp(2)
						.toFixed(2)
				),
			]
		})
		dispatch({
			type: SET_RC_MODAL_RECEIVE_WAITING_RECEIVES,
			payload: {
				original: receives,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcModalCancelWaitingReceives = (receives) => {
	return (dispatch, getState) => {
		let filtered = receives
			.filter(
				(receive) =>
					receive.code.toLowerCase().includes(getState().receive.modalCancel.searchCode.toLowerCase()) &&
					receive.displayName.toLowerCase().includes(getState().receive.modalCancel.searchName.toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((item) => {
			let receivedAmount = getState()
				.receive.selectedRc.rc.receivedItems.filter((receivedItem) => receivedItem.code === item.code)
				.reduce((total, receivedItem) => {
					return total.plus(receivedItem.amount)
				}, Utils.BigNumber(0))
				.toNumber()
			return [
				item.code,
				item.displayName,
				item.displayUnit,
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(receivedAmount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(item.discount) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.tax) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 + item.tax / 100)
						.dp(2)
						.toFixed(2)
				),
			]
		})
		dispatch({
			type: SET_RC_MODAL_CANCEL_WAITING_RECEIVES,
			payload: {
				original: receives,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcPendingReceives = (receives) => {
	let filtered = receives.filter((receive) => receive).sort((a, b) => Utils.sort(a.name, b.name))
	let body = filtered.map((item) => {
		return [
			item.lot,
			item.expireDate.toLocaleDateString(),
			item.code,
			item.name,
			item.unit,
			Utils.formatNumWithComma(item.amount) + " x " + Utils.formatNumWithComma(item.minQtyPerOrder),
			Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
			Utils.formatNumWithComma(item.discount) + "%",
			Utils.formatNumWithComma(item.discountPricePerUnit),
			Utils.formatNumWithComma(item.bonus),
			Utils.formatNumWithComma(Utils.BigNumber(item.totalPrice).toFixed(2)),
			item.supplierDiscount + "%",
			Utils.formatNumWithComma(Utils.BigNumber(item.discountedTotalPrice).toFixed(2)),
			Utils.formatNumWithComma(item.taxRate) + "%",
			Utils.formatNumWithComma(Utils.BigNumber(item.taxedTotalPrice).toFixed(2)),
		]
	})

	return {
		type: SET_RC_PENDINGS,
		payload: {
			original: receives,
			filtered: filtered,
			body: body,
		},
	}
}

export const setRcModalCancelPendingReceives = (receives) => {
	return (dispatch, getState) => {
		let filtered = receives.filter((receive) => receive).sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((item) => {
			let receivedAmount = getState()
				.receive.selectedRc.rc.receivedItems.filter((receivedItem) => receivedItem.code === item.code)
				.reduce((total, receivedItem) => {
					return total.plus(receivedItem.amount)
				}, Utils.BigNumber(0))
				.toNumber()
			return [
				item.code,
				item.displayName,
				item.displayUnit,
				Utils.formatNumWithComma(
					Utils.BigNumber(item.amount).minus(receivedAmount).dividedBy(item.minQtyPerOrder).toNumber()
				) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(item.discount) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).minus(receivedAmount).dividedBy(item.minQtyPerOrder))
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.tax) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).minus(receivedAmount).dividedBy(item.minQtyPerOrder))
						.times(1 + item.tax / 100)
						.dp(2)
						.toFixed(2)
				),
			]
		})
		dispatch({
			type: SET_RC_MODAL_CANCEL_PENDING_RECEIVE,
			payload: {
				original: receives,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRcSearchNum = (num) => {
	return {
		type: SET_RC_SEARCH_NUM,
		payload: {
			num: num,
		},
	}
}

export const setRcDisplayAllInd = (isDisplayAll) => {
	return {
		type: SET_RC_DISPLAY_ALL_IND,
		payload: {
			isDisplayAll: isDisplayAll,
		},
	}
}

export const setRcSelectedRc = (index, rc) => {
	return {
		type: SET_RC_SELECTED_RC,
		payload: {
			index: index,
			rc: rc,
		},
	}
}

export const setRcSelectedWaiting = (index, inventory) => {
	return {
		type: SET_RC_SELECTED_WAITING,
		payload: {
			index: index,
			inventory: inventory,
		},
	}
}

export const setRcSelectedPending = (index, inventory) => {
	return {
		type: SET_RC_SELECTED_PENDING,
		payload: {
			index: index,
			inventory: inventory,
		},
	}
}

export const setRcDetailCode = (code) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_CODE,
		payload: {
			code: code,
		},
	}
}

export const setRcDetailName = (name) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_NAME,
		payload: {
			name: name,
		},
	}
}

export const setRcDetailUnit = (unit) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_UNIT,
		payload: {
			unit: unit,
		},
	}
}

export const setRcDetailLot = (lotName) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_LOT,
		payload: {
			lot: lotName,
		},
	}
}

export const setRcDetailExpireDate = (date) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_EXPIRE_DATE,
		payload: {
			expireDate: date,
		},
	}
}

export const setRcDetailAmount = (amount) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_AMOUNT,
		payload: {
			amount: amount,
		},
	}
}

export const setRcDetailPricePerUnit = (price) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT,
		payload: {
			pricePerUnit: price,
		},
	}
}

export const setRcDetailBonus = (bonus) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_BONUS,
		payload: {
			bonus: bonus,
		},
	}
}

export const setRcDetailTax = (tax) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_TAX,
		payload: {
			tax: tax,
		},
	}
}

export const setRcDetailDiscount = (discount) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_DISCOUNT,
		payload: {
			discount: discount,
		},
	}
}

export const setRcDetailDiscontPricePerUnit = (price) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT,
		payload: {
			discountPricePerUnit: price,
		},
	}
}

export const setRcDetailSupplierDiscount = (supplierDiscount) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT,
		payload: {
			supplierDiscount: supplierDiscount,
		},
	}
}

export const setRcDetailSupplierDiscountPricePerUnit = (price) => {
	return {
		type: SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT,
		payload: {
			supplierDiscountPricePerUnit: price,
		},
	}
}

export const setRcDetailDiscountedTotalPrice = (discountedTotalPrice) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE,
		payload: {
			discountedTotalPrice: discountedTotalPrice,
		},
	}
}

export const setRcDetailDiscountedModified = (isModified) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND,
		payload: {
			isModified: isModified,
		},
	}
}

export const setRcDetailTotalPrice = (price) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_TOTAL_PRICE,
		payload: {
			totalPrice: price,
		},
	}
}

export const setRcDetailTaxedTotalPrice = (taxedTotalPrice) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE,
		payload: {
			taxedTotalPrice: taxedTotalPrice,
		},
	}
}

export const setRcDetailDiscountTaxPricePerUnit = (price) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT,
		payload: {
			discountTaxPricePerUnit: price,
		},
	}
}

export const setRcDetailTaxedTotalPriceModified = (isModified) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND,
		payload: {
			isTaxedTotalPriceModified: isModified,
		},
	}
}

export const setRcDetailTotalPriceModified = (isModified) => {
	return {
		type: SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND,
		payload: {
			isTotalPriceModified: isModified,
		},
	}
}

export const setRcInvoiceNum = (num) => {
	return {
		type: SET_RC_INVOICE_NUM,
		payload: {
			num: num,
		},
	}
}

export const setRcTaxedTotalPrice = (taxedTotalPrice) => {
	return {
		type: SET_RC_TAXED_TOTAL_PRICE,
		payload: {
			taxedTotalPrice: taxedTotalPrice,
		},
	}
}

export const setRcSelectedSupplier = (index, supplier) => {
	return {
		type: SET_RC_SELECTED_SUPPLIER,
		payload: {
			index: index,
			supplier: supplier,
		},
	}
}

export const setRcSelectedInvoice = (index, invoice) => {
	return {
		type: SET_RC_SELECTED_INVOICE,
		payload: {
			index: index,
			invoice: invoice,
		},
	}
}

export const setRcWaitingReceiveDisplayAll = (isDisplayAll) => {
	return {
		type: SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND,
		payload: {
			isDisplayAll: isDisplayAll,
		},
	}
}

export const setRcModalCancelReason = (reason) => {
	return {
		type: SET_RC_MODAL_CANCEL_REASON,
		payload: {
			reason: reason,
		},
	}
}
