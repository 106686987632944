import React from "react"
import { Col, Container, FormCheck, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setDateAction, setBoolean } from "~/redux/action"
import {
	SET_REPORT_HOSPITAL_LAB_COVID19_END_DATETIME,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_PCR,
	SET_REPORT_HOSPITAL_LAB_COVID19_START_DATETIME,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_ANTIGEN,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_POSITIVE,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_NEGATIVE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class ReportLabCovid19LabFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onIncludePCR = this.onIncludePCR.bind(this)
		this.onIncludeAntigen = this.onIncludeAntigen.bind(this)
		this.onIncludePositive = this.onIncludePositive.bind(this)
		this.onIncludeNegative = this.onIncludeNegative.bind(this)
	}

	componentWillUnmount() {
		this.props.setDateAction(SET_REPORT_HOSPITAL_LAB_COVID19_START_DATETIME, Utils.generateDate().getTime())
		this.props.setDateAction(
			SET_REPORT_HOSPITAL_LAB_COVID19_END_DATETIME,
			Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
		)
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_PCR, true)
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_ANTIGEN, false)
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_POSITIVE, true)
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_NEGATIVE, false)
	}

	onStartDate(date) {
		this.props.setDateAction(SET_REPORT_HOSPITAL_LAB_COVID19_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setDateAction(SET_REPORT_HOSPITAL_LAB_COVID19_END_DATETIME, date.getTime())
	}

	onIncludePCR(event) {
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_PCR, event.target.checked)
	}

	onIncludeAntigen(event) {
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_ANTIGEN, event.target.checked)
	}

	onIncludePositive(event) {
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_POSITIVE, event.target.checked)
	}

	onIncludeNegative(event) {
		this.props.setBoolean(SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_NEGATIVE, event.target.checked)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.filter)}</legend>
					<Row>
						<Col className={ResourceAssistance.CSS.fullSize} />
						<Col md="auto">
							<DatePicker
								startDate={Utils.generateDateFromLong(this.props.filter.startDateTime)}
								onChange={this.onStartDate}
							/>
						</Col>
						<Col md="auto">
							<FormLabel style={{ alignSelf: ResourceAssistance.CSS.center }}>
								{translate(ResourceAssistance.Message.to)}
							</FormLabel>
						</Col>
						<Col md="auto">
							<DatePicker
								startDate={Utils.generateDateFromLong(this.props.filter.endDateTime)}
								onChange={this.onEndDate}
							/>
						</Col>
						<Col className={ResourceAssistance.CSS.fullSize} />
					</Row>
					<Row>
						<Col className={ResourceAssistance.CSS.fullSize} />
						<Col md="auto">
							<FormLabel>{translate(ResourceAssistance.Hospitel.pcr)}</FormLabel>
						</Col>
						<Col md="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								checked={this.props.filter.includePCR}
								onChange={this.onIncludePCR}
							/>
						</Col>
						<Col className={ResourceAssistance.CSS.fullSize} />
						<Col md="auto">
							<FormLabel>{translate(ResourceAssistance.Hospitel.antigen)}</FormLabel>
						</Col>
						<Col md="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								checked={this.props.filter.includeAntigen}
								onChange={this.onIncludeAntigen}
							/>
						</Col>
						<Col className={ResourceAssistance.CSS.fullSize} />
					</Row>
					<Row>
						<Col />
						<Col md="auto">
							<FormLabel>{translate(ResourceAssistance.Hospitel.positive)}</FormLabel>
						</Col>
						<Col md="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								checked={this.props.filter.includePositive}
								onChange={this.onIncludePositive}
							/>
						</Col>
						<Col />
						<Col md="auto">
							<FormLabel>{translate(ResourceAssistance.Hospitel.negative)}</FormLabel>
						</Col>
						<Col md="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								checked={this.props.filter.includeNegative}
								onChange={this.onIncludeNegative}
							/>
						</Col>
						<Col />
					</Row>
				</fieldset>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.lab.covid19,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setDateAction,
			setBoolean,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportLabCovid19LabFilterDetails)
