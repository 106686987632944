import * as axios from "axios"
import { ResourceAssistance } from "~/i18n"
import { LOGIN } from "~/redux/type"
import { loginAction } from "~/redux/action/loginAction"
import { store } from "~/redux/Store"

const Logout = ({ history }) => {
	const unSubscribe = store.subscribe(() => {})
	try {
		axios({
			method: "GET",
			url: ResourceAssistance.Url.auth.logout,
			withCredentials: true,
		}).then((res) => {
			store.dispatch(loginAction(LOGIN, false, {}))
			// store.dispatch(resetAction())
			// history.push(ResourceAssistance.Path.root)
		})
	} catch (error) {
		console.log(error)
	}
	unSubscribe()
}

export default Logout
