import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Utils } from "~/utils/Utils"
import { ResourceAssistance, translate } from "~/i18n"

class InpatientModalDoctorSOAPInfoCriteria extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions
						style={{ flex: "unset", display: "unset" }}
						size={"small"}
						labelStyle={{ fontWeight: "bold" }}
						contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
						column={1}
					>
						<Descriptions.Item span={1} contentStyle={{ justifyContent: "center" }}>
							{Utils.formatDateTime(this.props.ipd.selectedSOAP.soap.startDateTime)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Hospitel.subjective)}>
							{this.props.ipd.selectedSOAP.soap.subjective}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Hospitel.objective)}>
							{this.props.ipd.selectedSOAP.soap.objective}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Hospitel.assessment)}>
							{this.props.ipd.selectedSOAP.soap.assessment}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Hospitel.plan)}>
							{this.props.ipd.selectedSOAP.soap.plan}
						</Descriptions.Item>
						<Descriptions.Item span={1} contentStyle={{ justifyContent: "flex-end" }}>
							{this.props.ipd.selectedSOAP.soap.lastModifiedBy.displayName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InpatientModalDoctorSOAPInfoCriteria)
