import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setTfRequests } from "~/redux/action"
import { SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, SET_TF_REQUEST_LOADING } from "~/redux/type"
import TFModalCRIReasonRow from "./TFModalCancelTRIReasonRow"

class TFModalCancelTRI extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "TFModalCancelTRI",
		}

		this.onClose = this.onClose.bind(this)
		this.onCancel = this.onCancel.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	onCancel(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.cancelTRI,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				items: [
					{
						transferRequestItemId: this.props.transfer.selectedRequestItem.item.id,
						reason: this.props.transfer.cancelRequestItemModal.reason,
					},
				],
			},
		}

		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.onClose()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form onSubmit={this.onCancel}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.reason)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<TFModalCRIReasonRow />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.red} type={ResourceAssistance.Button.type.submit}>
							{translate(ResourceAssistance.Message.cancel)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isTFCancelRequestItemDisplay,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setTfRequests, setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalCancelTRI)
