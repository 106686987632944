import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import {
	setAdmRoleModalNRPendingPermissions,
	setAdmRoleModalNRSelectedPendingPermission,
	setAdmRoleModalNRSelectedPermission,
} from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AdmRoleModalNRPermission extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onSelectPendingRow = this.onSelectPendingRow.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidMount() {
		if (this.props.role.selectedRole.index !== -1) {
			this.props.setAdmRoleModalNRPendingPermissions(this.props.role.selectedRole.role.permissions)
		}
	}

	componentWillUnmount() {
		this.props.setAdmRoleModalNRSelectedPermission(-1, null)
		this.props.setAdmRoleModalNRSelectedPendingPermission(-1, null)
		this.props.setAdmRoleModalNRPendingPermissions([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.role.modalNewRole.pendingPermissionTable.original,
				this.props.role.modalNewRole.pendingPermissionTable.original
			)
		) {
			this.props.setAdmRoleModalNRSelectedPendingPermission(-1, null)
		}
	}

	onSelectRow(row, index) {
		this.props.setAdmRoleModalNRSelectedPermission(index, this.props.role.modalNewRole.permissionTable.filtered[index])
	}

	onSelectPendingRow(row, index) {
		this.props.setAdmRoleModalNRSelectedPendingPermission(
			index,
			this.props.role.modalNewRole.pendingPermissionTable.filtered[index]
		)
	}

	onDownBtn() {
		if (this.props.role.modalNewRole.selectedPermission.index === -1) {
			return
		}
		let selectedPermission = this.props.role.modalNewRole.selectedPermission.permission
		let pendingPermissions = this.props.role.modalNewRole.pendingPermissionTable.filtered.filter(
			(each) => each.id !== this.props.role.modalNewRole.selectedPermission.permission.id
		)
		pendingPermissions.push(selectedPermission)
		this.props.setAdmRoleModalNRPendingPermissions(pendingPermissions)
	}

	onUpBtn() {
		if (this.props.role.modalNewRole.selectedPendingPermission.index === -1) {
			return
		}
		let pendingPermissions = this.props.role.modalNewRole.pendingPermissionTable.filtered.filter(
			(each) => each.id !== this.props.role.modalNewRole.selectedPendingPermission.permission.id
		)
		this.props.setAdmRoleModalNRPendingPermissions(pendingPermissions)
	}

	render() {
		return (
			<Col>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableHighlight
						data={this.props.role.modalNewRole.permissionTable}
						isClearHighlight={this.props.role.modalNewRole.selectedPermission.index === -1}
						onClick={this.onSelectRow}
					/>
				</Row>
				<Row>
					<Col
						className={
							this.props.role.modalNewRole.selectedPermission.index === -1 ? ResourceAssistance.CSS.disabled : ""
						}
					>
						<FcDownload size={"3rem"} onClick={this.onDownBtn} style={{ alignSelf: "center" }} />
					</Col>
					<Col
						className={
							this.props.role.modalNewRole.selectedPendingPermission.index === -1 ? ResourceAssistance.CSS.disabled : ""
						}
					>
						<FcUpload size={"3rem"} onClick={this.onUpBtn} style={{ alignSelf: "center" }} />
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableHighlight
						data={this.props.role.modalNewRole.pendingPermissionTable}
						isClearHighlight={this.props.role.modalNewRole.selectedPendingPermission.index === -1}
						onClick={this.onSelectPendingRow}
					/>
				</Row>
			</Col>
		)
	}
}

const mapStateToProps = (state) => ({
	role: state.admin.userConfig.role,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmRoleModalNRSelectedPermission,
			setAdmRoleModalNRSelectedPendingPermission,
			setAdmRoleModalNRPendingPermissions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmRoleModalNRPermission)
