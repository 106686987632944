import React from "react"
import { Col, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_GA,
	SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS,
	SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

const HEENT = "HEENT"
const HEART = "HEART"
const LUNG = "LUNG"
const ABDOMEN = "ABDOMEN"
const BACK = "BACK"
const EXTREMITIES = "EXTREMITIES"
const NEURO = "NEURO"

const EAppearance = {
	N: "OPD_MEDICAL_RECORD_PHYSICAL_EXAMINATION_NORMAL",
	A: "OPD_MEDICAL_RECORD_PHYSICAL_EXAMINATION_ABNORMAL",
}

class OPDModalMedicalRecordPE extends React.Component {
	constructor(props) {
		super(props)

		this.onGA = this.onGA.bind(this)
		this.onHEENT = this.onHEENT.bind(this)
		this.onHEENTNote = this.onHEENTNote.bind(this)
		this.onHeart = this.onHeart.bind(this)
		this.onHeartNote = this.onHeartNote.bind(this)
		this.onLung = this.onLung.bind(this)
		this.onLungNote = this.onLungNote.bind(this)
		this.onAbdomen = this.onAbdomen.bind(this)
		this.onAbdomenNote = this.onAbdomenNote.bind(this)
		this.onBack = this.onBack.bind(this)
		this.onBackNote = this.onBackNote.bind(this)
		this.onExtremities = this.onExtremities.bind(this)
		this.onExtremitiesNote = this.onExtremitiesNote.bind(this)
		this.onNeuro = this.onNeuro.bind(this)
		this.onNeuroNote = this.onNeuroNote.bind(this)
		this.onPhysicalExaminationOthers = this.onPhysicalExaminationOthers.bind(this)
		this.onRiskOfAbuse = this.onRiskOfAbuse.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			let medicalRecord = this.props.opd.selectedMedicalRecord.medicalRecord
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_GA, medicalRecord.ga)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE, medicalRecord.heent)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE, medicalRecord.heentNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE, medicalRecord.heart)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE, medicalRecord.heartNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE, medicalRecord.lung)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE, medicalRecord.lungNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE, medicalRecord.abdomen)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE, medicalRecord.abdomenNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE, medicalRecord.back)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE, medicalRecord.backNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE, medicalRecord.extremities)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE, medicalRecord.extremitiesNote)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE, medicalRecord.neuro)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE, medicalRecord.neuroNote)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS,
				medicalRecord.physicalExaminationOthers
			)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE, medicalRecord.riskOfAbuse)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_GA, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE, ResourceAssistance.CONSTANT.NONE)
	}

	onGA(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_GA, event.target.value)
	}

	onHEENT(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onHEENTNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE, event.target.value)
	}

	onHeart(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onHeartNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE, event.target.value)
	}

	onLung(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onLungNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE, event.target.value)
	}

	onAbdomen(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onAbdomenNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE, event.target.value)
	}

	onBack(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onBackNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE, event.target.value)
	}

	onExtremities(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}
	onExtremitiesNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE, event.target.value)
	}

	onNeuro(event) {
		switch (event.target.value) {
			case EAppearance.N:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE, ResourceAssistance.CONSTANT.NORMAL)
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE, "")
				break

			case EAppearance.A:
				this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE, ResourceAssistance.CONSTANT.ABNORMAL)
				break

			default:
				break
		}
	}

	onNeuroNote(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE, event.target.value)
	}

	onPhysicalExaminationOthers(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS, event.target.value)
	}

	onRiskOfAbuse(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.physicalExamination)}</legend>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.riskOfAbuse })}
							value={this.props.opd.modalMedicalRecord.riskOfAbuse}
							onChange={this.onRiskOfAbuse}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={1}
							maxRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.generalAppearance })}
							value={this.props.opd.modalMedicalRecord.GA}
							onChange={this.onGA}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<FormLabel>HEENT</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.N}
								name={HEENT}
								checked={this.props.opd.modalMedicalRecord.HEENT.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onHEENT}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.A}
								name={HEENT}
								checked={this.props.opd.modalMedicalRecord.HEENT.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onHEENT}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.HEENT.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.HEENT.note}
								onChange={this.onHEENTNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Heart</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.N}
								name={HEART}
								checked={this.props.opd.modalMedicalRecord.heart.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onHeart}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.A}
								name={HEART}
								checked={this.props.opd.modalMedicalRecord.heart.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onHeart}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.heart.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.heart.note}
								onChange={this.onHeartNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Lung</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.N}
								name={LUNG}
								checked={this.props.opd.modalMedicalRecord.lung.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onLung}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								value={EAppearance.A}
								name={LUNG}
								checked={this.props.opd.modalMedicalRecord.lung.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onLung}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.lung.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.lung.note}
								onChange={this.onLungNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Abdomen</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								name={ABDOMEN}
								value={EAppearance.N}
								checked={this.props.opd.modalMedicalRecord.abdomen.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onAbdomen}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								name={ABDOMEN}
								value={EAppearance.A}
								checked={this.props.opd.modalMedicalRecord.abdomen.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onAbdomen}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.abdomen.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.abdomen.note}
								onChange={this.onAbdomenNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Back</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								name={BACK}
								value={EAppearance.N}
								checked={this.props.opd.modalMedicalRecord.back.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onBack}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								name={BACK}
								value={EAppearance.A}
								checked={this.props.opd.modalMedicalRecord.back.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onBack}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.back.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.back.note}
								onChange={this.onBackNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Extremities</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								name={EXTREMITIES}
								value={EAppearance.N}
								checked={
									this.props.opd.modalMedicalRecord.extremities.appearance === ResourceAssistance.CONSTANT.NORMAL
								}
								onChange={this.onExtremities}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								name={EXTREMITIES}
								value={EAppearance.A}
								checked={
									this.props.opd.modalMedicalRecord.extremities.appearance === ResourceAssistance.CONSTANT.ABNORMAL
								}
								onChange={this.onExtremities}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={
									this.props.opd.modalMedicalRecord.extremities.appearance === ResourceAssistance.CONSTANT.NORMAL
								}
								value={this.props.opd.modalMedicalRecord.extremities.note}
								onChange={this.onExtremitiesNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<FormLabel>Neuro</FormLabel>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Radio
								name={NEURO}
								value={EAppearance.N}
								checked={this.props.opd.modalMedicalRecord.neuro.appearance === ResourceAssistance.CONSTANT.NORMAL}
								onChange={this.onNeuro}
							/>
							<InputGroup.Append>
								<InputGroup.Text>N</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Radio
								name={NEURO}
								value={EAppearance.A}
								checked={this.props.opd.modalMedicalRecord.neuro.appearance === ResourceAssistance.CONSTANT.ABNORMAL}
								onChange={this.onNeuro}
							/>
							<InputGroup.Append>
								<InputGroup.Text>A</InputGroup.Text>
							</InputGroup.Append>
							<FormControl
								required
								as={ResourceAssistance.FormControl.as.textArea}
								rows={1}
								disabled={this.props.opd.modalMedicalRecord.neuro.appearance === ResourceAssistance.CONSTANT.NORMAL}
								value={this.props.opd.modalMedicalRecord.neuro.note}
								onChange={this.onNeuroNote}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={3}
							maxRows={3}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.other })}
							onChange={this.onPhysicalExaminationOthers}
							value={this.props.opd.modalMedicalRecord.physicalExaminationOthers}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordPE))
