import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalNurseRecordDate extends React.Component {
	constructor(props) {
		super(props)

		this.onRecordDateTime = this.onRecordDateTime.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedNurseNote.nurseNote) {
			this.props.setValue(
				SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME,
				this.props.hospitel.selectedNurseNote.nurseNote.dateTime
			)
		} else {
			this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME, new Date().getTime())
		}
	}

	onRecordDateTime(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME, date.getTime())
	}

	render() {
		return (
			<Row style={{ alignSelf: "center" }}>
				<Col md="auto">
					<DatePicker
						// timeIntervals={120}
						// includeTimes={[
						// 	Utils.generateDate(0, 0, 0, 2, 0, 0),
						// 	Utils.generateDate(0, 0, 0, 6, 0, 0),
						// 	Utils.generateDate(0, 0, 0, 10, 0, 0),
						// 	Utils.generateDate(0, 0, 0, 14, 0, 0),
						// 	Utils.generateDate(0, 0, 0, 18, 0, 0),
						// 	Utils.generateDate(0, 0, 0, 22, 0, 0),
						// ]}
						minDate={Utils.generateDate()}
						showDisabledMonthNavigation
						startDate={Utils.generateDateFromLong(this.props.hospitel.modalNurseRecord.recordDateTime)}
						onChange={this.onRecordDateTime}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecordDate))
