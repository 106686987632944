import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setCashierServices, setSelected } from "~/redux/action"
import { SET_CASHIER_SELECTED_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierRightService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightService",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.selectedHealthCarePlan, this.props.cashier.selectedHealthCarePlan)) {
			if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
				this.props.setCashierServices(this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements)
			} else {
				this.props.setCashierServices([])
			}
		}

		if (!_.isEqual(prevProps.cashier.serviceTable.original, this.props.cashier.serviceTable.original)) {
			if (this.props.cashier.selectedService.service) {
				let idx = this.props.cashier.serviceTable.filtered.findIndex(
					(billingStatement) => billingStatement.id === this.props.cashier.selectedService.service.id
				)
				if (idx > -1) {
					this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, idx, this.props.cashier.serviceTable.filtered[idx])
				} else {
					this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, -1, null)
				}
			}
		}
	}

	onSelectRow(service, index) {
		this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, index, this.props.cashier.serviceTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.service)}</legend>
						<ScrollableTable
							enableHighlight
							striped
							hover
							data={this.props.cashier.serviceTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.cashier.selectedService.index === -1}
							highlightedRow={this.props.cashier.selectedService.index}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setCashierServices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierRightService)
