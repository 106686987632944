import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { Utils } from "~/utils/Utils"

class PharmacyModalReturnDispensedOrder extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pharmacyDispensed)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
							column={4}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.startDate)} span={1}>
								{Utils.formatDate(this.props.pharmacy.selectedDispensedOrder.dispensedOrder.startDateTime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.endDate)} span={1}>
								{Utils.formatDate(this.props.pharmacy.selectedDispensedOrder.dispensedOrder.endDateTime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
								{Utils.formatNumWithComma(this.props.pharmacy.selectedDispensedOrder.dispensedOrder.amount)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
								{Utils.formatNumWithComma(this.props.pharmacy.selectedDispensedOrder.dispensedOrder.unit)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.pharmacist)} span={4}>
								{this.props.pharmacy.selectedDispensedOrder.dispensedOrder.lastModifiedBy.displayName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dispensedBy)} span={4}>
								{this.props.pharmacy.selectedDispensedOrder.dispensedOrder.lastModifiedBy.displayName}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalReturnDispensedOrder)
