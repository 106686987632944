import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setModalDoneIndAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PO_EDIT_DONE_IND } from "~/redux/type"
import { onModalDisplayAction } from "~/redux/action/modal/actions"
import { SET_PO_EDIT_DISPLAY } from "~/redux/type/type/MODAL"
import POEditDetails from "./POEditDetails"
import { VscSaveAs } from "react-icons/vsc"
import { GrRotateLeft } from "react-icons/gr"

class POEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "POEdit",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneBtnDisabled() {
		if (this.props.selectedSupplier.index === -1) {
			return true
		}

		let target = Object.assign({}, this.props.selectedSupplier.supplier.orderDetail, {
			...this.props.selectedSupplier.supplier.orderDetail,
			discount: this.props.edit.discount,
			paymentTerms: this.props.edit.paymentTerms.trim(),
			requiredDateTime: this.props.edit.requiredDate,
			routingId: this.props.edit.routingId.trim(),
			shippingContact: this.props.edit.shippingContact.trim(),
			billingContact: this.props.edit.billingContact.trim(),
			payment: {
				...this.props.selectedSupplier.supplier.orderDetail.payment,
				id: this.props.edit.selectedPayment.payment ? this.props.edit.selectedPayment.payment.id : 0,
			},
		})
		return _.isEqual(this.props.selectedSupplier.supplier.orderDetail, target)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_PO_EDIT_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.po.updatePoDetails,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				poId: this.props.selectedPo.po.id,
				supplierCode: this.props.selectedSupplier.supplier.code.trim(),
				supplier: this.props.selectedSupplier.supplier.name.trim(),
				userId: this.props.login.user.id,
				discount: this.props.edit.discount,
				paymentId: this.props.edit.selectedPayment.payment.id,
				paymentTerms: this.props.edit.paymentTerms.trim(),
				requiredDateTime: this.props.edit.requiredDate,
				routingId: this.props.edit.routingId,
				shippingContact: this.props.edit.shippingContact.trim(),
				billingContact: this.props.edit.billingContact.trim(),
			},
		}

		let callback = (res) => {
			this.props.setModalDoneIndAction(SET_PO_EDIT_DONE_IND, true, res.data.orders)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.onClose()
	}

	render() {
		return (
			<div>
				<Modal
					id={this.state.componentName}
					centered
					animation={false}
					size={ResourceAssistance.Modal.size.lg}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					show={this.props.isDisplay}
					onHide={this.onClose}
				>
					<Form
						className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
						onSubmit={this.onDone}
					>
						<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
							<ModalTitle>{translate(ResourceAssistance.Message.purchaseOrder)}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<POEditDetails />
						</ModalBody>
						<ModalFooter>
							<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
								<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.close)}
							</Button>
							<Button
								variant={ResourceAssistance.Button.variant.green}
								type={ResourceAssistance.Button.type.submit}
								disabled={this.isDoneBtnDisabled()}
							>
								<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.save)}
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPOEditDisplay,
	login: state.login,
	edit: state.purchaseOrder.edit,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
	selectedPo: state.purchaseOrder.selectedPo,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setModalDoneIndAction,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POEdit)
