import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	ADD_PR_NEW_ITEM,
	APPROVE,
	CHANGE_DATE,
	LOAD_ITEMS,
	LOAD_OUT_OF_STOCK_INVENTORY,
	LOAD_PRS,
	LOAD_PR_TYPES,
	RESET,
	SELECT_INVENTORY,
	SELECT_NEW_ITEM,
	SELECT_PR,
	SELECT_PR_DETAIL,
	SELECT_PR_ITEM,
	SELECT_TYPE,
	SET_DISPLAY_ALL,
	SET_INVENTORY_PROPERTY,
	SET_PROPERTY,
	SET_PR_LOCATIONS,
	SET_PR_SEARCH_LOCATION,
	SET_SEARCH_NUM,
	SET_SELECTED_INVENTORIES,
} from "../../type"
import {
	SET_PR_APPROVER_APPROVED,
	SET_PR_APPROVE_APPROVE_IND,
	SET_PR_APPROVE_EVENT,
	SET_PR_LOCATION_BRANCH,
	SET_PR_LOCATION_ORG,
	SET_PR_MIN_STOCK_LAST_PURCHASED_INFO,
	SET_PR_NEW_PR_LAST_PURCHASED_INFO,
	SET_PR_SELECTED_BRANCH,
	SET_PR_SELECTED_ORG,
} from "../../type/type/PR"

export const approveAction = (approverK, approver, isApproved, approve, approvedDate, date) => {
	return (dispatch) => {
		dispatch({
			type: APPROVE,
			payload: {
				approverK: approverK,
				approver: approver,
				isApproved: isApproved,
				approve: approve,
				approvedDate: approvedDate,
				date: date,
			},
		})
		return Promise.resolve()
	}
}

export const setPrApproverApproved = (approver, isApproved) => {
	return {
		type: SET_PR_APPROVER_APPROVED,
		payload: {
			approver: approver,
			isApproved: isApproved,
		},
	}
}

export const changeSearchDateAction = (startDate, endDate) => {
	return (dispatch) => {
		dispatch({
			type: CHANGE_DATE,
			payload: {
				startDate: startDate,
				endDate: endDate,
			},
		})
		return Promise.resolve()
	}
}

export const loadItemsAction = (original) => {
	return (dispatch, getState) => {
		original.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let filtered = original.filter(
			(item) =>
				item.displayName
					.trim()
					.toLowerCase()
					.includes(getState().purchaseRequest.newPr.searchName.trim().toLowerCase()) &&
				getState().purchaseRequest.selectedType.type &&
				item.type.id === getState().purchaseRequest.selectedType.type.id
		)

		let tableBody = filtered.map((item) => {
			return [item.displayName]
		})

		dispatch({
			type: LOAD_ITEMS,
			payload: {
				original: original,
				filtered: filtered,
				tableBody: tableBody,
			},
		})

		return Promise.resolve()
	}
}

export const loadPrTypesAction = (types) => {
	return (dispatch) => {
		let filtered = types.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		dispatch({
			type: LOAD_PR_TYPES,
			payload: {
				types: filtered,
			},
		})
		return Promise.resolve()
	}
}

export const loadPRsAction = (original, filtered) => {
	return (dispatch, getState) => {
		filtered = filtered.filter(
			(order) =>
				order.id.toLowerCase().includes(getState().purchaseRequest.searchNum.toLowerCase()) &&
				order.location.name.toLowerCase().includes(getState().purchaseRequest.searchLocation.toLowerCase()) &&
				order.creationDateTime >= getState().purchaseRequest.searchDate.startDate.getTime() &&
				order.creationDateTime <= getState().purchaseRequest.searchDate.endDate.getTime()
		)

		if (!getState().purchaseRequest.isDisplayAll) {
			filtered = filtered.filter(
				(order) => !(order.requesterApproved && order.inspectorApproved && order.approverApproved)
			)
		}

		let originalOrder = original.sort((a, b) => Utils.sort(b.displayName, a.displayName))
		let filteredOrder = filtered.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = []
		rowColor = filteredOrder.map((order) => {
			let color = ""
			if (order.requesterApproved && order.inspectorApproved && order.approverApproved) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		let body = filteredOrder.map((pr) => {
			return [
				Utils.formatDate(pr.creationDateTime),
				Utils.formatTime(pr.creationDateTime),
				pr.id,
				pr.label,
				pr.location.name,
				pr.requesterApproved,
				pr.inspectorApproved,
				pr.approverApproved,
			]
		})
		dispatch({
			type: LOAD_PRS,
			payload: {
				original: originalOrder,
				filtered: filteredOrder,
				prs: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const loadOutOfStockInventoryAction = (locations) => {
	return (dispatch, getState) => {
		let inventoryDetails = locations
			.filter(
				(org) =>
					!getState().purchaseRequest.location.selectedOrg.org ||
					getState().purchaseRequest.location.selectedOrg.org.id === org.id
			)
			.reduce((allInventoryDetails, org) => {
				return allInventoryDetails.concat(
					org.locations
						.filter(
							(branch) =>
								!getState().purchaseRequest.location.selectedBranch.branch ||
								branch.id === getState().purchaseRequest.location.selectedBranch.branch.id
						)
						.reduce((orgInventoryDetails, branch) => {
							let branchInventoryDetails = branch.locations.reduce((branchInventoryDetails, stock) => {
								return branchInventoryDetails.concat(stock.inventoryDetails)
							}, [])

							let currents = branch.inStockItems.reduce((obj, inStockItem) => {
								obj[inStockItem.displayName.concat(inStockItem.brand).concat(inStockItem.supplierName)] =
									inStockItem.lots.reduce((subTotal, lot) => subTotal.plus(lot.current), Utils.BigNumber(0)).toNumber()

								let lastPurchasedInfo = obj[inStockItem.displayName.trim().toLowerCase()]
								obj[inStockItem.displayName.trim().toLowerCase()] = {
									lastPurchasedFrom: lastPurchasedInfo
										? lastPurchasedInfo["lastPurchasedDate"].getTime() > inStockItem.lastPurchasedDateTime
											? lastPurchasedInfo["lastPurchasedFrom"]
											: inStockItem.supplierName
										: inStockItem.supplierName,
									lastPurchasedDate: lastPurchasedInfo
										? lastPurchasedInfo["lastPurchasedDate"].getTime() > inStockItem.lastPurchasedDateTime
											? lastPurchasedInfo["lastPurchasedDate"]
											: new Date(inStockItem.lastPurchasedDateTime)
										: new Date(inStockItem.lastPurchasedDateTime),
								}
								return obj
							}, {})

							let branchInvDs = branchInventoryDetails.reduce((obj, invD) => {
								let key = invD.displayName.concat(invD.brand).concat(invD.supplier.displayName)
								let lastPurchasedKey = invD.displayName.trim().toLowerCase()
								obj[key] = {
									...invD,
									locationId: branch.id,
									locationName: branch.displayName,
									orgName: org.displayName,
									current: currents[key] ? currents[key] : 0,
									lastPurchasedFrom: currents[lastPurchasedKey] ? currents[lastPurchasedKey]["lastPurchasedFrom"] : "",
									lastPurchasedDate: currents[lastPurchasedKey]
										? currents[lastPurchasedKey]["lastPurchasedDate"]
										: null,
									min: Utils.BigNumber(invD.min)
										.plus(obj[key] ? obj[key].min : 0)
										.toNumber(),
									max: Utils.BigNumber(invD.max)
										.plus(obj[key] ? obj[key].max : 0)
										.toNumber(),
								}
								return obj
							}, {})
							return orgInventoryDetails.concat(Object.values(branchInvDs).filter((invD) => invD.current <= invD.min))
						}, [])
				)
			}, [])
		let filtered = inventoryDetails
			.filter(
				(invD) =>
					invD.code
						.trim()
						.toLowerCase()
						.includes(getState().purchaseRequest.minStock.searchCode.trim().toLowerCase()) &&
					invD.displayName
						.trim()
						.toLowerCase()
						.includes(getState().purchaseRequest.minStock.searchName.trim().toLowerCase())
			)
			.sort((a, b) =>
				Utils.sort(
					a.orgName.concat(a.locationName).concat(a.displayName),
					b.orgName.concat(b.locationName).concat(b.displayName)
				)
			)

		let body = filtered.map((inv) => {
			return [
				inv.orgName,
				inv.locationName,
				inv.code,
				inv.displayName,
				inv.brand,
				inv.unit,
				inv.current,
				inv.min,
				inv.max,
			]
		})

		dispatch({
			type: LOAD_OUT_OF_STOCK_INVENTORY,
			payload: {
				original: inventoryDetails,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const resetAction = () => {
	return {
		type: RESET,
		payload: {},
	}
}

export const selectTypeAction = (index, type) => {
	return {
		type: SELECT_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}

export const selectPRAction = (index, pr) => {
	return (dispatch, getState) => {
		let items =
			pr && pr.items
				? pr.items.map((item) => {
						if (!item.selected) {
							let branch = getState()
								.purchaseRequest.location.locations.filter((org) => org.id === pr.location.parent.id)[0]
								.locations.filter((branch) => branch.id === pr.location.id)[0]

							let inStockItem = branch.inStockItems.find(
								(inStockItem) =>
									inStockItem.displayName.trim().toLowerCase() === item.displayName.trim().toLowerCase() &&
									inStockItem.brand.trim().toLowerCase() === item.brand.trim().toLowerCase() &&
									inStockItem.supplierName.trim().toLowerCase() === item.supplierName.trim().toLowerCase()
							)
							let current = inStockItem
								? inStockItem.lots
										.reduce((subTotal, lot) => {
											return subTotal.plus(lot.current)
										}, Utils.BigNumber(0))
										.toNumber()
								: Utils.BigNumber(0).toNumber()

							let minAndMax = branch.locations.reduce(
								(minMax, inventory) => {
									let inventoryDetails = inventory.inventoryDetails.filter((invD) => {
										return invD.displayName.trim().toLowerCase() === item.displayName.trim().toLowerCase() &&
											invD.brand.trim().toLowerCase() === item.brand.trim().toLowerCase() &&
											invD.supplierName
											? invD.supplierName.trim().toLowerCase()
											: invD.supplier.displayName.trim().toLowerCase() === item.supplierName.trim().toLowerCase()
									})
									if (inventoryDetails.length > 0) {
										minMax[0] = minMax[0].plus(
											inventoryDetails.reduce((min, invD) => {
												return min.plus(invD.min)
											}, Utils.BigNumber(0))
										)
										minMax[1] = minMax[1].plus(
											inventoryDetails.reduce((max, invD) => {
												return max.plus(invD.max)
											}, Utils.BigNumber(0))
										)
									}

									return minMax
								},
								[Utils.BigNumber(0), Utils.BigNumber(0)]
							)

							Object.assign(item, {
								current: current,
								min: minAndMax[0].toNumber(),
								max: minAndMax[1].toNumber(),
							})
						}
						return item
				  })
				: []

		let pendingItems = items.filter((item) => !item.selected).sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let pendingItemData = pendingItems.map((item) => [
			item.code,
			item.displayName,
			item.unit,
			Utils.formatNumWithComma(item.current),
			Utils.formatNumWithComma(item.min),
			Utils.formatNumWithComma(item.max),
			Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
				" x " +
				Utils.formatNumWithComma(item.minQtyPerOrder),
			Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, getState().login.user.roles),
		])

		let selectedItems = items.filter((item) => item.selected).sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let selectedItemData = selectedItems.map((item) => [
			item.code,
			item.displayName,
			item.unit,
			Utils.formatNumWithComma(item.current),
			Utils.formatNumWithComma(item.min),
			Utils.formatNumWithComma(item.max),
			Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
				" x " +
				Utils.formatNumWithComma(item.minQtyPerOrder),
		])

		dispatch({
			type: SELECT_PR,
			payload: {
				index: index,
				pr: pr,
				note: pr ? pr.note : "",
				reason: pr ? pr.reason : "",
				pending: pendingItems,
				pendingItems: pendingItemData,
				selected: selectedItems,
				selectedItems: selectedItemData,
			},
		})
		return Promise.resolve()
	}
}

export const selectPRDetailAction = (pr, rIndex, cIndex, cValue) => {
	return {
		type: SELECT_PR_DETAIL,
		payload: {
			pr: pr,
			rIndex: rIndex,
			cIndex: cIndex,
			cValue: cValue,
		},
	}
}

export const selectItemAction = (index) => {
	return (dispatch) => {
		dispatch({
			type: SELECT_NEW_ITEM,
			payload: {
				index: index,
			},
		})
		return Promise.resolve()
	}
}

export const selectPrItemAction = (index, item) => {
	return {
		type: SELECT_PR_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setTextAreaAction = (type, text) => {
	return {
		type: type,
		payload: {
			text: text,
		},
	}
}

export const setItemPropertyAction = (type, data, ...id) => {
	return (dispatch) => {
		dispatch({
			type: SET_PROPERTY,
			payload: {
				subType: type,
				id: id[0],
				data: data,
			},
		})
		return Promise.resolve()
	}
}

export const setInventoryPropertyAction = (type, data, ...id) => {
	return (dispatch) => {
		dispatch({
			type: SET_INVENTORY_PROPERTY,
			payload: {
				subType: type,
				id: id[0],
				data: data,
			},
		})
		return Promise.resolve()
	}
}

export const setSelectedInventoriesAction = (inventories) => {
	let sortedInventories = inventories.sort((a, b) => Utils.sort(a.displayName, b.displayName))
	let body = sortedInventories.map((inventory) => {
		return [
			inventory.code,
			inventory.displayName,
			inventory.brand,
			inventory.unit,
			Utils.formatNumWithComma(Utils.BigNumber(inventory.amount).dividedBy(inventory.minQtyPerOrder).toNumber()) +
				" x " +
				Utils.formatNumWithComma(inventory.minQtyPerOrder),
		]
	})
	return {
		type: SET_SELECTED_INVENTORIES,
		payload: {
			body: body,
			inventories: sortedInventories,
		},
	}
}

export const selectInventoryAction = (index, inventory) => {
	return {
		type: SELECT_INVENTORY,
		payload: {
			index: index,
			inventory: inventory,
		},
	}
}

export const addNewItemAction = (data) => {
	return {
		type: ADD_PR_NEW_ITEM,
		payload: {
			body: Object.keys(data)
				.map((key) => data[key])
				.sort((a, b) => Utils.sort(a.displayName, b.displayName))
				.map((item) => {
					return [item.code, item.displayName, item.brand, item.unit, Utils.formatNumWithComma(item.amount)]
				}),
			items: data,
		},
	}
}

export const setSearchNumAction = (num) => {
	return {
		type: SET_SEARCH_NUM,
		payload: {
			searchNum: num,
		},
	}
}

export const setPrSearchLocation = (loc) => {
	return {
		type: SET_PR_SEARCH_LOCATION,
		payload: {
			searchLocation: loc,
		},
	}
}

export const setDisplayAllAction = (isDisplayAll) => {
	return {
		type: SET_DISPLAY_ALL,
		payload: {
			isDisplayAll: isDisplayAll,
		},
	}
}

export const setPrLocations = (locs) => {
	return (dispatch) => {
		dispatch({
			type: SET_PR_LOCATIONS,
			payload: {
				locations: locs,
			},
		})

		return Promise.resolve()
	}
}

export const setPrLocationOrgs = (locs) => {
	let orgs = locs
		.filter((loc) => loc.code.displayName === "ORG" && loc.active)
		.sort((a, b) => Utils.sort(a.displayName, b.displayName))
	return {
		type: SET_PR_LOCATION_ORG,
		payload: {
			orgs: orgs,
		},
	}
}

export const setPrLocationBranches = (locs) => {
	let branches = locs
		.filter((loc) => loc.code.displayName === "BRANCH" && loc.active)
		.sort((a, b) => Utils.sort(a.displayName, b.displayName))
	return {
		type: SET_PR_LOCATION_BRANCH,
		payload: {
			branches: branches,
		},
	}
}

export const setPrSelectedOrg = (index, org) => {
	return {
		type: SET_PR_SELECTED_ORG,
		payload: {
			org: org,
			index: index,
		},
	}
}

export const setPrSelectedBranch = (index, branch) => {
	return {
		type: SET_PR_SELECTED_BRANCH,
		payload: {
			branch: branch,
			index: index,
		},
	}
}

export const setPrApproveEvent = (event) => {
	return {
		type: SET_PR_APPROVE_EVENT,
		payload: {
			event: event,
		},
	}
}

export const setPrApproveInd = (isApprove) => {
	return {
		type: SET_PR_APPROVE_APPROVE_IND,
		payload: {
			isApprove: isApprove,
		},
	}
}

export const setPrMinLastPurchasedInfo = (lastPurchasedFrom, lastPurchasedDate) => {
	return {
		type: SET_PR_MIN_STOCK_LAST_PURCHASED_INFO,
		payload: {
			lastPurchasedFrom: lastPurchasedFrom,
			lastPurchasedDate: lastPurchasedDate,
		},
	}
}

export const setPrNewPrLastPurchasedInfo = (itemName) => {
	return (dispatch, getState) => {
		let lastPurchasedFrom = ""
		let lastPurchasedDate = ""
		let branch = getState().purchaseRequest.location.selectedBranch.branch
		if (branch) {
			branch.inStockItems
				.filter((inStockItem) => inStockItem.displayName.trim().toLowerCase() === itemName.trim().toLowerCase())
				.forEach((inStockItem) => {
					if (lastPurchasedDate) {
						if (lastPurchasedDate.getTime() > inStockItem.lastPurchasedDateTime) {
							lastPurchasedFrom = inStockItem.brand.concat("(", inStockItem.supplierName).concat(")")
							lastPurchasedDate = new Date(inStockItem.lastPurchasedDateTime)
						}
					} else {
						lastPurchasedFrom = inStockItem.brand.concat("(", inStockItem.supplierName).concat(")")
						lastPurchasedDate = new Date(inStockItem.lastPurchasedDateTime)
					}
				})
		}

		dispatch({
			type: SET_PR_NEW_PR_LAST_PURCHASED_INFO,
			payload: {
				lastPurchasedFrom: lastPurchasedFrom,
				lastPurchasedDate: lastPurchasedDate ? lastPurchasedDate.toLocaleDateString() : "",
			},
		})
		return Promise.resolve()
	}
}
