import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME,
	SET_DEPOSIT_MODAL_SEARCH_ID,
	SET_DEPOSIT_MODAL_SEARCH_LAST_NAME,
	SET_DEPOSIT_MODAL_SEARCH_PID,
} from "~/redux/type"

class DepositModalSearchPatientSearch extends React.Component {
	constructor(props) {
		super(props)

		this.onIdentificationNumber = this.onIdentificationNumber.bind(this)
		this.onPid = this.onPid.bind(this)
		this.onFirstName = this.onFirstName.bind(this)
		this.onLastName = this.onLastName.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_ID, "")
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_PID, "")
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME, "")
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_LAST_NAME, "")
	}

	onIdentificationNumber(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_ID, event.target.value)
	}

	onPid(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_PID, event.target.value)
	}

	onFirstName(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME, event.target.value)
	}

	onLastName(event) {
		this.props.setValue(SET_DEPOSIT_MODAL_SEARCH_LAST_NAME, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col />
					<Col md={"auto"}>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.patientId)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								autoFocus
								required
								type={ResourceAssistance.FormControl.type.number}
								disabled={
									this.props.deposit.modalSearch.identificationNumber ||
									this.props.deposit.modalSearch.firstName ||
									this.props.deposit.modalSearch.lastName ||
									this.props.deposit.modalSearch.startDateTime
								}
								value={this.props.deposit.modalSearch.pid}
								onChange={this.onPid}
							/>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<FormLabel>{translate(ResourceAssistance.Message.or)}</FormLabel>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.identification)}</InputGroup.Text>
							</InputGroup.Prepend>

							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								disabled={
									this.props.deposit.modalSearch.pid ||
									this.props.deposit.modalSearch.firstName ||
									this.props.deposit.modalSearch.lastName ||
									this.props.deposit.modalSearch.startDateTime
								}
								value={this.props.deposit.modalSearch.identificationNumber}
								onChange={this.onIdentificationNumber}
							/>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<FormLabel>{translate(ResourceAssistance.Message.or)}</FormLabel>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.firstName}>
							{(placeholder) => (
								<FormControl
									required={_.isEmpty(this.props.deposit.modalSearch.lastName)}
									type={ResourceAssistance.FormControl.type.text}
									placeholder={placeholder}
									disabled={
										this.props.deposit.modalSearch.identificationNumber ||
										this.props.deposit.modalSearch.pid ||
										this.props.deposit.modalSearch.startDateTime
									}
									value={this.props.deposit.modalSearch.firstName}
									onChange={this.onFirstName}
								/>
							)}
						</FormattedMessage>
					</Col>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.lastName}>
							{(placeholder) => (
								<FormControl
									required={_.isEmpty(this.props.deposit.modalSearch.firstName)}
									type={ResourceAssistance.FormControl.type.text}
									placeholder={placeholder}
									disabled={
										this.props.deposit.modalSearch.identificationNumber ||
										this.props.deposit.modalSearch.pid ||
										this.props.deposit.modalSearch.startDateTime
									}
									value={this.props.deposit.modalSearch.lastName}
									onChange={this.onLastName}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositModalSearchPatientSearch)
