import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_CASHIER_MODAL_ADJUSTMENT_BALANCE } from "~/redux/type"

class CashierModalAdjustmentBalance extends React.Component {
	constructor(props) {
		super(props)

		this.onBalance = this.onBalance.bind(this)
	}

	onBalance(event) {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.balance)}</legend>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col md={2} />
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.totalPrice)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.number}
								step={0.01}
								value={this.props.cashier.modalAdjustment.balance}
								onChange={this.onBalance}
							/>
						</InputGroup>
					</Col>
					<Col md={2} />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalAdjustmentBalance)
