import { ResourceAssistance } from "~/i18n"
import { SET_FRONT_INVENTORY_INVENTORIES, SET_FRONT_INVENTORY_LOTS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setFrontInventoryInventories = (inventories) => {
	return (dispatch, getState) => {
		let filtered = inventories
			.filter(
				(inventory) =>
					inventory.type.id === getState().hospital.frontInventory.selectedType.type.id &&
					inventory.code
						.trim()
						.toLowerCase()
						.includes(getState().hospital.frontInventory.searchCode.trim().toLowerCase()) &&
					inventory.genericName
						.trim()
						.toLowerCase()
						.includes(getState().hospital.frontInventory.searchGenericName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.genericName, b.genericName))

		if (getState().hospital.frontInventory.isDisplayExpired) {
			filtered = filtered.filter((inventory) =>
				inventory.transferOrderItems.some(
					(item) => item.expiredDateTime <= getState().hospital.frontInventory.lotExpiredDateTime
				)
			)
		}

		if (getState().hospital.frontInventory.isDisplayMinStock) {
			filtered = filtered.filter((inventory) => Utils.BigNumber(inventory.totalAmount).lt(inventory.max))
		}

		let rowColor = []

		let body = filtered.map((inventory, index) => {
			return [
				inventory.code,
				inventory.genericName,
				inventory.brand,
				inventory.unit,
				Utils.formatNumWithComma(inventory.totalAmount),
				Utils.formatNumWithComma(inventory.min),
				Utils.formatNumWithComma(inventory.max),
				[true],
			]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_INVENTORIES,
			payload: {
				original: inventories,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.filter((lot) => lot.total > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		if (getState().hospital.frontInventory.isDisplayExpired) {
			filtered = filtered.filter((lot) => lot.expiredDateTime <= getState().hospital.frontInventory.lotExpiredDateTime)
		}

		let colColor = filtered.map((lot) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[
					Utils.BigNumber(lot.expiredDateTime).lte(getState().hospital.frontInventory.lotExpiredDateTime),
					ResourceAssistance.CSS.Color.red,
				],
				[false, ""],
			]
		})

		let body = filtered.map((lot, index) => {
			return [lot.lotNum, Utils.formatNumWithComma(lot.total), lot.unit, Utils.formatDate(lot.expiredDateTime)]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				colColor: colColor,
			},
		})
		return Promise.resolve()
	}
}
