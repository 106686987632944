import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class RegModalClinicalRecordOther extends React.Component {
	ERadio = {
		YES: "YES",
		NO: "NO",
	}
	constructor(props) {
		super(props)

		this.state = {
			translator: this.ERadio.NO,
		}

		this.onTranslator = this.onTranslator.bind(this)
		this.onLanguage = this.onLanguage.bind(this)
	}

	componentDidMount() {
		if (this.props.reg.selectedAdmission.admission) {
			this.props.setValue(
				SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE,
				this.props.reg.selectedAdmission.admission.clinicalRecord.language
			)
			if (this.props.reg.selectedAdmission.admission.clinicalRecord.language) {
				this.setState({
					translator: this.ERadio.YES,
				})
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.translator, this.state.translator)) {
			this.props.setValue(
				SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE,
				this.state.translator === this.ERadio.YES && this.props.reg.selectedAdmission.admission
					? this.props.reg.selectedAdmission.admission.clinicalRecord.language
					: ""
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE, "")
	}

	onTranslator(e) {
		this.setState({
			translator: e.target.value,
		})
	}

	onLanguage(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.other)}</legend>
				<Row noGutters>
					<Col md="auto">
						<FormLabel style={{ paddingLeft: "0.3rem", marginTop: "10px" }}>
							{translate(ResourceAssistance.Message.translator)}
						</FormLabel>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<Radio.Group
							style={{ marginTop: "10px" }}
							options={[
								{ label: translate(ResourceAssistance.Message.no), value: this.ERadio.NO },
								{ label: translate(ResourceAssistance.Message.yes), value: this.ERadio.YES },
							]}
							value={this.state.translator}
							onChange={this.onTranslator}
						/>
					</Col>
					<Col>
						<GInput
							style={{ marginTop: 0 }}
							required
							placeholder={translate(ResourceAssistance.Message.language)}
							disabled={this.state.translator === this.ERadio.NO}
							value={this.props.reg.modalClinicalRecord.language}
							onChange={this.onLanguage}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegModalClinicalRecordOther)
