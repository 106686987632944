import _ from "lodash"
import React from "react"
import { Badge, Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmUserSelectedUser, setAdmUserUsers, setLoadingAction } from "~/redux/action"
import { SET_ADM_USER_NEW_USER_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmUserModalNUInfo from "./AdmUserModalNUInfo"
import AdmUserModalNURole from "./AdmUserModalNURole"

class AdmUserModalNU extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmUserModalNU",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.user.selectedUser.uesr, {
			...this.props.user.selectedUser.user,
			lastName: this.props.user.modalNewUser.lastName.trim(),
			firstName: this.props.user.modalNewUser.firstName.trim(),
			lastNameEng: Utils.trim(this.props.user.modalNewUser.lastNameEng),
			firstNameEng: Utils.trim(this.props.user.modalNewUser.firstNameEng),
			email: this.props.user.modalNewUser.email.trim(),
			nickname: this.props.user.modalNewUser.nickname.trim(),
			username: this.props.user.modalNewUser.loginName.trim(),
			phone: Utils.trim(this.props.user.modalNewUser.phone),
			active: this.props.user.modalNewUser.isActive,
			roles: this.props.user.modalNewUser.pendingRoleTable.original,
			role: this.props.user.modalNewUser.selectedRoleType.type,
			licenseNum: Utils.trim(this.props.user.modalNewUser.licenseNum),
		})
		return (
			_.isEmpty(this.props.user.modalNewUser.pendingRoleTable.original) ||
			_.isEqual(this.props.user.selectedUser.user, target)
		)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_USER_NEW_USER_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.user.saveUser}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.user.selectedUser.index === -1 ? 0 : this.props.user.selectedUser.user.id,
				lastName: this.props.user.modalNewUser.lastName.trim(),
				firstName: this.props.user.modalNewUser.firstName.trim(),
				lastNameEng: Utils.trim(this.props.user.modalNewUser.lastNameEng),
				firstNameEng: Utils.trim(this.props.user.modalNewUser.firstNameEng),
				email: this.props.user.modalNewUser.email.trim(),
				phone: Utils.trim(this.props.user.modalNewUser.phone),
				nickname: this.props.user.modalNewUser.nickname.trim(),
				loginName: this.props.user.modalNewUser.loginName.trim(),
				password: this.props.user.modalNewUser.password.trim(),
				active: this.props.user.modalNewUser.isActive,
				userId: this.props.login.user.id,
				roles: this.props.user.modalNewUser.pendingRoleTable.original.map((role) => {
					return {
						id: role.id,
					}
				}),
				roleId: this.props.user.modalNewUser.selectedRoleType.type.id,
				licenseNum: Utils.trim(this.props.user.modalNewUser.licenseNum),
			},
		}

		let callback = (res) => {
			this.props.setAdmUserSelectedUser(-1, null)
			let users = this.props.user.userTable.original.filter((each) => each.id !== res.data.users[0].id)
			users.push(res.data.users[0])
			this.props.setAdmUserUsers(users)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_USER_NEW_USER_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newUser)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<Row className={`${ResourceAssistance.CSS.fullSize}`}>
								<AdmUserModalNUInfo />
								<AdmUserModalNURole />
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmUserNewUserDisplay,
	login: state.login,
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmUserSelectedUser, setAdmUserUsers }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUserModalNU)
