import React from "react"
import {
	Badge,
	Button,
	Col,
	Container,
	Form,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FcDownload, FcUpload } from "react-icons/fc"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setDateAction,
	setLoadingAction,
	setRcInvoiceNum,
	setRcModalReceiveWaitingReceives,
	setRcOrders,
	setRcPendingReceives,
	setRcSelectedPending,
	setRcSelectedSupplier,
	setRcSelectedWaiting,
	setRcTaxedTotalPrice,
	setSearchText,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_RC_RECEIVE_DETAIL_DISPLAY, SET_RC_RECEIVE_DISPLAY } from "~/redux/type/type/MODAL"
import { SET_RC_RECEIVED_DATE, SET_RC_SEARCH_CODE, SET_RC_SEARCH_NAME } from "~/redux/type/type/RC"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

const EFormInput = {
	RC_TOTAL_PRICE: "RC_TOTAL_PRICE",
}

class RCModalReceive extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCModalReceive",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onWaitingSelectRow = this.onWaitingSelectRow.bind(this)
		this.onPendingSelectRow = this.onPendingSelectRow.bind(this)
		this.onSupplierChange = this.onSupplierChange.bind(this)
		this.onCodeChange = this.onCodeChange.bind(this)
		this.onNameChange = this.onNameChange.bind(this)
		this.onReceiveDate = this.onReceiveDate.bind(this)
		this.onInvoiceChange = this.onInvoiceChange.bind(this)
		this.onTaxedTotalPriceChange = this.onTaxedTotalPriceChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.modalReceive.searchCode !== this.props.modalReceive.searchCode ||
			prevProps.modalReceive.searchName !== this.props.modalReceive.searchName ||
			JSON.stringify(prevProps.selectedSupplier) !== JSON.stringify(this.props.selectedSupplier)
		) {
			if (this.props.selectedSupplier.supplier) {
				this.props.setRcModalReceiveWaitingReceives(this.props.selectedSupplier.supplier.items)
			}
		}

		if (
			JSON.stringify(prevProps.modalReceiveWaitingReceive.filtered) !==
			JSON.stringify(this.props.modalReceiveWaitingReceive.filtered)
		) {
			let index = this.props.selectedWaiting.inventory
				? this.props.modalReceiveWaitingReceive.filtered.findIndex(
						(inventory) => inventory.id === this.props.selectedWaiting.inventory.id
				  )
				: -1
			if (index !== -1) {
				this.props.setRcSelectedWaiting(index, this.props.modalReceiveWaitingReceive.filtered[index])
			} else {
				this.props.setRcSelectedWaiting(-1, null)
			}
		}

		if (
			prevProps.modalReceive.taxedTotalPrice !== this.props.modalReceive.taxedTotalPrice ||
			JSON.stringify(prevProps.pendingReceive.filtered) !== JSON.stringify(this.props.pendingReceive.filtered)
		) {
			this.validateTotalPrice()
		}

		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				let selectedIndex = document.getElementById(ResourceAssistance.ID.RC.modalReceiveSupplier).value
				this.props.setRcSelectedSupplier(selectedIndex, this.props.selectedRc.rc.suppliers[selectedIndex])
			}
		}
	}

	reset() {
		//modalReceive
		this.props.setSearchText(SET_RC_SEARCH_CODE, "")
		this.props.setSearchText(SET_RC_SEARCH_NAME, "")
		this.props.setDateAction(SET_RC_RECEIVED_DATE, Utils.generateDate())
		this.props.setRcInvoiceNum("")
		this.props.setRcTaxedTotalPrice("")

		this.props.setRcModalReceiveWaitingReceives([])
		this.props.setRcPendingReceives([])
		this.props.setRcSelectedSupplier(-1, null)
		this.props.setRcSelectedWaiting(-1, null)
		this.props.setRcSelectedPending(-1, null)

		this.props.onModalDisplayAction(SET_RC_RECEIVE_DISPLAY, false)
	}

	validateTotalPrice() {
		let htmlInput = document.getElementById(EFormInput.RC_TOTAL_PRICE)
		if (!htmlInput) {
			return
		}

		if (
			this.props.modalReceive.taxedTotalPrice &&
			this.props.selectedSupplier.supplier &&
			this.props.pendingReceive.filtered.length > 0
		) {
			let pendingTotalPrice = this.props.pendingReceive.filtered.reduce((object, item) => {
				return object.plus(item.taxedTotalPrice)
			}, Utils.BigNumber(0))

			if (
				Utils.BigNumber(this.props.modalReceive.taxedTotalPrice)
					.integerValue()
					.isEqualTo(pendingTotalPrice.integerValue())
			) {
				htmlInput.setCustomValidity("")
			} else {
				htmlInput.setCustomValidity(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxedTotalPriceDoesNotMatch })
				)
			}
		} else {
			htmlInput.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxedTotalPriceDoesNotMatch })
			)
		}
	}

	onSupplierChange(event) {
		this.props.setRcSelectedSupplier(event.target.value, this.props.selectedRc.rc.suppliers[event.target.value])
	}

	onCodeChange(event) {
		this.props.setSearchText(SET_RC_SEARCH_CODE, event.target.value)
	}

	onNameChange(event) {
		this.props.setSearchText(SET_RC_SEARCH_NAME, event.target.value)
	}

	onReceiveDate(date) {
		this.props.setDateAction(SET_RC_RECEIVED_DATE, date)
	}

	onInvoiceChange(event) {
		this.props.setRcInvoiceNum(event.target.value)
	}

	onTaxedTotalPriceChange(event) {
		this.props.setRcTaxedTotalPrice(event.target.value)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.rc.saveReceiveItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				id: this.props.selectedRc.rc.id,
				supplierCode: this.props.selectedSupplier.supplier.code,
				supplierName: this.props.selectedSupplier.supplier.displayName,
				receivedDate: this.props.modalReceive.receivedDate,
				payment: this.props.selectedSupplier.supplier.payment,
				terms: this.props.selectedSupplier.supplier.paymentTerms,
				invoice: this.props.modalReceive.invoiceNum.trim(),
				discount: this.props.selectedSupplier.supplier.discount,
				totalPrice: Utils.BigNumber(this.props.modalReceive.taxedTotalPrice).toNumber(),

				receiveItems: this.props.pendingReceive.filtered.map((receive) => {
					return {
						prItemId: receive.prItemId,
						lot: receive.lot,
						expireDate: receive.expireDate,
						amount: Utils.BigNumber(receive.amount).times(receive.minQtyPerOrder).toNumber(),
						bonus: receive.bonus,
						totalPrice: receive.totalPrice,
					}
				}),
			},
		}

		let callback = (res) => {
			let original = this.props.order.original
			res.data.receives.forEach((receive) => {
				let index = original.findIndex((original) => original.id === receive.id)
				original[index] = receive
			})
			this.props.setRcOrders(original)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onWaitingSelectRow(row, index) {
		this.props.setRcSelectedWaiting(index, this.props.modalReceiveWaitingReceive.filtered[index])
	}

	onPendingSelectRow(row, index) {
		this.props.setRcSelectedPending(index, this.props.pendingReceive.filtered[index])
	}

	onDownBtn(event) {
		if (this.props.selectedWaiting.index === -1) {
			return
		}
		this.props.onModalDisplayAction(SET_RC_RECEIVE_DETAIL_DISPLAY, true)
	}

	onUpBtn(event) {
		if (this.props.selectedPending.index === -1) {
			return
		}
		let pendingReceives = this.props.pendingReceive.filtered
		pendingReceives.splice(this.props.selectedPending.index, 1)
		this.props.setRcPendingReceives(pendingReceives)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.newReceive)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={"full-flex"}>
							<Row>
								<Col md="auto">
									<FormControl
										id={ResourceAssistance.ID.RC.modalReceiveSupplier}
										as={ResourceAssistance.FormControl.as.select}
										size={ResourceAssistance.FormControl.size.sm}
										disabled={this.props.pendingReceive.filtered.length > 0}
										onChange={this.onSupplierChange}
									>
										{this.props.selectedRc.rc ? (
											Utils.renderOptions(this.props.selectedRc.rc.suppliers, false)
										) : (
											<option></option>
										)}
									</FormControl>
								</Col>
								<Col>
									<SearchBox
										size={ResourceAssistance.Button.size.sm}
										num={2}
										controlType={ResourceAssistance.FormControl.type.text}
										callbacks={[this.onCodeChange, this.onNameChange]}
										placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
									/>
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col id={ResourceAssistance.ID.RC.modalReceive.tableReceive}>
									<ScrollableTable
										enableHighlight
										striped
										bordered
										hover
										isClearHighlight={this.props.selectedWaiting.index === -1}
										data={this.props.modalReceiveWaitingReceive}
										onClick={this.onWaitingSelectRow}
									/>
								</Col>
							</Row>
							<Row>
								<Col className={this.props.selectedWaiting.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
									<FcDownload
										size={"3rem"}
										style={{ alignSelf: ResourceAssistance.CSS.center }}
										onClick={this.onDownBtn}
									/>
								</Col>
								<Col className={this.props.selectedPending.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
									<FcUpload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onUpBtn} />
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col id={ResourceAssistance.ID.RC.modalReceive.tableReceived}>
									<ScrollableTable
										enableHighlight
										striped
										bordered
										hover
										isClearHighlight={false}
										data={this.props.pendingReceive}
										onClick={this.onPendingSelectRow}
									/>
								</Col>
							</Row>
							<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
								<Col md="auto">
									<DatePicker
										isShowTime={false}
										startDate={this.props.modalReceive.receivedDate}
										onChange={this.onReceiveDate}
									/>
								</Col>
								<Col md="auto">
									<FormLabel>{translate(ResourceAssistance.Message.invoiceNum)}</FormLabel>
								</Col>
								<Col>
									<FormControl
										required
										type={ResourceAssistance.FormControl.type.text}
										size={ResourceAssistance.FormControl.size.sm}
										value={this.props.modalReceive.invoiceNum}
										onChange={this.onInvoiceChange}
									></FormControl>
								</Col>
								<Col md="auto">
									<FormLabel>{translate(ResourceAssistance.Message.payment)}</FormLabel>
								</Col>
								<Col md="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.sm}
										disabled
									>
										{this.props.selectedSupplier.supplier ? this.props.selectedSupplier.supplier.payment : ""}
									</Button>
								</Col>
								<Col md="auto">
									<FormLabel>{translate(ResourceAssistance.Message.supplierDiscount)}</FormLabel>
								</Col>
								<Col md="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.sm}
										disabled
									>
										{this.props.selectedSupplier.supplier ? this.props.selectedSupplier.supplier.discount : ""}%
									</Button>
								</Col>
								<Col md="auto">
									<FormLabel>{translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)}</FormLabel>
								</Col>
								<Col md={1}>
									<FormControl
										required
										id={EFormInput.RC_TOTAL_PRICE}
										type={ResourceAssistance.FormControl.type.number}
										size={ResourceAssistance.FormControl.size.sm}
										value={this.props.modalReceive.taxedTotalPrice}
										min="0"
										step="0.01"
										onChange={this.onTaxedTotalPriceChange}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isRCReceiveDisplay,
	order: state.receive.order,
	modalReceive: state.receive.modalReceive,
	modalReceiveWaitingReceive: state.receive.modalReceiveWaitingReceive,
	pendingReceive: state.receive.pendingReceive,
	selectedWaiting: state.receive.selectedWaiting,
	selectedPending: state.receive.selectedPending,
	selectedRc: state.receive.selectedRc,
	selectedSupplier: state.receive.selectedSupplier,
	payment: state.receive.payment,
	selectedPayment: state.receive.selectedPayment,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDateAction,
			setLoadingAction,
			setRcInvoiceNum,
			setRcModalReceiveWaitingReceives,
			setRcOrders,
			setRcPendingReceives,
			setRcSelectedPending,
			setRcSelectedSupplier,
			setRcSelectedWaiting,
			setRcTaxedTotalPrice,
			setSearchText,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCModalReceive))
