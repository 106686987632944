import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setRcInvoices, setRcSelectedInvoice } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RCRightFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCRightFirstRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	onSelectRow(row, index) {
		this.props.setRcSelectedInvoice(index, this.props.invoice.filtered[index])
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.selectedRc.rc) !== JSON.stringify(this.props.selectedRc.rc)) {
			if (this.props.selectedRc.rc) {
				this.props.setRcInvoices(this.props.selectedRc.rc.invoices)
			} else {
				this.props.setRcInvoices([])
			}
			this.props.setRcSelectedInvoice(-1, null)
		}
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						enableOverlay
						data={this.props.invoice}
						isClearHighlight={this.props.selectedInvoice.index === -1}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	invoice: state.receive.invoice,
	selectedRc: state.receive.selectedRc,
	selectedInvoice: state.receive.selectedInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcInvoices, setRcSelectedInvoice }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCRightFirstRow)
