import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmUserModalCPWConfirmPassword, setAdmUserModalCPWPassword } from "~/redux/action"

class AdmUserModalCPWDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onPassword = this.onPassword.bind(this)
		this.onConfirmPassword = this.onConfirmPassword.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmUserModalCPWPassword("")
		this.props.setAdmUserModalCPWConfirmPassword("")
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.user.modalChangePassword.password !== this.props.user.modalChangePassword.password ||
			prevProps.user.modalChangePassword.confirmPassword !== this.props.user.modalChangePassword.confirmPassword
		) {
			this.validatePassword()
		}
	}

	validatePassword() {
		let passwordHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalCPW.password)
		let confirmPasswordHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalCPW.confirmPassword)

		if (
			this.props.user.modalChangePassword.password.trim() !== this.props.user.modalChangePassword.confirmPassword.trim()
		) {
			passwordHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.passwordDoesNotMatch })
			)
			confirmPasswordHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.passwordDoesNotMatch })
			)
		} else {
			passwordHtml.setCustomValidity("")
			confirmPasswordHtml.setCustomValidity("")
		}
	}

	onPassword(event) {
		this.props.setAdmUserModalCPWPassword(event.target.value.trim())
	}

	onConfirmPassword(event) {
		this.props.setAdmUserModalCPWConfirmPassword(event.target.value.trim())
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.password)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalCPW.password}
							type={ResourceAssistance.FormControl.type.password}
							value={this.props.user.modalChangePassword.password}
							onChange={this.onPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.confirmPassword)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalCPW.confirmPassword}
							type={ResourceAssistance.FormControl.type.password}
							value={this.props.user.modalChangePassword.confirmPassword}
							onChange={this.onConfirmPassword}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmUserModalCPWPassword, setAdmUserModalCPWConfirmPassword }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmUserModalCPWDetails))
