import { DatePicker } from "antd"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setCashierModalSearchPatients, setSelected, setValue } from "~/redux/action"
import { SET_CASHIER_MODAL_SEARCH_END_DATETIME, SET_CASHIER_MODAL_SEARCH_START_DATETIME } from "~/redux/type"

class CashierModalSearchPatientDate extends React.Component {
	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_CASHIER_MODAL_SEARCH_START_DATETIME, 0)
		this.props.setValue(SET_CASHIER_MODAL_SEARCH_END_DATETIME, 0)
	}

	onDateChange(value, dateString) {
		this.props.setValue(SET_CASHIER_MODAL_SEARCH_START_DATETIME, value ? value[0].milliseconds(0).valueOf() : 0)
		this.props.setValue(SET_CASHIER_MODAL_SEARCH_END_DATETIME, value ? value[1].milliseconds(0).valueOf() : 0)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<DatePicker.RangePicker
						size={"large"}
						placeholder={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }),
						]}
						ranges={{
							[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.today })]]: [
								moment().startOf("day"),
								moment().endOf("day"),
							],
							[[this.props.intl.formatMessage({ id: ResourceAssistance.Message.thisMonth })]]: [
								moment().startOf("month"),
								moment().endOf("month"),
							],
						}}
						disabled={
							this.props.cashier.modalSearch.pid ||
							this.props.cashier.modalSearch.identificationNumber ||
							this.props.cashier.modalSearch.firstName ||
							this.props.cashier.modalSearch.lastName
						}
						onChange={this.onDateChange}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalSearchPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierModalSearchPatientDate))
