import { ResourceAssistance } from "~/i18n"
import { SET_HEADER, SET_PAGE_LOADING } from "../type"

const init = {
	isPageLoading: false,
	header: ResourceAssistance.CONSTANT.PMGCARE,
}

const modalReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PAGE_LOADING:
			return Object.assign({}, state, {
				isPageLoading: action.payload.isLoading,
			})

		case SET_HEADER:
			return Object.assign({}, state, {
				header: action.payload.value,
			})

		default:
			return state
	}
}

export default modalReducer
