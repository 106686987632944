export const SET_FRONT_INVENTORY_ORGS = "SET_FRONT_INVENTORY_ORGS"
export const SET_FRONT_INVENTORY_ORGS_LOADING = "SET_FRONT_INVENTORY_ORGS_LOADING"
export const SET_FRONT_INVENTORY_SELECTED_DEPARTMENT = "SET_FRONT_INVENTORY_SELECTED_DEPARTMENT"
export const SET_FRONT_INVENTORY_TYPES = "SET_FRONT_INVENTORY_TYPES"
export const SET_FRONT_INVENTORY_TYPES_LOADING = "SET_FRONT_INVENTORY_TYPES_LOADING"
export const SET_FRONT_INVENTORY_SELECTED_TYPE = "SET_FRONT_INVENTORY_SELECTED_TYPE"
export const SET_FRONT_INVENTORY_SEARCH_CODE = "SET_FRONT_INVENTORY_SEARCH_CODE"
export const SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME = "SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME"
export const SET_FRONT_INVENTORY_INVENTORIES_LOADING = "SET_FRONT_INVENTORY_INVENTORIES_LOADING"
export const SET_FRONT_INVENTORY_INVENTORIES = "SET_FRONT_INVENTORY_INVENTORIES"
export const SET_FRONT_INVENTORY_LOTS = "SET_FRONT_INVENTORY_LOTS"
export const SET_FRONT_INVENTORY_SELECTED_INVENTORY = "SET_FRONT_INVENTORY_SELECTED_INVENTORY"
export const SET_FRONT_INVENTORY_EXPIRE_DATETIME = "SET_FRONT_INVENTORY_EXPIRE_DATETIME"
export const SET_FRONT_INVENTORY_DISPLAY_EXPIRED = "SET_FRONT_INVENTORY_DISPLAY_EXPIRED"
export const SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK = "SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK"
export const SET_FRONT_INVENTORY_EDIT_DISPLAY = "SET_FRONT_INVENTORY_EDIT_DISPLAY"
