import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setRegModalExpenseEstBudgets } from "~/redux/action"
import { SET_REGISTRATION_BUDGET_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import RegModalBudgetDetails from "./RegModalBudgetDetails"

class RegModalBudget extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RegModalBudget",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_REGISTRATION_BUDGET_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.reg.modalExpenseEst.selectedBudget.budget) {
			return false
		}

		let budget = this.props.reg.modalExpenseEst.selectedBudget.budget
		let target = Object.assign({}, budget, {
			code:
				this.props.reg.modalBudget.selectedHealthcare.healthcare &&
				this.props.reg.modalBudget.selectedHealthcare.healthcare.code,
			name:
				this.props.reg.modalBudget.selectedHealthcare.healthcare &&
				this.props.reg.modalBudget.selectedHealthcare.healthcare.displayName,
			description: this.props.reg.modalBudget.selectedHealthcare.healthcare
				? this.props.reg.modalBudget.selectedHealthcare.healthcare.description
				: Utils.trim(this.props.reg.modalBudget.healthcareOther),
			budget: this.props.reg.modalBudget.budget,
			contact: Utils.trim(this.props.reg.modalBudget.contact),
			note: Utils.trim(this.props.reg.modalBudget.note),
		})

		return _.isEqual(budget, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let budget = Object.assign(
			{},
			{
				code:
					this.props.reg.modalBudget.selectedHealthcare.healthcare &&
					this.props.reg.modalBudget.selectedHealthcare.healthcare.code,
				name:
					this.props.reg.modalBudget.selectedHealthcare.healthcare &&
					this.props.reg.modalBudget.selectedHealthcare.healthcare.displayName,
				description: this.props.reg.modalBudget.selectedHealthcare.healthcare
					? this.props.reg.modalBudget.selectedHealthcare.healthcare.description
					: Utils.trim(this.props.reg.modalBudget.healthcareOther),
				budget: this.props.reg.modalBudget.budget,
				contact: Utils.trim(this.props.reg.modalBudget.contact),
				note: Utils.trim(this.props.reg.modalBudget.note),
			}
		)

		let budgets = this.props.reg.modalExpenseEst.budgetTable.original.filter(
			(each, idx) =>
				!this.props.reg.modalExpenseEst.selectedBudget.budget ||
				idx !== this.props.reg.modalExpenseEst.selectedBudget.index
		)
		budgets.push(budget)
		this.props.setRegModalExpenseEstBudgets(budgets)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.budget)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<RegModalBudgetDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.FormControl.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRegistrationBudgetDisplay,
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setRegModalExpenseEstBudgets,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalBudget))
