import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { IoHourglassOutline } from "react-icons/io5"
import { GrRotateLeft } from "react-icons/gr"
import { MdFileDownload } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import { ExcelDataFactory } from "~/utils/factory/ExcelDataFactory"
import { Utils } from "~/utils/Utils"
import ReportCashierAdmissionFilterDetails from "./ReportCashierAdmissionFilterDetails"

class ReportCashierAdmissionFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportCashierAdmissionFilter",
			downloadDisabled: true,
		}

		this.ref = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.filter.id, this.props.filter.id)) {
			this.setState({
				downloadDisabled: true,
			})
		}
	}

	componentWillUnmount() {
		this.setState({
			downloadDisabled: true,
		})
	}

	runReport(admission) {
		let billingStatements = []

		admission.billingStatements.forEach((billingStatement) => {
			if (billingStatement.doctorOrder) {
				for (
					let i = 0;
					i <=
					Utils.calculateDaysBetween(
						billingStatement.doctorOrder.startDateTime,
						billingStatement.doctorOrder.endDateTime
					);
					i++
				) {
					billingStatements.push({
						dateTime: Utils.generateDateFromLong(
							billingStatement.doctorOrder.startDateTime,
							0,
							0,
							i,
							0,
							0,
							0
						).getTime(),
						fsCode: billingStatement.doctorOrder.fsCode,
						code: billingStatement.doctorOrder.code
							? billingStatement.doctorOrder.code
							: billingStatement.doctorOrder.serviceCode,
						categoryNum: billingStatement.doctorOrder.categoryNum,
						description: billingStatement.doctorOrder.description,
						unit: billingStatement.qtyPerDay,
						totalPrice: Utils.BigNumber(billingStatement.qtyPerDay)
							.times(billingStatement.doctorOrder.pricePerUnit)
							.toFixed(2),
					})
				}
			} else if (billingStatement.nurseOrder) {
				for (
					let i = 0;
					i <=
					Utils.calculateDaysBetween(
						billingStatement.nurseOrder.startDateTime,
						billingStatement.nurseOrder.endDateTime
					);
					i++
				) {
					billingStatements.push({
						dateTime: Utils.generateDateFromLong(billingStatement.nurseOrder.startDateTime, 0, 0, i, 0, 0, 0).getTime(),
						fsCode: billingStatement.nurseOrder.fsCode,
						code: billingStatement.nurseOrder.code
							? billingStatement.nurseOrder.code
							: billingStatement.nurseOrder.serviceCode,
						categoryNum: billingStatement.nurseOrder.categoryNum,
						description: billingStatement.nurseOrder.description,
						unit: billingStatement.qtyPerDay,
						totalPrice: Utils.BigNumber(billingStatement.qtyPerDay)
							.times(billingStatement.nurseOrder.pricePerUnit)
							.toFixed(2),
					})
				}
			} else if (billingStatement.lab) {
				billingStatements.push({
					dateTime: billingStatement.lab.issuedDateTime,
					fsCode: billingStatement.lab.service.fsCode,
					code: billingStatement.lab.service.code,
					categoryNum: billingStatement.lab.service.categoryNum,
					description: billingStatement.lab.service.description,
					unit: billingStatement.qtyPerDay,
					totalPrice: Utils.BigNumber(billingStatement.qtyPerDay)
						.times(billingStatement.lab.service.pricePerUnit)
						.toFixed(2),
				})
			} else if (billingStatement.certificate) {
				billingStatements.push({
					dateTime: billingStatement.certificate.lastModifiedDateTime,
					fsCode: billingStatement.certificate.service.fsCode,
					code: billingStatement.certificate.service.code,
					categoryNum: billingStatement.certificate.service.categoryNum,
					description: billingStatement.certificate.service.description,
					unit: billingStatement.qtyPerDay,
					totalPrice: Utils.BigNumber(billingStatement.qtyPerDay)
						.times(billingStatement.certificate.service.pricePerUnit)
						.toFixed(2),
				})
			}
		})

		let excel = ExcelDataFactory.generateCashierAdmission(this.props.filter.id, admission.patient, billingStatements)
		this.ref.current.uodateExcel(excel)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.cashier.getAdmissions,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				admissionId: this.props.filter.id,
			},
		}
		let callback = (res) => {
			this.setState({
				downloadDisabled: _.isEmpty(res.data.admissions),
			})
			if (!_.isEmpty(res.data.admissions)) {
				this.runReport(res.data.admissions[0])
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.byAdmission)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportCashierAdmissionFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={_.isEmpty(this.props.filter.id)}
						>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
						<ExcelDocument
							ref={this.ref}
							element={
								<Button variant={ResourceAssistance.Button.variant.info} disabled={this.state.downloadDisabled}>
									<MdFileDownload size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Hospitel.download)}
								</Button>
							}
						/>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.hospital.cashier.isAdmissionDisplay,
	filter: state.report.cashier.admission,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierAdmissionFilter))
