import React from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	loadPRsAction,
	onModalDisplayAction,
	selectPRAction,
	setDisplayAllAction,
	setPrModalTypeAction,
} from "~/redux/action"
import { PR_NEW } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class PRLeftThirdRow extends React.Component {
	constructor(props) {
		super(props)
		this.onCreatePrBtn = this.onCreatePrBtn.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
	}

	onCreatePrBtn(event) {
		event.preventDefault()
		this.props.selectPRAction(-1, null)
		this.props.onModalDisplayAction(PR_NEW, true)
		this.props.setPrModalTypeAction(PR_NEW)
	}

	onDisplayAll(event) {
		this.props.setDisplayAllAction(event.target.checked)

		this.props.selectPRAction(-1, null)
		this.props.loadPRsAction(this.props.pr.prs, this.props.pr.prs)
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<FormCheck
						className={ResourceAssistance.CSS.flexSelfCenter}
						type={ResourceAssistance.FormControl.type.checkBox}
						label={translate(ResourceAssistance.Message.displayAll)}
						onChange={this.onDisplayAll}
					/>
				</Col>
				<Col md="auto">
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onCreatePrBtn}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.new)}
						</Button>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.login,
		pr: state.purchaseRequest,
		searchDate: state.purchaseRequest.searchDate,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			loadPRsAction,
			selectPRAction,
			setPrModalTypeAction,
			setDisplayAllAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PRLeftThirdRow)
