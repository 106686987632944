import { pdf } from "@react-pdf/renderer"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GoCloudUpload } from "react-icons/go"
import { RiUserAddFill, RiUserSearchFill, RiUserSettingsFill } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { GDrive } from "~/api/google/GDrive"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_REGISTER_PATIENT,
	SET_HOSPITEL_LAB_SEARCH_PATIENT,
	SET_HOSPITEL_LAB_SELECTED_PAITENT,
	SET_PAGE_LOADING,
} from "~/redux/type"
import ReportCovid19Document from "~/report/hospitel/lab/document/ReportCovid19Document"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class CLLeftActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onSearchPatient = this.onSearchPatient.bind(this)
		this.onModifyPatient = this.onModifyPatient.bind(this)
		this.onGoogleDrive = this.onGoogleDrive.bind(this)
	}

	onNew(event) {
		this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_PAITENT, -1, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_REGISTER_PATIENT, true)
	}

	onSearchPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_SEARCH_PATIENT, true)
	}

	onModifyPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_REGISTER_PATIENT, true)
	}

	onGoogleDrive(event) {
		GDrive.signIn().then(() => {
			try {
				this.props.lab.patientTable.filtered
					.filter((patient) => {
						return !_.isEmpty(patient.labs) && patient.labs.some((lab) => lab.approvedBy)
					})
					.forEach((patient) => {
						patient.labs
							.filter((lab) => lab.approvedBy)
							.forEach((lab) => {
								let fileName = patient.id + "_" + lab.id + "_" + patient.firstName + "_" + patient.lastName
								let doc = (
									<ReportCovid19Document
										data={PrintableDataFactory.generateCovid19ReportData(patient, lab)}
										isPdfViewer={false}
									/>
								)
								const asPdf = pdf([])
								asPdf.updateContainer(doc)
								Promise.resolve(asPdf.toBlob()).then((blob) => {
									this.props.setLoadingAction(SET_PAGE_LOADING, true)
									GDrive.uploadPDF(
										fileName,
										blob,
										(f) => {
											Promise.resolve(GDrive.generatePublicUrl(f.id)).then((url) => {
												let params = {
													method: "POST",
													url: ResourceAssistance.Url.lab.updateLabUrl,
													data: {
														id: lab.id,
														patientId: patient.id,
														url: url,
													},
												}
												this.props.updateLab(params)
											})
										},
										(folderName) => {
											this.props.setLoadingAction(SET_PAGE_LOADING, false)
										},
										(fileName) => {
											this.props.setLoadingAction(SET_PAGE_LOADING, false)
										}
									)
								})
							})
					})
			} catch (error) {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
		})
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.Technician, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.lab.patientTable.filtered.length === 0}
							onClick={this.onGoogleDrive}
						>
							<GoCloudUpload size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.googleDrive)}
						</Button>
					</Col>
				)}

				<Col></Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						onClick={this.onSearchPatient}
					>
						<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.Nurse, this.props.login.user.roles) && (
					<Fragment>
						<Col md="auto">
							<div className="vertical"></div>
						</Col>

						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={this.props.lab.selectedPatient.index === -1}
								onClick={this.onModifyPatient}
							>
								<RiUserSettingsFill size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								onClick={this.onNew}
							>
								<RiUserAddFill size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						</Col>
					</Fragment>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CLLeftActionRow))
