import React from "react"
import { Col, FormCheck, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED } from "~/redux/type"

class ReportCashierUserFilterDischarge extends React.Component {
	constructor(props) {
		super(props)

		this.onFilterDischarge = this.onFilterDischarge.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED, false)
	}

	onFilterDischarge(event) {
		this.props.setValue(SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED, event.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.patient)}</legend>
				<Row>
					<Col md={2} />
					<Col md={"auto"}>
						<FormLabel htmlFor="filter_discharge">{translate(ResourceAssistance.Message.discharged)}</FormLabel>
					</Col>
					<Col md="auto">
						<FormCheck
							id={"filter_discharge"}
							checked={this.props.filter.dischargedChecked}
							onChange={this.onFilterDischarge}
						/>
					</Col>
					<Col md={2} />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.cashier.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierUserFilterDischarge))
