import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmTDisplayAll,
	setAdmTModalNTActive,
	setAdmTModalNTDescription,
	setAdmTModalNTName,
	setAdmTModalNTTaxRate,
	setAdmTSelectedTax,
	setAdmTTaxes,
	setSearchText,
} from "~/redux/action"
import { SET_ADMIN_TAX_SEARCH_NAME, SET_ADM_TAX_NEW_TAX_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"

class AdmTax extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentWillUnmount() {
		this.props.setSearchText(SET_ADMIN_TAX_SEARCH_NAME, "")
		this.props.setAdmTDisplayAll(true)
		this.props.setAdmTSelectedTax(-1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.tax.searchName !== this.props.tax.searchName ||
			prevProps.tax.displayInactive !== this.props.tax.displayInactive
		) {
			this.props.setAdmTSelectedTax(-1, null)
			this.props.setAdmTTaxes(this.props.tax.taxTable.original)
		}
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_TAX_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmTDisplayAll(event.target.checked)
	}

	onAdd(event) {
		this.props.setAdmTSelectedTax(-1, null)
		this.props.onModalDisplayAction(SET_ADM_TAX_NEW_TAX_DISPLAY, true)
	}

	onEdit(event) {
		this.props.setAdmTModalNTName(this.props.tax.selectedTax.tax.displayName)
		this.props.setAdmTModalNTDescription(this.props.tax.selectedTax.tax.description)
		this.props.setAdmTModalNTTaxRate(this.props.tax.selectedTax.tax.taxRate)
		this.props.setAdmTModalNTActive(this.props.tax.selectedTax.tax.active)
		this.props.onModalDisplayAction(SET_ADM_TAX_NEW_TAX_DISPLAY, true)
	}

	onSelectRow(row, index) {
		this.props.setAdmTSelectedTax(index, this.props.tax.taxTable.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.tax.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<div className="vertical"></div>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.tax.selectedTax.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.tax.selectedTax.index === -1}
							data={this.props.tax.taxTable}
							onClick={this.onSelectRow}
						/>
						{this.props.tax.taxTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	tax: state.admin.itemConfig.tax,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmTDisplayAll,
			setAdmTModalNTActive,
			setAdmTModalNTDescription,
			setAdmTModalNTName,
			setAdmTModalNTTaxRate,
			setAdmTSelectedTax,
			setAdmTTaxes,
			setSearchText,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmTax)
