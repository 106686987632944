import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setTfModalTransferPendingItems, setTfModalTransferSelectedPendingItem } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFModalNTPendingItemRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentWillUnmount() {
		this.props.setTfModalTransferPendingItems([])
		this.props.setTfModalTransferSelectedPendingItem(-1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.transfer.newTransferModal.pendingItemTable.filtered,
				this.props.transfer.newTransferModal.pendingItemTable.filtered
			)
		) {
			this.props.setTfModalTransferSelectedPendingItem(-1, null)
		}
	}

	onSelectRow(row, index) {
		this.props.setTfModalTransferSelectedPendingItem(
			index,
			this.props.transfer.newTransferModal.pendingItemTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col id={ResourceAssistance.ID.TF.modalTransfer.tableTransferredItem}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableHighlight
						isClearHighlight={this.props.transfer.newTransferModal.selectedPendingItem.index === -1}
						data={this.props.transfer.newTransferModal.pendingItemTable}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfModalTransferSelectedPendingItem, setTfModalTransferPendingItems }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNTPendingItemRow)
