import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"
import { store } from "~/redux/Store"
import ReportRedirect from "~/report/ReportRedirect"
import NotFoundPage from "~/view/container/404Page/NotFoundPage"
import App from "./App"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { Utils } from "./utils/Utils"

function render() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={ResourceAssistance.Path.notFoundPage} component={NotFoundPage} />
				<Route path={ResourceAssistance.Path.Report.report}>
					<Route component={ReportRedirect} />
				</Route>
				<Route path={ResourceAssistance.Path.root} component={App} />
			</Switch>
		</BrowserRouter>
	)
}

ReactDOM.render(
	<Provider store={store}>{Utils.isDevMode() ? <React.StrictMode>{render()}</React.StrictMode> : render()}</Provider>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
