import React, { Fragment } from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgNurse, SvgDoctor } from "~/icons"
import { onModalDisplayAction, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_HOSPITEL_SELECTED_NURSE_NOTE,
	SET_HOSPITEL_SELECTED_NURSE_ORDER,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { ImPrinter } from "react-icons/im"
import { PrintableDataFactory } from "~/utils/factory/PrintableDataFactory"
import _ from "lodash"
import IPDPrintableDataFactory from "~/utils/factory/IPDPrintableDataFactory"

class HospitelRightActionRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelRightActionRow",
		}

		this.onDoctorOrder = this.onDoctorOrder.bind(this)
		this.onNurseRecord = this.onNurseRecord.bind(this)
		this.onPrintDoctorOrder = this.onPrintDoctorOrder.bind(this)
		this.onPrintNurseNote = this.onPrintNurseNote.bind(this)
		this.onPrintMedicationSheet = this.onPrintMedicationSheet.bind(this)
		this.onPrintVitalSign = this.onPrintVitalSign.bind(this)
		this.onPrintMedicalCertificate = this.onPrintMedicalCertificate.bind(this)
		this.onPrintMedicalCertificateEng = this.onPrintMedicalCertificateEng.bind(this)
		this.onGlasgowComaScale = this.onGlasgowComaScale.bind(this)
		this.onPupilSize = this.onPupilSize.bind(this)
		this.onPainScale = this.onPainScale.bind(this)
		this.onPrintIntakeOutput = this.onPrintIntakeOutput.bind(this)
	}

	onDoctorOrder(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY, true)
	}

	onNurseRecord(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, true)
		this.props.setSelected(SET_HOSPITEL_SELECTED_NURSE_NOTE, -1, null)
		this.props.setSelected(SET_HOSPITEL_SELECTED_NURSE_ORDER, -1, null)
	}

	onPrintDoctorOrder() {
		let patient = this.props.hospitel.selectedPatient.patient
		let soaps = this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders
			.filter(
				(order) =>
					order.endDateTime >= this.props.hospitel.orderEndDateTime &&
					order.startDateTime <= this.props.hospitel.orderEndDateTime &&
					(!_.isEmpty(order.subjective) ||
						!_.isEmpty(order.objective) ||
						!_.isEmpty(order.assessment) ||
						!_.isEmpty(order.plan))
			)
			.map((soap) => {
				return {
					...soap,
					time: Utils.formatTime(soap.startDateTime),
				}
			})
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let orders = this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders
			.filter(
				(order) =>
					order.endDateTime >=
						Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59).getTime() &&
					order.startDateTime <= this.props.hospitel.orderEndDateTime &&
					_.isEmpty(order.subjective) &&
					_.isEmpty(order.objective) &&
					_.isEmpty(order.assessment) &&
					_.isEmpty(order.plan)
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
			.reduce((orderArray, curOrder) => {
				if (curOrder.duration > 0 && !curOrder.prn) {
					let schedules = []
					for (let i = curOrder.startDateTime; i <= curOrder.endDateTime; i = i + curOrder.duration * 60000) {
						if (
							i <= this.props.hospitel.orderEndDateTime &&
							i >= Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59)
						) {
							schedules.push({
								order: curOrder.description + " " + curOrder.notes,
								scheduled: Utils.formatTime(i),
								cancelledDate:
									curOrder.cancelledDateTime && this.props.hospitel.orderEndDateTime >= curOrder.cancelledDateTime
										? Utils.formatDateTime(curOrder.cancelledDateTime)
										: "",
								cancelledBy:
									curOrder.cancelledDateTime && this.props.hospitel.orderEndDateTime >= curOrder.cancelledDateTime
										? curOrder.lastModifiedBy.displayName
										: "",
							})
						}
					}
					orderArray = orderArray.concat(schedules)
				} else {
					orderArray.push({
						order: curOrder.description + " " + curOrder.notes,
						cancelledDate:
							curOrder.cancelledDateTime && this.props.hospitel.orderEndDateTime >= curOrder.cancelledDateTime
								? Utils.formatDateTime(curOrder.cancelledDateTime)
								: "",
						cancelledBy:
							curOrder.cancelledDateTime && this.props.hospitel.orderEndDateTime >= curOrder.cancelledDateTime
								? curOrder.lastModifiedBy.displayName
								: "",
					})
				}
				return orderArray
			}, [])

		let data = {
			titleIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.doctorStandingOrder }),
			timeIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.time }),
			arrowIcon: ResourceAssistance.Symbol.arrowIcon,

			signatureTitles: [
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.doctor }),
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.nurse }),
			],
			signatureDate: Utils.formatDateTime(new Date().getTime()),

			date: Utils.formatDate(this.props.hospitel.orderEndDateTime),
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			pid: patient.id,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn,

			soaps: soaps,
			orders: orders,
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.doctorOrder, "_blank")
		myWindow.data = data
	}

	onPrintNurseNote() {
		let patient = this.props.hospitel.selectedPatient.patient

		let nurseNotes = this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.nurseNotes
			.filter(
				(nurseNote) =>
					nurseNote.dateTime >=
						Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59) &&
					nurseNote.dateTime <= this.props.hospitel.orderEndDateTime
			)
			.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
			.map((nurseNote) => {
				return {
					time: Utils.formatTime(nurseNote.dateTime),
					focus: nurseNote.focus,
					assessment: _.isEqual(nurseNote.assessment, ResourceAssistance.CONSTANT.OTHER)
						? nurseNote.assessmentNote
						: nurseNote.assessment,
					intervention: nurseNote.intervention,
					evaluation: nurseNote.evaluation,
					signatureDate: Utils.formatDate(nurseNote.dateTime),
				}
			})

		let data = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Hospitel.nurseProgressNote,
			}),

			signatureDate: Utils.formatDateTime(new Date().getTime()),

			date: Utils.formatDate(this.props.hospitel.orderEndDateTime),
			day: Utils.calculateDaysBetween(
				this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
				this.props.hospitel.orderEndDateTime
			),

			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			id: patient.identificationNumber,
			pid: patient.id,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn,

			nurseNotes: nurseNotes,
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.nurseNote, "_blank")
		myWindow.data = data
	}

	onPrintMedicationSheet() {
		let patient = this.props.hospitel.selectedPatient.patient
		let medications = this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders
			.filter(
				(order) =>
					order.endDateTime >=
						Utils.generateDateFromLong(this.props.hospitel.orderEndDateTime, 0, 0, 0, -23, -59, -59).getTime() &&
					order.startDateTime <= this.props.hospitel.orderEndDateTime &&
					_.isEmpty(order.subjective) &&
					_.isEmpty(order.objective) &&
					_.isEmpty(order.assessment) &&
					_.isEmpty(order.plan) &&
					_.isEmpty(order.cancelledDateTime) &&
					!_.isEmpty(order.code)
			)
			.sort((a, b) => Utils.sort(a.description, b.description))
			.map((medication) => ({
				description: medication.description + " " + medication.notes,
			}))

		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.medicationSheet, "_blank")
		myWindow.data = PrintableDataFactory.generateMedicationSheetReportData(
			patient,
			medications,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.room,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintVitalSign() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.vitalSign, "_blank")
		myWindow.data = PrintableDataFactory.generateVitalSignReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.vitalSigns,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintIntakeOutput() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.intakeOutput, "_blank")
		myWindow.data = PrintableDataFactory.generateIntakeOutputReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onGlasgowComaScale() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.glasgowComaScale, "_blank")
		myWindow.data = PrintableDataFactory.generateGlasgowComaScaleReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.glasgowComaScales,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPupilSize() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.pupilSize, "_blank")
		myWindow.data = PrintableDataFactory.generatePupilSizeReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.pupilSizes,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPainScale() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.painScale, "_blank")
		myWindow.data = PrintableDataFactory.generatePainScaleReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.painScales,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.orderEndDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintMedicalCertificate() {
		let data = IPDPrintableDataFactory.generateMedicalCertificateReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedCertificate.certificate
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.opd.medicalCertificate, "_blank")
		myWindow.data = data
	}

	onPrintMedicalCertificateEng() {
		let patient = Object.assign({}, this.props.hospitel.selectedPatient.patient, {
			firstName: this.props.hospitel.selectedPatient.patient.firstNameEng,
			lastName: this.props.hospitel.selectedPatient.patient.lastNameEng,
		})
		let certificate = Object.assign({}, this.props.hospitel.selectedCertificate.certificate, {
			doctorName: this.props.hospitel.selectedCertificate.certificate.doctorNameEng,
		})
		let location = {
			name: certificate.location.parent.parent
				? certificate.location.parent.parent.displayNameEng
				: certificate.location.parent.displayNameEng,
			branchName: certificate.location.parent.parent
				? certificate.location.parent.displayNameEng
				: certificate.location.displayNameEng,
			address: certificate.location.parent.parent
				? certificate.location.parent.address.displayNameEng
				: certificate.location.address.displayNameEng,
		}
		let data = IPDPrintableDataFactory.generateMedicalCertificateReportData(patient, certificate, location)
		let myWindow = window.open(ResourceAssistance.Path.Report.opd.medicalCertificate, "_blank")
		myWindow.data = data
	}

	render() {
		return (
			<Row>
				<Col />
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, this.props.login.user.roles) && (
					<Col md={"auto"}>
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={
								this.props.hospitel.selectedHospitel.index === -1 ||
								this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
							}
							onClick={this.onDoctorOrder}
						>
							<SvgDoctor width={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.doctor)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, this.props.login.user.roles) && (
					<Fragment>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={
									this.props.hospitel.selectedHospitel.index === -1
									//TODO
									// || this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
								}
								onClick={this.onNurseRecord}
							>
								<SvgNurse width={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Hospitel.nurse)}
							</Button>
						</Col>
					</Fragment>
				)}
				<Col md={"auto"}>
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.hospitel.selectedHospitel.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem onClick={this.onPrintDoctorOrder}>
								{translate(ResourceAssistance.Hospitel.doctor)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem onClick={this.onPrintNurseNote}>
								{translate(ResourceAssistance.Hospitel.nurse)}
							</DropdownItem>
							<DropdownItem onClick={this.onPrintMedicationSheet}>
								{translate(ResourceAssistance.Hospitel.medicationSheet)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem onClick={this.onPrintVitalSign}>
								{translate(ResourceAssistance.Hospitel.vitalSign)}
							</DropdownItem>
							<DropdownItem onClick={this.onPrintIntakeOutput}>
								{translate(ResourceAssistance.Message.intakeOutput)}
							</DropdownItem>
							<DropdownItem onClick={this.onGlasgowComaScale}>
								{translate(ResourceAssistance.Message.glasgowComaScale)}
							</DropdownItem>
							<DropdownItem onClick={this.onPainScale}>{translate(ResourceAssistance.Message.painScale)}</DropdownItem>
							<DropdownItem onClick={this.onPupilSize}>{translate(ResourceAssistance.Message.pupilSize)}</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={this.props.hospitel.selectedCertificate.index === -1}
								onClick={this.onPrintMedicalCertificate}
							>
								{translate(ResourceAssistance.Hospitel.medicalCertificate)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.hospitel.selectedCertificate.index === -1}
								onClick={this.onPrintMedicalCertificateEng}
							>
								{translate(ResourceAssistance.Message.medicalCertificateEng)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightActionRow))
