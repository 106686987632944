import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setTfRequestItems, setTfSelectedRequestItem } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFRightSecondRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onTooltipEntering = this.onTooltipEntering.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.transfer.selectedRequest) !== JSON.stringify(this.props.transfer.selectedRequest)) {
			this.props.setTfSelectedRequestItem(-1, null)
			if (this.props.transfer.selectedRequest.request) {
				this.props.setTfRequestItems(this.props.transfer.selectedRequest.request.items)
			} else {
				this.props.setTfRequestItems([])
			}
		}
	}

	onSelectRow(row, index) {
		let item = this.props.transfer.requestItemTable.filtered[index]
		this.props.setTfSelectedRequestItem(index, item)
	}

	onTooltipEntering(event, rKey, cKey) {
		let transferredIdx = 6
		if (cKey === transferredIdx) {
			event.children[1].style.backgroundColor = ResourceAssistance.CSS.Color.dark_red
		}
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pendingItems)}</legend>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							enableOverlay
							onTooltipEntering={this.onTooltipEntering}
							enableHighlight
							striped
							hover
							data={this.props.transfer.requestItemTable}
							isClearHighlight={this.props.transfer.selectedRequestItem.index === -1}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfRequestItems, setTfSelectedRequestItem }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRightSecondRow)
