import { LOCALES } from "~/i18n"
import { LANGUAGE_CHANGE } from "../type"

const initial = {
	locale: LOCALES.THAI,
}

export const languageReducer = (state = initial, action) => {
	switch (action.type) {
		case LANGUAGE_CHANGE:
			return (state.locale = action.payload.locale)

		default:
			return state
	}
}
