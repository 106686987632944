import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_OPD_CERTIFICATES,
	SET_OPD_DOCTOR_ORDERS,
	SET_OPD_GCS,
	SET_OPD_INTAKE_OUTPUTS,
	SET_OPD_MEDICAL_RECORDS,
	SET_OPD_MODAL_APPOINTMENT_PATIENTS,
	SET_OPD_MODAL_DOCTOR_ORDER_ITEMS,
	SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
	SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
	SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES,
	SET_OPD_MODAL_NURSE_ORDER_ITEMS,
	SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS,
	SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES,
	SET_OPD_MODAL_NURSE_SERVICE_SERVICES,
	SET_OPD_NURSE_NOTES,
	SET_OPD_NURSE_ORDERS,
	SET_OPD_PAIN_SCALE,
	SET_OPD_PATIENTS,
	SET_OPD_PUPIL_SIZE,
	SET_OPD_VITAL_SIGNS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setOPDModalAppointmentPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) =>
			Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber())
		)

		let body = filtered.map((patient) => {
			return [
				patient.id,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
				patient.identificationNumber,
			]
		})

		dispatch({
			type: SET_OPD_MODAL_APPOINTMENT_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients
			.filter(
				(patient) =>
					String(patient.id).includes(getState().hospital.opd.searchPID.trim().toLowerCase()) &&
					String(patient.identificationNumber).includes(
						getState().hospital.opd.searchIdentification.trim().toLowerCase()
					)
			)
			.sort((a, b) => Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber()))

		let body = filtered.map((patient, index) => {
			return [
				index + 1,
				patient.id,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
				patient.identificationNumber,
			]
		})

		dispatch({
			type: SET_OPD_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDMedicalRecords = (admissions) => {
	return (dispatch, getState) => {
		let medicalRecords = admissions.reduce((medicalRecords, cur) => {
			if (cur.clinicalRecord) {
				medicalRecords = medicalRecords.concat(cur.clinicalRecord.medicalRecords)
			}
			return medicalRecords
		}, [])

		let filtered = medicalRecords.sort((a, b) => Utils.sort(b.appointmentDateTime, a.appointmentDateTime))

		let rowColor = filtered.map((medicalRecord) => {
			let color = ""

			if (medicalRecord.department.id !== getState().hospital.opd.location.id && medicalRecord.checkOutDateTime === 0) {
				color = ResourceAssistance.CSS.Color.red
			} else if (medicalRecord.checkOutDateTime > 0) {
				color = ResourceAssistance.CSS.Color.green
			} else if (
				_.isEmpty(medicalRecord.ga) ||
				_.isEmpty(medicalRecord.cc) ||
				_.isEmpty(medicalRecord.pi) ||
				_.isEmpty(medicalRecord.diagnosis) ||
				_.isEmpty(medicalRecord.treatment)
			) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((medicalRecord) => {
			return [
				Utils.formatDateTime(medicalRecord.appointmentDateTime),
				Utils.formatDateTime(medicalRecord.checkInDateTime),
				Utils.formatDateTime(medicalRecord.checkOutDateTime),
				medicalRecord.department.displayName,
			]
		})

		dispatch({
			type: SET_OPD_MEDICAL_RECORDS,
			payload: {
				original: admissions,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDDoctorOrders = (doctorOrders) => {
	return (dispatch, getState) => {
		let filtered = doctorOrders.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = []

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.description,
				Utils.trim(order.notes),
				_.isEmpty(order.code) ? Utils.formatDate(order.startDateTime) : Utils.formatDateTime(order.startDateTime),
				_.isEmpty(order.code) ? Utils.formatDate(order.endDateTime) : Utils.formatDateTime(order.endDateTime),
				order.lastModifiedBy.displayName,
			]
		})

		dispatch({
			type: SET_OPD_DOCTOR_ORDERS,
			payload: {
				original: doctorOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDNurseOrders = (nurseOrders) => {
	return (dispatch, getState) => {
		let filtered = nurseOrders.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = []

		let body = filtered.map((order) => {
			return [
				Utils.trim(order.description),
				order.notes,
				Utils.formatNumWithComma(order.qtyPerDay),
				Utils.formatDate(order.startDateTime),
				Utils.formatDate(order.endDateTime),
				order.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_OPD_NURSE_ORDERS,
			payload: {
				original: nurseOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDNurseNotes = (nurseNotes) => {
	return (dispatch, getState) => {
		let filtered = nurseNotes.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((nurseNote) => {
			return [
				Utils.formatDate(nurseNote.dateTime),
				Utils.formatTime(nurseNote.dateTime),
				nurseNote.focus,
				_.isEmpty(nurseNote.assessmentNote) ? nurseNote.assessment : nurseNote.assessmentNote,
				nurseNote.intervention,
				nurseNote.evaluation,
				nurseNote.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_OPD_NURSE_NOTES,
			payload: {
				original: nurseNotes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDVitalSigns = (vitalSigns) => {
	return (dispatch, getState) => {
		let filtered = vitalSigns.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((vitalSign) => {
			return [
				Utils.formatDate(vitalSign.dateTime),
				Utils.formatTime(vitalSign.dateTime),
				vitalSign.temperature,
				vitalSign.pr,
				vitalSign.rr,
				vitalSign.bp,
				vitalSign.oxygenSat,
				vitalSign.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_OPD_VITAL_SIGNS,
			payload: {
				original: vitalSigns,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDIntakeOutputs = (intakeOutputs) => {
	return (dispatch, getState) => {
		let filtered = intakeOutputs.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((IO) => {
			return [
				Utils.formatDate(IO.dateTime),
				Utils.formatTime(IO.dateTime),
				IO.intake,
				IO.intakeAmount,
				IO.intakeNote,
				IO.output,
				IO.outputAmount,
				IO.outputNote,
				IO.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_OPD_INTAKE_OUTPUTS,
			payload: {
				original: intakeOutputs,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDGlasgowComaScales = (gcScales) => {
	return (dispatch, getState) => {
		let filtered = gcScales.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((gcs) => {
			return [
				Utils.formatDate(gcs.dateTime),
				Utils.formatTime(gcs.dateTime),
				gcs.eyeOpening,
				gcs.verbal,
				gcs.motor,
				Utils.BigNumber(gcs.eyeOpening).plus(gcs.verbal).plus(gcs.motor).toNumber(),
				gcs.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		let rowColor = []
		rowColor = filtered.map((gcs) => {
			let color = ""
			let score = Utils.BigNumber(gcs.eyeOpening).plus(gcs.verbal).plus(gcs.motor)
			if (score.isGreaterThanOrEqualTo(13) && score.isLessThanOrEqualTo(15)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (score.isGreaterThanOrEqualTo(9) && score.isLessThanOrEqualTo(12)) {
				color = ResourceAssistance.CSS.Color.warning
			} else {
				color = ResourceAssistance.CSS.Color.red
			}
			return [true, color]
		})

		dispatch({
			type: SET_OPD_GCS,
			payload: {
				original: gcScales,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDPainScales = (painScales) => {
	return (dispatch, getState) => {
		let filtered = painScales.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((painScale) => {
			return [
				Utils.formatDate(painScale.dateTime),
				Utils.formatTime(painScale.dateTime),
				painScale.score,
				painScale.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		let rowColor = []
		rowColor = filtered.map((painScale) => {
			let color = ""
			let score = Utils.BigNumber(painScale.score)
			if (score.isGreaterThan(7)) {
				color = ResourceAssistance.CSS.Color.red
			} else if (score.isGreaterThan(3)) {
				color = ResourceAssistance.CSS.Color.warning
			} else {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		dispatch({
			type: SET_OPD_PAIN_SCALE,
			payload: {
				original: painScales,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDPupilSizes = (pupilSizes) => {
	return (dispatch, getState) => {
		let filtered = pupilSizes.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		let body = filtered.map((pupilSize) => {
			return [
				Utils.formatDate(pupilSize.dateTime),
				Utils.formatTime(pupilSize.dateTime),
				pupilSize.left,
				pupilSize.leftReactionToLight,
				pupilSize.right,
				pupilSize.rightReactionToLight,
				pupilSize.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 &&
					Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, getState().login.user.roles),
			]
		})

		dispatch({
			type: SET_OPD_PUPIL_SIZE,
			payload: {
				original: pupilSizes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDCertificates = (certificates) => {
	return (dispatch, getState) => {
		let filtered = certificates.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = []

		rowColor = filtered.map((certificate) => {
			let color = ""
			if (certificate.admission && !_.isEmpty(certificate.doctorName)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (certificate.admission) {
				color = ResourceAssistance.CSS.Color.warning
			}

			return [true, color]
		})

		let body = filtered.map((certificate) => {
			return [
				Utils.formatDate(certificate.creationDateTime),
				Utils.formatTime(certificate.creationDateTime),
				certificate.symptoms,
				certificate.diagnosis,
				certificate.doctorOpinion,
				certificate.doctorName,
				certificate.doctorLicense,
				certificate.lastModifiedBy.displayName,
				getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
					getState().hospital.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0,
			]
		})
		dispatch({
			type: SET_OPD_CERTIFICATES,
			payload: {
				original: certificates,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalDoctorOrderItems = (items) => {
	return (dispatch, getState) => {
		let itemRelps = items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])

		let filtered = itemRelps
			.filter(
				(itemRelp) =>
					getState().hospital.opd.modalDoctorOrder.selectedType.type &&
					getState().hospital.opd.modalDoctorOrder.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospital.opd.modalDoctorOrder.searchDescription.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.opd.modalDoctorOrder.searchDescription).toLowerCase()) ||
						Utils.trim(itemRelp.brand)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.opd.modalDoctorOrder.searchDescription).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let rowColor = filtered.map((each) => {
			let color = ""
			let pharmacyInventory = Utils.findPharmacyInventory(
				each.code,
				getState().hospital.opd.modalDoctorOrder.pharmacyInventories
			)
			if (!pharmacyInventory || pharmacyInventory.totalAmount <= 0) {
				color = ResourceAssistance.CSS.Color.red
			}
			return [true, color]
		})

		let body = filtered.map((each) => {
			return [each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_OPD_MODAL_DOCTOR_ORDER_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalNurseOrderItems = (items) => {
	return (dispatch, getState) => {
		let itemRelps = items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])

		let filtered = itemRelps
			.filter(
				(itemRelp) =>
					getState().hospital.opd.modalNurseOrder.selectedType.type &&
					getState().hospital.opd.modalNurseOrder.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospital.opd.modalNurseOrder.searchName.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.opd.modalNurseOrder.searchName).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_OPD_MODAL_NURSE_ORDER_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalNurseOrderPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items

		let body = filtered.map((each) => {
			return [
				each.displayName,
				each.notes,
				each.unit.displayName,
				Utils.formatNumWithComma(each.qtyPerDay),
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalDoctorOrderPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items

		let body = filtered.map((item) => {
			return [
				item.displayName,
				item.brand,
				Utils.trim(item.notes),
				Utils.formatDateTime(item.startDateTime),
				Utils.formatDateTime(item.endDateTime),
			]
		})

		dispatch({
			type: SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalDoctorServiceServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((service) =>
				service.name
					.trim()
					.toLowerCase()
					.includes(getState().hospital.opd.modalDoctorService.searchName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []
		rowColor = filtered.map((service) => {
			let color = ""

			if (service.role && service.role.id !== getState().login.user.userType.id) {
				color = ResourceAssistance.CSS.Color.red
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalDoctorServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services

		let body = filtered.map((each) => {
			return [
				each.name,
				each.description,
				each.notes,
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalNurseServiceServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((service) =>
				service.name
					.trim()
					.toLowerCase()
					.includes(getState().hospital.opd.modalNurseService.searchName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []
		rowColor = filtered.map((service) => {
			let color = ""

			if (service.role && getState().login.user.userType && service.role.id !== getState().login.user.userType.id) {
				color = ResourceAssistance.CSS.Color.red
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_OPD_MODAL_NURSE_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setOPDModalNurseServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services

		let body = filtered.map((each) => {
			return [
				each.name,
				each.description,
				each.notes,
				Utils.formatDate(each.startDateTime),
				Utils.formatDate(each.endDateTime),
			]
		})

		dispatch({
			type: SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
