import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setLoadingAction } from "~/redux/action"

class Debt extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Debt",
		}

		this.loadPatients = this.loadPatients.bind(this)
	}

	loadPatients() {
		// let params = {
		// 	method: "GET",
		// 	url: ResourceAssistance.Url.registration.getPatients,
		// 	withCredentials: true,
		// 	headers: {
		// 		"content-type": "application/json",
		// 	},
		// 	params: {
		// 		startDateTime: this.props.ipd.startDateTime,
		// 		endDateTime: this.props.ipd.endDateTime,
		// 	},
		// }
		// let callback = (res) => {
		// 	this.props.setIPDPatients(res.data.patients)
		// }
		// let errorHandler = (error) => {
		// 	this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		// }
		// let reqInterceptor = (config) => {
		// 	this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, true)
		// }
		// let resInterceptor = (response) => {
		// 	this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		// }
		// axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				{/* <Row noGutters>
					<Col md="auto">
						<IPDLeft loadPatients={this.loadPatients} />
					</Col>
					<Col>
						<IPDRight />
					</Col>
				</Row> */}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.hospital.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Debt))
