import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setDepositTransactions } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class DepositTransaction extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.deposit.patient, this.props.deposit.patient)) {
			if (this.props.deposit.patient) {
				this.props.setDepositTransactions(this.props.deposit.patient.transactions)
			} else {
				this.props.setDepositTransactions([])
			}
		}
	}

	componentDidMount() {
		this.props.setDepositTransactions(this.props.deposit.patient.transactions)
	}

	componentWillUnmount() {
		this.props.setDepositTransactions([])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.transaction)}</legend>
						<ScrollableTable
							enableHighlight
							enableOverlay
							striped
							hover
							data={this.props.deposit.transactionTable}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setDepositTransactions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositTransaction)
