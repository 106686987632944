import React from "react"
import {
	Badge,
	Button,
	Col,
	Container,
	FormControl,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setRcModalCancelPendingReceives,
	setRcModalCancelWaitingReceives,
	setRcOrders,
	setRcSelectedPending,
	setRcSelectedSupplier,
	setRcSelectedWaiting,
	setSearchText,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_RC_CANCEL_DISPLAY, SET_RC_CANCEL_REASON_DISPLAY } from "~/redux/type/type/MODAL"
import { SET_RC_MODAL_CANCEL_SEARCH_CODE, SET_RC_MODAL_CANCEL_SEARCH_NAME } from "~/redux/type/type/RC"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class RCModalCancel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCModalCancel",
		}

		this.onClose = this.onClose.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onSupplierChange = this.onSupplierChange.bind(this)
		this.onCodeChange = this.onCodeChange.bind(this)
		this.onNameChange = this.onNameChange.bind(this)
		this.onWaitingSelectRow = this.onWaitingSelectRow.bind(this)
		this.onPendingSelectRow = this.onPendingSelectRow.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.modalCancel.searchCode !== this.props.modalCancel.searchCode ||
			prevProps.modalCancel.searchName !== this.props.modalCancel.searchName ||
			JSON.stringify(prevProps.selectedSupplier) !== JSON.stringify(this.props.selectedSupplier)
		) {
			if (this.props.selectedSupplier.supplier) {
				this.props.setRcModalCancelWaitingReceives(this.props.selectedSupplier.supplier.items)
			}
		}

		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				let selectedIndex = document.getElementById(ResourceAssistance.ID.RC.modalCancelSupplier).value
				this.props.setRcSelectedSupplier(selectedIndex, this.props.selectedRc.rc.suppliers[selectedIndex])
			} else {
				this.props.setRcModalCancelPendingReceives([])
			}
		}

		if (JSON.stringify(prevProps.modalCancelPendingReceive) !== JSON.stringify(this.props.modalCancelPendingReceive)) {
			this.props.setRcSelectedPending(-1, null)
		}
	}

	reset() {
		this.props.setSearchText(SET_RC_MODAL_CANCEL_SEARCH_CODE, "")
		this.props.setSearchText(SET_RC_MODAL_CANCEL_SEARCH_NAME, "")
		this.props.setRcSelectedSupplier(-1, null)
		this.props.setRcSelectedWaiting(-1, null)

		this.props.onModalDisplayAction(SET_RC_CANCEL_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	onCancel() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.rc.cancelReceiveItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				id: this.props.selectedRc.rc.id,
				receiveItems: this.props.modalCancelPendingReceive.filtered.map((item) => {
					return {
						prItemId: item.id,
						amount: this.props.selectedRc.rc.receivedItems
							.reduce((total, received) => {
								if (received.code === item.code) {
									return total.minus(received.amount)
								}
								return total
							}, Utils.BigNumber(item.amount))
							.toNumber(),
						bonus: item.bonus,
						reason: item.reason,
					}
				}),
			},
		}

		let callback = (res) => {
			let original = this.props.order.original
			res.data.receives.forEach((receive) => {
				let index = original.findIndex((original) => original.id === receive.id)
				original[index] = receive
			})
			this.props.setRcOrders(original)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onSupplierChange(event) {
		this.props.setRcSelectedSupplier(event.target.value, this.props.selectedRc.rc.suppliers[event.target.value])
	}

	onCodeChange(event) {
		this.props.setSearchText(SET_RC_MODAL_CANCEL_SEARCH_CODE, event.target.value)
	}

	onNameChange(event) {
		this.props.setSearchText(SET_RC_MODAL_CANCEL_SEARCH_NAME, event.target.value)
	}

	onWaitingSelectRow(row, index) {
		this.props.setRcSelectedWaiting(index, this.props.modalCancelWaitingReceive.filtered[index])
	}

	onPendingSelectRow(row, index) {
		this.props.setRcSelectedPending(index, this.props.modalCancelPendingReceive.filtered[index])
	}

	onDownBtn(event) {
		if (this.props.selectedWaiting.index === -1) {
			return
		}
		this.props.onModalDisplayAction(SET_RC_CANCEL_REASON_DISPLAY, true)
	}

	onUpBtn(event) {
		if (this.props.selectedPending.index === -1) {
			return
		}
		let pendings = this.props.modalCancelPendingReceive.filtered
		pendings.splice(this.props.selectedPending.index, 1)
		this.props.setRcModalCancelPendingReceives(pendings)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.lg}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
					<ModalTitle>
						<Badge>{translate(ResourceAssistance.Message.cancelNotReceived)}</Badge>
					</ModalTitle>
				</ModalHeader>

				<ModalBody className={ResourceAssistance.CSS.fullFlex}>
					<Container fluid className="full-flex">
						<Row>
							<Col md="auto">
								<FormControl
									id={ResourceAssistance.ID.RC.modalCancelSupplier}
									as={ResourceAssistance.FormControl.as.select}
									size={ResourceAssistance.FormControl.size.sm}
									onChange={this.onSupplierChange}
								>
									{this.props.selectedRc.rc ? (
										Utils.renderOptions(this.props.selectedRc.rc.suppliers, false)
									) : (
										<option></option>
									)}
								</FormControl>
							</Col>
							<Col>
								<SearchBox
									size={ResourceAssistance.Button.size.sm}
									num={2}
									controlType={ResourceAssistance.FormControl.type.text}
									callbacks={[this.onCodeChange, this.onNameChange]}
									placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
								/>
							</Col>
						</Row>
						<Row className={ResourceAssistance.CSS.fullFlex}>
							<Col id={ResourceAssistance.ID.RC.modalCancel.tableReceive}>
								<ScrollableTable
									enableHighlight
									striped
									bordered
									hover
									isClearHighlight={this.props.selectedWaiting.index === -1}
									data={this.props.modalCancelWaitingReceive}
									onClick={this.onWaitingSelectRow}
								/>
							</Col>
						</Row>
						<Row>
							<Col className={this.props.selectedWaiting.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
								<FcDownload
									size={"3rem"}
									style={{ alignSelf: ResourceAssistance.CSS.center }}
									onClick={this.onDownBtn}
								/>
							</Col>
							<Col className={this.props.selectedPending.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
								<FcUpload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onUpBtn} />
							</Col>
						</Row>
						<Row
							noGutters
							className={ResourceAssistance.mulCSS(
								ResourceAssistance.CSS.fullSize,
								ResourceAssistance.CSS.labelInfoRow
							)}
						>
							<Col id={ResourceAssistance.ID.RC.modalCancel.tableCancelled}>
								<ScrollableTable
									enableHighlight
									striped
									bordered
									hover
									isClearHighlight={this.props.selectedPending.index === -1}
									data={this.props.modalCancelPendingReceive}
									onClick={this.onPendingSelectRow}
								/>
							</Col>
							<Col md="auto">
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									disabled
									value={this.props.selectedPending.inventory ? this.props.selectedPending.inventory.reason : ""}
								></FormControl>
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onCancel}>
						{translate(ResourceAssistance.Message.cancel)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRCCancelDisplay,
	login: state.login,
	order: state.receive.order,
	modalCancelWaitingReceive: state.receive.modalCancelWaitingReceive,
	modalCancelPendingReceive: state.receive.modalCancelPendingReceive,
	selectedRc: state.receive.selectedRc,
	selectedWaiting: state.receive.selectedWaiting,
	selectedPending: state.receive.selectedPending,
	selectedSupplier: state.receive.selectedSupplier,
	modalCancel: state.receive.modalCancel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setRcModalCancelWaitingReceives,
			setSearchText,
			setRcSelectedSupplier,
			setRcSelectedWaiting,
			setRcModalCancelPendingReceives,
			setRcSelectedPending,
			setRcOrders,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCModalCancel)
