import { LOCALES } from "../locale"

const th = {
	[LOCALES.THAI]: {
		// Warning
		appointmentDelete: "ลบการนัดหมายแผนกผู้ป่วยนอก",
		forAnyComplications:
			"กรณีมีภาวะแทรกซ้อนหรือเปลี่ยนแปลงการวินิจฉัยโรคจะต้องมีการประเมินค่าใช้จ่ายใหม่ โดยประเมินค่าใช้จ่ายตามความเป็นจริง",
		inCaseRefundPatient:
			"ในกรณีที่ผู้ป่วยไม่สามารถทำการผ่าตัดได้โรงพยาบาลจะคืนเงินมัดจำโดยการโอนเงินผ่านบัญชีธนาคาร ภายใน 7 วันทำการ",
		opdCheckOut: "เวลาสิ้นสุดการปรึกษาแพทย์",
		wrongAmount: "จำนวนที่สั่งต้องเป็นทวีคูณของหน่วยใหญ่",
		wrongTotalPrice: "ราคารวมคำนวณไม่ถูกต้อง",
		wrongTransferAmount: "จำนวนที่โอนต้องน้อยกว่าหรือเท่ากับจำนวนปัจจุบัน",
		wrongTransferAmountGreaterThanRequested: "ไม่สามารถโอนมากกว่าจำนวนที่ขอ",
		wrongWithdrawAmount: "จำนวนเงินที่ถอนผิด",
		pharmacyCannotMeetDoctorOrder: "คำสั่งซื้อหมดสต็อก",
		theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling:
			"ใบสั่งแพทย์เป็นใบสั่งยาเท่านั้นและจะไม่รวมอยู่ในการเรียกเก็บเงิน",
		warningCreateBillingInvoice: "สิ่งนี้จะสร้างใบแจ้งหนี้",
		warningCreateBillingReceipt: "นี้จะสร้างใบเสร็จรับเงิน",
		warningCreateBillingReceiptAndInvoice: "นี้จะสร้างใบเสร็จรับเงินและใบแจ้งหนี้",
		warningDischargePatient: "การปล่อยผู้ป่วย",

		//Remark
		remarkReactionToLight: "***เร็ว +, ไม่มีปฏิกิริยา -, ช้า s, ปิดตา c",
		remarkPrimaryHealthCarePlan: "แผนประกันสุขภาพที่ได้รับการชำระเงินโดยตรงจากผู้ป่วยด้วยเงินสด บัตรเครดิต ฯลฯ",

		//Message
		"": "",
		ICD: "ICD",
		LMP: "LMP",
		accidentHistory: "ประวัติอุบัติเหตุ",
		active: "ใช้งานอยู่",
		adHocOrder: "Ad Hoc Order",
		add: "เพิ่ม",
		address: "ที่อยู่",
		addressEng: "ที่อยู่ (Eng)",
		adjustments: "การปรับ",
		admin: "ผู้จัดการระบบ",
		admission: "การรักษาในโรงพยาบาล",
		admissionDate: "วันที่เข้ารับการรักษา",
		admissionPlan: "เข้านอนในโรงพยาบาล",
		age: "อายุ",
		aie: "AIE",
		alcohol: "แอลกอฮอล์",
		allergies: "แพ้ยา/อาหาร",
		amount: "จำนวน",
		amountOfPackage: "จำนวนรับหน่วยใหญ่",
		amountPerOrder: "Amount/Order",
		analyte: "การตรวจวิเคราะห์",
		antigen: "Antigen",
		antigenCOI: "Antigen (COI)",
		appointment: "นัดใหม่",
		appointmentDate: "วันที่นัดหมาย",
		appointmentTime: "เวลานัดหมาย",
		approve: "อนุมัติ",
		approved: "อนุมัติ",
		approvedBy: "อนุมัติโดย",
		approvedByAnotherOrderWarning: "รายการนี้มีการสั่งซื้อไปแล้ว ยังไม่ได้รับสินค้า",
		approvedInventories: "รายการที่อนุมัติ",
		approver: "ผู้อนุมัติ",
		approverCheck: "A",
		arrival: "มาโดย",
		assessment: "Assessment",
		attachment: "เอกสารแนบ",
		balance: "ยอดเงิน",
		bill: "ใบเสร็จ/ใบแจ้งหนี้",
		billByCategory: "ใบเสร็จ/ใบแจ้งหนี้ (หมวดหมู่)",
		billByHealthCarePlan: "ใบเสร็จ/ใบแจ้งหนี้ (แผนดูแลสุขภาพ)",
		billDetails: "รายละเอียดใบเสร็จ/ใบแจ้งหนี้",
		billSummary: "สรุปการเรียกเก็บเงิน/ใบแจ้งหนี้",
		billTo: "สถานที่วางบิล",
		billingAddress: "ที่อยู่เรียกเก็บเงิน",
		billingContact: "ติดต่อเรียกเก็บเงิน",
		billingToTodayByAdmission: "ประมาณการค่ารักษาพยาบาลล่าสุด",
		bloodTest: "ตรวจเลือด",
		bonus: "แถม",
		bpmmHg: "BP. (mmHg)",
		branch: "สาขา",
		brandName: "ชื่อสินค้า",
		brandNameAndCode: "ชื่อสินค้า/รหัส",
		budget: "งบประมาณ",
		by: "โดย",
		byAdmission: "โดยการรับเข้าเรียน",
		byUser: "โดยผู้ใช้",
		cancel: "ยกเลิก",
		cancelNotReceived: "รายการที่กำลังจะยกเลิก",
		cancelled: "ยกเลิก",
		cannotReadSMC: "อ่านไอดีไม่ได้!",
		carAccident: "อุบัตติเหตุทางรถ",
		cashier: "แคชเชียร์",
		category: "หมวดหมู่",
		categoryNum: "หมวดหมู่ #",
		certified: "ตรวจสอบ",
		certifiedBy: "ตรวจสอบโดย",
		certifiesThat: "ขอรับรองว่า",
		change: "ทอนเงินให้กับลูกค้า",
		changeEndDate: "แก้ไขวันที่สิ้นสุด",
		changePassword: "เปลี่ยนรหัสผ่าน",
		checkIn: "ลงทะเบียน",
		checkInDate: "วันที่เริ่มรักษา",
		checkInTime: "เวลาเริ่มรักษา",
		checkOut: "ออกจาก",
		checkOutDate: "วันที่สิ้นสุด",
		checkOutTime: "เวลาสิ้นสุด",
		checkbox: "C",
		checkout: "คิดค่าใช้จ่าย",
		chiefComplaint: "CC.",
		chromatography: "Chromatography",
		city: "เมือง",
		cityEng: "เมือง (Eng)",
		claimFile: "เอกสารเรียกร้อง",
		clear: "ลบออก",
		clinicalRecord: "บันทึกการรักษา",
		close: "ปิด",
		code: "รหัส",
		companyOrResponsiblePersonContact: "ชื่อบริษัท/ผู้รับผิดชอบ และเบอร์ติดต่อ",
		complete: "เสร็จสิ้น",
		confirm: "ยืนยัน",
		confirmPassword: "ยืนยันรหัสผ่าน",
		contact: "ติดต่อ",
		contactInfo: "ข้อมูลการติดต่อ",
		continue: "ดำเนินการต่อ",
		coordinator: "ผู้ประสานงาน",
		costPerOrder: "ราคาทุนต่อหน่วยใหญ่ ก่อนVAT",
		covid19Lab: "Covid-19 แล็บ",
		covid19Negative: "ผลการตรวจไม่พบเชื้อ Covid-19",
		covid19Positive: "ผลการตรวจพบเชื้อ Covid-19",
		createNewPR: "เพิ่มสินค้าในใบขอซื้อ ",
		currencySign: "\u0E3F",
		current: "จำนวนคงคลัง",
		currentBalance: "ยอดเงินปัจจุบัน",
		currentLevel: "จำนวนปัจจุบัน",
		date: "วันที่",
		dateOfBirth: "วันเกิด",
		dateOfPo: "วันที่สั่งซื้อ",
		day: "วัน",
		days: "วัน",
		daysOfRest: "วัน",
		debt: "หนี้",
		default: "ค่าตั้งต้น",
		delete: "ลบ",
		department: "แผนก",
		deposit: "เงินฝาก",
		depressionAssessment: "แบบประเมินภาวะซึมเศร้า",
		description: "คำอธิบาย",
		deselectAll: "ยกเลิกการเลือกทั้งหมด",
		diagnosis: "การวินิจฉัยโรค",
		discharge: "ให้ผู้ป่วยออก",
		dischargeDate: "วันที่ให้ผู้ป่วยออก",
		discharged: "ผู้ป่วยที่ปลดประจำการ",
		discount: "ลดราคา",
		discountPricePerOrder: "ราคาหลังส่วนลดรวม",
		discountPricePerUnit: "ราคาหลังส่วนลด",
		discountTaxPricePerUnit: "ราคาหลังภาษีและส่วนลด",
		discountWithSymbol: "ส่วนลด %",
		dispensingTime: "เวลาจ่าย",
		displayAll: "แสดงทั้งหมด",
		displayExpire: "แสดงรายการที่หมดอายุ",
		displayInactive: "แสดงรายการที่ไม่ใช้งาน",
		displayMinStock: "แสดงรายการที่ต่ำกว่า",
		doctor: "แพทย์",
		doctorConsultation: "ปรึกษาแพทย์",
		doctorName: "ชื่อแพทย์",
		doctorNote: "หมอหมายเหตุ",
		doctorOpinion: "ความเห็นแพทย์",
		doctorOptionAfter: "ได้รับการรักษา ครบถ้วน 14 วันแล้ว สามารถไปทำงาน และ ใช้ชีวิตได้ตามปกติ",
		doctorStandingOrder: "คำสั่งแพทย์",
		documentNum: "หมายเลขเอกสาร",
		done: "เสร็จสิ้น",
		dose: "ขนาดยา",
		doubleClickToDelete: "ดับเบิ้ลคลิ๊กเพื่อลบไอเท็ม",
		download: "Download",
		drinking: "ดื่มสุรา",
		driver: "ผู้ขับขี่",
		dueDate: "วันเสร็จสิ้น",
		dupplicateCode: "มีรหัสนี้แล้ว!",
		dupplicateCodeWarning: "รหัสนี้มีอยู่แล้ว!",
		dupplicateName: "มีชื่อนี้แล้ว!",
		dupplicateNameWarning: "Name exist already!",
		dupplicateWarning: "มีในระบบแล้ว!",
		duration: "ระยะเวลา",
		eGene: "E gene:CT",
		edit: "แก้ไข",
		email: "อีเมลล์",
		empty: "",
		emptyCancelReason: "ต้องใส่เหตุผลในการปฏิเสธ",
		emptyDisapproveReasonWarning: "ไม่มีเหตุผลในการยกเลิก! เหตุผลในการยกเลิกไม่สามารถเปลี่ยนแปลงหลังจากการอนุมัติ",
		emptyNoteWarning: "กล่องข้อความว่างเปล่า! ข้อความไม่สามารถเปลี่ยนแปลงได้หลังการอนุมัติ",
		emptyWarning: "ว่างเปล่าไม่ได้",
		endDate: "วันที่สิ้นสุด",
		endDateTime: "วันที่สิ้นสุด",
		estimatedDateOfConfinement: "วันที่จัดส่งโดยประมาณ (EDC)",
		evaluation: "Evaluation",
		every: "ทุก",
		examinationDate: "วันที่รับการรักษา",
		expected: "วันที่กำหนด",
		expenseEstimation: "ประมาณการค่าใช้จ่าย",
		expire: "หมดอายุ",
		expired: "หมดอายุ",
		expiredDate: "วันหมดอายุ",
		fallRisk: "เสี่ยงล้ม",
		familyHealthHistory: "ประวัติสุขภาพครอบครัว",
		fax: "เบอร์แฟกซ์",
		faxNo: "เบอร์แฟกซ์",
		filter: "ตัวกรอง",
		financialPlan: "แผนการเงิน",
		firstAssessment: "แบบประเมินแรกรับ",
		firstName: "ชื่อจริง",
		firstNameEng: "ชื่อจริง (Eng)",
		fluorescence: "Fluorescence",
		focus: "Focus",
		form: "รูปแบบยา",
		from: "จาก",
		fsCode: "F/S Code",
		gender: "เพศ",
		general: "ทั่วไป",
		generalAppearance: "ลักษณะทั่วไป (GA)",
		generalInfo: "ข้อมูลทั่วไป",
		generic: "ทั่วไป",
		genericName: "ชื่อสามัญ",
		genericNameOrTradeName: "ชื่อสามัญ/ชื่อการค้า",
		googleDrive: "G-Drive",
		grandTotal: "ราคารวมสุทธิ",
		graph: "กราฟ",
		hasExaminedOn: "ได้รับการตรวจ/รักษาเมื่อ",
		hashSymbol: "\u00A0#",
		health: "สุขภาพ",
		healthCare: "สิทธิ",
		healthCarePlan: "แผนดูแลสุขภาพ",
		healthHistory: "ประวัติสุขภาพ",
		height: "ส่วนสูง",
		hello: "สวัสดี",
		hn: "HN",
		home: "หน้าแรก",
		hospital: "โรงพยาบาล",
		hospitalBilling: "การเรียกเก็บเงินของโรงพยาบาล",
		hospitel: "Hospitel",
		hours: "ชั่วโมง",
		id: "เลขที่",
		idCard: "บัตรประชาชน",
		identification: "บัตรประชาชน/พาสพอร์ต",
		inPatient: "ผู้ป่วยใน",
		inProgress: "กำลังดำเนินการ",
		inpatientPlan: "แผนผู้ป่วยใน",
		inspector: "ผู้ตรวจสอบ",
		inspectorCheck: "I",
		instruction: "คำแนะนำ",
		internationalClassificationDiseases: "การจัดจำแนกระหว่างประเทศของโรค (ICD)",
		intervention: "Intervention",
		inventory: "สินค้าคงคลัง",
		inventoryByLocation: "รายงานใบคลังสินค้าตามสถานที่",
		investigation: "การตรวจพิเศษ",
		invoiceId: "รหัสใบแจ้งหนี้",
		invoiceNum: "เลขที่ใบแจ้งหนี้ #",
		ipd: "IPD",
		isolationPrecaution: "ข้อควรระวังการแยกตัว",
		issued: "จัดทำ",
		issuedBy: "จัดทำโดย",
		item: "ไอเท็ม",
		itemSupplier: "ชื่อสินค้า",
		itemType: "ชนิดของไอเท็ม",
		keyword: "คำสำคัญ",
		lab: "ห้องทดลอง",
		labNumber: "เลขที่แลบ",
		label: "ป้ายกำกับ",
		language: "ต้องการล่ามภาษา",
		lastMenstrualPeriod: "ประจำเดือนครั้งสุดท้าย (LMP)",
		lastName: "นามสกุล",
		lastNameEng: "นามสกุล (Eng)",
		lastPurchasedFrom: "บริษัทที่สั่งซื้อล่าสุด",
		lastReceivedDate: "วันที่ซื้อล่าสุด",
		letterOfConsent: "หนังสือแสดงความยินยอมรับการรักษาพยาบาลเป็นผู้ป่วยใน",
		level: "ระดับ",
		licenseNo: "ใบอนุญาตเลขที่",
		licenseNum: "ใบอนุญาต #",
		loadPreviousMedicalRecords: "โหลดเวชระเบียนก่อนหน้า",
		loading: "กำลังดาวโหลด...",
		location: "สถานที่",
		login: "เข้าสู่ระบบ",
		loginError: "ชื่อผู้ใช้ หรือ รหัสผิดพลาด!",
		loginName: "ชื่อผู้ใช้",
		logout: "ออกจากระบบ",
		lot: "เลขที่ผลิต",
		lotNum: "เลขที่ผลิต",
		management: "การจัดการ",
		max: "จำนวนสูงสุด",
		maxLevel: "ปริมาณสูงสุด",
		md: "แพทย์ผู้ตรวจรักษา",
		medicalCertificate: "ใบรับรองแพทย์",
		medicalCertificateEng: "ใบรับรองแพทย์ (Eng)",
		medicalCertificateIPD: "ใบรับรองแพทย์ (IPD)",
		medicalCertificateOPD: "ใบรับรองแพทย์ (OPD)",
		medicalRecord: "เวชระเบียน",
		medicalSupply: "เวชภัณฑ์",
		medicationSheet: "ใบบันทึกการให้ยา",
		medicationUsage: "วิธีใช้ยา",
		medicine: "ยา",
		medicineHistory: "ประวัติยาเสพติด",
		memoId: "รหัสเอกสาร",
		menopause: "วัยหมดประจำเดือน",
		menstruation: "ภาวะมีประจำเดือน",
		min: "จำนวนต่ำสุด",
		minLevel: "ปริมาณต่ำสุด",
		minQtyPerOrder: "จำนวนขั้นต่ำในการสั่งซื้อ",
		minStock: "สินค้าคงเหลือน้อยกว่าจำนวนต่ำสุด",
		minutes: "นาที",
		modifyDateRange: "แก้ไขช่วงวันที่",
		month: "เดือน",
		nGene: "N gene:CT",
		name: "ชื่อ",
		nameEng: "ชื่อ (Eng)",
		nameSurname: "ชื่อ-นามสกุล",
		nameTitle: "คำนำหน้า",
		negative: "Negative",
		new: "ใหม่",
		newForm: "รูปแบบการบริหารยาใหม่",
		newItem: "ไอเท็มใหม่",
		newItemSupplier: "ชื่อสินค้าใหม่",
		newItemWarning: "สินค้าได้รับการอนุมัติแล้ว!",
		newLab: "แลบใหม่",
		newReceive: "รายการที่กำลังจะอนุมัติ",
		newRecord: "สถิติใหม่",
		newRequest: "สร้างใบขอ",
		newRole: "บทบาทใหม่",
		newSupplier: "เพิ่มผู้ขายใหม่",
		newTax: "อัตราภาษีใหม่",
		newTransfer: "สร้างใบโอน",
		newUnit: "หน่วยใหม่",
		newUser: "ผู้ใช้ใหม่",
		nickName: "ชื่อเล่น",
		no: "ไม่ต้องการ",
		note: "หมายเหตุ",
		novelDiseaseInvestigationSheet: " ใบสอบสวนโรค Novel 3",
		number: "เลขที่",
		nurse: "พยาบาล",
		nurseOrder: "คำสั่งพยาบาล",
		nurseProgressNote: "หมายเหตุความคืบหน้าของพยาบาล ",
		o2Sat: "O\u2082 Sat (%)",
		oGene: "ORF1ab gene:CT",
		objective: "Objective",
		oneMonth: "หนึ่งเดือน",
		oneWeek: "หนึ่งอาทิตย์",
		opd: "OPD",
		opdClinicalRecord: "OPD บันทึกการรักษา",
		operationalService: "การบริการตามจริง",
		or: "หรือ",
		order: "คำสั่ง",
		org: "บริษัท",
		other: "อื่นๆ",
		package: "จำนวนย่อยในหน่วยใหญ่",
		paid: "ยอดที่ชำระแล้ว",
		parentLoc: "คลังใหญ่",
		parentType: "",
		passenger: "ผู้โดยสาร/คนซ้อน",
		passiveSmokeExposure: "การเปิดรับควันแบบพาสซีฟ",
		password: "รหัส",
		passwordDoesNotMatch: "รหัสผ่านไม่ตรง!",
		pastIllnesses: "ความเจ็บป่วยในอดีต",
		pastSurgicalHistory: "ประวัติการผ่าตัด",
		patient: "ผู้ป่วย",
		patientId: "PID",
		patientInfo: "ข้อมูลผู้ป่วย",
		patientName: "ชื่อผู้ป่วย",
		patientSaving: "บัญชีออมทรัพย์ผู้ป่วย",
		pay: "จ่ายเงิน",
		payee: "ผู้รับเงิน",
		payment: "การชำระเงิน",
		paymentTerms: "ระยะเวลาการชำระเงิน",
		paymentType: "วิธีการชำระเงิน",
		pcr: "PCR",
		pedestrian: "บุคคลภายนอก",
		pending: "รอ",
		pendingItems: "ไอเท็มที่ขอ",
		pendingOrder: "ใบสั่งซื้อที่ยังรอรับ",
		pendingTransfers: "ไอเท็มที่ยังไม่ได้นำส่ง",
		permission: "การอนุญาต",
		phone: "โทรศัพท์",
		physicalExamination: "การตรวจร่างกาย",
		placeOfIncident: "สถานที่เกิดเหตุ",
		plan: "Plan",
		poDate: "วันที่สั่งซื้อ",
		poId: "รหัสใบสั่งซื้อ",
		poNumber: "เลขที่ใบสั่งซื้อ #",
		policeName: "ร้อยเวรเจ้าของคดี",
		policeStation: "ส.น.",
		positive: "Positive",
		postcode: "รหัสไปรษณีย์",
		prNumber: "เลขที่ใบขอซื้อ #",
		prPerMin: "PR./min",
		pregnant: "ตั้งครรภ์",
		presentIllness: "ความเจ็บป่วยในปัจจุบัน (PI.)",
		pricePerOrder: "ราคาต่อหน่วยซื้อ",
		pricePerOrderBeforeTax: "ราคาต่อหน่วยซื้อ ก่อน VAT",
		pricePerUnit: "ราคา",
		pricePerUnitBeforeTax: "ราคา/หน่วย ก่อน VAT",
		print: "พิมพ์",
		printDate: "พิมพ์วันที่",
		printLabel: "พิมพ์สติกเกอร์",
		prnRecord: "บันทึกการให้ยา PRN",
		professionalServiceExpenseByUser: "สรุปค่าบริการแยกตามบุคลากร",
		province: "จังหวัด",
		provinceEng: "จังหวัด(Eng)",
		purchase: "ซื้อ",
		purchaseFrom: "ซื้อจาก",
		purchaseOrder: "ใบสั่งซื้อ",
		purchaseOrderById: "รายงานใบสั่งซื้อโดยบัตรประจำตัว",
		purchaseOrderByLocation: "รายงานใบสั่งซื้อตามสถานที่",
		purchaseRequest: "ใบขอซื้อ",
		qty: "ปริมาณ",
		qtyPerDay: "จำนวน/วัน",
		questionnaire: "แบบสอบถาม",
		radiology: "ถ่ายภาพรังสี",
		rc: "เลขที่ใบรับสินค้า",
		reason: "เหตุผลที่ถูกปฏิเสธ",
		receipt: "ใบเสร็จ",
		receiptCategory: "ใบเสร็จ (หมวดหมู่)",
		receiptId: "ใบเสร็จรับเงิน",
		receive: "ใบรับสินค้า",
		receiveItemDetails: "รายละเอียดของรายการที่จะอนุมัติ",
		received: "จำนวนที่ได้รับ",
		receivedBy: "ผู้รับสินค้า",
		references: "อ้างอิง",
		refresh: "รีเฟรช",
		registerOPD: "ลงทะเบียน OPD",
		registerPatient: "ลงทะเบียนผู้ป่วย",
		registration: "การลงทะเบียน",
		registrationDate: "วันที่ลงทะเบียน",
		reject: "ปฏิเสธ",
		remark: "หมายเหตุ",
		report: "รายงาน",
		reportById: "รายงานโดยระบุตัวตน",
		reportByLocation: "รายงานแยกตามสถานที่",
		request: "ยืนยันการขอ",
		requestCovid19MedicalCertificate: "ขอใบรับรองแพทย์ตรวจ Covid-19",
		requestFrom: "แผนกที่ขอ",
		requestId: "เลขที่ใบขอโอน",
		requestedInventories: "รายการที่ต้องการ",
		requester: "ผู้ขอซื้อ",
		requesterCheck: "R",
		requiredByDate: "วันที่กำหนดรับสินค้า",
		result: "ผลการทดสอบ",
		riskOfAbuse: "เสี่ยงต่อการถูกทารุณกรรม",
		role: "บทบาท",
		room: "ห้อง",
		rrPerMin: "RR./min",
		run: "เรียกรายงาน",
		sGene: "S gene:CT",
		satCode: "SAT Code",
		save: "บันทึก",
		saveAll: "บันทึกทั้งหมด",
		scheduled: "กำหนดการ",
		search: "ค้นหา",
		searchPatient: "ค้นหาผู้ป่วย",
		selectAll: "เลือกทั้งหมด",
		sell: "ขาย",
		sellingPricePerUnit: "ราคาขาย/หน่วย",
		seq: "SEQ",
		sequence: "ลำดับที่",
		service: "บริการ",
		setAmount: "ใส่จำนวน...",
		shipAddress: "ที่อยู่จัดส่ง",
		shippingContact: "ติดต่อจัดส่งสินค้า",
		signature: "ลงชื่อ",
		skipPayment: "ข้ามการชำระเงิน",
		smartCardReaderIsNotWorking:
			"ID Smart Card Reader is not working! \n Note* (Make sure the SMC Reader app is running.)",
		smoking: "สูบบุหรี่",
		soap: "SOAP",
		startDate: "วันที่เริ่มต้น",
		startDateTime: "วันที่เริ่มต้น",
		status: "สถานะ",
		stock: "คลังสินค้า",
		stockAndPurchasing: "คลังและจัดซื้อ",
		stockManager: "Stock Manager",
		stools: "Stools",
		stop: "หยุด",
		stretcher: "รถนอน",
		subjective: "Subjective",
		subunit: "หน่วยย่อย",
		supplementOrHerbsHistory: "Supplement/Herbs ประวัติ",
		supplier: "ผู้ขาย",
		supplierCode: "รหัสผู้ขาย",
		supplierDiscount: "ส่วนลดจากผู้ขาย",
		supplierName: "ชื่อผู้ขาย",
		symptoms: "เนื่องจากอาการ",
		table: "ตาราง",
		taxId: "เลขที่ผู้เสียภาษี",
		taxRate: "ภาษี",
		taxRateWithSymbol: "อัตราภาษี %",
		taxedTotalPriceDoesNotMatch: "ราคารวมไม่ตรงกับราคาที่คำนวณจากในระบบ",
		tel: "เบอร์โทร",
		telNo: "เบอร์โทร",
		temperature: "T. (C)",
		terms: "ระยะเวลา",
		thai: "ไทย",
		theAboveNamedPersonRequires: "เห็นสมควรให้หยุดพักรักษาตัวเป็นเวลา",
		thisMonth: "เดือนนี้",
		time: "เวลา",
		timeOfIncident: "เวลาที่เกิดเหตุ",
		title: "คำนำหน้า",
		to: "ถึง",
		tobacco: "ยาสูบ",
		today: "วันนี้",
		tomorrow: "พรุ่งนี้",
		totalBudget: "วงเงินรวม",
		totalDeposit: "ยอดเงินฝาก",
		totalEstimationCost: "ค่าใช้จ่ายรวม",
		totalPaid: "ยอดชำระทั้งหมด",
		totalPrice: "ราคารวม",
		totalPriceDiscounted: "ราคารวมหลังส่วนลด",
		totalPriceDiscountedTaxed: "ราคารวมหลังภาษีและส่วนลด",
		totalPriceTaxed: "ราคารวมสุทธิ",
		totalUnpaid: "ยอดค้างชำระทั้งหมด",
		totalWithdraw: "การใช้จ่าย",
		tradeName: "ชื่อการค้า",
		tradeNameOrBrand: "ชื่อการค้า/ยี่ห้อ",
		transaction: "ธุรกรรม",
		transfer: "โอน",
		transferByLocation: "รายงานใบโอนตามสถานที่",
		transferFrom: "สาขาที่โอน",
		transferId: "เลขที่ใบโอน",
		transferingItems: "ไอเท็มที่เตรียมนำส่ง",
		transferred: "นำส่งแล้ว",
		translator: "ความต้องการล่าม",
		treatment: "การรักษา",
		triage: "Triage (ESI Index)",
		twoWeeks: "สองสัปดาห์",
		type: "ประเภท",
		typeOfIncident: "ลักษณะการเกิดเหตุ",
		typesOfDiagnosis: "ประเภทของการวินิจฉัย",
		uid: "UID",
		unapproveReason: "เหตุผลที่ไม่อนุมัติ: ",
		unapproved: "ไม่อนุมัติ",
		underlyingDisease: "โรคประจำตัว",
		unit: "หน่วย",
		unitPrice: "ราคาต่อหน่วย",
		unpaid: "ยอดค้างชำระ",
		uom: "หน่วยวัด",
		upload: "Upload",
		uploaded: "อัพโหลดแล้ว",
		urine: "Urine",
		user: "ผู้ใช้",
		username: "ชื่อผู้ใช้",
		usingContraceptive: "ใช้ยาคุมกำเนิด",
		vaccine: "วัคซีน",
		value: "มูลค่า",
		vat: "ภาษีมูลค่าเพิ่ม",
		victim: "เหยื่อผู้เคราะห์ร้าย",
		view: "มุมมองรายงาน",
		vitalSign: "สัญญาณชีพ",
		walk: "เดินมาเอง",
		warning: "คำเตือน!",
		weight: "น้ำหนัก",
		wheelchair: "รถเข็น",
		withdraw: "ถอน",
		womenOnly: "ผู้หญิงเท่านั้น",
		year: "ปี",
		yes: "ใช่",
		intakeOutput: "Intake/Output",
		intake: "อินพุต",
		output: "เอาต์พุต",
		method: "วิธี",
		volume: "ปริมาตร",
		amountML: "จำนวน (mL)",
		totalIntake: "อินพุตทั้งหมด",
		totalOutput: "เอาต์พุตทั้งหมด",
		ioBalance: "I/O ยอดเงิน",
		pulse: "PR",
		glasgowComaScale: "Glasgow Coma Scale (GCS)",
		eyeOpeningResponse: "การตอบสนองของดวงตา (E)",
		verbalResponse: "การตอบสนองทางวาจา (V)",
		motorResponse: "การตอบสนองของมอเตอร์ (M)",
		noEyeOpening: "ไม่ลืมตา",
		toPressure: "ลืมตาเมื่อได้รับแรงกด",
		toSound: "ลืมตาเมื่อได้ยินเสียง",
		spontaneous: "ลืมตาได้เอง",
		noVerbal: "ไม่ส่งเสียงใดๆ",
		sounds: "ส่งเสียงแต่ไม่เป็นคําพูด",
		words: "พูดเป็นคําๆ",
		confused: "พูดคุยสับสน",
		orientated: "พูดคุยได้ไม่สับสน",
		noMotor: "ไม่ตอบสนองต่อสิ่งกระตุ้น",
		extension: "แขนขาเหยียดเกร็ง",
		abnormalFlexion: "แขนงอเข้าหาตัวผิดปกติ",
		normalFlexion: "หรืองอแขนเมื่อถูกกระตุ้นด้วยแรงกด",
		localising: "ทราบตําแหน่งที่ถูกกระตุ้น/ปัดสิ่งกระตุ้นถูกตําแหน่ง",
		obeysCommands: "เคลื่อนไหวได้ตามคําสั่ง",
		score: "คะแนน",
		painScale: "ระดับความเจ็บปวด",
		pupilSize: "ขนาดของรูม่านตา",
		left: "ด้านซ้าย (L)",
		right: "ด้านขวา (R)",
		notification: "การแจ้งเตือน",
		message: "ข้อความ",
		leftEye: "ตาซ้าย (L)",
		rightEye: "ตาขวา (R)",
		size: "ขนาด",
		leftEyePupilSizeMM: "ขนาดรูม่านตา (mm) (L)",
		rightEyePupilSizeMM: "ขนาดรูม่านตา (mm) (R)",
		reactionToLight: "ปฏิกิริยาต่อแสง",
		leftEyeReactionToLight: "ปฏิกิริยาต่อแสง (L)",
		rightEyeReactionToLight: "ปฏิกิริยาต่อแสง (R)",
		timeline: "เส้นเวลา",
		pharmacy: "ร้านขายยา",
		lastLocation: "ตำแหน่งสุดท้าย",
		heent: "HEENT",
		heart: "Heart",
		lung: "Lung",
		abdomen: "Abdomen",
		back: "Back",
		extremities: "Extremities",
		neuro: "Neuro",
		pharmacistVerified: "เภสัชกรตรวจสอบแล้ว",
		verified: "ได้รับการตรวจสอบแล้ว",
		modified: "เปลี่ยนไปแล้ว",
		dispensed: "ได้ถูกส่งไป",
		return: "คืนสินค้า",
		pharmacyInventory: "สินค้าคงคลังร้านขายยา",
		male: "ชาย",
		female: "หญิง",
		pcrComment: "ข้อสังเกต PCR",
		sarsCov2RnaPcr: "SARS-CoV-2-RNA-PCR",
		xpertXpressSarsCov2: "Xpert-Xpress-SARS-CoV-2",
		n2geneCT: "N2 gene:CT",
		antigenComment: "ข้อสังเกต Antigen",
		dispense: "แจกจ่าย",
		verify: "ตรวจสอบ",
		pharmacist: "เภสัชกร",
		pharmacyDispensed: "ร้านขายยาได้จ่ายยา",
		dispensedBy: "ส่งโดย",
		returned: "จำนวนที่ส่งคืน",
		returning: "พร้อมกลับ",
		doctorPrescriptionOnly: "ใบสั่งแพทย์เท่านั้น",
		cancelDoctorPrescriptionOnly: "ยกเลิกใบสั่งแพทย์เท่านั้น",
		primaryPlan: "แผนหลัก",
		refund: "คืนเงิน",
		doctorPrefix: "Dr.",
		doctorPrescription: "ใบสั่งแพทย์",
		relativeInfo: "ข้อมูลญาติ",
		relationship: "ความสัมพันธ์",
		beforeMeal: "ก่อนอาหาร",
		beforeBed: "ก่อนนอน",
		afterMeal: "หลังอาหาร",
		nurseName: "ชื่อพยาบาล",
	},
}

export default th
