const Url = {
	Url: {
		auth: {
			login: "/auth/login",
			logout: "/auth/logout",
			validateCookie: "/auth/validate",
		},

		pr: {
			getPrs: "/pr/getPrs",
			getTypes: "/getTypes",
			getLocations: "/pr/getLocations",
			savePR: "/savePr",
			updatePR: "/updatePr",
			deletePrItem: "/deletePrItem",
			setOrderItemSelection: "/setOIS",
		},

		po: {
			downloadFile: "/po/downloadFile",
			getPurchaseOrders: "/po/getPurchaseOrders",
			getPaymentTypes: "/po/getPaymentTypes",
			rejectPr: "/po/updatePrAndDeletePo",
			updatePoDetails: "/po/updatePoDetails",
			updatePoApproval: "/po/updatePoApproval",
			uploadAttachmentFile: "/po/uploadAttachment",
		},

		rc: {
			getReceives: "/rc/getReceives",
			cancelReceiveItems: "/rc/cancelReceiveItems",
			updateReceive: "/rc/updateReceive",
			saveReceiveItems: "/rc/saveReceiveItems",
		},

		transfer: {
			approve: "/tf/approve",
			completeTR: "/tf/completeTR",
			cancelTRI: "/tf/cancelTRI",
			createT: "/tf/createT",
			createTR: "/tf/createTR",
			getItems: "/tf/getItems",
			getOrgs: "/tf/getOrgs",
			getGeneralLocations: "/tf/getGenLocs",
			getRequests: "/tf/getRequests",
			getTypes: "/tf/getTypes",
			setTransferred: "tf/setTransferred",
		},

		inventory: {
			deleteInventory: "/inv/deleteInventoryDetail",
			getActiveItems: "/inv/getActiveItems",
			getLocations: "/inv/getLocations",
			getTypes: "/inv/getTypes",
			saveInventory: "/inv/saveInventoryDetail",
		},

		admin: {
			item: {
				loadItemSuppliers: "admin/getItemSuppliers",
				saveItemSupplier: "admin/saveItemSupplier",
			},
			itemSource: {
				loadItems: "admin/getItems",
				saveItem: "admin/saveItem",
			},
			location: {
				loadLocs: "admin/getLocations",
				saveLoc: "admin/saveLoc",
			},
			supplier: {
				loadSuppliers: "admin/getSuppliers",
				saveSupplier: "admin/saveSupplier",
			},
			type: {
				item: {
					loadTypes: "admin/getItemTypes",
					saveType: "admin/saveItemType",
				},
				payment: {
					loadTypes: "/admin/getPaymentTypes",
					saveType: "/admin/savePaymentType",
				},
				service: {
					loadTypes: "/admin/gsts",
					saveType: "/admin/sst",
				},
				role: {
					loadTypes: "/admin/grts",
					saveType: "/admin/srt",
				},
				default: {
					loadTypes: "/admin/gdts",
					saveType: "/admin/sdt",
				},
				healthCarePlan: {
					loadTypes: "/admin/ghcps",
					saveType: "/admin/shcp",
				},
				icd: {
					loadTypes: "/admin/gicds",
					saveType: "/admin/sicd",
				},
				isolationPrecaution: {
					loadTypes: "/admin/gips",
					saveType: "/admin/sip",
				},
				fallRisk: {
					loadTypes: "/admin/gfrs",
					saveType: "/admin/sfr",
				},
				intake: {
					loadTypes: "/admin/gis",
					saveType: "/admin/si",
				},
				output: {
					loadTypes: "/admin/gops",
					saveType: "/admin/sop",
				},
				pcr: {
					loadTypes: "/admin/gpcrs",
					saveType: "/admin/spcr",
				},
				antigen: {
					loadTypes: "/admin/gas",
					saveType: "/admin/sa",
				},
			},
			unit: {
				loadUnits: "admin/getUnits",
				saveUnit: "admin/saveUnit",
			},
			form: {
				loadForms: "admin/getForms",
				saveForm: "admin/saveForm",
			},
			tax: {
				loadTaxes: "admin/getTaxes",
				saveTax: "admin/saveTax",
			},
			user: {
				loadUsers: "admin/getUsers",
				saveUser: "admin/saveUser",
				changePassword: "admin/changePassword",
				loadRoleTypes: "admin/gurts",
				saveSignature: "admin/ss",
			},
			role: {
				loadRoles: "admin/getRoles",
				saveRole: "admin/saveRole",
			},
			permission: {
				loadPermissions: "admin/getPermissions",
			},
			service: {
				getServices: "/admin/service/gss",
				saveService: "/admin/service/ss",
				getRoles: "/admin/service/guts",
			},
			customDefault: {
				getCategories: "/admin/customDefault/gcdcs",
				saveFocus: "/admin/customDefault/sf",
				saveIntervention: "/admin/customDefault/si",
			},
			icd: {
				getICDs: "/admin/icd/gicds",
				getLevels: "/admin/icd/gls",
				saveICD: "/admin/icd/sicd",
				saveLevel: "/admin/icd/sl",
			},
			laboratory: {
				getPCRComments: "/admin/laboratory/gpcrcs",
				getAntigenComments: "/admin/laboratory/gacs",
				savePCRComment: "/admin/laboratory/spcrc",
				saveAntigenComment: "/admin/laboratory/sac",
			},
		},

		report: {
			inv: {
				getOrgs: "/report/inv/getOrgs",
				getInvs: "/report/inv/getInvs",
			},
			po: {
				getOrgs: "/report/po/getOrgs",
				getPOs: "/report/po/getPOs",
				getPO: "/report/po/getPO",
			},
			tf: {
				getOrgs: "/report/tf/getOrgs",
				getTFs: "/report/tf/getTFs",
			},
			hospitel: {
				getOrgs: "/report/hospitel/gos",
				getHospitels: "/report/hospitel/ghs",
			},
			lab: {
				getLabs: "/report/lab/gls",
			},
			cashier: {
				getAdmissions: "/report/cashier/getAdmissions",
				getRoles: "/report/cashier/getRoles",
				getPayeeOrders: "/report/cashier/gpos",
				getBillingToTodayByAdmission: "/report/cashier/gbttba",
			},
		},
		cashier: {
			searchPatient: "/hs/cashier/sp",
			createAdmission: "/hs/cashier/ca",
			discharge: "/hs/cashier/dis",
			deleteBillingStatement: "/hs/cashier/dbs",
			getHealthCares: "/hs/cashier/ghcs",
			getPayments: "/hs/cashier/gps",
			saveBilling: "/hs/cashier/sb",
			saveBillingPlans: "/hs/cashier/sbps",
			saveBillingStatementAdjustment: "/hs/cashier/sbsa",
			saveDoctorOrderPrescriptionOnly: "/hs/cashier/sdopo",
		},
		deposit: {
			searchPatient: "/his/deposit/sp",
			getPayments: "/his/deposit/gps",
			saveDeposit: "/his/deposit/sdp",
		},
		registration: {
			registerPatient: "/hs/registration/rp",
			getPatients: "/hs/registration/gps",
			getHealthCares: "/hs/registration/ghcs",
			getExpenseEstimation: "/hs/registration/gee",
			getItems: "/hs/registration/gis",
			getTypes: "/hs/registration/gts",
			getServices: "/hs/registration/gss",
			getIsoPrecautions: "/hs/registration/gips",
			getFallRisks: "/hs/registration/gfrs",
			createClinicalRecord: "/hs/registration/ccr",
			uploadSatCodeFile: "/hs/registration/uscf",
			downloadFile: "/hs/registration/df",
			uploadNovelFile: "/hs/registration/un3",
			uploadIdFile: "/hs/registration/uid",
			uploadHealthCareFile: "/hs/registration/uhc",
			uploadCovidResultFile: "/hs/registration/ucr",
		},
		opd: {
			getCustomDefaultCategories: "/his/opd/gcdcs",
			getUserTypes: "/his/opd/guts",
			getServices: "/his/opd/gss",
			getUnits: "/his/opd/gus",
			getItems: "/his/opd/gis",
			getTypes: "/his/opd/gts",
			getOrgs: "/his/opd/gos",
			getPatients: "/his/opd/gps",
			getICDs: "/his/opd/gicds",
			getLevels: "/his/opd/gls",
			getIntakes: "/his/opd/gits",
			getOutputs: "/his/opd/gops",
			getPatientsWithRegisteredOPD: "/his/opd/gpswropd",
			getFrontInventoriesByBranch: "/his/opd/gfisbb",
			updateMedicalRecord: "/his/opd/umr",
			updateDoctorOrderRange: "/his/opd/udor",
			updateAppointmentDate: "/his/opd/uad",
			scheduleAppointment: "/his/opd/sa",
			saveDoctorOrders: "/his/opd/sdos",
			saveCertificate: "/his/opd/smc",
			saveNurseNote: "/his/opd/snn",
			saveVitalSign: "/his/opd/svs",
			saveNurseOrders: "/his/opd/snos",
			saveICDs: "/his/opd/sicds",
			saveIOs: "/his/opd/sios",
			saveGCSs: "/his/opd/sgcss",
			savePSs: "/his/opd/spss",
			savePupilSize: "/his/opd/splss",
			deleteDoctorOrder: "/his/opd/ddo",
			deleteNurseNote: "/his/opd/dnn",
			deleteVitalSign: "/his/opd/dvs",
			deleteNurseOrder: "/his/opd/dno",
			deleteMedicalRecord: "/his/opd/dmr",
			deleteICD: "/his/opd/dicd",
			deleteIOs: "/his/opd/dios",
			deleteMedicalCertificate: "/his/opd/dmc",
			deleteGCSs: "/his/opd/dgcss",
			deletePainScales: "/his/opd/dpss",
			deletePupilSize: "/his/opd/dplss",
		},
		ipd: {
			cancelOrder: "/hs/ipd/co",
			getHospitels: "/hs/ipd/ghs",
			getPatient: "/hs/ipd/gp",
			getPatientsByFilter: "/hs/ipd/gpsbf",
			getServices: "/hs/ipd/gss",
			getItems: "/hs/ipd/gis",
			getTypes: "/hs/ipd/gts",
			getCategories: "/hs/ipd/getCategories",
			registerHospitel: "/hs/ipd/rh",
			saveOrders: "/hs/ipd/sos",
			saveNurseOrders: "/hs/ipd/saveNurseOrders",
			stopOrder: "/hs/ipd/so",
			saveVitalSign: "/hs/ipd/svs",
			deleteVitalSign: "/hs/ipd/dvs",
			saveNurseNote: "/hs/ipd/snn",
			deleteNurseNote: "/hs/ipd/dnn",
			deleteNurseOrder: "/hs/ipd/deleteNurseOrder",
			dischargeHospitel: "/hs/ipd/dh",
			getOrgs: "/hs/ipd/gos",
			deleteSOAP: "/hs/ipd/dsoap",
			getUnits: "/hs/ipd/gus",
			savePRNDispensingRecord: "/hs/ipd/sprndr",
			getUserTypes: "/hs/ipd/guts",
			saveCertificate: "/hs/ipd/smc",
			deleteICD: "/hs/ipd/dicd",
			saveICDs: "/hs/ipd/sicds",
			getICDs: "/hs/ipd/gicds",
			getLevels: "/hs/ipd/gls",
			getIntakes: "/hs/ipd/gits",
			getOutputs: "/hs/ipd/gops",
			saveIOs: "/hs/ipd/sios",
			deleteIOs: "/hs/ipd/dios",
			deleteMedicalCertificate: "/hs/ipd/dmc",
			saveGCSs: "/hs/ipd/sgcss",
			deleteGCSs: "/hs/ipd/dgcss",
			savePainScales: "/hs/ipd/spss",
			deletePainScales: "/hs/ipd/dpss",
			savePupilSizes: "/hs/ipd/splss",
			deletePupilSizes: "/hs/ipd/dplss",
		},
		lab: {
			cancelLab: "/hs/lab/cll",
			completeLab: "/hs/lab/cpl",
			createLab: "/hs/lab/cl",
			getOrgs: "/hs/lab/gos",
			getServices: "/hs/lab/gss",
			getPatients: "/hs/lab/gps",
			getPatientsByFilter: "/hs/lab/gpsbf",
			getPCRCommentCategories: "/hs/lab/gpcrccs",
			getAntigenCommentCategories: "/hs/lab/gacs",
			registerPatient: "/hs/lab/rp",
			updateLabResult: "/hs/lab/ulr",
			updateLabUrl: "/hs/lab/ulurl",
		},
		pharmacy: {
			getPatients: "/his/pharmacy/gps",
			getOrgs: "/his/pharmacy/gos",
			getInventory: "/his/pharmacy/gi",
			deletePharmacyOrder: "/his/pharmacy/dpo",
			savePharmacyOrder: "/his/pharmacy/spo",
			saveDispensedPharmacyOrder: "/his/pharmacy/sdpo",
			savePharmacyReturnOrder: "/his/pharmacy/spro",
		},
		frontInventory: {
			getOrgs: "/his/frontInventory/gos",
			getTypes: "/his/frontInventory/gts",
			getPharmacyInventories: "/his/frontInventory/gisbl",
			updateInventory: "/his/frontInventory/ui",
		},
		notification: {
			getPatients: "/menu/notification/gps",
			saveNotifications: "/menu/notification/sns",
			deleteNotifications: "/menu/notification/dns",
		},
		timeline: {
			getPatients: "/menu/timeline/gps",
		},
	},
}

export default Url
