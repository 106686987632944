import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OutpatientRightGCS from "./OutpatientRightGCS"
import OutpatientRightIntakeOutput from "./OutpatientRightIntakeOutput"
import OutpatientRightPainScale from "./OutpatientRightPainScale"
import OutpatientRightPupilSize from "./OutpatientRightPupilSize"
import OutpatientRightVitalSign from "./OutpatientRightVitalSign"

class OutpatientRightDataTab extends React.Component {
	EDataTab = {
		VITAL_SIGN: "OPD_TAB_VITAL_SIGN",
		INTAKE_OUTPUT: "OPD_TAB_INTAKE_OUTPUT",
		GLASGOW_COMA_SCALE: "OPD_TAB_GLASGOW_COMA_SCALE",
		PAIN_SCALE: "OPD_TAB_PAIN_SCALE",
		PUPIL_SIZE: "OPD_TAB_PUPIL_SIZE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EDataTab.VITAL_SIGN,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tabs variant="tabs" activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Tab
					eventKey={this.EDataTab.VITAL_SIGN}
					title={translate(ResourceAssistance.Hospitel.vitalSign)}
					name={this.EDataTab.VITAL_SIGN}
				>
					{this.state.tab === this.EDataTab.VITAL_SIGN && <OutpatientRightVitalSign />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.INTAKE_OUTPUT}
					title={translate(ResourceAssistance.Message.intakeOutput)}
					name={this.EDataTab.INTAKE_OUTPUT}
				>
					{this.state.tab === this.EDataTab.INTAKE_OUTPUT && <OutpatientRightIntakeOutput />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.GLASGOW_COMA_SCALE}
					title={translate(ResourceAssistance.Message.glasgowComaScale)}
					name={this.EDataTab.GLASGOW_COMA_SCALE}
				>
					{this.state.tab === this.EDataTab.GLASGOW_COMA_SCALE && <OutpatientRightGCS />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.PAIN_SCALE}
					title={translate(ResourceAssistance.Message.painScale)}
					name={this.EDataTab.PAIN_SCALE}
				>
					{this.state.tab === this.EDataTab.PAIN_SCALE && <OutpatientRightPainScale />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.PUPIL_SIZE}
					title={translate(ResourceAssistance.Message.pupilSize)}
					name={this.EDataTab.PUPIL_SIZE}
				>
					{this.state.tab === this.EDataTab.PUPIL_SIZE && <OutpatientRightPupilSize />}
				</Tab>
			</Tabs>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDataTab)
