import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setRcWaitingReceives } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RCBottomSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCBottomSecondRow",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.selectedRc) !== JSON.stringify(this.props.selectedRc) ||
			prevProps.isDisplayAll !== this.props.isDisplayAll
		) {
			if (this.props.selectedRc.index === -1) {
				this.props.setRcWaitingReceives([])
			} else {
				this.props.setRcWaitingReceives(this.props.selectedRc.rc.suppliers)
			}
		}
	}

	render() {
		return (
			<Row noGutters id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Col>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						hover
						enableOverlay
						data={this.props.waitingReceive}
						isClearHightlight={this.props.selectedRc.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	selectedRc: state.receive.selectedRc,
	waitingReceive: state.receive.waitingReceive,
	isDisplayAll: state.receive.isWaitingReceiveDisplayAll,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcWaitingReceives }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCBottomSecondRow)
