import { ResourceAssistance } from "~/i18n"
import { SET_PR_TYPES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setAdminPaymentTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) =>
			type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase())
		)

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.patientSaving,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [
				type.displayName,
				type.description,
				type.savingAccount,
				type.createdBy.nickname,
				Utils.formatDate(type.creationDateTime),
			]
		})
		dispatch({
			type: SET_PR_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminHealthCareTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) =>
			type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase())
		)

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.primaryPlan,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [
				type.code,
				type.displayName,
				type.description,
				type.primaryHealthCarePlan,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})
		dispatch({
			type: SET_PR_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
