import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSearchText, setTfRequests } from "~/redux/action"
import { SET_TF_SEARCH_LOCATION, SET_TF_SEARCH_NUMBER } from "~/redux/type/type/TF"
import SearchBox from "~/view/component/search_box/SearchBox"

class TFLeftSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "TFLeftSecondRow",
		}

		this.onSearchNumChange = this.onSearchNumChange.bind(this)
		this.onSearchLocationChange = this.onSearchLocationChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.transfer.searchNum !== this.props.transfer.searchNum ||
			prevProps.transfer.searchLocation !== this.props.transfer.searchLocation
		) {
			this.props.setTfRequests(this.props.transfer.requestTable.original)
		}
	}

	onSearchNumChange(event) {
		this.props.setSearchText(SET_TF_SEARCH_NUMBER, event.target.value)
	}

	onSearchLocationChange(event) {
		this.props.setSearchText(SET_TF_SEARCH_LOCATION, event.target.value)
	}

	render() {
		return (
			<Row noGutters id={this.state.componentName}>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.hashSymbol, ResourceAssistance.Message.location]}
						callbacks={[this.onSearchNumChange, this.onSearchLocationChange]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setSearchText, setTfRequests }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFLeftSecondRow)
