import { ResourceAssistance } from "~/i18n"
import logoPMG from "~/img/pmg-logo.png"
import { PrintableDataFactory } from "./PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class CashierPrintableDataFactory extends PrintableDataFactory {
	static generateBillByHealthCarePlanData = (patient, admission, cashier, healthCarePlans) => {
		return {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.service }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.totalPrice }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			adjustmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.adjustments }),
			balanceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dischargeDate }),

			logo: logoPMG,
			name: "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด",
			branchName: "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี",
			address: "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150",
			tel: "02-451-4920-4",
			taxId: "0105535059152",

			pid: patient.id,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientAddress: patient.address,
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			healthCarePlans: healthCarePlans.map((hcp) => {
				let subTotal = Utils.BigNumber(0)
				let adjustments = Utils.BigNumber(0)
				return {
					...hcp,
					billingStatements: hcp.billingStatements.map((bs) => {
						subTotal = subTotal.plus(bs.charge)
						adjustments = adjustments.plus(bs.adjustment)
						return {
							...bs,
							amount: Utils.formatNumWithComma(Utils.BigNumber(bs.charge).toFixed(2)),
							adjustment: Utils.formatNumWithComma(Utils.BigNumber(bs.adjustment).negated().toFixed(2)),
							balance: Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.adjustment).toFixed(2)),
						}
					}),
					subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
					adjustments: Utils.formatNumWithComma(adjustments.negated().toFixed(2)),
					grandTotal: Utils.formatNumWithComma(subTotal.minus(adjustments).toFixed(2)),
				}
			}),

			signatureTitles: [this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier"],
			signatureNames: [cashier.firstName + " " + cashier.lastName],
		}
	}
}

export default CashierPrintableDataFactory
