import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLabPatientLabs, setSelected } from "~/redux/action"

class CLRightUserRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLRightUserRow",
		}
	}

	render() {
		return (
			<Row noGutters id={this.state.componentName} className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>{translate(ResourceAssistance.Message.issuedBy)}</InputGroup.Text>
						</InputGroup.Prepend>
						<FormControl
							type={ResourceAssistance.FormControl.type.text}
							disabled
							value={this.props.lab.selectedLab.lab ? this.props.lab.selectedLab.lab.issuedBy.displayName : ""}
						/>
					</InputGroup>
				</Col>
				<Col>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>{translate(ResourceAssistance.Message.certifiedBy)}</InputGroup.Text>
						</InputGroup.Prepend>
						<FormControl
							type={ResourceAssistance.FormControl.type.text}
							disabled
							value={
								this.props.lab.selectedLab.lab && this.props.lab.selectedLab.lab.certifiedBy
									? this.props.lab.selectedLab.lab.certifiedBy.displayName
									: ""
							}
						/>
					</InputGroup>
				</Col>
				<Col>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>{translate(ResourceAssistance.Message.approvedBy)}</InputGroup.Text>
						</InputGroup.Prepend>
						<FormControl
							type={ResourceAssistance.FormControl.type.text}
							disabled
							value={
								this.props.lab.selectedLab.lab && this.props.lab.selectedLab.lab.approvedBy
									? this.props.lab.selectedLab.lab.approvedBy.displayName
									: ""
							}
						/>
					</InputGroup>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLabPatientLabs,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CLRightUserRow))
