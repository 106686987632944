import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
class DepositPatient extends React.Component {
	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontSize: "16px" }}
							contentStyle={{ fontSize: "16px" }}
							column={3}
						>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Hospitel.patientId)}>
								{this.props.deposit.patient.id}
							</Descriptions.Item>
							<Descriptions.Item
								label={translate(ResourceAssistance.Message.patientName)}
								contentStyle={{ whiteSpace: "normal" }}
								span={2}
							>
								{this.props.deposit.patient.title +
									" " +
									this.props.deposit.patient.firstName +
									" " +
									this.props.deposit.patient.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
								{this.props.deposit.patient.identificationNumber}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.gender)}>
								{this.props.deposit.patient.gender}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)}>
								{Utils.formatDate(this.props.deposit.patient.dobtime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)}>
								{Utils.calculateAge(this.props.deposit.patient.dobtime)} {translate(ResourceAssistance.Hospitel.year)}{" "}
								{Utils.calculateAgeMonth(this.props.deposit.patient.dobtime)}{" "}
								{translate(ResourceAssistance.Hospitel.month)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={3}>
								{this.props.deposit.patient.tel}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.address)} span={3}>
								{this.props.deposit.patient.address}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositPatient)
