import { QuestionCircleOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import React, { Fragment } from "react"
import { Col, FormLabel, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { Property } from "./TypeModalNew"

class TypeModalNewHealthCarePlanFields extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayPrimaryPlan && (
					<Row>
						<Col>
							<InputGroup style={{ width: "auto" }}>
								<FormLabel>{translate(ResourceAssistance.Message.primaryPlan)}</FormLabel>
								<Popover content={translate(ResourceAssistance.Remark.primaryHealthCarePlan)} trigger="hover">
									<QuestionCircleOutlined style={{ color: "red" }} />
								</Popover>
							</InputGroup>
						</Col>
						<Col>
							<ToggleCheckBox
								name={Property.PRIMARY_PLAN}
								onClick={this.props.onPropertyChange}
								checked={this.props.isPrimaryHealthCarePlan}
							/>
						</Col>
					</Row>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewHealthCarePlanFields)
