import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setOPDModalNurseServicePendingServices, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
} from "~/redux/type"

class OPDModalNurseServiceAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	onDownBtn() {
		window.adjustmentServiceCaller = this
		this.props.setValue(
			SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
			this.props.opd.modalNurseService.selectedService.service
		)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY, true)
	}

	onUpBtn() {
		let pendingServices = this.props.opd.modalNurseService.pendingServiceTable.filtered.filter(
			(each, index) => index !== this.props.opd.modalNurseService.selectedPendingService.index
		)
		this.props.setOPDModalNurseServicePendingServices(pendingServices)
	}

	adjustmentServiceCallback() {
		let selectedService = Object.assign({}, this.props.opd.modalNurseService.selectedService.service, {
			notes: this.props.hospitel.modalAdjustmentService.notes,
			startDateTime: this.props.hospitel.modalAdjustmentService.startDateTime,
			endDateTime: this.props.hospitel.modalAdjustmentService.isDisableEndDate
				? null
				: this.props.hospitel.modalAdjustmentService.endDateTime,
		})
		let services = this.props.opd.modalNurseService.pendingServiceTable.original
		services.push(selectedService)
		this.props.setOPDModalNurseServicePendingServices(services)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.opd.modalNurseService.selectedService.index === -1}
							onClick={this.onDownBtn}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.opd.modalNurseService.selectedPendingService.index === -1}
							onClick={this.onUpBtn}
						>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setOPDModalNurseServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseServiceAction)
