import React, { Fragment } from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue, setSelected } from "~/redux/action"
import {
	SET_ADM_SERVICE_NEW_SERVICE_ACTIVE,
	SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM,
	SET_ADM_SERVICE_NEW_SERVICE_CODE,
	SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION,
	SET_ADM_SERVICE_NEW_SERVICE_FS_CODE,
	SET_ADM_SERVICE_NEW_SERVICE_NAME,
	SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT,
	SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigServiceModalNSService extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onCode = this.onCode.bind(this)
		this.onFeeScheduleCode = this.onFeeScheduleCode.bind(this)
		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onPricePerUnit = this.onPricePerUnit.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onCategoryNum = this.onCategoryNum.bind(this)
	}

	componentDidMount() {
		let service = this.props.service.selectedService.service
		if (service) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.service.modalNewService.type)
			type.selectedIndex = Utils.BigNumber(this.props.service.selectedType.index).plus(1).toNumber()
			this.props.setSelected(
				SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE,
				this.props.service.selectedType.index,
				this.props.service.types[this.props.service.selectedType.index]
			)

			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CODE, service.code)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FS_CODE, service.fsCode)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_NAME, service.name)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION, service.description)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT, service.pricePerUnit)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ACTIVE, service.active)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM, service.categoryNum)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CODE, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FS_CODE, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_NAME, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ACTIVE, true)
	}

	onType(event) {
		this.props.setSelected(
			SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE,
			event.target.value,
			this.props.service.types[event.target.value]
		)
	}

	onCode(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CODE, event.target.value.toUpperCase())
	}

	onFeeScheduleCode(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FS_CODE, event.target.value.toUpperCase())
	}

	onName(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION, event.target.value)
	}

	onPricePerUnit(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT, event.target.value)
	}

	onActive(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ACTIVE, event.target.checked)
	}

	onCategoryNum(event) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.category)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.service.modalNewService.type}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.service.types, true)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.code)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.service.modalNewService.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.fsCode)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.service.modalNewService.fsCode}
							onChange={this.onFeeScheduleCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.categoryNum)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.number}
							value={this.props.service.modalNewService.categoryNum}
							onChange={this.onCategoryNum}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.service.modalNewService.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.description)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							rows={5}
							value={this.props.service.modalNewService.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.pricePerUnit)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.number}
							step={0.01}
							value={this.props.service.modalNewService.pricePerUnit}
							onChange={this.onPricePerUnit}
						/>
					</Col>
				</Row>
				<Row noGutters>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.service.modalNewService.isActive} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNSService))
