import COMPONENT from "./Component"
import CLASSNAME from "./CSS"
import MESSAGE from "./Message"
import Url from "./Url"
import SYMBOL from "./Symbol"
import KEY_CODE from "./KeyCode"
import Privilege from "./Privilege"
import Constant from "./Constant"
import Id from "./ID"
import Report from "./Report"
import enUS from "../message/en-US"
import { LOCALES } from "../locale"
import Warning from "./Warning"
import Remark from "./Remark"
import ContextMenu from "./ContextMenu"
import Enum from "./Enum"

const ResourceAssistance = {
	...SYMBOL,
	...COMPONENT,
	...KEY_CODE,
	...Privilege,
	...Constant,
	ID: {
		...Id,
	},
	ContextMenu: {
		...ContextMenu,
	},
	Enum: {
		...Enum,
	},

	Report: {
		...Report,
		intl: {
			...enUS[LOCALES.ENGLISH],
		},
	},

	CSS: {
		...CLASSNAME,
		removePadding: "remove-padding",
		leftPannelDatePickerGroup: "left-pannel-datepicker-group",
		vCenter: "self-vertical-center",
	},

	LoginForm: {
		// FormControl: {
		//     type: {...Properties.FormControl.type}
		// },
		username: "username",
		password: "password",
	},

	PurchaseRequest: {
		RightPannel: {
			Value: {
				requester: "requester",
				inspector: "inspector",
				approver: "approver",
				isRequesterApproved: "requesterApproved",
				isInspectorApproved: "inspectorApproved",
				isApproverApproved: "approverApproved",
				requesterApprovedDateTime: "requesterApprovedDateTime",
				inspectorApprovedDateTime: "inspectorApprovedDateTime",
				approverApprovedDateTime: "approverApprovedDateTime",
			},
		},
	},

	...Url,

	Path: {
		notFoundPage: "/404",
		root: "/",
		login: "/login",
		home: "/home",
		inventory: "/inventory",
		transfer: "/transfer",
		pr: "/pr",
		po: "/po",
		rc: "/rc",
		configType: "/admin/type",
		configLoc: "/admin/loc",
		configUser: "/admin/user",
		configItem: "/admin/item",
		configService: "/admin/service",
		configCustomDefault: "/admin/customDefault",
		configICD: "/admin/icd",
		configLaboratory: "/admin/laboratory",

		reportInv: "/r/inv",
		reportTf: "/r/tf",
		reportPr: "/r/pr",
		reportPo: "/r/po",
		reportRc: "/r/rc",
		Report: {
			report: "/report",
			File: {
				claim: "/report/file/claim",
				pdf: "/report/file/pdf",
			},
			Inv: {
				byLocation: {
					qty: "/report/invByLocationQty",
					value: "/report/invByLocationValue",
				},
			},
			Tf: {
				tf: "/report/tf",
				byLocation: "/report/tfByLocation",
			},
			Po: {
				po: "/report/po",
				byLocation: "/report/poByLocation",
				byId: "/report/poById",
			},
			Rc: {
				rc: "/report/rc",
			},
			// Hospital
			Hospital: {
				label: "/report/label",
				doctorOrder: "/report/doctorOrder",
				nurseNote: "/report/nurseNote",
				clinicalRecord: "/report/clinicalRecord",
				admissionNote: "/report/admissionNote",
				medicationSheet: "/report/medicationSheet",
				vitalSign: "/report/vitalSign",
				firstAssessment: "/report/firstAssessment",
				letterOfConsent: "/report/letterOfConsent",
				depressionAssessment: "/report/depressionAssessment",
			},
			cashier: {
				bill: "/report/cashierBill",
				billByHealthCarePlan: "/report/cashierBillByHealthCarePlan",
				billDetails: "/report/cashierBillDetails",
				billSummary: "/report/cashierBillSummary",
				billingToToday: "/report/cashierBillingToTodayByAdmission",
				byUser: "/report/cashierByUser",
				healthCarePlanCategoryReceipt: "/report/cashierHealthCarePlanCategoryReceipt",
				healthCarePlanReceipt: "/report/cashierHealthCarePlanReceipt",
			},
			lab: {
				covid19Lab: "/report/labCovid19Lab",
				covid19Excel: "/report/Covid19_excel",
			},
			ipd: {
				patient: "/report/ipdPatient",
				expenseEst: "/report/ipdExpenseEst",
				glasgowComaScale: "/report/glasgowComaScale",
				pupilSize: "/report/pupilSize",
				painScale: "/report/painScale",
				intakeOutput: "/report/intakeOutput",
			},
			opd: {
				doctorPrescription: "/report/opd/doctorPrescription",
				nurseOrder: "/report/opd/nurseOrder",
				nurseNote: "/report/opd/nurseNote",
				vitalSign: "/report/opd/vitalSign",
				medicalCertificate: "/report/opd/medicalCertificate",
			},
			pharmacy: {
				orderLabel: "/report/pharmacy/orderLabel",
			},
		},

		//Hospital
		Hospitel: {
			cashier: "/hp/cashier",
			registration: "/hp/registration",
			hospitel: "/hp/ipd",
			covid19Lab: "/hp/covid19Lab",
			reportCashier: "/hp/r/cashier",
			reportHospitel: "/hp/r/hospitel",
			reportLab: "/hp/r/lab",
		},

		HIS: {
			debt: "/HIS/debt",
			deposit: "/HIS/deposit",
			radiology: "/HIS/radiology",
			opd: "/HIS/opd",
			pharmacy: "/HIS/pharmacy",
			frontInventory: "/HIS/inventory",
		},
	},

	...MESSAGE,
	...Warning,
	...Remark,

	//method to conduct multiple CSS class name.
	mulCSS: (...className) => {
		let classNames = ""
		className.forEach((element) => {
			classNames += " " + element
		})

		return classNames
	},
}

export default ResourceAssistance
