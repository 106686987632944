import { Checkbox } from "antd"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import "react-bootstrap"
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import { ImAttachment } from "react-icons/im"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import "./scrollable_table.css"

export const Overlay = React.forwardRef((props, ref) => {
	return <Tooltip ref={ref}>"sfdfsdfdf"</Tooltip>
})

class ScrollableTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ScrollableTable",
			isVisible: false,
		}
		this.observer = new IntersectionObserver(([entry]) => {
			this.setState({ isVisible: entry.isIntersecting })
		})
		this.ScrollableTableSizeRef = React.createRef()
		this.ref = this.props.refs ? this.props.refs : React.createRef()
		this.overlayRef = React.createRef()
	}

	componentDidMount() {
		// let table = Array.prototype.find.call(
		// 	document.getElementById("POLeftFourthRow").getElementsByTagName("div"),
		// 	(element) => {
		// 		console.log(element)
		// 		return element.id === "ScrollableTable"
		// 	}
		// )

		this.observer.observe(this.ScrollableTableSizeRef.current)
		if (!this.ScrollableTableSizeRef.current.style.maxHeight) {
			this.calculateHeight()
		}

		if (!this.props.enableHighlight) {
			this.ref.current.children[1].className = this.ref.current.children[1].className + " remove_cursor"
		} else {
			this.ref.current.children[1].className = this.ref.current.children[1].className + " selection_cursor"
		}
		if (this.props.enableOverlay && !this.props.overlayMsg) {
			let widthPerCharAndCharWidthArray = []
			//header//tr
			let totalCharsWidth = Array.prototype.reduce
				.call(
					this.ref.current.children[0].children[0].children,
					(total, char, index) => {
						let style = Utils.getComputedStyle(char)
						let formattedWidth = Utils.calculateTextWidthWithW(char.innerText, style.font)
						widthPerCharAndCharWidthArray[index] = []
						widthPerCharAndCharWidthArray[index][0] = Utils.BigNumber(formattedWidth)
							.dividedBy(char.innerText.length)
							.toNumber()
						widthPerCharAndCharWidthArray[index][1] = formattedWidth
						// return total.plus(Utils.BigNumber(formattedWidth).isLessThan(68) ? 68 : formattedWidth) //since characters are not equal size so add 10 offset size
						return total.plus(formattedWidth)
					},
					Utils.BigNumber(0)
				)
				.toNumber()
			let extraSpace = Utils.BigNumber(this.ref.current.children[0].clientWidth).minus(totalCharsWidth).toNumber()
			this.setState({
				colMaxChars: {
					col: widthPerCharAndCharWidthArray.map((char) => {
						return Utils.BigNumber(char[1]).plus(extraSpace).dividedBy(char[0]).toNumber()
					}),
				},
			})
		}
	}

	componentWillUnmount() {
		this.observer.unobserve(this.ScrollableTableSizeRef.current)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isVisible !== this.state.isVisible) {
			if (this.state.isVisible && this.ScrollableTableSizeRef.current.style.maxHeight === "0px") {
				this.calculateHeight()
			}
		}
		if (
			this.props.enableHighlight &&
			(prevProps.highlightedRow !== this.props.highlightedRow || this.props.isClearHighlight)
			// prevProps.highlightedRow !== this.props.highlightedRow ||
			// this.props.isClearHighlight
		) {
			this.clearHighlight()
		}
		if (this.props.enableHighlight && !this.props.isClearHighlight) {
			Array.from(this.ref.current.children[1].children)
				.filter((row, index) => index === this.props.highlightedRow)
				.forEach((row) => {
					if (row.style.backgroundColor) {
						row.style.backgroundImage = `linear-gradient(to right, lightskyblue 40%, ${row.style.backgroundColor})`
						row.className = row.className.replace(ResourceAssistance.CSS.ScrollableTable.highlightSelected, "")
					} else {
						row.style.backgroundImage = ""
						row.className = ResourceAssistance.CSS.ScrollableTable.highlightSelected
					}
				})
		}
	}

	calculateHeight() {
		let childDOM = this.ScrollableTableSizeRef.current.firstChild
		this.ScrollableTableSizeRef.current.removeChild(childDOM)
		let marginBottom = parseFloat(Utils.getComputedStyle(this.ScrollableTableSizeRef.current).marginBottom)
		let computedHeight = this.ScrollableTableSizeRef.current.parentNode.clientHeight - marginBottom
		this.ScrollableTableSizeRef.current.style.maxHeight = computedHeight + ResourceAssistance.CONSTANT.PX
		this.ScrollableTableSizeRef.current.appendChild(childDOM)
	}

	showOverlay(cKey, col) {
		return this.state.colMaxChars && col && Utils.BigNumber(this.state.colMaxChars.col[cKey]).isLessThan(col.length)
	}

	async onClick(row, index) {
		if (this.props.enableHighlight) {
			this.clearHighlight()

			if (this.ref.current.children[1].children[index].style.backgroundColor) {
				this.ref.current.children[1].children[
					index
				].style.backgroundImage = `linear-gradient(to right, lightskyblue 60%, ${this.ref.current.children[1].children[index].style.backgroundColor})`
			} else {
				this.ref.current.children[1].children[index].className =
					ResourceAssistance.CSS.ScrollableTable.highlightSelected
			}

			if (this.props.onClick && !this.props.disabled) {
				this.props.onClick(row, index)
			}
		}
	}

	onTooltipEntering = (event, rKey, cKey) => {
		event.children[1].style.backgroundColor = ResourceAssistance.CSS.Color.info

		if (this.props.enableOverlay && this.props.onTooltipEntering) {
			this.props.onTooltipEntering(event, rKey, cKey)
		}
	}

	onDoubleClick(row, index) {
		this.props.onDoubleClick && this.props.onDoubleClick(row, index)
	}

	onMouseOver(row, index) {
		this.props.onMouseOver && this.props.onMouseOver(row, index)
	}

	async onContextMenu(row, rKey, e) {
		e.persist()
		await this.onClick(row, rKey)
		this.props.onContextMenu && this.props.onContextMenu(row, rKey, e)
	}

	clearHighlight() {
		Array.from(this.ref.current.children[1].children)
			.filter((row) => {
				return (
					row.className.includes(ResourceAssistance.CSS.ScrollableTable.highlightSelected) ||
					row.style.backgroundImage.includes("linear-gradient")
				)
			})
			.forEach((row) => {
				if (!_.isEmpty(row.style.backgroundImage)) {
					row.style.backgroundImage = ""
				} else {
					row.className = row.className.replace(ResourceAssistance.CSS.ScrollableTable.highlightSelected, "")
				}
			})
	}

	getHeader() {
		return this.props.data.header.map((header, key) => {
			return <th key={key}>{_.isEmpty(header) ? "" : translate(header)}</th>
		})
	}

	getBody() {
		let body = this.props.data.body
			.filter((row) => row !== undefined)
			.map((row, rKey) => {
				return this.props.enableOverlay && this.props.overlayMsg ? (
					<OverlayTrigger
						key={rKey}
						onEntering={(event) => this.onTooltipEntering(event, rKey, -1)}
						overlay={<Tooltip>{this.props.overlayMsg}</Tooltip>}
					>
						{/* <OverlayTrigger key={rKey} overlay={<Overlay ref={this.overlayRef} />}> */}
						{this.renderRow(row, rKey)}
					</OverlayTrigger>
				) : (
					this.renderRow(row, rKey)
				)
			})

		if (this.props.extra) {
			body = body.concat([
				<tr key={this.props.data.body.length + 1} style={{ backgroundColor: "unset", cursor: "default" }}>
					<th key={0} colSpan={this.props.data.header.length}>
						&nbsp;
					</th>
				</tr>,
				<tr
					key={this.props.data.body.length + 2}
					style={{ backgroundColor: "unset", textAlign: "center", cursor: "default" }}
				>
					<th key={0} colSpan={this.props.data.header.length} rowSpan={2} style={{ borderTop: "none" }}>
						{this.props.extra}
					</th>
				</tr>,
			])
		}
		return body
	}

	renderRow(row, rKey) {
		return (
			<tr
				key={rKey}
				style={{
					backgroundColor:
						this.props.data.rowColor && this.props.data.rowColor.length > 0 && this.props.data.rowColor[rKey][0]
							? this.props.data.rowColor[rKey][1]
							: "",
				}}
				onClick={() => this.onClick(row, rKey)}
				onDoubleClick={() => this.onDoubleClick(row, rKey)}
				// onMouseOver={() => this.onMouseOver(row, rKey)}
				onContextMenu={(e) => this.onContextMenu(row, rKey, e)}
			>
				{row.map((col, cKey) => {
					if (typeof col === "boolean" || Array.isArray(col)) {
						if (this.props.displayCheckBox) {
							return (
								<td key={cKey}>
									<Checkbox
										style={{ flexDirection: "row" }}
										disabled={
											_.isEmpty(this.props.isCheckBoxDisabledArray)
												? this.props.isCheckBoxDisabled
												: this.props.isCheckBoxDisabledArray[rKey]
										}
										onChange={(event) => this.props.onChecked(event, row, rKey, cKey)}
									/>
								</td>
							)
						} else if (this.props.displayBtn) {
							if (_.isEmpty(this.props.btnIcons)) {
								if (col) {
									return (
										<td key={cKey}>
											<Button
												style={{ borderColor: "transparent" }}
												variant={this.props.buttonColor}
												size={ResourceAssistance.Button.size.lg}
												disabled={this.props.isCheckBoxDisabled}
												onClick={(event) => this.props.onChecked(event, row, rKey, cKey)}
											>
												{this.props.btnIcon && this.props.btnIcon}
												{this.props.btnMessage && translate(this.props.btnMessage)}
											</Button>
										</td>
									)
								} else {
									return undefined
								}
							} else {
								if (Array.isArray(col)) {
									return (
										<td key={cKey}>
											{this.props.btnIcons.map((each, index) => {
												return (
													each &&
													col[index] && (
														<Button
															key={index}
															style={{ borderColor: "transparent" }}
															variant={this.props.buttonColors[index]}
															size={ResourceAssistance.Button.size.lg}
															disabled={this.props.isCheckBoxDisabledArray[index]}
															onClick={(event) => this.props.onCheckedArray[index](event, row, rKey, cKey)}
														>
															{this.props.btnIcons[index] && this.props.btnIcons[index]}
															{this.props.btnMessages[index] && translate(this.props.btnMessages[index])}
														</Button>
													)
												)
											})}
										</td>
									)
								} else if (col) {
									return (
										<td key={cKey}>
											{this.props.btnIcons.map((each, index) => {
												return (
													each && (
														<Button
															key={index}
															style={{ borderColor: "transparent" }}
															variant={this.props.buttonColors[index]}
															size={ResourceAssistance.Button.size.lg}
															disabled={this.props.isCheckBoxDisabledArray[index]}
															onClick={(event) => this.props.onCheckedArray[index](event, row, rKey, cKey)}
														>
															{this.props.btnIcons[index] && this.props.btnIcons[index]}
															{this.props.btnMessages[index] && translate(this.props.btnMessages[index])}
														</Button>
													)
												)
											})}
										</td>
									)
								} else {
									return undefined
								}
							}
						} else if (this.props.displayAttachment) {
							return <td key={cKey}>{col ? <ImAttachment /> : ""}</td>
						} else {
							if (
								this.props.data.colColor &&
								this.props.data.colColor.length > 0 &&
								this.props.data.colColor[rKey][cKey][0]
							) {
								return (
									<td key={cKey} style={{ backgroundColor: this.props.data.colColor[rKey][cKey][1] }}>
										{col ? "\u2714" : "\u00A0"}
									</td>
								)
							} else {
								return <td key={cKey}>{col ? "\u2714" : "\u00A0"}</td>
							}
						}
					} else {
						if (
							this.props.data.colColor &&
							this.props.data.colColor.length > 0 &&
							this.props.data.colColor[rKey][cKey][0]
						) {
							//display overlay on top of the column.
							if (this.props.enableOverlay && !this.props.overlayMsg && this.showOverlay(cKey, col)) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{col}</Tooltip>}
									>
										<td key={cKey} style={{ backgroundColor: this.props.data.colColor[rKey][cKey][1] }}>
											{col}
										</td>
									</OverlayTrigger>
								)
							}
							//display overlay on selected column
							else if (
								this.props.enableOverlay &&
								!this.props.overlayMsg &&
								this.props.data.overlayMsg &&
								this.props.data.overlayMsg.length > 0 &&
								this.props.data.overlayMsg[rKey][cKey][0]
							) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{this.props.data.overlayMsg[rKey][cKey][1]}</Tooltip>}
									>
										<td key={cKey} style={{ backgroundColor: this.props.data.colColor[rKey][cKey][1] }}>
											{col}
										</td>
									</OverlayTrigger>
								)
							} else {
								return (
									<td key={cKey} style={{ backgroundColor: this.props.data.colColor[rKey][cKey][1] }}>
										{col}
									</td>
								)
							}
						} else if (
							this.props.data.colStyle &&
							this.props.data.colStyle.length > 0 &&
							this.props.data.colStyle[rKey][cKey][0]
						) {
							//display overlay on top of the column.
							if (this.props.enableOverlay && !this.props.overlayMsg && this.showOverlay(cKey, col)) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{col}</Tooltip>}
									>
										<td key={cKey} style={this.props.data.colStyle[rKey][cKey][1]}>
											{col}
										</td>
									</OverlayTrigger>
								)
							}
							//display overlay on selected column
							else if (
								this.props.enableOverlay &&
								!this.props.overlayMsg &&
								this.props.data.overlayMsg &&
								this.props.data.overlayMsg.length > 0 &&
								this.props.data.overlayMsg[rKey][cKey][0]
							) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{this.props.data.overlayMsg[rKey][cKey][1]}</Tooltip>}
									>
										<td key={cKey} style={this.props.data.colStyle[rKey][cKey][1]}>
											{col}
										</td>
									</OverlayTrigger>
								)
							} else {
								return (
									<td key={cKey} style={this.props.data.colStyle[rKey][cKey][1]}>
										{col}
									</td>
								)
							}
						} else {
							if (this.props.enableOverlay && !this.props.overlayMsg && this.showOverlay(cKey, col)) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{col}</Tooltip>}
									>
										<td key={cKey}>{col}</td>
									</OverlayTrigger>
								)
							} else if (
								this.props.enableOverlay &&
								!this.props.overlayMsg &&
								this.props.data.overlayMsg &&
								this.props.data.overlayMsg.length > 0 &&
								this.props.data.overlayMsg[rKey][cKey][0]
							) {
								return (
									<OverlayTrigger
										placement="auto"
										key={cKey}
										onEntering={(event) => this.onTooltipEntering(event, rKey, cKey)}
										overlay={<Tooltip>{this.props.data.overlayMsg[rKey][cKey][1]}</Tooltip>}
									>
										<td key={cKey}>{col}</td>
									</OverlayTrigger>
								)
							} else {
								return <td key={cKey}>{col}</td>
							}
						}
					}
				})}
			</tr>
		)
	}

	render() {
		return (
			<div
				id={this.state.componentName}
				className={this.props.className}
				ref={this.ScrollableTableSizeRef}
				style={this.props.style}
			>
				<Table
					striped={this.props.striped}
					bordered={this.props.bordered}
					borderless={this.props.borderless}
					hover={this.props.hover}
					size={this.props.size}
					variant={this.props.variant}
					responsive={this.props.responsive}
					ref={this.ref}
				>
					<thead>
						<tr>{this.getHeader()}</tr>
					</thead>
					<tbody>{this.getBody()}</tbody>
				</Table>
			</div>
		)
	}
}

ScrollableTable.propTypes = {
	bordered: PropTypes.bool,
	borderless: PropTypes.bool,
	buttonColor: PropTypes.string,
	buttonColors: PropTypes.arrayOf(PropTypes.string),
	btnMessages: PropTypes.arrayOf(PropTypes.string),
	btnIcons: PropTypes.array,
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	displayCheckBox: PropTypes.bool,
	displayAttachment: PropTypes.bool,
	enableHighlight: PropTypes.bool,
	enableOverlay: PropTypes.bool,
	highlightedRow: PropTypes.number,
	hover: PropTypes.bool,
	isCheckBoxDisabled: PropTypes.bool,
	isCheckBoxDisabledArray: PropTypes.arrayOf(PropTypes.bool),
	isClearHighlight: PropTypes.bool,
	onChecked: PropTypes.func,
	onCheckedArray: PropTypes.arrayOf(PropTypes.func),
	onClick: PropTypes.func,
	onDoubleClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	onTooltipEntering: PropTypes.func,
	onContextMenu: PropTypes.func,
	overlayMsg: PropTypes.string,
	responsive: PropTypes.bool || PropTypes.string,
	size: PropTypes.string,
	striped: PropTypes.bool,
	variant: PropTypes.string,
	style: PropTypes.object,
}

ScrollableTable.defaultProps = {
	disabled: false,
	striped: false,
	data: { header: [], body: [] },
	isCheckBoxDisabled: false,
	enableOverlay: false,
	overlayMsg: "",
	style: {},
	onCheckedArray: [],
	isCheckBoxDisabledArray: [],
	btnMessages: [],
	btnIcons: [],
	buttonColors: [],
}

export default ScrollableTable
