import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setTfModalRequestSelectedToBranch,
	setTfModalRequestSelectedToLocation,
	setTfModalRequestSelectedToOrg,
	setTfModalRequestToBranches,
	setTfModalRequestToLocations,
} from "~/redux/action"
import { Utils } from "~/utils/Utils"

class TFModalNRLocationRow extends React.Component {
	constructor(props) {
		super(props)
		this.onOrgChange = this.onOrgChange.bind(this)
		this.onBranchChange = this.onBranchChange.bind(this)
		this.onLocationChange = this.onLocationChange.bind(this)
	}

	componentWillUnmount() {
		this.props.setTfModalRequestSelectedToOrg(-1, null)
		this.props.setTfModalRequestSelectedToBranch(-1, null)
		this.props.setTfModalRequestSelectedToLocation(-1, null)
	}

	componentDidMount() {
		//setup selected org
		if (this.props.transfer.newRequestModal.toOrgs.length > 0) {
			let selectedOrg = this.props.transfer.newRequestModal.selectedToOrg
			let org = document.getElementById(ResourceAssistance.ID.TF.modalRequest.toOrgs)
			let orgIdx = selectedOrg.location ? selectedOrg.index : org.selectedIndex
			this.props.setTfModalRequestSelectedToOrg(orgIdx, this.props.transfer.newRequestModal.toOrgs[orgIdx])
			org.selectedIndex = orgIdx
		}

		//setup selected branch
		if (this.props.transfer.newRequestModal.toBranches.length > 0) {
			let selectedBranch = this.props.transfer.newRequestModal.selectedToBranch
			let branch = document.getElementById(ResourceAssistance.ID.TF.modalRequest.toBranches)
			let branchIdx = selectedBranch.location ? selectedBranch.index : branch.selectedIndex
			this.props.setTfModalRequestSelectedToBranch(branchIdx, this.props.transfer.newRequestModal.toBranches[branchIdx])
			branch.selectedIndex = branchIdx
		}

		//setup selected location
		if (this.props.transfer.newRequestModal.toLocations.length > 0) {
			let selectedLocation = this.props.transfer.newRequestModal.selectedToLocation
			let location = document.getElementById(ResourceAssistance.ID.TF.modalRequest.toLocations)
			let locationIdx = selectedLocation.location ? selectedLocation.index : location.selectedIndex
			this.props.setTfModalRequestSelectedToLocation(
				locationIdx,
				this.props.transfer.newRequestModal.toLocations[locationIdx]
			)
			location.selectedIndex = locationIdx
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//setup to branches
		if (
			JSON.stringify(prevProps.transfer.newRequestModal.selectedToOrg) !==
			JSON.stringify(this.props.transfer.newRequestModal.selectedToOrg)
		) {
			this.props.setTfModalRequestToBranches(this.props.transfer.newRequestModal.selectedToOrg.location.locations)
		}
		if (
			JSON.stringify(prevProps.transfer.newRequestModal.toBranches) !==
			JSON.stringify(this.props.transfer.newRequestModal.toBranches)
		) {
			let branch = document.getElementById(ResourceAssistance.ID.TF.modalRequest.toBranches)
			if (branch.selectedIndex > -1) {
				this.props.setTfModalRequestSelectedToBranch(
					branch.selectedIndex,
					this.props.transfer.newRequestModal.toOrgs[this.props.transfer.newRequestModal.selectedToOrg.index].locations[
						branch.selectedIndex
					]
				)
			} else {
				this.props.setTfModalRequestSelectedToBranch(-1, null)
			}
		}

		//setup to locations
		if (
			JSON.stringify(prevProps.transfer.newRequestModal.selectedToBranch) !==
			JSON.stringify(this.props.transfer.newRequestModal.selectedToBranch)
		) {
			if (this.props.transfer.newRequestModal.selectedToBranch.location) {
				this.props.setTfModalRequestToLocations(this.props.transfer.newRequestModal.selectedToBranch.location.locations)
			} else {
				this.props.setTfModalRequestToLocations([])
			}
		}
		if (
			JSON.stringify(prevProps.transfer.newRequestModal.toLocations) !==
			JSON.stringify(this.props.transfer.newRequestModal.toLocations)
		) {
			let location = document.getElementById(ResourceAssistance.ID.TF.modalRequest.toLocations)
			if (location.selectedIndex > -1) {
				this.props.setTfModalRequestSelectedToLocation(
					location.selectedIndex,
					this.props.transfer.newRequestModal.selectedToBranch.location.locations[location.selectedIndex]
				)
			} else {
				this.props.setTfModalRequestSelectedToLocation(-1, null)
			}
		}
	}

	onOrgChange(event) {
		this.props.setTfModalRequestSelectedToOrg(
			event.target.value,
			this.props.transfer.newRequestModal.toOrgs[event.target.value]
		)
	}

	onBranchChange(event) {
		this.props.setTfModalRequestSelectedToBranch(
			event.target.value,
			this.props.transfer.newRequestModal.selectedToOrg.location.locations[event.target.value]
		)
	}

	onLocationChange(event) {
		this.props.setTfModalRequestSelectedToLocation(
			event.target.value,
			this.props.transfer.newRequestModal.selectedToBranch.location.locations[event.target.value]
		)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.to)}</legend>
						<Row>
							<Col md="auto">
								<FormLabel size={ResourceAssistance.FormControl.size.sm}>
									{translate(ResourceAssistance.Message.org)}
								</FormLabel>
							</Col>
							<Col>
								<FormControl
									required
									id={ResourceAssistance.ID.TF.modalRequest.toOrgs}
									size={ResourceAssistance.FormControl.size.sm}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onOrgChange}
								>
									{Utils.renderOptions(this.props.transfer.newRequestModal.toOrgs, false)}
								</FormControl>
							</Col>
							<Col md="auto">
								<FormLabel size={ResourceAssistance.FormControl.size.sm}>
									{translate(ResourceAssistance.Message.branch)}
								</FormLabel>
							</Col>
							<Col>
								<FormControl
									required
									id={ResourceAssistance.ID.TF.modalRequest.toBranches}
									size={ResourceAssistance.FormControl.size.sm}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onBranchChange}
								>
									{Utils.renderOptions(this.props.transfer.newRequestModal.toBranches, false)}
								</FormControl>
							</Col>
							<Col md="auto">
								<FormLabel size={ResourceAssistance.FormControl.size.sm}>
									{translate(ResourceAssistance.Message.location)}
								</FormLabel>
							</Col>
							<Col>
								<FormControl
									required
									id={ResourceAssistance.ID.TF.modalRequest.toLocations}
									size={ResourceAssistance.FormControl.size.sm}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onLocationChange}
								>
									{Utils.renderOptions(this.props.transfer.newRequestModal.toLocations, false)}
								</FormControl>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	isShow: state.modal.isTFNewRequestDisplay,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfModalRequestSelectedToOrg,
			setTfModalRequestToBranches,
			setTfModalRequestSelectedToBranch,
			setTfModalRequestToLocations,
			setTfModalRequestSelectedToLocation,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNRLocationRow)
