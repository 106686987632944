import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_OPD_CERTIFICATES,
	SET_OPD_DOCTOR_ORDERS,
	SET_OPD_GCS,
	SET_OPD_ICD_TYPES,
	SET_OPD_INTAKE_OUTPUTS,
	SET_OPD_INTAKE_TYPES,
	SET_OPD_LEVELS,
	SET_OPD_LOCATION,
	SET_OPD_MEDICAL_RECORDS,
	SET_OPD_MEDICAL_RECORD_LOADING,
	SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND,
	SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME,
	SET_OPD_MODAL_ADJUSTMENT_NOTES,
	SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME,
	SET_OPD_MODAL_APPOINTMENT_DATE_TIME,
	SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME,
	SET_OPD_MODAL_APPOINTMENT_PATIENTS,
	SET_OPD_MODAL_APPOINTMENT_SELECTED_PATIENT,
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION,
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME,
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME,
	SET_OPD_MODAL_DOCTOR_ORDER_DURATION,
	SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY,
	SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME,
	SET_OPD_MODAL_DOCTOR_ORDER_ITEMS,
	SET_OPD_MODAL_DOCTOR_ORDER_ITEM_LOADING,
	SET_OPD_MODAL_DOCTOR_ORDER_NOTES,
	SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
	SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES,
	SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK,
	SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT,
	SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME,
	SET_OPD_MODAL_DOCTOR_ORDER_TYPES,
	SET_OPD_MODAL_DOCTOR_ORDER_UNITS,
	SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES,
	SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
	SET_OPD_MODAL_DOCTOR_SERVICE_SEARCH_NAME,
	SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY,
	SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE,
	SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE,
	SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES,
	SET_OPD_MODAL_LOCATION_ORGS,
	SET_OPD_MODAL_LOCATION_SELECTED_BRANCH,
	SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_OPD_MODAL_LOCATION_SELECTED_ORG,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_BRANCHES,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_ORGS,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_START,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_ROLES,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS,
	SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN,
	SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES,
	SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_CC,
	SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS,
	SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN,
	SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION,
	SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_GA,
	SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT,
	SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION,
	SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE,
	SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES,
	SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL,
	SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS,
	SET_OPD_MODAL_MEDICAL_RECORD_PI,
	SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE,
	SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT,
	SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE,
	SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT,
	SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME,
	SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME,
	SET_OPD_MODAL_NURSE_ASSESSMENT,
	SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER,
	SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES,
	SET_OPD_MODAL_NURSE_DATETIME,
	SET_OPD_MODAL_NURSE_EVALUATION,
	SET_OPD_MODAL_NURSE_FOCUS,
	SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY,
	SET_OPD_MODAL_NURSE_INTERVENTION,
	SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY,
	SET_OPD_MODAL_NURSE_ORDER_ITEMS,
	SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS,
	SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME,
	SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM,
	SET_OPD_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM,
	SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE,
	SET_OPD_MODAL_NURSE_ORDER_TYPES,
	SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES,
	SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES,
	SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME,
	SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY,
	SET_OPD_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE,
	SET_OPD_MODAL_NURSE_SERVICE_SELECTED_SERVICE,
	SET_OPD_MODAL_NURSE_SERVICE_SERVICES,
	SET_OPD_MODAL_VITAL_SIGN_BP,
	SET_OPD_MODAL_VITAL_SIGN_DATETIME,
	SET_OPD_MODAL_VITAL_SIGN_OXYGEN,
	SET_OPD_MODAL_VITAL_SIGN_PR,
	SET_OPD_MODAL_VITAL_SIGN_RR,
	SET_OPD_MODAL_VITAL_SIGN_TEMPERATURE,
	SET_OPD_NURSE_NOTES,
	SET_OPD_NURSE_ORDERS,
	SET_OPD_OUTPUT_TYPES,
	SET_OPD_PAIN_SCALE,
	SET_OPD_PATIENTS,
	SET_OPD_PATIENTS_LOADING,
	SET_OPD_PUPIL_SIZE,
	SET_OPD_SEARCH_END_DATETIME,
	SET_OPD_SEARCH_IDENTIFICATION,
	SET_OPD_SEARCH_PID,
	SET_OPD_SEARCH_START_DATETIME,
	SET_OPD_SELECTED_DOCTOR_ORDER,
	SET_OPD_SELECTED_GCS,
	SET_OPD_SELECTED_INTAKE_OUTPUT,
	SET_OPD_SELECTED_MEDICAL_CERTIFICATE,
	SET_OPD_SELECTED_MEDICAL_RECORD,
	SET_OPD_SELECTED_NURSE_NOTE,
	SET_OPD_SELECTED_NURSE_ORDER,
	SET_OPD_SELECTED_PAIN_SCALE,
	SET_OPD_SELECTED_PAITENT,
	SET_OPD_SELECTED_PUPIL_SIZE,
	SET_OPD_SELECTED_VITAL_SIGN,
	SET_OPD_VITAL_SIGNS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	location: null,
	startDateTime: moment().startOf("day").valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	searchPID: "",
	searchIdentification: "",
	icdTypes: [],
	levels: [],
	intakes: [],
	outputs: [],
	selectedPatient: {
		index: -1,
		patient: null,
	},
	selectedMedicalRecord: {
		index: -1,
		medicalRecord: null,
	},
	selectedDoctorOrder: {
		index: -1,
		order: null,
	},
	selectedNurseOrder: {
		index: -1,
		order: null,
	},
	selectedNurseNote: {
		index: -1,
		nurseNote: null,
	},
	selectedVitalSign: {
		index: -1,
		vitalSign: null,
	},
	selectedCertificate: {
		index: -1,
		certificate: null,
	},
	selectedIO: {
		index: -1,
		io: null,
	},
	selectedGCS: {
		index: -1,
		gcs: null,
	},
	selectedPainScale: {
		index: -1,
		painScale: null,
	},
	selectedPupilSize: {
		index: -1,
		pupilSize: null,
	},
	patientTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.seq,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Message.firstName,
			ResourceAssistance.Message.lastName,
			ResourceAssistance.Hospitel.dateOfBirth,
			ResourceAssistance.Hospitel.identification,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	medicalRecordTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.appointmentDate,
			ResourceAssistance.Message.checkInDate,
			ResourceAssistance.Message.checkOutDate,
			ResourceAssistance.Message.location,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	doctorOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.by,
		],
		body: [],
		rowColor: [],
	},
	nurseOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.qtyPerDay,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	certificateTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.symptoms,
			ResourceAssistance.Hospitel.diagnosis,
			ResourceAssistance.Hospitel.doctorOpinion,
			ResourceAssistance.Hospitel.doctorName,
			ResourceAssistance.Hospitel.licenseNo,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	nurseNoteTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.focus,
			ResourceAssistance.Hospitel.assessment,
			ResourceAssistance.Hospitel.intervention,
			ResourceAssistance.Hospitel.evaluation,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
	},
	vitalSignTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.temperature,
			ResourceAssistance.Message.prPerMin,
			ResourceAssistance.Message.rrPerMin,
			ResourceAssistance.Message.bpmmHg,
			ResourceAssistance.Message.o2Sat,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
	},
	intakeOutputTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.intake,
			ResourceAssistance.Message.amountML,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.output,
			ResourceAssistance.Message.amountML,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
	},
	gcsTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.eyeOpeningResponse,
			ResourceAssistance.Message.verbalResponse,
			ResourceAssistance.Message.motorResponse,
			ResourceAssistance.Message.score,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	painScaleTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.score,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
	},
	pupilSizeTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.leftEyePupilSizeMM,
			ResourceAssistance.Message.leftEyeReactionToLight,
			ResourceAssistance.Message.rightEyePupilSizeMM,
			ResourceAssistance.Message.rightEyeReactionToLight,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
	},
	modalLocation: {
		orgs: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedDepartment: {
			index: -1,
			department: null,
		},
	},
	modalAppointment: {
		dateTime: "",
		selectedPatient: {
			index: -1,
			patient: null,
		},
		patientTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Hospitel.dateOfBirth,
				ResourceAssistance.Hospitel.identification,
			],
			body: [],
		},
	},
	modalAppointmentEdit: {
		dateTime: "",
	},
	modalMedicalRecord: {
		isAdmissionPlanDisplay: false,
		isMenstruationDisplay: false,
		height: "",
		weight: "",
		underlyingDisease: ResourceAssistance.CONSTANT.NONE,
		allergies: ResourceAssistance.CONSTANT.NONE,
		pastIllnesses: ResourceAssistance.CONSTANT.NONE,
		pastSurgicalHistory: ResourceAssistance.CONSTANT.NONE,
		familyHealthHistory: ResourceAssistance.CONSTANT.NONE,
		accidentHistory: ResourceAssistance.CONSTANT.NONE,
		medicineHistory: ResourceAssistance.CONSTANT.NONE,
		supplementOrHerbsHistory: ResourceAssistance.CONSTANT.NONE,
		alcoholHistory: ResourceAssistance.CONSTANT.NONE,
		tobaccoHistory: ResourceAssistance.CONSTANT.NONE,
		passiveSmokeExposure: ResourceAssistance.CONSTANT.NONE,
		riskOfAbuse: ResourceAssistance.CONSTANT.NONE,
		menstruation: "",
		admissionPlan: "",
		doctorNote: ResourceAssistance.CONSTANT.NONE,
		GA: ResourceAssistance.CONSTANT.NONE,
		CC: "",
		PI: "",
		diagnosis: "",
		treatment: "",
		physicalExaminationOthers: ResourceAssistance.CONSTANT.NONE,
		HEENT: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		heart: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		lung: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		abdomen: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		back: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		extremities: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
		neuro: {
			appearance: ResourceAssistance.CONSTANT.NORMAL,
			note: "",
		},
	},
	modalDoctorOrder: {
		startDateTime: moment().valueOf(),
		endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		searchDescription: "",
		isPRN: false,
		notes: "",
		durationQty: "",
		duration: "",
		types: [],
		units: [],
		pharmacyInventories: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		selectedDuration: {
			index: -1,
			duration: null,
		},
		selectedUnit: {
			index: -1,
			unit: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.genericName,
				ResourceAssistance.Message.tradeName,
				ResourceAssistance.Message.unit,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.order,
				ResourceAssistance.Message.tradeName,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalModifyRange: {
		startDateTime: undefined,
		endDateTime: undefined,
	},
	modalDoctorInstruction: {
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		description: "",
	},
	modalCertificate: {
		examinationStartDateTime: moment().startOf("day").valueOf(),
		examinationEndDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		symptom: "",
		diagnosis: "",
		doctorOpinion: "",
		startDateTime: moment().startOf("day").valueOf(),
		endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		bloodTestChecked: false,
		radiologyChecked: false,
		bloodTestDetails: "",
		radiologyDetails: "",
		investigation: "",
		treatment: "",
		orgs: [],
		branches: [],
		categories: [],
		services: [],
		roles: [],
		users: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedRole: {
			index: -1,
			role: null,
		},
		selectedUser: {
			index: -1,
			user: null,
		},
	},
	modalDoctorService: {
		searchName: "",
		categories: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalNurse: {
		recordDateTime: moment().valueOf(),
		focus: "",
		assessment: ResourceAssistance.CONSTANT.ASYMPTOMATIC,
		assessmentOther: "",
		intervention: "",
		evaluation: ResourceAssistance.CONSTANT.DEFAULT_NURSE_EVALUATION,
		categories: [],
		selectedFocusCategory: {
			index: -1,
			category: null,
		},
		selectedInterventionCategory: {
			index: -1,
			category: null,
		},
	},
	modalVitalSign: {
		dateTime: moment().valueOf(),
		temperature: "",
		pr: "",
		rr: "",
		bp: "",
		oxygen: "",
	},
	modalNurseOrder: {
		searchName: "",
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
			],
			body: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.qtyPerDay,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalAdjustment: {
		isDisableEndDate: true,
		qtyPerDay: "",
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		notes: "",
	},
	modalNurseService: {
		searchName: "",
		categories: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
}

const outpatientReducer = (state = init, action) => {
	switch (action.type) {
		case SET_OPD_SEARCH_PID:
			return Object.assign({}, state, {
				searchPID: action.payload.text,
			})

		case SET_OPD_SEARCH_IDENTIFICATION:
			return Object.assign({}, state, {
				searchIdentification: action.payload.text,
			})

		case SET_OPD_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_LOCATION_SELECTED_ORG: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		}

		case SET_OPD_MODAL_LOCATION_SELECTED_BRANCH: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		}

		case SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedDepartment: {
						index: action.payload.index,
						department: action.payload.selected,
					},
				},
			})
		}

		case SET_OPD_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})

		case SET_OPD_PATIENTS:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_OPD_PATIENTS_LOADING:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_OPD_MEDICAL_RECORD_LOADING:
			return Object.assign({}, state, {
				medicalRecordTable: {
					...state.medicalRecordTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_OPD_SELECTED_PAITENT:
			return Object.assign({}, state, {
				selectedPatient: {
					index: action.payload.index,
					patient: action.payload.selected,
				},
			})

		case SET_OPD_MEDICAL_RECORDS:
			return Object.assign({}, state, {
				medicalRecordTable: {
					...state.medicalRecordTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_OPD_SELECTED_MEDICAL_RECORD:
			return Object.assign({}, state, {
				selectedMedicalRecord: {
					index: action.payload.index,
					medicalRecord: action.payload.selected,
				},
			})

		case SET_OPD_MODAL_APPOINTMENT_PATIENTS:
			return Object.assign({}, state, {
				modalAppointment: {
					...state.modalAppointment,
					patientTable: {
						...state.modalAppointment.patientTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_OPD_MODAL_APPOINTMENT_DATE_TIME:
			return Object.assign({}, state, {
				modalAppointment: {
					...state.modalAppointment,
					dateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_APPOINTMENT_SELECTED_PATIENT:
			return Object.assign({}, state, {
				modalAppointment: {
					...state.modalAppointment,
					selectedPatient: {
						index: action.payload.index,
						patient: action.payload.selected,
					},
				},
			})

		case SET_OPD_SEARCH_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})

		case SET_OPD_SEARCH_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_GA:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					GA: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_HEENT_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					HEENT: {
						...state.modalMedicalRecord.HEENT,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_HEENT_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					HEENT: {
						...state.modalMedicalRecord.HEENT,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_HEART_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					heart: {
						...state.modalMedicalRecord.heart,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_HEART_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					heart: {
						...state.modalMedicalRecord.heart,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_LUNG_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					lung: {
						...state.modalMedicalRecord.lung,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_LUNG_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					lung: {
						...state.modalMedicalRecord.lung,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					abdomen: {
						...state.modalMedicalRecord.abdomen,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_ABDOMEN_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					abdomen: {
						...state.modalMedicalRecord.abdomen,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_BACK_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					back: {
						...state.modalMedicalRecord.back,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_BACK_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					back: {
						...state.modalMedicalRecord.back,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					extremities: {
						...state.modalMedicalRecord.extremities,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_EXTREMITIES_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					extremities: {
						...state.modalMedicalRecord.extremities,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_NEURO_APPEARANCE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					neuro: {
						...state.modalMedicalRecord.neuro,
						appearance: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_NEURO_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					neuro: {
						...state.modalMedicalRecord.neuro,
						note: action.payload.value,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_PHYSICAL_EXAMINATION_OTHERS:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					physicalExaminationOthers: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_CC:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					CC: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_PI:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					PI: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					diagnosis: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					treatment: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_ADMISSION_PLAN:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					isAdmissionPlanDisplay: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_DISPLAY_MENSTRUATION:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					isMenstruationDisplay: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					admissionPlan: action.payload.value,
				},
			})

		case SET_OPD_NURSE_NOTES:
			return Object.assign({}, state, {
				nurseNoteTable: {
					...state.nurseNoteTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_OPD_SELECTED_NURSE_NOTE:
			return Object.assign({}, state, {
				selectedNurseNote: {
					index: action.payload.index,
					nurseNote: action.payload.selected,
				},
			})

		case SET_OPD_VITAL_SIGNS:
			return Object.assign({}, state, {
				vitalSignTable: {
					...state.vitalSignTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_OPD_INTAKE_OUTPUTS:
			return Object.assign({}, state, {
				intakeOutputTable: {
					...state.intakeOutputTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_OPD_DOCTOR_ORDERS:
			return Object.assign({}, state, {
				doctorOrderTable: {
					...state.doctorOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_OPD_NURSE_ORDERS:
			return Object.assign({}, state, {
				nurseOrderTable: {
					...state.nurseOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_OPD_CERTIFICATES:
			return Object.assign({}, state, {
				certificateTable: {
					...state.certificateTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_OPD_MODAL_DOCTOR_ORDER_TYPES:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					types: action.payload.objs,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					searchDescription: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_ITEMS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					itemTable: {
						...state.modalDoctorOrder.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_ITEM_LOADING:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					itemTable: {
						...state.modalDoctorOrder.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_UNITS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					units: action.payload.objs,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					pendingItemTable: {
						...state.modalDoctorOrder.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					startDateTime: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					endDateTime: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_NOTES:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					notes: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					durationQty: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_DURATION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					duration: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedDuration: {
						index: action.payload.index,
						duration: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedUnit: {
						index: action.payload.index,
						unit: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					isPRN: action.payload.value,
				},
			})
		case SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					pharmacyInventories: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					categories: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_SEARCH_NAME:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					searchName: action.payload.value,
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					serviceTable: {
						...state.modalDoctorService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					pendingServiceTable: {
						...state.modalDoctorService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME:
			return Object.assign({}, state, {
				modalDoctorInstruction: {
					...state.modalDoctorInstruction,
					startDateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME:
			return Object.assign({}, state, {
				modalDoctorInstruction: {
					...state.modalDoctorInstruction,
					endDateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION:
			return Object.assign({}, state, {
				modalDoctorInstruction: {
					...state.modalDoctorInstruction,
					description: action.payload.value,
				},
			})
		case SET_OPD_SELECTED_DOCTOR_ORDER:
			return Object.assign({}, state, {
				selectedDoctorOrder: {
					index: action.payload.index,
					order: action.payload.selected,
				},
			})

		case SET_OPD_MODAL_MODIFY_RANGE_START_DATETIME:
			return Object.assign({}, state, {
				modalModifyRange: {
					...state.modalModifyRange,
					startDateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MODIFY_RANGE_END_DATETIME:
			return Object.assign({}, state, {
				modalModifyRange: {
					...state.modalModifyRange,
					endDateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					diagnosis: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					symptom: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					doctorOpinion: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_START:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					startDateTime: action.payload.date,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_REST_END:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					endDateTime: action.payload.date,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_ORGS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					orgs: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_BRANCHES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					branches: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					categories: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					services: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					bloodTestChecked: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					bloodTestDetails: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					radiologyChecked: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					radiologyDetails: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					investigation: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					treatment: action.payload.value,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_ROLES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					roles: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_USERS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					users: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedRole: {
						index: action.payload.index,
						role: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedUser: {
						index: action.payload.index,
						user: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					examinationEndDateTime: action.payload.date,
				},
			})

		case SET_OPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					examinationStartDateTime: action.payload.date,
				},
			})

		case SET_OPD_SELECTED_MEDICAL_CERTIFICATE:
			return Object.assign({}, state, {
				selectedCertificate: {
					index: action.payload.index,
					certificate: action.payload.selected,
				},
			})

		case SET_OPD_MODAL_NURSE_DATETIME:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					recordDateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_FOCUS:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					focus: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_FOCUS_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					selectedFocusCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					categories: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_NURSE_ASSESSMENT:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					assessment: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_ASSESSMENT_OTHER:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					assessmentOther: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_EVALUATION:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					evaluation: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_INTERVENTION:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					intervention: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurse: {
					...state.modalNurse,
					selectedInterventionCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})

		case SET_OPD_SELECTED_VITAL_SIGN:
			return Object.assign({}, state, {
				selectedVitalSign: {
					index: action.payload.index,
					vitalSign: action.payload.selected,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_DATETIME:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					dateTime: action.payload.value,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_BP:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					bp: action.payload.value,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_OXYGEN:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					oxygen: action.payload.value,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_PR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					pr: action.payload.value,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_RR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					rr: action.payload.value,
				},
			})

		case SET_OPD_MODAL_VITAL_SIGN_TEMPERATURE:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					temperature: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_ITEMS:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					itemTable: {
						...state.modalNurseOrder.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_TYPES:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					types: action.payload.objs,
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					searchName: action.payload.value,
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					pendingItemTable: {
						...state.modalNurseOrder.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_OPD_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})

		case SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isDisableEndDate: action.payload.value,
				},
			})
		case SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					endDateTime: action.payload.value,
				},
			})
		case SET_OPD_MODAL_ADJUSTMENT_NOTES:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					notes: action.payload.value,
				},
			})
		case SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					qtyPerDay: action.payload.value,
				},
			})
		case SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					startDateTime: action.payload.value,
				},
			})
		case SET_OPD_SELECTED_NURSE_ORDER:
			return Object.assign({}, state, {
				selectedNurseOrder: {
					index: action.payload.index,
					order: action.payload.selected,
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					categories: action.payload.objs,
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_SEARCH_NAME:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					searchName: action.payload.value,
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					serviceTable: {
						...state.modalNurseService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					pendingServiceTable: {
						...state.modalNurseService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OPD_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME:
			return Object.assign({}, state, {
				modalAppointmentEdit: {
					...state.modalAppointmentEdit,
					dateTime: action.payload.value,
				},
			})
		case SET_OPD_ICD_TYPES:
			return Object.assign({}, state, {
				icdTypes: action.payload.objs,
			})
		case SET_OPD_LEVELS:
			return Object.assign({}, state, {
				levels: action.payload.objs,
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					allergies: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					height: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					pastIllnesses: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					underlyingDisease: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					weight: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					pastSurgicalHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					familyHealthHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					accidentHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					medicineHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					supplementOrHerbsHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					alcoholHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					tobaccoHistory: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					passiveSmokeExposure: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_RISK_OF_ABUSE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					riskOfAbuse: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_MENSTRUATION:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					menstruation: action.payload.value,
				},
			})
		case SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE:
			return Object.assign({}, state, {
				modalMedicalRecord: {
					...state.modalMedicalRecord,
					doctorNote: action.payload.value,
				},
			})
		case SET_OPD_INTAKE_TYPES:
			return Object.assign({}, state, {
				intakes: action.payload.objs,
			})
		case SET_OPD_OUTPUT_TYPES:
			return Object.assign({}, state, {
				outputs: action.payload.objs,
			})
		case SET_OPD_SELECTED_INTAKE_OUTPUT:
			return Object.assign({}, state, {
				selectedIO: {
					index: action.payload.index,
					io: action.payload.selected,
				},
			})
		case SET_OPD_GCS:
			return Object.assign({}, state, {
				gcsTable: {
					...state.gcsTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_OPD_SELECTED_GCS:
			return Object.assign({}, state, {
				selectedGCS: {
					index: action.payload.index,
					gcs: action.payload.selected,
				},
			})
		case SET_OPD_SELECTED_PAIN_SCALE:
			return Object.assign({}, state, {
				selectedPainScale: {
					index: action.payload.index,
					painScale: action.payload.selected,
				},
			})
		case SET_OPD_PAIN_SCALE:
			return Object.assign({}, state, {
				painScaleTable: {
					...state.painScaleTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_OPD_PUPIL_SIZE:
			return Object.assign({}, state, {
				pupilSizeTable: {
					...state.pupilSizeTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_OPD_SELECTED_PUPIL_SIZE:
			return Object.assign({}, state, {
				selectedPupilSize: {
					index: action.payload.index,
					pupilSize: action.payload.selected,
				},
			})
		default:
			return state
	}
}

export default outpatientReducer
