import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_TYPE_TITLE,
	SET_LOCATIONS,
	SET_LOCATION_DISPLAY_INACTIVE,
	SET_LOC_PARENT_LOCS,
	SET_LOC_PARENT_ORGS,
	SET_LOC_SEARCH_TEXT,
	SET_PR_TYPES,
	SET_SELECTED_LOC,
	SET_SELECTED_TYPE,
	SET_TYPE_URL,
} from "../../type/type/ADMIN"
export * from "./formActions"
export * from "./itemActions"
export * from "./itemSourceActions"
export * from "./locationActions"
export * from "./permissionActions"
export * from "./roleActions"
export * from "./serviceActions"
export * from "./supplierActions"
export * from "./taxActions"
export * from "./unitActions"
export * from "./userActions"
export * from "./customDefaultActions"
export * from "./typeActions"
export * from "./icdActions"
export * from "./laboratoryActions"

const ORG = "ORG"
const BRANCH = "BRANCH"
const INVENTORY = "INVENTORY"
const GENERAL_LOCATION = "GENERAL_LOCATION"
const IPD = "IPD"
const OPD = "OPD"

export const setAdminTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) =>
			type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase())
		)

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [type.displayName, type.description, type.createdBy.nickname, Utils.formatDate(type.creationDateTime)]
		})
		dispatch({
			type: SET_PR_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setSelectedType = (index, type) => {
	return {
		type: SET_SELECTED_TYPE,
		payload: {
			type: type,
			index: index,
		},
	}
}

export const setTypeUrl = (url) => {
	return {
		type: SET_TYPE_URL,
		payload: {
			url: url,
		},
	}
}

export const setLocation = (locations) => {
	return (dispatch, getState) => {
		let filtered = locations.filter((location) =>
			location.displayName.toLowerCase().includes(getState().admin.location.searchText.toLowerCase())
		)

		if (!getState().admin.location.displayInactive) {
			filtered = filtered.filter((location) => location.active)
		}

		if (!getState().admin.location.displayOrg) {
			filtered = filtered.filter((location) => location.code.displayName !== ORG)
		}

		if (!getState().admin.location.displayBranch) {
			filtered = filtered.filter((location) => location.code.displayName !== BRANCH)
		}

		if (!getState().admin.location.displayInventory) {
			filtered = filtered.filter((location) => location.code.displayName !== INVENTORY)
		}

		if (!getState().admin.location.displayLocation) {
			filtered = filtered.filter(
				(location) =>
					location.code.displayName !== GENERAL_LOCATION &&
					location.code.displayName !== IPD &&
					location.code.displayName !== OPD
			)
		}

		let header = [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.nameEng,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.address,
			ResourceAssistance.Message.addressEng,
			ResourceAssistance.Message.billingAddress,
			ResourceAssistance.Message.telNo,
			ResourceAssistance.Message.faxNo,
			ResourceAssistance.Message.type,
			ResourceAssistance.Message.parentLoc,
			ResourceAssistance.Message.by,
		]

		let colors = []
		if (getState().admin.location.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((location) => {
			return [
				location.displayName,
				location.displayNameEng,
				location.description,
				location.taxNo,
				location.address ? location.address.displayName : "",
				location.address ? location.address.displayNameEng : "",
				location.address ? location.address.billingAddress : "",
				location.address ? location.address.tel : "",
				location.address ? location.address.fax : "",
				location.code.displayName,
				location.parent ? location.parent.displayName : "",
				location.createdBy.nickname,
			]
		})

		dispatch({
			type: SET_LOCATIONS,
			payload: {
				locations: locations,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setLocationDisplayInactive = (isDisplay) => {
	return {
		type: SET_LOCATION_DISPLAY_INACTIVE,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setSelectedLoc = (index, loc) => {
	return {
		type: SET_SELECTED_LOC,
		payload: {
			loc: loc,
			index: index,
		},
	}
}

export const setLocSearchText = (text) => {
	return {
		type: SET_LOC_SEARCH_TEXT,
		payload: {
			searchText: text,
		},
	}
}

export const setLocationType = (type, value) => {
	return {
		type: type,
		payload: {
			value: value,
		},
	}
}

export const setParentLocs = (locs) => {
	return {
		type: SET_LOC_PARENT_LOCS,
		payload: {
			locs: locs,
		},
	}
}

export const setParentOrgLocs = (locs) => {
	return {
		type: SET_LOC_PARENT_ORGS,
		payload: {
			locs: locs,
		},
	}
}

export const setAdminTypeTitile = (title) => {
	return {
		type: SET_ADMIN_TYPE_TITLE,
		payload: {
			title: title,
		},
	}
}
