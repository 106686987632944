import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import ResourceAssistance from "./../../../../../i18n/resource/ResourceAssistance"

class IPDModalCreatePRNRecordDate extends React.Component {
	constructor(props) {
		super(props)

		this.onDispensingDate = this.onDispensingDate.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME, new Date().getTime())
	}

	onDispensingDate(date) {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME, date.getTime())
	}

	render() {
		return (
			<Row>
				<Col md={2} />
				<Col>
					<FormLabel>{translate(ResourceAssistance.Message.dispensingTime)}</FormLabel>
				</Col>
				<Col>
					<DatePicker
						minDate={Utils.generateDate()}
						maxDate={Utils.generateDate()}
						showDisabledMonthNavigation
						startDate={Utils.generateDateFromLong(this.props.createPRNRecord.dispensingDateTime)}
						onChange={this.onDispensingDate}
					/>
				</Col>
				<Col md={2} />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	createPRNRecord: state.hospitel.hospitel.modalCreatePRNRecord,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalCreatePRNRecordDate))
