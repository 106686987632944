import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY } from "~/redux/type"

class IPDModalCreatePRNRecordGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onQty = this.onQty.bind(this)
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY, 0)
	}

	onQty(event) {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col md={2} />
				<Col>
					<FormLabel>{translate(ResourceAssistance.Message.qty)}</FormLabel>
				</Col>
				<Col>
					<FormControl
						required
						type={ResourceAssistance.FormControl.type.number}
						size={ResourceAssistance.FormControl.size.sm}
						onChange={this.onQty}
					/>
				</Col>
				<Col md={2} />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	createPRNRecord: state.hospitel.hospitel.modalCreatePRNRecord,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalCreatePRNRecordGeneral))
