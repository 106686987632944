import React from "react"
import { Route, Switch } from "react-router"
import { ResourceAssistance } from "~/i18n"
import ExcelDocument from "./component/ExcelDocument"
import ReportAdmissionNoteDocument from "./hospital/document/ReportAdmissionNoteDocument"
import ReportClaimDocument from "./hospital/document/ReportClaimDocument"
import ReportClinicalRecordDocument from "./hospital/document/ReportClinicalRecordDocument"
import ReportDepressionAssessmentDocument from "./hospital/document/ReportDepressionAssessmentDocument"
import ReportDoctorStandingOrderDocument from "./hospital/document/ReportDoctorStandingOrderDocument"
import ReportFirstAssessmentDocument from "./hospital/document/ReportFirstAssessmentDocument"
import ReportGlasgowComaScaleDoc from "./hospital/document/ReportGlasgowComaScaleDoc"
import ReportLabelDocument from "./hospital/document/ReportLabelDocument"
import ReportLetterOfConsentDocument from "./hospital/document/ReportLetterOfConsentDocument"
import ReportMedicationSheetDocument from "./hospital/document/ReportMedicationSheetDocument"
import ReportNurseProgressNoteDocument from "./hospital/document/ReportNurseProgressNoteDocument"
import ReportPainScaleDoc from "./hospital/document/ReportPainScaleDoc"
import ReportPupilSizeDoc from "./hospital/document/ReportPupilSizeDoc"
import ReportVitalSignDocument from "./hospital/document/ReportVitalSignDocument"
import PdfFile from "./hospital/file/PdfFile"
import ReportCashierBillDetailsDoc from "./hospitel/cashier/document/ReportCashierBillDetailsDoc"
import ReportCashierBillDocument from "./hospitel/cashier/document/ReportCashierBillDocument"
import ReportCashierBillingToTodayDoc from "./hospitel/cashier/document/ReportCashierBillingToTodayDoc"
import ReportCashierByUserDoc from "./hospitel/cashier/document/ReportCashierByUserDoc"
import ReportCashierHealthCarePlanReceiptCategoryDoc from "./hospitel/cashier/document/ReportCashierHealthCarePlanReceiptCategoryDoc"
import ReportCashierHealthCarePlanReceiptDoc from "./hospitel/cashier/document/ReportCashierHealthCarePlanReceiptDoc"
import ReportCashierReceiptDocument from "./hospitel/cashier/document/ReportCashierReceiptDocument"
import ReportIPDPatientDocument from "./hospitel/ipd/document/ReportIPDPatientDocument"
import ReportIPDPatientExpenseEstDoc from "./hospitel/ipd/document/ReportIPDPatientExpenseEstDoc"
import ReportCovid19Document from "./hospitel/lab/document/ReportCovid19Document"
import ReportInventoryByLocationDocument from "./inv/document/ReportInventoryByLocationDocument"
import ReportInventoryByLocationValueDocument from "./inv/document/ReportInventoryByLocationValueDocument"
import ReportOPDDoctorOrderDocument from "./opd/doc/ReportOPDDoctorPrescriptionDocument"
import ReportOPDMedicalCertificateDocument from "./opd/doc/ReportOPDMedicalCertificateDocument"
import ReportOPDNurseNoteDocument from "./opd/doc/ReportOPDNurseNoteDocument"
import ReportOPDNurseOrderDocument from "./opd/doc/ReportOPDNurseOrderDocument"
import ReportOPDVitalSignDocument from "./opd/doc/ReportOPDVitalSignDocument"
import ReportPurchaseOrderByIDDocument from "./po/ReportPurchaseOrderByIDDocument"
import ReportPurchaseOrderByLocationDocument from "./po/ReportPurchaseOrderByLocationDocument"
import ReportPurchaseOrderDocument from "./po/ReportPurchaseOrderDocument"
import ReportReceiveDocument from "./rc/ReportReceiveDocument"
import ReportTransferByLocationDocument from "./tf/ReportTransferByLocationDocument"
import ReportTransferDocument from "./tf/ReportTransferDocument"
import ReportIntakeOutputDoc from "./hospital/document/ReportIntakeOutputDoc"
import ReportCashierBillByHealthCarePlanDoc from "./hospitel/cashier/document/ReportCashierBillByHealthCarePlanDoc"
import LabelMedicationDoc from "./hospital/pharmacy/document/LabelMedicationDoc"

class ReportRedirect extends React.Component {
	render() {
		return (
			<Switch>
				{/* Inventory */}
				<Route
					exact
					path={ResourceAssistance.Path.Report.Inv.byLocation.qty}
					component={ReportInventoryByLocationDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Inv.byLocation.value}
					component={ReportInventoryByLocationValueDocument}
				/>

				{/* Transfer */}
				<Route exact path={ResourceAssistance.Path.Report.Tf.tf} component={ReportTransferDocument} />
				<Route exact path={ResourceAssistance.Path.Report.Tf.byLocation} component={ReportTransferByLocationDocument} />

				{/* Purchase Order */}
				<Route exact path={ResourceAssistance.Path.Report.Po.po} component={ReportPurchaseOrderDocument} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.Po.byLocation}
					component={ReportPurchaseOrderByLocationDocument}
				/>
				<Route exact path={ResourceAssistance.Path.Report.Po.byId} component={ReportPurchaseOrderByIDDocument} />

				{/* Receive */}
				<Route exact path={ResourceAssistance.Path.Report.Rc.rc} component={ReportReceiveDocument} />

				{/* Hospital */}
				<Route exact path={ResourceAssistance.Path.Report.Hospital.label} component={ReportLabelDocument} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.doctorOrder}
					component={ReportDoctorStandingOrderDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.nurseNote}
					component={ReportNurseProgressNoteDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.clinicalRecord}
					component={ReportClinicalRecordDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.admissionNote}
					component={ReportAdmissionNoteDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.medicationSheet}
					component={ReportMedicationSheetDocument}
				/>
				<Route exact path={ResourceAssistance.Path.Report.Hospital.vitalSign} component={ReportVitalSignDocument} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.firstAssessment}
					component={ReportFirstAssessmentDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.letterOfConsent}
					component={ReportLetterOfConsentDocument}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.Hospital.depressionAssessment}
					component={ReportDepressionAssessmentDocument}
				/>
				<Route exact path={ResourceAssistance.Path.Report.File.claim} component={ReportClaimDocument} />
				{/* ************************************************************************************* */}

				{/* *********************************File***************************** */}
				<Route exact path={ResourceAssistance.Path.Report.File.pdf} component={PdfFile} />

				{/* ****************************************************************** */}

				{/* Covid19 */}
				<Route exact path={ResourceAssistance.Path.Report.lab.covid19Lab} component={ReportCovid19Document} />
				<Route exact path={ResourceAssistance.Path.Report.lab.covid19Excel} component={ExcelDocument} />

				{/* IPD */}
				<Route exact path={ResourceAssistance.Path.Report.ipd.patient} component={ReportIPDPatientDocument} />
				<Route exact path={ResourceAssistance.Path.Report.ipd.expenseEst} component={ReportIPDPatientExpenseEstDoc} />
				<Route exact path={ResourceAssistance.Path.Report.ipd.glasgowComaScale} component={ReportGlasgowComaScaleDoc} />
				<Route exact path={ResourceAssistance.Path.Report.ipd.pupilSize} component={ReportPupilSizeDoc} />
				<Route exact path={ResourceAssistance.Path.Report.ipd.painScale} component={ReportPainScaleDoc} />
				<Route exact path={ResourceAssistance.Path.Report.ipd.intakeOutput} component={ReportIntakeOutputDoc} />

				{/* Cashier */}
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.billSummary}
					component={ReportCashierReceiptDocument}
				/>
				<Route exact path={ResourceAssistance.Path.Report.cashier.bill} component={ReportCashierBillDocument} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.billDetails}
					component={ReportCashierBillDetailsDoc}
				/>
				<Route exact path={ResourceAssistance.Path.Report.cashier.byUser} component={ReportCashierByUserDoc} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.billingToToday}
					component={ReportCashierBillingToTodayDoc}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.healthCarePlanReceipt}
					component={ReportCashierHealthCarePlanReceiptDoc}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.healthCarePlanCategoryReceipt}
					component={ReportCashierHealthCarePlanReceiptCategoryDoc}
				/>
				<Route
					exact
					path={ResourceAssistance.Path.Report.cashier.billByHealthCarePlan}
					component={ReportCashierBillByHealthCarePlanDoc}
				/>

				{/* OPD */}
				<Route
					exact
					path={ResourceAssistance.Path.Report.opd.doctorPrescription}
					component={ReportOPDDoctorOrderDocument}
				/>
				<Route exact path={ResourceAssistance.Path.Report.opd.nurseOrder} component={ReportOPDNurseOrderDocument} />
				<Route exact path={ResourceAssistance.Path.Report.opd.nurseNote} component={ReportOPDNurseNoteDocument} />
				<Route exact path={ResourceAssistance.Path.Report.opd.vitalSign} component={ReportOPDVitalSignDocument} />
				<Route
					exact
					path={ResourceAssistance.Path.Report.opd.medicalCertificate}
					component={ReportOPDMedicalCertificateDocument}
				/>

				{/* Pharmacy  */}
				<Route exact path={ResourceAssistance.Path.Report.pharmacy.orderLabel} component={LabelMedicationDoc} />
			</Switch>
		)
	}
}

export default ReportRedirect
