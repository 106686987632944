import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class ContextMenuPatientInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={2}>
								{this.props.patient.id}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={2}>
								{this.props.patient.identificationNumber}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={2}>
								{this.props.patient.title + " " + this.props.patient.firstName + " " + this.props.patient.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{Utils.formatDate(this.props.patient.dobtime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)} span={1}>
								{Utils.calculateAge(this.props.patient.dobtime)}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

export default ContextMenuPatientInfo
