import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setReportInvByLocationViewQty, setReportInvByLocationViewValue } from "~/redux/action"

const EView = {
	QTY: "REPORT_INVENTORY_BY_LOCATION_FILTER_VIEW_QTY",
	VALUE: "REPORT_INVENTORY_BY_LOCATION_FILTER_VIEW_VALUE",
}

class ReportInvByLocationFilterViewDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onView = this.onView.bind(this)
	}

	componentDidMount() {}

	componentWillUnmount() {
		this.props.setReportInvByLocationViewQty(true)
		this.props.setReportInvByLocationViewValue(false)
	}

	componentDidUpdate(prevProps, prevState) {}

	onView(event) {
		switch (event.target.value) {
			case EView.QTY:
				this.props.setReportInvByLocationViewQty(true)
				this.props.setReportInvByLocationViewValue(false)
				break

			case EView.VALUE:
				this.props.setReportInvByLocationViewQty(false)
				this.props.setReportInvByLocationViewValue(true)
				break

			default:
				break
		}
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.view)}</legend>
				<Row>
					<Col className={ResourceAssistance.CSS.fullSize} />
					<Col>
						<Row>
							<Col md="auto">
								<FormLabel>{translate(ResourceAssistance.Message.qty)}</FormLabel>
							</Col>
							<Col>
								<input
									type={ResourceAssistance.Button.type.radio}
									value={EView.QTY}
									name="view"
									checked={this.props.filter.isQty}
									onChange={this.onView}
								/>
							</Col>
						</Row>
					</Col>
					<Row>
						<Col md="auto">
							<FormLabel>{translate(ResourceAssistance.Message.value)}</FormLabel>
						</Col>
						<Col>
							<input
								type={ResourceAssistance.Button.type.radio}
								value={EView.VALUE}
								name="view"
								checked={this.props.filter.isValue}
								onChange={this.onView}
							/>
						</Col>
					</Row>

					<Col className={ResourceAssistance.CSS.fullSize} />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.inv.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setReportInvByLocationViewQty,
			setReportInvByLocationViewValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportInvByLocationFilterViewDetails))
