import _ from "lodash"
import React from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setTfDisplayAllInd,
	setTfRequests,
	setTfSelectedRequest,
} from "~/redux/action"
import { SET_TF_NEW_REQUEST_DISPLAY, SET_TF_REQUEST_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class TFLeftThirdRow extends React.Component {
	constructor(props) {
		super(props)

		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onComplete = this.onComplete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.transfer.isDisplayAll !== this.props.transfer.isDisplayAll) {
			this.props.setTfRequests(this.props.transfer.requestTable.original)
		}
	}

	isCompleteDisabled() {
		return (
			this.props.transfer.selectedRequest.index === -1 ||
			this.props.transfer.selectedRequest.request.approvalApproved ||
			this.props.transfer.selectedRequest.request.items.some((item) => {
				return item.amount !== item.transferredAmount && !item.cancelled
			})
		)
	}

	isPrintDisabled() {
		return (
			this.props.transfer.selectedRequest.request.transferOrders.filter((transfer) => transfer.approval).length === 0
		)
	}

	onDisplayAll(event) {
		this.props.setTfDisplayAllInd(event.target.checked)
	}

	onPrint(event) {
		this.props.transfer.selectedRequest.request.transferOrders
			.filter((transfer) => transfer.approval)
			.forEach((transfer) => {
				this.props.print(transfer)
			})
	}

	onNew(event) {
		this.props.setTfSelectedRequest(-1, null)
		this.props.onModalDisplayAction(SET_TF_NEW_REQUEST_DISPLAY, true)
	}

	onComplete(event) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.completeTR,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				locationId: this.props.transfer.selectedRequest.request.location.id,
				userId: this.props.login.user.id,
				items: this.props.transfer.selectedRequest.request.transferOrders.flatMap((transferOrder) => {
					return transferOrder.items
						.filter((transferedItem) => _.isEqual(transferedItem.status, ResourceAssistance.Enum.received))
						.map((transferedItem) => ({
							transferOrderItemId: transferedItem.id,
							// code: transferedItem.code,
							// genericName: transferedItem.name,
							// brand: transferedItem.brand,
							// unit: transferedItem.unit,
						}))
				}),
			},
		}
		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col>
					<FormCheck
						className={ResourceAssistance.CSS.flexSelfCenter}
						type={ResourceAssistance.FormControl.type.checkBox}
						label={translate(ResourceAssistance.Message.displayAll)}
						onChange={this.onDisplayAll}
					/>
				</Col>

				{(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Complete, this.props.login.user.roles) ||
					Utils.hasPrivilege(
						ResourceAssistance.Privilege.Management.Transfer.Transfer,
						this.props.login.user.roles
					)) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.transfer.selectedRequest.index === -1 || this.isPrintDisabled()}
							onClick={this.onPrint}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Request, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							size={ResourceAssistance.Button.size.sm}
							onClick={this.onNew}
						>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.newRequest)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Complete, this.props.login.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
							disabled={this.isCompleteDisabled()}
							onClick={this.onComplete}
						>
							<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.complete)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setTfDisplayAllInd,
			setTfRequests,
			setTfSelectedRequest,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TFLeftThirdRow))
