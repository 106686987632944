import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_BUDGET_BUDGET,
	SET_REGISTRATION_MODAL_BUDGET_CONTACT,
	SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER,
	SET_REGISTRATION_MODAL_BUDGET_NOTE,
	SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class RegModalBudgetHealthcare extends React.Component {
	HEALTH_CARE = "HEALTH_CARE"
	EHealthCare = {
		HEALTH_CARE: "HEALTH_CARE",
		OTHER: "OTHER",
	}

	constructor(props) {
		super(props)

		this.state = {
			healthcareRadio: this.EHealthCare.HEALTH_CARE,
		}

		this.onHealthCare = this.onHealthCare.bind(this)
		this.onHealthCareRadio = this.onHealthCareRadio.bind(this)
		this.onHealthCareOther = this.onHealthCareOther.bind(this)
	}

	componentDidMount() {
		if (this.props.reg.modalExpenseEst.selectedBudget.budget) {
			if (_.isEmpty(this.props.reg.modalExpenseEst.selectedBudget.budget.code)) {
				this.setState({
					healthcareRadio: this.EHealthCare.OTHER,
				})
				this.props.setValue(
					SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER,
					this.props.reg.modalExpenseEst.selectedBudget.budget.description
				)
			} else {
				let idx = this.props.reg.modalBudget.healthcares.findIndex(
					(healthcare) =>
						healthcare.code === this.props.reg.modalExpenseEst.selectedBudget.budget.code &&
						healthcare.displayName === this.props.reg.modalExpenseEst.selectedBudget.budget.name &&
						healthcare.description === this.props.reg.modalExpenseEst.selectedBudget.budget.description
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE,
						idx,
						this.props.reg.modalBudget.healthcares[idx]
					)
				}
			}

			this.props.setValue(
				SET_REGISTRATION_MODAL_BUDGET_BUDGET,
				this.props.reg.modalExpenseEst.selectedBudget.budget.budget
			)
			this.props.setValue(
				SET_REGISTRATION_MODAL_BUDGET_CONTACT,
				this.props.reg.modalExpenseEst.selectedBudget.budget.contact
			)
			this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_NOTE, this.props.reg.modalExpenseEst.selectedBudget.budget.note)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.healthcareRadio, this.state.healthcareRadio)) {
			if (_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)) {
				this.props.setSelected(SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE, -1, null)
			} else {
				this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE, -1, null)
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER, "")
	}

	onHealthCareRadio(event) {
		this.setState({
			healthcareRadio: event.target.value,
		})
	}

	onHealthCare(value) {
		this.props.setSelected(
			SET_REGISTRATION_MODAL_BUDGET_SELECTED_HEALTH_CARE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.reg.modalBudget.healthcares[value] : null
		)
	}

	onHealthCareOther(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_HEALTH_CARE_OTHER, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
				<Row noGutters>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.HEALTH_CARE}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<Select
							showSearch
							placeholder={translate(ResourceAssistance.Hospitel.healthCare)}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							status={this.props.reg.modalBudget.selectedHealthcare.healthcare ? undefined : "error"}
							value={
								this.props.reg.modalBudget.selectedHealthcare.healthcare
									? this.props.reg.modalBudget.selectedHealthcare.index
									: undefined
							}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={this.onHealthCare}
						>
							{Utils.renderSelects(this.props.reg.modalBudget.healthcares, false)}
						</Select>
					</Col>
				</Row>
				<Row noGutters>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.OTHER}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.other })}...`}
							rows={3}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							value={this.props.reg.modalBudget.healthcareOther}
							onChange={this.onHealthCareOther}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalBudgetHealthcare))
