import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_DEPARTMENT,
	SET_HOSPITEL_END_DATETIME,
	SET_HOSPITEL_FILTER_INPATIENT_CHECKED,
	SET_HOSPITEL_FILTER_NURSE_CHECKED,
	SET_HOSPITEL_FILTER_SOAP_CHECKED,
	SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED,
	SET_HOSPITEL_HOSPITELS,
	SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND,
	SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME,
	SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES,
	SET_IPD_SELECTED_PAIN_SCALE,
	SET_IPD_PUPIL_SIZE,
	SET_IPD_PAIN_SCALE,
	SET_IPD_SELECTED_PUPIL_SIZE,
	SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME,
	SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SEARCH_NAME,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING,
	SET_HOSPITEL_MODAL_LOCATION_BRANCHES,
	SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS,
	SET_HOSPITEL_MODAL_LOCATION_ORGS,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG,
	SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES,
	SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS,
	SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING,
	SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS,
	SET_HOSPITEL_MODAL_NURSE_ORDER_SEARCH_NAME,
	SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM,
	SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM,
	SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_TYPE,
	SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT,
	SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT_OTHER,
	SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME,
	SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION,
	SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS,
	SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS_SELECTED_CATEGORY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION,
	SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION_SELECTED_CATEGORY,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SEARCH_NAME,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_CATEGORY,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING,
	SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM,
	SET_HOSPITEL_MODAL_REGISTER_PATIENT,
	SET_HOSPITEL_MODAL_REGISTER_ROOM,
	SET_HOSPITEL_MODAL_SEARCH_FIRST_NAME,
	SET_HOSPITEL_MODAL_SEARCH_ID,
	SET_HOSPITEL_MODAL_SEARCH_LAST_NAME,
	SET_HOSPITEL_MODAL_SEARCH_PID,
	SET_HOSPITEL_MODAL_STOP_END_DATETIME,
	SET_HOSPITEL_MODAL_VITAL_SIGN_BP,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME,
	SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN,
	SET_HOSPITEL_MODAL_VITAL_SIGN_PR,
	SET_HOSPITEL_MODAL_VITAL_SIGN_RR,
	SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE,
	SET_HOSPITEL_NURSE_NOTES,
	SET_HOSPITEL_NURSE_ORDERS,
	SET_HOSPITEL_ORDERS,
	SET_HOSPITEL_ORDER_END_DATE,
	SET_HOSPITEL_PATIENTS,
	SET_HOSPITEL_PATIENTS_LOADING,
	SET_HOSPITEL_SEARCH_ID,
	SET_HOSPITEL_SEARCH_PATIENT_ID,
	SET_HOSPITEL_SELECTED_HOSPITEL,
	SET_HOSPITEL_SELECTED_NURSE_NOTE,
	SET_HOSPITEL_SELECTED_NURSE_ORDER,
	SET_HOSPITEL_SELECTED_ORDER,
	SET_HOSPITEL_SELECTED_PAITENT,
	SET_HOSPITEL_SELECTED_SOAP,
	SET_HOSPITEL_SELECTED_VITAL_SIGN,
	SET_HOSPITEL_SOAPS,
	SET_HOSPITEL_START_DATETIME,
	SET_HOSPITEL_VITAL_SIGNS,
	SET_IPD_CERTIFICATES,
	SET_IPD_GCS,
	SET_IPD_INTAKE_OUTPUTS,
	SET_IPD_INTAKE_TYPES,
	SET_IPD_LEVELS,
	SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME,
	SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SERVICES,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_USERS,
	SET_IPD_MODAL_PRN_RECORD_RECORDS,
	SET_IPD_OUTPUT_TYPES,
	SET_IPD_SELECTED_CERTIFICATE,
	SET_IPD_SELECTED_GCS,
	SET_IPD_SELECTED_INTAKE_OUTPUT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { SET_IPD_ICD_TYPES } from "./../../type/type/hospital/IPD"

const init = {
	startDateTime: Utils.generateDate(0, 0, -14).getTime(),
	endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
	orderEndDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
	searchPatientId: "",
	searchId: "",
	department: null,
	soapChecked: false,
	nurseChecked: false,
	vitalSignChecked: false,
	inpatientChecked: false,
	icdTypes: [],
	levels: [],
	intakes: [],
	outputs: [],
	selectedPatient: {
		index: -1,
		patient: null,
	},
	selectedHospitel: {
		index: -1,
		hospitel: null,
	},
	selectedOrder: {
		index: -1,
		order: null,
	},
	selectedNurseOrder: {
		index: -1,
		order: null,
	},
	selectedVitalSign: {
		index: -1,
		vitalSign: null,
	},
	selectedNurseNote: {
		index: -1,
		nurseNote: null,
	},
	selectedSOAP: {
		index: -1,
		soap: null,
	},
	selectedCertificate: {
		index: -1,
		certificate: null,
	},
	selectedIO: {
		index: -1,
		io: null,
	},
	selectedGCS: {
		index: -1,
		gcs: null,
	},
	selectedPainScale: {
		index: -1,
		painScale: null,
	},
	selectedPupilSize: {
		index: -1,
		pupilSize: null,
	},
	patientTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.seq,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Message.firstName,
			ResourceAssistance.Message.lastName,
			ResourceAssistance.Hospitel.dateOfBirth,
			ResourceAssistance.Hospitel.identification,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	hospitelTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.room,
		],
		body: [],
		rowColor: [],
	},
	soapTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.subjective,
			ResourceAssistance.Hospitel.objective,
			ResourceAssistance.Hospitel.assessment,
			ResourceAssistance.Hospitel.plan,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	orderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.cancelled,
			ResourceAssistance.Message.by,
		],
		body: [],
		rowColor: [],
	},
	nurseOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.qtyPerDay,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	nurseNoteTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.focus,
			ResourceAssistance.Hospitel.assessment,
			ResourceAssistance.Hospitel.intervention,
			ResourceAssistance.Hospitel.evaluation,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
	},
	vitalSignTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.temperature,
			ResourceAssistance.Message.prPerMin,
			ResourceAssistance.Message.rrPerMin,
			ResourceAssistance.Message.bpmmHg,
			ResourceAssistance.Message.o2Sat,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
	},
	intakeOutputTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.intake,
			ResourceAssistance.Message.amountML,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.output,
			ResourceAssistance.Message.amountML,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
	},
	certificateTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Hospitel.symptoms,
			ResourceAssistance.Hospitel.diagnosis,
			ResourceAssistance.Hospitel.doctorOpinion,
			ResourceAssistance.Hospitel.doctorName,
			ResourceAssistance.Hospitel.licenseNo,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	gcsTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.eyeOpeningResponse,
			ResourceAssistance.Message.verbalResponse,
			ResourceAssistance.Message.motorResponse,
			ResourceAssistance.Message.score,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	painScaleTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.time, ResourceAssistance.Message.score, ResourceAssistance.Message.by, ""],
		body: [],
		colStyle: [],
	},
	pupilSizeTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.leftEyePupilSizeMM,
			ResourceAssistance.Message.leftEyeReactionToLight,
			ResourceAssistance.Message.rightEyePupilSizeMM,
			ResourceAssistance.Message.rightEyeReactionToLight,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
	},
	modalLocation: {
		orgs: [],
		branches: [],
		departments: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedDepartment: {
			index: -1,
			department: null,
		},
	},
	modalRegister: {
		patient: null,
		identificationNumber: "",
		room: "",
	},
	modalSearch: {
		pid: "",
		identificationNumber: "",
		firstName: "",
		lastName: "",
	},
	modalDoctorOrder: {
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		searchDescription: "",
		notes: "",
		isPRN: false,
		durationQty: "",
		duration: "",
		types: [],
		units: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		selectedDuration: {
			index: -1,
			duration: null,
		},
		selectedUnit: {
			index: -1,
			unit: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.genericName,
				ResourceAssistance.Message.tradeName,
				ResourceAssistance.Message.unit,
			],
			body: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.order,
				ResourceAssistance.Message.tradeName,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalStop: {
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
	},
	modalNurseRecord: {
		recordDateTime: new Date().getTime(),
		focus: "",
		assessment: ResourceAssistance.CONSTANT.ASYMPTOMATIC,
		assessmentOther: "",
		intervention: "",
		evaluation: ResourceAssistance.CONSTANT.DEFAULT_NURSE_EVALUATION,
		categories: [],
		selectedFocusCategory: {
			index: -1,
			category: null,
		},
		selectedInterventionCategory: {
			index: -1,
			category: null,
		},
	},
	modalDoctorNote: {
		noteDateTime: new Date().getTime(),
		subjective: "",
		objective: "",
		assessment: "",
		plan: "",
	},
	modalVitalSign: {
		dateTime: new Date().getTime(),
		temperature: "",
		pr: "",
		rr: "",
		bp: "",
		oxygen: "",
	},
	modalDefaultOrder: {
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		admitHospitel: "",
		oxygenCannular: "",
		oxygenMask: "",
		diet: "",
		vitalSign: "",
		monitor: "",
		description: "",
	},
	modalNurseOrder: {
		searchName: "",
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
			],
			body: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.qtyPerDay,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalNurseService: {
		searchName: "",
		categories: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalAdjustment: {
		isDisableEndDate: true,
		qtyPerDay: "",
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		notes: "",
	},
	modalAdjustmentService: {
		service: null,
		isDisableEndDate: true,
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		notes: "",
	},
	modalDoctorService: {
		searchName: "",
		categories: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalPRNRecord: {
		recordTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.date, ResourceAssistance.Message.qty, ResourceAssistance.Message.by],
			body: [],
		},
	},
	modalCreatePRNRecord: {
		dispensingDateTime: new Date().getTime(),
		dispensingQty: 0,
	},
	modalCertificate: {
		examinationStartDateTime: moment().startOf("day").valueOf(),
		examinationEndDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		symptom: "",
		diagnosis: "",
		doctorOpinion: "",
		startDateTime: moment().startOf("day").valueOf(),
		endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		bloodTestChecked: false,
		radiologyChecked: false,
		bloodTestDetails: "",
		radiologyDetails: "",
		investigation: "",
		treatment: "",
		categories: [],
		services: [],
		roles: [],
		users: [],
		selectedCategory: {
			index: -1,
			category: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedRole: {
			index: -1,
			role: null,
		},
		selectedUser: {
			index: -1,
			user: null,
		},
	},
}

const hospitelReducer = (state = init, action) => {
	switch (action.type) {
		case SET_HOSPITEL_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.date,
			})
		case SET_HOSPITEL_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.date,
			})
		case SET_HOSPITEL_SEARCH_PATIENT_ID:
			return Object.assign({}, state, {
				searchPatientId: action.payload.text,
			})
		case SET_HOSPITEL_SEARCH_ID:
			return Object.assign({}, state, {
				searchId: action.payload.text,
			})
		case SET_HOSPITEL_PATIENTS:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_HOSPITEL_PATIENTS_LOADING:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_HOSPITEL_SELECTED_PAITENT:
			return Object.assign({}, state, {
				selectedPatient: {
					index: action.payload.index,
					patient: action.payload.selected,
				},
			})
		case SET_HOSPITEL_HOSPITELS:
			return Object.assign({}, state, {
				hospitelTable: {
					...state.hospitelTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_HOSPITEL_SELECTED_HOSPITEL:
			return Object.assign({}, state, {
				selectedHospitel: {
					index: action.payload.index,
					hospitel: action.payload.selected,
				},
			})
		case SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					identificationNumber: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_REGISTER_PATIENT:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					patient: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_SEARCH_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					identificationNumber: action.payload.text,
				},
			})
		case SET_HOSPITEL_MODAL_REGISTER_ROOM:
			return Object.assign({}, state, {
				modalRegister: {
					...state.modalRegister,
					room: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					types: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					units: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedUnit: {
						index: action.payload.index,
						unit: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					itemTable: {
						...state.modalDoctorOrder.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					itemTable: {
						...state.modalDoctorOrder.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					startDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					endDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					searchDescription: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					pendingItemTable: {
						...state.modalDoctorOrder.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					notes: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					isPRN: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					durationQty: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					duration: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION:
			return Object.assign({}, state, {
				modalDoctorOrder: {
					...state.modalDoctorOrder,
					selectedDuration: {
						index: action.payload.index,
						duration: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_ORDERS:
			return Object.assign({}, state, {
				orderTable: {
					...state.orderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_HOSPITEL_NURSE_ORDERS:
			return Object.assign({}, state, {
				nurseOrderTable: {
					...state.nurseOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_HOSPITEL_ORDER_END_DATE:
			return Object.assign({}, state, {
				orderEndDateTime: action.payload.value,
			})
		case SET_HOSPITEL_SELECTED_ORDER:
			return Object.assign({}, state, {
				selectedOrder: {
					index: action.payload.index,
					order: action.payload.selected,
				},
			})
		case SET_HOSPITEL_SELECTED_NURSE_ORDER:
			return Object.assign({}, state, {
				selectedNurseOrder: {
					index: action.payload.index,
					order: action.payload.selected,
				},
			})
		case SET_HOSPITEL_MODAL_STOP_END_DATETIME:
			return Object.assign({}, state, {
				modalStop: {
					...state.modalStop,
					endDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE:
			return Object.assign({}, state, {
				modalDoctorNote: {
					...state.modalDoctorNote,
					subjective: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE:
			return Object.assign({}, state, {
				modalDoctorNote: {
					...state.modalDoctorNote,
					objective: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT:
			return Object.assign({}, state, {
				modalDoctorNote: {
					...state.modalDoctorNote,
					assessment: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN:
			return Object.assign({}, state, {
				modalDoctorNote: {
					...state.modalDoctorNote,
					plan: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					recordDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME:
			return Object.assign({}, state, {
				modalDoctorNote: {
					...state.modalDoctorNote,
					noteDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					dateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					temperature: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_PR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					pr: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_RR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					rr: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_BP:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					bp: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					oxygen: action.payload.value,
				},
			})
		case SET_HOSPITEL_VITAL_SIGNS:
			return Object.assign({}, state, {
				vitalSignTable: {
					...state.vitalSignTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_IPD_INTAKE_OUTPUTS:
			return Object.assign({}, state, {
				intakeOutputTable: {
					...state.intakeOutputTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_HOSPITEL_SELECTED_VITAL_SIGN:
			return Object.assign({}, state, {
				selectedVitalSign: {
					index: action.payload.index,
					vitalSign: action.payload.selected,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					categories: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					selectedFocusCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					selectedInterventionCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					focus: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					assessment: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					intervention: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					evaluation: action.payload.value,
				},
			})
		case SET_HOSPITEL_NURSE_NOTES:
			return Object.assign({}, state, {
				nurseNoteTable: {
					...state.nurseNoteTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_HOSPITEL_SELECTED_NURSE_NOTE:
			return Object.assign({}, state, {
				selectedNurseNote: {
					index: action.payload.index,
					nurseNote: action.payload.selected,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					startDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					endDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					description: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					admitHospitel: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					oxygenCannular: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					oxygenMask: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					diet: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					vitalSign: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR:
			return Object.assign({}, state, {
				modalDefaultOrder: {
					...state.modalDefaultOrder,
					monitor: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT_OTHER:
			return Object.assign({}, state, {
				modalNurseRecord: {
					...state.modalNurseRecord,
					assessmentOther: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_SEARCH_PID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					pid: action.payload.text,
				},
			})
		case SET_HOSPITEL_MODAL_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					firstName: action.payload.text,
				},
			})
		case SET_HOSPITEL_MODAL_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					lastName: action.payload.text,
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_BRANCHES:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					branches: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_DEPARTMENTS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					departments: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedDepartment: {
						index: action.payload.index,
						department: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_DEPARTMENT:
			return Object.assign({}, state, {
				department: action.payload.value,
			})
		case SET_HOSPITEL_SOAPS:
			return Object.assign({}, state, {
				soapTable: {
					...state.soapTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_HOSPITEL_SELECTED_SOAP:
			return Object.assign({}, state, {
				selectedSOAP: {
					index: action.payload.index,
					soap: action.payload.selected,
				},
			})
		case SET_HOSPITEL_FILTER_SOAP_CHECKED:
			return Object.assign({}, state, {
				soapChecked: action.payload.value,
			})
		case SET_HOSPITEL_FILTER_NURSE_CHECKED:
			return Object.assign({}, state, {
				nurseChecked: action.payload.value,
			})
		case SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED:
			return Object.assign({}, state, {
				vitalSignChecked: action.payload.value,
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					itemTable: {
						...state.modalNurseOrder.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					types: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					itemTable: {
						...state.modalNurseOrder.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_SEARCH_NAME:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					searchName: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isDisableEndDate: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					startDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					endDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					qtyPerDay: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					notes: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					pendingItemTable: {
						...state.modalNurseOrder.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalNurseOrder: {
					...state.modalNurseOrder,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					serviceTable: {
						...state.modalNurseService.serviceTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					categories: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SEARCH_NAME:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					searchName: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					serviceTable: {
						...state.modalNurseService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalNurseService: {
					...state.modalNurseService,
					pendingServiceTable: {
						...state.modalNurseService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND:
			return Object.assign({}, state, {
				modalAdjustmentService: {
					...state.modalAdjustmentService,
					isDisableEndDate: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustmentService: {
					...state.modalAdjustmentService,
					startDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME:
			return Object.assign({}, state, {
				modalAdjustmentService: {
					...state.modalAdjustmentService,
					endDateTime: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES:
			return Object.assign({}, state, {
				modalAdjustmentService: {
					...state.modalAdjustmentService,
					notes: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					categories: action.payload.objs,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SEARCH_NAME:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					searchName: action.payload.value,
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					serviceTable: {
						...state.modalDoctorService.serviceTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					serviceTable: {
						...state.modalDoctorService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					pendingServiceTable: {
						...state.modalDoctorService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalDoctorService: {
					...state.modalDoctorService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE:
			return Object.assign({}, state, {
				modalAdjustmentService: {
					...state.modalAdjustmentService,
					service: action.payload.value,
				},
			})
		case SET_HOSPITEL_FILTER_INPATIENT_CHECKED:
			return Object.assign({}, state, {
				inpatientChecked: action.payload.value,
			})
		case SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME:
			return Object.assign({}, state, {
				modalCreatePRNRecord: {
					...state.modalCreatePRNRecord,
					dispensingDateTime: action.payload.value,
				},
			})
		case SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY:
			return Object.assign({}, state, {
				modalCreatePRNRecord: {
					...state.modalCreatePRNRecord,
					dispensingQty: action.payload.value,
				},
			})
		case SET_IPD_MODAL_PRN_RECORD_RECORDS:
			return Object.assign({}, state, {
				modalPRNRecord: {
					...state.modalPRNRecord,
					recordTable: {
						...state.modalPRNRecord.recordTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_IPD_CERTIFICATES:
			return Object.assign({}, state, {
				certificateTable: {
					...state.certificateTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_IPD_SELECTED_CERTIFICATE:
			return Object.assign({}, state, {
				selectedCertificate: {
					index: action.payload.index,
					certificate: action.payload.selected,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					roles: action.payload.objs,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedCategory: {
						index: action.payload.index,
						category: action.payload.selected,
					},
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SERVICES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					services: action.payload.objs,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					categories: action.payload.objs,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedRole: {
						index: action.payload.index,
						role: action.payload.selected,
					},
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					selectedUser: {
						index: action.payload.index,
						user: action.payload.selected,
					},
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_USERS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					users: action.payload.objs,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					examinationEndDateTime: action.payload.date,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					examinationStartDateTime: action.payload.date,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					symptom: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					diagnosis: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					bloodTestChecked: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					bloodTestDetails: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					investigation: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					radiologyChecked: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					radiologyDetails: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					treatment: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					doctorOpinion: action.payload.value,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					endDateTime: action.payload.date,
				},
			})
		case SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START:
			return Object.assign({}, state, {
				modalCertificate: {
					...state.modalCertificate,
					startDateTime: action.payload.date,
				},
			})
		case SET_IPD_ICD_TYPES:
			return Object.assign({}, state, {
				icdTypes: action.payload.objs,
			})
		case SET_IPD_LEVELS:
			return Object.assign({}, state, {
				levels: action.payload.objs,
			})
		case SET_IPD_SELECTED_INTAKE_OUTPUT:
			return Object.assign({}, state, {
				selectedIO: {
					index: action.payload.index,
					io: action.payload.selected,
				},
			})
		case SET_IPD_INTAKE_TYPES:
			return Object.assign({}, state, {
				intakes: action.payload.objs,
			})
		case SET_IPD_OUTPUT_TYPES:
			return Object.assign({}, state, {
				outputs: action.payload.objs,
			})
		case SET_IPD_GCS:
			return Object.assign({}, state, {
				gcsTable: {
					...state.gcsTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_IPD_SELECTED_GCS:
			return Object.assign({}, state, {
				selectedGCS: {
					index: action.payload.index,
					gcs: action.payload.selected,
				},
			})
		case SET_IPD_SELECTED_PAIN_SCALE:
			return Object.assign({}, state, {
				selectedPainScale: {
					index: action.payload.index,
					painScale: action.payload.selected,
				},
			})
		case SET_IPD_PAIN_SCALE:
			return Object.assign({}, state, {
				painScaleTable: {
					...state.painScaleTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_IPD_PUPIL_SIZE:
			return Object.assign({}, state, {
				pupilSizeTable: {
					...state.pupilSizeTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_IPD_SELECTED_PUPIL_SIZE:
			return Object.assign({}, state, {
				selectedPupilSize: {
					index: action.payload.index,
					pupilSize: action.payload.selected,
				},
			})
		default:
			return state
	}
}

export default hospitelReducer
