import React from "react"
import { Button, Col, FormLabel, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setDateAction } from "~/redux/action"
import { SET_HOSPITEL_END_DATETIME, SET_HOSPITEL_START_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelLeftDateRow extends React.Component {
	constructor(props) {
		super(props)
		this.onStartDateTime = this.onStartDateTime.bind(this)
		this.onEndDateTime = this.onEndDateTime.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	onStartDateTime(date) {
		this.props.setDateAction(SET_HOSPITEL_START_DATETIME, date.getTime())
	}

	onEndDateTime(date) {
		this.props.setDateAction(SET_HOSPITEL_END_DATETIME, date.getTime())
	}

	onSearch(event) {
		event.preventDefault()
		this.props.loadHospitels()
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col sm="auto">
					<DatePicker
						startDate={Utils.generateDateFromLong(this.props.hospitel.startDateTime)}
						onChange={this.onStartDateTime}
					/>
				</Col>
				<Col>
					<FormLabel style={{ alignSelf: ResourceAssistance.CSS.center }}>
						{translate(ResourceAssistance.Message.to)}
					</FormLabel>
				</Col>
				<Col sm="auto">
					<DatePicker
						startDate={Utils.generateDateFromLong(this.props.hospitel.endDateTime)}
						onChange={this.onEndDateTime}
					/>
				</Col>
				<Col sm="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						type={ResourceAssistance.Button.type.submit}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearch}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setDateAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelLeftDateRow)
