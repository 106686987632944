import { message } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setOPDModalDoctorOrderItems, setOPDModalDoctorOrderPendingItems, setSelected } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import OutpatientModalDoctorDuration from "./OutpatientModalDoctorDuration"

class OutpatientModalDoctorItems extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedType.type, this.props.opd.modalDoctorOrder.selectedType.type)
		) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
		}

		if (
			prevProps.opd.modalDoctorOrder.searchDescription !== this.props.opd.modalDoctorOrder.searchDescription ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedType, this.props.opd.modalDoctorOrder.selectedType) ||
			!_.isEqual(
				prevProps.opd.modalDoctorOrder.pharmacyInventories,
				this.props.opd.modalDoctorOrder.pharmacyInventories
			)
		) {
			this.props.setOPDModalDoctorOrderItems(this.props.opd.modalDoctorOrder.itemTable.original)
		}

		if (
			prevProps.opd.modalDoctorOrder.startDateTime !== this.props.opd.modalDoctorOrder.startDateTime ||
			prevProps.opd.modalDoctorOrder.endDateTime !== this.props.opd.modalDoctorOrder.endDateTime ||
			prevProps.opd.modalDoctorOrder.duration !== this.props.opd.modalDoctorOrder.duration ||
			prevProps.opd.modalDoctorOrder.durationQty !== this.props.opd.modalDoctorOrder.durationQty ||
			!_.isEqual(
				prevProps.opd.modalDoctorOrder.selectedDuration.duration,
				this.props.opd.modalDoctorOrder.selectedDuration.duration
			)
		) {
			if (
				this.props.opd.modalDoctorOrder.durationQty &&
				this.props.opd.modalDoctorOrder.duration &&
				!this.validatePharmacyInventory()
			) {
				message.destroy()
				message.error(
					this.props.intl.formatMessage({ id: ResourceAssistance.Warning.pharmacyCannotMeetDoctorOrder }),
					5
				)
			} else {
				message.destroy()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	checkPharmacyInventoryAvaliability(item) {
		let pharmacyInventory = Utils.findPharmacyInventory(item.code, this.props.opd.modalDoctorOrder.pharmacyInventories)
		return pharmacyInventory && pharmacyInventory.totalAmount > 0
	}

	validatePharmacyInventory() {
		if (_.isEmpty(this.props.opd.modalDoctorOrder.durationQty) || _.isEmpty(this.props.opd.modalDoctorOrder.duration)) {
			return false
		}
		let doctorOrderQty = Utils.calculateDoctorOrderQty(
			{
				...this.props.opd.modalDoctorOrder.selectedItem.item,
				startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
				endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
				duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
					.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
					.toNumber(),
				durationQty: this.props.opd.modalDoctorOrder.durationQty,
			},
			this.props.opd.modalDoctorOrder.startDateTime,
			this.props.opd.modalDoctorOrder.endDateTime
		)

		let pharmacyInventory = Utils.findPharmacyInventory(
			this.props.opd.modalDoctorOrder.selectedItem.item.code,
			this.props.opd.modalDoctorOrder.pharmacyInventories
		)

		return pharmacyInventory && pharmacyInventory.totalAmount > doctorOrderQty
	}

	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()

		let selectedItem = this.props.opd.modalDoctorOrder.selectedItem.item
		let newItem = Object.assign({}, selectedItem, {
			...selectedItem,
			startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
			endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
			notes: this.props.opd.modalDoctorOrder.notes,
			categoryName: this.props.opd.modalDoctorOrder.selectedType.type.description,
			durationQty: this.props.opd.modalDoctorOrder.durationQty,
			duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
				.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
				.toNumber(),
			isPRN: this.props.opd.modalDoctorOrder.isPRN,
		})
		let pendingItems = this.props.opd.modalDoctorOrder.pendingItemTable.filtered
		pendingItems.push(newItem)
		this.props.setOPDModalDoctorOrderPendingItems(pendingItems)

		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	onUpBtn() {
		let pendingItems = this.props.opd.modalDoctorOrder.pendingItemTable.filtered.filter(
			(each) => each.displayName !== this.props.opd.modalDoctorOrder.selectedPendingItem.item.displayName
		)
		this.props.setOPDModalDoctorOrderPendingItems(pendingItems)
	}

	onSelectItem(item, index) {
		let selectedItem = this.props.opd.modalDoctorOrder.itemTable.filtered[index]
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, index, selectedItem)
	}

	render() {
		return (
			<Form
				className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
				onSubmit={this.onDownBtn}
			>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Row className={ResourceAssistance.CSS.fullSize}>
							<Col>
								<ScrollableTable
									striped
									hover
									enableHighlight
									onClick={this.onSelectItem}
									data={this.props.opd.modalDoctorOrder.itemTable}
									isClearHighlight={this.props.opd.modalDoctorOrder.selectedItem.index === -1}
								/>
							</Col>
							{this.props.opd.modalDoctorOrder.itemTable.isLoading && (
								<Spinner
									animation={ResourceAssistance.Spinner.border}
									variant={ResourceAssistance.Button.variant.primary}
								/>
							)}
						</Row>
						<Row>
							<Col />
							<Col md="auto">
								<Button
									type={ResourceAssistance.Button.type.submit}
									variant={ResourceAssistance.Button.variant.link}
									disabled={
										this.props.opd.modalDoctorOrder.selectedItem.index === -1 || !this.validatePharmacyInventory()
									}
								>
									<FcDownload size={"3rem"} />
								</Button>
							</Col>
							<Col />
							<Col md={"auto"}>
								<Button
									variant={ResourceAssistance.Button.variant.link}
									disabled={this.props.opd.modalDoctorOrder.selectedPendingItem.index === -1}
									onClick={this.onUpBtn}
								>
									<FcUpload size={"3rem"} />
								</Button>
							</Col>
							<Col />
						</Row>
					</Col>
					{this.props.opd.modalDoctorOrder.selectedItem.item &&
						this.checkPharmacyInventoryAvaliability(this.props.opd.modalDoctorOrder.selectedItem.item) && (
							<Col md={3} style={{ minWidth: "435px" }}>
								<OutpatientModalDoctorDuration />
							</Col>
						)}
				</Row>
			</Form>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDModalDoctorOrderItems,
			setOPDModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorItems))
