import { PR_DONE_HANDLER, SET_WARNING_MESSAGE } from "../../type"
import { SET_WARNING_CLOSE, SET_WARNING_CONTINUE, SET_WARNING_ID } from "../../type/type/MODAL"

export const onModalDisplayAction = (type, isDisplay) => {
	return (dispatch) => {
		dispatch({
			type: type,
			payload: {
				isDisplay: isDisplay,
			},
		})
		return Promise.resolve()
	}
}

export const onModalElementDisplayAction = (type, isDisplay) => {
	return (dispatch) => {
		dispatch({
			type: type,
			payload: {
				isDisplay: isDisplay,
			},
		})
		return Promise.resolve()
	}
}

export const setPrModalTypeAction = (type) => {
	return {
		type: PR_DONE_HANDLER,
		payload: {
			type: type,
		},
	}
}

export const setWarningMsgAction = (msg) => {
	return {
		type: SET_WARNING_MESSAGE,
		payload: {
			msg: msg,
		},
	}
}

export const setWarningContinue = (isContinue) => {
	return (dispatch) => {
		dispatch({
			type: SET_WARNING_CONTINUE,
			payload: {
				isContinue: isContinue,
			},
		})
		return Promise.resolve()
	}
}

export const setWarningClose = (isClose) => {
	return {
		type: SET_WARNING_CLOSE,
		payload: {
			isClose: isClose,
		},
	}
}

export const setWarningId = (id) => {
	return {
		type: SET_WARNING_ID,
		payload: {
			id: id,
		},
	}
}
