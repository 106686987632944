import Chart from "chart.js/auto"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OutpatientRightIntakeOutputChart extends React.Component {
	chart = null

	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightIntakeOutputChart",
		}

		this.chartRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord, this.props.opd.selectedMedicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.buildChart()
			} else {
				if (this.chart) {
					this.chart.destroy()
				}
			}
		}
	}

	componentDidMount() {
		this.buildChart()
	}

	buildChart() {
		const ctx = this.chartRef.current.getContext("2d")
		this.chartRef.current.style.maxHeight = "239px"
		this.chartRef.current.style.maxWidth = this.chartRef.current.clientWidth + "px"

		if (this.chart) {
			this.chart.destroy()
		}

		let ios = this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.intakeOutputs.sort((a, b) =>
			Utils.sort(a.dateTime, b.dateTime)
		)

		this.chart = new Chart(ctx, {
			type: "line",
			data: {
				labels: ios.map((io) => {
					return Utils.formatDateTime(Utils.generateDateFromLong(io.dateTime))
				}),
				datasets: [
					{
						data: ios.map((io) => {
							return io.intakeAmount ? io.intakeAmount : 0
						}),
						label: "I",
						yAxisID: "I",
						borderColor: ResourceAssistance.CSS.Color.blue,
						backgroundColor: "white",
						fill: false,
					},
					{
						data: ios.map((io) => {
							return io.outputAmount ? io.outputAmount : 0
						}),
						label: "O",
						yAxisID: "I",
						borderColor: ResourceAssistance.CSS.Color.black,
						backgroundColor: "white",
						fill: false,
					},
					{
						data: ios.reduce((array, io, idx) => {
							let ioBalance = Utils.BigNumber(array[idx - 1])
								.plus(io.intakeAmount ? io.intakeAmount : 0)
								.minus(io.outputAmount ? io.outputAmount : 0)
								.toNumber()
							array.push(ioBalance)
							return array
						}, []),
						// data: ios.map((io, i, array) => {
						// 	return array.reduce((total, cur, idx) => {
						// 		if (cur <= i) {
						// 			total = total.plus(cur.)
						// 		}
						// 		return total
						// 	}, Utils.BigNumber(0)).toNumber()
						// 	// return Utils.BigNumber(io.intakeAmount ? io.intakeAmount : 0)
						// 	// 	.minus(io.outputAmount ? io.outputAmount : 0)
						// 	// 	.toNumber()
						// }),
						label: this.props.intl.formatMessage({ id: ResourceAssistance.Message.ioBalance }),
						yAxisID: "I",
						borderColor: ResourceAssistance.CSS.Color.red,
						backgroundColor: "white",
						fill: false,
					},
				],
			},
			options: {
				stacked: false,
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					I: {
						type: "linear",
						display: true,
						position: "left",
						suggestedMin: Math.min(...ios.map((io) => Math.min(io.intakeAmount, io.outputAmount))),
						suggestedMax: Math.max(...ios.map((io) => Math.max(io.intakeAmount, io.outputAmount))),
						beginAtZero: true,
						grid: {
							color: function (context) {
								if (context.index % 2 === 0) {
									return Chart.defaults.borderColor
								}
							},
						},
						ticks: {
							precision: 1,
							callback: function (value, index, values) {
								return index % 2 === 0 ? value : ""
							},
						},
						title: {
							display: true,
							align: "end",
							text: ResourceAssistance.CONSTANT.ML,
							padding: { top: 0, left: 0, right: 0, bottom: 0 },
						},
					},
				},
			},
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<canvas id={this.state.componentName} ref={this.chartRef} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightIntakeOutputChart))
