import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { IoHourglassOutline } from "react-icons/io5"
import { GrRotateLeft } from "react-icons/gr"
import { MdFileDownload } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import { ExcelDataFactory } from "~/utils/factory/ExcelDataFactory"
import ReportHospitelPatientFilterDetails from "./ReportHospitelPatientFilterDetails"

class ReportHospitelPatientFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportHospitelPatientFilter",
			downloadDisabled: true,
		}

		this.ref = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.filter.selectedDepartment, this.props.filter.selectedDepartment)) {
			this.setState({
				downloadDisabled: true,
			})
		}
	}

	componentWillUnmount() {
		this.setState({
			downloadDisabled: true,
		})
	}

	runReport(hospitels) {
		let excel = ExcelDataFactory.generateHospitelPatients(hospitels, this.props.filter.selectedDepartment.department)

		this.ref.current.uodateExcel(excel)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.hospitel.getHospitels,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.filter.selectedDepartment.department.id,
			},
		}
		let callback = (res) => {
			this.setState({
				downloadDisabled: _.isEmpty(res.data.hospitels),
			})
			if (!_.isEmpty(res.data.hospitels)) {
				this.runReport(res.data.hospitels)
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Hospitel.patient)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportHospitelPatientFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={!this.props.filter.selectedDepartment.department}
						>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
						<ExcelDocument
							ref={this.ref}
							element={
								<Button variant={ResourceAssistance.Button.variant.info} disabled={this.state.downloadDisabled}>
									<MdFileDownload size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Hospitel.download)}
								</Button>
							}
						/>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.hospital.hospitel.isPatientDisplay,
	filter: state.report.hospitel.patient,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportHospitelPatientFilter))
