export const SET_HOSPITAL_LAB_MODAL_SEARCH_FIRST_NAME = "SET_HOSPITAL_LAB_MODAL_SEARCH_FIRST_NAME"
export const SET_HOSPITAL_LAB_MODAL_SEARCH_LAST_NAME = "SET_HOSPITAL_LAB_MODAL_SEARCH_LAST_NAME"
export const SET_HOSPITAL_LAB_MODAL_SEARCH_PID = "SET_HOSPITAL_LAB_MODAL_SEARCH_PID"
export const SET_HOSPITEL_LAB_APPROVE_DISPLAY = "SET_HOSPITEL_LAB_APPROVE_DISPLAY"
export const SET_HOSPITEL_LAB_END_DATETIME = "SET_HOSPITEL_LAB_END_DATETIME"
export const SET_HOSPITEL_LAB_LAB_DISPLAY = "SET_HOSPITEL_LAB_LAB_DISPLAY"
export const SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS = "SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS"
export const SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES = "SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES"
export const SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES = "SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES"
export const SET_HOSPITEL_LAB_MODAL_LAB_ORGS = "SET_HOSPITEL_LAB_MODAL_LAB_ORGS"
export const SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH = "SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH"
export const SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY = "SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY"
export const SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG = "SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG"
export const SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE = "SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE"
export const SET_HOSPITEL_LAB_MODAL_LAB_SERVICES = "SET_HOSPITEL_LAB_MODAL_LAB_SERVICES"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_N2GENE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_N2GENE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE"
export const SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD = "SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_COI = "SET_HOSPITEL_LAB_MODAL_POSITIVE_COI"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_EGENE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_EGENE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_N2GENE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_N2GENE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_NGENE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_NGENE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_OGENE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_OGENE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_SGENE = "SET_HOSPITEL_LAB_MODAL_POSITIVE_SGENE"
export const SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD = "SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS = "SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_DOB = "SET_HOSPITEL_LAB_MODAL_REGISTER_DOB"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME = "SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER = "SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER =
	"SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME = "SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE = "SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE"
export const SET_HOSPITEL_LAB_MODAL_REGISTER_TEL = "SET_HOSPITEL_LAB_MODAL_REGISTER_TEL"
export const SET_HOSPITEL_LAB_MODAL_SEARCH_ID = "SET_HOSPITEL_LAB_MODAL_SEARCH_ID"
export const SET_HOSPITEL_LAB_NEGATIVE_DISPLAY = "SET_HOSPITEL_LAB_NEGATIVE_DISPLAY"
export const SET_HOSPITEL_LAB_PATIENTS = "SET_HOSPITEL_LAB_PATIENTS"
export const SET_HOSPITEL_LAB_PATIENTS_LOADING = "SET_HOSPITEL_LAB_PATIENTS_LOADING"
export const SET_HOSPITEL_LAB_PATIENT_LABS = "SET_HOSPITEL_LAB_PATIENT_LABS"
export const SET_HOSPITEL_LAB_POSITIVE_DISPLAY = "SET_HOSPITEL_LAB_POSITIVE_DISPLAY"
export const SET_HOSPITEL_LAB_REGISTER_PATIENT = "SET_HOSPITEL_LAB_REGISTER_PATIENT"
export const SET_HOSPITEL_LAB_SEARCH_ID = "SET_HOSPITEL_LAB_SEARCH_ID"
export const SET_HOSPITEL_LAB_SEARCH_PATIENT = "SET_HOSPITEL_LAB_SEARCH_PATIENT"
export const SET_HOSPITEL_LAB_SEARCH_PATIENT_ID = "SET_HOSPITEL_LAB_SEARCH_PATIENT_ID"
export const SET_HOSPITEL_LAB_SELECTED_LAB = "SET_HOSPITEL_LAB_SELECTED_LAB"
export const SET_HOSPITEL_LAB_SELECTED_PAITENT = "SET_HOSPITEL_LAB_SELECTED_PAITENT"
export const SET_HOSPITEL_LAB_START_DATETIME = "SET_HOSPITEL_LAB_START_DATETIME"
export const SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY = "SET_REPORT_HOSPITAL_LAB_COVID19_FILTER_DISPLAY"
export const SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES = "SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES"
export const SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_TYPE = "SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_TYPE"
export const SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_COMMENT = "SET_LAB_MODAL_POSITIVE_SELECTED_PCR_COMMENT_COMMENT"
export const SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPES = "SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPES"
export const SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE = "SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE"
export const SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT = "SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT"
export const SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING = "SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING"
export const SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING = "SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING"
export const SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES = "SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES"
export const SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPE_LOADING = "SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPE_LOADING"
export const SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPE_LOADING = "SET_LAB_MODAL_NEGATIVE_PCR_COMMENT_TYPE_LOADING"
export const SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPES = "SET_LAB_MODAL_NEGATIVE_ANTIGEN_COMMENT_TYPES"
