import { Radio, Space } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"

class GCSMotor extends React.Component {
	ERadio = {
		NONE: {
			description: translate(ResourceAssistance.Message.noMotor),
			value: 1,
		},
		EXTENSION: {
			description: translate(ResourceAssistance.Message.extension),
			value: 2,
		},
		ABNORMAL_FLEXION: {
			description: translate(ResourceAssistance.Message.abnormalFlexion),
			value: 3,
		},
		NORMAL_FLEXION: {
			description: translate(ResourceAssistance.Message.normalFlexion),
			value: 4,
		},
		LOCALISING: {
			description: translate(ResourceAssistance.Message.localising),
			value: 5,
		},
		OBEYS_COMMANDS: {
			description: translate(ResourceAssistance.Message.obeysCommands),
			value: 6,
		},
	}

	constructor(props) {
		super(props)

		this.state = {
			motor: this.ERadio.NONE.value,
		}

		this.onMotor = this.onMotor.bind(this)
	}

	componentDidMount() {
		if (this.props.gcs) {
			this.setState({
				motor: this.props.gcs.motor,
			})
		}
	}

	onMotor(e) {
		this.setState({
			motor: e.target.value,
		})
		this.props.onMotor(e)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.motorResponse)}</legend>
						<Row>
							<Col md="auto">
								<Radio.Group value={this.state.motor} onChange={this.onMotor}>
									<Space direction="vertical">
										<Radio value={this.ERadio.NONE.value}>{this.ERadio.NONE.description}</Radio>
										<Radio value={this.ERadio.EXTENSION.value}>{this.ERadio.EXTENSION.description}</Radio>
										<Radio value={this.ERadio.ABNORMAL_FLEXION.value}>{this.ERadio.ABNORMAL_FLEXION.description}</Radio>
										<Radio value={this.ERadio.NORMAL_FLEXION.value}>{this.ERadio.NORMAL_FLEXION.description}</Radio>
										<Radio value={this.ERadio.LOCALISING.value}>{this.ERadio.LOCALISING.description}</Radio>
										<Radio value={this.ERadio.OBEYS_COMMANDS.value}>{this.ERadio.OBEYS_COMMANDS.description}</Radio>
									</Space>
								</Radio.Group>
							</Col>
							<Col>
								<Space direction="vertical">
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.NONE.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.EXTENSION.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.ABNORMAL_FLEXION.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.NORMAL_FLEXION.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.LOCALISING.value}</FormLabel>
									<FormLabel style={{ textAlign: "center" }}>{this.ERadio.OBEYS_COMMANDS.value}</FormLabel>
								</Space>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

GCSMotor.propTypes = {
	onMotor: propTypes.func.isRequired,
}

GCSMotor.defaultProps = {
	onMotor: () => {},
}

export default injectIntl(GCSMotor)
