import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPharmacyPatients, setValue } from "~/redux/action"
import { SET_PHARMACY_SEARCH_IDENTIFICATION, SET_PHARMACY_SEARCH_PID } from "~/redux/type"
import SearchBox from "~/view/component/search_box/SearchBox"

class PharmacyLeftSearch extends React.Component {
	constructor(props) {
		super(props)

		this.onPatientId = this.onPatientId.bind(this)
		this.onId = this.onId.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.pharmacy.searchPID !== this.props.pharmacy.searchPID ||
			prevProps.pharmacy.searchIdentification !== this.props.pharmacy.searchIdentification
		) {
			this.props.setPharmacyPatients(this.props.pharmacy.patientTable.original)
		}
	}

	onPatientId(event) {
		this.props.setValue(SET_PHARMACY_SEARCH_PID, event.target.value)
	}

	onId(event) {
		this.props.setValue(SET_PHARMACY_SEARCH_IDENTIFICATION, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Hospitel.patientId, ResourceAssistance.Hospitel.identification]}
						callbacks={[this.onPatientId, this.onId]}
						values={[this.props.pharmacy.searchPID, this.props.pharmacy.searchIdentification]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyLeftSearch)
