import _ from "lodash"
import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import OutpatientRightICD from "./OutpatientRightICD"
import OutpatientRightNurseNote from "./OutpatientRightNurseNote"

class OutpatientRightNoteTab extends React.Component {
	ENote = {
		NURSE_PROGRESS_NOTE: "OPD_NURSE_PROGRESS_NOTE",
	}
	constructor(props) {
		super(props)

		this.state = {
			componentName: "OutpatientRightNoteTab",
			tab: this.ENote.NURSE_PROGRESS_NOTE,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord, this.props.opd.selectedMedicalRecord)) {
			this.setState({
				tab: this.ENote.NURSE_PROGRESS_NOTE,
			})
		}
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tab.Container
				id={this.state.componentName}
				activeKey={this.state.tab}
				onSelect={this.onTabClick}
				mountOnEnter={false}
			>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.ENote.NURSE_PROGRESS_NOTE}>
									{translate(ResourceAssistance.Hospitel.nurseProgressNote)}
								</Nav.Link>
							</Nav.Item>
							{this.props.opd.selectedMedicalRecord.medicalRecord &&
								Object.entries(
									Utils.groupBy(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.icds, "type")
								).map(([key, values]) => {
									return (
										<Nav.Item key={key}>
											<Nav.Link eventKey={key}>{key}</Nav.Link>
										</Nav.Item>
									)
								})}
						</Nav>
					</Col>
				</Row>
				<Row className="full-size" style={{ maxHeight: "9rem", marginBottom: 0 }}>
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.ENote.NURSE_PROGRESS_NOTE}>
								<OutpatientRightNurseNote />
							</Tab.Pane>
							{this.props.opd.selectedMedicalRecord.medicalRecord &&
								Object.entries(
									Utils.groupBy(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.icds, "type")
								).map(([key, values]) => {
									return (
										<Tab.Pane key={key} eventKey={key}>
											<OutpatientRightICD icds={values} />
										</Tab.Pane>
									)
								})}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientRightNoteTab)
