import React from "react"
import PropTypes from "prop-types"
import "./toggleCheckBox.css"

class ToggleCheckBox extends React.Component {
	render() {
		return (
			<div className="switch">
				<input
					disabled={this.props.disabled}
					id={this.props.name}
					type="checkbox"
					className="switch-input"
					checked={this.props.checked}
					onClick={this.props.onClick}
					onChange={(event) => {}}
					name={this.props.name}
				/>
				<label htmlFor={this.props.name} className="switch-label"></label>
			</div>
		)
	}
}

ToggleCheckBox.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	name: PropTypes.string,
}

ToggleCheckBox.defaultProps = {
	checked: true,
	disabled: false,
	onClick: () => {},
}

export default ToggleCheckBox
