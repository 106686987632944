import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import HospitelRightVitalSignRow from "./HospitelRightVitalSignRow"
import IPDRightGCS from "./IPDRightGCS"
import IPDRightIntakeOutput from "./IPDRightIntakeOutput"
import IPDRightPainScale from "./IPDRightPainScale"
import IPDRightPupilSize from "./IPDRightPupilSize"

class IPDRightDataTab extends React.Component {
	EDataTab = {
		VITAL_SIGN: "IPD_TAB_VITAL_SIGN",
		INTAKE_OUTPUT: "IPD_TAB_INTAKE_OUTPUT",
		GLASGOW_COMA_SCALE: "IPD_TAB_GLASGOW_COMA_SCALE",
		PAIN_SCALE: "IPD_TAB_PAIN_SCALE",
		PUPIL_SIZE: "IPD_TAB_PUPIL_SIZE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EDataTab.VITAL_SIGN,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tabs variant="tabs" activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Tab
					eventKey={this.EDataTab.VITAL_SIGN}
					title={translate(ResourceAssistance.Hospitel.vitalSign)}
					name={this.EDataTab.VITAL_SIGN}
				>
					{this.state.tab === this.EDataTab.VITAL_SIGN && <HospitelRightVitalSignRow />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.INTAKE_OUTPUT}
					title={translate(ResourceAssistance.Message.intakeOutput)}
					name={this.EDataTab.INTAKE_OUTPUT}
				>
					{this.state.tab === this.EDataTab.INTAKE_OUTPUT && <IPDRightIntakeOutput />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.GLASGOW_COMA_SCALE}
					title={translate(ResourceAssistance.Message.glasgowComaScale)}
					name={this.EDataTab.GLASGOW_COMA_SCALE}
				>
					{this.state.tab === this.EDataTab.GLASGOW_COMA_SCALE && <IPDRightGCS />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.PAIN_SCALE}
					title={translate(ResourceAssistance.Message.painScale)}
					name={this.EDataTab.PAIN_SCALE}
				>
					{this.state.tab === this.EDataTab.PAIN_SCALE && <IPDRightPainScale />}
				</Tab>
				<Tab
					eventKey={this.EDataTab.PUPIL_SIZE}
					title={translate(ResourceAssistance.Message.pupilSize)}
					name={this.EDataTab.PUPIL_SIZE}
				>
					{this.state.tab === this.EDataTab.PUPIL_SIZE && <IPDRightPupilSize />}
				</Tab>
			</Tabs>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDRightDataTab)
