import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Image, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import io from "socket.io-client"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_DOB,
	SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TEL,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE,
	SET_PAGE_LOADING,
	SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_PHOTO,
	SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY,
	SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import GInput from "~/view/component/input/GInput"
import { genders, nameTitles } from "./IPDModalRegisterDetails"
import ServerUtils from "./../../../../../utils/ServerUtils"

class IPDModalRegisterInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			socket: null,
		}
		this.onNameTitle = this.onNameTitle.bind(this)
		this.onFirstName = this.onFirstName.bind(this)
		this.onLastName = this.onLastName.bind(this)
		this.onIdentificationNumber = this.onIdentificationNumber.bind(this)
		this.onGender = this.onGender.bind(this)
		this.onTelPrimary = this.onTelPrimary.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onDOB = this.onDOB.bind(this)
		this.onFirstNameEng = this.onFirstNameEng.bind(this)
		this.onLastNameEng = this.onLastNameEng.bind(this)
		this.onTelSecondary = this.onTelSecondary.bind(this)
		this.onTelTertiary = this.onTelTertiary.bind(this)
	}

	componentDidMount() {
		this.connectSMCReader()
		if (this.props.ipd.selectedPatient.index !== -1) {
			const patient = this.props.ipd.selectedPatient.patient
			const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, patient.title))
			if (titleIdx > -1) {
				let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.title)
				titleHtml.selectedIndex = titleIdx + 1
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, nameTitles[titleIdx].displayName)
			}

			const genderIdx = genders.findIndex((gender) => _.isEqual(gender.displayName, patient.gender))
			if (genderIdx > -1) {
				let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.gender)
				genderHtml.selectedIndex = genderIdx + 1
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, genders[genderIdx].displayName)
			}

			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, patient.firstName)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, patient.lastName)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, patient.firstNameEng)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, patient.lastNameEng)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, patient.identificationNumber)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, patient.dobtime)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, patient.tel)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, patient.telSecondary)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, patient.telTertiary)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, patient.address)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, patient.photo)
		}
	}

	componentWillUnmount() {
		if (this.state.socket) {
			this.state.socket.disconnect()
		}
		this.reset()
	}

	reset() {
		this.resetTitle()
		this.resetGender()
		this.resetSmartCardInfo()
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, "")
	}

	resetSmartCardInfo() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, "")
	}

	resetGender() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, "")
		let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.gender)
		genderHtml.selectedIndex = 0
	}

	resetTitle() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, "")
		let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.title)
		titleHtml.selectedIndex = 0
	}

	connectSMCReader() {
		const socket = io.connect("http://127.0.0.1:9898")
		this.setState({ socket: socket })
		socket.on("connect_error", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Hospitel.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect_failed", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Message.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect", function () {
			socket.emit("set-query", {
				query: ["cid", "name", "nameEn", "dob", "gender", "address", "photo"],
			})
		})
		socket.on("smc-data", (res) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
			if (res.data.name && res.data.name.prefix) {
				const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, res.data.name.prefix))
				if (titleIdx > -1) {
					let titleHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.title)
					titleHtml.selectedIndex = titleIdx + 1
					this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, nameTitles[titleIdx].displayName)
				}
			}

			if (res.data.gender) {
				let genderHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.gender)
				genderHtml.selectedIndex = res.data.gender
				this.props.setValue(
					SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER,
					genders[Utils.BigNumber(res.data.gender).minus(1).toNumber()].displayName
				)
			}

			if (res.data) {
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, res.data.cid || "")
				this.props.setValue(
					SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS,
					res.data.address && res.data.address.full ? res.data.address.full : ""
				)
				this.props.setValue(
					SET_HOSPITEL_IPD_MODAL_REGISTER_DOB,
					res.data.dob
						? Utils.generateDateByFormat(res.data.dob, "yyyy-mm-dd").getTime()
						: Utils.generateDate().getTime()
				)
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, res.data.photo)
			}

			if (res.data && res.data.name) {
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, res.data.name.firstname || "")
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, res.data.name.lastname || "")
			}

			if (res.data && res.data.nameEN) {
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, res.data.nameEN.firstname || "")
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, res.data.nameEN.lastname || "")
			}
		})

		socket.on("smc-removed", function (data) {})
		socket.on("smc-incorrect", function (data) {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		})
		socket.on("smc-inserted", (data) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
			this.resetSmartCardInfo()
		})
	}

	onNameTitle(value) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, value > -1 ? nameTitles[value].displayName : "")
	}

	onFirstName(event) {
		if (typeof event.target.value === "string") {
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, event.target.value.trim())
		}
	}

	onLastName(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, event.target.value.trim())
	}

	onFirstNameEng(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, Utils.trim(e.target.value))
	}

	onLastNameEng(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, Utils.trim(e.target.value))
	}

	onIdentificationNumber(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, event.target.value.trim())
	}

	onGender(value) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, value > -1 ? genders[value].displayName : "")
	}

	onDOB(date) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, date.getTime())
	}

	onTelPrimary(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, event.target.value.trim())
	}

	onTelSecondary(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, e.target.value)
	}

	onTelTertiary(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, e.target.value)
	}

	onAddress(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
				{this.props.ipd.modalRegister.photo && (
					<Row>
						<Col style={{ alignItems: "center" }}>
							<Image width={100} src={ServerUtils.getBase64Img(this.props.ipd.modalRegister.photo)} />
						</Col>
					</Row>
				)}
				<Row>
					<Col style={{ marginTop: "10px" }} md={3}>
						<Select
							required
							id={ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.title}
							showSearch
							size={"large"}
							placeholder={translate(ResourceAssistance.Hospitel.title)}
							status={!this.props.ipd.modalRegister.title ? "error" : undefined}
							value={this.props.ipd.modalRegister.title ? this.props.ipd.modalRegister.title : undefined}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={this.onNameTitle}
						>
							{Utils.renderSelects(nameTitles, false)}
						</Select>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.firstName)}
							value={this.props.ipd.modalRegister.firstName}
							onChange={this.onFirstName}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.lastName)}
							value={this.props.ipd.modalRegister.lastName}
							onChange={this.onLastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3} />
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.firstNameEng)}
							value={this.props.ipd.modalRegister.firstNameEng}
							onChange={this.onFirstNameEng}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.lastNameEng)}
							value={this.props.ipd.modalRegister.lastNameEng}
							onChange={this.onLastNameEng}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={{ marginTop: "10px" }} md={3}>
						<Select
							required
							id={ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.gender}
							showSearch
							size={"large"}
							placeholder={translate(ResourceAssistance.Hospitel.gender)}
							status={!this.props.ipd.modalRegister.gender ? "error" : undefined}
							value={this.props.ipd.modalRegister.gender ? this.props.ipd.modalRegister.gender : undefined}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							onChange={this.onGender}
						>
							{Utils.renderSelects(genders, false)}
						</Select>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Hospitel.identification)}
							value={this.props.ipd.modalRegister.identificationNumber}
							onChange={this.onIdentificationNumber}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.dateOfBirth)}</InputGroup.Text>
							</InputGroup.Prepend>

							<DatePicker
								inputClassName={ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.dob}
								maximumDate={Utils.generateDate(0, 0, -1)}
								isShowTime={false}
								startDate={Utils.generateDateFromLong(this.props.ipd.modalRegister.dob)}
								locale={{ yearOffSet: 543 }}
								onChange={this.onDOB}
							/>

							<InputGroup.Append>
								<InputGroup.Text>{translate(ResourceAssistance.Hospitel.age)}</InputGroup.Text>
							</InputGroup.Append>
							<InputGroup.Append>
								<InputGroup.Text>{Utils.calculateAge(this.props.ipd.modalRegister.dob)}</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.tel}
							onChange={this.onTelPrimary}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.telSecondary}
							onChange={this.onTelSecondary}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.telTertiary}
							onChange={this.onTelTertiary}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.address)}
							value={this.props.ipd.modalRegister.address}
							onChange={this.onAddress}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalRegisterInfo))
