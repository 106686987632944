import React from "react"
import { Col, Container, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap"
import { AiOutlinePercentage } from "react-icons/ai"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setPoEditDiscount,
	setPoEditTaxedTotalPrice,
	setPoModalEditBillingContact,
	setPoModalEditPaymentTerms,
	setPoModalEditRequiredDate,
	setPoModalEditRoutingId,
	setPoModalEditSelectedPayment,
	setPoModalEditShippingContact,
} from "~/redux/action"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import POEditAction from "./POEditAction"

class POEditDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onRoutingId = this.onRoutingId.bind(this)
		this.onDiscountChange = this.onDiscountChange.bind(this)
		this.onPaymentType = this.onPaymentType.bind(this)
		this.onPaymentTerms = this.onPaymentTerms.bind(this)
		this.onRequiredByDate = this.onRequiredByDate.bind(this)
		this.onShippingContact = this.onShippingContact.bind(this)
		this.onBillingContact = this.onBillingContact.bind(this)
	}

	componentDidMount() {
		if (this.props.selectedSupplier.supplier.orderDetail.routingId) {
			this.props.setPoModalEditRoutingId(this.props.selectedSupplier.supplier.orderDetail.routingId)
		}

		this.props.setPoEditTaxedTotalPrice(this.props.selectedSupplier.supplier)
		this.props.setPoEditDiscount(this.props.selectedSupplier.supplier.orderDetail.discount)
		if (this.props.selectedSupplier.supplier.orderDetail.payment) {
			let paymentHtml = document.getElementById(ResourceAssistance.ID.PO.modalEdit.paymentType)
			let idx = this.props.edit.payments.findIndex(
				(each) => each.id === this.props.selectedSupplier.supplier.orderDetail.payment.id
			)
			this.props.setPoModalEditSelectedPayment(idx, this.props.edit.payments[idx])
			paymentHtml.selectedIndex = idx + 1
		}

		this.props.setPoModalEditPaymentTerms(
			this.props.selectedSupplier.supplier.orderDetail.paymentTerms
				? this.props.selectedSupplier.supplier.orderDetail.paymentTerms
				: ""
		)

		if (this.props.selectedSupplier.supplier.orderDetail.requiredDateTime) {
			this.props.setPoModalEditRequiredDate(this.props.selectedSupplier.supplier.orderDetail.requiredDateTime)
		} else {
			this.props.setPoModalEditRequiredDate(Utils.generateDate(0, 0, 7, 0, 0, 0).getTime())
		}

		if (this.props.selectedSupplier.supplier.orderDetail.shippingContact) {
			this.props.setPoModalEditShippingContact(this.props.selectedSupplier.supplier.orderDetail.shippingContact)
		} else {
			this.props.setPoModalEditShippingContact("")
		}

		if (this.props.selectedSupplier.supplier.orderDetail.billingContact) {
			this.props.setPoModalEditBillingContact(this.props.selectedSupplier.supplier.orderDetail.billingContact)
		} else {
			this.props.setPoModalEditBillingContact("")
		}
	}

	componentWillUnmount() {
		this.props.setPoModalEditSelectedPayment(-1, null)
		this.props.setPoModalEditPaymentTerms("")
		this.props.setPoModalEditRequiredDate(null)
		this.props.setPoModalEditRoutingId("")
		this.props.setPoModalEditShippingContact("")
		this.props.setPoModalEditBillingContact("")
	}

	onRoutingId(event) {
		this.props.setPoModalEditRoutingId(event.target.value.toUpperCase())
	}

	onDiscountChange(event) {
		this.props.setPoEditDiscount(event.target.value)
	}

	onPaymentType(event) {
		this.props.setPoModalEditSelectedPayment(event.target.value, this.props.edit.payments[event.target.value])
	}

	onPaymentTerms(event) {
		this.props.setPoModalEditPaymentTerms(event.target.value)
	}

	onRequiredByDate(date) {
		this.props.setPoModalEditRequiredDate(date.getTime())
	}

	onShippingContact(event) {
		this.props.setPoModalEditShippingContact(event.target.value)
	}

	onBillingContact(event) {
		this.props.setPoModalEditBillingContact(event.target.value)
	}

	render() {
		return (
			<Container fluid>
				<POEditAction />
				<Row>
					<Col md={4}></Col>
					<Col>
						<FormLabel className={ResourceAssistance.CSS.textCenter}>
							{this.props.selectedSupplier.supplier.code}-{this.props.selectedSupplier.supplier.name}
						</FormLabel>
					</Col>
					<Col md={4}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.memoId)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							type={ResourceAssistance.FormControl.type.text}
							size={ResourceAssistance.FormControl.size.sm}
							value={this.props.edit.routingId}
							onChange={this.onRoutingId}
						/>
					</Col>
					<Col md={3}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.totalPrice)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							type={ResourceAssistance.FormControl.type.text}
							size={ResourceAssistance.FormControl.size.sm}
							disabled
							value={Utils.formatNumWithComma(this.props.edit.totalPriceBeforeTax)}
						/>
					</Col>
					<Col md={3}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col md={3}>
						<FormLabel>{translate(ResourceAssistance.Message.discount)}</FormLabel>
					</Col>
					<Col>
						<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
							<Col>
								<InputGroup size={ResourceAssistance.FormControl.size.sm}>
									<FormControl
										type={ResourceAssistance.FormControl.type.number}
										size={ResourceAssistance.FormControl.size.sm}
										value={this.props.edit.discount}
										onChange={this.onDiscountChange}
									/>
									<InputGroup.Append>
										<InputGroup.Text>{<AiOutlinePercentage />}</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
							<Col>
								<FormControl
									type={ResourceAssistance.FormControl.type.text}
									size={ResourceAssistance.FormControl.size.sm}
									disabled
									value={Utils.formatNumWithComma(this.props.edit.discountPrice)}
								/>
							</Col>
						</Row>
					</Col>
					<Col md={3}></Col>
				</Row>

				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.paymentType)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.PO.modalEdit.paymentType}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onPaymentType}
						>
							{Utils.renderOptions(this.props.edit.payments)}
						</FormControl>
					</Col>
					<Col md={3}></Col>
				</Row>

				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.paymentTerms)}</FormLabel>
					</Col>
					<Col>
						<InputGroup size={ResourceAssistance.FormControl.size.sm}>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.number}
								size={ResourceAssistance.FormControl.size.sm}
								value={this.props.edit.paymentTerms}
								min="1"
								step="1"
								onChange={this.onPaymentTerms}
							/>
							<InputGroup.Append>
								<InputGroup.Text>Days</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Col>
					<Col md={3}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.requiredByDate)}</FormLabel>
					</Col>
					<Col>
						<DatePicker
							isShowTime={false}
							startDate={
								new Date(
									this.props.selectedSupplier.supplier.orderDetail.requiredDateTime
										? this.props.selectedSupplier.supplier.orderDetail.requiredDateTime
										: Utils.generateDate(0, 0, 7, 0, 0, 0).getTime()
								)
							}
							onChange={this.onRequiredByDate}
						/>
					</Col>
					<Col md={3}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.shippingContact)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.tel}
							size={ResourceAssistance.FormControl.size.sm}
							value={this.props.edit.shippingContact}
							onChange={this.onShippingContact}
						/>
					</Col>
					<Col md={3}></Col>
				</Row>
				<Row>
					<Col md={3}></Col>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.billingContact)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.tel}
							size={ResourceAssistance.FormControl.size.sm}
							value={this.props.edit.billingContact}
							onChange={this.onBillingContact}
						/>
					</Col>
					<Col md={3}></Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	edit: state.purchaseOrder.edit,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPoEditTaxedTotalPrice,
			setPoEditDiscount,
			setPoModalEditSelectedPayment,
			setPoModalEditPaymentTerms,
			setPoModalEditRequiredDate,
			setPoModalEditRoutingId,
			setPoModalEditShippingContact,
			setPoModalEditBillingContact,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POEditDetails)
