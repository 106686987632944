import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"

class PupilSizeRemark extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions style={{ height: "100%" }} size={"small"} column={1}>
						<Descriptions.Item
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontWeight: "bold" }}
							label={translate(ResourceAssistance.Message.reactionToLight)}
						>
							{translate(ResourceAssistance.Remark.reactionToLight)}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

export default injectIntl(PupilSizeRemark, { forwardRef: true })
