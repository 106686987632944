import { Radio } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormCheck, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalDoctorOrderModify extends React.Component {
	durations = [
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.days }),
			value: 1440,
		},
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.hours }),
			value: 60,
		},
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.minutes }),
			value: 1,
		},
	]

	ETime = {
		NONE: ResourceAssistance.CONSTANT.NONE,
		BEFORE_MEAN: this.props.intl.formatMessage({ id: ResourceAssistance.Message.beforeMeal }),
		AFTER_MEAN: this.props.intl.formatMessage({ id: ResourceAssistance.Message.afterMeal }),
		BEFORE_BED: this.props.intl.formatMessage({ id: ResourceAssistance.Message.beforeBed }),
	}

	constructor(props) {
		super(props)

		this.state = {
			time: this.ETime.NONE,
		}
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onNotes = this.onNotes.bind(this)
		this.onMedicationUsage = this.onMedicationUsage.bind(this)
		this.onPRN = this.onPRN.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onDuration = this.onDuration.bind(this)
		this.onDurationType = this.onDurationType.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onTime = this.onTime.bind(this)
	}

	componentDidMount() {
		this.loadUnits()
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION, 0, this.durations[0])
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.modalDoctorOrder.startDateTime !== this.props.hospitel.modalDoctorOrder.startDateTime) {
			if (this.props.hospitel.modalDoctorOrder.startDateTime > this.props.hospitel.modalDoctorOrder.endDateTime) {
				this.props.setValue(
					SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME,
					Utils.generateDateFromLong(this.props.hospitel.modalDoctorOrder.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
				)
			}
		}

		if (
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.durationQty, this.props.hospitel.modalDoctorOrder.durationQty) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedUnit, this.props.hospitel.modalDoctorOrder.selectedUnit) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.duration, this.props.hospitel.modalDoctorOrder.duration) ||
			!_.isEqual(
				prevProps.hospitel.modalDoctorOrder.selectedDuration,
				this.props.hospitel.modalDoctorOrder.selectedDuration
			) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.isPRN, this.props.hospitel.modalDoctorOrder.isPRN) ||
			!_.isEqual(prevState.time, this.state.time)
		) {
			let usage = Utils.trim(this.props.hospitel.modalDoctorOrder.notes)

			if (
				prevProps.hospitel.modalDoctorOrder.selectedItem.item &&
				prevProps.hospitel.modalDoctorOrder.selectedDuration.duration &&
				prevProps.hospitel.modalDoctorOrder.selectedUnit.unit
			) {
				let prevDuration =
					prevProps.hospitel.modalDoctorOrder.durationQty +
					" " +
					prevProps.hospitel.modalDoctorOrder.selectedUnit.unit.displayName +
					" " +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.every }) +
					" " +
					prevProps.hospitel.modalDoctorOrder.duration +
					" " +
					prevProps.hospitel.modalDoctorOrder.selectedDuration.duration.displayName

				if (prevState.time !== ResourceAssistance.CONSTANT.NONE) {
					prevDuration = prevDuration + " (" + prevState.time + ")"
				}

				if (prevProps.hospitel.modalDoctorOrder.isPRN) {
					prevDuration = prevDuration.concat(" ", ResourceAssistance.CONSTANT.PRN)
				}

				usage = Utils.trim(usage.replace(prevDuration, ""))
			}

			if (
				this.props.hospitel.modalDoctorOrder.durationQty &&
				this.props.hospitel.modalDoctorOrder.selectedUnit.unit &&
				this.props.hospitel.modalDoctorOrder.duration &&
				this.props.hospitel.modalDoctorOrder.selectedDuration.duration
			) {
				let duration =
					this.props.hospitel.modalDoctorOrder.durationQty +
					" " +
					this.props.hospitel.modalDoctorOrder.selectedUnit.unit.displayName +
					" " +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.every }) +
					" " +
					this.props.hospitel.modalDoctorOrder.duration +
					" " +
					this.props.hospitel.modalDoctorOrder.selectedDuration.duration.displayName

				if (this.state.time !== ResourceAssistance.CONSTANT.NONE) {
					duration = duration + " (" + this.state.time + ")"
				}

				if (this.props.hospitel.modalDoctorOrder.isPRN) {
					duration = duration.concat(" ", ResourceAssistance.CONSTANT.PRN)
				}

				usage = Utils.trim(duration.concat(" ", usage))
			}

			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES, usage)
		}

		if (
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedItem, this.props.hospitel.modalDoctorOrder.selectedItem)
		) {
			let durationTypeDOM = document.getElementById(
				ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.durationType
			)
			durationTypeDOM.selectedIndex = 0

			let durationUnitDOM = document.getElementById(ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.unit)
			durationUnitDOM.selectedIndex = 0

			this.reset()
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK, false)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION, "")
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION, 0, this.durations[0])
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT, -1, null)
	}

	loadUnits() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getUnits,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS, res.data.units)
		}
		let errorHandler = (error) => {}
		let reqInterceptor = (config) => {}
		let resInterceptor = (response) => {}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onStartDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME, date.getTime())
	}

	onNotes(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES, event.target.value)
	}

	onMedicationUsage(event) {
		let usage = this.props.hospitel.modalDoctorOrder.notes.replace(/, +/g, "")
		if (event.target.checked) {
			usage = Utils.trim(usage).replace(/, +/g, "")
			usage = usage.concat(_.isEmpty(usage) ? "" : ", ", event.target.value)
		} else {
			usage = usage.replace(event.target.value, "")
		}
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES, usage)
	}

	onPRN(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK, event.target.checked)
	}

	onQty(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY, event.target.value)
	}

	onDuration(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION, event.target.value)
	}

	onDurationType(event) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION,
			event.target.value,
			this.durations[event.target.value]
		)
	}

	onUnit(event) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT,
			event.target.value,
			this.props.hospitel.modalDoctorOrder.units[event.target.value]
		)
	}

	onTime(e) {
		this.setState({
			time: e.target.value,
		})
	}

	render() {
		return (
			<Fragment>
				<Row></Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.date)}</legend>
							<Row noGutters>
								<Col md="auto">
									<DatePicker
										minDate={Utils.generateDate()}
										showDisabledMonthNavigation
										disabled={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
										startDate={Utils.generateDateFromLong(this.props.hospitel.modalDoctorOrder.startDateTime)}
										onChange={this.onStartDate}
									/>
								</Col>
								<Col>
									<FormLabel style={{ textAlign: "center" }}>{translate(ResourceAssistance.Message.to)}</FormLabel>
								</Col>
								<Col md="auto">
									<DatePicker
										minDate={Utils.generateDateFromLong(this.props.hospitel.modalDoctorOrder.startDateTime)}
										showDisabledMonthNavigation
										disabled={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
										startDate={Utils.generateDateFromLong(this.props.hospitel.modalDoctorOrder.endDateTime)}
										onChange={this.onEndDate}
										isShowDateRange
										shouldCloseOnSelect={false}
										rangeStartDate={Utils.generateDateFromLong(this.props.hospitel.modalDoctorOrder.startDateTime)}
									/>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>

				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.duration)}</legend>
							<Row>
								<Col md="auto">
									<FormGroup controlId={"prn"}>
										<FormCheck
											label={ResourceAssistance.CONSTANT.PRN}
											checked={this.props.hospitel.modalDoctorOrder.isPRN}
											onChange={this.onPRN}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
								<Col>
									<InputGroup size={ResourceAssistance.FormControl.size.sm}>
										<FormControl
											required
											id="qty"
											type={ResourceAssistance.FormControl.type.number}
											value={this.props.hospitel.modalDoctorOrder.durationQty}
											onChange={this.onQty}
										/>
										<FormControl
											required
											id={ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.unit}
											as={ResourceAssistance.FormControl.as.select}
											onChange={this.onUnit}
										>
											{Utils.renderOptions(this.props.hospitel.modalDoctorOrder.units, true)}
										</FormControl>
										<InputGroup.Append>
											<InputGroup.Text>{translate(ResourceAssistance.Message.every)}</InputGroup.Text>
										</InputGroup.Append>

										<FormControl
											required
											type={ResourceAssistance.FormControl.type.number}
											value={this.props.hospitel.modalDoctorOrder.duration}
											onChange={this.onDuration}
										/>
										<FormControl
											required
											id={ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.durationType}
											as={ResourceAssistance.FormControl.as.select}
											onChange={this.onDurationType}
										>
											{Utils.renderOptions(this.durations, false)}
										</FormControl>
									</InputGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<Radio.Group value={this.state.time} onChange={this.onTime}>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.NONE}>
											{this.ETime.NONE}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.BEFORE_MEAN}>
											{this.ETime.BEFORE_MEAN}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.AFTER_MEAN}>
											{this.ETime.AFTER_MEAN}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.BEFORE_BED}>
											{this.ETime.BEFORE_BED}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>

				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.medicationUsage)}</legend>
							{this.props.hospitel.modalDoctorOrder.selectedItem.item &&
								this.props.hospitel.modalDoctorOrder.selectedItem.item.medicationUsage && (
									<Row>
										<Col>
											{/* <FormGroup controlId={"medicationUsage"}>
												<FormCheck
													label={this.props.hospitel.modalDoctorOrder.selectedItem.item.medicationUsage}
													checked={_.includes(
														this.props.hospitel.modalDoctorOrder.notes,
														this.props.hospitel.modalDoctorOrder.selectedItem.item.medicationUsage
													)}
													onChange={this.onMedicationUsage}
													value={this.props.hospitel.modalDoctorOrder.selectedItem.item.medicationUsage}
												/>
											</FormGroup> */}
											<FormLabel>{this.props.hospitel.modalDoctorOrder.selectedItem.item.medicationUsage}</FormLabel>
										</Col>
									</Row>
								)}
							<Row>
								<Col>
									<InputGroup>
										<FormControl
											required
											as={ResourceAssistance.FormControl.as.textArea}
											rows={3}
											disabled={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
											value={this.props.hospitel.modalDoctorOrder.notes}
											onChange={this.onNotes}
										/>
									</InputGroup>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setObjArray,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderModify))
