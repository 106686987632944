import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import ReportTitle from "~/report/component/ReportTitle"
import SignatureFooter from "~/report/component/SignatureFooter"

class ReportPurchaseOrderDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.purchaseOrder,
				items: [],
			},
		}

		// window.onstorage = (e) => {
		// 	if (e.key != "reportPo") return
		// 	this.setState({
		// 		data: Object.assign({}, this.state.data, {
		// 			...this.state.data,
		// 			...JSON.parse(e.newValue),
		// 		}),
		// 	})
		// }
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Shipping */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.info, { marginRight: 10 }]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>
									Purchase From/{this.state.data.purchaseFromIntl}:{" "}
								</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.vendorName}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.vendorAddress}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>Tel: {this.state.data.vendorTel}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.info, { marginRight: 10 }]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Ship-To/{this.state.data.shipToIntl}: </Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.shipToName}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.shipToAddress}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>Tel: {this.state.data.shippingContact}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.info]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Bill-To/{this.state.data.billToIntl}: </Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>{this.state.data.billToAddress}</Text>
								<Text style={ResourceAssistance.Report.styles.textItalic}>Tel: {this.state.data.billingContact}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* PO info */}
						<View style={ResourceAssistance.Report.styles.row}>
							<View style={[ResourceAssistance.Report.styles.col, styles.info]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Vendor ID/{this.state.data.vendorIdIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.vendorId}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Payment Type/{this.state.data.paymentTypeIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.paymentType}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Payment Terms/{this.state.data.paymentTermsIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.paymentTerms} Days</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Required By Date/{this.state.data.requiredByDateIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.requiredByDate}</Text>
									</View>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.col, styles.info]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>PO ID/{this.state.data.poIdIntl}: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.poId}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											PO Date/{this.state.data.poDateIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.poDate}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Memo ID/{this.state.data.memoIdIntl}:{" "}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.memoId}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Item Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.codeIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.descriptionIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>
										{this.state.data.qtyIntl}/{this.state.data.uomIntl}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.bonusIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitPriceIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.discountIntl} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>ID</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Code</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>Description</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Qty/UOM</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Bonus</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Unit Price</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Discount</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{/* Items */}
						{this.state.data.items.map((item, index) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
										<Text>{index + 1}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
										<Text>{item.code}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{item.displayName}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
										<Text>
											{Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber())}
											x{Utils.formatNumWithComma(item.minQtyPerOrder)} {item.unit}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bonus]}>
										<Text>{Utils.formatNumWithComma(item.bonus)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unitPrice]}>
										<Text>{Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2))}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
										<Text>{Utils.formatNumWithComma(item.discount)}%</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>
											{Utils.formatNumWithComma(
												Utils.BigNumber(item.amount)
													.dividedBy(item.minQtyPerOrder)
													.times(item.pricePerUnit)
													.times(1 - item.discount / 100)
													.dp(2)
													.toFixed(2)
											)}
										</Text>
									</View>
								</View>
							)
						})}
						<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />

						{/* Totals */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Remark/{this.state.data.remarkIntl}:
											</Text>
										</View>
									</View>
								</View>

								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Sub Total/{this.state.data.subTotalIntl}
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.subTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Discount/{this.state.data.discountIntl} {this.state.data.discount}%
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.discountTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 16 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>Total/{this.state.data.totalIntl}</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.total}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												VAT/{this.state.data.vatIntl} {this.state.data.vat}%
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.vatTotal}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 16 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Grand Total/{this.state.data.grandTotalIntl}
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.grandTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Signature */}
						<SignatureFooter data={this.state.data} isIssuerDisplay={false} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 115,
	},
	info: {
		justifyContent: "flex-start",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	id: {
		minWidth: "5%",
		maxWidth: "5%",
	},
	code: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	description: {
		minWidth: "25%",
		maxWidth: "25%",
	},
	qty: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	bonus: {
		minWidth: "6%",
		maxWidth: "6%",
	},
	unitPrice: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	discount: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	amount: {
		minWidth: "10%",
		maxWidth: "10%",
	},
})

export default ReportPurchaseOrderDocument
