import React, { Fragment } from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setSelected, setLoadingAction } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REPORT_HOSPITEL_PATIENT_BRANCHES,
	SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS,
	SET_REPORT_HOSPITEL_PATIENT_ORGS,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class ReporthospitelPatientFilterLocation extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onDepartment = this.onDepartment.bind(this)
	}

	componentDidMount() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.hospitel.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.patient.selectedOrg.index !== this.props.hospitel.patient.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.REPORT.hospitel.patient.branch)
			branchHtml.selectedIndex = 0
			this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH, -1, null)
			if (this.props.hospitel.patient.selectedOrg.org) {
				this.props.setObjArray(
					SET_REPORT_HOSPITEL_PATIENT_BRANCHES,
					this.props.hospitel.patient.selectedOrg.org.locations
				)
			} else {
				this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_BRANCHES, [])
			}
		}

		if (prevProps.hospitel.patient.selectedBranch.index !== this.props.hospitel.patient.selectedBranch.index) {
			let department = document.getElementById(ResourceAssistance.ID.REPORT.hospitel.patient.department)
			department.selectedIndex = 0
			this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT, -1, null)
			if (this.props.hospitel.patient.selectedBranch.branch) {
				this.props.setObjArray(
					SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS,
					this.props.hospitel.patient.selectedBranch.branch.locations
				)
			} else {
				this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_ORGS, [])
		this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_BRANCHES, [])
		this.props.setObjArray(SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS, [])
		this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG, -1, null)
		this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH, -1, null)
		this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT, -1, null)
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG,
				event.target.value,
				this.props.hospitel.patient.orgs[event.target.value]
			)
		} else {
			this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG, -1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH,
				event.target.value,
				this.props.hospitel.patient.branches[event.target.value]
			)
		} else {
			this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH, -1, null)
		}
	}

	onDepartment(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT,
				event.target.value,
				this.props.hospitel.patient.departments[event.target.value]
			)
		} else {
			this.props.setSelected(SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT, -1, null)
		}
	}

	render() {
		return (
			<Fragment>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.location)}</legend>
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.org)}</InputGroup.Text>
								</InputGroup.Prepend>

								<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
									{Utils.renderOptions(this.props.hospitel.patient.orgs)}
								</FormControl>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.branch)}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									required
									id={ResourceAssistance.ID.REPORT.hospitel.patient.branch}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onBranch}
								>
									{Utils.renderOptions(this.props.hospitel.patient.branches)}
								</FormControl>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.department)}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									required
									id={ResourceAssistance.ID.REPORT.hospitel.patient.department}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onDepartment}
								>
									{Utils.renderOptions(this.props.hospitel.patient.departments)}
								</FormControl>
							</InputGroup>
						</Col>
					</Row>
				</fieldset>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.report.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReporthospitelPatientFilterLocation)
