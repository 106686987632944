import React from "react"
import { Button, Col, Container, Dropdown, FormCheck, Row, Spinner } from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import DropdownMenu from "react-bootstrap/DropdownMenu"
import DropdownToggle from "react-bootstrap/DropdownToggle"
import { FaRegEdit } from "react-icons/fa"
import { FiRefreshCcw } from "react-icons/fi"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	onModalElementDisplayAction,
	setAdminLocDisplayBranch,
	setAdminLocDisplayInventory,
	setAdminLocDisplayLocation,
	setAdminLocDisplayOrg,
	setAdminlocModalNLTitle,
	setLoadingAction,
	setLocation,
	setLocationDisplayInactive,
	setLocationType,
	setLocSearchText,
	setParentLocs,
	setParentOrgLocs,
	setSelectedLoc,
} from "~/redux/action"
import {
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_LOCATION_LOADING,
	SET_LOC_BRANCH,
	SET_LOC_GENERAL,
	SET_LOC_INVENTORY,
	SET_LOC_IPD,
	SET_LOC_OPD,
	SET_LOC_ORG,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import "./location.css"

const EType = {
	Org: "ORG",
	Branch: "BRANCH",
	Inventory: "INVENTORY",
	General_Location: "GENERAL_LOCATION",
	IPD: "IPD",
	OPD: "OPD",
	PHARMACY: "PHARMACY",
}
class Location extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)
		this.state = {
			componentName: "Location",
		}

		this.onSelectRowClick = this.onSelectRowClick.bind(this)
		this.onAddLocationClick = this.onAddLocationClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
		this.onRefreshClick = this.onRefreshClick.bind(this)
		this.loadLocs = this.loadLocs.bind(this)
		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onInventory = this.onInventory.bind(this)
		this.onLocation = this.onLocation.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
	}

	componentDidMount() {
		this.loadLocs()
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.loc.displayOrg !== this.props.loc.displayOrg ||
			prevProps.loc.displayBranch !== this.props.loc.displayBranch ||
			prevProps.loc.displayInventory !== this.props.loc.displayInventory ||
			prevProps.loc.displayLocation !== this.props.loc.displayLocation
		) {
			this.props.setLocation(this.props.loc.locations.locations)
		}
	}

	loadLocs() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.location.loadLocs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			Utils.convertToObject(res.data.locations)
			this.props.setLocation(res.data.locations)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_LOCATION_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_LOCATION_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	onSelectRowClick(item, index) {
		this.props.setSelectedLoc(index, this.props.loc.locations.filtered[index])
	}

	onAddLocationClick(event) {
		this.props.setSelectedLoc(-1, null)
		switch (event.target.name) {
			case EType.Org:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.org))
				this.props.setLocationType(SET_LOC_ORG, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, false)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.Branch:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.branch))
				this.props.setLocationType(SET_LOC_BRANCH, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.Inventory:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.inventory))
				this.props.setLocationType(SET_LOC_INVENTORY, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)

				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.General_Location:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_GENERAL, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.IPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Hospitel.ipd))
				this.props.setLocationType(SET_LOC_IPD, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.OPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.opd))
				this.props.setLocationType(SET_LOC_OPD, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			default:
				break
		}
	}

	onEditBtnClick(event) {
		switch (this.props.loc.selectedLoc.loc.code.displayName) {
			case EType.Org:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.org))
				this.props.setLocationType(SET_LOC_ORG, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, false)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				break

			case EType.Branch:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.branch))
				this.props.setLocationType(SET_LOC_BRANCH, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)

				break

			case EType.Inventory:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.inventory))
				this.props.setLocationType(SET_LOC_INVENTORY, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)

				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				break

			case EType.PHARMACY:
			case EType.General_Location:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_GENERAL, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, true)
				break

			case EType.IPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_IPD, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				break

			case EType.OPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_OPD, true)
				this.props.setParentOrgLocs(
					this.props.loc.locations.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
				)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, true)
				break

			default:
				break
		}

		this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
	}

	onRefreshClick(event) {
		this.loadLocs()
	}

	onOrg(event) {
		this.props.setAdminLocDisplayOrg(event.target.checked)
	}

	onBranch(event) {
		this.props.setAdminLocDisplayBranch(event.target.checked)
	}

	onInventory(event) {
		this.props.setAdminLocDisplayInventory(event.target.checked)
	}

	onLocation(event) {
		this.props.setAdminLocDisplayLocation(event.target.checked)
	}

	onDisplayAll(event) {
		this.props.setSelectedLoc(-1, null)
		this.props.setLocationDisplayInactive(event.target.checked)
		this.props.setLocation(this.props.loc.locations.locations)
	}

	onSearchChange(event) {
		this.props.setSelectedLoc(-1, null)
		this.props.setLocSearchText(event.target.value)
		this.props.setLocation(this.props.loc.locations.locations)
	}

	render() {
		return (
			<div className={`${ResourceAssistance.CSS.fullFlex}`} style={{ padding: "0px 15px" }}>
				<Container fluid id={this.state.componentName} className={`border ${ResourceAssistance.CSS.fullFlex}`}>
					<Row>
						<Col>
							<SearchBox
								controlTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[ResourceAssistance.Message.name]}
								callbacks={[this.onSearchChange]}
								values={[this.props.loc.searchText]}
							/>
						</Col>
						<Col sm="auto">
							<div className="vertical"></div>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.org)}
								checked={this.props.loc.displayOrg}
								onChange={this.onOrg}
							/>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.branch)}
								checked={this.props.loc.displayBranch}
								onChange={this.onBranch}
							/>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.inventory)}
								checked={this.props.loc.displayInventory}
								onChange={this.onInventory}
							/>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.location)}
								checked={this.props.loc.displayLocation}
								onChange={this.onLocation}
							/>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.displayInactive)}
								checked={this.props.loc.displayInactive}
								onChange={this.onDisplayAll}
							/>
						</Col>
						<Col sm="auto">
							<div className="vertical"></div>
						</Col>
						<Col sm="auto">
							<Dropdown>
								<DropdownToggle
									variant={ResourceAssistance.Button.variant.primary}
									size={ResourceAssistance.Button.size.sm}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.add)}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem name={EType.Org} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.org)}
									</DropdownItem>
									<DropdownItem name={EType.Branch} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.branch)}
									</DropdownItem>
									<DropdownItem name={EType.Inventory} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.inventory)}
									</DropdownItem>
									<DropdownItem name={EType.General_Location} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.department)}
									</DropdownItem>
									<DropdownItem name={EType.IPD} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Hospitel.ipd)}
									</DropdownItem>
									<DropdownItem name={EType.OPD} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.opd)}
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</Col>

						<Col sm="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								disabled={this.props.loc.selectedLoc.index === -1}
								onClick={this.onEditBtnClick}
							>
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col sm="auto">
							<Button size={ResourceAssistance.Button.size.sm} onClick={this.onRefreshClick}>
								<FiRefreshCcw size={ResourceAssistance.ReactIcon.size} />
							</Button>
						</Col>
					</Row>
					<Row className={ResourceAssistance.CSS.fullSize}>
						<Col>
							<ScrollableTable
								striped
								enableHighlight
								isClearHighlight={this.props.loc.selectedLoc.index === -1}
								data={this.props.loc.locations}
								onClick={this.onSelectRowClick}
							/>
							{this.props.loc.locations.isLoading && (
								<Spinner
									animation={ResourceAssistance.Spinner.border}
									variant={ResourceAssistance.Button.variant.primary}
								/>
							)}
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	loc: state.admin.location,
	modal: state.modal.newLoc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			onModalElementDisplayAction,
			setAdminLocDisplayBranch,
			setAdminLocDisplayInventory,
			setAdminLocDisplayLocation,
			setAdminLocDisplayOrg,
			setAdminlocModalNLTitle,
			setLoadingAction,
			setLocSearchText,
			setLocation,
			setLocationDisplayInactive,
			setLocationType,
			setParentLocs,
			setParentOrgLocs,
			setSelectedLoc,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Location)
