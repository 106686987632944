import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { IoHourglassOutline } from "react-icons/io5"
import { GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_TF_FILTER_BY_LOCATION } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ReportTFByLocationFilterDetails from "./ReportTFByLocationFilterDetails"

const EStatus = {
	PENDING: "PENDING",
	IN_PROGRESS: "IN_PROGRESS",
	RECEIVED: "RECEIVED",
	CANCELLED: "CANCELLED",
}

class ReportTFByLocationFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportTFByLocationFilter",
		}

		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	generateStatusIcon(status) {
		switch (status) {
			case EStatus.IN_PROGRESS:
				return "\ue558"

			case EStatus.RECEIVED:
				return "\ue5ca"

			case EStatus.CANCELLED:
				return "\ue5cd"

			case EStatus.PENDING:
			default:
				return ""
		}
	}

	runReport(requests) {
		let data = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.transferByLocation,
			}),
			startDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.startDateTime,
			}),
			endDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.endDateTime,
			}),
			branchIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.branch,
			}),
			requestIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.requestId,
			}),
			locationIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.location,
			}),
			transferIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.transferId,
			}),
			dateIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.date,
			}),
			issuedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.issuedBy,
			}),
			certifiedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certifiedBy,
			}),
			approvedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.approvedBy,
			}),
			lotIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.lot,
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
			}),
			descriptionIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.description,
			}),
			brandIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.brandName,
			}),
			qtyIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.qty,
			}),
			unitIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unit,
			}),
			statusIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.status,
			}),
			certifiedIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certified,
			}),
			approvedIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.approved,
			}),

			startDateTime: Utils.formatDateTime(this.props.filter.startDateTime),
			endDateTime: Utils.formatDateTime(this.props.filter.endDateTime),
			branch: this.props.filter.selectedBranch.branch.displayName,
			certifiedIcon: this.props.filter.isCertified
				? ResourceAssistance.Symbol.Report.check
				: ResourceAssistance.Symbol.Report.cancel,
			approvedIcon: this.props.filter.isApproved
				? ResourceAssistance.Symbol.Report.check
				: ResourceAssistance.Symbol.Report.cancel,

			requests: requests
				.filter((request) => !_.isEmpty(request.transferOrders))
				.map((request) => {
					return Object.assign(
						{},
						{
							id: request.id,
							location: request.location.displayName,
							dateTime: Utils.formatDateTime(request.requesterApprovedDateTime),
							issuedBy: request.requesterApproved ? request.requester.lastName + " " + request.requester.firstName : "",
							transfers: request.transferOrders.map((transfer) => {
								return Object.assign(
									{},
									{
										id: transfer.id,
										dateTime: transfer.approval ? Utils.formatDate(transfer.lastModifiedDateTime) : "",
										certifiedBy: transfer.approval
											? transfer.approval.lastName + " " + transfer.approval.firstName
											: "",
										approvedBy: request.approvalApproved
											? request.approval.lastName + " " + request.approval.firstName
											: "",
										items: transfer.items.map((item) => {
											return Object.assign(
												{},
												{
													lot: item.lotNum,
													id: item.code,
													description: item.name,
													brand: item.brand,
													qty: Utils.formatNumWithComma(item.amount),
													unit: item.unit,
													status: this.generateStatusIcon(item.status),
												}
											)
										}),
									}
								)
							}),
						}
					)
				}),

			name: this.props.filter.selectedOrg.org.displayName,
			taxId: this.props.filter.selectedOrg.org.taxNo,
			address: this.props.filter.selectedBranch.branch.address.displayName,
			tel: this.props.filter.selectedBranch.branch.address.tel,
			fax: this.props.filter.selectedBranch.branch.address.fax,
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Tf.byLocation, "_blank")
		myWindow.data = data
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_TF_FILTER_BY_LOCATION, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.tf.getTFs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				orgId: this.props.filter.selectedOrg.org.id,
				branchId: this.props.filter.selectedBranch.branch.id,
				certified: this.props.filter.isCertified,
				approved: this.props.filter.isApproved,
			},
		}
		let callback = (res) => {
			this.runReport(res.data.requests)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.reportByLocation)}</Badge>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportTFByLocationFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.tf.isReportByLocationDisplay,
	filter: state.report.tf.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportTFByLocationFilter))
