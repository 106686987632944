import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportIntakeOutputDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				intakeOutputs: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row, { justifyContent: "center" }]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Date Based Intake Output */}
						{this.state.data.intakeOutputs.map((intakeOutputs) => {
							return (
								<Fragment>
									<View wrap={false} style={[{ flexGrow: 1 }]}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Date: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { marginRight: 10 }]}>
												<Text>{intakeOutputs.date}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Day: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{intakeOutputs.day} </Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />

										{/* Item Header */}
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.intake]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.intakeIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.noteIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.output]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.outputIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.noteIntl}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.intake]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Intake</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Amount(mL)</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Note</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.output]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Output</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Amount(mL)</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Note</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
										{intakeOutputs.intakeOutputs.map((io) => {
											return (
												<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
														<Text>{Utils.formatTime(io.dateTime)}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.intake]}>
														<Text>{io.intake}</Text>
													</View>
													<View
														style={[
															ResourceAssistance.Report.styles.col,
															styles.amount,
															{ textAlign: "right", paddingRight: 10 },
														]}
													>
														<Text>{Utils.formatNumberFromStr(io.intakeAmount)}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
														<Text>{io.intakeNote}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.output]}>
														<Text>{io.output}</Text>
													</View>
													<View
														style={[
															ResourceAssistance.Report.styles.col,
															styles.amount,
															{ textAlign: "right", paddingRight: 10 },
														]}
													>
														<Text>{Utils.formatNumWithComma(io.outputAmount)}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
														<Text>{io.outputNote}</Text>
													</View>
												</View>
											)
										})}
										<View style={[ResourceAssistance.Report.styles.horizontalLine, { flexGrow: 1 }]} />
										<View style={[ResourceAssistance.Report.styles.row, { textAlign: "right" }]}>
											<View style={[ResourceAssistance.Report.styles.col]} />
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader, { minWidth: 20 }]}>
												<Text>{this.state.data.totalIntakeIntl}/Total Intake(mL): </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, { maxWidth: 100 }]}>
												<Text>{Utils.formatNumWithComma(intakeOutputs.totalIntake)}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { textAlign: "right" }]}>
											<View style={[ResourceAssistance.Report.styles.col]} />
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader, { minWidth: 20 }]}>
												<Text>{this.state.data.totalOutputIntl}/Total Output(mL): </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, { maxWidth: 100 }]}>
												<Text>{Utils.formatNumWithComma(intakeOutputs.totalOutput)}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { textAlign: "right" }]}>
											<View style={[ResourceAssistance.Report.styles.col]} />
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader, { minWidth: 20 }]}>
												<Text>{this.state.data.ioBalanceIntl}/I/O Balance(mL): </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, { maxWidth: 100 }]}>
												<Text>{Utils.formatNumWithComma(intakeOutputs.ioBalance)}</Text>
											</View>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.separator]} />
								</Fragment>
							)
						})}
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	intake: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	output: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	amount: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	note: {
		minWidth: "14%",
		maxWidth: "14%",
	},
})

export default ReportIntakeOutputDoc
