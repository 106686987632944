import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdCheck, MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setTfModalTransferOrgs,
	setTfModalTransferPendingItems,
	setTfRequests,
	setTfSelectedTransfer,
	setTfSelectedTransferItem,
} from "~/redux/action"
import { SET_PAGE_LOADING, SET_TF_NEW_TRANSFER_DISPLAY, SET_TF_REQUEST_LOADING } from "~/redux/type"

class TFRightTransferActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onPrint = this.onPrint.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onTransfer = this.onTransfer.bind(this)
	}

	isNewBtnDisabled() {
		let itemSize = this.props.transfer.selectedRequest.request
			? this.props.transfer.selectedRequest.request.items.filter(
					(each) => !each.cancelled && each.amount !== each.transferredAmount
			  ).length
			: 0
		return (
			this.props.transfer.selectedRequest.index === -1 ||
			!this.props.transfer.selectedRequest.request.requesterApproved ||
			this.props.transfer.selectedRequest.request.approvalApproved ||
			itemSize === 0
		)
	}

	onPrint() {
		this.props.print(this.props.transfer.selectedTransfer.transfer)
	}

	onNew() {
		this.props.setTfSelectedTransfer(-1, null)
		this.props.setTfSelectedTransferItem(-1, null)

		this.props.loadOrgs(
			ResourceAssistance.Url.transfer.getOrgs,
			(res) => {
				this.props.setTfModalTransferOrgs(res.data.locations)
				this.props.onModalDisplayAction(SET_TF_NEW_TRANSFER_DISPLAY, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			{ orgId: this.props.transfer.selectedRequest.request.org.id }
		)
	}

	onEdit() {
		this.props.loadOrgs(
			ResourceAssistance.Url.transfer.getOrgs,
			(res) => {
				this.props.setTfModalTransferOrgs(res.data.locations)
				//assign transfer order infos to item
				let selectedTransfer = this.props.transfer.selectedTransfer.transfer
				let items = selectedTransfer.items
				items.forEach((each) => {
					Object.assign(each, {
						location: selectedTransfer.location.parent.displayName + "-" + selectedTransfer.location.displayName,
						locationId: selectedTransfer.location.id,
					})
				})

				this.props.setTfModalTransferPendingItems(items)

				this.props.onModalDisplayAction(SET_TF_NEW_TRANSFER_DISPLAY, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			{ orgId: this.props.transfer.selectedRequest.request.org.id }
		)
	}

	onTransfer() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.approve,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				transferId: this.props.transfer.selectedTransfer.transfer.id,
				userId: this.props.login.user.id,
			},
		}

		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onTransferred(isTransferred) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.setTransferred,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				items: [
					{
						transferOrderItemId: this.props.transfer.selectedTransferItem.item.id,
						transferred: isTransferred,
					},
				],
			},
		}

		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row className="flex-self-center">
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={
							this.props.transfer.selectedTransfer.index === -1 ||
							!this.props.transfer.selectedTransfer.transfer.approval
						}
						onClick={this.onPrint}
					>
						<ImPrinter size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.print)}
					</Button>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isNewBtnDisabled()}
						onClick={this.onNew}
					>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.newTransfer)}
					</Button>
				</Col>
				<Col sm="auto">
					<div className="vertical"></div>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						size={ResourceAssistance.Button.size.sm}
						disabled={
							this.props.transfer.selectedTransfer.index === -1 ||
							this.props.transfer.selectedTransfer.transfer.approval
						}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						<span>{translate(ResourceAssistance.Message.edit)}</span>
					</Button>
				</Col>
				<Col sm="auto">
					<div className="vertical"></div>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={
							this.props.transfer.selectedTransfer.index === -1 ||
							this.props.transfer.selectedTransfer.transfer.approval
						}
						onClick={this.onTransfer}
					>
						{this.props.transfer.selectedTransfer.transfer && this.props.transfer.selectedTransfer.transfer.approval ? (
							<MdCheck size={ResourceAssistance.ReactIcon.size} />
						) : (
							""
						)}
						<span>{translate(ResourceAssistance.Message.transfer)}</span>
					</Button>
				</Col>
				<Col sm="auto">
					<div className="vertical"></div>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={
							this.props.transfer.selectedRequest.index === -1 ||
							this.props.transfer.selectedTransfer.index === -1 ||
							this.props.transfer.selectedTransferItem.index === -1 ||
							!this.props.transfer.selectedTransfer.transfer.approval ||
							this.props.transfer.selectedRequest.request.approvalApproved ||
							this.props.transfer.selectedTransferItem.item.status === "RECEIVED"
						}
						onClick={() => {
							this.onTransferred(true)
						}}
					>
						<span>{translate(ResourceAssistance.Message.transferred)}</span>
					</Button>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.red}
						size={ResourceAssistance.Button.size.sm}
						disabled={
							this.props.transfer.selectedRequest.index === -1 ||
							this.props.transfer.selectedTransfer.index === -1 ||
							this.props.transfer.selectedTransferItem.index === -1 ||
							!this.props.transfer.selectedTransfer.transfer.approval ||
							this.props.transfer.selectedRequest.request.approvalApproved ||
							this.props.transfer.selectedTransferItem.item.status === "CANCELLED"
						}
						onClick={() => {
							this.onTransferred(false)
						}}
					>
						<span>{translate(ResourceAssistance.Message.cancelled)}</span>
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setTfModalTransferOrgs,
			setTfModalTransferPendingItems,
			setTfRequests,
			setTfSelectedTransfer,
			setTfSelectedTransferItem,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRightTransferActionRow)
