import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportInventoryByLocationDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.inventoryByLocation,
				inStockItems: [],
				inventories: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Filter */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Branch/{this.state.data.branchIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.branch}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Code/{this.state.data.codeIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.code}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Description/{this.state.data.descriptionIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.description}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Item Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.brandIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitIntl}</Text>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>ID</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Description</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Brand</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Unit</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{/* Item Info */}
						{this.state.data.inStockItems.map((item) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
											<Text>{item.id}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
											<Text style={ResourceAssistance.Report.styles.textItalic}>{item.description}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
											<Text>{item.brand}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
											<Text>{item.qty}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
											<Text>{item.unit}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalSubline} />

									{/* Lot Header */}
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]} />
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.lotIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.expired]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.expiredIntl}</Text>
											</View>
										</View>

										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]} />
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>Lot</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.expired]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Expired</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalSubline} />
									</View>

									{item.lots.map((lot) => {
										return (
											<Fragment>
												<View style={[ResourceAssistance.Report.styles.row, { marginBottom: 5, fontSize: 14 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
														<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.arrowIcon}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
														<Text>{lot.id}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
														<Text>{lot.qty}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.expired]}>
														<Text>{lot.expired}</Text>
													</View>
												</View>
											</Fragment>
										)
									})}
									<View style={ResourceAssistance.Report.styles.horizontalLine} />
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separator} />

						{this.state.data.inventories.map((inventory) => {
							return (
								<Fragment>
									{/* Inventory Info */}
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													Inventory/{this.state.data.inventoryIntl}:
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{inventory.name}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													Description/{this.state.data.descriptionIntl}:
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{inventory.description}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />
									</View>

									{/* Inventory Header */}
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailId]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailDescription]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailBrand]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.brandIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailUnit]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailCurrent]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.currentIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMin]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.minIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMax]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.maxIntl}</Text>
											</View>
										</View>

										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailId]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>ID</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailDescription]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Description</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailBrand]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Brand</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailUnit]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Unit</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailCurrent]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Current</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMin]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Min</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMax]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Max</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
									</View>

									{/* Inventory Detail Info */}
									{inventory.inventoryDetails.map((itemDetail) => {
										return (
											<Fragment>
												<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { marginBottom: 5 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailId]}>
														<Text>{itemDetail.id}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col, styles.itemDetailDescription]}>
														<Text style={ResourceAssistance.Report.styles.textItalic}>{itemDetail.description}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailBrand]}>
														<Text>{itemDetail.brand}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailUnit]}>
														<Text>{itemDetail.unit}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailCurrent]}>
														<Text>{itemDetail.current}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMin]}>
														<Text>{itemDetail.min}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.itemDetailMax]}>
														<Text>{itemDetail.max}</Text>
													</View>
												</View>
											</Fragment>
										)
									})}
									<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />
								</Fragment>
							)
						})}
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	id: {
		minWidth: "20%",
		maxWidth: "20%",
	},
	description: {
		minWidth: "42%",
		maxWidth: "42%",
	},
	brand: {
		minWidth: "20%",
		maxWidth: "20%",
	},
	qty: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	unit: {
		minWidth: "8%",
		maxWidth: "8%",
	},

	//Item details
	itemDetailId: {
		minWidth: "16%",
		maxWidth: "16%",
	},
	itemDetailDescription: {
		minWidth: "35%",
		maxWidth: "35%",
	},
	itemDetailBrand: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	itemDetailUnit: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	itemDetailCurrent: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	itemDetailMin: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	itemDetailMax: {
		minWidth: "9%",
		maxWidth: "9%",
	},
})

export default ReportInventoryByLocationDocument
