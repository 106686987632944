import _ from "lodash"
import React, { Fragment } from "react"
import {
	Button,
	Col,
	Container,
	Form,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import EnumAssistance from "~/enum/EnumAssistance"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	onModalElementDisplayAction,
	setAdminLocNewLocSelectedBranch,
	setAdminLocNewLocSelectedOrg,
	setLocationType,
	setParentLocs,
	setParentOrgLocs,
	setSelectedLoc,
} from "~/redux/action"
import {
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_LOC_BRANCH,
	SET_LOC_GENERAL,
	SET_LOC_INVENTORY,
	SET_LOC_ORG,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

const Property = {
	NAME: 1,
	DESCRIPTION: 2,
	ACTIVE: 3,
	PARENT_LOC: "ADMIN_LOC_NEW_LOC_PARENT_LOC",
	ADDRESS: 5,
	CITY: 6,
	PROVINCE: 7,
	POSTCODE: 8,
	PARENT_ORG: "ADMIN_LOC_NEW_LOC_PARENT_ORG",
	TEL: 9,
	FAX: 10,
	TAX_NO: 11,
	BILLING_ADDRESS: 12,
	NAME_ENG: 13,
	ADDRESS_ENG: 14,
	CITY_ENG: 15,
	PROVINCE_ENG: 16,
	PHARMACY_IND: 17,
}

class NewLocation extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(Property)

		this.state = this.initState()
		this.reset = this.reset.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
	}

	initState() {
		return {
			componentName: "NewLocation",
			name: "",
			nameEng: "",
			description: "",
			active: true,
			address: "",
			addressEng: "",
			city: "",
			cityEng: "",
			province: "",
			provinceEng: "",
			postcode: "",
			tel: "",
			fax: "",
			taxNo: "",
			billingAddress: "",
			isPharmacy: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				if (this.props.loc.selectedLoc.loc) {
					//init loc info
					this.setState({
						name: this.props.loc.selectedLoc.loc.displayName,
						nameEng: this.props.loc.selectedLoc.loc.displayNameEng,
						description: this.props.loc.selectedLoc.loc.description,
						active: this.props.loc.selectedLoc.loc.active,
						isPharmacy: this.props.loc.selectedLoc.loc.code.displayName === EnumAssistance.PHARMACY,
						address: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.street : "",
						addressEng: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.streetEng : "",
						city: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.city : "",
						cityEng: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.cityEng : "",
						province: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.province : "",
						provinceEng: this.props.loc.selectedLoc.loc.address
							? this.props.loc.selectedLoc.loc.address.provinceEng
							: "",
						postcode: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.postcode : "",
						tel: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.tel : "",
						fax: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.fax : "",
						taxNo: this.props.loc.selectedLoc.loc.taxNo,
						billingAddress: this.props.loc.selectedLoc.loc.address
							? this.props.loc.selectedLoc.loc.address.billingAddress
							: "",
					})

					//init org tag dropdown
					if (this.props.loc.isBranch) {
						let orgHtml = document.getElementById(Property.PARENT_ORG)
						let orgIdx = this.props.loc.parentOrgs.locs.findIndex(
							(each) => each.id === this.props.loc.selectedLoc.loc.parent.id
						)
						if (orgIdx > -1) {
							orgHtml.selectedIndex = orgIdx + 1
							this.props.setAdminLocNewLocSelectedOrg(orgIdx, this.props.loc.parentOrgs.locs[orgIdx])
						}
					} else {
						let orgHtml = document.getElementById(Property.PARENT_ORG)
						let org = this.props.loc.parentOrgs.locs.find((org) => {
							return org.locations.find((branch) => branch.id === this.props.loc.selectedLoc.loc.parent.id)
						})
						let orgIdx = this.props.loc.parentOrgs.locs.findIndex((each) => each.id === org.id)
						if (orgIdx > -1) {
							orgHtml.selectedIndex = orgIdx + 1
							this.props.setAdminLocNewLocSelectedOrg(orgIdx, this.props.loc.parentOrgs.locs[orgIdx])
						}
					}
				}
			} else {
				this.setState(this.initState())
				this.props.setAdminLocNewLocSelectedOrg(-1, null)
				this.props.setAdminLocNewLocSelectedBranch(-1, null)
				this.props.setParentOrgLocs([])
				this.props.setParentLocs([])
			}
		}

		if (this.props.isDisplay) {
			if (JSON.stringify(prevProps.loc.newLoc.selectedOrg) !== JSON.stringify(this.props.loc.newLoc.selectedOrg)) {
				if (this.props.loc.newLoc.selectedOrg.org) {
					this.props.setParentLocs(this.props.loc.newLoc.selectedOrg.org.locations)
				} else {
					this.props.setParentLocs([])
				}

				this.props.setAdminLocNewLocSelectedBranch(-1, null)
				let locHtml = document.getElementById(Property.PARENT_LOC)
				if (locHtml) {
					locHtml.selectedIndex = 0
				}
			}

			if (!_.isEqual(prevProps.loc.parentLocs.locs, this.props.loc.parentLocs.locs)) {
				//init branch tag dropdown
				if (this.props.loc.selectedLoc.loc) {
					let locHtml = document.getElementById(Property.PARENT_LOC)
					let locIdx = this.props.loc.parentLocs.locs.findIndex(
						(each) => each.id === this.props.loc.selectedLoc.loc.parent.id
					)
					if (locIdx > -1) {
						locHtml.selectedIndex = locIdx + 1
						this.props.setAdminLocNewLocSelectedBranch(locIdx, this.props.loc.parentLocs.locs[locIdx])
					}
				}
			}
		}
	}

	reset() {
		this.setState(this.initState())
		this.props.setSelectedLoc(-1, null)
		this.props.setLocationType(SET_LOC_ORG, false)
		this.props.setLocationType(SET_LOC_BRANCH, false)
		this.props.setLocationType(SET_LOC_INVENTORY, false)
		this.props.setLocationType(SET_LOC_GENERAL, false)
		this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, false)
		this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, false)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.loc.selectedLoc.loc, {
			...this.props.loc.selectedLoc.loc,
			displayName: this.state.name.trim(),
			description: this.state.description.trim(),
			active: this.state.active,
		})

		if (this.props.loc.isOrg) {
			target = Object.assign({}, this.props.loc.selectedLoc.loc, {
				...this.props.loc.selectedLoc.loc,
				taxNo: this.state.taxNo.trim(),
				displayNameEng: Utils.trim(this.state.nameEng),
			})
		} else if (this.props.loc.isBranch) {
			target = Object.assign({}, target, {
				address: Object.assign({}, target.address, {
					...target.address,
					street: this.state.address.trim(),
					streetEng: Utils.trim(this.state.addressEng),
					city: this.state.city.trim(),
					cityEng: Utils.trim(this.state.cityEng),
					province: this.state.province.trim(),
					provinceEng: Utils.trim(this.state.provinceEng),
					postcode: Number(this.state.postcode),
					tel: this.state.tel,
					fax: this.state.fax,
					billingAddress: this.state.billingAddress.trim(),
				}),
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedOrg.index === -1 ? 0 : this.props.loc.newLoc.selectedOrg.org.id,
				}),
			})
		} else if (this.props.loc.isInventory) {
			target = Object.assign({}, target, {
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedBranch.index === -1 ? 0 : this.props.loc.newLoc.selectedBranch.branch.id,
				}),
			})
		} else if (this.props.loc.isGeneralLocation) {
			target = Object.assign({}, target, {
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedBranch.index === -1 ? 0 : this.props.loc.newLoc.selectedBranch.branch.id,
					pharmacy: this.state.isPharmacy,
				}),
			})
		}

		return _.isEqual(this.props.loc.selectedLoc.loc, target)
	}

	getElementIdx(elementName) {
		let parentLoc = document.getElementsByName(String(elementName))
		let index = -1
		if (this.props.modal.isParentLocDisplay && parentLoc && parentLoc.length > 0 && parentLoc[0].length !== 0) {
			index = parentLoc[0].options[parentLoc[0].selectedIndex].value
		}
		return index
	}

	renderTypes(locs) {
		return locs.map((loc, key) => {
			return (
				<option key={key} value={key}>
					{loc.displayName}
				</option>
			)
		})
	}

	saveLoc() {
		let raw = JSON.stringify({
			id: this.props.loc.selectedLoc.loc ? this.props.loc.selectedLoc.loc.id : 0,
			name: Utils.trim(this.state.name),
			nameEng: Utils.trim(this.state.nameEng),
			description: Utils.trim(this.state.description),
			address: Utils.trim(this.state.address),
			addressEng: Utils.trim(this.state.addressEng),
			city: Utils.trim(this.state.city),
			cityEng: Utils.trim(this.state.cityEng),
			province: Utils.trim(this.state.province),
			provinceEng: Utils.trim(this.state.provinceEng),
			postcode: Utils.trim(this.state.postcode),
			tel: Utils.trim(this.state.tel),
			fax: Utils.trim(this.state.fax),
			taxNo: Utils.trim(this.state.taxNo),
			billingAddress: Utils.trim(this.state.billingAddress),
			parentLocId: this.props.loc.isBranch
				? this.props.loc.newLoc.selectedOrg.org.id
				: this.props.loc.newLoc.selectedBranch.index === -1
				? 0
				: this.props.loc.newLoc.selectedBranch.branch.id,
			active: this.state.active,
			userId: this.props.login.user.id,
			org: this.props.loc.isOrg,
			branch: this.props.loc.isBranch,
			inventory: this.props.loc.isInventory,
			generalLocation: this.props.loc.isGeneralLocation,
			ipd: this.props.loc.isIPD,
			opd: this.props.loc.isOPD,
			pharmacy: this.state.isPharmacy,
		})

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.location.saveLoc}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: raw,
		}

		let errorHandler = () => {
			this.setState(this.initState())
		}

		let callback = () => {}

		axios(params, callback, errorHandler)
	}

	onCloseBtn() {
		this.reset()
	}

	onDoneBtn(event) {
		event.preventDefault()
		event.stopPropagation()

		this.saveLoc()
		this.reset()
	}

	onPropertyChange(event) {
		switch (Number(event.target.name)) {
			case Property.NAME:
				this.setState({ name: event.target.value })
				break
			case Property.DESCRIPTION:
				this.setState({ description: event.target.value })
				break
			case Property.ACTIVE:
				this.setState({ active: event.target.checked })
				break
			case Property.ADDRESS:
				this.setState({ address: event.target.value })
				break
			case Property.CITY:
				this.setState({ city: event.target.value })
				break
			case Property.PROVINCE:
				this.setState({ province: event.target.value })
				break
			case Property.POSTCODE:
				this.setState({ postcode: event.target.value })
				break
			case Property.TEL:
				this.setState({ tel: event.target.value })
				break
			case Property.FAX:
				this.setState({ fax: event.target.value })
				break
			case Property.TAX_NO:
				this.setState({ taxNo: event.target.value })
				break
			case Property.BILLING_ADDRESS:
				this.setState({ billingAddress: event.target.value })
				break
			case Property.NAME_ENG:
				this.setState({ nameEng: event.target.value })
				break
			case Property.ADDRESS_ENG:
				this.setState({ addressEng: event.target.value })
				break
			case Property.CITY_ENG:
				this.setState({ cityEng: event.target.value })
				break
			case Property.PROVINCE_ENG:
				this.setState({ provinceEng: event.target.value })
				break
			case Property.PHARMACY_IND:
				this.setState({ isPharmacy: event.target.checked })
				break

			default:
				break
		}
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setAdminLocNewLocSelectedOrg(event.target.value, this.props.loc.parentOrgs.locs[event.target.value])
		} else {
			this.props.setAdminLocNewLocSelectedOrg(-1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setAdminLocNewLocSelectedBranch(event.target.value, this.props.loc.parentLocs.locs[event.target.value])
		} else {
			this.props.setAdminLocNewLocSelectedBranch(-1, null)
		}
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onCloseBtn}
			>
				<Form onSubmit={this.onDoneBtn}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{this.props.loc.newLoc.title}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container>
							<Row>
								<Col>
									<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
								</Col>
								<Col>
									<FormControl
										required
										type={ResourceAssistance.FormControl.type.text}
										name={Property.NAME}
										onChange={this.onPropertyChange}
										value={this.state.name}
									/>
								</Col>
							</Row>
							{this.props.modal.isNameEngDisplay && (
								<Row>
									<Col>
										<FormLabel>{translate(ResourceAssistance.Message.nameEng)}</FormLabel>
									</Col>
									<Col>
										<FormControl
											required
											type={ResourceAssistance.FormControl.type.text}
											name={Property.NAME_ENG}
											onChange={this.onPropertyChange}
											value={this.state.nameEng}
										/>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									<FormLabel>{translate(ResourceAssistance.Message.description)}</FormLabel>
								</Col>
								<Col>
									<FormControl
										required
										as={ResourceAssistance.FormControl.as.textArea}
										rows="3"
										name={Property.DESCRIPTION}
										value={this.state.description}
										onChange={this.onPropertyChange}
									/>
								</Col>
							</Row>
							{this.props.modal.isTaxNoDisplay && (
								<Row>
									<Col>
										<FormLabel>{translate(ResourceAssistance.Message.taxId)}</FormLabel>
									</Col>
									<Col>
										<FormControl
											required
											type={ResourceAssistance.FormControl.type.text}
											name={Property.TAX_NO}
											value={this.state.taxNo}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isParentOrgDisplay && (
								<Row>
									<Col>
										<FormLabel>{translate(ResourceAssistance.Message.org)}</FormLabel>
									</Col>
									<Col>
										<FormattedMessage id={ResourceAssistance.Message.loading}>
											{(placeholder) => (
												<FormControl
													required
													id={Property.PARENT_ORG}
													as={ResourceAssistance.FormControl.as.select}
													onChange={this.onOrg}
												>
													{this.props.loc.parentOrgs.isLoading ? (
														<option>{placeholder}</option>
													) : (
														Utils.renderOptions(this.props.loc.parentOrgs.locs)
													)}
												</FormControl>
											)}
										</FormattedMessage>
									</Col>
								</Row>
							)}
							{this.props.modal.isParentLocDisplay && (
								<Row id="displayParent">
									<Col>
										<FormLabel>{translate(ResourceAssistance.Message.branch)}</FormLabel>
									</Col>
									<Col>
										<FormattedMessage id={ResourceAssistance.Message.loading}>
											{(placeholder) => (
												<FormControl
													required
													id={Property.PARENT_LOC}
													as={ResourceAssistance.FormControl.as.select}
													onChange={this.onBranch}
												>
													{this.props.loc.parentLocs.isLoading ? (
														<option>{placeholder}</option>
													) : (
														Utils.renderOptions(this.props.loc.parentLocs.locs)
													)}
												</FormControl>
											)}
										</FormattedMessage>
									</Col>
								</Row>
							)}
							{this.props.modal.isAddressDisplay && (
								<Fragment>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.address)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												as={ResourceAssistance.FormControl.as.textArea}
												rows="2"
												name={Property.ADDRESS}
												value={this.state.address}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.addressEng)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												as={ResourceAssistance.FormControl.as.textArea}
												rows="2"
												name={Property.ADDRESS_ENG}
												value={this.state.addressEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.city)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.text}
												name={Property.CITY}
												value={this.state.city}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.cityEng)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.text}
												name={Property.CITY_ENG}
												value={this.state.cityEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.province)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.text}
												name={Property.PROVINCE}
												value={this.state.province}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.provinceEng)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.text}
												name={Property.PROVINCE_ENG}
												value={this.state.provinceEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.postcode)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.number}
												min="0"
												name={Property.POSTCODE}
												value={this.state.postcode}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.tel)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.tel}
												min="0"
												name={Property.TEL}
												value={this.state.tel}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.fax)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												type={ResourceAssistance.FormControl.type.tel}
												min="0"
												name={Property.FAX}
												value={this.state.fax}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormLabel>{translate(ResourceAssistance.Message.billingAddress)}</FormLabel>
										</Col>
										<Col>
											<FormControl
												required
												as={ResourceAssistance.FormControl.as.textArea}
												rows="3"
												name={Property.BILLING_ADDRESS}
												value={this.state.billingAddress}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
								</Fragment>
							)}
							{this.props.modal.isPharmacyIndDisplay && (
								<Row>
									<Col>
										<FormLabel>{translate(ResourceAssistance.Message.pharmacy)}</FormLabel>
									</Col>
									<Col>
										<ToggleCheckBox
											name={String(Property.PHARMACY_IND)}
											onClick={this.onPropertyChange}
											checked={this.state.isPharmacy}
										/>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
								</Col>
								<Col>
									<ToggleCheckBox
										name={String(Property.ACTIVE)}
										onClick={this.onPropertyChange}
										checked={this.state.active}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isNewLocationDisplay,
	modal: state.modal.newLoc,
	loc: state.admin.location,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			onModalElementDisplayAction,
			setAdminLocNewLocSelectedBranch,
			setAdminLocNewLocSelectedOrg,
			setLocationType,
			setParentLocs,
			setParentOrgLocs,
			setSelectedLoc,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(NewLocation)
