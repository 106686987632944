import React from "react"
import { Col, Container, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmUModalNUActive, setAdmUModalNUDescription, setAdmUModalNUName } from "~/redux/action"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmUModalNUDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmUModalNUName("")
		this.props.setAdmUModalNUDescription("")
		this.props.setAdmUModalNUActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.unit.modalNewUnit.name !== this.props.unit.modalNewUnit.name ||
			prevProps.unit.modalNewUnit.description !== this.props.unit.modalNewUnit.description
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.unit.modalNU.name)
		let nameInput = this.props.unit.modalNewUnit.name.trim().toLowerCase()
		let descriptionHtml = document.getElementById(ResourceAssistance.ID.ADM.unit.modalNU.description)
		let descriptionInput = this.props.unit.modalNewUnit.description.trim().toLowerCase()

		if (
			!nameInput ||
			this.props.unit.unitTable.original
				.filter((each) => !this.props.unit.selectedUnit.unit || this.props.unit.selectedUnit.unit.id !== each.id)
				.some((each) => each.displayName.trim().toLowerCase() === nameInput)
		) {
			nameHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
			descriptionHtml.setCustomValidity("")
		} else if (!descriptionInput) {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
		} else {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity("")
		}
	}

	onName(event) {
		this.props.setAdmUModalNUName(event.target.value)
	}

	onDescription(event) {
		this.props.setAdmUModalNUDescription(event.target.value)
	}

	onActive(event) {
		this.props.setAdmUModalNUActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.unit.modalNU.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.unit.modalNewUnit.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.description)}</FormLabel>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.unit.modalNU.description}
							as={ResourceAssistance.FormControl.as.textArea}
							value={this.props.unit.modalNewUnit.description}
							rows="5"
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabel>{translate(ResourceAssistance.Message.active)}</FormLabel>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.unit.modalNewUnit.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	unit: state.admin.itemConfig.unit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmUModalNUName, setAdmUModalNUActive, setAdmUModalNUDescription }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmUModalNUDetails))
