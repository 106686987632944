import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setNotifications,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_NOTIFICATION_NEW_DISPLAY,
	SET_NOTIFICATION_PATIENT,
	SET_NOTIFICATION_SELECTED_NOTIFICATION,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class NotificationTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "NotificationTable",
		}

		this.onSelect = this.onSelect.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.deleteNotification = this.deleteNotification.bind(this)
	}

	componentDidMount() {
		this.props.setNotifications(this.props.notification.patient.notifications)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.notification.patient, this.props.notification.patient)) {
			this.props.setNotifications(this.props.notification.patient ? this.props.notification.patient.notifications : [])
		}
	}

	componentWillUnmount() {
		this.props.setNotifications([])
		this.props.setSelected(SET_NOTIFICATION_SELECTED_NOTIFICATION, -1, null)
	}

	deleteNotification() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.notification.deleteNotifications,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.notification.patient.id,
				messages: [
					{
						id: this.props.notification.selectedNotification.notification.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				this.props.setValue(SET_NOTIFICATION_PATIENT, res.data.patients[0])
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_NOTIFICATION_SELECTED_NOTIFICATION,
			index,
			this.props.notification.notificationTable.filtered[index]
		)
	}

	onNew(e) {
		this.props.onModalDisplayAction(SET_NOTIFICATION_NEW_DISPLAY, true)
		this.props.setSelected(SET_NOTIFICATION_SELECTED_NOTIFICATION, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.setSelected(
			SET_NOTIFICATION_SELECTED_NOTIFICATION,
			rIdx,
			this.props.notification.notificationTable.filtered[rIdx]
		)
		this.props.onModalDisplayAction(SET_NOTIFICATION_NEW_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedNotification = this.props.notification.notificationTable.filtered[rIdx]
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.notification })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(
				selectedNotification.lastModifiedDateTime
			)}
		    `
		)
		window.warningContinueCallback = this.deleteNotification
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col style={{ minHeight: "500px" }}>
					<ScrollableTable
						enableHighlight
						enableOverlay
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						isClearHighlight={this.props.notification.selectedNotification.index === -1}
						higlightedRow={this.props.notification.selectedNotification.index}
						data={this.props.notification.notificationTable}
						onClick={this.onSelect}
						extra={
							<Button
								style={{ fontSize: "medium" }}
								variant={ResourceAssistance.Button.variant.link}
								size={ResourceAssistance.Button.size.sm}
								onClick={this.onNew}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setNotifications,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationTable))
