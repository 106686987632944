import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportTransferByLocationDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.transferByLocation,
				requests: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Filter */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Start Date/{this.state.data.startDateTimeIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.startDateTime}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>End Date/{this.state.data.endDateTimeIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.endDateTime}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Branch/{this.state.data.branchIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.branch}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Certified/{this.state.data.certifiedIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.certifiedIcon}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Approved/{this.state.data.approvedIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.approvedIcon}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{this.state.data.requests.map((request) => {
							return (
								<Fragment>
									{/* Request info */}
									<View wrap={false}>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Request ID/{this.state.data.requestIdIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{request.id}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Location/{this.state.data.locationIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{request.location}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Date/{this.state.data.dateIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{request.dateTime}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Issued By/{this.state.data.issuedByIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{request.issuedBy}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
									</View>

									{/* Transfer Info */}
									{request.transfers.map((transfer) => {
										return (
											<Fragment>
												<View wrap={false} style={{ fontSize: 16 }}>
													<View style={ResourceAssistance.Report.styles.row}>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
															<Text>Transfer ID/{this.state.data.transferIdIntl}:</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
															<Text>{transfer.id}</Text>
														</View>
													</View>
													<View style={ResourceAssistance.Report.styles.row}>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
															<Text>Date/{this.state.data.dateIntl}:</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
															<Text>{transfer.dateTime}</Text>
														</View>
													</View>
													<View style={ResourceAssistance.Report.styles.row}>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
															<Text>Certified By/{this.state.data.certifiedByIntl}:</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
															<Text>{transfer.certifiedBy}</Text>
														</View>
													</View>
													<View style={ResourceAssistance.Report.styles.row}>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
															<Text>Approved By/{this.state.data.approvedByIntl}:</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
															<Text>{transfer.approvedBy}</Text>
														</View>
													</View>
													<View style={ResourceAssistance.Report.styles.horizontalLine} />
												</View>

												{/* Item Header */}
												<View wrap={false}>
													<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
															<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.lotIntl}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
															<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>
																{this.state.data.descriptionIntl}
															</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.brandIntl}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitIntl}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.status]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>
																{this.state.data.statusIntl}
															</Text>
														</View>
													</View>
													<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
															<Text style={[ResourceAssistance.Report.styles.textBold]}>Lot</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
															<Text style={[ResourceAssistance.Report.styles.textBold]}>ID</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>Description</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>Brand</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>Unit</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.status]}>
															<Text style={ResourceAssistance.Report.styles.textBold}>Status</Text>
														</View>
													</View>
												</View>
												<View style={ResourceAssistance.Report.styles.horizontalLine} />

												{/* Item */}
												{transfer.items.map((item) => {
													return (
														<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.lot]}>
																<Text>{item.lot}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
																<Text>{item.id}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
																<Text style={ResourceAssistance.Report.styles.textItalic}>{item.description}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
																<Text>{item.brand}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
																<Text>{item.qty}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
																<Text>{item.unit}</Text>
															</View>
															<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.status]}>
																<Text style={ResourceAssistance.Report.styles.textIcon}>{item.status}</Text>
															</View>
														</View>
													)
												})}
												<View style={[ResourceAssistance.Report.styles.separatorFlexGrow, { borderBottomWidth: 1 }]} />
											</Fragment>
										)
									})}
									<View style={[ResourceAssistance.Report.styles.separator]} />
								</Fragment>
							)
						})}
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		justifyContent: "flex-start",
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	lot: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	id: {
		minWidth: "17%",
		maxWidth: "17%",
	},
	description: {
		minWidth: "34%",
		maxWidth: "34%",
	},
	brand: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	qty: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	unit: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	status: {
		minWidth: "6%",
		maxWidth: "6%",
	},
})

export default ReportTransferByLocationDocument
