import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import ConfigServiceModalNSRole from "./ConfigServiceModalNSRole"
import ConfigServiceModalNSService from "./ConfigServiceModalNSService"

class ConfigServiceModalNSDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<ConfigServiceModalNSService />
					</Col>
					<Col>
						<ConfigServiceModalNSRole />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNSDetails))
