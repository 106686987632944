import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setNotificationPatients, setValue } from "~/redux/action"
import { SET_NOTIFICATION_PATIENT, SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import NotificationSearchDetails from "./NotificationSearchDetails"

class NotificationSearch extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "NotificationSearch",
			pid: "",
			identificationNumber: "",
			firstName: "",
			lastName: "",
		}

		this.onClose = this.onClose.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
	}

	reset() {
		this.setState({
			pid: "",
			identificationNumber: "",
			firstName: "",
			lastName: "",
		})
		this.props.onModalDisplayAction(SET_NOTIFICATION_SEARCH_PATIENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return !this.props.notification.modalSearch.selectedPatient.patient
	}

	onSearchChange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}

	onClose() {
		this.reset()
	}

	onSearch(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.notification.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				pid: this.state.pid ? this.state.pid : 0,
				identificationNumber: this.state.identificationNumber,
				firstName: Utils.trim(this.state.firstName),
				lastName: Utils.trim(this.state.lastName),
			},
		}
		let callback = (res) => {
			this.props.setNotificationPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onDone(e) {
		e.preventDefault()
		e.stopPropagation()

		this.props.setValue(SET_NOTIFICATION_PATIENT, this.props.notification.modalSearch.selectedPatient.patient)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={_.isEmpty(this.props.notification.modalSearch.patientTable.original) ? this.onSearch : this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.search)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<NotificationSearchDetails {...this.state} onSearchChange={this.onSearchChange} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={
								_.isEmpty(this.props.notification.modalSearch.patientTable.original)
									? ResourceAssistance.Button.variant.primary
									: ResourceAssistance.Button.variant.green
							}
							type={ResourceAssistance.Button.type.submit}
							disabled={
								_.isEmpty(this.props.notification.modalSearch.patientTable.original) ? false : this.isDoneBtnDisabled()
							}
						>
							{_.isEmpty(this.props.notification.modalSearch.patientTable.original) ? (
								<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							) : (
								<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							)}
							{_.isEmpty(this.props.notification.modalSearch.patientTable.original)
								? translate(ResourceAssistance.Message.search)
								: translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isMenuNotificationSearchDisplay,
	login: state.login,
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setNotificationPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSearch)
