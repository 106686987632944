import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class NotificationPatientInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
							column={3}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.notification.patient.id}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={2}>
								{this.props.notification.patient.identificationNumber}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
								{this.props.notification.patient.title +
									" " +
									this.props.notification.patient.firstName +
									" " +
									this.props.notification.patient.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{Utils.formatDate(this.props.notification.patient.dobtime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)} span={1}>
								{`${Utils.calculateAge(this.props.notification.patient.dobtime)} ${this.props.intl.formatMessage({
									id: ResourceAssistance.Hospitel.year,
								})} ${Utils.calculateAgeMonth(this.props.notification.patient.dobtime)} ${this.props.intl.formatMessage(
									{ id: ResourceAssistance.Hospitel.month }
								)}`}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationPatientInfo))
