import { Descriptions } from "antd"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class ContextMenuPatientHealthCarePlan extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							labelStyle={{ fontWeight: "bold" }}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
						>
							{this.props.patient.clinicalRecord.healthcareCode ? (
								<Fragment>
									<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
										{this.props.patient.clinicalRecord.healthcareCode}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
										{this.props.patient.clinicalRecord.healthcareName}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={2}>
										{this.props.patient.clinicalRecord.healthcareDescription}
									</Descriptions.Item>
								</Fragment>
							) : (
								<Descriptions.Item label={translate(ResourceAssistance.Message.other)} span={2}>
									{this.props.patient.clinicalRecord.healthcareOther}
								</Descriptions.Item>
							)}
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

export default ContextMenuPatientHealthCarePlan
