import React from "react"
import { Button, Col, FormLabel, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setDateAction, setLoadingAction, setTfRequests } from "~/redux/action"
import { SET_TF_REQUEST_LOADING, SET_TF_SEARCH_END_DATE, SET_TF_SEARCH_START_DATE } from "~/redux/type/type/TF"
import DatePicker from "~/view/component/date_picker/DatePicker"

class TFLeftFirstRow extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDateChange = this.onStartDateChange.bind(this)
		this.onEndDateChange = this.onEndDateChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	loadTransferRequests() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.transfer.getRequests,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: this.props.transfer.searchDate,
		}
		let callback = (res) => {
			this.props.setTfRequests(res.data.requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	componentDidMount() {
		this.loadTransferRequests()
	}

	onStartDateChange(date) {
		this.props.setDateAction(SET_TF_SEARCH_START_DATE, date)
	}

	onEndDateChange(date) {
		this.props.setDateAction(SET_TF_SEARCH_END_DATE, date)
	}

	onSearch(event) {
		this.loadTransferRequests()
	}

	render() {
		return (
			<Row noGutters className={ResourceAssistance.CSS.labelInfoRow}>
				<Col md="auto">
					<DatePicker startDate={this.props.transfer.searchDate.startDate} onChange={this.onStartDateChange} />
				</Col>
				<Col>
					<FormLabel stype={{ alignSelf: ResourceAssistance.CSS.center }}>
						{translate(ResourceAssistance.Message.to)}
					</FormLabel>
				</Col>
				<Col md="auto">
					<DatePicker startDate={this.props.transfer.searchDate.endDate} onChange={this.onEndDateChange} />
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						type={ResourceAssistance.Button.type.submit}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearch}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setDateAction, setLoadingAction, setTfRequests }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFLeftFirstRow)
