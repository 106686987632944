// Message:{
//  key : the translate id used by {<FormattedMessage id={}/>} from react-intl.
//  key : {
//      text : the text that will not be translated, so it will display the same for different language.
//      translateId: the translate id used for translation.
//  }
// }
//
const Message = {
	Message: {
		"": "",
		stockManagement: {
			text: "Stock Manager",
			translateId: "stockManagement",
		},
		stockManager: "stockManager",
		active: "active",
		add: "add",
		address: "address",
		admin: "admin",
		amount: "amount",
		approve: "approve",
		approver: "approver",
		approverCheck: "approverCheck",
		bonus: "bonus",
		branch: "branch",
		brandName: "brandName",
		brandNameAndCode: "brandNameAndCode",
		cancel: "cancel",
		checkbox: "checkbox",
		chinese: "中文",
		city: "city",
		cityEng: "cityEng",
		close: "close",
		code: "code",
		continue: "continue",
		createNewPR: "createNewPR",
		current: "current",
		currentLevel: "currentLevel",
		date: "date",
		dateOfPo: "dateOfPo",
		delete: "delete",
		description: "description",
		discount: "discount",
		supplierDiscount: "supplierDiscount",
		displayAll: "displayAll",
		displayExpire: "displayExpire",
		displayMinStock: "displayMinStock",
		displayInactive: "displayInactive",
		done: "done",
		dose: "dose",
		doubleClickToDelete: "doubleClickToDelete",
		dueDate: "dueDate",
		edit: "edit",
		empty: "empty",
		emptyDisapproveReasonWarning: "emptyDisapproveReasonWarning",
		emptyNoteWarning: "emptyNoteWarning",
		english: "EN",
		expire: "expire",
		expired: "expired",
		form: "form",
		general: "general",
		generic: "generic",
		hello: "hello",
		home: "home",
		id: "id",
		inspector: "inspector",
		inspectorCheck: "inspectorCheck",
		inventory: "inventory",
		label: "label",
		lastPurchasedFrom: "lastPurchasedFrom",
		approvedInventories: "approvedInventories",
		requestedInventories: "requestedInventories",
		loading: "loading",
		location: "location",
		login: "login",
		loginError: "loginError",
		logout: "logout",
		management: "management",
		max: "max",
		maxLevel: "maxLevel",
		min: "min",
		minLevel: "minLevel",
		minStock: "minStock",
		name: "name",
		new: "new",
		newItemWarning: "newItemWarning",
		note: "note",
		number: "number",
		org: "org",
		parentLoc: "parentLoc",
		password: "password",
		phone: "phone",
		poNumber: "poNumber",
		postcode: "postcode",
		prNumber: "prNumber",
		pricePerUnit: "pricePerUnit",
		discountPricePerUnit: "discountPricePerUnit",
		discountPricePerOrder: "discountPricePerOrder",
		print: "print",
		province: "province",
		provinceEng: "provinceEng",
		purchaseOrder: "purchaseOrder",
		purchaseRequest: "purchaseRequest",
		rc: "rc",
		receive: "receive",
		refresh: "refresh",
		reject: "reject",
		report: "report",
		requester: "requester",
		requesterCheck: "requesterCheck",
		save: "save",
		search: "search",
		setAmount: "setAmount",
		shipAddress: "shipAddress",
		stock: "stock",
		supplier: "supplier",
		supplierName: "supplierName",
		taxRate: "taxRate",
		thai: "thai",
		time: "time",
		to: "to",
		totalPrice: "totalPrice",
		type: "type",
		uid: "uid",
		unapproveReason: "unapproveReason",
		unapproved: "unapproved",
		unit: "unit",
		user: "user",
		username: "username",
		warning: "warning",
		reason: "reason",
		transfer: "transfer",
		invoiceNum: "invoiceNum",
		lotNum: "lotNum",
		lot: "lot",
		newReceive: "newReceive",
		cancelNotReceived: "cancelNotReceived",
		receiveItemDetails: "receiveItemDetails",
		confirm: "confirm",
		itemType: "itemType",
		payment: "payment",
		complete: "complete",
		hashSymbol: "hashSymbol",
		lastReceivedDate: "lastReceivedDate",
		wrongAmount: "wrongAmount",
		totalPriceDiscounted: "totalPriceDiscounted",
		totalPriceTaxed: "totalPriceTaxed",
		totalPriceDiscountedTaxed: "totalPriceDiscountedTaxed",
		by: "by",
		emptyCancelReason: "emptyCancelReason",
		wrongTotalPrice: "wrongTotalPrice",
		taxedTotalPriceDoesNotMatch: "taxedTotalPriceDoesNotMatch",
		discountTaxPricePerUnit: "discountTaxPricePerUnit",
		package: "package",
		received: "received",
		pendingOrder: "pendingOrder",
		approvedByAnotherOrderWarning: "approvedByAnotherOrderWarning",
		from: "from",
		transferingItems: "transferingItems",
		transferred: "transferred",
		pendingItems: "pendingItems",
		pendingTransfers: "pendingTransfers",
		newRequest: "newRequest",
		newTransfer: "newTransfer",
		request: "request",
		cancelled: "cancelled",
		wrongTransferAmount: "wrongTransferAmount",
		wrongTransferAmountGreaterThanRequested: "wrongTransferAmountGreaterThanRequested",
		status: "status",
		pending: "pending",
		inProgress: "inProgress",
		item: "item",
		itemSupplier: "itemSupplier",
		newSupplier: "newSupplier",
		dupplicateWarning: "dupplicateWarning",
		dupplicateCodeWarning: "dupplicateCodeWarning",
		dupplicateNameWarning: "dupplicateNameWarning",
		emptyWarning: "emptyWarning",
		newUnit: "newUnit",
		newForm: "newForm",
		newTax: "newTax",
		taxRateWithSymbol: "taxRateWithSymbol",
		minQtyPerOrder: "minQtyPerOrder",
		newItem: "newItem",
		discountWithSymbol: "discountWithSymbol",
		newItemSupplier: "newItemSupplier",
		role: "role",
		permission: "permission",
		firstName: "firstName",
		lastName: "lastName",
		firstNameEng: "firstNameEng",
		lastNameEng: "lastNameEng",
		email: "email",
		nickName: "nickName",
		loginName: "loginName",
		newUser: "newUser",
		confirmPassword: "confirmPassword",
		passwordDoesNotMatch: "passwordDoesNotMatch",
		changePassword: "changePassword",
		newRole: "newRole",
		tel: "tel",
		fax: "fax",
		taxId: "taxId",
		telNo: "telNo",
		faxNo: "faxNo",
		purchaseFrom: "purchaseFrom",
		memoId: "memoId",
		supplierCode: "supplierCode",
		poId: "poId",
		poDate: "poDate",
		paymentType: "paymentType",
		paymentTerms: "paymentTerms",
		terms: "terms",
		requiredByDate: "requiredByDate",
		expected: "expected",
		qty: "qty",
		unitPrice: "unitPrice",
		uom: "uom",
		remark: "remark",
		grandTotal: "grandTotal",
		issuedBy: "issuedBy",
		certifiedBy: "certifiedBy",
		approvedBy: "approvedBy",
		receivedBy: "receivedBy",
		issued: "issued",
		certified: "certified",
		approved: "approved",
		vat: "vat",
		requestFrom: "requestFrom",
		transferFrom: "transferFrom",
		requestId: "requestId",
		transferId: "transferId",
		expiredDate: "expiredDate",
		reportByLocation: "reportByLocation",
		reportById: "reportById",
		run: "run",
		filter: "filter",
		startDateTime: "startDateTime",
		startDate: "startDate",
		endDate: "endDate",
		endDateTime: "endDateTime",
		purchaseOrderByLocation: "purchaseOrderByLocation",
		purchaseOrderById: "purchaseOrderById",
		transferByLocation: "transferByLocation",
		inventoryByLocation: "inventoryByLocation",
		department: "department",
		billingAddress: "billingAddress",
		contact: "contact",
		shippingContact: "shippingContact",
		billingContact: "billingContact",
		contactInfo: "contactInfo",
		billTo: "billTo",
		view: "view",
		value: "value",
		pricePerUnitBeforeTax: "pricePerUnitBeforeTax",
		pricePerOrderBeforeTax: "pricePerOrderBeforeTax",
		uploaded: "uploaded",
		seq: "seq",
		receiptId: "receiptId",
		invoiceId: "invoiceId",
		or: "or",
		order: "order",
		stop: "stop",
		adHocOrder: "adHocOrder",
		focus: "focus",
		temperature: "temperature",
		prPerMin: "prPerMin",
		rrPerMin: "rrPerMin",
		bpmmHg: "bpmmHg",
		o2Sat: "o2Sat",
		stools: "stools",
		urine: "urine",
		changeEndDate: "changeEndDate",
		pricePerOrder: "pricePerOrder",
		medicationUsage: "medicationUsage",
		references: "references",
		room: "room",
		upload: "upload",
		satCode: "satCode",
		novelDiseaseInvestigationSheet: "novelDiseaseInvestigationSheet",
		examinationDate: "examinationDate",
		investigation: "investigation",
		treatment: "treatment",
		bloodTest: "bloodTest",
		radiology: "radiology",
		idCard: "idCard",
		category: "category",
		fsCode: "fsCode",
		medicalSupply: "medicalSupply",
		qtyPerDay: "qtyPerDay",
		day: "day",
		purchase: "purchase",
		sell: "sell",
		saveAll: "saveAll",
		operationalService: "operationalService",
		instruction: "instruction",
		bill: "bill",
		billByCategory: "billByCategory",
		billByHealthCarePlan: "billByHealthCarePlan",
		billSummary: "billSummary",
		billDetails: "billDetails",
		admissionDate: "admissionDate",
		dischargeDate: "dischargeDate",
		byAdmission: "byAdmission",
		byUser: "byUser",
		categoryNum: "categoryNum",
		keyword: "keyword",
		doctorOptionAfter: "doctorOptionAfter",
		today: "today",
		table: "table",
		graph: "graph",
		amountPerOrder: "amountPerOrder",
		attachment: "attachment",
		tradeName: "tradeName",
		payee: "payee",
		licenseNum: "licenseNum",
		professionalServiceExpenseByUser: "professionalServiceExpenseByUser",
		discharged: "discharged",
		inPatient: "inPatient",
		tradeNameOrBrand: "tradeNameOrBrand",
		subunit: "subunit",
		genericName: "genericName",
		generalInfo: "generalInfo",
		stockAndPurchasing: "stockAndPurchasing",
		sellingPricePerUnit: "sellingPricePerUnit",
		costPerOrder: "costPerOrder",
		amountOfPackage: "amountOfPackage",
		documentNum: "documentNum",
		clear: "clear",
		billingToTodayByAdmission: "billingToTodayByAdmission",
		duration: "duration",
		every: "every",
		scheduled: "scheduled",
		genericNameOrTradeName: "genericNameOrTradeName",
		default: "default",
		prnRecord: "prnRecord",
		newRecord: "newRecord",
		dispensingTime: "dispensingTime",
		financialPlan: "financialPlan",
		paid: "paid",
		unpaid: "unpaid",
		pay: "pay",
		change: "change",
		totalPaid: "totalPaid",
		totalUnpaid: "totalUnpaid",
		skipPayment: "skipPayment",
		thisMonth: "thisMonth",
		receipt: "receipt",
		debt: "debt",
		selectAll: "selectAll",
		printDate: "printDate",
		patientName: "patientName",
		currencySign: "currencySign",
		days: "days",
		hours: "hours",
		minutes: "minutes",
		receiptCategory: "receiptCategory",
		deselectAll: "deselectAll",
		deposit: "deposit",
		withdraw: "withdraw",
		transaction: "transaction",
		currentBalance: "currentBalance",
		totalDeposit: "totalDeposit",
		totalWithdraw: "totalWithdraw",
		patientSaving: "patientSaving",
		hospitalBilling: "hospitalBilling",
		opd: "opd",
		registrationDate: "registrationDate",
		registerOPD: "registerOPD",
		other: "other",
		pastSurgicalHistory: "pastSurgicalHistory",
		familyHealthHistory: "familyHealthHistory",
		appointmentDate: "appointmentDate",
		appointmentTime: "appointmentTime",
		appointment: "appointment",
		checkInDate: "checkInDate",
		checkInTime: "checkInTime",
		checkOutDate: "checkOutDate",
		checkOutTime: "checkOutTime",
		checkIn: "checkIn",
		checkOut: "checkOut",
		claimFile: "claimFile",
		expenseEstimation: "expenseEstimation",
		medicalRecord: "medicalRecord",
		admissionPlan: "admissionPlan",
		chiefComplaint: "chiefComplaint",
		presentIllness: "presentIllness",
		doctorConsultation: "doctorConsultation",
		questionnaire: "questionnaire",
		drinking: "drinking",
		smoking: "smoking",
		pregnant: "pregnant",
		vaccine: "vaccine",
		modifyDateRange: "modifyDateRange",
		tomorrow: "tomorrow",
		oneWeek: "oneWeek",
		twoWeeks: "twoWeeks",
		oneMonth: "oneMonth",
		loadPreviousMedicalRecords: "loadPreviousMedicalRecords",
		nurseOrder: "nurseOrder",
		medicalCertificateOPD: "medicalCertificateOPD",
		medicalCertificateIPD: "medicalCertificateIPD",
		ICD: "ICD",
		internationalClassificationDiseases: "internationalClassificationDiseases",
		level: "level",
		medicine: "medicine",
		coordinator: "coordinator",
		typesOfDiagnosis: "typesOfDiagnosis",
		budget: "budget",
		companyOrResponsiblePersonContact: "companyOrResponsiblePersonContact",
		carAccident: "carAccident",
		typeOfIncident: "typeOfIncident",
		timeOfIncident: "timeOfIncident",
		placeOfIncident: "placeOfIncident",
		policeStation: "policeStation",
		policeName: "policeName",
		victim: "victim",
		driver: "driver",
		passenger: "passenger",
		pedestrian: "pedestrian",
		generalAppearance: "generalAppearance",
		inpatientPlan: "inpatientPlan",
		arrival: "arrival",
		walk: "walk",
		wheelchair: "wheelchair",
		stretcher: "stretcher",
		totalEstimationCost: "totalEstimationCost",
		totalBudget: "totalBudget",
		triage: "triage",
		isolationPrecaution: "isolationPrecaution",
		fallRisk: "fallRisk",
		yes: "yes",
		no: "no",
		language: "language",
		translator: "translator",
		accidentHistory: "accidentHistory",
		medicineHistory: "medicineHistory",
		supplementOrHerbsHistory: "supplementOrHerbsHistory",
		alcohol: "alcohol",
		tobacco: "tobacco",
		passiveSmokeExposure: "passiveSmokeExposure",
		riskOfAbuse: "riskOfAbuse",
		menstruation: "menstruation",
		womenOnly: "womenOnly",
		lastMenstrualPeriod: "lastMenstrualPeriod",
		estimatedDateOfConfinement: "estimatedDateOfConfinement",
		usingContraceptive: "usingContraceptive",
		menopause: "menopause",
		doctorNote: "doctorNote",
		medicalCertificateEng: "medicalCertificateEng",
		nameEng: "nameEng",
		addressEng: "addressEng",
		intakeOutput: "intakeOutput",
		intake: "intake",
		output: "output",
		method: "method",
		volume: "volume",
		amountML: "amountML",
		totalIntake: "totalIntake",
		totalOutput: "totalOutput",
		ioBalance: "ioBalance",
		pulse: "pulse",
		glasgowComaScale: "glasgowComaScale",
		eyeOpeningResponse: "eyeOpeningResponse",
		verbalResponse: "verbalResponse",
		motorResponse: "motorResponse",
		noEyeOpening: "noEyeOpening",
		toPressure: "toPressure",
		toSound: "toSound",
		spontaneous: "spontaneous",
		noVerbal: "noVerbal",
		sounds: "sounds",
		words: "words",
		confused: "confused",
		orientated: "orientated",
		noMotor: "noMotor",
		extension: "extension",
		abnormalFlexion: "abnormalFlexion",
		normalFlexion: "normalFlexion",
		localising: "localising",
		obeysCommands: "obeysCommands",
		score: "score",
		painScale: "painScale",
		left: "left",
		leftEye: "leftEye",
		message: "message",
		notification: "notification",
		pupilSize: "pupilSize",
		right: "right",
		rightEye: "rightEye",
		size: "size",
		leftEyePupilSizeMM: "leftEyePupilSizeMM",
		rightEyePupilSizeMM: "rightEyePupilSizeMM",
		reactionToLight: "reactionToLight",
		leftEyeReactionToLight: "leftEyeReactionToLight",
		rightEyeReactionToLight: "rightEyeReactionToLight",
		timeline: "timeline",
		pharmacy: "pharmacy",
		lastLocation: "lastLocation",
		heent: "heent",
		heart: "heart",
		lung: "lung",
		abdomen: "abdomen",
		back: "back",
		extremities: "extremities",
		neuro: "neuro",
		pharmacist: "pharmacist",
		pharmacistVerified: "pharmacistVerified",
		verified: "verified",
		modified: "modified",
		dispensed: "dispensed",
		return: "return",
		pharmacyInventory: "pharmacyInventory",
		male: "male",
		female: "female",
		pcrComment: "pcrComment",
		sarsCov2RnaPcr: "sarsCov2RnaPcr",
		xpertXpressSarsCov2: "xpertXpressSarsCov2",
		n2geneCT: "n2geneCT",
		antigenComment: "antigenComment",
		dispense: "dispense",
		verify: "verify",
		pharmacyDispensed: "pharmacyDispensed",
		dispensedBy: "dispensedBy",
		returned: "returned",
		returning: "returning",
		doctorPrescriptionOnly: "doctorPrescriptionOnly",
		cancelDoctorPrescriptionOnly: "cancelDoctorPrescriptionOnly",
		primaryPlan: "primaryPlan",
		refund: "refund",
		doctorPrefix: "doctorPrefix",
		doctorPrescription: "doctorPrescription",
		relativeInfo: "relativeInfo",
		relationship: "relationship",
		beforeMeal: "beforeMeal",
		beforeBed: "beforeBed",
		afterMeal: "afterMeal",
		nurseName: "nurseName",
	},

	Hospitel: {
		age: "age",
		analyte: "analyte",
		antigen: "antigen",
		antigenCOI: "antigenCOI",
		chromatography: "chromatography",
		covid19Lab: "covid19Lab",
		dateOfBirth: "dateOfBirth",
		fluorescence: "fluorescence",
		gender: "gender",
		hospital: "hospital",
		hospitel: "hospitel",
		ipd: "ipd",
		labNumber: "labNumber",
		nGene: "nGene",
		nameTitle: "nameTitle",
		negative: "negative",
		newLab: "newLab",
		oGene: "oGene",
		patientId: "patientId",
		patientInfo: "patientInfo",
		pcr: "pcr",
		positive: "positive",
		printLabel: "printLabel",
		registerPatient: "registerPatient",
		result: "result",
		sGene: "sGene",
		searchPatient: "searchPatient",
		smartCardReaderIsNotWorking: "smartCardReaderIsNotWorking",
		cannotReadSMC: "cannotReadSMC",
		title: "title",
		lab: "lab",
		download: "download",
		sequence: "sequence",
		hn: "hn",
		nameSurname: "nameSurname",
		healthCare: "healthCare",
		healthCarePlan: "healthCarePlan",
		// uploadGDrive: "uploadGDrive",
		googleDrive: "googleDrive",
		health: "health",
		height: "height",
		weight: "weight",
		underlyingDisease: "underlyingDisease",
		identification: "identification",
		year: "year",
		month: "month",
		eGene: "eGene",
		medicalCertificate: "medicalCertificate",
		doctor: "doctor",
		licenseNo: "licenseNo",
		doctorName: "doctorName",
		certifiesThat: "certifiesThat",
		hasExaminedOn: "hasExaminedOn",
		symptoms: "symptoms",
		theAboveNamedPersonRequires: "theAboveNamedPersonRequires",
		daysOfRest: "daysOfRest",
		signature: "signature",
		md: "md",
		diagnosis: "diagnosis",
		doctorOpinion: "doctorOpinion",
		days: "days",
		cashier: "cashier",
		service: "service",
		covid19Positive: "covid19Positive",
		covid19Negative: "covid19Negative",
		requestCovid19MedicalCertificate: "requestCovid19MedicalCertificate",
		vitalSign: "vitalSign",
		clinicalRecord: "clinicalRecord",
		physicalExamination: "physicalExamination",
		allergies: "allergies",
		pastIllnesses: "pastIllnesses",
		adjustments: "adjustments",
		balance: "balance",
		nurse: "nurse",
		aie: "aie",
		assessment: "assessment",
		intervention: "intervention",
		evaluation: "evaluation",
		subjective: "subjective",
		objective: "objective",
		plan: "plan",
		registration: "registration",
		healthHistory: "healthHistory",
		doctorStandingOrder: "doctorStandingOrder",
		nurseProgressNote: "nurseProgressNote",
		soap: "soap",
		opdClinicalRecord: "opdClinicalRecord",
		admission: "admission",
		medicationSheet: "medicationSheet",
		checkout: "checkout",
		discharge: "discharge",
		firstAssessment: "firstAssessment",
		patient: "patient",
		letterOfConsent: "letterOfConsent",
		depressionAssessment: "depressionAssessment",
	},
}

export default Message
