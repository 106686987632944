import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue, setLoadingAction, setObjArray, setSelected } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REPORT_CASHIER_USER_ROLES,
	SET_REPORT_CASHIER_USER_SELECTED_ROLE,
	SET_REPORT_CASHIER_USER_SELECTED_USER,
	SET_REPORT_CASHIER_USER_USERS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class ReportCashierUserFilterRole extends React.Component {
	constructor(props) {
		super(props)

		this.onRole = this.onRole.bind(this)
		this.onUser = this.onUser.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.filter.selectedRole, this.props.filter.selectedRole)) {
			this.props.setSelected(SET_REPORT_CASHIER_USER_SELECTED_USER, -1, null)
			if (!this.props.filter.selectedRole.role) {
				this.props.setObjArray(SET_REPORT_CASHIER_USER_USERS, [])
			} else {
				this.props.setObjArray(SET_REPORT_CASHIER_USER_USERS, this.props.filter.selectedRole.role.users)
			}
		}
	}

	componentDidMount() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.cashier.getRoles,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: true },
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REPORT_CASHIER_USER_ROLES, res.data.userTypes)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_REPORT_CASHIER_USER_ROLES, [])
		this.props.setSelected(SET_REPORT_CASHIER_USER_SELECTED_ROLE, -1, null)
		this.props.setSelected(SET_REPORT_CASHIER_USER_SELECTED_USER, -1, null)
	}

	onRole(event) {
		this.props.setSelected(
			SET_REPORT_CASHIER_USER_SELECTED_ROLE,
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.filter.roles[event.target.value] : null
		)
	}

	onUser(event) {
		this.props.setSelected(
			SET_REPORT_CASHIER_USER_SELECTED_USER,
			event.target.value ? Number(event.target.value) : -1,
			event.target.value ? this.props.filter.users[event.target.value] : null
		)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={2} />
					<Col md="auto">
						<FormLabel>{translate(ResourceAssistance.Message.role)}</FormLabel>
					</Col>
					<Col>
						<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onRole}>
							{Utils.renderOptions(this.props.filter.roles)}
						</FormControl>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col md={2} />
					<Col md="auto">
						<FormLabel>{translate(ResourceAssistance.Message.name)}</FormLabel>
					</Col>
					<Col>
						<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onUser}>
							{Utils.renderOptions(this.props.filter.users)}
						</FormControl>
					</Col>
					<Col md={2} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.cashier.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
			setObjArray,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierUserFilterRole))
