import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, SET_IPD_PUPIL_SIZE_DISPLAY } from "~/redux/type"
import { SET_PAGE_LOADING } from "~/redux/type/"
import { Utils } from "~/utils/Utils"
import PupilSize from "~/view/component/pupil-size/PupilSize"

class IPDModalPupilSize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDModalPupilSize",
		}
		this.pupilSizeRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	isDoneBtnDisabled() {
		if (!this.pupilSizeRef.current || !this.pupilSizeRef.current.state.datetime) {
			return true
		}
		let target = Object.assign({}, this.props.ipd.selectedPupilSize.pupilSize, {
			dateTime: this.pupilSizeRef.current.state.datetime,
			left: this.pupilSizeRef.current.state.left,
			leftReactionToLight: this.pupilSizeRef.current.state.leftReactionToLight,
			right: this.pupilSizeRef.current.state.right,
			rightReactionToLight: this.pupilSizeRef.current.state.rightReactionToLight,
		})
		return _.isEqual(target, this.props.ipd.selectedPupilSize.pupilSize)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.props.onModalDisplayAction(SET_IPD_PUPIL_SIZE_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.savePupilSizes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				admissionId: this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.id,
				userId: this.props.login.user.id,
				pupilSizes: [
					{
						id: this.props.ipd.selectedPupilSize.pupilSize ? this.props.ipd.selectedPupilSize.pupilSize.id : 0,
						dateTime: this.pupilSizeRef.current.state.datetime,
						left: this.pupilSizeRef.current.state.left,
						leftReactionToLight: Utils.trim(this.pupilSizeRef.current.state.leftReactionToLight),
						right: this.pupilSizeRef.current.state.right,
						rightReactionToLight: Utils.trim(this.pupilSizeRef.current.state.rightReactionToLight),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setHospitelPatients(patients)
			}
			this.props.onModalDisplayAction(SET_IPD_PUPIL_SIZE_DISPLAY, false)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.pupilSize)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PupilSize
							ref={this.pupilSizeRef}
							onRenderParentCallback={this.onRenderParentCallback}
							pupilSize={
								this.props.ipd.selectedPupilSize.pupilSize
									? {
											datetime: this.props.ipd.selectedPupilSize.pupilSize.dateTime,
											left: this.props.ipd.selectedPupilSize.pupilSize.left,
											leftReactionToLight: this.props.ipd.selectedPupilSize.pupilSize.leftReactionToLight,
											right: this.props.ipd.selectedPupilSize.pupilSize.right,
											rightReactionToLight: this.props.ipd.selectedPupilSize.pupilSize.rightReactionToLight,
									  }
									: null
							}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isInpatientPupilSizeDisplay,
	login: state.login,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelPatients,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalPupilSize))
