import React from "react"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import ContextMenuPatient from "./menu/patient/ContextMenuPatient"

class ContextMenu extends React.Component {
	render() {
		return (
			<Route>
				<Route component={ContextMenuPatient} />
			</Route>
		)
	}
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(ContextMenu)
