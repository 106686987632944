import propTypes from "prop-types"
import React from "react"
import { Badge, Button, FormGroup, FormLabel, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiFillWarning } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setValue,
	setWarningClose,
	setWarningContinue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_WARNING_DISPLAY } from "~/redux/type"

class Warning extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Warning",
		}

		this.onClose = this.onClose.bind(this)
		this.onContinue = this.onContinue.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (!this.props.isDisplay) {
				this.props.setWarningId("")
				this.props.setWarningClose(false)
				this.props.setWarningContinue(false)
				this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.sm)
				this.props.setWarningMsgAction("")
				window.warningCloseCallback = null
				window.warningContinueCallback = null
			}
		}
	}

	onClose(event) {
		this.props.setWarningClose(true)
		if (window.warningCloseCallback) {
			window.warningCloseCallback()
		}
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, false)
	}

	onContinue(event) {
		this.props.setWarningContinue(true)
		if (window.warningContinueCallback) {
			window.warningContinueCallback()
		}
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, false)
	}

	render() {
		return (
			<div id={this.state.componentName}>
				<Modal
					centered
					animation={false}
					show={this.props.isShow}
					size={this.props.modal.warning.size}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					onHide={this.onCloseBtn}
				>
					<ModalHeader className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.warning)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FormGroup>
							<FormLabel style={{ textAlign: "center" }}>{this.props.msg}</FormLabel>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onContinue}>
							<AiFillWarning size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.continue)}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

Warning.propTypes = {
	isShow: propTypes.bool,
	msg: propTypes.any,
}

Warning.defaultProps = {
	isShow: false,
	msg: "",
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isWarningDisplay,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setWarningClose,
			setWarningContinue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Warning)
