import _ from "lodash"
import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ErrorCode } from "~/axios/ErrorCode"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue, setWarningMsgAction } from "~/redux/action"
import {
	SET_ADM_NEW_TYPE_DISPLAY_CODE,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN,
	SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import TypeModalNewDetails from "./TypeModalNewDetails"

export const Property = {
	CODE: "TYPE_CODE",
	NAME: "TYPE_NAME",
	DESCRIPTION: "TYPE_DESCRIPTION",
	ACTIVE: "TYPE_ACTIVE",
	SAVING_ACCOUNT: "TYPE_PAYMENT_PATIENT_SAVING",
	PRIMARY_PLAN: "TYPE_HEALTH_CARE_PLAN_PRIMARY_PLAN",
}

class TypeModalNew extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(Property)

		this.state = this.initState()
		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
		this.onPropertyChange = this.onPropertyChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.type.selectedType.index !== this.props.type.selectedType.index ||
			(prevProps.type.selectedType.index === this.props.type.selectedType.index && prevState === this.state)
		) {
			if (this.props.type.selectedType.type) {
				this.setState({
					code: this.props.type.selectedType.type.code,
					name: this.props.type.selectedType.type.displayName,
					description: this.props.type.selectedType.type.description,
					active: this.props.type.selectedType.type.active,
					isSavingAccount: this.props.type.selectedType.type.savingAccount,
					isPrimaryHealthCarePlan: this.props.type.selectedType.type.primaryHealthCarePlan,
				})
			} else {
				this.setState(this.initState())
			}
		}
	}

	initState() {
		return {
			componentName: "NewType",
			code: "",
			name: "",
			description: "",
			active: true,
			isSavingAccount: false,
			isPrimaryHealthCarePlan: false,
		}
	}

	reset() {
		this.setState(this.initState())
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN, false)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, false)
	}

	onCloseBtn(event) {
		this.reset()
	}

	saveType() {
		let raw = JSON.stringify({
			id: this.props.type.selectedType.type ? this.props.type.selectedType.type.id : 0,
			userId: this.props.login.user.id,
			code: Utils.trim(this.state.code),
			name: Utils.trim(this.state.name),
			description: Utils.trim(this.state.description),
			active: this.state.active,
			savingAccount: this.state.isSavingAccount,
			primaryHealthCarePlan: this.state.isPrimaryHealthCarePlan,
		})

		let errorHandler = (error) => {
			this.setState(this.initState())
			if (_.isEqual(error.response.status, ErrorCode.CONFLICT)) {
				this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
				this.props.setWarningMsgAction(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })
				)
			}
		}
		axios(this.props.setParam(raw, this.props.type.url), this.props.callback, errorHandler)
	}

	onDoneBtn(event) {
		this.saveType()
		this.reset()
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.CODE:
				this.setState({ code: event.target.value.toUpperCase() })
				break

			case Property.NAME:
				this.setState({ name: event.target.value })
				break

			case Property.DESCRIPTION:
				this.setState({ description: event.target.value })
				break

			case Property.ACTIVE:
				this.setState({ active: event.target.checked })
				break

			case Property.SAVING_ACCOUNT:
				this.setState({ isSavingAccount: event.target.checked })
				break

			case Property.PRIMARY_PLAN:
				this.setState({ isPrimaryHealthCarePlan: event.target.checked })
				break

			default:
				break
		}
	}

	isDisabled() {
		let disabled = false
		disabled = disabled || _.isEmpty(Utils.trim(this.state.name)) || _.isEmpty(Utils.trim(this.state.description))
		if (this.props.type.modal.displayCode) {
			disabled = disabled || _.isEmpty(Utils.trim(this.state.code))
		}
		return disabled
	}

	render() {
		return (
			<div>
				<Modal
					id={this.state.componentName}
					centered
					animation={false}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					show={this.props.isDisplay}
					onHide={this.onCloseBtn}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(this.props.type.modal.title)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<TypeModalNewDetails {...this.props} {...this.state} onPropertyChange={this.onPropertyChange} />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDisabled()}
							onClick={this.onDoneBtn}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isNewTypeDisplay,
	modal: state.modal.newType,
	login: state.login,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setValue, setWarningMsgAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TypeModalNew))
