import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import OPDModalNurseAIE from "./OPDModalNurseAIE"
import OPDModalNurseFocus from "./OPDModalNurseFocus"

class OPDModalNurseNote extends React.Component {
	render() {
		return (
			<Row>
				<Col md={4}>
					<OPDModalNurseFocus />
				</Col>
				<Col>
					<OPDModalNurseAIE />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseNote)
