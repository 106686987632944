import _ from "lodash"
import { Utils } from "./Utils"

/**
 * Helper methods apply to UTS objects from Server directly.
 */

class ServerUtils {
	static findPharmacyInventory = (code, pharmacyInventories) => {
		return pharmacyInventories.find((each) => each.code === code)
	}

	static getLastVisitedLocationFrom = (admission) => {
		return _.isEmpty(admission.ipds)
			? admission.clinicalRecord.medicalRecords
					.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
					.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
					.find((medicalRecord) => true)
			: admission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
	}

	static getTreeNodesFrom = (orgs, displayEmptyChildren = true) => {
		return orgs
			.filter((org) => displayEmptyChildren || !_.isEmpty(org.locations))
			.map((org, orgIdx) => {
				return {
					title: org.displayName,
					// value: org.displayName,
					value: org.id,
					selectable: false,
					children: org.locations
						.filter((branch) => displayEmptyChildren || !_.isEmpty(branch.locations))
						.map((branch, branchIdx) => {
							return {
								title: branch.displayName,
								// value: org.displayName + "-" + branch.displayName,
								value: branch.id,
								selectable: false,
								children: branch.locations.map((department, departmentIdx) => {
									return {
										title: department.displayName,
										// value: org.displayName + "-" + branch.displayName + "-" + department.displayName,
										value: department.id,
										extra: {
											org: {
												index: orgIdx,
												org: org,
											},
											branch: {
												index: branchIdx,
												branch: branch,
											},
											department: {
												index: departmentIdx,
												department: department,
											},
										},
									}
								}),
							}
						}),
				}
			})
			.filter((org) => displayEmptyChildren || org.children.some((branch) => !_.isEmpty(branch.children)))
	}

	static getBase64Img(base64Str) {
		return "data:image/png;base64," + base64Str
	}
}

export default ServerUtils
