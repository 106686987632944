import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setSearchText, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import CLModalNewLabType from "./CLModalNewLabType"

class CLModalNewLabDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onNotes = this.onNotes.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lab.modalLab.selectedOrg.index !== this.props.lab.modalLab.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalLab.branch)
			branchHtml.selectedIndex = 0
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH, -1, null)
			if (this.props.lab.modalLab.selectedOrg.org) {
				this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES, this.props.lab.modalLab.selectedOrg.org.locations)
			} else {
				this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_BRANCHES, [])
		this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG, -1, null)
		this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH, -1, null)
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG,
				event.target.value,
				this.props.lab.modalLab.orgs[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_ORG, -1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH,
				event.target.value,
				this.props.lab.modalLab.branches[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_BRANCH, -1, null)
		}
	}

	onNotes(event) {}

	render() {
		return (
			<Container fluid>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.location)}</legend>
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.org)}</InputGroup.Text>
								</InputGroup.Prepend>

								<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
									{Utils.renderOptions(this.props.lab.modalLab.orgs)}
								</FormControl>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Message.branch)}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									required
									id={ResourceAssistance.ID.HOSPITEL.lab.modalLab.branch}
									as={ResourceAssistance.FormControl.as.select}
									onChange={this.onBranch}
								>
									{Utils.renderOptions(this.props.lab.modalLab.branches)}
								</FormControl>
							</InputGroup>
						</Col>
					</Row>
				</fieldset>

				<CLModalNewLabType />

				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>{translate(ResourceAssistance.Message.note)}</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows="3"
								disabled
								onChange={this.onNotes}
							></FormControl>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			setObjArray,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNewLabDetails)
