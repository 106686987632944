import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setCashierHealthCarePlans, setCashierAdmissionBalance } from "~/redux/action"
import { SET_CASHIER_SELECTED_HEALTH_CARE_PLAN } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierRightHealthCarePlan extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightHealthCarePlan",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.selectedAdmission, this.props.cashier.selectedAdmission)) {
			if (this.props.cashier.selectedAdmission.admission) {
				this.props.setCashierHealthCarePlans(this.props.cashier.selectedAdmission.admission.billingStatements)
				this.props.setCashierAdmissionBalance(this.props.cashier.selectedAdmission.admission)
			} else {
				this.props.setCashierHealthCarePlans([])
				this.props.setCashierAdmissionBalance(null)
			}
		}

		if (!_.isEqual(prevProps.cashier.healthCarePlanTable.original, this.props.cashier.healthCarePlanTable.original)) {
			if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
				let idx = this.props.cashier.healthCarePlanTable.filtered.findIndex(
					(healthCarePlan) =>
						_.isEqual(
							healthCarePlan.healthCareCode,
							this.props.cashier.selectedHealthCarePlan.healthCarePlan.healthCareCode
						) &&
						_.isEqual(
							healthCarePlan.healthCareName,
							this.props.cashier.selectedHealthCarePlan.healthCarePlan.healthCareName
						)
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_CASHIER_SELECTED_HEALTH_CARE_PLAN,
						idx,
						this.props.cashier.healthCarePlanTable.filtered[idx]
					)
				} else {
					this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, -1, null)
				}
			}
		}
	}

	onSelectRow(service, index) {
		this.props.setSelected(
			SET_CASHIER_SELECTED_HEALTH_CARE_PLAN,
			index,
			this.props.cashier.healthCarePlanTable.filtered[index]
		)
	}

	render() {
		return (
			<Fragment>
				<Row id={this.state.componentName} className={"full-size"}>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
							<Row style={{ height: "85%" }}>
								<Col>
									<ScrollableTable
										enableHighlight
										striped
										hover
										data={this.props.cashier.healthCarePlanTable}
										onClick={this.onSelectRow}
										isClearHighlight={this.props.cashier.selectedHealthCarePlan.index === -1}
										highlightedRow={this.props.cashier.selectedHealthCarePlan.index}
									/>
								</Col>
							</Row>
							<Row style={{ height: "13%" }}>
								<Col>
									<ScrollableTable style={{ minHeight: "unset" }} data={this.props.cashier.balanceTable} />
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierHealthCarePlans,
			setCashierAdmissionBalance,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierRightHealthCarePlan)
