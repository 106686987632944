import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
} from "~/redux/type"
import CashierModalAdjustmentBalance from "./CashierModalAdjustmentBalance"
import CashierModalAdjustmentDiscount from "./CashierModalAdjustmentDiscount"
import CashierModalAdjustmentQty from "./CashierModalAdjustmentQty"

class CashierModalAdjustmentDetails extends React.Component {
	componentDidMount() {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT, 0)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT, 0)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION, ResourceAssistance.CONSTANT.NONE)
	}

	componentWillUnmount() {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE, 0)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY, true)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY, "")
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, true)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY, true)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{this.props.cashier.modalAdjustment.isQtyPerDayDisplay && <CashierModalAdjustmentQty />}
				{this.props.cashier.modalAdjustment.isBalanceDisplay && <CashierModalAdjustmentBalance />}
				{this.props.cashier.modalAdjustment.isDiscountDisplay && <CashierModalAdjustmentDiscount />}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalAdjustmentDetails)
