import { Descriptions } from "antd"
import React, { Fragment } from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM, SET_HOSPITEL_MODAL_REGISTER_PATIENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalRegisterPatient extends React.Component {
	constructor(props) {
		super(props)

		this.onIdentificationNumber = this.onIdentificationNumber.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_PATIENT, null)
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM, "")
	}

	onIdentificationNumber(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM, event.target.value)
	}

	render() {
		return (
			<Fragment>
				{!this.props.hospitel.modalRegister.patient && (
					<Row>
						<Col>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>{translate(ResourceAssistance.Hospitel.identification)}</InputGroup.Text>
								</InputGroup.Prepend>
								<FormControl
									required
									autoFocus
									type={ResourceAssistance.FormControl.type.text}
									value={this.props.hospitel.modalRegister.identificationNumber}
									onChange={this.onIdentificationNumber}
								/>
								<InputGroup.Append>
									<InputGroup.Text>{<GoSearch />}</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Row>
				)}

				{this.props.hospitel.modalRegister.patient && (
					<Fragment>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
							<Row>
								<Col>
									<Descriptions
										size={"small"}
										labelStyle={{ fontWeight: "bold" }}
										contentStyle={{ fontStyle: "italic" }}
										column={2}
									>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
											{this.props.hospitel.modalRegister.patient.id}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
											{this.props.hospitel.modalRegister.patient.identificationNumber}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={2}>
											{this.props.hospitel.modalRegister.patient.title +
												" " +
												this.props.hospitel.modalRegister.patient.firstName +
												" " +
												this.props.hospitel.modalRegister.patient.lastName}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={2}>
											{Utils.formatDate(this.props.hospitel.modalRegister.patient.dobtime)}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)} span={2}>
											{Utils.calculateAge(this.props.hospitel.modalRegister.patient.dobtime)}
										</Descriptions.Item>
									</Descriptions>
								</Col>
							</Row>
						</fieldset>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.healthHistory)}</legend>
							<Row>
								<Col>
									<Descriptions
										size={"small"}
										labelStyle={{ fontWeight: "bold" }}
										contentStyle={{ fontStyle: "italic" }}
										column={2}
									>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.height)} span={1}>
											{this.props.hospitel.modalRegister.patient.height} cm
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.weight)} span={1}>
											{this.props.hospitel.modalRegister.patient.weight} kg
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.underlyingDisease)} span={2}>
											{this.props.hospitel.modalRegister.patient.underlyingDisease}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.allergies)} span={2}>
											{this.props.hospitel.modalRegister.patient.allergies}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Hospitel.pastIllnesses)} span={2}>
											{this.props.hospitel.modalRegister.patient.pastIllnesses}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.pastSurgicalHistory)} span={2}>
											{this.props.hospitel.modalRegister.patient.pastSurgicalHistory}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.familyHealthHistory)} span={2}>
											{this.props.hospitel.modalRegister.patient.familyHealthHistory}
										</Descriptions.Item>
									</Descriptions>
								</Col>
							</Row>
						</fieldset>
					</Fragment>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalRegisterPatient))
