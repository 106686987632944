import _ from "lodash"
import React from "react"
import { Badge, Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiOutlineWarning } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients } from "~/redux/action"
import { SET_OPD_MODIFY_DATE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import OPDModalModifyDateDetails from "./OPDModalModifyDateDetails"

class OPDModalModifyDate extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalModifyDate",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_MODIFY_DATE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.opd.selectedDoctorOrder.order, {
			...this.props.opd.selectedDoctorOrder.order,
			startDateTime: this.props.opd.modalModifyRange.startDateTime,
			endDateTime: this.props.opd.modalModifyRange.endDateTime,
		})
		return _.isEqual(target, this.props.opd.selectedDoctorOrder.order)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.updateDoctorOrderRange,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				doctorOrders: [
					{
						id: this.props.opd.selectedDoctorOrder.order.id,
						startDateTime: this.props.opd.modalModifyRange.startDateTime,
						endDateTime: this.props.opd.modalModifyRange.endDateTime,
					},
				],
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>
							<Badge>{translate(ResourceAssistance.Message.modifyDateRange)}</Badge>
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalModifyDateDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.warning}
							disabled={this.isDoneBtnDisabled()}
						>
							<AiOutlineWarning size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientModifyDateDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalModifyDate)
