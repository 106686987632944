import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportInventoryByLocationValueDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.inventoryByLocation,
				inStockItems: [],
				inventories: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Filter */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Branch/{this.state.data.branchIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.branch}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Code/{this.state.data.codeIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.code}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Description/{this.state.data.descriptionIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.description}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Item Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.brandIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.unitIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.pricePerUnitIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.totalIntl}</Text>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>ID</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Description</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Brand</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Unit</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Price/Unit</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Total</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{this.state.data.inStockItems.map((item) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
											<Text>{item.id}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
											<Text style={ResourceAssistance.Report.styles.textItalic}>{item.description}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.brand]}>
											<Text>{item.brand}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
											<Text>{item.qty}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.unit]}>
											<Text>{item.unit}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit]}>
											<Text>{item.pricePerUnit}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
											<Text>{item.total}</Text>
										</View>
									</View>
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Total */}
						<View style={[ResourceAssistance.Report.styles.row, { textAlign: "right" }]}>
							<View style={[ResourceAssistance.Report.styles.col]} />

							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>
											Grand Total/{this.state.data.grandTotalIntl}
										</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.grandTotal}</Text>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	id: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	description: {
		minWidth: "29%",
		maxWidth: "29%",
	},
	brand: {
		minWidth: "18%",
		maxWidth: "18%",
	},
	qty: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	unit: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	pricePerUnit: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	total: {
		minWidth: "10%",
		maxWidth: "10%",
	},
})

export default ReportInventoryByLocationValueDocument
