import React from "react"
import { Col, FormControl, FormLabel, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setPoBillingContact } from "~/redux/action"

class PORightBillingAddressRow extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedSupplier.index !== this.props.selectedSupplier.index) {
			if (this.props.selectedSupplier.supplier) {
				this.props.setPoBillingContact(
					this.props.selectedSupplier.supplier.orderDetail.billingContact
						? this.props.selectedSupplier.supplier.orderDetail.billingContact
						: ""
				)
			} else {
				this.props.setPoBillingContact("")
			}
		}
	}

	render() {
		return (
			<Row>
				<Col md="auto">
					<FormLabel>
						{translate(ResourceAssistance.Message.billingAddress)}
						{ResourceAssistance.Symbol.colon}
					</FormLabel>
				</Col>
				<Col>
					<FormControl
						type={ResourceAssistance.FormControl.type.text}
						size={ResourceAssistance.FormControl.size.sm}
						disabled
						value={this.props.selectedPo.po ? this.props.selectedPo.po.pr.location.address.billingAddress : ""}
					/>
				</Col>
				<Col md="auto">
					<FormLabel>
						{translate(ResourceAssistance.Message.contact)}
						{ResourceAssistance.Symbol.colon}
					</FormLabel>
				</Col>
				<Col md={3}>
					<FormControl
						type={ResourceAssistance.FormControl.type.text}
						size={ResourceAssistance.FormControl.size.sm}
						disabled
						value={this.props.contact}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contact: state.purchaseOrder.billingContact,
	selectedPo: state.purchaseOrder.selectedPo,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoBillingContact }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightBillingAddressRow)
